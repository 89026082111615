import { COLORS } from '@chronext/react-common';
import {
  Assessment,
  Calculate,
  CompareArrows,
  LocalShipping,
  MoveToInbox,
  PendingActions,
  PriceChangeOutlined,
  Watch,
} from '@mui/icons-material';
import CategoryIcon from '@mui/icons-material/Category';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import PercentIcon from '@mui/icons-material/Percent';
import SellIcon from '@mui/icons-material/Sell';
import StoreIcon from '@mui/icons-material/Store';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { Box, Divider, Drawer, DrawerProps, List, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ClientRoute, createPath } from '../../../routing';
import { ClientRouteGroups } from '../../../routing/clientRoutes';
import styles from './Sidebar.module.scss';
import Logo from './components/Logo';
import SidebarItem from './components/SidebarItem';

export type Item = {
  name: string | React.ReactNode;
  icon?: React.ReactNode;
  href: string;
  description?: string | React.ReactNode;
  groups: string[];
  cardColor?: string;
  categoryName?: React.ReactNode;
};

export type MenuItem = {
  item: Item;
  children?: Item[];
};

export const MenuItems: MenuItem[] = [
  {
    item: {
      name: <FormattedMessage id='menu_tracking' defaultMessage='Tracking' />,
      icon: <LocalShipping />,
      href: createPath({ path: ClientRoute.TRACKING_TABS, params: { tab: 'sales' } }),
      groups: ClientRouteGroups.TRACKING,
      description: (
        <FormattedMessage
          id='menuDescriptions_tracking'
          defaultMessage='Shipment status for sales orders, purchase orders and internal transfers'
        />
      ),
      categoryName: <FormattedMessage id='menuCategories_operations' defaultMessage='Operations' />,
      cardColor: 'blue',
    },
  },
  {
    item: {
      name: <FormattedMessage id='menu_workshop' defaultMessage='Workshop' />,
      icon: <Watch />,
      href: ClientRoute.WORKSHOP,
      groups: ClientRouteGroups.WORKSHOP,
    },
    children: [
      {
        name: <FormattedMessage id='menu_reports' defaultMessage='Reports' />,
        icon: <Assessment />,
        href: ClientRoute.WORKSHOP_REPORTS,
        groups: ClientRouteGroups.WORKSHOP_REPORTS,
      },
      {
        name: <FormattedMessage id='menu_inventory' defaultMessage='Inventory' />,
        icon: <Inventory2Icon />,
        href: ClientRoute.WORKSHOP_INVENTORY,
        groups: ClientRouteGroups.WORKSHOP_INVENTORY,
      },
      {
        name: <FormattedMessage id='menu_processes' defaultMessage='Processes' />,
        icon: <PendingActions />,
        href: ClientRoute.WORKSHOP_PROCESSES,
        groups: ClientRouteGroups.WORKSHOP_PROCESSES,
        description: (
          <FormattedMessage
            id='menuDescriptions_processes'
            defaultMessage='Workshop Processes from Watch Inbound to Final Control'
          />
        ),
      },
      {
        name: <FormattedMessage id='menu_workshopBooking' defaultMessage='Book Item' />,
        icon: <StoreIcon />,
        href: ClientRoute.WORKSHOP_BOOKING_ITEM,
        groups: ClientRouteGroups.WORKSHOP_BOOKING_ITEM,
        description: <FormattedMessage id='menuDescriptions_workshopBooking' defaultMessage='Book a workshop item' />,
      },
      {
        name: <FormattedMessage id='menu_workshopLocations' defaultMessage='Locations' />,
        icon: <WarehouseIcon />,
        href: ClientRoute.WORKSHOP_LOCATIONS,
        groups: ClientRouteGroups.WORKSHOP_LOCATIONS,
        description: (
          <FormattedMessage id='menuDescriptions_workshopLocations' defaultMessage='List and edit workshop locations' />
        ),
      },
    ],
  },
  {
    item: {
      name: <FormattedMessage id='menu_workshopInbound' defaultMessage='Workshop Inbound' />,
      icon: <MoveToInbox />,
      href: ClientRoute.WORKSHOP_INBOUND,
      groups: ClientRouteGroups.WORKSHOP_INBOUND,
    },
    children: [
      {
        name: <FormattedMessage id='menu_workshopInboundDashboard' defaultMessage='Dashboard' />,
        icon: <Assessment />,
        href: createPath({ path: ClientRoute.WORKSHOP_INBOUND_DASHBOARD_TABS, params: { tab: 'assignee' } }),
        groups: ClientRouteGroups.WORKSHOP_INBOUND_DASHBOARD,
        cardColor: 'purple',
      },
      {
        name: <FormattedMessage id='menu_workshopInboundRequests' defaultMessage='Workshop Requests' />,
        icon: <CompareArrows />,
        href: ClientRoute.WORKSHOP_INBOUND_REQUESTS,
        groups: ClientRouteGroups.WORKSHOP_INBOUND_REQUESTS,
        cardColor: 'blue',
      },
      {
        name: <FormattedMessage id='menu_workshopInboundItems' defaultMessage='Items' />,
        icon: <Watch />,
        href: createPath({ path: ClientRoute.WORKSHOP_INBOUND_ITEMS, params: { tab: 'all' } }),
        groups: ClientRouteGroups.WORKSHOP_INBOUND_ITEMS,
        cardColor: 'blue',
      },
      {
        name: <FormattedMessage id='menu_workshopInboundActivities' defaultMessage='Activities' />,
        icon: <PendingActions />,
        href: createPath({ path: ClientRoute.WORKSHOP_INBOUND_ACTIVITIES_TABS, params: { tab: 'inventory' } }),
        groups: ClientRouteGroups.WORKSHOP_INBOUND_ACTIVITIES,
        cardColor: 'blue',
      },
    ],
  },
  {
    item: {
      name: <FormattedMessage id='menu_sell' defaultMessage='Sell Requests' />,
      icon: <SellIcon />,
      href: createPath({ path: ClientRoute.SELL_TABS, params: { tab: 'all' } }),
      groups: ClientRouteGroups.SELL,
      description: (
        <FormattedMessage
          id='menuDescriptions_sell'
          defaultMessage='View, make offers and approve/reject sell requests'
        />
      ),
      categoryName: <FormattedMessage id='menuCategories_sell' defaultMessage='Sell Requests' />,
      cardColor: 'purple',
    },
  },
  {
    item: {
      name: <FormattedMessage id='menu_priceCalculator' defaultMessage='Price Calculator' />,
      icon: <Calculate />,
      href: ClientRoute.PRICE_CALCULATOR,
      groups: ClientRouteGroups.PRICE_CALCULATOR,
      description: (
        <FormattedMessage
          id='menuDescriptions_priceCalculator'
          defaultMessage='Price Calculator for Sales and Supply'
        />
      ),
      categoryName: <FormattedMessage id='menu_products' defaultMessage='Products' />,
      cardColor: 'orange',
    },
  },
  {
    item: {
      name: <FormattedMessage id='menu_priceCalculatorConfig' defaultMessage='Price Calculator Config' />,
      icon: <Calculate />,
      href: ClientRoute.PRICE_CALCULATOR_CONFIG,
      groups: ClientRouteGroups.PRICE_CALCULATOR_CONFIG,
      categoryName: <FormattedMessage id='menu_products' defaultMessage='Products' />,
      cardColor: 'purple',
    },
  },
  {
    item: {
      name: <FormattedMessage id='menu_products' defaultMessage='Products' />,
      icon: <Watch />,
      href: ClientRoute.PRODUCTS,
      groups: ClientRouteGroups.PRODUCTS,
    },
    children: [
      {
        name: <FormattedMessage id='menu_productCategories' defaultMessage='Categories' />,
        icon: <CategoryIcon />,
        href: ClientRoute.PRODUCT_CATEGORIES,
        groups: ClientRouteGroups.PRODUCT_CATEGORIES,
        cardColor: 'brown',
      },
      {
        name: <FormattedMessage id='menu_productDiscounts' defaultMessage='Discount Codes' />,
        icon: <PercentIcon />,
        href: ClientRoute.PRODUCT_DISCOUNTS,
        groups: ClientRouteGroups.PRODUCT_DISCOUNTS,
        cardColor: 'redDark',
      },
    ],
  },
  {
    item: {
      name: <FormattedMessage id='menu_workshopPrices' defaultMessage='Workshop Prices' />,
      icon: <PriceChangeOutlined />,
      href: ClientRoute.WORKSHOP_PRICES,
      groups: ClientRouteGroups.WORKSHOP_PRICES,
      description: (
        <FormattedMessage
          id='menuDescriptions_workshopPrices'
          defaultMessage='Watchmaking Service Price List and Services'
        />
      ),
      cardColor: 'greenMedium',
    },
  },
];

type Props = {
  open: boolean;
  drawerWidth: number;
  onCloseCallback: () => void;
};

const Sidebar: React.FC<Props> = (props) => {
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const drawerProps: DrawerProps = {
    sx: {
      display: {
        xs: isSmUp ? 'none' : 'block',
        sm: isSmUp ? 'block' : 'none',
      },
      '& .MuiDrawer-paper': {
        boxSizing: 'border-box',
        width: props.drawerWidth,
      },
    },
    variant: isSmUp ? 'permanent' : 'temporary',
    open: props.open,
    onClose: props.onCloseCallback,
    ModalProps: {
      keepMounted: true,
    },
  };

  return (
    <Box component='nav' sx={{ width: { sm: props.drawerWidth }, flexShrink: { sm: 0 } }}>
      <Drawer {...drawerProps}>
        <Box height='48px' alignItems='center' display='flex' sx={{ backgroundColor: COLORS.corePrimary }}>
          <Logo />
        </Box>
        <Divider />
        <List disablePadding className={styles.sidebarList}>
          {MenuItems.map((itemData) => (
            <SidebarItem key={itemData.item.href} {...itemData} />
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
