import { Edit } from '@mui/icons-material';
import { ButtonGroup } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { currentUserSelector } from '../../../../selectors/user';
import { useAppSelector } from '../../../../state';
import { TrackingComment } from '../../../../state/ducks/tracking/types';
import ButtonGroupIconButton from '../../../components/buttons/ButtonGroupIconButton';
import SimpleTable, { SimpleTableColDef } from '../../../components/table/SimpleTable';
import CreateUpdateTrackingCommentModal from './CreateUpdateTrackingCommentModal';
import TrackingCommentsTableToolbar from './TrackingCommentsTableToolbar';

type Props = {
  comments: any[];
  trackingId: string;
  fetchTrackingItem: () => void;
};

// Column definitions for Tracking History table
const commentsTableColumns: SimpleTableColDef[] = [
  {
    id: 'common_labels_comment',
    headerName: 'Comment',
    field: 'comment',
    minWidth: 500,
    sortable: false,
    filterable: false,
  },
  {
    id: 'common_labels_createdBy',
    headerName: 'Created By',
    field: 'username',
    minWidth: 250,

    sortable: false,
    filterable: false,
  },
  {
    id: 'common_labels_createdAt',
    headerName: 'Created At',
    field: 'createdAt',
    minWidth: 250,
    sortable: false,
    filterable: false,
  },
];

const TrackingCommentsTable: React.FC<Props> = ({ trackingId, comments, fetchTrackingItem }) => {
  const currentUser = useAppSelector(currentUserSelector);
  const intl = useIntl();

  const [tableColumns, setTableColumns] = useState<SimpleTableColDef[]>(commentsTableColumns);
  const [selectedComment, setSelectedComment] = useState<TrackingComment | null>(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const actionsColumn: SimpleTableColDef = {
    id: 'common_actions',
    headerName: 'Actions',
    field: 'actions',
    filterable: false,
    sortable: false,
    width: 80,
    align: 'center',
    renderCell: (params: GridRenderCellParams) => {
      if (params.row.username === currentUser.username) {
        return (
          <ButtonGroup variant='text'>
            <ButtonGroupIconButton
              color='info'
              onClick={() => {
                setSelectedComment(params.row);
                setShowUpdateModal(true);
              }}
              title={intl.formatMessage({
                id: 'common_edit',
                defaultMessage: 'Edit',
              })}>
              <Edit />
            </ButtonGroupIconButton>
          </ButtonGroup>
        );
      }
    },
  };

  useEffect(() => {
    setTableColumns([...commentsTableColumns, actionsColumn]);
  }, []);

  return (
    <>
      {showUpdateModal && selectedComment && (
        <CreateUpdateTrackingCommentModal
          trackingId={trackingId}
          comment={selectedComment}
          show={showUpdateModal}
          handleToggleModal={() => setShowUpdateModal(!showUpdateModal)}
          onSuccess={fetchTrackingItem}
        />
      )}
      <SimpleTable
        columns={tableColumns}
        rows={comments || []}
        slots={{
          toolbar: TrackingCommentsTableToolbar,
        }}
        slotProps={{
          toolbar: {
            trackingId,
            onSuccess: fetchTrackingItem,
          },
        }}
      />
    </>
  );
};

export default TrackingCommentsTable;
