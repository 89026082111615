const deMessages = {
  address_display_billing: 'Rechnungsadresse',
  address_display_pickup: 'Abholadresse',
  auth_unauthorized_message:
    'Sie sind nicht berechtigt, diese Seite zu sehen. Wenn Sie glauben, dass dies ein Fehler ist, wenden Sie sich bitte an Ihren Administrator.',
  auth_unauthorized_title: 'Fehler',
  common_actions: 'Aktionen',
  common_add: 'Hinzufügen',
  common_all: 'Alle',
  common_approve: 'Genehmigen',
  common_average: 'Durchschnitt',
  common_back: 'Zurück',
  common_cancel: 'Abbrechen',
  common_clear: 'Leeren',
  common_close: 'Schließen',
  common_confirm: 'Bestätigen',
  common_copyToClipboard: 'In Zwischenablage kopieren',
  common_days: '{count, plural, one {# Tag} other {# Tage}}',
  common_decline: 'Ablehnen',
  common_delete: 'Löschen',
  common_deleteModal_title: 'Eintrag "{entry}" löschen',
  common_details: 'Details',
  common_disable: 'Deaktivieren',
  common_download: 'Herunterladen',
  common_edit: 'Bearbeiten',
  common_enable: 'Aktivieren',
  common_filters: 'Filter',
  common_labels_accountHolder: 'Kontoinhaber',
  common_labels_additionalStreetInfo: 'Adresszeile 2',
  common_labels_bankName: 'Name der Bank',
  common_labels_bic: 'BIC',
  common_labels_boxAvailable: 'Box',
  common_labels_braceletType: 'Art des Armbands',
  common_labels_brand: 'Marke',
  common_labels_city: 'Stadt',
  common_labels_comment: 'Kommentar',
  common_labels_company: 'Firma',
  common_labels_count: 'Anzahl',
  common_labels_country: 'Land',
  common_labels_createdAt: 'Erstellt am',
  common_labels_createdBy: 'Erstellt von',
  common_labels_differentBillingAddress: 'Andere Rechnungsadresse',
  common_labels_elapsedTime: 'Vergangene Zeit',
  common_labels_endTime: 'Endzeit',
  common_labels_finalVariant: 'Endgültige Variante',
  common_labels_firstName: 'Vorname',
  common_labels_gender: 'Geschlecht',
  common_labels_history: 'Verlauf',
  common_labels_iban: 'IBAN',
  common_labels_id: 'Id',
  common_labels_lastName: 'Nachname',
  common_labels_margin: 'Marge',
  common_labels_model: 'Modell',
  common_labels_number: 'Nr.',
  common_labels_orderId: 'Auftragsnummer',
  common_labels_paperAvailable: 'Papiere',
  common_labels_phoneNumber: 'Telefonnr.',
  common_labels_pickupDate: 'Übergabedatum',
  common_labels_postalCode: 'PLZ',
  common_labels_price: 'Preis',
  common_labels_reference: 'Referenz',
  common_labels_salutation: 'Anrede',
  common_labels_serialNumber: 'Inventar-Id',
  common_labels_sku: 'SKU',
  common_labels_startTime: 'Startzeit',
  common_labels_state: 'Staat',
  common_labels_status: 'Status',
  common_labels_statusMessage: 'Statusmeldung',
  common_labels_streetName: 'Straße',
  common_labels_streetNumber: 'Hausnr.',
  common_labels_updatedAt: 'Aktualisiert am',
  common_labels_updatedBy: 'Aktualisiert von',
  common_labels_value: 'Wert',
  common_labels_variantCode: 'Varianten-Code',
  common_labels_watchCondition: 'Zustand',
  common_months: '{count, plural, one {# Monat} other {# Monate}}',
  common_next: 'Weiter',
  common_no: 'Nein',
  common_noImages: 'Keine Bilder verfügbar',
  common_ok: 'Ok',
  common_optional: 'optional',
  common_refresh: 'Aktualisieren',
  common_remove: 'Entfernen',
  common_required: 'erforderlich',
  common_resetFilters: 'Filter & Sortierung zurücksetzen',
  common_results: 'Ergebnisse',
  common_salutation_f: 'Frau',
  common_salutation_m: 'Herr',
  common_save: 'Speichern',
  common_search: 'Suchen',
  common_set: 'Setzen',
  common_shareFilters: 'Filter & Sortierung teilen',
  common_show: 'Anzeigen',
  common_submit: 'Übermitteln',
  common_total: 'Gesamt',
  common_warning_noUndo: 'Diese Aktion kann nicht rückgängig gemacht werden.',
  common_weeks: '{count, plural, one {# Woche} other {# Wochen}}',
  common_years: '{count, plural, one {# Jahr} other {# Jahre}}',
  common_yes: 'Ja',
  condition_a: 'Gut',
  condition_aa: 'Sehr Gut',
  condition_aaa: 'Wie Neu',
  condition_good: 'Gut',
  condition_like_new: 'Wie Neu',
  condition_new: 'Neu',
  condition_used: 'Gebraucht',
  condition_vint: 'Vintage',
  dataGrid_noResultsOverlayLabel: 'Keine Ergebnisse gefunden.',
  dataGrid_noRowsLabel: 'Keine Zeilen',
  forms_dateFilter_endDate: 'Enddatum',
  forms_dateFilter_startDate: 'Startdatum',
  forms_fileUpload_label: 'Dateien auswählen',
  forms_fileUpload_noFiles: 'Keine Dateien ausgewählt',
  menu_inventory: 'Inventar',
  menu_priceCalculator: 'Preisrechner',
  menu_priceCalculatorConfig: 'Preisrechner-Konfiguration',
  menu_processes: 'Prozesse',
  menu_productCategories: 'Kategorien',
  menu_productDiscounts: 'Rabattcodes',
  menu_products: 'Produkte',
  menu_reports: 'Berichte',
  menu_sell: 'Verkaufsanfragen',
  menu_tracking: 'Sendungsverfolgung',
  menu_workshop: 'Werkstatt',
  menu_workshopBooking: 'Artikel buchen',
  menu_workshopInbound: 'Werkstatt-Eingang',
  menu_workshopInboundActivities: 'Aktivitäten',
  menu_workshopInboundDashboard: 'Dashboard',
  menu_workshopInboundItems: 'Artikel',
  menu_workshopInboundRequests: 'Werkstatt-Anfragen',
  menu_workshopLocations: 'Standorte',
  menu_workshopPrices: 'Werkstatt-Preise',
  menuCategories_operations: 'Operations',
  menuCategories_pickupLocations: 'Pickup Locations',
  menuCategories_sell: 'Verkaufsanfragen',
  menuDescriptions_priceCalculator: 'Preisrechner für Sales und Supply',
  menuDescriptions_processes: 'Werkstattprozesse von Uhreingang bis zur Endkontrolle',
  menuDescriptions_sell: 'Angebote ansehen, Verkaufsanfragen bestätigen/ablehnen.',
  menuDescriptions_tracking: 'Sendungsstatus für Kundenaufträge und Einkaufsbestellungen',
  menuDescriptions_workshopBooking: 'Werkstattartikel einbuchen',
  menuDescriptions_workshopLocations: 'Workshop-Standorte auflisten und bearbeiten',
  menuDescriptions_workshopPrices:
    'Verwaltung der im Webshop angebotenen Werkstattleistungen und entsprechender Preise',
  priceCalculator_disclaimer:
    'Diese Preise werden auf der Grundlage von {count, plural, one {# Datensatz} other {# Datensätzen}} berechnet, die von {startDate} bis {endDate} gesammelt wurden.',
  priceCalculator_disclaimer_modelledData:
    'Der Preis basiert auf einem Datensatz mit einen durchschnittlichen Fehlerwert von {mae}, was bedeutet, dass die Vorhersagen im Durchschnitt um diesen Betrag abweichen, und {absoluteErrorPercentage}% der Vorhersagen liegen innerhalb eines Bereichs von ±{absoluteErrorRange} vom tatsächlichen Wert.',
  priceCalculator_formDescription:
    'Um den Preis für einen Artikel zu berechnen, müssen folgende Daten angegeben werden: Marke, Zifferblattfarbe und mindestens eines der beiden Felder Modell bzw. Referenz',
  priceCalculator_formDescription_modelledData:
    'Falls es keine Ergebnisse gibt, geben Sie bitte mindestens alle drei Angaben zu Marke, Modell und Referenz ein. Wir können den potenziellen Preis auf der Grundlage unseres Modells der Daten berechnen.',
  priceCalculator_labels_marketPrice: 'Marktpreis',
  priceCalculator_priceGraph: 'Preise auf Basis des Herstellungsjahres',
  priceCalculator_priceUpTo: 'bis zu',
  priceCalculatorConfig_createModal_description:
    'Um einen neuen Eintrag hinzuzufügen, füllen Sie bitte die untenstehenden Felder aus. Bitte beachten Sie, dass Sie erst dann ein Modell auswählen können, wenn Sie eine Marke aus dem Dropdown-Menü ausgewählt haben. Wenn es noch keinen Eintrag für die ausgewählte Marke gibt, müssen Sie diesen erst erstellen. Das Gleiche gilt für die Referenz.',
  priceCalculatorConfig_createModal_marginTierMarginValueError: 'Mindestwert {minMarginValue}.',
  priceCalculatorConfig_createModal_marginTierMinValueError: 'Erster Eintrag muss 0 sein.',
  priceCalculatorConfig_createModal_referenceExists: 'Referenz existiert bereits. Bitte geben Sie eine andere an.',
  priceCalculatorConfig_createModal_referenceInvalid:
    'Referenz kann nur Buchstaben, Zahlen und die folgenden Zeichen enthalten: -./',
  priceCalculatorConfig_createModal_title: 'Neuer Eintrag',
  priceCalculatorConfig_minProductValue: 'Min. Produktwert',
  priceCalculatorConfig_tabs_brands: 'Marken',
  priceCalculatorConfig_tabs_margins: 'Margen',
  priceCalculatorConfig_updateModal_title:
    '{fallback, select, true {Standardeintrag} other {Eintrag "{entry}"}} aktualisieren',
  pricingCalculator_actions_delete_warning:
    'Bitte beachten Sie, dass ein Eintrag nur dann gelöscht werden kann, wenn es sich nicht um einen Fallback-Eintrag für eine Marke oder ein Modell handelt und diese Marke oder dieses Modell weitere Einträge hat.',
  productCategories_actions_clear: 'Alle Produkte aus Kategorie "{category}" entfernen',
  productCategories_actions_create: 'Neue Kategorie erstellen',
  productCategories_actions_delete: 'Kategorie "{category}" endgültig löschen',
  productCategories_actions_modify: 'Produkte in Kategorie "{category}" bearbeiten',
  productCategories_clearModal_deleteItemsText:
    '{count, plural, one {# Produkt wird} other {# Produkte werden}} aus dieser Kategorie entfernt.',
  productCategories_clearModal_emptyText: 'Aktion nicht verfügbar, diese Kategorie ist leer.',
  productCategories_clearModal_title: 'Kategorie "{category}" leeren?',
  productCategories_createModal_description:
    'Um eine neue Kategorie hinzuzufügen, füllen Sie bitte die untenstehenden Felder aus.',
  productCategories_createModal_title: 'Neue Kategorie',
  productCategories_deleteModal_notEmptyText: 'Aktion nicht verfügbar, nur leere Kategorien können gelöscht werden.',
  productCategories_deleteModal_title: 'Kategorie "{category}" löschen?',
  productCategories_detailsModal_productCount: 'Produktanzahl: {count}',
  productCategories_labels_key: 'Schlüssel',
  productCategories_labels_name: 'Name',
  productCategories_modifyModal_detectedSkus: 'SKus erkannt: {skuCount}',
  productCategories_modifyModal_explanation:
    'Um SKUs hinzuzufügen, zu entfernen oder zu ersetzen, laden Sie entweder eine oder mehrere CSV-Dateien mit den SKUs hoch oder tragen sie die SKUs mit Komma getrennt in das Textfeld ein. WICHTIG: wenn ein Feld bearbeitet wird, wird das andere überschrieben.',
  productCategories_modifyModal_formFields_skus_hint: 'Beispiel: A10500_T0815,X10500_T0815',
  productCategories_modifyModal_formFields_skus_label: 'SKUs',
  productCategories_modifyModal_productCount: 'Aktuelle Anzahl von Produkten: {count}',
  productCategories_modifyModal_title: 'Inhalt der Kategorie "{category}" bearbeiten',
  productDiscountBatchJobs_createModal_amountHint:
    'Legen Sie fest, wie viele Rabattcodes erstellt werden sollen. Minimalmenge 2.',
  productDiscountBatchJobs_createModal_codePatternHint:
    'Codes dürfen nur Großbuchstaben, Zahlen, _, - oder # enthalten. {br}# wird verwendet, um die Platzierung der generierten Zeichen zu bestimmen.',
  productDiscountBatchJobs_createModal_description:
    'Um einen neuen Batch-Auftrag hinzuzufügen, füllen Sie bitte die unten stehenden Felder aus. Nach der Erstellung wird der Auftrag im Hintergrund ausgeführt. Sie können den Status überprüfen, indem Sie die Liste der Stapelaufträge aktualisieren.',
  productDiscountBatchJobs_createModal_title: 'Neuer Batch-Auftrag',
  productDiscountBatchJobs_detailsModal_title: 'Details zu Batch-Auftrag {id}',
  productDiscounts_actions_create: 'Neuen Rabattcode erstellen',
  productDiscounts_actions_createBatchJob: 'Neuen Batch-Auftrag erstellen',
  productDiscounts_actions_delete: 'Rabattcode permanent löschen',
  productDiscounts_actions_download_codes: 'Rabattcodes herunterladen',
  productDiscounts_actions_edit: 'Rabattcode bearbeiten',
  productDiscounts_createModal_codeHint:
    'Codes dürfen nur Großbuchstaben, Zahlen, _, -, # oder * enthalten.{br}# werden durch Zahlen ersetzt, * durch Buchstaben',
  productDiscounts_createModal_codePreview: 'Generierter Code: {code}',
  productDiscounts_createModal_countriesHint:
    'Komma-separierte Liste von Ländercodes. Leer lassen, damit der Code in allen Ländern verfügbar ist.',
  productDiscounts_createModal_description:
    'Um einen neuen Rabattcode hinzuzufügen, füllen Sie bitte die nachstehenden Felder aus.',
  productDiscounts_createModal_detectedCountries: 'Erkannte Länder: {countryCount}',
  productDiscounts_createModal_groupsHint:
    'Komma-separierte Liste von Gruppen. Beispiel: Fügen Sie die Ticket-ID oder den Namen der Marketingkampagne als Gruppe hinzu.',
  productDiscounts_createModal_maxApplicationsHint:
    'Begrenzen Sie, wie oft ein Rabattcode verwendet werden kann (mindestens 1). Leer lassen für keine Begrenzung.',
  productDiscounts_createModal_presets: 'Voreinstellungen',
  productDiscounts_createModal_title: 'Neuer Rabattcode',
  productDiscounts_createModal_validity: 'Gültigkeitszeitraum',
  productDiscounts_deleteModal_discountHasStarted:
    'Bitte vergewissern Sie sich, dass der Code noch nicht an Kunden kommuniziert worden ist.',
  productDiscounts_deleteModal_title: 'Rabattcode "{code}" löschen?',
  productDiscounts_detailsModal_allCountries: 'Dieser Rabattcode ist für alle Länder gültig.',
  productDiscounts_detailsModal_noCategories: 'Dieser Rabattcode hat keine Kategorien.',
  productDiscounts_detailsModal_noGroups: 'Dieser Rabattcode hat keine Gruppen.',
  productDiscounts_detailsModal_title: 'Details zu Rabattcode "{code}"',
  productDiscounts_detailsModal_unlimitedApplications: 'Dieser Rabattcode ist unbegrenzt anwendbar.',
  productDiscounts_editModal_activeWarning:
    'Der Gültigkeitszeitraum für diesen Rabattcode hat bereits begonnen. Die Werte, die Sie ändern können, sind daher begrenzt.',
  productDiscounts_editModal_changedDataHint:
    'Felder, die mit diesem Symbol gekennzeichnet sind, werden beim Speichern des Formulars geändert.',
  productDiscounts_editModal_maxApplicationsHint:
    'Begrenzen Sie, wie oft ein Rabattcode verwendet werden kann (mindestens 1). Wenn der Rabattcode bereits aktiv ist, können Sie diesen Wert nur erhöhen.',
  productDiscounts_editModal_title: 'Rabattcode "{discountCode}" bearbeiten',
  productDiscounts_filter_hideBatchDiscounts: 'Batch-Rabattcodes ausblenden',
  productDiscounts_labels_amount: 'Menge',
  productDiscounts_labels_categories: 'Kategorien',
  productDiscounts_labels_code: 'Code',
  productDiscounts_labels_codePattern: 'Codemuster',
  productDiscounts_labels_countries: 'Länder',
  productDiscounts_labels_currencies: 'Währungen',
  productDiscounts_labels_discountPercentage: 'Rabatt-Prozentsatz',
  productDiscounts_labels_discountTiers: 'Rabattstaffeln',
  productDiscounts_labels_discountType: 'Typ',
  productDiscounts_labels_discountValue: 'Rabattwert',
  productDiscounts_labels_groups: 'Gruppen',
  productDiscounts_labels_isActive: 'Aktiv',
  productDiscounts_labels_isRelative: 'relative Werte in %',
  productDiscounts_labels_maxApplications: 'Max. Anwendungen',
  productDiscounts_labels_minCartValue: 'Min. Warenkorbwert',
  productDiscounts_labels_validFrom: 'Gültig ab',
  productDiscounts_labels_validUntil: 'Gültig bis',
  productDiscounts_searchPlaceholder: 'Suche nach Code / Gruppen',
  productDiscounts_tabs_batchJobs: 'Batch-Aufträge',
  productDiscounts_tabs_discounts: 'Rabattcodes',
  productDiscounts_types_absolute: 'Absolut',
  productDiscounts_types_myDiscounts: 'Meine Rabattcodes',
  productDiscounts_types_relative: 'Relativ',
  sellRequests_actions_cancel: 'Verkaufsanfrage stornieren',
  sellRequests_actions_createOffer: 'Neues Angebot erstellen',
  sellRequests_actions_requestVendorInfo: 'Informationen anfordern',
  sellRequests_actions_setToPending: 'Auf Ausstehend setzen',
  sellRequests_actions_updateExpiration: 'Gültigkeit aktualisieren',
  sellRequests_cancellationReasons_brandNotInteresting: 'Marke uninteressant',
  sellRequests_cancellationReasons_duplicatedRequest: 'Duplizierte Anfrage',
  sellRequests_cancellationReasons_fakeWatch: 'Gefälschte Uhr',
  sellRequests_cancellationReasons_marketSituation: 'Marktlage',
  sellRequests_cancellationReasons_negotiationIssue: 'Verhandlungsproblem',
  sellRequests_cancellationReasons_noVendorResponse: 'Keine Antwort des Verkäufers',
  sellRequests_cancellationReasons_offerDeclined: 'Angebot vom Verkäufer abgelehnt',
  sellRequests_cancellationReasons_watchCondition: 'Zustand der Uhr',
  sellRequests_cancelModal_title: 'Verkaufsanfrage {reference} stornieren',
  sellRequests_details_cardTitles_item: 'Artikel',
  sellRequests_details_cardTitles_offer: 'Angebot',
  sellRequests_details_cardTitles_priceEstimates: 'Preisschätzungen',
  sellRequests_details_cardTitles_vendor: 'Verkäufer',
  sellRequests_details_noOffer: 'Angebot wurde noch nicht erstellt',
  sellRequests_details_title: 'Details zu Verkaufsanfrage {reference}',
  sellRequests_detailsModal_country: 'Land',
  sellRequests_detailsModal_email: 'E-Mail Adresse',
  sellRequests_detailsModal_fullName: 'Voller Name',
  sellRequests_detailsModal_language: 'Sprache',
  sellRequests_detailsModal_noImages: 'Keine Bilder für diese Verkaufsanfrage verfügbar',
  sellRequests_detailsModal_phoneNumber: 'Telefonnr.',
  sellRequests_detailsModal_sellerInfo: 'Verkäuferinformationen',
  sellRequests_editModal_title: 'Verkaufsanfrage {reference} bearbeiten',
  sellRequests_filter_approvedOffer: 'Genehmigtes Angebot',
  sellRequests_filter_declinedOffer: 'Abgelehntes Angebot',
  sellRequests_filter_none: '<em>Kein Filter</em>',
  sellRequests_filter_noOffer: 'Kein Angebot',
  sellRequests_filter_pendingOffer: 'Ausstehendes Angebot',
  sellRequests_finalizeModal_confirmation: 'Sind Sie sicher? Dieser Prozess kann nicht rückgängig gemacht werden.',
  sellRequests_labels_acceptedAt: 'Angenommen am',
  sellRequests_labels_approvedAt: 'Genehmigt am',
  sellRequests_labels_approvedBy: 'Genehmigt von',
  sellRequests_labels_cancellationReason: 'Stornierungsgrund',
  sellRequests_labels_comments: 'Kommentar',
  sellRequests_labels_commissionPrice: 'Kommissionspreis',
  sellRequests_labels_commissionRate: 'Kommissionsrate',
  sellRequests_labels_constructionYear: 'Baujahr',
  sellRequests_labels_cxtSellingPrice: 'CHRONEXT Verkaufspreis inkl. MwSt.',
  sellRequests_labels_declinedAt: 'Abgelehnt am',
  sellRequests_labels_declinedBy: 'Abgelehnt von',
  sellRequests_labels_desiredMargin: 'Gewünschte Marge',
  sellRequests_labels_directPrice: 'Netto Direktangebot',
  sellRequests_labels_email: 'E-Mail Adresse',
  sellRequests_labels_exchangePrice: 'Netto Angebot Inzahlungnahme',
  sellRequests_labels_expirationDate: 'Ablaufdatum',
  sellRequests_labels_expirationDaysLimit: 'Gültigkeit in Tagen',
  sellRequests_labels_fullName: 'Voller Name',
  sellRequests_labels_images: 'Bilder',
  sellRequests_labels_language: 'Sprache',
  sellRequests_labels_missingDataFields: 'Fehlende Felder',
  sellRequests_labels_offeredBy: 'Angeboten von',
  sellRequests_labels_offerSellingMethods: 'Angebotene Verkaufsmethoden (+ ausgewählt)',
  sellRequests_labels_priceEstimates: 'Preisschätzungen',
  sellRequests_labels_sellingMethods: 'Verkaufsmethoden',
  sellRequests_labels_status: 'Angebotsstatus',
  sellRequests_labels_statusInfo: 'Statusinformation',
  sellRequests_labels_taxation: 'Besteuerung',
  sellRequests_labels_vendor: 'Verkäufer',
  sellRequests_labels_vendorEmail: 'Verkäufer - E-Mail',
  sellRequests_missingData: 'Fehlende Daten',
  sellRequests_missingDataModal_requestButton: 'Daten anfordern',
  sellRequests_missingDataModal_title: 'Fehlende Daten für Verkaufsanfrage {reference} anfordern ',
  sellRequests_offerModal_description:
    'Wenn Details geändert werden, wird der Angebotsstatus zurückgesetzt. Wenn Sie nur die Gültigkeitsdauer ändern möchten, verwenden Sie bitte die Funktion auf der Detailseite.',
  sellRequests_offerModal_lastOffers: '{count, plural, one {Letztes Angebot} other {Letzte # Angebote}} für diese SKU',
  sellRequests_offerModal_title: 'Angebotsdetails zu Verkaufsanfrage {reference}',
  sellRequests_offerModal_usePrice: 'diesen Preis übernehmen',
  sellRequests_offerRejectionReasons_cannot_trust_chronext:
    'Ich traue Chronext nicht zu, den Verkauf meiner Uhr abzuwickeln.',
  sellRequests_offerRejectionReasons_low_price: 'Das Preisangebot entsprach nicht meinen Erwartungen.',
  sellRequests_offerRejectionReasons_not_interested:
    'Ich bin nicht an einem Verkauf interessiert, ich wollte nur den Wert wissen.',
  sellRequests_offerRejectionReasons_not_the_right_option:
    'Mir wurde nicht die von mir bevorzugte Kaufoption angeboten.',
  sellRequests_offerRejectionReasons_other: 'Sonstiges',
  sellRequests_offerRejectionReasons_slow_response: 'Das Angebot kam zu spät, ich habe meine Uhr bereits verkauft.',
  sellRequests_offerReply_accepted: 'Der Kunde hat dieses Angebot angenommen.',
  sellRequests_offerReply_counterOffer: 'Gegenangebot',
  sellRequests_offerReply_declined: 'Der Kunde hat dieses Angebot abgelehnt.',
  sellRequests_requestVendorInformationModal_confirmation:
    'Bitte bestätigen Sie, dass Sie Informationen zum Verkäufer für diese Verkaufsanfrage anfordern möchten. Der Verkäufer erhält daraufhin eine E-Mail.',
  sellRequests_sellingMethods_commission: 'Kommission',
  sellRequests_sellingMethods_direct: 'Direkt',
  sellRequests_sellingMethods_exchange: 'Inzahlungnahme',
  sellRequests_sellingMethods_tradeIn: 'Inzahlungnahme',
  sellRequests_statusInfo_awaiting_erp_po: 'ERP - PO wird erstellt',
  sellRequests_statusInfo_awaiting_offer_approval: 'Angebotsgenehmigung ausstehend',
  sellRequests_statusInfo_awaiting_offer_creation: 'Angebotserstellung ausstehend',
  sellRequests_statusInfo_cancelled_by_supply_team: 'Vom Supply Team storniert',
  sellRequests_statusInfo_cancelled_by_vendor: 'Vom Verkäufer storniert',
  sellRequests_statusInfo_closed: 'Geschlossen',
  sellRequests_statusInfo_completed: 'Abgeschlossen',
  sellRequests_statusInfo_counter_offer: 'Gegenangebot prüfen',
  sellRequests_statusInfo_failed_erp_po_creation: 'ERP - PO-Erstellung fehlgeschlagen',
  sellRequests_statusInfo_inbound_done: 'Wareneingang abgeschlossen',
  sellRequests_statusInfo_inbound_pending: 'Wareneingang ausstehend',
  sellRequests_statusInfo_missing_info: 'Fehlende Informationen zur Uhr angefordert',
  sellRequests_statusInfo_new_request: 'Neue Anfrage',
  sellRequests_statusInfo_no_vendor_response: 'Keine Antwort des Verkäufers',
  sellRequests_statusInfo_offer_expired: 'Angebot abgelaufen',
  sellRequests_statusInfo_offer_sent: 'Angebot gesendet',
  sellRequests_statusInfo_payment_pending: 'Zahlung ausstehend',
  sellRequests_statusInfo_po_waiting_for_label: 'ERP - PO wartet auf Versandlabel',
  sellRequests_statusInfo_requested_vendor_info: 'Verkäuferinformationen angefordert',
  sellRequests_statusInfo_resend_offer: 'Verkäufer hat Angebot wiedereröffnet',
  sellRequests_statusInfo_workshop_approval_pending: 'Werkstattfreigabe ausstehend',
  sellRequests_statusInfo_workshop_approval_received: 'Werkstattfreigabe erhalten',
  sellRequests_statusInfo_workshop_in_progress: 'Werkstatt in Arbeit',
  sellRequests_statusInfo_workshop_received: 'In der Werkstatt eingegangen',
  sellRequests_tabs_overview: 'Übersicht',
  sellRequests_taxationTypes_diff: 'Differenz',
  sellRequests_taxationTypes_full: 'Voll',
  sellRequests_updateExpirationModal_title: 'Gültigkeit des Angebots für Verkaufsanfrage {reference} aktualisieren',
  sellRequests_updateVendorInformationModal_title:
    'Verkäuferinformationen für Verkaufsanfrage {reference} aktualisieren',
  status_active: 'aktiv',
  status_approved: 'genehmigt',
  status_cancelled: 'abgebrochen',
  status_complete: 'abgeschlossen',
  status_cxt_pending: 'CXT - Aktion erforderlich',
  status_declined: 'abgelehnt',
  status_expired: 'abgelaufen',
  status_failed: 'fehlgeschlagen',
  status_finalized: 'abgeschlossen',
  status_in_progress: 'in Bearbeitung',
  status_ordered: 'bestellt',
  status_paused: 'pausiert',
  status_pending: 'ausstehend',
  status_received: 'erhalten',
  status_requested: 'angefordert',
  status_sent: 'gesendet',
  status_waiting_for_customer: 'Auf Kunde wartend',
  successMessages_commercetools_clearCategory:
    'Kategorie {categoryKey} erfolgreich geleert, {count, plural, one {# Produkt} other {# Produkte}} entfernt',
  successMessages_commercetools_createBatchJob: 'Batch-Auftrag für Codemuster {code} erfolgreich erstellt',
  successMessages_commercetools_createCategory:
    'Kategorie {categoryName} mit Schlüssel {categoryKey} erfolgreich erstellt',
  successMessages_commercetools_createDiscountCode: 'Rabattcode {code} erfolgreich erstellt',
  successMessages_commercetools_deleteCategory: 'Kategorie {categoryKey} erfolgreich gelöscht',
  successMessages_commercetools_deleteDiscountCode: 'Rabattcode {code} erfolgreich gelöscht',
  successMessages_commercetools_updateCategory: 'Kategorie {categoryKey} erfolgreich aktualiesiert',
  successMessages_commercetools_updateCategory_add:
    'Kategorie {categoryKey} erfolgreich aktualisiert, {skusAddedCount, plural, one {# Produkt} other {# Produkte}} hinzugefügt',
  successMessages_commercetools_updateCategory_remove:
    'Kategorie {categoryKey} erfolgreich aktualisiert, {skusRemovedCount, plural, one {# Produkt} other {# Produkte}} entfernt',
  successMessages_commercetools_updateCategory_set:
    'Kategorie {categoryKey} erfolgreich aktualisiert, {skusAddedCount, plural, one {# Produkt} other {# Produkte}} hinzugefügt und {skusRemovedCount, plural, one {# Produkt} other {# Produkte}} entfernt',
  successMessages_copiedToClipboard: 'Erfolgreich in die Zwischenablage kopiert',
  successMessages_default: 'Aktion erfolgreich ausgeführt',
  successMessages_finalizeOffer: 'Angebot {reference} erfolgreich abgeschlossen',
  successMessages_pricing_createPricingConfigEntry: 'Margen-Konfigurationseintrag {reference} erfolgreich erstellt',
  successMessages_pricing_deletePricingConfigEntry: 'Margen-Konfigurationseintrag {reference} erfolgreich gelöscht',
  successMessages_pricing_updatePricingConfigEntry: 'Margen-Konfigurationseintrag {reference} erfolgreich aktualisiert',
  successMessages_saveOfferDetails: 'Angebot {reference} erfolgreich gespeichert',
  successMessages_sell_cancelSellRequest: 'Verkaufsanfrage {reference} erfolgreich storniert',
  successMessages_sell_requestMissingData: 'Fehlende Daten für Verkaufsanfrage {reference} erfolgreich angefordert',
  successMessages_sell_requestVendorInformation:
    'Verkäuferinformationen für Verkaufsanfrage {reference} erfolgreich angefordert',
  successMessages_sell_updateSellRequest: 'Verkaufsanfrage {reference} erfolgreich aktualisiert',
  successMessages_sell_updateSellRequestOffer: 'Angebot für Verkaufsanfrage {reference} erfolgreich aktualisiert',
  successMessages_sell_updateVendorInformation:
    'Verkäuferinformationen für Verkaufsanfrage {reference} erfolgreich aktualisiert',
  successMessages_tracking_createTrackingComment: 'Kommentar erfolgreich erstellt',
  successMessages_tracking_updateTrackingComment: 'Kommentar erfolgreich aktualisiert',
  successMessages_workshop_createLocation: 'Ort {reference} erfolgreich erstellt',
  successMessages_workshop_createWorkshopProcess: 'Prozess {reference} erfolgreich angelegt',
  successMessages_workshop_updateWorkshopItem: 'Ort {reference} erfolgreich aktualisiert',
  successMessages_workshop_updateWorkshopProcess: 'Prozess {reference} erfolgreich aktualisiert',
  successMessages_workshopInbound_createWorkshopInboundReport: 'Protokoll erfolgreich erstellt',
  successMessages_workshopInbound_createWorkshopInboundServices: 'Services erfolgreich erstellt',
  successMessages_workshopInbound_sendWorkshopInboundServiceQuotation: 'Angebot erfolgreich gesendet',
  successMessages_workshopInbound_updateExternalWorkshop:
    'Informationen zu externer Werkstatt erfolgreich aktualisiert',
  successMessages_workshopInbound_updateWorkshopInboundActivityStatus: 'Aktivitätsstatus erfolgreich aktualisiert',
  successMessages_workshopInbound_updateWorkshopInboundReport: 'Protokoll erfolgreich aktualisiert',
  successMessages_workshopInbound_updateWorkshopInboundWatch: 'Ersatzteilinformationen erfolgreich aktualisiert',
  successMessages_workshopPrices_createWorkshopPrice: 'Eintrag {entry} erfolgreich erstellt',
  successMessages_workshopPrices_createWorkshopService: 'Service {name} mit Schlüssel {key} erfolgreich erstellt',
  successMessages_workshopPrices_deleteWorkshopPrice: 'Eintrag {entry} erfolgreich gelöscht',
  successMessages_workshopPrices_deleteWorkshopService: 'Service {service} erfolgreich gelöscht',
  successMessages_workshopPrices_updateWorkshopPrice: 'Eintrag {entry} erfolgreich aktualisiert',
  successMessages_workshopPrices_updateWorkshopService: 'Service {service} erfolgreich aktualisiert',
  tracking_comments_actions_create: 'Neuen Kommentar erstellen',
  tracking_comments_createModal_title: 'Neuer Kommentar',
  tracking_comments_updateModal_title: 'Kommentar aktualisieren',
  tracking_details_purchases_title: 'Details zur Bestellung {reference}',
  tracking_details_sales_title: 'Details für Kundenauftrag {reference}',
  tracking_details_transfers_title: 'Details zu Transferauftrag {reference}',
  tracking_downloadReportsModal_title:
    'Bericht über {type, select, sales {Kundenaufträge} purchases {Einkaufsbestellungen} transfer_orders {Interne Transfers} other {}} Sendungsverfolgung herunterladen',
  tracking_labels_carrier: 'Spediteur',
  tracking_labels_checkpointTime: 'Checkpoint - Zeit',
  tracking_labels_comments: 'Kommentare ({count})',
  tracking_labels_destination: 'Zielort',
  tracking_labels_destinationPostalCode: 'PLZ Zielort',
  tracking_labels_expectedDeliveryDate: 'Voraussichtliches Lieferdatum',
  tracking_labels_invoiceId: 'Rechnungsnummer',
  tracking_labels_origin: 'Herkunft',
  tracking_labels_paymentMethod: 'Zahlungsmethode',
  tracking_labels_purchaseOrderId: 'Auftragsnummer',
  tracking_labels_shipmentId: 'Sendungsnummer',
  tracking_labels_startDate: 'Startdatum',
  tracking_labels_statusSubtag: 'Status Subtag',
  tracking_labels_trackingNumber: 'Sendungsverfolgungsnr.',
  tracking_labels_transferOrderId: 'Auftragsnummer',
  tracking_tabs_purchases: 'Einkaufsbestellungen',
  tracking_tabs_sales: 'Kundenaufträge',
  tracking_tabs_transfers: 'Interne Transfers',
  workshop_booking_bookItem: 'Artikel buchen',
  workshop_bookingHistory_labels_endTime: 'Endzeit',
  workshop_bookingHistory_labels_inInventory: 'Im Inventar',
  workshop_bookingHistory_labels_locationArea: 'Standortbereich',
  workshop_bookingHistory_labels_locationName: 'Standortname',
  workshop_bookingHistory_title: 'Buchungshistorie',
  workshop_inventory_actions_startActivity: 'Aktivität "{activity}" starten',
  workshop_inventory_confirm_item_creation:
    'Bitte bestätigen Sie die Erstellung eines Werkstattartikels für die Seriennummer {serialNumber}',
  workshop_inventory_createItem: 'Neuen Werkstattartikel erstellen',
  workshop_inventory_labels_activities: 'Aktivitäten',
  workshop_inventory_labels_lastActivity: 'Letzte Aktivität',
  workshop_inventory_labels_location: 'Standort',
  workshop_inventory_labels_ongoingProcesses: 'Laufende Prozesse',
  workshop_inventory_labels_productReference: 'Produktreferenz',
  workshop_inventory_model: 'Modell',
  workshop_inventory_updateItem: 'Artikel aktualisieren',
  workshop_labels_activity: 'Aktivität',
  workshop_labels_assignee: 'Zugewiesene Person',
  workshop_labels_lastTimestamp: 'Letzter Zeitstempel',
  workshop_labels_quotationSent: 'Angebot versendet',
  workshop_labels_service: 'Service',
  workshop_locations_add: 'Neuen Standort hinzufügen',
  workshop_locations_labels_inInventory: 'Im Inventar',
  workshop_locations_labels_isActive: 'Ist aktiv',
  workshop_locations_labels_locationArea: 'Bereich',
  workshop_locations_labels_locationCode: 'Standort Code',
  workshop_locations_labels_locationName: 'Name des Standorts',
  workshop_locations_update: 'Ort aktualisieren',
  workshop_processes_createProcess: 'Neuen Prozess anlegen',
  workshop_processes_labels_elapsedTime: 'Laufzeit',
  workshop_processes_labels_elapsedTimeHint: 'Format: ss, mm:ss oder hh:mm:ss',
  workshop_processes_title: 'Werkstattprozesse',
  workshop_processes_updateProcess: 'Prozess aktualisieren',
  workshop_report_labels_questions_actualReference: 'Tatsächliche Referenz',
  workshop_report_labels_questions_amplitudeCrownDown: 'Amplitude Krone unten(°)',
  workshop_report_labels_questions_amplitudeDialUp: 'Amplitude Zifferblatt oben (°)',
  workshop_report_labels_questions_authenticity: 'Ist die Uhr echt?',
  workshop_report_labels_questions_bracelet: 'Armband',
  workshop_report_labels_questions_braceletLength: 'Armband Länge(mm)',
  workshop_report_labels_questions_braceletMaterial: 'Material des Armbands',
  workshop_report_labels_questions_braceletSupply: 'Armband Einkauf (nur Rolex)',
  workshop_report_labels_questions_caliber: 'Kaliber',
  workshop_report_labels_questions_caseMaterial: 'Gehäuse-Material',
  workshop_report_labels_questions_caseShape: 'Gehäuseform',
  workshop_report_labels_questions_caseSize: 'Gehäusegröße (mm)',
  workshop_report_labels_questions_comment: 'Kommentar',
  workshop_report_labels_questions_crystalMaterial: 'Material des Uhrglases',
  workshop_report_labels_questions_currentCondition: 'Aktueller Zustand',
  workshop_report_labels_questions_dialColor: 'Zifferblattfarbe',
  workshop_report_labels_questions_indexType: 'Index Typ',
  workshop_report_labels_questions_internalService: 'Interner Service',
  workshop_report_labels_questions_manufacturerSerialNumber: 'Seriennummer des Herstellers',
  workshop_report_labels_questions_movementType: 'Uhrwerk Typ',
  workshop_report_labels_questions_polishedCondition: 'Polierter Zustand',
  workshop_report_labels_questions_repairable: 'Repairable',
  workshop_report_labels_questions_serviceMandatory: 'Welcher Service ist erforderlich?',
  workshop_report_labels_questions_serviceNeeded: 'Service erforderlich',
  workshop_report_labels_questions_sparePartsNeeded: 'Benötigte Ersatzteile',
  workshop_report_labels_questions_warrantyDate: 'Garantiedatum',
  workshop_report_labels_questions_watchType: 'Uhrentyp',
  workshop_reports_allActivity: 'Alle Aktivitätsaufzeichnungen',
  workshop_reports_areaOverview: 'Bereichsübersicht',
  workshop_reports_dailyActivity: 'tägliche Aktivitäten',
  workshop_reports_employeeActivity: 'Mitarbeiteraktivitäten',
  workshop_reports_labels_employee: 'Mitarbeiter',
  workshop_reports_labels_scannedItems: 'Eindeutige Scans',
  workshop_reports_labels_scans: 'Scans',
  workshop_reports_labels_showAllItems: 'Auch Artikel anzeigen, die nicht im Inventar sind',
  workshop_reports_labels_uncompletedCount: 'Unabgeschlossen',
  workshop_reworkReasons_amplitude_out_of_range: 'Amplitude außerhalb des Bereichs',
  workshop_reworkReasons_assembled_wrong: 'Falsch zusammengebaut',
  workshop_reworkReasons_date_setting_wrong: 'Datumseinstellung falsch',
  workshop_reworkReasons_dirty: 'Verschmutzt',
  workshop_reworkReasons_hand_setting_wrong: 'Zeigereinstellung falsch',
  workshop_reworkReasons_not_running: 'Läuft nicht',
  workshop_reworkReasons_not_waterproof: 'Nicht wasserdicht',
  workshop_reworkReasons_outer_parts_defective: 'Äußere Teile defekt',
  workshop_reworkReasons_scratched_after_assembling: 'Nach dem Montieren zerkratzt',
  workshop_serviceActivityTypes_assembling: 'Montage',
  workshop_serviceActivityTypes_authenticate: 'Authentifizieren',
  workshop_serviceActivityTypes_cleaning: 'Reinigung',
  workshop_serviceActivityTypes_dismantling: 'Demontage',
  workshop_serviceActivityTypes_endControl: 'Endkontrolle',
  workshop_serviceActivityTypes_externalWorkshop: 'Externe Werkstatt',
  workshop_serviceActivityTypes_fullService: 'Komplettrevision',
  workshop_serviceActivityTypes_maintenance: 'Wartung',
  workshop_serviceActivityTypes_partialService: 'Teilrevision',
  workshop_serviceActivityTypes_polishing: 'Politur',
  workshop_serviceActivityTypes_rework: 'Überarbeitung',
  workshopInbound_actions_createReport: 'Protokoll erstellen',
  workshopInbound_actions_createRework: 'Überarbeitung notwendig',
  workshopInbound_actions_createServices: 'Services erstellen',
  workshopInbound_actions_saveReportStopActivity: 'Speichern & Aktivität beenden',
  workshopInbound_actions_sendQuotation: 'Kostenvoranschlag senden',
  workshopInbound_actions_sendQuotation_confirmation:
    'Bitte bestätigen Sie, dass Sie das Angebot an das ERP-System senden möchten',
  workshopInbound_actions_updateExternalWorkshop: 'Informationen zu externer Werkstatt aktualisieren ',
  workshopInbound_actions_updateSpareParts: 'Ersatzteilinformationen aktualisieren',
  workshopInbound_details_cardTitles_activity: 'Details zu Aktivität {reference}',
  workshopInbound_details_cardTitles_item: 'Artikel',
  workshopInbound_labels_activities: 'Aktivitäten',
  workshopInbound_labels_assignee: 'Zugewiesene Person',
  workshopInbound_labels_backlog: 'Backlog',
  workshopInbound_labels_id: 'Artikelnummer',
  workshopInbound_labels_itemOverview_watches_completed: 'Fertiggestellte Uhren',
  workshopInbound_labels_itemOverview_watches_received: 'Erhaltene Uhren',
  workshopInbound_labels_orderType: 'Auftragstyp',
  workshopInbound_labels_receivedAt: 'Kostenvoranschlag erhalten am',
  workshopInbound_labels_reworkReasons: 'Gründe zur Überarbeitung',
  workshopInbound_labels_services: 'Services',
  workshopInbound_labels_serviceStatus: 'Service-Status',
  workshopInbound_labels_spareParts: 'Ersatzteile',
  workshopInbound_labels_sparePartsStatus: 'Status Ersatzteile',
  workshopInbound_labels_usedGoods: 'Gebrauchtware',
  workshopInbound_labels_vendorId: 'Verkäufer-Id',
  workshopInbound_labels_vendorName: 'Name des Verkäufers',
  workshopInbound_maintenanceMode: 'Wartungsmodus',
  workshopInbound_report_tabs_activity: 'Nach Aktivität',
  workshopInbound_report_tabs_assignee: 'Nach zugewiesener Person',
  workshopInbound_report_tabs_item: 'Artikel - Übersicht',
  workshopInbound_report_title: 'Protokoll',
  workshopInbound_reworkModal_title: 'Aktivität "Überarbeitung" erstellen',
  workshopInbound_services_createModal_title: 'Services für {item} erstellen',
  workshopInbound_services_updateModal_title: 'Service {reference} aktualisieren',
  workshopPrices_labels_active: 'Aktiv',
  workshopPrices_labels_brandSlug: 'Marken-Slug',
  workshopPrices_labels_inactiveServices: 'Inaktive Services',
  workshopPrices_labels_key: 'Schlüssel',
  workshopPrices_labels_modelSlug: 'Modell-Slug',
  workshopPrices_labels_name: 'Name',
  workshopPrices_prices_actions_create: 'Neuen Eintrag anlegen',
  workshopPrices_prices_createModal_description:
    'Um einen neuen Eintrag hinzuzufügen, füllen Sie bitte die untenstehenden Felder aus. Bitte beachten Sie, dass Sie erst dann ein Modell auswählen können, wenn Sie eine Marke aus dem Dropdown-Menü ausgewählt haben. Wenn es noch keinen Eintrag für die ausgewählte Marke gibt, müssen Sie diesen erst erstellen.',
  workshopPrices_prices_createModal_title: 'Neuer Eintrag',
  workshopPrices_prices_individualOffer: 'Individuelles Angebot',
  workshopPrices_prices_updateModal_description:
    'Um die Preise für diesen Eintrag zu aktualisieren, passen Sie bitte die untenstehenden Felder an.',
  workshopPrices_prices_updateModal_title:
    '{fallback, select, true {Standardeintrag} other {Eintrag "{entry}"}} aktualisieren',
  workshopPrices_services_actions_create: 'Neuen Service erstellen',
  workshopPrices_services_createModal_description:
    'Um einen neuen Service hinzuzufügen, füllen Sie bitte die untenstehenden Felder aus.',
  workshopPrices_services_createModal_title: 'Neuer Service',
  workshopPrices_services_deleteModal_title: 'Service "{service}" löschen',
  workshopPrices_services_toggleModal_disableWarningText: 'Dieser Service wird nicht mehr im Webshop angezeigt.',
  workshopPrices_services_toggleModal_title:
    'Service "{service}" {active, select, true {deaktivieren} other {aktivieren}}?',
  workshopPrices_services_updateModal_description:
    'Um diesen Service zu aktualisieren, ändern Sie bitte die unten stehenden Werte wie erforderlich. Bitte beachten Sie, dass der Schlüssel im Webshop verwendet wird, um den Service zu identifizieren.',
  workshopPrices_services_updateModal_title: 'Service "{service}" bearbeiten',
  workshopPrices_tabs_algoliaBrands: 'Algolia Marken',
  workshopPrices_tabs_brands: 'Marken',
  workshopPrices_tabs_priceList: 'Preisliste',
  workshopPrices_tabs_services: 'Services',
};

export default deMessages;
