import { CardContent, Stack, Tooltip, Typography } from '@mui/material';
import * as React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { FormattedMessage } from 'react-intl';
import { TrackingItem } from '../../../../state/ducks/tracking/types';
import { getAlpha2FromAlpha3, getCountryNameFromAlpha2 } from '../../../../utils/countryUtils';
import { formatUTCToLocal } from '../../../../utils/datetime';
import TrackingLink, { getCarrierName } from '../../../components/links/TrackingLink';

type Props = {
  trackingItem: TrackingItem;
  excludedItems?: string[];
};

const CARD_CONTENT_ITEMS = [
  {
    id: 'trackingNumber',
    label: <FormattedMessage id='tracking_labels_trackingNumber' defaultMessage='Tracking Number' />,
    value: (trackingItem: TrackingItem) => (
      <TrackingLink trackingNumber={trackingItem.tracking?.id} carrier={trackingItem.tracking?.carrier} />
    ),
  },
  {
    id: 'carrier',
    label: <FormattedMessage id='tracking_labels_carrier' defaultMessage='Carrier' />,
    value: (trackingItem: TrackingItem) => getCarrierName(trackingItem.tracking.carrier),
  },
  {
    id: 'originCountryCode',
    label: <FormattedMessage id='tracking_labels_origin' defaultMessage='Origin' />,
    value: (trackingItem: TrackingItem) => (
      <Tooltip title={getCountryNameFromAlpha2(trackingItem.tracking.originCountryCode) || ''} arrow>
        <div>
          <ReactCountryFlag
            countryCode={getAlpha2FromAlpha3(trackingItem.tracking.originCountryCode)}
            style={{ fontSize: '20px', lineHeight: '1em' }}
          />
        </div>
      </Tooltip>
    ),
    standaloneElement: true,
  },
  {
    id: 'destCountryCode',
    label: <FormattedMessage id='tracking_labels_destination' defaultMessage='Destination' />,
    value: (trackingItem: TrackingItem) => (
      <Tooltip title={getCountryNameFromAlpha2(trackingItem.tracking.destCountryCode) || ''} arrow>
        <div>
          <ReactCountryFlag
            countryCode={getAlpha2FromAlpha3(trackingItem.tracking.destCountryCode)}
            style={{ fontSize: '20px', lineHeight: '1em' }}
          />
        </div>
      </Tooltip>
    ),
    standaloneElement: true,
  },
  {
    id: 'destPostalCode',
    label: <FormattedMessage id='tracking_labels_destinationPostalCode' defaultMessage='Dest. Postal Code' />,
    value: (trackingItem: TrackingItem) => trackingItem.tracking?.destPostalCode,
  },
  {
    id: 'expectedDelivery',
    label: <FormattedMessage id='tracking_labels_expectedDeliveryDate' defaultMessage='Expected Delivery Date' />,
    value: (trackingItem: TrackingItem) => formatUTCToLocal(trackingItem.tracking?.expectedDelivery),
  },
  {
    id: 'createdAt',
    label: <FormattedMessage id='common_labels_createdAt' defaultMessage='Created At' />,
    value: (trackingItem: TrackingItem) => formatUTCToLocal(trackingItem.tracking?.createdAt),
  },
  {
    id: 'shipmentId',
    label: <FormattedMessage id='tracking_labels_shipmentId' defaultMessage='Shipment ID' />,
    value: (trackingItem: TrackingItem) => trackingItem.shipmentId,
  },
  {
    id: 'invoiceId',
    label: <FormattedMessage id='tracking_labels_invoiceId' defaultMessage='Invoice ID' />,
    value: (trackingItem: TrackingItem) => trackingItem.invoiceId,
  },
  {
    id: 'paymentMethod',
    label: <FormattedMessage id='tracking_labels_paymentMethod' defaultMessage='Payment Method' />,
    value: (trackingItem: TrackingItem) => trackingItem.paymentMethod,
  },
  {
    id: 'inventoryId',
    label: <FormattedMessage id='common_labels_serialNumber' defaultMessage='Inventory ID' />,
    value: (trackingItem: TrackingItem) => trackingItem.inventoryId,
  },
];

const TrackingDetailsCardContent: React.FC<Props> = ({ trackingItem, excludedItems = [] }) => {
  const filteredItems = CARD_CONTENT_ITEMS.filter((item) => !excludedItems?.includes(item.id));
  const stacks = [
    filteredItems.slice(0, Math.ceil(filteredItems.length / 2)),
    filteredItems.slice(Math.ceil(filteredItems.length / 2)),
  ];

  return (
    <CardContent>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={4}>
        {stacks.map((stack, index) => (
          <Stack key={index}>
            {stack.map((item) => (
              <Stack key={item.id} direction='row' spacing={1}>
                <Typography sx={{ fontWeight: 'bold', width: '160px' }}>{item.label}:</Typography>
                {item.standaloneElement ? (
                  item.value(trackingItem)
                ) : (
                  <Typography>{item.value(trackingItem)}</Typography>
                )}
              </Stack>
            ))}
          </Stack>
        ))}
      </Stack>
    </CardContent>
  );
};

export default TrackingDetailsCardContent;
