import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../../state';
import { createWorkshopItemAction } from '../../../../state/ducks/workshop/actions';

type Props = {
  workshopItemReference: string;
  isOpen: boolean;
  onClose: () => void;
};

const CreateWorkshopItemModal: React.FC<Props> = ({ workshopItemReference, isOpen, onClose }) => {
  const dispatch = useAppDispatch();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage
          id='workshop_inventory_confirm_item_creation'
          defaultMessage='Please confirm to create a workshop item for serial number {serialNumber}'
          values={{ serialNumber: workshopItemReference }}
        />
      </DialogTitle>
      <DialogActions>
        <Button
          color='success'
          onClick={() => {
            dispatch(
              createWorkshopItemAction({
                serialNumber: workshopItemReference,
              }),
            ).then((response) => {
              if (response.payload) {
                onClose();
              }
            });
          }}>
          <FormattedMessage id='common_ok' defaultMessage='Ok' />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateWorkshopItemModal;
