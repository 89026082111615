import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Stack,
  TextField,
} from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { requestSelector } from '../../../../selectors/request';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { createTrackingCommentAction, updateTrackingCommentAction } from '../../../../state/ducks/tracking/actions';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  trackingId: string;
  comment?: any;
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
};

const initialFormData = {
  comment: '',
};

const CreateUpdateTrackingCommentModal: React.FC<Props> = ({
  trackingId,
  comment,
  onSuccess,
  handleToggleModal,
  show,
}) => {
  const dispatch = useAppDispatch();
  const updateRequest = useAppSelector((state) => requestSelector(state, updateTrackingCommentAction.typePrefix));
  const createRequest = useAppSelector((state) => requestSelector(state, createTrackingCommentAction.typePrefix));

  const [showUpdateSnackbar, setShowUpdateSnackbar] = useState(false);
  const [showCreateSnackbar, setShowCreateSnackbar] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [isFormValid, setIsFormValid] = useState(false);

  const clearForm = () => {
    setFormData(initialFormData);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const submitForm = () => {
    if (trackingId && comment?.id) {
      dispatch(
        updateTrackingCommentAction({
          reference: trackingId,
          commentId: comment.id,
          comment: formData.comment,
        }),
      ).then(() => {
        setShowUpdateSnackbar(true);
        clearForm();
        handleToggleModal();
        onSuccess();
      });
    } else {
      dispatch(
        createTrackingCommentAction({
          reference: trackingId,
          comment: formData.comment,
        }),
      ).then(() => {
        setShowCreateSnackbar(true);
        clearForm();
        handleToggleModal();
        onSuccess();
      });
    }
  };

  useEffect(() => {
    if (comment?.id) {
      setFormData({ comment: comment.comment });
    } else {
      clearForm();
    }
  }, [show]);

  useEffect(() => {
    if (formData.comment && formData.comment !== comment?.comment) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formData]);

  return (
    <>
      {showUpdateSnackbar && <ActionStatusSnackbar actionName={updateTrackingCommentAction.typePrefix} />}
      {showCreateSnackbar && <ActionStatusSnackbar actionName={createTrackingCommentAction.typePrefix} />}
      <Dialog open={show} onClose={handleToggleModal}>
        <DialogTitle>
          {comment ? (
            <FormattedMessage id='tracking_comments_updateModal_title' defaultMessage='Update Comment' />
          ) : (
            <FormattedMessage id='tracking_comments_createModal_title' defaultMessage='New Comment' />
          )}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              margin='dense'
              id='comment'
              name='comment'
              value={formData.comment}
              onChange={handleChange}
              label={<FormattedMessage id='common_labels_comment' defaultMessage='Comment' />}
              fullWidth
              multiline
              minRows={3}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleModal} color='error'>
            <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
          </Button>
          <Button onClick={submitForm} disabled={!isFormValid} color='success'>
            <FormattedMessage id='common_save' defaultMessage='Save' />
          </Button>
        </DialogActions>
        {((comment && updateRequest?.status === 'pending') || createRequest?.status === 'pending') && (
          <LinearProgress color='primary' />
        )}
      </Dialog>
    </>
  );
};

export default CreateUpdateTrackingCommentModal;
