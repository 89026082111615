import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Button, FormControlLabel, Radio, RadioGroup, Stack, Switch, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { userStateSelector } from '../../../../selectors/user';
import { useAppSelector } from '../../../../state';
import CreateDiscountCodeModal from './CreateDiscountCodeModal';
import styles from './DiscountsTableToolbar.module.scss';

type Props = {
  onSuccess: () => void;
  clearSearch: () => void;
  onSearchChange: () => void;
  searchText: string;
  discountCreator: string;
  onCreatorFilterChange: () => void;
  hideBatchDiscounts: boolean;
  onHideBatchDiscountsChange: () => void;
  userCanCreateDiscounts: boolean;
};

const DiscountsTableToolbar: React.FC<Props> = ({
  onSuccess,
  searchText,
  onSearchChange,
  clearSearch,
  discountCreator,
  onCreatorFilterChange,
  hideBatchDiscounts,
  onHideBatchDiscountsChange,
  userCanCreateDiscounts,
}) => {
  const userState = useAppSelector(userStateSelector);
  const intl = useIntl();

  return (
    <div className={styles.wrapper}>
      <Stack spacing={0.5} direction='row'>
        <RadioGroup defaultValue='' value={discountCreator} row={true} onChange={onCreatorFilterChange}>
          <FormControlLabel
            value=''
            control={<Radio />}
            label={<FormattedMessage id='common_all' defaultMessage='All' />}
          />
          <FormControlLabel
            value={userState.currentUser.username}
            control={<Radio />}
            label={<FormattedMessage id='productDiscounts_types_myDiscounts' defaultMessage='My Discounts' />}
          />
        </RadioGroup>
        <FormControlLabel
          control={
            <Switch
              checked={hideBatchDiscounts}
              onChange={onHideBatchDiscountsChange}
              inputProps={{ 'aria-label': 'controlled' }}
              name={intl.formatMessage({
                id: 'productDiscounts_filter_hideBatchDiscounts',
                defaultMessage: 'Hide Batch Discounts',
              })}
            />
          }
          label={
            <FormattedMessage id='productDiscounts_filter_hideBatchDiscounts' defaultMessage='Hide Batch Discounts' />
          }
        />
      </Stack>
      {userCanCreateDiscounts && <CreateDiscountCodeModal onSuccess={onSuccess} />}

      <TextField
        sx={{ minWidth: '300px' }}
        variant='standard'
        value={searchText}
        onChange={onSearchChange}
        placeholder={`${intl.formatMessage({
          id: 'productDiscounts_searchPlaceholder',
          defaultMessage: 'Search for code / groups',
        })}...`}
        InputProps={{
          startAdornment: <SearchIcon fontSize='small' />,
          endAdornment: (
            <IconButton
              title={intl.formatMessage({ id: 'common_clear', defaultMessage: 'Clear' })}
              aria-label='Clear'
              size='small'
              style={{ visibility: searchText ? 'visible' : 'hidden' }}
              onClick={clearSearch}>
              <ClearIcon fontSize='small' />
            </IconButton>
          ),
        }}
      />
    </div>
  );
};

export default DiscountsTableToolbar;
