import { WorkshopActivities, WorkshopReworkReasons, WorkshopStatus } from '../../constants/workshop';
import { WorkshopActivityReportFormData } from '../../constants/workshopReport';
import { APIRoute, createPath } from '../../routing';
import {
  WorkshopInboundExternalWorkshopCurrency,
  WorkshopInboundWatchService,
} from '../../state/ducks/workshopInbound/types';
import { get, patch, post } from '../../utils/httpUtils';

export const getWorkshopInboundRequests = (params: URLSearchParams) => {
  return get(APIRoute.WORKSHOP_INBOUND_REQUESTS, params);
};

export const getWorkshopInboundWatches = (params: URLSearchParams) => {
  return get(APIRoute.WORKSHOP_INBOUND_WATCHES, params);
};

export const getWorkshopInboundWatch = (reference: string) => {
  const url = createPath({
    path: APIRoute.WORKSHOP_INBOUND_WATCH,
    params: { reference },
  });
  return get(url);
};

export type UpdateWorkshopInboundWatchParams = {
  item_number?: string;
  variant_code?: string;
  description?: string;
  brand?: string;
  model?: string;
  submodel?: string;
  reference?: string;
  dial_color?: string;
  bracelet_material?: string;
  box?: boolean;
  certificate?: boolean;
  condition?: string;
  year?: number;
  quantity?: number;
  unit_price?: number;
  priority?: boolean;
};

export const updateWorkshopInboundWatch = (reference: string, requestBody: UpdateWorkshopInboundWatchParams) => {
  const url = createPath({
    path: APIRoute.WORKSHOP_INBOUND_WATCH,
    params: { reference },
  });
  return patch(url, requestBody);
};

export type UpdateWorkshopInboundWatchSparePartsParams = {
  spare_parts_status: string;
  spare_parts: string;
};

export const updateWorkshopInboundWatchSpareParts = (
  reference: string,
  requestBody: UpdateWorkshopInboundWatchSparePartsParams,
) => {
  const url = createPath({
    path: APIRoute.WORKSHOP_INBOUND_SPARE_PARTS,
    params: { reference },
  });
  return patch(url, requestBody);
};

export const getWorkshopInboundActivities = (params: URLSearchParams) => {
  return get(APIRoute.WORKSHOP_INBOUND_ACTIVITIES, params);
};

export const getMaintenanceActivityStatus = (params: any) => {
  return get(APIRoute.WORKSHOP_INBOUND_MAINTENANCE_ACTIVITY_STATUS, params);
};

export const getWorkshopInboundActivity = (reference: string) => {
  const url = createPath({
    path: APIRoute.WORKSHOP_INBOUND_ACTIVITY,
    params: { reference },
  });
  return get(url);
};

export type CreateWorkshopInboundActivityParams = {
  service_id: string;
  watch_id?: string;
  activity_type: WorkshopActivities;
  status: WorkshopStatus;
  rework_reasons?: WorkshopReworkReasons[];
};

export const createWorkshopInboundActivity = (requestBody: CreateWorkshopInboundActivityParams) => {
  return post(APIRoute.WORKSHOP_INBOUND_ACTIVITIES, requestBody);
};

export type UpdateWorkshopInboundActivityStatusParams = {
  status?: string;
  assignee?: string;
  updated_by: string;
};

export const updateWorkshopInboundActivity = (
  reference: string,
  requestBody: UpdateWorkshopInboundActivityStatusParams,
) => {
  const url = createPath({
    path: APIRoute.WORKSHOP_INBOUND_ACTIVITY,
    params: { reference },
  });
  return patch(url, requestBody);
};

export const getWorkshopInboundReportFields = () => {
  return get(APIRoute.WORKSHOP_INBOUND_ACTIVITY_REPORT_FIELDS);
};

export const getWorkshopInboundActivityReport = (reference: string) => {
  const url = createPath({
    path: APIRoute.WORKSHOP_INBOUND_ACTIVITY_REPORT,
    params: { reference },
  });
  return get(url);
};

export type CreateWorkshopInboundReportParams = WorkshopActivityReportFormData & {
  activity_id: number;
};

export const createWorkshopInboundReport = (requestBody: CreateWorkshopInboundReportParams) => {
  return post(APIRoute.WORKSHOP_INBOUND_ACTIVITY_REPORTS, requestBody);
};

export type UpdateWorkshopInboundReportParams = WorkshopActivityReportFormData & {
  reportId: number;
};

export const updateWorkshopInboundReport = (requestBody: UpdateWorkshopInboundReportParams) => {
  const { reportId, ...rest } = requestBody;
  const url = createPath({
    path: APIRoute.WORKSHOP_INBOUND_ACTIVITY_REPORTS_DETAILS,
    params: { reference: reportId.toString() },
  });
  return patch(url, rest);
};

export type WorkshopInboundService = Pick<
  WorkshopInboundWatchService,
  'service_type' | 'is_optional' | 'price' | 'comment'
>;

export const createWorkshopInboundServices = (reference: string, requestBody: WorkshopInboundService[]) => {
  const url = createPath({
    path: APIRoute.WORKSHOP_INBOUND_WATCH_SERVICES,
    params: { reference },
  });
  return post(url, requestBody);
};

export const updateWorkshopInboundService = (
  reference: string,
  requestBody: Omit<WorkshopInboundService, 'service_type'>,
) => {
  const url = createPath({
    path: APIRoute.WORKSHOP_INBOUND_SERVICE,
    params: { reference },
  });
  return patch(url, requestBody);
};

export const sendWorkshopInboundServiceQuotation = (reference: string) => {
  return post(APIRoute.WORKSHOP_INBOUND_SERVICE_QUOTATION, { service_ids: [reference] });
};

export const getWorkshopInboundReportActivity = (params: URLSearchParams) => {
  return get(APIRoute.WORKSHOP_INBOUND_REPORTS_ACTIVITY, params);
};

export const getWorkshopInboundReportItemOverview = (params: URLSearchParams) => {
  return get(APIRoute.WORKSHOP_INBOUND_REPORTS_ITEM_OVERVIEW, params);
};

export type UpdateWorkshopInboundExternalWorkshopParams = {
  received_date?: string;
  currency?: WorkshopInboundExternalWorkshopCurrency;
  amount?: number;
  updated_by: string;
};

export const updateExternalWorkshop = (reference: string, requestBody: UpdateWorkshopInboundExternalWorkshopParams) => {
  const path = createPath({
    path: APIRoute.WORKSHOP_INBOUND_EXTERNAL_WORKSHOP_DETAILS,
    params: { reference },
  });
  return patch(path, requestBody);
};
