import { DeleteForever } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { requestSelector } from '../../../selectors/request';
import { useAppDispatch, useAppSelector } from '../../../state';
import { deletePricingConfigEntryAction, getPricingConfigAction } from '../../../state/ducks/pricing/actions';
import { MarginConfigItem } from '../../../state/ducks/pricing/types';
import ActionStatusSnackbar from '../snackbars/ActionStatusSnackbar';
import ButtonGroupIconButton from './ButtonGroupIconButton';

type Props = {
  item: MarginConfigItem;
};

const DeleteMarginConfigItemButton: React.FC<Props> = ({ item }) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const request = useAppSelector((state) => requestSelector(state, deletePricingConfigEntryAction.typePrefix));

  const deleteItem = () => {
    dispatch(deletePricingConfigEntryAction(item?.id?.toString())).then((response) => {
      setShowSnackbar(true);
      if (response.type === deletePricingConfigEntryAction.fulfilled.type) {
        setShowConfirmationModal(false);
        const urlSearchParams = new URLSearchParams();
        dispatch(getPricingConfigAction(urlSearchParams));
      }
    });
  };

  return (
    <>
      {showSnackbar && (
        <ActionStatusSnackbar
          actionName={deletePricingConfigEntryAction.typePrefix}
          config={{ messageValues: { reference: item.id } }}
        />
      )}
      <Dialog open={showConfirmationModal} onClose={() => setShowConfirmationModal(false)}>
        <DialogTitle>
          <FormattedMessage
            id='common_deleteModal_title'
            defaultMessage='Delete entry "{entry}"?'
            values={{
              entry: `${item.brandOriginal || item.id}${item.modelOriginal ? ` ${item.modelOriginal}` : ''}${
                item.referenceOriginal ? ` ${item.referenceOriginal}` : ''
              }`,
            }}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontWeight: 'medium' }}>
            <FormattedMessage id='common_warning_noUndo' defaultMessage='This action cannot be undone.' />
            <FormattedMessage
              id='pricingCalculator_actions_delete_warning'
              defaultMessage='Please note that an entry can only be deleted if it is not a fallback entry for any brand or model and that brand or model has other entries.'
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowConfirmationModal(!showConfirmationModal)}
            disabled={request?.status === 'pending'}>
            <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
          </Button>
          <Button color='success' onClick={deleteItem} disabled={request?.status === 'pending'}>
            <FormattedMessage id='common_confirm' defaultMessage='Confirm' />
          </Button>
        </DialogActions>
        {request?.status === 'pending' && <LinearProgress color='primary' />}
      </Dialog>
      <ButtonGroupIconButton
        variant='contained'
        color='error'
        disabled={!item.isDeletable}
        onClick={() => setShowConfirmationModal(true)}
        title={intl.formatMessage({
          id: 'common_delete',
          defaultMessage: 'Delete',
        })}>
        <DeleteForever />
      </ButtonGroupIconButton>
    </>
  );
};

export default DeleteMarginConfigItemButton;
