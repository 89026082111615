import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { requestSelector } from '../../../../selectors/request';
import { workshopServiceStateSelector } from '../../../../selectors/workshopService';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { getWorkshopPriceAction, getWorkshopServicesAction } from '../../../../state/ducks/workshopService/actions';
import { PriceServices, Service } from '../../../../state/ducks/workshopService/types';
import { text2title } from '../../../../utils/textUtils';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';
import { WorkshopInboundWatch, WorkshopInboundWatchService } from '../../../../state/ducks/workshopInbound/types';
import {
  createWorkshopInboundServicesAction,
  updateWorkshopInboundServiceAction,
  WorkshopInboundServices,
} from '../../../../state/ducks/workshopInbound/actions';
import { slugify } from '@chronext/react-common';
import { WorkshopActivities, WorkshopServiceApprovalStatus } from '../../../../constants/workshop';

type Props = {
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
  watch?: WorkshopInboundWatch;
  service?: WorkshopInboundWatchService;
  mode?: string;
};

const initialFormData: { services: WorkshopInboundServices } = {
  services: {
    polishing: { price: '', optional: true, comment: '' },
    partialService: { price: '', optional: true, comment: '' },
    fullService: { price: '', optional: true, comment: '' },
    externalWorkshop: { price: '', optional: true, comment: '' },
  },
};

const EXTERNAL_SERVICE = {
  key: WorkshopActivities.externalWorkshop,
  name: 'External Workshop',
};
export const SERVICES = ['partialService', 'polishing', 'fullService', EXTERNAL_SERVICE.key];
const OPTIONAL = 'optional';
const PRICE = 'price';
const COMMENT = 'comment';

const renderServiceRows = (
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  services: Service[] | Pick<Service, 'key' | 'name'>[],
  formData: { services: WorkshopInboundServices },
  mode = 'create',
) => {
  return services.map((service) => {
    return (
      <TableRow key={service.key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        {mode === 'create' && service?.name && (
          <TableCell>
            <FormattedMessage id={`workshop_serviceActivityTypes_${service?.key}`} defaultMessage={service.name} />
          </TableCell>
        )}
        <TableCell>
          <TextField
            margin='dense'
            id={`${PRICE}_${service.key}`}
            name={`${PRICE}_${service.key}`}
            type='number'
            value={formData.services?.[service?.key]?.price}
            onChange={handleChange}
            label={<FormattedMessage id='common_labels_price' defaultMessage='Price' />}
            fullWidth
          />
        </TableCell>
        <TableCell>
          <Switch
            checked={formData.services?.[service?.key]?.optional}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            name={`${OPTIONAL}_${service.key}`}
          />
        </TableCell>
        <TableCell>
          <TextField
            margin='dense'
            id={`${COMMENT}_${service.key}`}
            name={`${COMMENT}_${service.key}`}
            type='text'
            value={formData.services?.[service?.key]?.comment}
            onChange={handleChange}
            label={<FormattedMessage id='common_labels_comment' defaultMessage='Comment' />}
            fullWidth
            multiline
            rows={4}
            inputProps={{ maxLength: 150 }}
          />
        </TableCell>
      </TableRow>
    );
  });
};

const CreateUpdateInboundServiceModal: React.FC<Props> = ({
  onSuccess,
  handleToggleModal,
  show,
  watch,
  service,
  mode = 'create',
}) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { services } = useAppSelector(workshopServiceStateSelector);
  const createRequest = useAppSelector((state) =>
    requestSelector(state, createWorkshopInboundServicesAction.typePrefix),
  );
  const updateRequest = useAppSelector((state) =>
    requestSelector(state, updateWorkshopInboundServiceAction.typePrefix),
  );

  const [showCreateSnackbar, setShowCreateSnackbar] = useState(false);
  const [showUpdateSnackbar, setShowUpdateSnackbar] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [isFormValid, setIsFormValid] = useState(true);

  const clearForm = () => {
    setFormData(initialFormData);
  };

  const handleClose = (clear = false) => {
    if (clear) {
      clearForm();
    }
    handleToggleModal();
  };

  const handleServicesFieldsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formServices = { ...formData.services };
    const [name, service] = event.target.name.split('_');
    switch (name) {
      case OPTIONAL:
        formServices[service][name] = event.target.checked;
        setFormData({ ...formData, services: formServices });
        break;
      case PRICE:
      case COMMENT:
        formServices[service][name] = event.target.value;
        setFormData({ ...formData, services: formServices });
    }
  };

  const submitForm = () => {
    switch (mode) {
      case 'create':
        if (watch) {
          dispatch(
            createWorkshopInboundServicesAction({
              watch,
              services: formData.services,
            }),
          ).then((response) => {
            setShowCreateSnackbar(true);
            if (response?.payload) {
              clearForm();
              handleToggleModal();
              onSuccess();
            }
          });
        }
        break;
      case 'update':
        if (service) {
          dispatch(
            updateWorkshopInboundServiceAction({
              id: service.id,
              service: formData.services[service.service_type],
            }),
          ).then((response) => {
            setShowUpdateSnackbar(true);
            if (response?.payload) {
              clearForm();
              handleToggleModal();
              onSuccess();
            }
          });
        }
    }
  };

  const setServicePriceSuggestions = (prices: PriceServices) => {
    const newServices = { ...formData.services };
    Object.keys(newServices).forEach((key) => {
      newServices[key].price = prices?.[key]?.value?.toString() || '';
    });
    setFormData({ ...formData, services: newServices });
  };

  useEffect(() => {
    if (show) {
      switch (mode) {
        case 'create':
          clearForm();
          if (watch) {
            const urlSearchParams = new URLSearchParams();
            dispatch(getWorkshopServicesAction(urlSearchParams)).then((response) => {
              if (response?.payload?.length > 0) {
                dispatch(
                  getWorkshopPriceAction({
                    brand: slugify(watch.brand),
                    model: slugify(watch.model),
                  }),
                ).then((response) => {
                  if (response?.payload) {
                    setServicePriceSuggestions(response.payload.services);
                  }
                });
              }
            });
          }
          break;
        case 'update':
          if (service) {
            const newServices = {
              [service.service_type]: {
                price: service.price?.toString() || '',
                optional: service.is_optional,
                comment: service.comment || '',
              },
            };
            setFormData({ services: newServices });
          }
          break;
      }
    }
  }, [show]);

  useEffect(() => {
    if (service) {
      const isFormValid =
        formData.services[service.service_type].price !== '' &&
        (formData.services[service.service_type].price !== service.price?.toString() ||
          formData.services[service.service_type].optional !== service.is_optional ||
          formData.services[service.service_type].comment !== service.comment);
      setIsFormValid(isFormValid);
    }
  }, [formData]);

  return (
    <>
      {showCreateSnackbar && <ActionStatusSnackbar actionName={createWorkshopInboundServicesAction.typePrefix} />}
      {showUpdateSnackbar && <ActionStatusSnackbar actionName={updateWorkshopInboundServiceAction.typePrefix} />}
      <Dialog open={show} maxWidth={mode === 'create' ? 'md' : 'sm'} fullWidth onClose={handleToggleModal}>
        <DialogTitle>
          {service && (
            <FormattedMessage
              id='workshopInbound_services_updateModal_title'
              defaultMessage='Update Service {reference}'
              values={{
                reference: `${service.id} - ${intl.formatMessage({
                  id: `workshop_serviceActivityTypes_${service.service_type}`,
                  defaultMessage: text2title(service.service_type),
                })}`,
              }}
            />
          )}
          {watch && (
            <FormattedMessage
              id='workshopInbound_services_createModal_title'
              defaultMessage='Create Services for {item}'
              values={{ item: [watch.brand, watch.model, watch.reference].join(' ') }}
            />
          )}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TableContainer component={Paper}>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    {mode === 'create' && (
                      <TableCell>
                        <FormattedMessage id='workshop_labels_service' defaultMessage='Service' />
                      </TableCell>
                    )}
                    <TableCell>
                      <FormattedMessage id='common_labels_price' defaultMessage='Price' />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id='common_optional' defaultMessage='Optional' />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id='common_labels_comment' defaultMessage='Comment' />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderServiceRows(
                    handleServicesFieldsChange,
                    mode === 'update' && service
                      ? [{ key: service.service_type, name: '' }]
                      : [...services, EXTERNAL_SERVICE].filter((s) =>
                          SERVICES.filter(
                            (key) =>
                              !watch?.services?.some(
                                (s) =>
                                  s.service_type === key &&
                                  s.approval_status !== WorkshopServiceApprovalStatus.declined,
                              ),
                          ).includes(s.key),
                        ),
                    formData,
                    mode,
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color='error'>
            <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
          </Button>
          <Button onClick={submitForm} disabled={!isFormValid} color='success'>
            <FormattedMessage id='common_save' defaultMessage='Save' />
          </Button>
        </DialogActions>
        {(createRequest?.status === 'pending' || updateRequest?.status === 'pending') && (
          <LinearProgress color='primary' />
        )}
      </Dialog>
    </>
  );
};

export default CreateUpdateInboundServiceModal;
