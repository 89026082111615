import { Button, MenuItem, Stack, TextField } from '@mui/material';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { currentUserSelector } from '../../../../selectors/user';
import {
  workshopActivitiesSelector,
  workshopItemSelector,
  workshopItemsSelector,
} from '../../../../selectors/workshop';
import { useAppDispatch, useAppSelector } from '../../../../state';
import {
  createWorkshopProcessAction,
  getWorkshopActivitiesAction,
  getWorkshopItemAction,
  getWorkshopItemsAction,
  getWorkshopProcessesAction,
} from '../../../../state/ducks/workshop/actions';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';
import CreateWorkshopItemModal from '../../workshop-inventory/components/CreateWorkshopItemModal';

const initialFormData = {
  activityReference: '',
  workshopItemReference: '',
};

const WorkshopProcessForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const currentUser = useAppSelector(currentUserSelector);
  const activities = useAppSelector(workshopActivitiesSelector);
  const workshopItems = useAppSelector(workshopItemsSelector);
  const workshopItem = useAppSelector(workshopItemSelector);
  const [formData, setFormData] = useState(initialFormData);
  const [createItemModalOpen, setCreateItemModalOpen] = useState(false);
  const [showCreateSnackbar, setShowCreateSnackbar] = useState(false);

  // Loads activity items to populate items in the select field
  useEffect(() => {
    dispatch(getWorkshopActivitiesAction());
  }, []);

  // Runs on each change in the value of the input field for serial number
  useEffect(() => {
    if (!formData.workshopItemReference) {
      return;
    }

    getWorkshopItems(formData.workshopItemReference);
  }, [formData.workshopItemReference]);

  // Close the modal if the workshop item is successfully retrieved
  useEffect(() => {
    if (!createItemModalOpen || !workshopItem) {
      return;
    }
    setCreateItemModalOpen(false);
  }, [workshopItem]);

  useEffect(() => {
    const reference = workshopItem?.reference;
    if (!reference || workshopItem) {
      return;
    }
    dispatch(getWorkshopItemAction(reference));
  }, [workshopItems]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'workshopItemReference') {
      setFormData({ ...formData, [event.target.name]: event.target.value.toUpperCase() });
    } else {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };

  const getWorkshopItems = useCallback(
    debounce((reference: string) => {
      if (!reference) {
        return;
      }
      const params = new URLSearchParams();
      params.set('serial_number', reference);
      dispatch(getWorkshopItemsAction(params));
    }, 500),
    [],
  );

  const onCreateNewProcessClick = () => {
    const { activityReference, workshopItemReference } = formData;
    dispatch(createWorkshopProcessAction({ activityReference, workshopItemReference })).then((response) => {
      setShowCreateSnackbar(true);
      if (response.payload) {
        const startTime = moment().subtract(1, 'month').startOf('month').toISOString();
        const endTime = moment().endOf('day').toISOString();
        const searchParams = new URLSearchParams({
          created_by: currentUser.username,
          offset: '0',
          limit: '1000',
          start_time: startTime,
          end_time: endTime,
        });

        dispatch(getWorkshopProcessesAction(searchParams));
      }
    });
  };

  const startMaintenance = () => {
    const activityReference = activities.find((item) => item.name === 'Maintenance')?.reference || '';
    dispatch(createWorkshopProcessAction({ activityReference, workshopItemReference: '0' })).then((response) => {
      setShowCreateSnackbar(true);
      if (response.payload) {
        const startTime = moment().subtract(1, 'month').startOf('month').toISOString();
        const endTime = moment().endOf('day').toISOString();
        const searchParams = new URLSearchParams({
          created_by: currentUser.username,
          offset: '0',
          limit: '1000',
          start_time: startTime,
          end_time: endTime,
        });

        dispatch(getWorkshopProcessesAction(searchParams));
      }
    });
  };

  const ActionButton = () => {
    let button = (
      <Button
        variant='contained'
        color='secondary'
        size='small'
        disabled={!formData.workshopItemReference || !formData.activityReference}
        onClick={onCreateNewProcessClick}
        fullWidth>
        <FormattedMessage id='workshop_processes_createProcess' defaultMessage='Create New Process' />
      </Button>
    );
    if (workshopItems.count !== 1) {
      button = (
        <Button
          variant='contained'
          color='secondary'
          size='small'
          disabled={!formData.workshopItemReference || workshopItems.count > 0}
          onClick={() => setCreateItemModalOpen(true)}
          fullWidth>
          <FormattedMessage id='workshop_inventory_createItem' defaultMessage='Create New Workshop Item' />
        </Button>
      );
    }
    return button;
  };

  return (
    <>
      {showCreateSnackbar && <ActionStatusSnackbar actionName={createWorkshopProcessAction.typePrefix} />}
      <Stack
        spacing={2}
        mb={2}
        direction={{ xs: 'column', sm: 'row' }}
        alignItems='center'
        justifyContent='space-between'>
        <Stack spacing={1} maxWidth={{ xs: '320px', sm: '400px' }} sx={{ flex: 1 }}>
          <TextField
            margin='dense'
            size='small'
            name='workshopItemReference'
            value={formData.workshopItemReference}
            onChange={handleChange}
            label={intl.formatMessage({
              id: 'common_labels_serialNumber',
              defaultMessage: 'Serial Number',
            })}
            inputProps={{ style: { textTransform: 'uppercase' } }}
          />
          <TextField
            margin='dense'
            size='small'
            name='activityReference'
            value={formData.activityReference}
            onChange={handleChange}
            label={intl.formatMessage({
              id: 'workshop_inventory_labels_activities',
              defaultMessage: 'Activities',
            })}
            select>
            {activities
              .filter((item) => item.name !== 'Maintenance')
              .map((item) => (
                <MenuItem key={item.reference} value={item.reference} disabled={!item.isActive}>
                  {item.name}
                </MenuItem>
              ))}
          </TextField>
          <ActionButton />
        </Stack>
        <Button variant='contained' color='warning' onClick={startMaintenance}>
          <FormattedMessage
            id='workshop_inventory_actions_startActivity'
            defaultMessage='Start activity "{activity}"'
            values={{ activity: 'Maintenance' }}
          />
        </Button>
      </Stack>
      <CreateWorkshopItemModal
        isOpen={createItemModalOpen}
        workshopItemReference={formData.workshopItemReference}
        onClose={() => {
          setCreateItemModalOpen(false);
          getWorkshopItems(formData.workshopItemReference.toUpperCase());
        }}
      />
    </>
  );
};

export default WorkshopProcessForm;
