import { PaletteMode } from '@mui/material';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { createAccessToken, getCurrentUser } from '../../../api/server/user';
import { UserActions } from './types';

export const getCurrentUserAction = createAsyncThunk(UserActions.getCurrentUser, async () => {
  const response = await getCurrentUser();
  return response.data;
});

export const createAccessTokenAction = createAsyncThunk(UserActions.getAccessToken, async () => {
  const response = await createAccessToken();
  return response.data;
});

export const setLanguageAction = createAction<string>(UserActions.setLanguage);

export const setColorModeAction = createAction<PaletteMode>(UserActions.setColorMode);

export const setViewAsGroupAction = createAction<string>(UserActions.setViewAsGroup);
