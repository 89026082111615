import { PaletteMode } from '@mui/material';

export enum UserActions {
  getCurrentUser = 'user/getCurrentUser',
  getAccessToken = 'user/getAccessToken',
  setLanguage = 'user/setLanguage',
  setColorMode = 'user/setColorMode',
  setViewAsGroup = 'user/setViewAsGroup',
}

export type User = {
  username: string;
  groups: string[];
  language: string;
  colorMode: PaletteMode;
  viewAsGroup?: string;
};

export type UserState = {
  currentUser: User;
  users: User[];
};
