export const COUNTRY_CODES = [
  'AE',
  'AG',
  'AI',
  'AR',
  'AT',
  'AU',
  'AW',
  'BB',
  'BE',
  'BG',
  'BH',
  'BM',
  'BN',
  'BO',
  'BQ',
  'BR',
  'BS',
  'BY',
  'BZ',
  'CA',
  'CH',
  'CK',
  'CL',
  'CN',
  'CO',
  'CR',
  'CV',
  'CW',
  'CY',
  'CZ',
  'DE',
  'DK',
  'DM',
  'EC',
  'EE',
  'EG',
  'ES',
  'FI',
  'FJ',
  'FO',
  'FR',
  'GB',
  'GD',
  'GI',
  'GL',
  'GP',
  'GR',
  'GT',
  'GU',
  'HK',
  'HN',
  'HR',
  'HU',
  'ID',
  'IE',
  'IL',
  'IN',
  'IS',
  'IT',
  'JM',
  'JO',
  'JP',
  'KH',
  'KN',
  'KR',
  'KW',
  'KY',
  'LB',
  'LC',
  'LI',
  'LK',
  'LT',
  'LU',
  'LV',
  'MA',
  'MC',
  'MD',
  'MG',
  'MH',
  'MO',
  'MP',
  'MQ',
  'MS',
  'MT',
  'MU',
  'MV',
  'MX',
  'MY',
  'NC',
  'NL',
  'NO',
  'NZ',
  'OM',
  'PA',
  'PE',
  'PF',
  'PG',
  'PH',
  'PK',
  'PL',
  'PR',
  'PT',
  'PY',
  'QA',
  'RE',
  'RO',
  'RS',
  'RU',
  'SA',
  'SC',
  'SE',
  'SG',
  'SI',
  'SK',
  'SR',
  'SV',
  'SX',
  'TC',
  'TH',
  'TT',
  'TW',
  'TZ',
  'UA',
  'US',
  'UY',
  'VA',
  'VC',
  'VE',
  'VG',
  'VI',
  'VN',
  'ZA',
];
