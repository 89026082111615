export enum PricingActions {
  getPricing = 'pricing/getPricing',
  getPricingConfig = 'pricing/getPricingConfig',
  createPricingConfigEntry = 'pricing/createPricingConfigEntry',
  updatePricingConfigEntry = 'pricing/updatePricingConfigEntry',
  deletePricingConfigEntry = 'pricing/deletePricingConfigEntry',
  getPricingConfigBrands = 'pricing/getPricingConfigBrands',
  getPricingConfigModels = 'pricing/getPricingConfigModels',
  getPricingConfigReferences = 'pricing/getPricingConfigReferences',
}

export type Pricing = {
  direct: number;
  commission: number;
  tradeIn: number;
  marketPrice: number;
  minMarketPrice: number;
  maxMarketPrice: number;
  yearsAndPrices: Record<string, number>;
};

type PricingItem = {
  brand: string;
  model: string;
  reference: string;
  dialColor: string;
  condition: string;
  papers: boolean;
  box: boolean;
  braceletType: string;
};

type ModelledData = {
  isModelled: boolean;
  mae: number;
  absoluteErrorRange: number;
  absoluteErrorPercentage: number;
};

export type CalculatorResult = {
  prices: Pricing | null;
  sampleCount: number;
  item: PricingItem | null;
  weekEndingDate: string;
  margins: Omit<Pricing, 'marketPrice'> | null;
  modelledData: ModelledData | null;
};

export type MarginTiers = {
  [key: string]: number;
};

export type MarginRanges = {
  direct: MarginTiers;
  tradeIn: MarginTiers;
  commission: MarginTiers;
};

export type MarginConfigItem = {
  id: string;
  brandOriginal: string;
  modelOriginal: string;
  referenceOriginal: string;
  marginRanges: MarginRanges;
  isDeletable: number;
};

export type MarginConfig = {
  totalCount: number;
  marginItems: MarginConfigItem[];
  brands: string[];
  models: string[];
  references: string[];
};

export type PricingState = {
  calculatorResult: CalculatorResult;
  marginConfig: MarginConfig;
};
