import { TranslateOutlined } from '@mui/icons-material';
import { Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { currentUserSelector } from '../../../../selectors/user';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { setLanguageAction } from '../../../../state/ducks/user/actions';

const LANGUAGES: { [param: string]: string } = {
  en: 'English',
  de: 'Deutsch',
};

const LanguageSwitcher: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);
  const theme = useTheme();
  const isBreakpointXS = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <Button
      className='language-switcher'
      color='inherit'
      size='small'
      variant='outlined'
      onClick={() => dispatch(setLanguageAction(currentUser.language === 'en' ? 'de' : 'en'))}
      startIcon={<TranslateOutlined />}>
      {isBreakpointXS ? currentUser.language : LANGUAGES?.[currentUser?.language]}
    </Button>
  );
};

export default LanguageSwitcher;
