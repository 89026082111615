import { FileCopyOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useIntl } from 'react-intl';
import { copyToClipboard } from '../../../utils/textUtils';

type Props = {
  text: string;
};

const CopyToClipboardButton: React.FC<Props> = ({ text }) => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <IconButton
      title={intl.formatMessage({ id: 'common_copyToClipboard', defaultMessage: 'Copy to clipboard' })}
      color='primary'
      onClick={() => copyToClipboard(text, (msg) => enqueueSnackbar(intl.formatMessage(msg), { variant: 'success' }))}>
      <FileCopyOutlined />
    </IconButton>
  );
};

export default CopyToClipboardButton;
