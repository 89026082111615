import axios, { AxiosRequestConfig } from 'axios';
import { RequestError } from '../state/ducks/request/types';

export type RequestConfig = AxiosRequestConfig;

const api = axios.create();

api.interceptors.response.use(
  (response) => response,
  (err) => {
    const customError: RequestError = {
      code: '',
      name: '',
      message: 'An unknown error occurred',
    };

    if (err?.response?.data?.errors) {
      return Promise.reject(err?.response?.data);
    }

    if (err?.response?.data?.detail) {
      const message =
        typeof err.response.data.detail === 'string' ? err.response.data.detail : err.response.data.detail[0].msg;
      customError.code = err?.response?.status || '';
      customError.name = err?.response?.data?.exception || err?.response?.statusText || '';
      customError.message = message || '';
    }

    if (err?.response?.data?.message) {
      customError.code = err?.response?.status || '';
      customError.name = err?.response?.data?.exception || err?.response?.statusText || '';
      customError.message = err?.response?.data?.message || '';
    }

    if (err?.response?.data?.context) {
      const message =
        typeof err.response.data.context === 'string' ? err.response.data.context : err.response.data.context.message;
      customError.code = err?.response?.status || '';
      customError.name = err?.response?.data?.exception || err?.response?.statusText || '';
      customError.message = message || '';
    }

    if (err?.response?.data?.error) {
      const { code, name, message } = err.response.data.error;
      customError.code = code ? code.toString() : err?.response?.status || '';
      customError.name = name || err?.response?.statusText || '';
      customError.message = message || err.response.data.error?.message || '';
    }
    return Promise.reject(customError);
  },
);

export default api;
