import { Stack } from '@mui/material';
import React from 'react';
import { APP_CONFIG } from '../../../config/app';
import { ClientRouteGroups } from '../../../routing/clientRoutes';
import { currentUserSelector, isAuthorizedSelector } from '../../../selectors/user';
import { useAppSelector } from '../../../state';
import WorkshopProcessesTable from '../../components/workshop-tables/WorkshopProcessesTable';
import WorkshopProcessCreateForm from './components/WorkshopProcessCreateForm';

const WorkshopProcesses: React.FC = () => {
  const currentUser = useAppSelector(currentUserSelector);
  const userCanEdit =
    useAppSelector((state) =>
      isAuthorizedSelector(
        state,
        ClientRouteGroups.WORKSHOP_INBOUND_ITEM.filter((g) => g.startsWith('Workshop')),
      ),
    ) || !APP_CONFIG.isProd;

  return (
    <Stack sx={{ height: '100%' }}>
      {userCanEdit && <WorkshopProcessCreateForm />}
      <WorkshopProcessesTable username={currentUser?.username} />
    </Stack>
  );
};

export default WorkshopProcesses;
