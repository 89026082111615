export enum WorkshopActions {
  getWorkshopProcesses = 'workshop/getWorkshopProcesses',
  getActivityReport = 'workshop/getActivityReport',
  getEmployeeReport = 'workshop/getEmployeeReport',
  getActivities = 'workshop/getActivities',
  getWorkshopItems = 'workshop/getWorkshopItems',
  getWorkshopItem = 'workshop/getWorkshopItem',
  createProcess = 'workshop/createProcess',
  updateProcess = 'workshop/updateProcess',
  createWorkshopItem = 'workshop/createItem',
  updateWorkshopItem = 'workshop/updateItem',
  getLocations = 'workshop/getLocations',
  createLocation = 'workshop/createLocation',
  updateLocation = 'workshop/updateLocation',
  addBooking = 'workshop/addBooking',
  getLocationsOverview = 'workshop/getLocationsOverview',
}

export type WorkshopItem = {
  reference: string;
  serialNumber: string;
  productReference: string;
  variantReference: string; // sku
  variantCode: string;
  createdAt: string;
  createdBy: string;
  processes: Process[];
  bookingHistory: BookingHistoryItem[];
};

export type Activity = {
  reference: string;
  name: string;
  multiprocessingAllowed: boolean;
  isActive: boolean;
};

export type Process = {
  reference: string;
  workshopItemReference: string;
  status: string;
  elapsedTime: number;
  isPending: boolean;
  isStarted: boolean;
  isPaused: boolean;
  isCompleted: boolean;
  createdBy: string;
  createdAt: string;
  latestTimestamp: string;
  activity: Activity;
};

export type WorkshopLocation = {
  reference: number;
  name: string;
  code: string;
  area: string;
  inInventory: boolean;
  isActive: boolean;
};

export type BookingHistoryItem = {
  reference: string;
  createdBy: string;
  bookingStart: string;
  bookingEnd: string;
  location: WorkshopLocation;
};

export type ActivityReportItem = {
  reference: string;
  name: string;
  employee: string;
  uncompletedCount: number;
  elapsedTime: number;
  scans: number;
  scannedItems: number;
  startTime: string;
  endTime: string;
};

export type EmployeeReportItem = {
  employee: string;
  scannedItems: number;
  scans: number;
  elapsedTime: number;
  startTime: string;
  endTime: string;
};

export type WorkshopReports = {
  activity: ActivityReportItem[];
  employee: EmployeeReportItem[];
};

export type ProcessesState = {
  count: number;
  items: Process[];
};

export type WorkshopItemsState = {
  count: number;
  items: WorkshopItem[];
};

export type WorkshopLocationOverviewItem = {
  locationReference: number;
  countOfItems: number;
};

export type WorkshopAreaOverviewItem = {
  area: string;
  totalCountOfItems: number;
  overview: WorkshopLocationOverviewItem[];
};

export type WorkshopState = {
  reports: WorkshopReports;
  activities: Activity[];
  processes: ProcessesState;
  workshopItems: WorkshopItemsState;
  workshopItem: WorkshopItem | null;
  workshopLocations: WorkshopLocation[];
  workshopAreaOverview: WorkshopAreaOverviewItem[];
};
