import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../../state';
import { requestVendorInformationAction } from '../../../../state/ducks/sell/actions';
import { SellRequest } from '../../../../state/ducks/sell/types';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  sellRequest: SellRequest;
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
};

const RequestVendorInformationModal: React.FC<Props> = ({ show, handleToggleModal, sellRequest, onSuccess }) => {
  const dispatch = useAppDispatch();
  const [showSnackbar, setShowSnackbar] = useState(false);

  const requestVendorInformation = () => {
    dispatch(requestVendorInformationAction(sellRequest.reference.toString())).then((response) => {
      setShowSnackbar(true);
      if (response.payload) {
        handleToggleModal();
        onSuccess();
      }
    });
  };

  return (
    <>
      {showSnackbar && <ActionStatusSnackbar actionName={requestVendorInformationAction.typePrefix} />}
      <Dialog open={show} onClose={handleToggleModal}>
        <DialogContent>
          <FormattedMessage
            id='sellRequests_requestVendorInformationModal_confirmation'
            defaultMessage='Please confirm that you want to request vendor information for this sell request. This will trigger an email to be sent to the vendor.'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleModal}>
            <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
          </Button>
          <Button color='info' onClick={requestVendorInformation}>
            <FormattedMessage id='common_confirm' defaultMessage='Confirm' />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RequestVendorInformationModal;
