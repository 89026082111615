import { Card, CardActionArea, CardContent, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { THEME_COLORS } from '@chronext/react-common';
import { currentUserSelector, isAuthorizedSelector } from '../../../../selectors/user';
import { useAppSelector } from '../../../../state';
import { Item } from '../../../components/sidebar/Sidebar';
import styles from './../Homepage.module.scss';

type Props = {
  menuItem: Item;
  categoryName?: React.ReactNode;
};

const HomepageCardItem: React.FC<Props> = ({ menuItem, categoryName }) => {
  const currentUser = useAppSelector(currentUserSelector);
  const isAuthorized = useAppSelector((state) => isAuthorizedSelector(state, menuItem.groups));
  if (!isAuthorized) return null;

  const color = menuItem.cardColor || 'green';
  const colorStyle =
    currentUser.colorMode === 'light' && THEME_COLORS?.[`${color}Light`] ? `${color}Light` : `${color}`;
  const themeStyles =
    colorStyle === 'orange'
      ? {
          color: THEME_COLORS?.[colorStyle].backgroundColor,
          backgroundColor: THEME_COLORS?.[colorStyle].color,
        }
      : THEME_COLORS?.[colorStyle];
  const category = categoryName || menuItem.categoryName;

  return (
    <Grid item xs={2} sm={4} md={4}>
      <Card className={styles.cardWrapper} variant='outlined' style={themeStyles}>
        <CardActionArea className={styles.cardLinkWrapper} component={Link} to={menuItem.href}>
          <CardContent>
            {category && <Typography variant='body2'>{category}</Typography>}
            <Stack direction='row' spacing={0.5} alignItems='center'>
              {menuItem.icon}
              <Typography fontWeight='medium' variant='h5' component='div' sx={{ mb: 1.5 }}>
                {menuItem.name}
              </Typography>
            </Stack>
            <Typography variant='body2'>{menuItem.description}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default HomepageCardItem;
