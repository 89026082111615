import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  addSkusToCategory,
  clearCategory,
  createBatchJob,
  CreateBatchJobParams,
  createCategory,
  CreateCategoryParams,
  createDiscountCode,
  CreateDiscountCodeParams,
  deleteCategory,
  deleteDiscountCode,
  downloadBatchJobDiscountCodes,
  getCategories,
  getCategoryProductCount,
  getDiscountBatchJob,
  getDiscountBatchJobs,
  getDiscountCodes,
  removeSkusFromCategory,
  setCategorySkuList,
  updateDiscountCode,
  UpdateDiscountCodeParams,
} from '../../../api/server/commercetools';
import { downloadCSV } from '../../../utils/responseUtils';
import { CommercetoolsActions } from './types';

export const getCategoriesAction = createAsyncThunk(
  CommercetoolsActions.getCategories,
  async (params: URLSearchParams, { rejectWithValue }) => {
    try {
      const response = await getCategories(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const clearCategoryAction = createAsyncThunk(
  CommercetoolsActions.clearCategory,
  async (key: string, { rejectWithValue }) => {
    try {
      const response = await clearCategory(key);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createCategoryAction = createAsyncThunk(
  CommercetoolsActions.createCategory,
  async (params: CreateCategoryParams, { rejectWithValue }) => {
    try {
      const response = await createCategory(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteCategoryAction = createAsyncThunk(
  CommercetoolsActions.deleteCategory,
  async (key: string, { rejectWithValue }) => {
    try {
      const response = await deleteCategory(key);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

type UpdateCategorySkuListActionParams = {
  key: string;
  skus: string[];
  actionType: 'add' | 'remove' | 'set';
};

export const updateCategorySkuListAction = createAsyncThunk(
  CommercetoolsActions.updateCategory,
  async (params: UpdateCategorySkuListActionParams) => {
    const responses = [];
    const chunkSize = 300;
    let i, j;
    for (i = 0, j = params.skus.length; i < j; i += chunkSize) {
      let response;
      const currentChunk = params.skus.slice(i, i + chunkSize);
      switch (params.actionType) {
        case 'add':
          response = await addSkusToCategory(params.key, currentChunk);
          break;
        case 'remove':
          response = await removeSkusFromCategory(params.key, currentChunk);
          break;
        case 'set':
          response =
            i === 0
              ? await setCategorySkuList(params.key, currentChunk)
              : (response = await addSkusToCategory(params.key, currentChunk));
          break;
      }
      responses.push(response.data);
    }
    return responses;
  },
);

export const getCategoryProductCountAction = createAsyncThunk(
  CommercetoolsActions.getCategoryProductCount,
  async (key: string, { rejectWithValue }) => {
    try {
      const response = await getCategoryProductCount(key);
      return response.data?.count;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getDiscountCodesAction = createAsyncThunk(
  CommercetoolsActions.getDiscountCodes,
  async (params: URLSearchParams, { rejectWithValue }) => {
    try {
      const response = await getDiscountCodes(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createDiscountCodeAction = createAsyncThunk(
  CommercetoolsActions.createDiscountCode,
  async (params: CreateDiscountCodeParams, { rejectWithValue }) => {
    try {
      const response = await createDiscountCode(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

type UpdateDiscountCodeActionParams = {
  code: string;
  requestBody: UpdateDiscountCodeParams;
};

export const updateDiscountCodeAction = createAsyncThunk(
  CommercetoolsActions.updateDiscountCode,
  async (params: UpdateDiscountCodeActionParams, { rejectWithValue }) => {
    try {
      const response = await updateDiscountCode(params.code, params.requestBody);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteDiscountCodesAction = createAsyncThunk(
  CommercetoolsActions.deleteDiscountCode,
  async (code: string, { rejectWithValue }) => {
    try {
      const response = await deleteDiscountCode(code);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getDiscountBatchJobsAction = createAsyncThunk(
  CommercetoolsActions.getDiscountBatchJobs,
  async (params: URLSearchParams, { rejectWithValue }) => {
    try {
      const response = await getDiscountBatchJobs(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getDiscountBatchJobAction = createAsyncThunk(
  CommercetoolsActions.getDiscountBatchJob,
  async (jobId: string, { rejectWithValue }) => {
    try {
      const response = await getDiscountBatchJob(jobId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const downloadBatchJobDiscountCodesAction = createAsyncThunk(
  CommercetoolsActions.downloadBatchJobDiscountCodes,
  async (jobId: string, { rejectWithValue }) => {
    try {
      const response = await downloadBatchJobDiscountCodes(jobId);
      downloadCSV(response.data, jobId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createBatchJobAction = createAsyncThunk(
  CommercetoolsActions.createBatchJob,
  async (params: CreateBatchJobParams, { rejectWithValue }) => {
    try {
      const response = await createBatchJob(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
