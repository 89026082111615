import { Clear, DeleteForever, FormatListBulleted, InfoOutlined } from '@mui/icons-material';
import { ButtonGroup, IconButton } from '@mui/material';
import { GridCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { commercetoolsCategoriesSelector } from '../../../selectors/commercetools';
import { useAppSelector } from '../../../state';
import { getCategoriesAction } from '../../../state/ducks/commercetools/actions';
import { formatUTCToLocal } from '../../../utils/datetime';
import ButtonGroupIconButton from '../../components/buttons/ButtonGroupIconButton';
import ActionStatusSnackbar from '../../components/snackbars/ActionStatusSnackbar';
import ServerSidePaginatedTable, {
  ServerSidePaginatedTableColDef,
} from '../../components/table/ServerSidePaginatedTable';
import CategoryDetailsModal from './components/CategoryDetailsModal';
import CategoryTableToolbar from './components/CategoryTableToolbar';
import ClearCategoryModal from './components/ClearCategoryModal';
import DeleteCategoryModal from './components/DeleteCategoryModal';
import SkuListModal from './components/SkuListModal';
import styles from './ProductCategoriesPage.module.scss';

// Column definitions for Product Categories table
const categoriesTableColumns: ServerSidePaginatedTableColDef[] = [
  {
    id: 'common_details',
    headerName: 'Details',
    field: 'details', // This field doesn't exist in the row data
    sortable: false,
    renderCell: () => {
      return (
        <IconButton aria-label='edit' size='small'>
          <InfoOutlined />
        </IconButton>
      );
    },
    width: 80,
    align: 'center',
  },
  {
    id: 'productCategories_labels_key',
    headerName: 'Key',
    field: 'key',
    sortable: false,
    flex: 1,
  },
  {
    id: 'productCategories_labels_name',
    headerName: 'Name',
    field: 'name',
    sortable: false,
    flex: 1,
    valueGetter: ({ value }: GridValueGetterParams) => value?.en,
  },
  {
    id: 'common_labels_updatedAt',
    headerName: 'Updated At',
    field: 'modifiedAt',
    sortable: false,
    valueGetter: ({ value }: GridValueGetterParams) => value && formatUTCToLocal(value),
    flex: 1,
  },
  {
    id: 'common_labels_createdAt',
    headerName: 'Created At',
    field: 'createdAt',
    sortable: false,
    valueGetter: ({ value }: GridValueGetterParams) => value && formatUTCToLocal(value),
    flex: 1,
  },
];

const ProductCategoriesPage: React.FC = () => {
  const { categories, categoriesTotal } = useAppSelector(commercetoolsCategoriesSelector);
  const [tableColumns, setTableColumns] = useState<ServerSidePaginatedTableColDef[]>(categoriesTableColumns);
  const intl = useIntl();

  // Query parameters for API requests
  const [searchParams, setSearchParams] = useState({
    search: '',
  });

  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [categoryKey, setCategoryKey] = useState('');
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showClearConfirmationModal, setShowClearConfirmationModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showSkuListModal, setShowSkuListModal] = useState(false);

  useEffect(() => {
    const actionsColumn: ServerSidePaginatedTableColDef[] = [];

    actionsColumn.push({
      id: 'common_actions',
      headerName: 'Actions',
      field: 'actions',
      width: 150,
      align: 'center',
      sortable: false,
      renderCell: (params: any) => {
        return (
          <ButtonGroup variant='text'>
            <ButtonGroupIconButton
              color='info'
              onClick={() => {
                setCategoryKey(params?.row?.key);
                setShowSkuListModal(true);
              }}
              title={intl.formatMessage(
                {
                  id: 'productCategories_actions_modify',
                  defaultMessage: 'Modify SKUs in category "{category}"',
                },
                { category: params?.row?.key },
              )}>
              <FormatListBulleted />
            </ButtonGroupIconButton>
            <ButtonGroupIconButton
              color='warning'
              onClick={() => {
                setCategoryKey(params?.row?.key);
                setShowClearConfirmationModal(true);
              }}
              title={intl.formatMessage(
                {
                  id: 'productCategories_actions_clear',
                  defaultMessage: 'Clear all SKUs from category "{category}"',
                },
                { category: params?.row?.key },
              )}>
              <Clear />
            </ButtonGroupIconButton>
            <ButtonGroupIconButton
              color='error'
              onClick={() => {
                setCategoryKey(params?.row?.key);
                setShowDeleteConfirmationModal(true);
              }}
              title={intl.formatMessage(
                {
                  id: 'productCategories_actions_delete',
                  defaultMessage: 'Permanently delete category "{category}"',
                },
                { category: params?.row?.key },
              )}>
              <DeleteForever />
            </ButtonGroupIconButton>
          </ButtonGroup>
        );
      },
    });

    setTableColumns([...tableColumns, ...actionsColumn]);
  }, []);

  return (
    <div className={styles.wrapper}>
      <ActionStatusSnackbar actionName={getCategoriesAction.typePrefix} />
      {showClearConfirmationModal && (
        <ClearCategoryModal
          show={showClearConfirmationModal}
          categoryKey={categoryKey}
          handleToggleModal={() => setShowClearConfirmationModal(!showClearConfirmationModal)}
          onSuccess={() => setTriggerRefresh(!triggerRefresh)}
        />
      )}
      {showDeleteConfirmationModal && (
        <DeleteCategoryModal
          show={showDeleteConfirmationModal}
          categoryKey={categoryKey}
          handleToggleModal={() => setShowDeleteConfirmationModal(!showDeleteConfirmationModal)}
          onSuccess={() => setTriggerRefresh(!triggerRefresh)}
        />
      )}
      {showDetailsModal && (
        <CategoryDetailsModal
          show={showDetailsModal}
          categoryKey={categoryKey}
          handleToggleModal={() => setShowDetailsModal(!showDetailsModal)}
        />
      )}
      {showSkuListModal && (
        <SkuListModal
          show={showSkuListModal}
          categoryKey={categoryKey}
          handleToggleModal={() => setShowSkuListModal(!showSkuListModal)}
          onSuccess={() => setTriggerRefresh(!triggerRefresh)}
        />
      )}
      <ServerSidePaginatedTable
        rows={categories}
        columns={tableColumns}
        totalCount={categoriesTotal}
        triggerRefresh={triggerRefresh}
        fetchDataEffect={getCategoriesAction}
        searchParams={searchParams}
        onCellClick={(params: GridCellParams) => {
          if (params?.field !== 'details') {
            return;
          }
          setCategoryKey(params?.row?.key);
          setShowDetailsModal(true);
        }}
        slots={{
          toolbar: CategoryTableToolbar,
        }}
        slotProps={{
          toolbar: {
            onSuccess: () => setTriggerRefresh(!triggerRefresh),
          },
        }}
      />
    </div>
  );
};

export default ProductCategoriesPage;
