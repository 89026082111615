import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateUpdateWorkshopServiceParams,
  createWorkshopPrice,
  CreateWorkshopPriceParams,
  createWorkshopService,
  CreateWorkshopServicePriceParams,
  deleteWorkshopPrice,
  deleteWorkshopService,
  getWorkshopBrand,
  getWorkshopBrands,
  getWorkshopModels,
  getWorkshopPrice,
  getWorkshopPriceList,
  getWorkshopServices,
  updateWorkshopPrice,
  updateWorkshopService,
  UpdateWorkshopServicePriceParams,
} from '../../../api/server/workshopService';
import { WorkshopPricesActions } from './types';

export const getWorkshopServicesAction = createAsyncThunk(
  WorkshopPricesActions.getWorkshopServices,
  async (params: URLSearchParams) => {
    const response = await getWorkshopServices(params);
    return response.data;
  },
);

export const createWorkshopServiceAction = createAsyncThunk(
  WorkshopPricesActions.createWorkshopService,
  async (params: CreateUpdateWorkshopServiceParams) => {
    const response = await createWorkshopService(params);
    return response?.data;
  },
);

type UpdateWorkshopServiceRequestActionParams = {
  id: string;
  requestBody: CreateUpdateWorkshopServiceParams;
};

export const updateWorkshopServiceAction = createAsyncThunk(
  WorkshopPricesActions.updateWorkshopService,
  async (params: UpdateWorkshopServiceRequestActionParams) => {
    const response = await updateWorkshopService(params.id, params.requestBody);
    return response?.data;
  },
);

export const deleteWorkshopServiceAction = createAsyncThunk(
  WorkshopPricesActions.deleteWorkshopService,
  async (id: string) => {
    const response = await deleteWorkshopService(id);
    return response?.data;
  },
);

export const getWorkshopPriceListAction = createAsyncThunk(
  WorkshopPricesActions.getWorkshopPrices,
  async (params: URLSearchParams) => {
    const response = await getWorkshopPriceList(params);
    return response.data;
  },
);

export const getWorkshopPriceAction = createAsyncThunk(
  WorkshopPricesActions.getWorkshopPrice,
  async ({ brand, model }: { brand: string; model?: string }) => {
    const response = await getWorkshopPrice(brand, model);
    return response.data;
  },
);

export type CreateWorkshopPriceRequestParams = Omit<CreateWorkshopPriceParams, 'services'> & {
  services: {
    [key: string]: Omit<CreateWorkshopServicePriceParams, 'key' | 'value'> & {
      value?: string;
      individualOffer?: boolean;
    };
  };
};

export const createWorkshopPriceAction = createAsyncThunk(
  WorkshopPricesActions.createWorkshopPrice,
  async (params: CreateWorkshopPriceRequestParams) => {
    const { services, ...rest } = params;
    const newParams: CreateWorkshopPriceParams = { ...rest };
    newParams.services = Object.entries(services)
      .filter(([, value]) => !(value.value === '' && value.active && !value.individualOffer))
      .map(([key, value]) => {
        const price = value.value ? parseInt(value?.value, 10) : undefined;
        return {
          key,
          value: value.individualOffer ? null : price,
          active: value.active,
        };
      });
    const response = await createWorkshopPrice(newParams);
    return response?.data;
  },
);

export type UpdateWorkshopPriceRequestServices = {
  [key: string]: Omit<UpdateWorkshopServicePriceParams, 'key' | 'value'> & {
    value?: string;
    individualOffer?: boolean;
  };
};

type UpdateWorkshopPriceRequestParams = {
  id: string;
  requestBody: UpdateWorkshopPriceRequestServices;
};

export const updateWorkshopPriceAction = createAsyncThunk(
  WorkshopPricesActions.updateWorkshopPrice,
  async (params: UpdateWorkshopPriceRequestParams) => {
    const newParams: UpdateWorkshopServicePriceParams[] = Object.entries(params.requestBody)
      .filter(([, value]) => value.delete || !(value.value === '' && value.active && !value.individualOffer))
      .map(([key, value]) => {
        const price = value.value ? parseInt(value?.value, 10) : undefined;
        return {
          key,
          value: value.individualOffer ? null : price,
          active: value.active,
          delete: value.delete,
        };
      });
    const response = await updateWorkshopPrice(params.id, newParams);
    return response?.data;
  },
);

export const deleteWorkshopPriceAction = createAsyncThunk(
  WorkshopPricesActions.deleteWorkshopPrice,
  async (id: string) => {
    const response = await deleteWorkshopPrice(id);
    return response?.data;
  },
);

export const getWorkshopBrandsAction = createAsyncThunk(
  WorkshopPricesActions.getWorkshopBrands,
  async (params: URLSearchParams) => {
    const response = await getWorkshopBrands(params);
    return response.data;
  },
);

export const getWorkshopBrandAction = createAsyncThunk(
  WorkshopPricesActions.getWorkshopBrand,
  async (brand: string) => {
    const response = await getWorkshopBrand(brand);
    return response.data;
  },
);

export const getWorkshopModelsAction = createAsyncThunk(
  WorkshopPricesActions.getWorkshopModels,
  async (brand: string) => {
    const response = await getWorkshopModels(brand);
    return response.data;
  },
);
