import { Autocomplete, TextField } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { useIntl } from 'react-intl';
import { useAppSelector, useAppDispatch } from '../../../../state';
import { Variant } from '../../../../state/ducks/inventory/types';
import { requestSelector } from '../../../../selectors/request';
import { inventoryVariantsSelector } from '../../../../selectors/inventory';
import { getVariantsAction } from '../../../../state/ducks/inventory/actions';

type Props = {
  label?: string;
  width?: number;
  productReference?: string;
  onSelectChange: (value: Variant | undefined) => void;
};

const VariantFilter: React.FC<Props> = ({ label, width, onSelectChange, productReference }) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { items: variants } = useAppSelector(inventoryVariantsSelector);
  const getVariantsRequest = useAppSelector((state) => requestSelector(state, getVariantsAction.typePrefix));

  const [selectedVariantReference, setSelectedVariantReference] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState('');

  const variantReferenceList = variants.map((variant) => variant.reference);

  useEffect(() => {
    const searchParams = new URLSearchParams();
    if (inputValue) {
      searchParams.set('search_key', inputValue);
    }
    if (productReference) {
      searchParams.set('product_reference', productReference);
    }
    callGetVariantsAction(searchParams);
  }, [inputValue, productReference]);

  useEffect(() => {
    const selectedVariantItem = variants.find((variant) => variant.reference === selectedVariantReference);
    onSelectChange(selectedVariantItem);
  }, [selectedVariantReference]);

  const callGetVariantsAction = useCallback(
    debounce((searchParams) => {
      dispatch(getVariantsAction(searchParams));
    }, 500),
    [],
  );

  return (
    <Autocomplete
      sx={{ width: width || 300 }}
      onChange={(_, value) => setSelectedVariantReference(value)}
      onInputChange={(_, value) => setInputValue(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            label ||
            intl.formatMessage({
              id: 'common_labels_variantCode',
              defaultMessage: 'Variant Code',
            })
          }
          size='small'
          margin='dense'
          inputProps={{ ...params.inputProps, style: { ...params.inputProps.style, textTransform: 'uppercase' } }}
        />
      )}
      options={variantReferenceList}
      value={selectedVariantReference}
      loading={getVariantsRequest?.status === 'pending'}
    />
  );
};

export default VariantFilter;
