import { TabContext, TabPanel } from '@mui/lab';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink, useParams } from 'react-router-dom';
import { ClientRoute, createPath } from '../../../routing';
import WorkshopInboundItems from './WorkshopInboundItems';

type Params = {
  [key: string]: string;
};

const tabProps = {
  sx: {
    flex: 1,
    padding: 0,
  },
};

const WorkshopInboundItemsWrapper: React.FC = () => {
  const { tab } = useParams<Params>();

  return (
    <TabContext value={tab}>
      <Stack sx={{ height: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 3 }}>
          <Tabs selectionFollowsFocus value={tab}>
            <Tab
              value='all'
              component={NavLink}
              to={createPath({ path: ClientRoute.WORKSHOP_INBOUND_ITEMS, params: { tab: 'all' } })}
              label={<FormattedMessage id='sellRequests_tabs_overview' defaultMessage='Overview' />}
            />
            <Tab
              value='approved'
              component={NavLink}
              to={createPath({ path: ClientRoute.WORKSHOP_INBOUND_ITEMS, params: { tab: 'approved' } })}
              label={<FormattedMessage id='workshopInbound_labels_approved' defaultMessage='Service Approved' />}
            />
            <Tab
              value='declined'
              component={NavLink}
              to={createPath({ path: ClientRoute.WORKSHOP_INBOUND_ITEMS, params: { tab: 'declined' } })}
              label={<FormattedMessage id='workshopInbound_labels_declined' defaultMessage='Service Declined' />}
            />
            <Tab
              value='priority'
              component={NavLink}
              to={createPath({ path: ClientRoute.WORKSHOP_INBOUND_ITEMS, params: { tab: 'priority' } })}
              label={<FormattedMessage id='workshopInbound_labels_priority' defaultMessage='Priority' />}
            />
          </Tabs>
        </Box>
        <TabPanel value='all' {...tabProps}>
          {(tab === 'all' || tab === null) && <WorkshopInboundItems key='all' />}
        </TabPanel>
        <TabPanel value='approved' {...tabProps}>
          {tab === 'approved' && (
            <WorkshopInboundItems key='service_status_approved' tabFilter={{ service_approved: 'approved' }} />
          )}
        </TabPanel>
        <TabPanel value='declined' {...tabProps}>
          {tab === 'declined' && (
            <WorkshopInboundItems key='service_status_declined' tabFilter={{ service_approved: 'declined' }} />
          )}
        </TabPanel>
        <TabPanel value='priority' {...tabProps}>
          {tab === 'priority' && <WorkshopInboundItems key='priority' tabFilter={{ priority: true }} />}
        </TabPanel>
      </Stack>
    </TabContext>
  );
};

export default WorkshopInboundItemsWrapper;
