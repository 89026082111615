import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Box, Button, Dialog, DialogActions, DialogContent, MobileStepper, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import SwipeableViews from 'react-swipeable-views';
import { bindKeyboard } from 'react-swipeable-views-utils';

const EnhancedSwipeableViews = bindKeyboard(SwipeableViews);

export type GalleryImage = {
  key: string;
  src: string;
  name: string;
};

type Props = {
  images: GalleryImage[];
  show: boolean;
  handleToggleModal: () => void;
};

const Gallery: React.FC<Props> = ({ images, show, handleToggleModal }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length || 0;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Dialog open={show} onClose={handleToggleModal} scroll='paper'>
      <DialogContent>
        <Box>
          {maxSteps > 0 ? (
            <>
              <EnhancedSwipeableViews axis='x' index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
                {images.map((imageItem, index) => {
                  return (
                    <div key={imageItem.key}>
                      {Math.abs(activeStep - index) <= 2 ? (
                        <Box
                          component='img'
                          sx={{
                            display: 'block',
                            width: '100%',
                          }}
                          src={imageItem.src}
                          alt={imageItem.name}
                        />
                      ) : null}
                    </div>
                  );
                })}
              </EnhancedSwipeableViews>
              <MobileStepper
                steps={maxSteps}
                position='static'
                activeStep={activeStep}
                nextButton={
                  <Button size='small' onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                    <FormattedMessage id='common_next' defaultMessage='Next' />
                    {<KeyboardArrowRight />}
                  </Button>
                }
                backButton={
                  <Button size='small' onClick={handleBack} disabled={activeStep === 0}>
                    {<KeyboardArrowLeft />}
                    <FormattedMessage id='common_back' defaultMessage='Back' />
                  </Button>
                }
              />
            </>
          ) : (
            <Typography color='text.secondary'>
              <FormattedMessage id='common_noImages' defaultMessage='No images available' />
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleToggleModal}>
          <FormattedMessage id='common_close' defaultMessage='Close' />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Gallery;
