import { TabContext, TabPanel } from '@mui/lab';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tracking } from '../../../../state/ducks/tracking/types';
import TrackingCommentsTable from './TrackingCommentsTable';
import TrackingHistoryTable from './TrackingHistoryTable';

const tabProps = {
  sx: {
    flex: 1,
    padding: 0,
  },
};

type Props = {
  tracking: Tracking;
  fetchTrackingItem: () => void;
};

const TrackingDetailsTabs: React.FC<Props> = ({ tracking, fetchTrackingItem }) => {
  const { id, history, comments } = tracking;
  const [tabIndex, setTabIndex] = React.useState(0);

  return (
    <TabContext value={tabIndex.toString()}>
      <Stack sx={{ height: 'calc(100% - 230px)' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 3 }}>
          <Tabs selectionFollowsFocus value={tabIndex} onChange={(_, newValue) => setTabIndex(newValue)}>
            <Tab label={<FormattedMessage id='common_labels_history' defaultMessage='History' />}></Tab>
            <Tab
              label={
                <FormattedMessage
                  id='tracking_labels_comments'
                  defaultMessage='Comments ({count})'
                  values={{ count: comments.length }}
                />
              }></Tab>
          </Tabs>
        </Box>
        <TabPanel value='0' {...tabProps}>
          <TrackingHistoryTable history={history} />
        </TabPanel>
        <TabPanel value='1' {...tabProps}>
          <TrackingCommentsTable comments={comments} trackingId={id} fetchTrackingItem={fetchTrackingItem} />
        </TabPanel>
      </Stack>
    </TabContext>
  );
};

export default TrackingDetailsTabs;
