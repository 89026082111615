import { Grid } from '@mui/material';
import React, { Fragment } from 'react';
import { MenuItems } from '../../components/sidebar/Sidebar';
import HomepageCardItem from './components/HomepageCardItem';

const Homepage: React.FC = () => {
  return (
    <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
      {MenuItems.map((itemData) => (
        <Fragment key={itemData.item.href}>
          {!itemData?.children && <HomepageCardItem menuItem={itemData.item} />}
          {itemData?.children?.map((item) => (
            <HomepageCardItem key={item.href} menuItem={item} categoryName={itemData.item.name} />
          ))}
        </Fragment>
      ))}
    </Grid>
  );
};

export default Homepage;
