import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { algoliaStateSelector } from '../../../../selectors/algolia';
import { requestSelector } from '../../../../selectors/request';
import { workshopServiceStateSelector } from '../../../../selectors/workshopService';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { getAlgoliaBrandsAction, getAlgoliaModelsForBrandAction } from '../../../../state/ducks/algolia/actions';
import {
  createWorkshopPriceAction,
  CreateWorkshopPriceRequestParams,
  getWorkshopBrandAction,
  getWorkshopBrandsAction,
  getWorkshopModelsAction,
} from '../../../../state/ducks/workshopService/actions';
import { Model, Service } from '../../../../state/ducks/workshopService/types';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
};

const initialFormData: CreateWorkshopPriceRequestParams = {
  brand: '',
  brandSlug: '',
  model: '',
  modelSlug: '',
  services: {},
};

const ACTIVE = 'active';
const INDIVIDUAL_OFFER = 'individualOffer';
const VALUE = 'value';

const renderServiceRows = (
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  services: Service[],
  formData: CreateWorkshopPriceRequestParams,
) => {
  return services.map((service) => {
    return (
      <TableRow key={service.key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell>{service.name}</TableCell>
        <TableCell>
          <TextField
            margin='dense'
            id={`${VALUE}_${service.key}`}
            name={`${VALUE}_${service.key}`}
            type='number'
            value={formData.services?.[service?.key]?.value}
            onChange={handleChange}
            label={<FormattedMessage id='common_labels_price' defaultMessage='Price' />}
            fullWidth
            disabled={formData.services?.[service?.key]?.individualOffer}
          />
        </TableCell>
        <TableCell>
          <Switch
            checked={formData.services?.[service?.key]?.individualOffer}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            name={`${INDIVIDUAL_OFFER}_${service.key}`}
          />
        </TableCell>
        <TableCell>
          <Switch
            color='success'
            checked={formData.services?.[service?.key]?.active}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            name={`${ACTIVE}_${service.key}`}
          />
        </TableCell>
      </TableRow>
    );
  });
};

const CreateWorkshopPriceModal: React.FC<Props> = ({ onSuccess, handleToggleModal, show }) => {
  const dispatch = useAppDispatch();
  const { brands: algoliaBrands, models: algoliaModels } = useAppSelector(algoliaStateSelector);
  const { brands, prices, services } = useAppSelector(workshopServiceStateSelector);
  const createRequest = useAppSelector((state) => requestSelector(state, createWorkshopPriceAction.typePrefix));

  const [showCreateSnackbar, setShowCreateSnackbar] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [isFormValid, setIsFormValid] = useState(false);
  const [brandExists, setBrandExists] = useState(false);
  const [existingModels, setExistingModels] = useState<string[]>([]);

  const clearForm = () => {
    services.forEach((service) => {
      initialFormData.services[service.key] = { value: '', active: true, individualOffer: false };
    });
    setFormData(initialFormData);
  };

  const handleClose = (clear = false) => {
    if (clear) {
      clearForm();
    }
    handleToggleModal();
  };

  const handleServicesFieldsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formServices = { ...formData.services };
    const [name, service] = event.target.name.split('_');
    switch (name) {
      case ACTIVE:
      case INDIVIDUAL_OFFER:
        formServices[service][name] = event.target.checked;
        setFormData({ ...formData, services: formServices });
        break;
      case VALUE:
        formServices[service][name] = event.target.value;
        setFormData({ ...formData, services: formServices });
    }
  };

  const handleBrandChange = (event: SelectChangeEvent) => {
    dispatch(getWorkshopBrandAction(event.target.value)).then((response) => {
      setBrandExists(!!response?.payload);
      if (response?.payload) {
        dispatch(getWorkshopModelsAction(event.target.value)).then((response) => {
          const models = response.payload?.data?.map((m: Model) => m.slug) || [];
          setExistingModels(models);
          dispatch(getAlgoliaModelsForBrandAction(event.target.value));
        });
      }
    });
    const name = algoliaBrands.find((b) => b.slug === event.target.value)?.name || '';
    setFormData({ ...formData, brandSlug: event.target.value, brand: name, model: '', modelSlug: '' });
  };

  const handleModelChange = (event: SelectChangeEvent) => {
    const name = algoliaModels.find((b) => b.slug === event.target.value)?.name || '';
    setFormData({ ...formData, modelSlug: event.target.value, model: name });
  };

  const submitForm = () => {
    dispatch(createWorkshopPriceAction(formData)).then((response) => {
      setShowCreateSnackbar(true);
      if (response?.payload && !response?.payload?.errors) {
        clearForm();
        handleToggleModal();
        onSuccess();
      }
    });
  };

  useEffect(() => {
    clearForm();
    const urlSearchParams = new URLSearchParams();
    dispatch(getWorkshopBrandsAction(urlSearchParams));
    dispatch(getAlgoliaBrandsAction(urlSearchParams));
  }, [show]);

  useEffect(() => {
    if (formData.brand && formData.brandSlug && (!brandExists || (formData.model && formData.modelSlug))) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formData]);

  return (
    <>
      {showCreateSnackbar && <ActionStatusSnackbar actionName={createWorkshopPriceAction.typePrefix} />}
      <Dialog open={show} onClose={handleToggleModal}>
        <DialogTitle>
          <FormattedMessage id='workshopPrices_prices_createModal_title' defaultMessage='New Entry' />
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText>
              <FormattedMessage
                id='workshopPrices_prices_createModal_description'
                defaultMessage='To add a new entry, please fill out the fields below. Please note that you can only select a model after you have selected a brand from the dropdown. If there is no entry for the selected brand yet, you have to create that first.'
              />
            </DialogContentText>
            <FormControl>
              <InputLabel id='brandLabel'>
                <FormattedMessage id='common_labels_brand' defaultMessage='Brand' />
              </InputLabel>
              <Select
                margin='dense'
                id='brand'
                name='brand'
                value={formData.brandSlug}
                onChange={handleBrandChange}
                input={<OutlinedInput label={<FormattedMessage id='common_labels_brand' defaultMessage='Brand' />} />}
                labelId='brandLabel'
                fullWidth>
                {algoliaBrands.map((option) => (
                  <MenuItem key={option.slug} value={option.slug}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {formData.brand && brandExists && (
              <FormControl>
                <InputLabel id='modelLabel'>
                  <FormattedMessage id='common_labels_model' defaultMessage='Model' />
                </InputLabel>
                <Select
                  margin='dense'
                  id='model'
                  name='model'
                  value={formData.modelSlug}
                  onChange={handleModelChange}
                  input={<OutlinedInput label={<FormattedMessage id='common_labels_model' defaultMessage='model' />} />}
                  labelId='modelLabel'
                  fullWidth>
                  {algoliaModels
                    .filter((model) => !existingModels.includes(model.slug))
                    .map((option) => (
                      <MenuItem key={option.slug} value={option.slug}>
                        {option.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            <TableContainer component={Paper}>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id='workshop_labels_service' defaultMessage='Service' />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id='common_labels_price' defaultMessage='Price' />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id='workshopPrices_prices_individualOffer' defaultMessage='Individual Offer' />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id='workshopPrices_labels_active' defaultMessage='Active' />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderServiceRows(
                    handleServicesFieldsChange,
                    services.filter((s) => s.active),
                    formData,
                  )}
                  {services.filter((s) => !s.active)?.length ? (
                    <>
                      <TableRow>
                        <TableCell align='center' colSpan={5}>
                          <FormattedMessage
                            id='workshopPrices_labels_inactiveServices'
                            defaultMessage='Inactive Services'
                          />
                        </TableCell>
                      </TableRow>
                      {renderServiceRows(
                        handleServicesFieldsChange,
                        services.filter((s) => !s.active),
                        formData,
                      )}
                    </>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color='error'>
            <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
          </Button>
          <Button onClick={submitForm} disabled={!isFormValid} color='success'>
            <FormattedMessage id='common_save' defaultMessage='Save' />
          </Button>
        </DialogActions>
        {createRequest?.status === 'pending' && <LinearProgress color='primary' />}
      </Dialog>
    </>
  );
};

export default CreateWorkshopPriceModal;
