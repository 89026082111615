import { Download } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import deLocale from 'date-fns/locale/de';
import moment from 'moment';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { TrackingReportType } from '../../../../api/server/tracking';
import { AftershipStatus } from '../../../../constants/tracking';
import { useAppDispatch } from '../../../../state';
import {
  downloadTrackingReportPurchasesAction,
  downloadTrackingReportSalesAction,
  downloadTrackingReportTransfersAction,
} from '../../../../state/ducks/tracking/actions';

type Props = {
  reportType: TrackingReportType;
  show: boolean;
  handleToggleModal: () => void;
};

const DOWNLOAD_ACTIONS: Record<TrackingReportType, any> = {
  [TrackingReportType.SALES]: downloadTrackingReportSalesAction,
  [TrackingReportType.PURCHASES]: downloadTrackingReportPurchasesAction,
  [TrackingReportType.TRANSFERS]: downloadTrackingReportTransfersAction,
};

const initialFormData: {
  startDate: Date | null;
  state: string;
} = {
  startDate: new Date(moment().subtract(100, 'days').format('YYYY-MM-DD')),
  state: 'all',
};

const DownloadTrackingReportModal: React.FC<Props> = ({ reportType, show, handleToggleModal }) => {
  const dispatch = useAppDispatch();
  const downloadAction = DOWNLOAD_ACTIONS[reportType];

  const [formData, setFormData] = useState(initialFormData);

  const clearForm = () => {
    setFormData(initialFormData);
  };

  const submitForm = () => {
    const { startDate, state } = formData;
    const submitData = {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      state: state === 'all' ? '' : state,
    };
    dispatch(downloadAction(submitData)).then((response: { payload: { errors: any } }) => {
      if (response?.payload && !response.payload?.errors) {
        clearForm();
        handleToggleModal();
      }
    });
  };

  return (
    <Dialog open={show} onClose={handleToggleModal}>
      <DialogTitle>
        <FormattedMessage
          id='tracking_downloadReportsModal_title'
          defaultMessage='Download {type, select, sales {Sales Orders} purchases {Purchase Orders} transfer_orders {Internal Transfers} other {}} Tracking Report'
          values={{ type: reportType }}
        />
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} pt={1}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
            <DatePicker
              label={<FormattedMessage id='tracking_labels_startDate' defaultMessage='Start Date' />}
              value={formData.startDate}
              onChange={(newDate) => setFormData({ ...formData, startDate: newDate })}
              minDate={new Date(moment().subtract(100, 'days').format('YYYY-MM-DD'))}
              maxDate={new Date()}
            />
          </LocalizationProvider>
          <TextField
            margin='dense'
            name='state'
            size='small'
            value={formData.state}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setFormData({ ...formData, state: event.target.value })
            }
            label={<FormattedMessage id='common_labels_status' defaultMessage='Status' />}
            select
            sx={{ width: '150px' }}>
            <MenuItem value='all'>
              <FormattedMessage id='common_all' defaultMessage='All' />
            </MenuItem>
            {Object.keys(AftershipStatus).map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleToggleModal} color='error'>
          <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
        </Button>
        <Button onClick={submitForm} color='success' endIcon={<Download />}>
          <FormattedMessage id='common_download' defaultMessage='Download' />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DownloadTrackingReportModal;
