import { SellRequestStatus, SellRequestStatusInfo } from '../../../constants/sell';

export enum SellActions {
  refreshSellRequests = 'sell/refreshSellRequests',
  getSellRequests = 'sell/getSellRequests',
  getSellRequest = 'sell/getSellRequest',
  updateSellRequest = 'sell/updateSellRequest',
  requestMissingData = 'sell/requestMissingData',
  cancelSellRequest = 'sell/cancelSellRequest',
  updateSellRequestStatus = 'sell/updateSellRequestStatus',
  updateSellRequestOffer = 'sell/updateSellRequestOffer',
  updateSellRequestOfferStatus = 'sell/updateSellRequestOfferStatus',
  updateSellRequestOfferExpirationDate = 'sell/updateSellRequestOfferExpirationDate',
  requestVendorInformation = 'sell/requestVendorInformation',
  updateVendorInformation = 'sell/updateVendorInformation',
  getExistingOffers = 'sell/getExistingOffers',
  getChronextAgents = 'sell/getChronextAgents',
  updateSellRequestAgentAction = 'sell/updateSellRequestAgentAction',
}

export type VendorAddress = {
  address_id: number;
  salutation: string;
  first_name: string;
  last_name: string;
  country: string;
  city: string;
  street: string;
  street_number: number;
  postal_code: string;
  street_info: string;
  phone: string;
  additional_info: string;
  state?: string;
  company?: string;
  pickup: boolean;
  created_at: string;
  updated_at: string;
  pickup_date?: string;
};

export type Vendor = {
  reference: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  language: string;
  gender: string;
  country: string;
  addresses: VendorAddress[];
  iban: string;
  bic: string;
  bankName: string;
  bankHolderName: string;
};

type SellingMethod = 'direct' | 'commission' | 'exchange';

export type SellingMethods = SellingMethod[];

export type Image = {
  key: string;
  name: string;
};

export enum OfferStatus {
  APPROVED = 'approved',
  DECLINED = 'declined',
  PENDING = 'pending',
}

type OfferItem = Pick<
  SellRequest,
  'reference' | 'sellRequestId' | 'sku' | 'constructionYear' | 'watchCondition' | 'paperAvailable' | 'boxAvailable'
>;

export type Offer = {
  taxation: string;
  status: string;
  isApproved: boolean;
  isPending: boolean;
  isDeclined: boolean;
  cxtSellingPrice: number;
  commissionRate: number;
  directPrice: number;
  exchangePrice: number;
  createdBy: string;
  approver: string;
  createdAt: string;
  updatedAt: string;
  approvedAt: string;
  expirationDaysLimit: number;
  watch?: OfferItem;
};

export type CounterOffer = {
  currency: string;
  price: number;
  selling_method: string;
  created_at: string;
};

export type OfferReply = {
  accept: string;
  rejection_reason: string;
  selling_method: string;
  created_at: string;
};

export type SellRequest = {
  reference: number;
  sellRequestId: string;
  purchaseOrder?: string;
  trackingNumber?: string;
  pickupDate?: string;
  dropOff?: boolean;
  watchBrand: string;
  watchModel: string;
  watchRef: string;
  sku: string;
  constructionYear: string;
  watchCondition: string;
  paperAvailable: boolean;
  boxAvailable: boolean;
  currencyCode: string;
  priceFrom: number;
  priceTo: number;
  priceFinal: number;
  comments: string;
  directPriceFrom: number;
  directPriceTo: number;
  commissionPriceFrom: number;
  commissionPriceTo: number;
  createdAt: string;
  updatedAt: string;
  images: Image[];
  status: SellRequestStatus;
  statusInfo: SellRequestStatusInfo;
  cancellationReason?: string;
  seller: Vendor;
  sellingMethods: SellingMethods;
  offer: Offer | null;
  counterOffer?: CounterOffer;
  offerReply?: OfferReply;
  sentToERP?: boolean;
  agent?: ChronextAgents | null;
};

export type ChronextAgents = {
  reference: number;
  firstName: string;
  lastName: string;
  preferredName: string;
  email: string;
  team: string;
};

export type SellState = {
  chronextAgents: ChronextAgents[];
  sellRequests: {
    total: number;
    items: SellRequest[];
  };
  sellRequest: SellRequest | null;
  offers: Offer[];
};

export type SellAddress = {
  addressId?: string;
  salutation?: string;
  firstName?: string;
  lastName?: string;
  country?: string;
  city?: string;
  streetName?: string;
  streetNumber?: string;
  additionalStreetInfo?: string;
  postalCode?: string;
  phone?: string;
  additionalAddressInfo?: string;
  state?: string;
  company?: string;
  pickupDate?: string;
};
