import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, LinearProgress } from '@mui/material';
import { APP_CONFIG } from '../../../config/app';
import { ClientRouteGroups } from '../../../routing/clientRoutes';
import { isAuthorizedSelector } from '../../../selectors/user';
import ButtonGroupIconButton from './ButtonGroupIconButton';
import {
  getWorkshopInboundWatchAction,
  sendWorkshopInboundServiceQuotationAction,
} from '../../../state/ducks/workshopInbound/actions';
import { useAppDispatch, useAppSelector } from '../../../state';
import ActionStatusSnackbar from '../snackbars/ActionStatusSnackbar';
import { FormattedMessage, useIntl } from 'react-intl';
import { Send } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { requestSelector } from '../../../selectors/request';

type Props = {
  serviceId: string;
};

const WorkshopInboundSendQuotationButton: React.FC<Props> = ({ serviceId }) => {
  const userCanEdit =
    useAppSelector((state) =>
      isAuthorizedSelector(
        state,
        ClientRouteGroups.WORKSHOP_INBOUND_ITEM.filter((g) => g.startsWith('Workshop')),
      ),
    ) || !APP_CONFIG.isProd;
  const { reference } = useParams<{ reference: string }>();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const request = useAppSelector((state) =>
    requestSelector(state, sendWorkshopInboundServiceQuotationAction.typePrefix),
  );

  const sendQuotation = () => {
    dispatch(sendWorkshopInboundServiceQuotationAction(serviceId)).then((response) => {
      setShowSnackbar(true);
      if (response?.payload) {
        setShowConfirmationModal(false);
        dispatch(getWorkshopInboundWatchAction(reference));
      }
    });
  };

  return (
    <>
      {showSnackbar && <ActionStatusSnackbar actionName={sendWorkshopInboundServiceQuotationAction.typePrefix} />}
      <Dialog open={showConfirmationModal} onClose={() => setShowConfirmationModal(false)}>
        <DialogContent>
          <FormattedMessage
            id='workshopInbound_actions_sendQuotation_confirmation'
            defaultMessage='Please confirm that you want to send the quotation to the ERP system'
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowConfirmationModal(!showConfirmationModal)}
            disabled={request?.status === 'pending'}>
            <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
          </Button>
          <Button color='success' onClick={sendQuotation} disabled={request?.status === 'pending'}>
            <FormattedMessage id='common_confirm' defaultMessage='Confirm' />
          </Button>
        </DialogActions>
        {request?.status === 'pending' && <LinearProgress color='primary' />}
      </Dialog>
      <ButtonGroupIconButton
        variant='contained'
        color='primary'
        disabled={!userCanEdit}
        onClick={() => setShowConfirmationModal(true)}
        title={intl.formatMessage({
          id: 'workshopInbound_actions_sendQuotation',
          defaultMessage: 'Send Quotation',
        })}>
        <Send />
      </ButtonGroupIconButton>
    </>
  );
};

export default WorkshopInboundSendQuotationButton;
