import {
  AttachMoney,
  CurrencyFranc,
  CurrencyPound,
  DeleteForever,
  Edit,
  Euro,
  InfoOutlined,
  Money,
  Percent,
} from '@mui/icons-material';
import { ButtonGroup, Divider, IconButton, Stack, Tooltip } from '@mui/material';
import { GridCellParams, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DISCOUNT_CODES_BATCH_GROUP } from '../../../constants/discountCodes';
import { GROUPS } from '../../../constants/groups';
import { commercetoolsDiscountCodesSelector } from '../../../selectors/commercetools';
import { isAuthorizedSelector } from '../../../selectors/user';
import { useAppSelector } from '../../../state';
import { getDiscountCodesAction } from '../../../state/ducks/commercetools/actions';
import { formatUTCToLocal } from '../../../utils/datetime';
import ButtonGroupIconButton from '../../components/buttons/ButtonGroupIconButton';
import BooleanIcon from '../../components/icons/BooleanIcon';
import ActionStatusSnackbar from '../../components/snackbars/ActionStatusSnackbar';
import ServerSidePaginatedTable, {
  ServerSidePaginatedTableColDef,
} from '../../components/table/ServerSidePaginatedTable';
import DeleteDiscountCodeModal from './components/DeleteDiscountCodeModal';
import DiscountCodeDetailsModal from './components/DiscountCodeDetailsModal';
import DiscountsTableToolbar from './components/DiscountsTableToolbar';
import EditDiscountCodeModal from './components/EditDiscountCodeModal';
import styles from './ProductDiscounts.module.scss';

// Column definitions for Product Categories table
const categoriesTableColumns: ServerSidePaginatedTableColDef[] = [
  {
    id: 'common_details',
    headerName: 'Details',
    field: 'details', // This field doesn't exist in the row data
    sortable: false,
    renderCell: () => {
      return (
        <IconButton aria-label='edit' size='small'>
          <InfoOutlined />
        </IconButton>
      );
    },
    width: 80,
    align: 'center',
  },
  {
    id: 'productDiscounts_labels_code',
    headerName: 'Code',
    field: 'code',
    minWidth: 150,
    sortable: false,
    flex: 1,
  },
  {
    id: 'productDiscounts_labels_currencies',
    headerName: 'Currencies',
    field: 'currencies',
    minWidth: 120,
    sortable: false,
    flex: 1,
    align: 'center',
    renderCell: (params: GridRenderCellParams) => {
      const { currencies } = params.row;
      if (!Array.isArray(currencies)) {
        return '';
      }
      const commonStyles = { fontSize: '1rem' };
      const getColor = (currency: string) => (currencies.includes(currency) ? 'primary' : 'disabled');

      return (
        <Stack direction='row' spacing={1} divider={<Divider orientation='vertical' flexItem />}>
          <Tooltip title='EUR' arrow>
            <Euro sx={{ ...commonStyles }} color={getColor('EUR')} />
          </Tooltip>
          <Tooltip title='GBP' arrow>
            <CurrencyPound sx={{ ...commonStyles }} color={getColor('GBP')} />
          </Tooltip>
          <Tooltip title='USD' arrow>
            <AttachMoney sx={{ ...commonStyles }} color={getColor('USD')} />
          </Tooltip>
          <Tooltip title='CHF' arrow>
            <CurrencyFranc sx={{ ...commonStyles }} color={getColor('CHF')} />
          </Tooltip>
        </Stack>
      );
    },
  },
  {
    id: 'productDiscounts_labels_discountType',
    headerName: 'Type',
    field: 'isPercentValue',
    sortable: false,
    align: 'center',
    renderCell: (params: GridRenderCellParams) => {
      const { isPercentValue } = params.row;
      return isPercentValue ? (
        <Tooltip title={<FormattedMessage id='productDiscounts_types_relative' defaultMessage='Relative' />}>
          <Percent />
        </Tooltip>
      ) : (
        <Tooltip title={<FormattedMessage id='productDiscounts_types_absolute' defaultMessage='Absolute' />}>
          <Money />
        </Tooltip>
      );
    },
  },
  {
    id: 'productDiscounts_labels_isActive',
    headerName: 'Active',
    field: 'isActive',
    sortable: false,
    align: 'center',
    renderCell: (params: GridRenderCellParams) => {
      const { isActive } = params.row;
      return <BooleanIcon check={isActive} />;
    },
  },
  {
    id: 'productDiscounts_labels_validFrom',
    headerName: 'Valid From',
    field: 'validFrom',
    sortable: false,
    valueGetter: ({ value }) => value && formatUTCToLocal(value),
    flex: 1,
  },
  {
    id: 'productDiscounts_labels_validUntil',
    headerName: 'Valid Until',
    field: 'validUntil',
    sortable: false,
    valueGetter: ({ value }) => value && formatUTCToLocal(value),
    flex: 1,
  },
  {
    id: 'common_labels_createdBy',
    headerName: 'Created By',
    field: 'createdBy',
    sortable: false,
    flex: 1,
  },
];

const ProductDiscounts: React.FC<{ userCanEdit: boolean }> = ({ userCanEdit }) => {
  const isAdmin = useAppSelector((state) => isAuthorizedSelector(state, [GROUPS.ADMIN]));
  const { discountCodes, discountCodesTotal } = useAppSelector(commercetoolsDiscountCodesSelector);
  const [tableColumns, setTableColumns] = useState<ServerSidePaginatedTableColDef[]>(categoriesTableColumns);

  const intl = useIntl();
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [discountCreator, setDiscountCreator] = useState('');
  const [hideBatchDiscounts, setHideBatchDiscounts] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchParams, setSearchParams] = useState({
    where: '',
  });
  const [selectedCode, setSelectedCode] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  useEffect(() => {
    // allow complex query searches
    const searchPredicate = searchText.startsWith('q=')
      ? searchText.slice(2)
      : `code="${searchText}"or groups contains any ("${searchText}")`;
    const creatorPredicate = `custom(fields(email="${discountCreator}"))`;
    let predicate = '';

    if (discountCreator && searchText) {
      predicate = `${searchPredicate} and ${creatorPredicate}`;
    } else if (searchText) {
      predicate = searchPredicate;
    } else if (discountCreator) {
      predicate = creatorPredicate;
    }

    const hideBatchDiscountsPredicate = `not(groups contains any ("${DISCOUNT_CODES_BATCH_GROUP}"))`;
    if (hideBatchDiscounts && predicate) {
      predicate = `${predicate} and ${hideBatchDiscountsPredicate}`;
    } else if (hideBatchDiscounts) {
      predicate = hideBatchDiscountsPredicate;
    }

    setSearchParams({
      where: predicate,
    });
  }, [searchText, discountCreator, hideBatchDiscounts]);

  useEffect(() => {
    const actionsColumn: ServerSidePaginatedTableColDef[] = [];
    if (userCanEdit) {
      actionsColumn.push({
        id: 'common_actions',
        headerName: 'Actions',
        field: 'actions',
        align: 'center',
        sortable: false,
        renderCell: (params: any) => {
          const discountHasStarted =
            !params?.row?.validFrom || new Date().getTime() > new Date(params?.row?.validFrom).getTime();
          return (
            <ButtonGroup variant='text'>
              <ButtonGroupIconButton
                color='info'
                onClick={() => {
                  setSelectedRow(params.row);
                  setShowEditModal(true);
                }}
                title={intl.formatMessage(
                  {
                    id: 'productDiscounts_actions_edit',
                    defaultMessage: 'Edit Discount Code',
                  },
                  { category: params.row?.key },
                )}>
                <Edit />
              </ButtonGroupIconButton>
              {(!discountHasStarted || isAdmin) && (
                <ButtonGroupIconButton
                  color='error'
                  onClick={() => {
                    setSelectedRow(params.row);
                    setSelectedCode(params.row.code);
                    setShowDeleteConfirmationModal(true);
                  }}
                  title={intl.formatMessage(
                    {
                      id: 'productDiscounts_actions_delete',
                      defaultMessage: 'Permanently delete Discount Code',
                    },
                    { category: params.row?.key },
                  )}>
                  <DeleteForever />
                </ButtonGroupIconButton>
              )}
            </ButtonGroup>
          );
        },
      });
    }
    setTableColumns([...tableColumns, ...actionsColumn]);
  }, []);

  return (
    <div className={styles.wrapper}>
      <ActionStatusSnackbar actionName={getDiscountCodesAction.typePrefix} />
      {userCanEdit && showDeleteConfirmationModal && (
        <DeleteDiscountCodeModal
          show={showDeleteConfirmationModal}
          code={selectedCode}
          handleToggleModal={() => setShowDeleteConfirmationModal(!showDeleteConfirmationModal)}
          onSuccess={() => setTriggerRefresh(!triggerRefresh)}
          discountCode={selectedRow}
        />
      )}
      {userCanEdit && showEditModal && selectedRow && (
        <EditDiscountCodeModal
          show={showEditModal}
          discountCode={selectedRow}
          handleToggleModal={() => setShowEditModal(!showEditModal)}
          onSuccess={() => setTriggerRefresh(!triggerRefresh)}
        />
      )}
      {showDetailsModal && selectedRow && (
        <DiscountCodeDetailsModal
          show={showDetailsModal}
          discountCode={selectedRow}
          handleToggleModal={() => setShowDetailsModal(!showDetailsModal)}
        />
      )}
      <ServerSidePaginatedTable
        rows={discountCodes}
        columns={tableColumns}
        totalCount={discountCodesTotal}
        triggerRefresh={triggerRefresh}
        fetchDataEffect={getDiscountCodesAction}
        searchParams={searchParams}
        onCellClick={(params: GridCellParams) => {
          if (params.field !== 'details') {
            return;
          }
          setSelectedRow(params.row);
          setShowDetailsModal(true);
        }}
        slots={{
          toolbar: DiscountsTableToolbar,
        }}
        slotProps={{
          toolbar: {
            onSuccess: () => setTriggerRefresh(!triggerRefresh),
            searchText,
            onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => setSearchText(event.target.value),
            clearSearch: () => setSearchText(''),
            onCreatorFilterChange: (event: React.ChangeEvent<HTMLInputElement>) =>
              setDiscountCreator(event.target.value),
            discountCreator,
            hideBatchDiscounts,
            onHideBatchDiscountsChange: (event: React.ChangeEvent<HTMLInputElement>) =>
              setHideBatchDiscounts(event.target.checked),
            userCanCreateDiscounts: userCanEdit,
          },
        }}
      />
    </div>
  );
};

export default ProductDiscounts;
