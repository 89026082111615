import { Tooltip } from '@mui/material';
import {
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import ReactCountryFlag from 'react-country-flag';
import { AftershipStatus, CARRIERS } from '../../../../constants/tracking';
import { getAlpha2FromAlpha3, getCountryNameFromAlpha2 } from '../../../../utils/countryUtils';
import { formatUTCToLocal } from '../../../../utils/datetime';
import StatusBadge from '../../../components/badges/StatusBadge';
import TrackingLink, { getCarrierName } from '../../../components/links/TrackingLink';

export const TRACKING_TABLE_COLUMNS = [
  {
    id: 'tracking_labels_shipmentId',
    headerName: 'Shipment ID',
    field: 'shipmentId',
    minWidth: 150,
    sortable: false,
    filterable: false,
  },
  {
    id: 'tracking_labels_invoiceId',
    headerName: 'Invoice ID',
    field: 'invoiceId',
    minWidth: 150,
    sortable: false,
    filterable: false,
  },
  {
    id: 'tracking_labels_paymentMethod',
    headerName: 'Payment Method',
    field: 'paymentMethod',
    minWidth: 150,
    sortable: false,
    filterable: false,
  },
  {
    id: 'tracking_labels_carrier',
    headerName: 'Carrier',
    field: 'carrier',
    minWidth: 100,
    sortable: false,
    type: 'singleSelect',
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'is'),
    valueOptions: Object.keys(CARRIERS),
    getOptionLabel: (option: string) => CARRIERS?.[option] || option,
    valueGetter: (params: GridValueGetterParams) => getCarrierName(params.row.tracking?.carrier),
  },
  {
    id: 'tracking_labels_trackingNumber',
    headerName: 'Tracking Number',
    field: 'trackingNumber',
    minWidth: 160,
    sortable: false,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
    renderCell: (params: GridRenderCellParams) => (
      <TrackingLink trackingNumber={params.row.tracking?.id} carrier={params.row.tracking?.carrier} />
    ),
  },
  {
    id: 'common_labels_status',
    headerName: 'Status',
    field: 'state',
    minWidth: 150,
    sortable: false,
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'is'),
    type: 'singleSelect',
    valueOptions: Object.keys(AftershipStatus),
    renderCell: (params: GridRenderCellParams) => (
      <StatusBadge status={params.row.tracking?.state} showIcon size='small' />
    ),
  },
  {
    id: 'tracking_labels_origin',
    headerName: 'Origin',
    field: 'originCountryCode',
    sortable: false,
    filterable: false,
    renderCell: (params: GridRenderCellParams) => {
      const countryCode = getAlpha2FromAlpha3(params?.row?.tracking?.originCountryCode) || '';
      return (
        <Tooltip title={getCountryNameFromAlpha2(countryCode) || ''} arrow>
          <div>
            <ReactCountryFlag countryCode={countryCode} style={{ fontSize: '20px', lineHeight: '1em' }} />
          </div>
        </Tooltip>
      );
    },
  },
  {
    id: 'tracking_labels_destination',
    headerName: 'Destination',
    field: 'destCountryCode',
    sortable: false,
    filterable: false,
    renderCell: (params: GridRenderCellParams) => {
      const countryCode = getAlpha2FromAlpha3(params?.row?.tracking?.destCountryCode) || '';
      return (
        <Tooltip title={getCountryNameFromAlpha2(countryCode) || ''} arrow>
          <div>
            <ReactCountryFlag countryCode={countryCode} style={{ fontSize: '20px', lineHeight: '1em' }} />
          </div>
        </Tooltip>
      );
    },
  },
  {
    id: 'tracking_labels_expectedDeliveryDate',
    headerName: 'Expected Delivery Date',
    field: 'expectedDelivery',
    minWidth: 150,
    sortable: false,
    filterable: false,
    valueGetter: (params: GridValueGetterParams) => formatUTCToLocal(params.row.tracking?.expectedDelivery),
  },
  {
    id: 'common_labels_createdAt',
    headerName: 'Created At',
    field: 'createdAt',
    minWidth: 150,
    sortable: false,
    filterable: false,
    valueGetter: (params: GridValueGetterParams) => formatUTCToLocal(params.row.tracking?.createdAt),
  },
];

export const TRACKING_FILTER_QUERY_MAP: Record<string, any> = {
  trackingNumber: 'tracking_id',
  purchaseOrderId: 'purchase_order_id',
  orderId: 'order_id',
  transferOrderId: 'transfer_order_id',
  inventoryId: 'inventory_id',
  state: 'status',
  carrier: 'carrier',
};
