import { getDefaultFromSlug, unslugify } from '@chronext/react-common';
import { ButtonGroup } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { pricingConfigSelector } from '../../../selectors/pricing/pricing.selector';
import { useAppDispatch, useAppSelector } from '../../../state';
import { getPricingConfigAction } from '../../../state/ducks/pricing/actions';
import DeleteMarginConfigItemButton from '../../components/buttons/DeleteMarginConfigItemButton';
import EditMarginConfigItemButton from '../../components/buttons/EditMarginConfigItemButton';
import ActionStatusSnackbar from '../../components/snackbars/ActionStatusSnackbar';
import ServerSidePaginatedTable, {
  ServerSidePaginatedTableColDef,
} from '../../components/table/ServerSidePaginatedTable';
import PriceCalculatorMarginConfigDetailPanel from './components/PriceCalculatorMarginConfigDetailPanel';
import PricingCalculatorConfigListTableToolbar from './components/PricingCalculatorConfigListTableToolbar';
import styles from './PriceCalculator.module.scss';

const marginConfigTableColumns: ServerSidePaginatedTableColDef[] = [
  {
    id: 'common_labels_id',
    headerName: 'ID',
    field: 'id',
    maxWidth: 60,
    sortable: false,
    flex: 1,
  },
  {
    id: 'common_labels_brand',
    headerName: 'Brand',
    field: 'brandOriginal',
    renderCell: (params: GridRenderCellParams) =>
      String(getDefaultFromSlug(params.row.brandOriginal, '').brand || '') || unslugify(params.row.brandOriginal),
    minWidth: 150,
    sortable: false,
    flex: 1,
  },
  {
    id: 'common_labels_model',
    headerName: 'Model',
    field: 'modelOriginal',
    renderCell: (params: GridRenderCellParams) =>
      String(getDefaultFromSlug(params.row.brandOriginal, params.row.modelOriginal)?.model || '') ||
      unslugify(params.row.modelOriginal),
    minWidth: 150,
    sortable: false,
    flex: 1,
  },
  {
    id: 'common_labels_reference',
    headerName: 'Reference',
    field: 'referenceOriginal',
    minWidth: 150,
    sortable: false,
    flex: 1,
  },
];

const PriceCalculatorMarginConfigList: React.FC = () => {
  const dispatch = useAppDispatch();
  const { marginItems, totalCount } = useAppSelector(pricingConfigSelector);

  const [tableColumns, setTableColumns] = useState<ServerSidePaginatedTableColDef[]>(marginConfigTableColumns);

  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [searchParams] = useState({
    where: '',
  });

  const getDetailPanelContent = useCallback(({ row }) => <PriceCalculatorMarginConfigDetailPanel row={row} />, []);

  useEffect(() => {
    if (!marginItems?.length) {
      const urlSearchParams = new URLSearchParams();
      dispatch(getPricingConfigAction(urlSearchParams));
    }
  }, []);

  useEffect(() => {
    setTableColumns([...marginConfigTableColumns, actionsColumn]);
  }, [marginItems.length]);

  const actionsColumn: ServerSidePaginatedTableColDef = {
    id: 'common_actions',
    headerName: 'Actions',
    field: 'actions',
    align: 'left',
    sortable: false,
    renderCell: (params: any) => {
      return (
        <ButtonGroup variant='text'>
          <EditMarginConfigItemButton item={params.row} />
          {params.row.isDeletable && <DeleteMarginConfigItemButton item={params.row} />}
        </ButtonGroup>
      );
    },
  };

  return (
    <div className={styles.wrapper}>
      <ActionStatusSnackbar actionName={getPricingConfigAction.typePrefix} />
      <ServerSidePaginatedTable
        rows={marginItems}
        columns={tableColumns}
        totalCount={totalCount}
        triggerRefresh={triggerRefresh}
        fetchDataEffect={getPricingConfigAction}
        searchParams={searchParams}
        slots={{
          toolbar: PricingCalculatorConfigListTableToolbar,
        }}
        slotProps={{
          toolbar: {
            onSuccess: () => setTriggerRefresh(!triggerRefresh),
          },
        }}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => 'auto'}
      />
    </div>
  );
};

export default PriceCalculatorMarginConfigList;
