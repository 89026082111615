import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { GridFilterModel, useGridApiRef } from '@mui/x-data-grid-pro';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
  WORKSHOP_INBOUND_DASHBOARD_OVERVIEW,
  WORKSHOP_INBOUND_DASHBOARD_TABLE,
} from '../../../../constants/localStorage';
import { WorkshopReportType } from '../../../../constants/workshop';
import useSearchStoreHistory from '../../../../hooks/useSearchStoreHistory';
import { ClientRoute } from '../../../../routing';
import {
  workshopInboundReportItemOverviewSelector,
  workshopInboundWatchesSelector,
} from '../../../../selectors/workshop-inbound/workshop-inbound.selector';
import { useAppDispatch, useAppSelector } from '../../../../state';
import {
  getWorkshopInboundReportItemOverviewAction,
  getWorkshopInboundWatchesAction,
} from '../../../../state/ducks/workshopInbound/actions';
import { WorkshopInboundActions } from '../../../../state/ducks/workshopInbound/types';
import Price from '../../../components/price/Price';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';
import { defaultSearchParamsAll } from './inboundDashboardTableHelper';
import WorkshopInboundDashboardToolbar from './WorkshopInboundDashboardToolbar';

const WorkshopInboundReportItemOverview: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const reportData = useAppSelector(workshopInboundReportItemOverviewSelector);
  const workshopWatches = useAppSelector(workshopInboundWatchesSelector);

  const apiRef = useGridApiRef();
  const { searchParams, updateSearchFilters, initialFiltersParsed } = useSearchStoreHistory({
    storeName: WORKSHOP_INBOUND_DASHBOARD_OVERVIEW,
    defaultSearchParams: { ...defaultSearchParamsAll, report_type: WorkshopReportType.watch_overview },
    apiRef,
  });

  const onFilterUpdate = (start: string, end: string) => {
    const filterModel: GridFilterModel = {
      items: [
        {
          id: 'start_date',
          field: 'start_date',
          operator: '>=',
          value: start,
        },
        {
          id: 'end_date',
          field: 'end_date',
          operator: '<=',
          value: end,
        },
        {
          id: 'report_type',
          field: 'report_type',
          operator: 'is',
          value: WorkshopReportType.watch_overview,
        },
      ],
    };
    updateSearchFilters(filterModel);
  };

  useEffect(() => {
    const params = new URLSearchParams();
    for (const [param, val] of Object.entries(searchParams)) {
      if (val) {
        params.set(param, val.toString());
      } else {
        params.delete(param);
      }
    }
    dispatch(getWorkshopInboundReportItemOverviewAction(params));
  }, [searchParams]);

  useEffect(() => {
    const params = new URLSearchParams({ show_empty_sn: 'false', backlog: 'true', show_total_value: 'true' });
    dispatch(getWorkshopInboundWatchesAction(params));
  }, []);

  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      <ActionStatusSnackbar actionName={WorkshopInboundActions.getWorkshopInboundReportOverview} />
      <Stack spacing={2}>
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={3}>
            <Card>
              <CardContent>
                <Stack direction='row' spacing={1} mb={2}>
                  <Typography variant='h5' component='div'>
                    <FormattedMessage id='workshopInbound_labels_backlog' defaultMessage='Backlog' />
                  </Typography>
                </Stack>
                <Stack direction='row' spacing={1}>
                  <Typography>
                    <FormattedMessage id='common_labels_count' defaultMessage='Count' />:
                  </Typography>
                  <Typography>{workshopWatches.count}</Typography>
                </Stack>
                <Stack direction='row' spacing={1}>
                  <Typography>
                    <FormattedMessage id='common_labels_value' defaultMessage='Value' />:
                  </Typography>
                  <Typography>
                    <Price value={Number(workshopWatches?.total_value)} />
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <WorkshopInboundDashboardToolbar
          onDateFilterUpdate={onFilterUpdate}
          metaData={reportData.metaData}
          params={initialFiltersParsed}
          type={WorkshopReportType.watch_overview}
        />
        {reportData.items && (
          <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
            {Object.entries(reportData?.items)?.map(([key, value]) => (
              <Grid item xs={2} sm={4} md={4} key={key}>
                <Card
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    const urlParams = new URLSearchParams({
                      completed: key === 'watches_completed' ? 'true' : 'false',
                    });
                    history.push({
                      pathname: ClientRoute.WORKSHOP_INBOUND_DASHBOARD_ITEMS,
                      search: new URLSearchParams(urlParams).toString(),
                    });
                  }}>
                  <CardContent>
                    <Stack direction='row' spacing={1} mb={2}>
                      <Typography variant='h5' component='div'>
                        {['watches_received', 'watches_completed'].includes(key) && (
                          <FormattedMessage id={`workshopInbound_labels_itemOverview_${key}`} defaultMessage={key} />
                        )}
                      </Typography>
                    </Stack>
                    <Stack direction='row' spacing={1}>
                      <Typography>
                        <FormattedMessage id='common_labels_count' defaultMessage='Count' />:
                      </Typography>
                      <Typography>{value.count}</Typography>
                    </Stack>
                    <Stack direction='row' spacing={1}>
                      <Typography>
                        <FormattedMessage id='common_labels_value' defaultMessage='Value' />:
                      </Typography>
                      <Typography>
                        <Price value={Number(value.value)} />
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Stack>
    </Box>
  );
};

export default WorkshopInboundReportItemOverview;
