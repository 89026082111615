import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../../state';
import {
  cancelSellRequestAction,
  updateSellRequestOfferExpirationDateAction,
} from '../../../../state/ducks/sell/actions';
import { SellRequest } from '../../../../state/ducks/sell/types';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  sellRequest: SellRequest;
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
};

const initialFormData = {
  daysLimit: 7,
};

const UpdateExpirationModal: React.FC<Props> = ({ show, handleToggleModal, sellRequest, onSuccess }) => {
  const dispatch = useAppDispatch();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const clearForm = () => {
    setFormData(initialFormData);
  };

  const updateExpiration = () => {
    if (!formData.daysLimit) return;
    if (!sellRequest) {
      return;
    }
    // Convert numeric strings to numbers and prices to cents
    const params = {
      reference: sellRequest.reference.toString(),
      daysLimit: Number(formData.daysLimit),
    };
    // send a request to save the details
    dispatch(updateSellRequestOfferExpirationDateAction(params)).then((response) => {
      setShowSnackbar(true);
      if (response.payload) {
        clearForm();
        handleToggleModal();

        onSuccess();
      }
    });
  };

  useEffect(() => {
    if (sellRequest?.offer?.expirationDaysLimit) {
      setFormData({ daysLimit: sellRequest.offer?.expirationDaysLimit });
    } else {
      clearForm();
    }
  }, [show]);

  return (
    <>
      {showSnackbar && <ActionStatusSnackbar actionName={updateSellRequestOfferExpirationDateAction.typePrefix} />}
      <Dialog open={show} onClose={handleToggleModal}>
        <DialogTitle>
          <FormattedMessage
            id='sellRequests_updateExpirationModal_title'
            defaultMessage='Update Expiration of the Offer for Sell Request {reference}'
            values={{ reference: sellRequest.sellRequestId || sellRequest.reference }}
          />
        </DialogTitle>
        <DialogContent>
          <TextField
            type='number'
            margin='dense'
            name='daysLimit'
            value={formData.daysLimit}
            inputProps={{
              min: 0,
              step: 1,
            }}
            onChange={handleChange}
            label={
              <FormattedMessage id='sellRequests_labels_expirationDaysLimit' defaultMessage='Expiration in days' />
            }
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleModal}>
            <FormattedMessage id='common_close' defaultMessage='Close' />
          </Button>
          <Button color='info' onClick={updateExpiration} disabled={!formData.daysLimit}>
            <FormattedMessage id='common_save' defaultMessage='Save' />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateExpirationModal;
