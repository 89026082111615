import AddIcon from '@mui/icons-material/Add';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../../state';
import { createCategoryAction } from '../../../../state/ducks/commercetools/actions';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  onSuccess: () => void;
};

const initialFormData = {
  name: '',
  key: '',
};

const CreateCategoryModal: React.FC<Props> = ({ onSuccess }) => {
  const dispatch = useAppDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [isFormValid, setIsFormValid] = useState(false);

  const clearForm = () => {
    setFormData(initialFormData);
  };

  const handleClickOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const submitForm = () => {
    dispatch(createCategoryAction(formData)).then((response) => {
      setShowSnackbar(true);
      if (response?.payload && !response?.payload?.errors) {
        clearForm();
        setModalOpen(false);
        onSuccess();
      }
    });
  };

  useEffect(() => {
    if (formData.key && formData.name) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formData]);

  return (
    <>
      {showSnackbar && <ActionStatusSnackbar actionName={createCategoryAction.typePrefix} />}
      <Button startIcon={<AddIcon />} color='success' variant='outlined' onClick={handleClickOpen}>
        <FormattedMessage id='productCategories_actions_create' defaultMessage='Create new Category' />
      </Button>
      <Dialog open={modalOpen} onClose={handleClose}>
        <DialogTitle>
          <FormattedMessage id='productCategories_createModal_title' defaultMessage='New Category' />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage
              id='productCategories_createModal_description'
              defaultMessage='To add a new Category, please fill out the fields below.'
            />
          </DialogContentText>
          <TextField
            margin='dense'
            id='name'
            name='name'
            value={formData.name}
            onChange={handleChange}
            label={<FormattedMessage id='productCategories_labels_name' defaultMessage='Name' />}
            fullWidth
          />
          <TextField
            margin='dense'
            id='key'
            name='key'
            value={formData.key}
            onChange={handleChange}
            label={<FormattedMessage id='productCategories_labels_key' defaultMessage='Key' />}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='error'>
            <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
          </Button>
          <Button onClick={submitForm} disabled={!isFormValid} color='success'>
            <FormattedMessage id='common_save' defaultMessage='Save' />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateCategoryModal;
