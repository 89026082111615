import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { commercetoolsCategoryProductCountSelector } from '../../../../selectors/commercetools';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { clearCategoryAction, getCategoryProductCountAction } from '../../../../state/ducks/commercetools/actions';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  categoryKey: string;
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
};

const ClearCategoryModal: React.FC<Props> = ({ categoryKey, handleToggleModal, show, onSuccess }) => {
  const dispatch = useAppDispatch();
  const categoryProductCount = useAppSelector(commercetoolsCategoryProductCountSelector);
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    dispatch(getCategoryProductCountAction(categoryKey));
  }, []);

  return (
    <>
      {showSnackbar && (
        <ActionStatusSnackbar actionName={clearCategoryAction.typePrefix} config={{ messageValues: { categoryKey } }} />
      )}
      <Dialog open={show} onClose={handleToggleModal}>
        <DialogTitle>
          {categoryProductCount > 0 ? (
            <FormattedMessage
              id='productCategories_clearModal_title'
              defaultMessage='Clear Category "{category}"?'
              values={{ category: categoryKey }}
            />
          ) : (
            <FormattedMessage
              id='productCategories_clearModal_emptyText'
              defaultMessage='Action not available, this category is empty'
            />
          )}
        </DialogTitle>
        {categoryProductCount > 0 && (
          <DialogContent>
            <DialogContentText sx={{ fontWeight: 'medium' }}>
              <FormattedMessage id='common_warning_noUndo' defaultMessage='This action cannot be undone.' />
            </DialogContentText>
            <DialogContentText>
              <FormattedMessage
                id='productCategories_clearModal_deleteItemsText'
                defaultMessage='{count, plural, one {# product} other {# products}} will be cleared from this category.'
                values={{ count: categoryProductCount }}
              />
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleToggleModal}>
            {categoryProductCount > 0 ? (
              <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
            ) : (
              <FormattedMessage id='common_ok' defaultMessage='Ok' />
            )}
          </Button>
          {categoryProductCount > 0 && (
            <Button
              onClick={() => {
                dispatch(clearCategoryAction(categoryKey)).then((response) => {
                  setShowSnackbar(true);
                  if (response?.payload) {
                    handleToggleModal();
                    onSuccess();
                  }
                });
              }}
              color='error'>
              <FormattedMessage id='common_clear' defaultMessage='Clear' />
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClearCategoryModal;
