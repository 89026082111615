import { setupWorker } from 'msw';
import { rest } from 'msw';
// import * as MockAPI from './index';

export const worker = setupWorker(
  // ...MockAPI.someMockAPI,

  // Other requests without a mock handler
  rest.get('*', (req, res, ctx) => {
    console.log(req);
  }),
);
