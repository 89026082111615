import { createReducer } from '@reduxjs/toolkit';
import {
  getCategoriesAction,
  getCategoryProductCountAction,
  getDiscountBatchJobAction,
  getDiscountBatchJobsAction,
  getDiscountCodesAction,
} from './actions';
import {
  CommercetoolsCategories,
  CommercetoolsDiscountBatchJob,
  CommercetoolsDiscountBatchJobs,
  CommercetoolsDiscountCodes,
  CommercetoolsState,
} from './types';

export const initialState: CommercetoolsState = {
  categoriesTotal: 0,
  categories: [] as CommercetoolsCategories,
  categoryProductCount: 0,
  discountCodesTotal: 0,
  discountCodes: [] as CommercetoolsDiscountCodes,
  discountBatchJobsTotal: 0,
  discountBatchJobs: [] as CommercetoolsDiscountBatchJobs,
  discountBatchJob: {} as CommercetoolsDiscountBatchJob,
};

export const commercetoolsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getCategoriesAction.fulfilled, (state, action) => {
      state.categoriesTotal = action.payload?.meta?.total || action.payload?.data?.length || 0;
      state.categories = action.payload?.data || [];
    })
    .addCase(getCategoryProductCountAction.fulfilled, (state, action) => {
      state.categoryProductCount = action.payload;
    })
    .addCase(getDiscountCodesAction.fulfilled, (state, action) => {
      state.discountCodesTotal = action.payload?.meta?.total || action.payload?.data?.length || 0;
      state.discountCodes = action.payload?.data || [];
    })
    .addCase(getDiscountBatchJobsAction.fulfilled, (state, action) => {
      state.discountBatchJobsTotal = action.payload?.meta?.total || action.payload?.data?.length || 0;
      state.discountBatchJobs = action.payload?.data || [];
    })
    .addCase(getDiscountBatchJobAction.fulfilled, (state, action) => {
      state.discountBatchJob =
        action.payload?.data && action.payload?.meta ? { ...action.payload?.data, ...action.payload?.meta } : {};
    });
});

export default commercetoolsReducer;
