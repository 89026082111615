import {
  GridFilterModel,
  GridLogicOperator,
  GridRenderCellParams,
  GridRowParams,
  GridValueGetterParams,
  getGridSingleSelectOperators,
  getGridStringOperators,
} from '@mui/x-data-grid';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { WORKSHOP_INBOUND_TABLE } from '../../../constants/localStorage';
import { WorkshopActivities, WorkshopServiceTypes, WorkshopStatus } from '../../../constants/workshop';
import useSearchStoreHistory from '../../../hooks/useSearchStoreHistory';
import { ClientRoute, createPath } from '../../../routing';
import {
  refetchActivitiesSelector,
  workshopInboundActivitiesSelector,
} from '../../../selectors/workshop-inbound/workshop-inbound.selector';
import { useAppSelector } from '../../../state';
import { getWorkshopInboundActivitiesAction } from '../../../state/ducks/workshopInbound/actions';
import { formatUTCToLocal } from '../../../utils/datetime';
import { text2title } from '../../../utils/textUtils';
import StatusBadge from '../../components/badges/StatusBadge';
import ActionStatusSnackbar from '../../components/snackbars/ActionStatusSnackbar';
import ServerSidePaginatedTable, {
  ServerSidePaginatedTableColDef,
} from '../../components/table/ServerSidePaginatedTable';
import styles from './WorkshopInbound.module.scss';
import {
  WorkshopInboundTableToolbar,
  defaultMaintenanceSearchParams,
  defaultSearchParams,
  sortFunction,
} from './components/WorkshopInboundTableToolbar';

const tableColumns: (activity: string) => ServerSidePaginatedTableColDef[] = (activity) => {
  const activityOptions: any = { ...WorkshopActivities };
  if (activity !== WorkshopActivities.maintenance) {
    delete activityOptions.maintenance;
  }
  return [
    {
      id: 'common_labels_id',
      headerName: 'ID',
      field: 'id',
      minWidth: 50,
      sortable: false,
      filterable: false,
    },
    {
      id: 'workshop_labels_activity',
      headerName: 'Activity',
      field: 'activity_type',
      minWidth: 150,
      filterable: activity !== WorkshopActivities.maintenance,
      filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'is'),
      type: 'singleSelect',
      valueOptions: Object.keys(activityOptions),
      renderCell: (params) => (
        <FormattedMessage
          id={`workshop_serviceActivityTypes_${params.value}`}
          defaultMessage={text2title(params.value)}
        />
      ),
    },
    {
      id: 'common_labels_status',
      headerName: 'status',
      field: 'status',
      minWidth: 150,
      filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'is'),
      type: 'singleSelect',
      valueOptions: Object.keys(WorkshopStatus),
      renderCell: (params: GridRenderCellParams) => <StatusBadge status={params.value} />,
    },
    {
      id: 'workshop_labels_assignee',
      headerName: 'Assignee',
      field: 'assignee',
      minWidth: 150,
      filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
    },
    {
      id: 'common_labels_brand',
      headerName: 'Brand',
      field: 'brand',
      minWidth: 150,
      sortable: false,
      filterable: false,
      valueGetter: (params) => params.row.watch.brand,
    },
    {
      id: 'common_labels_model',
      headerName: 'Model',
      field: 'model',
      minWidth: 150,
      sortable: false,
      filterable: false,
      valueGetter: (params) => params.row.watch.model,
    },
    {
      id: 'common_labels_reference',
      headerName: 'Reference',
      field: 'reference',
      minWidth: 150,
      sortable: false,
      filterable: false,
      valueGetter: (params) => params.row.watch.reference,
    },
    {
      id: 'common_labels_serialNumber',
      headerName: 'Inventory ID',
      field: 'serial_number',
      filterable: activity === WorkshopActivities.maintenance ? false : true,
      minWidth: 150,
      sortable: false,
      filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
      valueGetter: (params) => params.row.watch.serial_number,
    },
    {
      id: 'workshop_labels_service',
      headerName: 'Service',
      filterable: activity === WorkshopActivities.maintenance ? false : true,
      field: 'service_type',
      minWidth: 150,
      filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'is'),
      type: 'singleSelect',
      valueOptions: Object.keys(WorkshopServiceTypes),
      renderCell: (params) =>
        params.row?.service?.service_type && (
          <FormattedMessage
            id={`workshop_serviceActivityTypes_${params.row?.service?.service_type}`}
            defaultMessage={text2title(params.row?.service?.service_type)}
          />
        ),
    },
    {
      id: 'common_labels_updatedBy',
      headerName: 'Updated By',
      field: 'updated_by',
      minWidth: 150,
      sortable: false,
      filterable: false,
    },
    {
      id: 'common_labels_createdAt',
      headerName: 'Created At',
      field: 'created_at',
      minWidth: 150,
      valueGetter: ({ value }: GridValueGetterParams) => value && formatUTCToLocal(value),
    },
  ];
};

type Props = {
  activity: string;
};

const WorkshopInboundActivitiesTable: React.FC<Props> = ({ activity = 'inventory' }: { activity: string }) => {
  const activities = useAppSelector(workshopInboundActivitiesSelector);
  const refetchActivities = useAppSelector(refetchActivitiesSelector);
  const history = useHistory();
  const intl = useIntl();
  const apiRef = useGridApiRef();

  const defaultSearchParamsActivities =
    activity === WorkshopActivities.maintenance ? defaultMaintenanceSearchParams : defaultSearchParams;

  const {
    searchParams,
    updateSearchFilters,
    updateSort,
    resetSearch,
    initialFilters,
    initialSorting,
    hasSavedSearch,
    shareSearch,
  } = useSearchStoreHistory({
    storeName: `${WORKSHOP_INBOUND_TABLE}_activities_${activity}`,
    defaultSearchParams: defaultSearchParamsActivities,
    sortFunction,
    apiRef,
    QUERY_MAP: {
      show_maintenance_activity: 'show_maintenance_activity',
    },
  });

  const defaultTableColumns = useMemo(() => {
    return tableColumns(activity);
  }, []);

  const [columns, setColumns] = useState<ServerSidePaginatedTableColDef[]>([...defaultTableColumns]);

  const handleFilterModelChange = debounce((filterModel: GridFilterModel) => {
    updateSearchFilters(filterModel);
  }, 150);

  useEffect(() => {
    const newCols = tableColumns(activity).map((col) => {
      if (['status', 'activity_type', 'service_type'].includes(col.field)) {
        return {
          ...col,
          getOptionLabel: (option: string) =>
            option &&
            intl.formatMessage({
              id: col.field === 'status' ? `status_${option}` : `workshop_serviceActivityTypes_${option}`,
              defaultMessage: text2title(option),
            }),
        };
      }
      return col;
    });
    setColumns(newCols);
  }, []);

  const onResetSearch = () => {
    resetSearch();
  };

  return (
    <div className={styles.wrapper}>
      <ActionStatusSnackbar actionName={getWorkshopInboundActivitiesAction.typePrefix} />
      <ServerSidePaginatedTable
        apiRef={apiRef}
        rows={activities.items}
        columns={columns}
        totalCount={activities.count}
        fetchDataEffect={getWorkshopInboundActivitiesAction}
        searchParams={searchParams}
        triggerRefresh={refetchActivities}
        onRowClick={(params: GridRowParams) => {
          const path = createPath({
            path: ClientRoute.WORKSHOP_INBOUND_ACTIVITY,
            params: { reference: params.row.id },
          });
          history.push(path);
        }}
        slots={{
          toolbar: WorkshopInboundTableToolbar,
        }}
        slotProps={{
          toolbar: {
            showFilterButtons: hasSavedSearch,
            handleResetClick: onResetSearch,
            handleShareClick: shareSearch,
          },
          filterPanel: {
            logicOperators: [GridLogicOperator.And],
          },
        }}
        sortingMode='server'
        onSortModelChange={updateSort}
        filterMode='server'
        onFilterModelChange={handleFilterModelChange}
        initialState={{
          filter: {
            filterModel: {
              items: initialFilters,
            },
          },
          sorting: {
            sortModel: initialSorting,
          },
        }}
      />
    </div>
  );
};

export default WorkshopInboundActivitiesTable;
