import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Button, Card, CardActions, CardContent, Collapse, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '../../../../state';
import { WorkshopLocationOverviewItem } from '../../../../state/ducks/workshop/types';
import styles from './AreaOverviewCard.module.scss';

type Props = {
  name: string;
  total: number;
  overview: WorkshopLocationOverviewItem[];
};

const AreaOverviewCard: React.FC<Props> = ({ name, total, overview }) => {
  const [open, setOpen] = React.useState(false);
  const locations = useAppSelector((store) => store.workshop.workshopLocations);

  return (
    <Grid item xs={2} sm={4} md={3}>
      <Card className={styles.cardWrapper} variant='outlined'>
        <CardContent>
          <Stack direction='row' spacing={0.5} alignItems='center' justifyContent='space-between'>
            <Typography fontWeight='medium' variant='h6' component='div'>
              {name}
            </Typography>
            <Typography fontWeight='medium' variant='h5' component='div'>
              {total}
            </Typography>
          </Stack>
        </CardContent>
        <CardActions>
          <Button onClick={() => setOpen(!open)} endIcon={open ? <ExpandLess /> : <ExpandMore />}>
            <FormattedMessage id='common_details' defaultMessage='Details' />
          </Button>
        </CardActions>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <CardContent>
            {overview.map((item) => {
              const location = locations.find((l) => l.reference === item.locationReference);
              return (
                <div key={item.locationReference}>
                  <Stack direction='row' spacing={0.5} justifyContent='space-between'>
                    <Typography fontWeight='medium' variant='body1'>
                      {location?.name}
                    </Typography>
                    <Typography fontWeight='medium' variant='body1'>
                      {item.countOfItems}
                    </Typography>
                  </Stack>
                </div>
              );
            })}
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
};

export default AreaOverviewCard;
