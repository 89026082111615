import { createAsyncThunk } from '@reduxjs/toolkit';
import { downloadBatchJobDiscountCodes } from '../../../api/server/commercetools';
import {
  createTrackingComment,
  downloadTrackingReport,
  getTrackingItem,
  getTrackingItems,
  GetTrackingReportParams,
  TrackingReportType,
  TrackingType,
  updateTrackingComment,
} from '../../../api/server/tracking';
import { downloadCSV } from '../../../utils/responseUtils';
import { TrackingActions } from './types';

export const getTrackingItemsSalesAction = createAsyncThunk(
  TrackingActions.getTrackingItemsSales,
  async (params: URLSearchParams) => {
    const response = await getTrackingItems(TrackingType.SALES, params);
    return response.data;
  },
);

export const getTrackingItemsPurchasesAction = createAsyncThunk(
  TrackingActions.getTrackingItemsPurchases,
  async (params: URLSearchParams) => {
    const response = await getTrackingItems(TrackingType.PURCHASES, params);
    return response.data;
  },
);

export const getTrackingItemSalesAction = createAsyncThunk(
  TrackingActions.getTrackingItemSales,
  async (reference: string) => {
    const response = await getTrackingItem(TrackingType.SALES, reference);
    return response.data;
  },
);

export const getTrackingItemPurchasesAction = createAsyncThunk(
  TrackingActions.getTrackingItemPurchases,
  async (reference: string) => {
    const response = await getTrackingItem(TrackingType.PURCHASES, reference);
    return response.data;
  },
);

export const getTrackingItemsTransfersAction = createAsyncThunk(
  TrackingActions.getTrackingItemsTransfers,
  async (params: URLSearchParams) => {
    const response = await getTrackingItems(TrackingType.TRANSFERS, params);
    return response.data;
  },
);

export const getTrackingItemTransfersAction = createAsyncThunk(
  TrackingActions.getTrackingItemTransfers,
  async (reference: string) => {
    const response = await getTrackingItem(TrackingType.TRANSFERS, reference);
    return response.data;
  },
);

type TrackingReportActionParams = Omit<GetTrackingReportParams, 'reportType'>;

export const downloadTrackingReportSalesAction = createAsyncThunk(
  TrackingActions.downloadTrackingReportSales,
  async (params: TrackingReportActionParams, { rejectWithValue }) => {
    try {
      const response = await downloadTrackingReport({ reportType: TrackingReportType.SALES, ...params });
      downloadCSV(response.data, `${params.startDate}_sales_report${params.state ? `_${params.state}` : ''}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const downloadTrackingReportPurchasesAction = createAsyncThunk(
  TrackingActions.downloadTrackingReportPurchases,
  async (params: TrackingReportActionParams, { rejectWithValue }) => {
    try {
      const response = await downloadTrackingReport({ reportType: TrackingReportType.PURCHASES, ...params });
      downloadCSV(response.data, `${params.startDate}_purchases_report${params.state ? `_${params.state}` : ''}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const downloadTrackingReportTransfersAction = createAsyncThunk(
  TrackingActions.downloadTrackingReportTransfers,
  async (params: TrackingReportActionParams, { rejectWithValue }) => {
    try {
      const response = await downloadTrackingReport({ reportType: TrackingReportType.TRANSFERS, ...params });
      downloadCSV(response.data, `${params.startDate}_transfers_report${params.state ? `_${params.state}` : ''}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createTrackingCommentAction = createAsyncThunk(
  TrackingActions.createTrackingComment,
  async (params: { reference: string; comment: string }) => {
    const response = await createTrackingComment(params);
    return response.data;
  },
);

export const updateTrackingCommentAction = createAsyncThunk(
  TrackingActions.updateTrackingComment,
  async (params: { reference: string; commentId: string; comment: string }) => {
    const response = await updateTrackingComment(params);
    return response.data;
  },
);
