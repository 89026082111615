import { Autocomplete, TextField } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { useIntl } from 'react-intl';
import { useAppSelector, useAppDispatch } from '../../../../state';
import { Product } from '../../../../state/ducks/inventory/types';
import { requestSelector } from '../../../../selectors/request';
import { inventoryProductsSelector } from '../../../../selectors/inventory';
import { getProductsAction } from '../../../../state/ducks/inventory/actions';

type Props = {
  label?: string;
  width?: number;
  onSelectChange: (value: Product | undefined) => void;
};

const ProductFilter: React.FC<Props> = ({ label, width, onSelectChange }) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { items: products } = useAppSelector(inventoryProductsSelector);
  const getProductsRequest = useAppSelector((state) => requestSelector(state, getProductsAction.typePrefix));

  const [selectedProductReference, setSelectedProductReference] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState('');

  const productReferenceList = products.map((product) => product.reference);

  useEffect(() => {
    const searchParams = new URLSearchParams();
    if (inputValue) {
      searchParams.set('search_key', inputValue);
    }
    callGetProductsAction(searchParams);
  }, [inputValue]);

  useEffect(() => {
    const selectedProductItem = products.find((product) => product.reference === selectedProductReference);
    onSelectChange(selectedProductItem);
  }, [selectedProductReference]);

  const callGetProductsAction = useCallback(
    debounce((searchParams) => {
      dispatch(getProductsAction(searchParams));
    }, 500),
    [],
  );

  return (
    <Autocomplete
      sx={{ width: width || 300, display: 'block' }}
      onChange={(_, value) => setSelectedProductReference(value)}
      onInputChange={(_, value) => setInputValue(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            label ||
            intl.formatMessage({
              id: 'workshop_inventory_labels_productReference',
              defaultMessage: 'Product Reference',
            })
          }
          size='small'
          margin='dense'
          inputProps={{ ...params.inputProps, style: { ...params.inputProps.style, textTransform: 'uppercase' } }}
        />
      )}
      options={productReferenceList}
      value={selectedProductReference}
      loading={getProductsRequest?.status === 'pending'}
    />
  );
};

export default ProductFilter;
