import { Box, Stack } from '@mui/material';
import { GridFilterModel, GridLogicOperator, GridRowParams } from '@mui/x-data-grid';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { debounce } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { SELL_REQUESTS_TABLE } from '../../../constants/localStorage';
import useSearchStoreHistory from '../../../hooks/useSearchStoreHistory';
import { ClientRoute, createPath } from '../../../routing';
import { requestSelector } from '../../../selectors/request';
import { sellRequestsSelector } from '../../../selectors/sell';
import { currentUserSelector } from '../../../selectors/user';
import { useAppDispatch, useAppSelector } from '../../../state';
import { getSellRequestsAction, refreshSWSSellRequestsAction } from '../../../state/ducks/sell/actions';
import { SellActions } from '../../../state/ducks/sell/types';
import { getCountryNameFromAlpha2 } from '../../../utils/countryUtils';
import ActionStatusSnackbar from '../../components/snackbars/ActionStatusSnackbar';
import ServerSidePaginatedTable, {
  ServerSidePaginatedTableColDef,
} from '../../components/table/ServerSidePaginatedTable';
import {
  SELL_REQUESTS_FILTER_PREFIXES,
  SELL_REQUESTS_QUERY_MAP,
  SELL_REQUESTS_TABLE_COLUMNS,
  SellRequestsTableToolbar,
  defaultSearchParams,
  sortFunction,
} from './components/sellRequestsTableHelper';

const tableColumns: ServerSidePaginatedTableColDef[] = [...SELL_REQUESTS_TABLE_COLUMNS];

type Props = {
  status: string;
};

const SellRequestsStatusTable: React.FC<Props> = ({ status }) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);
  const intl = useIntl();
  const sellRequests = useAppSelector(sellRequestsSelector);
  const refreshRequest = useAppSelector((state) => requestSelector(state, refreshSWSSellRequestsAction.typePrefix));
  const history = useHistory();
  const apiRef = useGridApiRef();

  const defaultSearchParamsStatus = {
    ...defaultSearchParams,
    sell_request_status: status,
  };

  const {
    searchParams,
    updateSearchFilters,
    updateSort,
    resetSearch,
    initialFilters,
    initialSorting,
    hasSavedSearch,
    shareSearch,
  } = useSearchStoreHistory({
    storeName: `${SELL_REQUESTS_TABLE}_${status}`,
    defaultSearchParams: defaultSearchParamsStatus,
    QUERY_MAP: SELL_REQUESTS_QUERY_MAP,
    sortFunction,
    apiRef,
  });

  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const columns = useMemo(() => {
    return tableColumns
      .filter((col) => {
        if (status === 'finalized') {
          return col.field !== 'sellingMethods';
        } else {
          return col.field !== 'offerSellingMethods';
        }
      })
      .map((col) => {
        if (['status', 'statusInfo'].includes(col.field)) {
          return {
            ...col,
            getOptionLabel: (option: string) =>
              option &&
              intl.formatMessage({
                id: `${SELL_REQUESTS_FILTER_PREFIXES[col.field]}${option}`,
                defaultMessage: option,
              }),
          };
        }
        if (['country'].includes(col.field)) {
          return {
            ...col,
            getOptionLabel: (option: string) => option && getCountryNameFromAlpha2(option, currentUser.language),
          };
        }
        return col;
      });
  }, [status]);

  const handleRefreshButtonClick = () => {
    dispatch(refreshSWSSellRequestsAction()).then((response) => {
      if (response?.meta?.requestStatus === 'fulfilled') {
        setTriggerRefresh(!triggerRefresh);
      }
    });
  };

  const handleFilterModelChange = debounce((filterModel: GridFilterModel) => {
    updateSearchFilters(filterModel);
  }, 150);

  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      <ActionStatusSnackbar actionName={SellActions.refreshSellRequests} />
      <Stack spacing={2} sx={{ height: '100%' }}>
        <Box sx={{ flex: 1 }}>
          <ServerSidePaginatedTable
            apiRef={apiRef}
            fetchDataEffect={getSellRequestsAction}
            columns={columns}
            rows={sellRequests.items}
            totalCount={sellRequests.total}
            initPageSize={50}
            searchParams={searchParams}
            triggerRefresh={triggerRefresh}
            onRowClick={(params: GridRowParams) => {
              const path = createPath({
                path: ClientRoute.SELL_DETAILS,
                params: { reference: params.row?.reference },
              });
              history.push(path);
            }}
            slots={{
              toolbar: SellRequestsTableToolbar,
            }}
            slotProps={{
              toolbar: {
                loading: refreshRequest?.status === 'pending',
                showFilterButtons: hasSavedSearch,
                handleRefreshClick: handleRefreshButtonClick,
                handleResetClick: resetSearch,
                handleShareClick: shareSearch,
              },
              filterPanel: {
                logicOperators: [GridLogicOperator.And],
              },
            }}
            sortingMode='server'
            onSortModelChange={updateSort}
            filterMode='server'
            onFilterModelChange={handleFilterModelChange}
            initialState={{
              filter: {
                filterModel: {
                  items: initialFilters,
                },
              },
              sorting: {
                sortModel: initialSorting,
              },
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default SellRequestsStatusTable;
