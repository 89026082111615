import { GridColDef } from '@mui/x-data-grid';
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { userLangSelector } from '../../../selectors/user';
import { useAppSelector } from '../../../state';
import { translateColumnLabels } from '../../../utils/translationUtils';
import { getDataGridLocaleText, getDataGridStyles } from './dataGridHelper';

export type SimpleTableColDef = GridColDef & {
  id?: string;
};

export type SimpleTableProps = Omit<DataGridProProps, 'columns'> & {
  columns: SimpleTableColDef[];
  initPageSize?: number;
};

const SimpleTable: React.FC<SimpleTableProps> = ({ columns, ...props }) => {
  const [formattedColumns, setFormattedColumns] = useState<GridColDef[]>([]);
  const [pageModel, setPageModel] = useState({
    page: 0,
    pageSize: props?.initPageSize || 25,
  });

  const intl = useIntl();
  const userLang = useAppSelector(userLangSelector);

  // Formats the header of the columns
  useEffect(() => {
    setFormattedColumns(translateColumnLabels(columns, intl));
  }, [columns, userLang]);

  return (
    <DataGridPro
      density='compact'
      hideFooterSelectedRowCount={true}
      getRowId={(row) => row?.reference || nanoid()}
      columns={formattedColumns}
      localeText={getDataGridLocaleText(intl)}
      paginationModel={pageModel}
      onPaginationModelChange={(model) => setPageModel(model)}
      sx={getDataGridStyles(!!props.onRowClick)}
      {...props}
    />
  );
};

export default SimpleTable;
