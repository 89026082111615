import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import * as React from 'react';
import { logout } from '../../../api/server/user';
import { APP_CONFIG } from '../../../config/app';
import { GROUPS } from '../../../constants/groups';
import { currentUserSelector, isAuthorizedSelector } from '../../../selectors/user';
import { useAppSelector } from '../../../state';
import ColorModeSwitcher from './components/ColorModeSwitcher';
import LanguageSwitcher from './components/LanguageSwitcher';
import MaintenanceActivityButton from './components/MaintenanceActivityButton';
import UserGroupSwitcher from './components/UserGroupSwitcher';

type Props = {
  handleToggle: () => void;
};

const TopBar: React.FC<Props> = (props) => {
  const currentUser = useAppSelector(currentUserSelector);

  const handleLogout = () => {
    localStorage.removeItem('token-issued-at');
    logout().finally(() => {
      window.location.reload();
    });
  };

  const isAuthorizedForMaintenance = useAppSelector((state) =>
    isAuthorizedSelector(state, [GROUPS.WORKSHOP_ADMIN, GROUPS.WORKSHOP_EMPLOYEE], APP_CONFIG.isProd),
  );

  return (
    <AppBar position='sticky' elevation={0}>
      <Toolbar variant='dense'>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          width='100%'>
          <div>
            <IconButton
              size='small'
              color='inherit'
              edge='start'
              onClick={props.handleToggle}
              sx={{ mr: 1, display: { sm: 'none' } }}>
              <MenuIcon />
            </IconButton>
            <LanguageSwitcher />
          </div>
          <Stack direction='row' alignItems='center' spacing={1}>
            {isAuthorizedForMaintenance && <MaintenanceActivityButton />}
            <Typography color='inherit' variant='button'>
              {currentUser.username}
            </Typography>
            {currentUser.groups.includes(GROUPS.ADMIN) && <UserGroupSwitcher />}
            <ColorModeSwitcher />
            <IconButton
              size='small'
              aria-label='logout'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleLogout}
              color='inherit'>
              <LogoutIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
export default TopBar;
