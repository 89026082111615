import { Stack } from '@mui/material';
import { Moment } from 'moment';
import React from 'react';
import { requestSelector } from '../../../../selectors/request';
import { employeeReportSelector } from '../../../../selectors/workshop';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { getEmployeeReportAction } from '../../../../state/ducks/workshop/actions';
import DateFilter from '../../../components/datetime/DateFilter';
import SimpleTable, { SimpleTableColDef } from '../../../components/table/SimpleTable';

const columns: SimpleTableColDef[] = [
  {
    id: 'workshop_reports_labels_employee',
    headerName: 'Employee',
    field: 'employee',
    minWidth: 150,
    flex: 1,
  },
  {
    id: 'workshop_reports_labels_scans',
    headerName: 'Scans',
    field: 'scans',
    minWidth: 200,
  },
  {
    id: 'workshop_reports_labels_scannedItems',
    headerName: 'Distinct Scans',
    field: 'scannedItems',
    minWidth: 200,
  },
  {
    id: 'workshop_reports_labels_elapsedTime',
    headerName: 'Elapsed Time',
    field: 'elapsedTime',
    minWidth: 150,
    flex: 1,
    sortable: false,
  },
  {
    id: 'workshop_reports_labels_startTime',
    headerName: 'Start Time',
    field: 'startTime',
    minWidth: 150,
    flex: 1,
    sortable: false,
  },
  {
    id: 'workshop_reports_labels_endTime',
    headerName: 'End Time',
    field: 'endTime',
    minWidth: 150,
    flex: 1,
    sortable: false,
  },
];

const EmployeeActivityReport: React.FC = () => {
  const dispatch = useAppDispatch();
  const employeeReportData = useAppSelector(employeeReportSelector);
  const requestState = useAppSelector((state) => {
    return requestSelector(state, getEmployeeReportAction.typePrefix);
  });

  // dispatch action when the date filter is updated
  const onFilterUpdate = (start: Moment, end: Moment) => {
    dispatch(
      getEmployeeReportAction({
        startTime: start.toISOString(),
        endTime: end.toISOString(),
      }),
    );
  };

  return (
    <Stack spacing={2} sx={{ position: 'relative', height: '100%' }}>
      <DateFilter onFilterUpdate={onFilterUpdate} />
      <SimpleTable columns={columns} rows={employeeReportData} loading={requestState?.status === 'pending'} />
    </Stack>
  );
};

export default EmployeeActivityReport;
