import { createReducer } from '@reduxjs/toolkit';
import {
  getTrackingItemPurchasesAction,
  getTrackingItemSalesAction,
  getTrackingItemsPurchasesAction,
  getTrackingItemsSalesAction,
  getTrackingItemsTransfersAction,
  getTrackingItemTransfersAction,
} from './actions';
import { TrackingState } from './types';

export const initialState: TrackingState = {
  countSales: 0,
  trackingItemsSales: [],
  trackingItemSales: undefined,
  countPurchases: 0,
  trackingItemsPurchases: [],
  trackingItemPurchases: undefined,
  countTransfers: 0,
  trackingItemsTransfers: [],
  trackingItemTransfers: undefined,
};

export const trackingReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getTrackingItemsSalesAction.fulfilled, (state, action) => {
      state.countSales = action.payload.count || action.payload.items.length;
      state.trackingItemsSales = action.payload.items;
    })
    .addCase(getTrackingItemSalesAction.fulfilled, (state, action) => {
      state.trackingItemSales = action.payload;
    })
    .addCase(getTrackingItemsPurchasesAction.fulfilled, (state, action) => {
      state.countPurchases = action.payload.count || action.payload.items.length;
      state.trackingItemsPurchases = action.payload.items;
    })
    .addCase(getTrackingItemPurchasesAction.fulfilled, (state, action) => {
      state.trackingItemPurchases = action.payload;
    })
    .addCase(getTrackingItemsTransfersAction.fulfilled, (state, action) => {
      state.countTransfers = action.payload.count || action.payload.items.length;
      state.trackingItemsTransfers = action.payload.items;
    })
    .addCase(getTrackingItemTransfersAction.fulfilled, (state, action) => {
      state.trackingItemTransfers = action.payload;
    });
});

export default trackingReducer;
