import { Warning } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import deLocale from 'date-fns/locale/de';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { UpdateDiscountCodeParams } from '../../../../api/server/commercetools';
import { DISCOUNT_CODES_BATCH_GROUP } from '../../../../constants/discountCodes';
import { requestSelector } from '../../../../selectors/request';
import { currentUserSelector } from '../../../../selectors/user';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { updateDiscountCodeAction } from '../../../../state/ducks/commercetools/actions';
import { CommercetoolsDiscountCode } from '../../../../state/ducks/commercetools/types';
import StatusBadge from '../../../components/badges/StatusBadge';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  discountCode: CommercetoolsDiscountCode | null;
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
};

type FormData = UpdateDiscountCodeParams & {
  groupsText: string;
};

const initialFormData: FormData = {
  groupsText: '',
  validFrom: new Date(),
  validUntil: new Date(new Date().setDate(new Date().getDate() + 1)),
  maxApplications: '',
};

const EditDiscountCodeModal: React.FC<Props> = ({ discountCode, handleToggleModal, show, onSuccess }) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);
  const updateRequest = useAppSelector((state) => requestSelector(state, updateDiscountCodeAction.typePrefix));

  const [formData, setFormData] = useState(initialFormData);
  const [submitData, setSubmitData] = useState<Partial<FormData>>({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const discountHasStarted =
    !discountCode?.validFrom || new Date().getTime() > new Date(discountCode?.validFrom).getTime();
  const previousDiscountValues: FormData = {
    groupsText: discountCode?.groups.filter((g) => g !== DISCOUNT_CODES_BATCH_GROUP).join(',') || '',
    validFrom: discountCode?.validFrom ? new Date(discountCode?.validFrom) : new Date(),
    validUntil: discountCode?.validUntil ? new Date(discountCode?.validUntil) : new Date(),
    maxApplications: discountCode?.maxApplications || '',
  };

  useEffect(() => {
    setFormData(previousDiscountValues);
  }, []);

  if (!discountCode) {
    return null;
  }

  const clearForm = () => {
    setFormData(initialFormData);
  };

  const validateForm = (data: FormData) => {
    const { groupsText, maxApplications, validFrom, validUntil } = data;
    const changedData: Partial<FormData> = {};

    const groupsArray = groupsText ? groupsText.split(',') : [];
    if (discountCode?.groups.includes(DISCOUNT_CODES_BATCH_GROUP)) {
      groupsArray.push(DISCOUNT_CODES_BATCH_GROUP);
    }
    groupsArray.sort((a: string, b: string) => a.localeCompare(b));
    const oldGroups = [...discountCode.groups];

    if (groupsArray.join(',') !== oldGroups.sort((a, b) => a.localeCompare(b)).join(',')) {
      changedData.groups = groupsArray;
    }

    const today = new Date(new Date().setHours(0, 0, 0, 0));

    if (discountHasStarted) {
      if (
        Number(discountCode?.maxApplications) !== 0 &&
        Number(maxApplications) > Number(discountCode?.maxApplications)
      ) {
        changedData.maxApplications = Number(maxApplications);
      }
      if (
        validUntil &&
        validUntil.getTime() !== new Date(discountCode.validUntil).getTime() &&
        validUntil.getTime() > today.getTime()
      ) {
        changedData.validUntil = validUntil;
      }
    } else {
      if (Number(maxApplications) > 0) {
        changedData.maxApplications = Number(maxApplications);
      }
      if (
        validFrom &&
        validFrom.getTime() !== new Date(discountCode.validFrom).getTime() &&
        validFrom.getTime() >= today.getTime()
      ) {
        changedData.validFrom = validFrom;
      }
      if (
        validUntil &&
        validUntil.getTime() !== new Date(discountCode.validUntil).getTime() &&
        ((changedData.validFrom && validUntil.getTime() > new Date(changedData.validFrom).getTime()) ||
          validUntil.getTime() > new Date(discountCode.validFrom).getTime())
      ) {
        changedData.validUntil = validUntil;
      }
    }
    if (Object.keys(changedData).length > 0) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
    setSubmitData(changedData);
  };

  const handleDateChange = (trigger: string, newDate?: Date | null | unknown) => {
    const date = newDate as Date;
    const newData = {
      [trigger]: date,
    };
    if (trigger === 'validFrom' && date && (!formData.validUntil || date >= formData.validUntil)) {
      newData.validUntil = new Date(new Date(date).setDate(date.getDate() + 1));
    }
    // preset times for validity dates
    if (newData.validFrom) {
      newData.validFrom.setHours(0, 0, 0);
    }
    if (newData.validUntil) {
      newData.validUntil.setHours(23, 59, 59);
    }

    const newFormData = { ...formData, ...newData };
    setFormData(newFormData);
    validateForm(newFormData);
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = { ...formData, [event.target.name]: event.target.value };
    setFormData(newFormData);
    validateForm(newFormData);
  };

  const submitForm = () => {
    const updateParams = { ...submitData };
    updateParams.modifiedBy = currentUser.username;
    dispatch(updateDiscountCodeAction({ code: discountCode.code, requestBody: updateParams })).then((response) => {
      setShowSnackbar(true);
      if (response?.payload && !response?.payload?.errors) {
        clearForm();
        handleToggleModal();
        onSuccess();
      }
    });
  };

  return (
    <>
      {showSnackbar && <ActionStatusSnackbar actionName={updateDiscountCodeAction.typePrefix} />}
      <Dialog open={show} onClose={() => updateRequest?.status !== 'pending' && handleToggleModal()}>
        <DialogTitle>
          <Stack direction='row' justifyContent='space-between'>
            <FormattedMessage
              id='productDiscounts_editModal_title'
              defaultMessage='Update Discount Code "{discountCode}"'
              values={{ discountCode: discountCode.code }}
            />
            {discountHasStarted && <StatusBadge status='active' />}
          </Stack>
        </DialogTitle>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
          <DialogContent>
            <Stack spacing={2}>
              {discountHasStarted && (
                <Typography color='warning.main'>
                  <FormattedMessage
                    id='productDiscounts_editModal_activeWarning'
                    defaultMessage='The validity period for this discount code has already started. The values you can change is limited because of that.'
                  />
                </Typography>
              )}
              <TextField
                margin='dense'
                type='number'
                id='maxApplications'
                name='maxApplications'
                value={formData.maxApplications}
                onChange={handleChange}
                disabled={discountHasStarted && !discountCode.maxApplications}
                label={
                  <FormattedMessage id='productDiscounts_labels_maxApplications' defaultMessage='Max Applications' />
                }
                helperText={
                  <FormattedMessage
                    id='productDiscounts_editModal_maxApplicationsHint'
                    defaultMessage='Limit how many times a discount code can be used (at least 1). If the discount code is already active, you can only increase this.'
                  />
                }
                fullWidth
                InputProps={
                  Object.keys(submitData).includes('maxApplications')
                    ? {
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Warning color='warning' />
                          </InputAdornment>
                        ),
                      }
                    : {}
                }
              />
              <TextField
                margin='dense'
                id='groupsText'
                name='groupsText'
                value={formData.groupsText}
                onChange={handleChange}
                label={<FormattedMessage id='productDiscounts_labels_groups' defaultMessage='Groups' />}
                helperText={
                  <FormattedMessage
                    id='productDiscounts_createModal_groupsHint'
                    defaultMessage='Comma-separated list of groups. Example: add the ticket ID or the marketing campaign name as a group.'
                  />
                }
                fullWidth
                InputProps={
                  Object.keys(submitData).includes('groups')
                    ? {
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Warning color='warning' />
                          </InputAdornment>
                        ),
                      }
                    : {}
                }
              />
              <DialogContentText fontWeight='medium' mb={1} color='primary' borderBottom='1px solid'>
                <FormattedMessage id='productDiscounts_createModal_validity' defaultMessage='Validity period' />
              </DialogContentText>
              <Stack direction='row' spacing={2}>
                <DatePicker
                  label={<FormattedMessage id='productDiscounts_labels_validFrom' defaultMessage='Valid from' />}
                  value={formData.validFrom}
                  onChange={(newDate) => handleDateChange('validFrom', newDate)}
                  disabled={discountHasStarted}
                  minDate={discountHasStarted && formData.validFrom ? new Date(formData.validFrom) : new Date()}
                  slotProps={{
                    textField: {
                      InputProps: Object.keys(submitData).includes('validFrom')
                        ? {
                            startAdornment: (
                              <InputAdornment position='start'>
                                <Warning color='warning' />
                              </InputAdornment>
                            ),
                          }
                        : {},
                    },
                  }}
                />
                <DatePicker
                  label={<FormattedMessage id='productDiscounts_labels_validUntil' defaultMessage='Valid until' />}
                  value={formData.validUntil}
                  onChange={(newDate) => handleDateChange('validUntil', newDate)}
                  minDate={formData.validFrom ? new Date(formData.validFrom) : new Date()}
                  slotProps={{
                    textField: {
                      InputProps: Object.keys(submitData).includes('validUntil')
                        ? {
                            startAdornment: (
                              <InputAdornment position='start'>
                                <Warning color='warning' />
                              </InputAdornment>
                            ),
                          }
                        : {},
                    },
                  }}
                />
              </Stack>
              {isFormValid && (
                <Stack direction='row' spacing={1}>
                  <Warning color='warning' />
                  <Typography>
                    <FormattedMessage
                      id='productDiscounts_editModal_changedDataHint'
                      defaultMessage='Fields marked with this icon will be changed when you save the form'
                    />
                  </Typography>
                </Stack>
              )}
            </Stack>
          </DialogContent>
        </LocalizationProvider>
        <DialogActions>
          <Button onClick={handleToggleModal} disabled={updateRequest?.status === 'pending'}>
            <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
          </Button>
          <Button
            onClick={() => {
              submitForm();
            }}
            disabled={!isFormValid || updateRequest?.status === 'pending'}
            color='info'>
            <FormattedMessage id='common_save' defaultMessage='Save' />
          </Button>
        </DialogActions>
        {updateRequest?.status === 'pending' && <LinearProgress color='primary' />}
      </Dialog>
    </>
  );
};

export default EditDiscountCodeModal;
