import { APIRoute, createPath } from '../../routing';
import { del, get, post } from '../../utils/httpUtils';

export const getCategories = (params: URLSearchParams) => {
  return get(APIRoute.COMMERCETOOLS_CATEGORIES, params);
};

export const clearCategory = (key: string) => {
  const url = createPath({
    path: APIRoute.COMMERCETOOLS_CATEGORIES_CLEAR,
    params: { key: key },
  });
  return post(url);
};

export type CreateCategoryParams = {
  key: string;
  name: string;
};

export const createCategory = (requestBody: CreateCategoryParams) => {
  return post(APIRoute.COMMERCETOOLS_CATEGORIES_CREATE, requestBody);
};

export const deleteCategory = (key: string) => {
  const url = createPath({
    path: APIRoute.COMMERCETOOLS_CATEGORIES_DELETE,
    params: { key: key },
  });
  return del(url);
};

export const getCategoryProductCount = (key: string) => {
  const url = createPath({
    path: APIRoute.COMMERCETOOLS_CATEGORIES_COUNT,
    params: { key: key },
  });
  return get(url);
};

export const setCategorySkuList = (key: string, skus: string[]) => {
  const url = createPath({
    path: APIRoute.COMMERCETOOLS_CATEGORIES_SET_REMOVE_SKUS,
    params: { key: key },
  });
  return post(url, skus, { timeout: 60000 });
};

export const addSkusToCategory = (key: string, skus: string[]) => {
  const url = createPath({
    path: APIRoute.COMMERCETOOLS_CATEGORIES_ADD_SKUS,
    params: { key: key },
  });
  return post(url, skus);
};

export const removeSkusFromCategory = (key: string, skus: string[]) => {
  const url = createPath({
    path: APIRoute.COMMERCETOOLS_CATEGORIES_SET_REMOVE_SKUS,
    params: { key: key },
  });
  return del(url, skus);
};

export const getDiscountCodes = (params: URLSearchParams) => {
  return get(APIRoute.COMMERCETOOLS_DISCOUNT_CODES, params);
};

export const deleteDiscountCode = (code: string) => {
  const url = createPath({
    path: APIRoute.COMMERCETOOLS_DISCOUNT_CODE_DELETE,
    params: { code },
  });
  return post(url);
};

export type CreateDiscountCodeParams = {
  code: string;
  currencies: string[];
  validFrom: Date;
  validUntil: Date;
  countries: string[];
  discounts: Record<string, number>;
  groups?: string[];
  categories?: string[];
  createdBy?: string;
  maxApplications?: number | null | string;
  isPercentValue?: boolean;
};

export const createDiscountCode = (requestBody: CreateDiscountCodeParams) => {
  return post(APIRoute.COMMERCETOOLS_DISCOUNT_CODES_CREATE, requestBody);
};

export type UpdateDiscountCodeParams = {
  groups?: string[];
  validFrom?: Date;
  validUntil?: Date;
  maxApplications?: number | null | string;
  modifiedBy?: string;
};

export const updateDiscountCode = (code: string, requestBody: UpdateDiscountCodeParams) => {
  const url = createPath({
    path: APIRoute.COMMERCETOOLS_DISCOUNT_CODE_UPDATE,
    params: { code },
  });
  return post(url, requestBody);
};

export const getDiscountBatchJobs = (params: URLSearchParams) => {
  return get(APIRoute.COMMERCETOOLS_DISCOUNT_CODE_BATCH_JOBS, params);
};

export const getDiscountBatchJob = (jobId: string) => {
  const url = createPath({
    path: APIRoute.COMMERCETOOLS_DISCOUNT_CODE_BATCH_JOB,
    params: { id: jobId },
  });
  return get(url);
};

export const downloadBatchJobDiscountCodes = (jobId: string) => {
  const url = createPath({
    path: APIRoute.COMMERCETOOLS_DISCOUNT_CODE_BATCH_JOB_EXPORT,
    params: { id: jobId },
  });
  const requestConfig = {
    headers: {
      'Content-Type': 'text/csv',
    },
  };
  return get(url, null, requestConfig);
};

export type CreateBatchJobParams = {
  code: string;
  currencies: string[];
  validFrom: Date;
  validUntil: Date;
  countries: string[];
  discounts: Record<string, number>;
  groups?: string[];
  categories?: string[];
  createdBy?: string;
  amount?: number | null | string;
  isPercentValue?: boolean;
};

export const createBatchJob = (requestBody: CreateBatchJobParams) => {
  return post(APIRoute.COMMERCETOOLS_DISCOUNT_CODE_BATCH_JOB_CREATE, requestBody);
};
