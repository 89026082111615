import { Card, CardHeader, Stack } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../state';
import { getTrackingItemTransfersAction } from '../../../state/ducks/tracking/actions';
import StatusBadge from '../../components/badges/StatusBadge';
import TrackingDetailsCardContent from './components/TrackingDetailsCardContent';
import TrackingDetailsTabs from './components/TrackingDetailsTabs';
import styles from './Tracking.module.scss';

type Params = {
  [key: string]: string;
};

const TrackingDetailsTransfers: React.FC = () => {
  const trackingItem = useAppSelector((store) => store.tracking.trackingItemTransfers);
  const dispatch = useAppDispatch();
  const { reference } = useParams<Params>();

  useEffect(() => {
    dispatch(getTrackingItemTransfersAction(reference));
  }, [reference]);

  if (!trackingItem) {
    return null;
  }

  return (
    <>
      <Stack spacing={2} className={styles.wrapper}>
        <Card>
          <CardHeader
            title={
              <Stack direction='row' justifyContent='space-between'>
                <FormattedMessage
                  id='tracking_details_transfers_title'
                  defaultMessage='Details for Transfer Order {reference}'
                  values={{ reference: trackingItem.transferOrderId }}
                />
                <StatusBadge status={trackingItem.tracking.state} showIcon />
              </Stack>
            }
          />
          <TrackingDetailsCardContent
            trackingItem={trackingItem}
            excludedItems={['shipmentId', 'invoiceId', 'paymentMethod', 'inventoryId']}
          />
        </Card>
        <TrackingDetailsTabs
          tracking={trackingItem.tracking}
          fetchTrackingItem={() => dispatch(getTrackingItemTransfersAction(reference))}
        />
      </Stack>
    </>
  );
};

export default TrackingDetailsTransfers;
