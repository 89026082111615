import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { COMMERCETOOLS_UPDATE_CATEGORY_MESSAGES, SUCCESS_MESSAGES } from '../../../constants/successMessages';
import { requestSelector } from '../../../selectors/request';
import { useAppSelector } from '../../../state';
import { CommercetoolsActions } from '../../../state/ducks/commercetools/types';
import { PricingActions } from '../../../state/ducks/pricing/types';
import { RequestError } from '../../../state/ducks/request/types';
import { SellActions } from '../../../state/ducks/sell/types';
import { TrackingActions } from '../../../state/ducks/tracking/types';
import { WorkshopActions } from '../../../state/ducks/workshop/types';
import { WorkshopInboundActions } from '../../../state/ducks/workshopInbound/types';
import { WorkshopPricesActions } from '../../../state/ducks/workshopService/types';

type Config = {
  messageValues?: Record<string, any>;
  [key: string]: any;
};

type Props = {
  actionName: string;
  autoHideDuration?: number;
  config?: Config;
};

const getSuccessMessage = (actionName: string, payload?: any, config?: Config): string | React.ReactElement => {
  const id = SUCCESS_MESSAGES?.[actionName]?.id || SUCCESS_MESSAGES.default.id;
  const defaultMessage = SUCCESS_MESSAGES?.[actionName]?.defaultMessage || SUCCESS_MESSAGES.default.defaultMessage;
  switch (actionName) {
    case WorkshopActions.createProcess:
      return <FormattedMessage id={id} defaultMessage={defaultMessage} values={{ reference: payload?.reference }} />;
    case SellActions.updateSellRequestStatus:
    case SellActions.updateSellRequestOffer:
    case SellActions.updateSellRequestOfferStatus:
    case SellActions.updateSellRequestOfferExpirationDate:
    case SellActions.requestVendorInformation:
    case SellActions.updateVendorInformation:
    case SellActions.updateSellRequestAgentAction:
      return (
        <FormattedMessage
          id={id}
          defaultMessage={defaultMessage}
          values={{ reference: payload?.sellRequestId || payload?.reference }}
        />
      );
    case WorkshopActions.updateProcess:
    case WorkshopActions.createLocation:
    case WorkshopActions.updateWorkshopItem:
      return <FormattedMessage id={id} defaultMessage={defaultMessage} values={{ reference: payload?.name }} />;
    case CommercetoolsActions.createCategory:
      return (
        <FormattedMessage
          id={id}
          defaultMessage={defaultMessage}
          values={{ categoryName: payload?.name?.en, categoryKey: payload?.key }}
        />
      );
    case CommercetoolsActions.clearCategory:
      return (
        <FormattedMessage
          id={id}
          defaultMessage={defaultMessage}
          values={{ ...config?.messageValues, count: payload?.skusRemovedCount }}
        />
      );
    case CommercetoolsActions.updateCategory:
      const countValues = {
        skusAddedCount: 0,
        skusRemovedCount: 0,
      };
      payload.forEach((item: Record<string, number>) => {
        if (item.skusAddedCount) countValues.skusAddedCount += item?.skusAddedCount;
        if (item.skusRemovedCount) countValues.skusRemovedCount += item?.skusRemovedCount;
      });
      return (
        <FormattedMessage
          id={
            COMMERCETOOLS_UPDATE_CATEGORY_MESSAGES?.[config?.actionType]?.id ||
            COMMERCETOOLS_UPDATE_CATEGORY_MESSAGES.default.id
          }
          defaultMessage={
            COMMERCETOOLS_UPDATE_CATEGORY_MESSAGES?.[config?.actionType]?.defaultMessage ||
            COMMERCETOOLS_UPDATE_CATEGORY_MESSAGES.default.defaultMessage
          }
          values={{ ...config?.messageValues, ...countValues }}
        />
      );
    case WorkshopPricesActions.createWorkshopService:
      return (
        <FormattedMessage id={id} defaultMessage={defaultMessage} values={{ name: payload?.name, key: payload?.key }} />
      );
    case WorkshopPricesActions.updateWorkshopService:
    case WorkshopPricesActions.deleteWorkshopService:
      return <FormattedMessage id={id} defaultMessage={defaultMessage} values={{ service: payload?.name }} />;
    case WorkshopPricesActions.createWorkshopPrice:
      return (
        <FormattedMessage
          id={id}
          defaultMessage={defaultMessage}
          values={{ entry: `${payload?.brand}${payload.model ? ` ${payload.model}` : ''}` }}
        />
      );
    case PricingActions.createPricingConfigEntry:
      return <FormattedMessage id={id} defaultMessage={defaultMessage} values={{ reference: payload?.id }} />;
    case CommercetoolsActions.deleteCategory:
    case CommercetoolsActions.createDiscountCode:
    case CommercetoolsActions.createBatchJob:
    case CommercetoolsActions.deleteDiscountCode:
    case WorkshopPricesActions.updateWorkshopPrice:
    case WorkshopPricesActions.deleteWorkshopPrice:
    case TrackingActions.createTrackingComment:
    case TrackingActions.updateTrackingComment:
    case SellActions.updateSellRequest:
    case SellActions.requestMissingData:
    case SellActions.cancelSellRequest:
    case WorkshopInboundActions.updateWorkshopInboundActivityStatus:
    case WorkshopInboundActions.createWorkshopInboundReport:
    case WorkshopInboundActions.updateWorkshopInboundReport:
    case WorkshopInboundActions.createWorkshopInboundServices:
    case WorkshopInboundActions.sendWorkshopInboundServiceQuotation:
    case WorkshopInboundActions.createWorkshopInboundActivity:
    case WorkshopInboundActions.updateWorkshopInboundWatch:
    case WorkshopInboundActions.updateWorkshopInboundWatchSpareParts:
    case WorkshopInboundActions.updateExternalWorkshop:
    case PricingActions.deletePricingConfigEntry:
    case PricingActions.updatePricingConfigEntry:
      return <FormattedMessage id={id} defaultMessage={defaultMessage} values={{ ...config?.messageValues }} />;
    default:
      return '';
  }
};

const ActionStatusSnackbar: React.FC<Props> = ({ actionName, autoHideDuration, config }) => {
  const { enqueueSnackbar } = useSnackbar();
  const request = useAppSelector((state) => requestSelector(state, actionName));

  useEffect(() => {
    let message: string | React.ReactElement = '';
    const snackbarOptions = { autoHideDuration: autoHideDuration || 6000 };
    const { errors, error, status } = request || {};
    switch (status) {
      case 'rejected':
        if (errors?.length) {
          errors.forEach((error) => {
            message = [error?.code, error?.name, error?.message].filter((v) => !!v).join(' - ') || '';
            if (message) {
              enqueueSnackbar(message, { ...snackbarOptions, variant: 'error' });
            }
          });
        } else {
          message = [error?.code, error?.name, error?.message].filter((v) => !!v).join(' - ') || '';
          if (message) {
            enqueueSnackbar(message, { ...snackbarOptions, variant: 'error' });
          }
        }
        break;
      case 'fulfilled':
        // handle errors from commercetools api service
        if (request?.payload?.errors) {
          request?.payload?.errors.forEach((error: RequestError) => {
            message = error?.message || '';
            if (message) {
              enqueueSnackbar(message, { ...snackbarOptions, variant: 'error' });
            }
          });
        } else {
          message = getSuccessMessage(actionName, request?.payload, config);
          if (message) {
            enqueueSnackbar(message, { ...snackbarOptions, variant: 'success' });
          }
        }
        break;
    }
  }, [request]);

  return null;
};

export default ActionStatusSnackbar;
