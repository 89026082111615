import { configureStore } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import algoliaReducer from './ducks/algolia/reducer';
import commercetoolsReducer from './ducks/commercetools/reducer';
import inventoryReducer from './ducks/inventory/reducer';
import pricingReducer from './ducks/pricing/reducer';
import requestReducer from './ducks/request/reducer';
import sellReducer from './ducks/sell/reducer';
import trackingReducer from './ducks/tracking/reducer';
import userReducer from './ducks/user/reducer';
import workshopReducer from './ducks/workshop/reducer';
import workshopServiceReducer from './ducks/workshopService/reducer';
import workshopInboundReducer from './ducks/workshopInbound/reducer';

export const store = configureStore({
  reducer: {
    user: userReducer,
    requests: requestReducer,
    tracking: trackingReducer,
    workshop: workshopReducer,
    workshopInbound: workshopInboundReducer,
    sell: sellReducer,
    inventory: inventoryReducer,
    commercetools: commercetoolsReducer,
    workshopService: workshopServiceReducer,
    algolia: algoliaReducer,
    pricing: pricingReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type Effect = ThunkAction<void, RootState, unknown, AnyAction>;
