import { CurrencyFranc, Euro } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import deLocale from 'date-fns/locale/de';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { UpdateWorkshopInboundExternalWorkshopParams } from '../../../../api/server/workshopInbound';
import { requestSelector } from '../../../../selectors/request';
import { currentUserSelector } from '../../../../selectors/user';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { updateExternalWorkshopAction } from '../../../../state/ducks/workshopInbound/actions';
import {
  WorkshopInboundExternalWorkshop,
  WorkshopInboundExternalWorkshopCurrency,
} from '../../../../state/ducks/workshopInbound/types';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  item: WorkshopInboundExternalWorkshop;
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
};

const initialFormData: Omit<UpdateWorkshopInboundExternalWorkshopParams, 'updated_by' | 'received_date'> & {
  received_date?: Date;
} = {
  currency: undefined,
  received_date: undefined,
  amount: 0,
};

const UpdateExternalWorkshopModal: React.FC<Props> = ({ item, onSuccess, handleToggleModal, show }) => {
  const dispatch = useAppDispatch();
  const request = useAppSelector((state) => requestSelector(state, updateExternalWorkshopAction.typePrefix));
  const currentUser = useAppSelector(currentUserSelector);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [isFormValid, setIsFormValid] = useState(false);

  const clearForm = () => {
    setFormData(initialFormData);
  };

  const handleCurrencyChange = (
    event: React.MouseEvent<HTMLElement>,
    newCurrency: WorkshopInboundExternalWorkshopCurrency,
  ) => {
    setFormData({ ...formData, currency: newCurrency });
  };

  const handleDateChange = (newDate: Date | null | unknown) => {
    const date = newDate as Date;
    setFormData({ ...formData, received_date: date });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const submitForm = () => {
    dispatch(
      updateExternalWorkshopAction({
        reference: item.id.toString(),
        requestBody: {
          ...formData,
          received_date: formData.received_date?.toISOString().split('T')[0],
          updated_by: currentUser?.username,
        },
      }),
    ).then((response) => {
      setShowSnackbar(true);
      if (response?.payload && !response.payload?.errors) {
        clearForm();
        handleToggleModal();
        onSuccess();
      }
    });
  };

  useEffect(() => {
    const { currency, amount, received_date } = item;
    setFormData({ currency, amount, received_date: new Date(received_date || '') });
  }, [show]);

  useEffect(() => {
    if (
      item.currency !== formData.currency ||
      item.amount !== formData.amount ||
      item.received_date !== formData.received_date?.toString()
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formData]);

  return (
    <>
      {showSnackbar && <ActionStatusSnackbar actionName={updateExternalWorkshopAction.typePrefix} />}
      <Dialog open={show} onClose={handleToggleModal}>
        <DialogTitle>
          <FormattedMessage
            id='workshopInbound_actions_updateExternalWorkshop'
            defaultMessage='Update External Workshop Information'
          />
        </DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
            <Stack spacing={2} mt={2}>
              <Stack direction='row' spacing={2}>
                <TextField
                  type='number'
                  margin='dense'
                  name='amount'
                  value={formData.amount}
                  onChange={handleChange}
                  label={<FormattedMessage id='common_labels_price' defaultMessage='Price' />}
                  fullWidth
                />
                <ToggleButtonGroup
                  exclusive
                  color='success'
                  id='currency'
                  value={formData.currency}
                  onChange={handleCurrencyChange}>
                  <ToggleButton value={WorkshopInboundExternalWorkshopCurrency.EUR}>
                    <Euro />
                  </ToggleButton>
                  <ToggleButton value={WorkshopInboundExternalWorkshopCurrency.CHF}>
                    <CurrencyFranc />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              <DatePicker
                label={
                  <FormattedMessage id='workshopInbound_labels_receivedAt' defaultMessage='Quotation received at' />
                }
                value={formData.received_date}
                onChange={(newDate) => handleDateChange(newDate)}
              />
            </Stack>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleModal} disabled={request?.status === 'pending'} color='error'>
            <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
          </Button>
          <Button onClick={submitForm} disabled={!isFormValid || request?.status === 'pending'} color='success'>
            <FormattedMessage id='common_save' defaultMessage='Save' />
          </Button>
        </DialogActions>
        {request?.status === 'pending' && <LinearProgress color='primary' />}
      </Dialog>
    </>
  );
};

export default UpdateExternalWorkshopModal;
