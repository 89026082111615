import { Chip } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './Badges.module.scss';

type Props = {
  condition: string | undefined;
};

const CONDITION_MAPPING: {
  [param: string]: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
} = {
  new: 'success',
  'like new': 'warning',
  good: 'info',
  used: 'error',
  vint: 'primary',
  a: 'error',
  aa: 'info',
  aaa: 'warning',
};

const ConditionBadge: React.FC<Props> = ({ condition }) => {
  const intl = useIntl();
  if (!condition) return null;
  const messageId = `condition_${condition.replace(' ', '_')}`;

  return (
    <Chip
      size='small'
      color={CONDITION_MAPPING?.[condition] || 'default'}
      className={styles.chip}
      label={intl.messages[messageId] ? <FormattedMessage id={messageId} defaultMessage={condition} /> : condition}
    />
  );
};

export default ConditionBadge;
