import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { MarginTiers } from '../../../../state/ducks/pricing/types';
import Price from '../../../components/price/Price';

type Props = {
  marginTiers: MarginTiers;
  type?: string;
  rowActions?: (key: string) => React.ReactNode;
};
const MarginRangeTable: React.FC<Props> = ({ marginTiers, type, rowActions }) => (
  <Box>
    {type && (
      <Typography fontWeight='medium' mb={1} color='primary' borderBottom='1px solid'>
        <FormattedMessage id={`sellRequests_sellingMethods_${type}`} defaultMessage={type} />
      </Typography>
    )}
    <TableContainer component={Paper}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id='priceCalculatorConfig_minProductValue' defaultMessage='Min. Product Value' />
            </TableCell>
            <TableCell>
              <FormattedMessage id='common_labels_margin' defaultMessage='Margin' />
            </TableCell>
            {!!rowActions && (
              <TableCell>
                <FormattedMessage id='common_actions' defaultMessage='Actions' />
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(marginTiers)?.map(([key, value]) => (
            <TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>
                <Price value={Number(key)} />
              </TableCell>
              <TableCell>{value}%</TableCell>
              {!!rowActions && <TableCell>{rowActions(key)}</TableCell>}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
);

export default MarginRangeTable;
