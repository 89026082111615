import { createReducer } from '@reduxjs/toolkit';
import { getChronextAgents, getExistingOffersAction, getSellRequestAction, getSellRequestsAction } from './actions';
import { ChronextAgents, SellState } from './types';

export const initialState: SellState = {
  chronextAgents: [],
  sellRequests: {
    total: 0,
    items: [],
  },
  sellRequest: null,
  offers: [],
};

export const sellReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getSellRequestsAction.fulfilled, (state, action) => {
      state.sellRequests.items = action.payload.items;
      state.sellRequests.total = action.payload.total || action.payload.items.length;
    })
    .addCase(getSellRequestAction.fulfilled, (state, action) => {
      state.sellRequest = action.payload;
    })
    .addCase(getExistingOffersAction.fulfilled, (state, action) => {
      state.offers = action.payload.items;
    })
    .addCase(getExistingOffersAction.pending, (state, action) => {
      state.offers = initialState.offers;
    })
    .addCase(getExistingOffersAction.rejected, (state, action) => {
      state.offers = initialState.offers;
    })
    .addCase(getChronextAgents.fulfilled, (state, action) => {
      state.chronextAgents = action.payload.items.slice().sort((a: ChronextAgents, b: ChronextAgents) => {
        return a.preferredName?.localeCompare(b.preferredName);
      });
    })
    .addCase(getChronextAgents.rejected, (state, action) => {
      state.chronextAgents = initialState.chronextAgents;
    });
});

export default sellReducer;
