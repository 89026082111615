import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../../state';
import { deleteWorkshopServiceAction } from '../../../../state/ducks/workshopService/actions';
import { Service } from '../../../../state/ducks/workshopService/types';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  service: Service;
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
};

const DeleteWorkshopServiceModal: React.FC<Props> = ({ service, handleToggleModal, show, onSuccess }) => {
  const dispatch = useAppDispatch();
  const [showSnackbar, setShowSnackbar] = useState(false);

  return (
    <>
      {showSnackbar && (
        <ActionStatusSnackbar
          actionName={deleteWorkshopServiceAction.typePrefix}
          config={{ messageValues: { service: service.name } }}
        />
      )}
      <Dialog open={show} onClose={handleToggleModal}>
        <DialogTitle>
          <FormattedMessage
            id='workshopPrices_services_deleteModal_title'
            defaultMessage='Delete Service "{service}"?'
            values={{ service: service.name }}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontWeight: 'medium' }}>
            <FormattedMessage id='common_warning_noUndo' defaultMessage='This action cannot be undone.' />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleModal}>
            <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
          </Button>
          <Button
            onClick={() => {
              dispatch(deleteWorkshopServiceAction(service.id)).then((response) => {
                setShowSnackbar(true);
                if (response?.payload && !response.payload?.errors) {
                  handleToggleModal();
                  onSuccess();
                }
              });
            }}
            color='error'>
            <FormattedMessage id='common_delete' defaultMessage='Delete' />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteWorkshopServiceModal;
