import { IntlShape } from 'react-intl';
import { SimpleTableColDef } from '../views/components/table/SimpleTable';

export const translateColumnLabels = (columns: SimpleTableColDef[], intl: IntlShape) => {
  return columns.map(({ id, headerName, renderHeader, ...rest }) => {
    if (renderHeader) {
      return { renderHeader, ...rest };
    }

    if (!id) {
      return { headerName, ...rest };
    }

    return {
      headerName: intl.formatMessage({
        id,
        defaultMessage: headerName,
      }),
      ...rest,
    };
  });
};
