import React, { useState } from 'react';
import { workshopServiceBrandsSelector } from '../../../selectors/workshopService';
import { useAppSelector } from '../../../state';
import { getWorkshopBrandsAction } from '../../../state/ducks/workshopService/actions';
import ActionStatusSnackbar from '../../components/snackbars/ActionStatusSnackbar';
import ServerSidePaginatedTable, {
  ServerSidePaginatedTableColDef,
} from '../../components/table/ServerSidePaginatedTable';
import styles from './WorkshopPrices.module.scss';

const brandsTableColumns: ServerSidePaginatedTableColDef[] = [
  {
    id: 'common_labels_brand',
    headerName: 'Brand',
    field: 'name',
    width: 60,
    sortable: false,
    flex: 1,
  },
  {
    id: 'workshopPrices_labels_brandSlug',
    headerName: 'Brand Slug',
    field: 'slug',
    sortable: false,
    flex: 1,
  },
];

const WorkshopBrands: React.FC = () => {
  const { brands, brandsTotal } = useAppSelector(workshopServiceBrandsSelector);
  const [searchParams] = useState({
    where: '',
  });

  return (
    <div className={styles.wrapper}>
      <ActionStatusSnackbar actionName={getWorkshopBrandsAction.typePrefix} />
      <ServerSidePaginatedTable
        rows={brands}
        columns={brandsTableColumns}
        totalCount={brandsTotal}
        fetchDataEffect={getWorkshopBrandsAction}
        searchParams={searchParams}
      />
    </div>
  );
};

export default WorkshopBrands;
