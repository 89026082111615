import { TabContext, TabPanel } from '@mui/lab';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import AlgoliaBrands from '../../components/algolia/AlgoliaBrands';
import PriceCalculatorMarginConfigList from './PriceCalculatorMarginConfigList';

const tabProps = {
  sx: {
    flex: 1,
    padding: 0,
  },
};

const PriceCalculatorMarginConfigPage: React.FC = () => {
  const [tab, setTab] = React.useState('margins');

  return (
    <TabContext value={tab.toString()}>
      <Stack sx={{ height: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 3 }}>
          <Tabs selectionFollowsFocus value={tab} onChange={(_, newValue) => setTab(newValue)}>
            <Tab
              value='margins'
              label={<FormattedMessage id='priceCalculatorConfig_tabs_margins' defaultMessage='Margins' />}
            />
            <Tab
              value='algolia'
              label={<FormattedMessage id='workshopPrices_tabs_algoliaBrands' defaultMessage='Algolia Brands' />}
            />
          </Tabs>
        </Box>
        <TabPanel value='margins' {...tabProps}>
          <PriceCalculatorMarginConfigList />
        </TabPanel>
        <TabPanel value='algolia' {...tabProps}>
          <AlgoliaBrands />
        </TabPanel>
      </Stack>
    </TabContext>
  );
};

export default PriceCalculatorMarginConfigPage;
