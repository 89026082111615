import React, { useState } from 'react';
import { APP_CONFIG } from '../../../config/app';
import { ClientRouteGroups } from '../../../routing/clientRoutes';
import { isAuthorizedSelector } from '../../../selectors/user';
import ButtonGroupIconButton from './ButtonGroupIconButton';
import { getWorkshopInboundWatchAction } from '../../../state/ducks/workshopInbound/actions';
import { useAppDispatch, useAppSelector } from '../../../state';
import { useIntl } from 'react-intl';
import { Edit } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { WorkshopInboundWatchService } from '../../../state/ducks/workshopInbound/types';
import CreateUpdateInboundServiceModal from '../../pages/workshop-inbound/components/CreateUpdateInboundServiceModal';

type Props = {
  service: WorkshopInboundWatchService;
};

const WorkshopInboundUpdateServiceButton: React.FC<Props> = ({ service }) => {
  const userCanEdit =
    useAppSelector((state) =>
      isAuthorizedSelector(
        state,
        ClientRouteGroups.WORKSHOP_INBOUND_ITEM.filter((g) => g.startsWith('Workshop')),
      ),
    ) || !APP_CONFIG.isProd;
  const { reference } = useParams<{ reference: string }>();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && (
        <CreateUpdateInboundServiceModal
          mode='update'
          show={showModal}
          handleToggleModal={() => setShowModal(!showModal)}
          onSuccess={() => dispatch(getWorkshopInboundWatchAction(reference))}
          service={service}
        />
      )}
      <ButtonGroupIconButton
        variant='contained'
        color='info'
        disabled={!userCanEdit}
        onClick={() => setShowModal(true)}
        title={intl.formatMessage({
          id: 'common_edit',
          defaultMessage: 'Edit',
        })}>
        <Edit />
      </ButtonGroupIconButton>
    </>
  );
};

export default WorkshopInboundUpdateServiceButton;
