import { SellRequestCancellationReason } from '../../constants/sell';
import { APIRoute, createPath } from '../../routing';
import { Image, SellAddress } from '../../state/ducks/sell/types';
import { get, patch, post, put } from '../../utils/httpUtils';

// Fetch the most recent requests from sellwatch-service
export const refreshSWSSellRequests = () => {
  return post(APIRoute.SELL_REFRESH_SWS, null);
};

export const getSellRequests = (params: URLSearchParams) => {
  return get(APIRoute.SELL_REQUESTS, params);
};

export const getSellRequest = (reference: string) => {
  const url = createPath({ path: APIRoute.SELL_REQUEST, params: { reference } });
  return get(url);
};

export type UpdateSellRequestParams = {
  watchBrand?: string;
  watchModel?: string;
  watchRef?: string;
  constructionYear?: string;
  watchCondition?: string;
  paperAvailable?: boolean;
  boxAvailable?: boolean;
  comments?: string;
  images?: Image[];
  sku?: string;
};

export const updateSellRequest = (reference: string, requestBody: UpdateSellRequestParams) => {
  const url = createPath({ path: APIRoute.SELL_REQUEST, params: { reference } });
  return put(url, requestBody);
};

export type RequestMissingDataParams = {
  missingData: string[];
};

export const requestMissingData = (reference: string, requestBody: RequestMissingDataParams) => {
  const url = createPath({ path: APIRoute.SELL_REQUEST_RESUBMISSION, params: { reference } });
  return post(url, requestBody);
};

export type CancelSellRequestParams = {
  [key in SellRequestCancellationReason]?: boolean;
};

export const cancelSellRequest = (reference: string, requestBody: CancelSellRequestParams) => {
  const url = createPath({ path: APIRoute.SELL_REQUEST_CANCEL, params: { reference } });
  return post(url, requestBody);
};

export const updateSellRequestStatus = (reference: string) => {
  const url = createPath({ path: APIRoute.SELL_REQUEST_STATUS, params: { reference } });
  return patch(url, null);
};

export const updateSellRequestOffer = (reference: string, offer: any) => {
  const url = createPath({ path: APIRoute.SELL_OFFER, params: { reference } });
  return put(url, { offer });
};

export const finalizeSellRequestOffer = (reference: string, status: string) => {
  const url = createPath({ path: APIRoute.SELL_OFFER, params: { reference } });
  return patch(url, { status });
};

export const updateSellRequestOfferExpirationDate = (reference: string, daysLimit: number) => {
  const url = createPath({ path: APIRoute.SELL_REQUEST_EXPIRATION_DATE, params: { reference } });
  return patch(url, { daysLimit });
};

export const requestVendorInformation = (reference: string) => {
  const url = createPath({ path: APIRoute.SELL_REQUEST_VENDOR_INFORMATION, params: { reference } });
  return post(url, null);
};

export type UpdateSellRequestVendorInformationParams = {
  country?: string;
  paymentDetails?: {
    srId?: string;
    bankHolderName?: string;
    bankName?: string;
    iban?: string;
    pickupAddress?: SellAddress;
    billingAddress?: SellAddress;
  };
};

export const updateSellRequestVendorInformation = (
  reference: string,
  requestBody: UpdateSellRequestVendorInformationParams,
) => {
  const url = createPath({ path: APIRoute.SELL_REQUEST_VENDOR_INFORMATION_UPDATE, params: { reference } });
  return put(url, requestBody);
};

export const getExistingOffers = (sku: string) => {
  const searchParams = new URLSearchParams({ sku });
  return get(APIRoute.SELL_EXISTING_OFFERS, searchParams);
};

export const getChronextAgentsRequest = (params: URLSearchParams) => {
  return get(APIRoute.USERS, params);
};

export const updateSellRequestAgent = (reference: string, agentReference: number) => {
  const url = createPath({ path: APIRoute.SELL_REQUEST_AGENT_UPDATE, params: { reference } });
  return patch(url, { agentReference });
};
