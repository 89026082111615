import { TabContext, TabPanel } from '@mui/lab';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { GROUPS } from '../../../constants/groups';
import { ClientRouteGroups } from '../../../routing/clientRoutes';
import { isAuthorizedSelector } from '../../../selectors/user';
import { useAppSelector } from '../../../state';
import BatchJobs from './BatchJobs';
import ProductDiscounts from './ProductDiscounts';

const tabProps = {
  sx: {
    flex: 1,
    padding: 0,
  },
};

const ProductDiscountsPage: React.FC = () => {
  const userCanEdit = useAppSelector((state) =>
    isAuthorizedSelector(
      state,
      ClientRouteGroups.PRODUCT_DISCOUNTS.filter((g) => g !== GROUPS.SALES_AGENT),
    ),
  );
  const [tabIndex, setTabIndex] = React.useState(0);

  return (
    <TabContext value={tabIndex.toString()}>
      <Stack sx={{ height: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 3 }}>
          <Tabs selectionFollowsFocus value={tabIndex} onChange={(_, newValue) => setTabIndex(newValue)}>
            <Tab label={<FormattedMessage id='productDiscounts_tabs_discounts' defaultMessage='Discount Codes' />} />
            {userCanEdit && (
              <Tab label={<FormattedMessage id='productDiscounts_tabs_batchJobs' defaultMessage='Batch Jobs' />} />
            )}
          </Tabs>
        </Box>
        <TabPanel value='0' {...tabProps}>
          <ProductDiscounts userCanEdit={userCanEdit} />
        </TabPanel>
        {userCanEdit && (
          <TabPanel value='1' {...tabProps}>
            <BatchJobs />
          </TabPanel>
        )}
      </Stack>
    </TabContext>
  );
};

export default ProductDiscountsPage;
