import { createReducer } from '@reduxjs/toolkit';
import {
  getWorkshopBrandsAction,
  getWorkshopPriceAction,
  getWorkshopPriceListAction,
  getWorkshopServicesAction,
} from './actions';
import { WorkshopPricesState } from './types';

const initialState = {
  services: [],
  servicesTotal: 0,
  prices: [],
  pricesTotal: 0,
  brands: [],
  brandsTotal: 0,
  price: null,
} as WorkshopPricesState;

const workshopServiceReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getWorkshopServicesAction.fulfilled, (state, action) => {
      state.services = action.payload;
      state.servicesTotal = action.payload?.length;
    })
    .addCase(getWorkshopPriceListAction.fulfilled, (state, action) => {
      state.prices = action.payload?.data;
      state.pricesTotal = action.payload?.metadata?.total;
    })
    .addCase(getWorkshopPriceAction.fulfilled, (state, action) => {
      state.price = action.payload;
    })
    .addCase(getWorkshopBrandsAction.fulfilled, (state, action) => {
      state.brands = action.payload?.data;
      state.brandsTotal = action.payload?.metadata?.total;
    });
});

export default workshopServiceReducer;
