import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { workshopLocationsSelector } from '../../../../selectors/workshop';
import { useAppDispatch, useAppSelector } from '../../../../state';
import {
  createWorkshopLocationAction,
  getWorkshopLocationsAction,
  updateWorkshopLocationAction,
} from '../../../../state/ducks/workshop/actions';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  locationReference?: number;
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
};

const initialFormData = {
  name: '',
  code: '',
  area: '',
  inInventory: true,
  isActive: true,
};

const locationAreaOptions = [
  'Personal',
  'Category Management Table',
  'Logistics / Transfer to logistics',
  'Photobox',
  'Photostudio',
  'Polishing Room',
  'Watchmaking Atelier',
  'Workshop Dump',
  'Workshop Operations',
];

export const CreateUpdateWorkshopLocationModal: React.FC<Props> = ({
  locationReference,
  handleToggleModal,
  show,
  onSuccess,
}) => {
  const dispatch = useAppDispatch();
  const workshopLocations = useAppSelector(workshopLocationsSelector);
  const [showUpdateSnackbar, setShowUpdateSnackbar] = useState(false);
  const [showCreateSnackbar, setShowCreateSnackbar] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    if (locationReference) {
      const location = workshopLocations.find((l) => l.reference === locationReference);
      if (location) {
        const { name, code, area, inInventory, isActive } = location;
        setFormData({ name, code, area, inInventory, isActive });
      }
    } else {
      clearForm();
    }
  }, [show]);

  const clearForm = () => {
    setFormData(initialFormData);
  };

  const handleChange = (event: any) => {
    if (['inInventory', 'isActive'].includes(event.target.name)) {
      setFormData({
        ...formData,
        [event.target.name]: event.target?.checked,
      });
    } else {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const onCreateUpdateLocationClick = () => {
    if (locationReference) {
      dispatch(updateWorkshopLocationAction({ locationReference, ...formData })).then((response) => {
        setShowUpdateSnackbar(true);
        if (response?.payload && !response?.payload?.errors) {
          clearForm();
          dispatch(getWorkshopLocationsAction());
          handleToggleModal();
          onSuccess();
        }
      });
    } else {
      dispatch(createWorkshopLocationAction(formData)).then((response) => {
        setShowCreateSnackbar(true);
        if (response?.payload && !response?.payload?.errors) {
          clearForm();
          dispatch(getWorkshopLocationsAction());
          handleToggleModal();
          onSuccess();
        }
      });
    }
  };

  return (
    <Box sx={{ marginLeft: 'auto' }}>
      {showUpdateSnackbar && <ActionStatusSnackbar actionName={updateWorkshopLocationAction.typePrefix} />}
      {showCreateSnackbar && <ActionStatusSnackbar actionName={createWorkshopLocationAction.typePrefix} />}
      <Dialog open={show} onClose={handleToggleModal}>
        <DialogContent>
          <TextField
            margin='dense'
            id='name'
            name='name'
            value={formData.name}
            onChange={handleChange}
            label={<FormattedMessage id='workshop_locations_labels_locationName' defaultMessage='Location Name' />}
            size='small'
            fullWidth
          />
          <TextField
            margin='dense'
            id='code'
            name='code'
            value={formData.code}
            onChange={handleChange}
            label={<FormattedMessage id='workshop_locations_labels_locationCode' defaultMessage='Location Code' />}
            size='small'
            fullWidth
          />
          <FormGroup>
            <FormControl size='small' sx={{ marginY: 1 }} fullWidth>
              <InputLabel id='select-area'>
                <FormattedMessage id='workshop_locations_labels_locationArea' defaultMessage='Area' />
              </InputLabel>
              <Select
                labelId='select-area'
                label={<FormattedMessage id='workshop_locations_labels_locationArea' defaultMessage='Area' />}
                name='area'
                onChange={handleChange}
                value={formData.area}>
                {locationAreaOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              name='inInventory'
              label={<FormattedMessage id='workshop_locations_labels_inInventory' defaultMessage='In Inventory' />}
              checked={formData.inInventory}
              control={<Checkbox onChange={handleChange} />}
            />
            <FormControlLabel
              name='isActive'
              label={<FormattedMessage id='workshop_locations_lables_isActive' defaultMessage='Is Active' />}
              checked={formData.isActive}
              control={<Checkbox onChange={handleChange} />}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleModal} color='error'>
            <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
          </Button>
          <Button onClick={onCreateUpdateLocationClick}>
            <FormattedMessage id='common_save' defaultMessage='Save' />
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreateUpdateWorkshopLocationModal;
