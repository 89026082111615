import { alpha3ToAlpha2, getName } from 'i18n-iso-countries';
import { CURRENCIES } from '../constants/currencies';

export const getCurrenciesFromCountries = (countries: string[], defaultCurrencies: string[]): string[] => {
  const currencies: string[] = [];
  Object.entries(CURRENCIES).forEach((entry) => {
    if (countries.includes(entry[0]) && currencies.indexOf(entry[1]) < 0) {
      currencies.push(entry[1]);
    }
  });
  return currencies.length ? currencies : defaultCurrencies;
};

export const getAlpha2FromAlpha3 = (alpha3: string): string => {
  return alpha3ToAlpha2(alpha3);
};

export const getCountryNameFromAlpha2 = (alpha2: string, language = 'en'): string => {
  return getName(alpha2, language);
};
