export const CURRENCIES = {
  AE: 'USD',
  AG: 'USD',
  AI: 'USD',
  AR: 'USD',
  AT: 'EUR',
  AU: 'USD',
  AW: 'USD',
  BB: 'USD',
  BE: 'EUR',
  BG: 'EUR',
  BH: 'USD',
  BM: 'USD',
  BN: 'USD',
  BO: 'USD',
  BQ: 'USD',
  BR: 'USD',
  BS: 'USD',
  BY: 'USD',
  BZ: 'USD',
  CA: 'USD',
  CH: 'CHF',
  CK: 'USD',
  CL: 'USD',
  CN: 'USD',
  CO: 'USD',
  CR: 'USD',
  CV: 'USD',
  CW: 'USD',
  CY: 'EUR',
  CZ: 'EUR',
  DE: 'EUR',
  DK: 'EUR',
  DM: 'USD',
  EC: 'USD',
  EE: 'EUR',
  EG: 'USD',
  ES: 'EUR',
  FI: 'EUR',
  FJ: 'USD',
  FO: 'EUR',
  FR: 'EUR',
  GB: 'GBP',
  GD: 'USD',
  GI: 'GBP',
  GL: 'USD',
  GP: 'EUR',
  GR: 'EUR',
  GT: 'USD',
  GU: 'USD',
  HK: 'USD',
  HN: 'USD',
  HR: 'EUR',
  HU: 'EUR',
  ID: 'USD',
  IE: 'EUR',
  IL: 'USD',
  IN: 'USD',
  IS: 'EUR',
  IT: 'EUR',
  JM: 'USD',
  JO: 'USD',
  JP: 'USD',
  KH: 'USD',
  KN: 'USD',
  KR: 'USD',
  KW: 'USD',
  KY: 'USD',
  LB: 'USD',
  LC: 'USD',
  LI: 'CHF',
  LK: 'USD',
  LT: 'EUR',
  LU: 'EUR',
  LV: 'EUR',
  MA: 'USD',
  MC: 'EUR',
  MD: 'USD',
  MG: 'USD',
  MH: 'USD',
  MO: 'USD',
  MP: 'USD',
  MQ: 'USD',
  MS: 'USD',
  MT: 'EUR',
  MU: 'USD',
  MV: 'USD',
  MX: 'USD',
  MY: 'USD',
  NC: 'USD',
  NL: 'EUR',
  NO: 'EUR',
  NZ: 'USD',
  OM: 'USD',
  PA: 'USD',
  PE: 'USD',
  PF: 'USD',
  PG: 'USD',
  PH: 'USD',
  PK: 'USD',
  PL: 'EUR',
  PR: 'USD',
  PT: 'EUR',
  PY: 'USD',
  QA: 'USD',
  RE: 'EUR',
  RO: 'EUR',
  RS: 'USD',
  RU: 'USD',
  SA: 'USD',
  SC: 'USD',
  SE: 'EUR',
  SG: 'USD',
  SI: 'EUR',
  SK: 'EUR',
  SR: 'USD',
  SV: 'USD',
  SX: 'USD',
  TC: 'USD',
  TH: 'USD',
  TT: 'USD',
  TW: 'USD',
  TZ: 'USD',
  UA: 'USD',
  US: 'USD',
  UY: 'USD',
  VA: 'EUR',
  VC: 'USD',
  VE: 'USD',
  VG: 'USD',
  VI: 'USD',
  VN: 'USD',
  ZA: 'USD',
};
