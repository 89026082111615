import { createReducer } from '@reduxjs/toolkit';
import {
  getActionName,
  isAsyncType,
  isFulfilledRequest,
  isPendingRequest,
  isRejectedRequest,
} from '../../../utils/actionTypeUtils';
import { clearRequestStackAction } from './actions';
import { RequestState } from './types';

export const initialState = {} as RequestState;

const requestReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearRequestStackAction, () => initialState)
    .addMatcher(
      (action) => isAsyncType(action.type),
      (state, action) => {
        const actionName = getActionName(action.type);
        state[actionName] = {};
        if (isRejectedRequest(action.type)) {
          const { code, name, message } = action?.error || {};
          state[actionName] = {
            status: 'rejected',
            error: {
              code: code || '',
              name: name || '',
              message: message || '',
            },
            errors: action?.payload?.errors || [],
          };
        }
        if (isPendingRequest(action.type)) {
          state[actionName] = {
            status: 'pending',
          };
        }
        if (isFulfilledRequest(action.type)) {
          const payload = action?.payload || {};
          state[actionName] = {
            status: 'fulfilled',
            payload,
          };
        }
      },
    );
});

export default requestReducer;
