import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { requestSelector } from '../../../../selectors/request';
import { useAppDispatch, useAppSelector } from '../../../../state';
import {
  updateWorkshopPriceAction,
  UpdateWorkshopPriceRequestServices,
} from '../../../../state/ducks/workshopService/actions';
import { Price, Service } from '../../../../state/ducks/workshopService/types';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  price: Price;
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
};

type FormData = { services: UpdateWorkshopPriceRequestServices };

const initialFormData: FormData = {
  services: {},
};

const ACTIVE = 'active';
const DELETE = 'delete';
const INDIVIDUAL_OFFER = 'individualOffer';
const VALUE = 'value';

const renderServiceRows = (
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  services: Service[],
  formData: FormData,
) => {
  return services.map((service) => {
    return (
      <TableRow key={service.key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell>{service.name}</TableCell>
        <TableCell>
          <TextField
            margin='dense'
            id={`${VALUE}_${service.key}`}
            name={`${VALUE}_${service.key}`}
            type='number'
            value={formData.services?.[service?.key]?.value}
            onChange={handleChange}
            label={<FormattedMessage id='common_labels_price' defaultMessage='Price' />}
            fullWidth
            disabled={formData.services?.[service?.key]?.delete || formData.services?.[service?.key]?.individualOffer}
          />
        </TableCell>
        <TableCell>
          <Switch
            checked={formData.services?.[service?.key]?.individualOffer}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            name={`${INDIVIDUAL_OFFER}_${service.key}`}
            disabled={formData.services?.[service?.key]?.delete}
          />
        </TableCell>
        <TableCell>
          <Switch
            color='success'
            checked={formData.services?.[service?.key]?.active}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            name={`${ACTIVE}_${service.key}`}
            disabled={formData.services?.[service?.key]?.delete}
          />
        </TableCell>
        <TableCell>
          <Switch
            color='error'
            checked={formData.services?.[service?.key]?.delete}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            name={`${DELETE}_${service.key}`}
          />
        </TableCell>
      </TableRow>
    );
  });
};

const UpdateWorkshopPriceModal: React.FC<Props> = ({ price, onSuccess, handleToggleModal, show }) => {
  const dispatch = useAppDispatch();
  const services = useAppSelector((store) => store.workshopService.services);
  const updateRequest = useAppSelector((state) => requestSelector(state, updateWorkshopPriceAction.typePrefix));

  const [showUpdateSnackbar, setShowUpdateSnackbar] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [isFormValid, setIsFormValid] = useState(false);

  const previousFormData: FormData = initialFormData;
  services.forEach((service) => {
    const currentService = price.services?.[service.key];
    previousFormData.services[service.key] = !!currentService
      ? {
          value: currentService?.value?.toString() || '',
          active: currentService?.active,
          individualOffer: currentService?.value === null,
          delete: false,
        }
      : { value: '', active: true, individualOffer: false, delete: false };
  });

  const clearForm = () => {
    setFormData(initialFormData);
  };

  const handleClose = (clear = false) => {
    if (clear) {
      clearForm();
    }
    handleToggleModal();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const formServices = { ...formData.services };
    const [name, service] = event.target.name.split('_');
    switch (name) {
      case ACTIVE:
      case DELETE:
      case INDIVIDUAL_OFFER:
        formServices[service][name] = event.target.checked;
        break;
      case VALUE:
        formServices[service][name] = event.target.value;
    }
    setIsFormValid(true);
    if (
      name !== DELETE &&
      price.services?.[service] &&
      formServices[service].value === '' &&
      formServices[service].active &&
      !formServices[service].individualOffer
    ) {
      formServices[service].delete = true;
    }
    setFormData({ ...formData, services: formServices });
  };

  const submitForm = () => {
    dispatch(
      updateWorkshopPriceAction({
        id: price.id,
        requestBody: formData.services,
      }),
    ).then((response) => {
      setShowUpdateSnackbar(true);
      if (response?.payload && !response.payload?.errors) {
        clearForm();
        handleToggleModal();
        onSuccess();
      }
    });
  };

  useEffect(() => {
    setFormData(previousFormData);
  }, []);

  useEffect(() => {
    clearForm();
  }, []);

  useEffect(() => {
    clearForm();
  }, [show]);

  if (!price) {
    return null;
  }

  return (
    <>
      {showUpdateSnackbar && (
        <ActionStatusSnackbar
          actionName={updateWorkshopPriceAction.typePrefix}
          config={{ messageValues: { entry: `${price.brand}${price.model ? ` ${price.model}` : ''}` } }}
        />
      )}
      <Dialog open={show} onClose={handleToggleModal}>
        <DialogTitle>
          <FormattedMessage
            id='workshopPrices_prices_updateModal_title'
            defaultMessage='{fallback, select, true {Update Default entry} other {Update entry "{entry}"}}'
            values={{
              fallback: !price.brand && !price.model,
              entry: `${price.brand}${price.model ? ` ${price.model}` : ''}`,
            }}
          />
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText>
              <FormattedMessage
                id='workshopPrices_prices_updateModal_description'
                defaultMessage='To update the prices for this entry, please adjust the fields below as needed.'
              />
            </DialogContentText>
            <TableContainer component={Paper}>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id='workshop_labels_service' defaultMessage='Service' />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id='common_labels_price' defaultMessage='Price' />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id='workshopPrices_prices_individualOffer' defaultMessage='Individual Offer' />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id='workshopPrices_labels_active' defaultMessage='Active' />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id='common_delete' defaultMessage='Delete' />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderServiceRows(
                    handleChange,
                    services.filter((s) => s.active),
                    formData,
                  )}
                  {services.filter((s) => !s.active)?.length ? (
                    <>
                      <TableRow>
                        <TableCell align='center' colSpan={5}>
                          <FormattedMessage
                            id='workshopPrices_labels_inactiveServices'
                            defaultMessage='Inactive Services'
                          />
                        </TableCell>
                      </TableRow>
                      {renderServiceRows(
                        handleChange,
                        services.filter((s) => !s.active),
                        formData,
                      )}
                    </>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(true)} color='error'>
            <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
          </Button>
          <Button onClick={submitForm} disabled={!isFormValid} color='success'>
            <FormattedMessage id='common_save' defaultMessage='Save' />
          </Button>
        </DialogActions>
        {updateRequest?.status === 'pending' && <LinearProgress color='primary' />}
      </Dialog>
    </>
  );
};

export default UpdateWorkshopPriceModal;
