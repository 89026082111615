import { Preview } from '@mui/icons-material';
import { Button, IconButton, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { GROUPS } from '../../../../constants/groups';
import { currentUserSelector } from '../../../../selectors/user';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { setViewAsGroupAction } from '../../../../state/ducks/user/actions';

const VIEW_GROUPS: Record<string, string> = { ...GROUPS, NONE: 'None', RESET: 'Reset' };

const UserGroupSwitcher: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isBreakpointXS = useMediaQuery(theme.breakpoints.only('xs'));

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGroupChange = (group: string) => {
    dispatch(setViewAsGroupAction(group));
    handleClose();
  };

  return (
    <>
      {isBreakpointXS ? (
        <IconButton
          size='small'
          aria-label='logout'
          aria-controls='menu-appbar'
          aria-haspopup='true'
          onClick={handleOpen}
          color='inherit'>
          <Preview />
        </IconButton>
      ) : (
        <Button
          className='group-switcher'
          color='inherit'
          size='small'
          variant='outlined'
          onClick={handleOpen}
          startIcon={<Preview />}>
          View as{currentUser?.viewAsGroup ? `: ${currentUser?.viewAsGroup}` : ''}
        </Button>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        variant='menu'>
        {Object.entries(VIEW_GROUPS)
          .filter(([key]) => key !== 'ADMIN')
          .map(([key, name]) => (
            <MenuItem key={key} onClick={() => handleGroupChange(name)} selected={currentUser.viewAsGroup === name}>
              {name}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default UserGroupSwitcher;
