import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { GROUPS } from '../../../../constants/groups';
import { sellRequestSelector } from '../../../../selectors/sell';
import { isAuthorizedSelector } from '../../../../selectors/user';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { finalizeSellRequestOfferAction } from '../../../../state/ducks/sell/actions';
import { OfferStatus } from '../../../../state/ducks/sell/types';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
  status: string;
};

const FinalizeModal: React.FC<Props> = ({ show, handleToggleModal, onSuccess, status }) => {
  const dispatch = useAppDispatch();
  const isSupplyManager = useAppSelector((state) => isAuthorizedSelector(state, [GROUPS.SUPPLY_MANAGER]));
  const sellRequestItem = useAppSelector(sellRequestSelector);
  const [showFinalizeSnackbar, setShowFinalizeSnackbar] = useState(false);

  const finalizeOffer = () => {
    if (!sellRequestItem?.offer) {
      return;
    }

    dispatch(
      finalizeSellRequestOfferAction({
        reference: sellRequestItem.reference.toString(),
        status,
      }),
    ).then((response) => {
      setShowFinalizeSnackbar(true);
      if (response.payload) {
        handleToggleModal();
        onSuccess();
      }
    });
  };

  return (
    <>
      {showFinalizeSnackbar && <ActionStatusSnackbar actionName={finalizeSellRequestOfferAction.typePrefix} />}
      {isSupplyManager ? (
        <Dialog open={show} onClose={handleToggleModal}>
          <DialogContent>
            <FormattedMessage
              id='sellRequests_finalizeModal_confirmation'
              defaultMessage='Are you sure? The process cannot be rolled back'
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleToggleModal}>
              <FormattedMessage id='common_no' defaultMessage='No' />
            </Button>
            <Button color={status === OfferStatus.APPROVED ? 'success' : 'error'} onClick={finalizeOffer}>
              {status === OfferStatus.APPROVED && <FormattedMessage id='common_approve' defaultMessage='Approve' />}
              {status === OfferStatus.DECLINED && <FormattedMessage id='common_decline' defaultMessage='Decline' />}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};

export default FinalizeModal;
