import { get, post } from '../../utils/httpUtils';
import { APIRoute } from '../../routing';

export const getCurrentUser = () => {
  return get(APIRoute.AUTH_USER);
};

export const createAccessToken = () => {
  return post(APIRoute.AUTH_ACCESS_TOKEN);
};

export const logout = () => {
  return post(APIRoute.AUTH_LOGOUT);
};
