import { DeleteForever } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { ButtonGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { workshopServiceServicesSelector } from '../../../selectors/workshopService';
import { workshopServicePricesSelector } from '../../../selectors/workshopService/workshopService.selector';
import { useAppDispatch, useAppSelector } from '../../../state';
import { getWorkshopPriceListAction, getWorkshopServicesAction } from '../../../state/ducks/workshopService/actions';
import { Service } from '../../../state/ducks/workshopService/types';
import ButtonGroupIconButton from '../../components/buttons/ButtonGroupIconButton';
import BooleanIcon from '../../components/icons/BooleanIcon';
import Price from '../../components/price/Price';
import ActionStatusSnackbar from '../../components/snackbars/ActionStatusSnackbar';
import ServerSidePaginatedTable, {
  ServerSidePaginatedTableColDef,
} from '../../components/table/ServerSidePaginatedTable';
import DeleteWorkshopPriceModal from './components/DeleteWorkshopPriceModal';
import UpdateWorkshopPriceModal from './components/UpdateWorkshopPriceModal';
import WorkshopPriceListTableToolbar from './components/WorkshopPriceListTableToolbar';
import styles from './WorkshopPrices.module.scss';

const pricesTableColumns: ServerSidePaginatedTableColDef[] = [
  {
    id: 'common_labels_id',
    headerName: 'ID',
    field: 'id',
    maxWidth: 60,
    sortable: false,
    flex: 1,
  },
  {
    id: 'common_labels_brand',
    headerName: 'Brand',
    field: 'brand',
    minWidth: 150,
    sortable: false,
    flex: 1,
  },
  {
    id: 'common_labels_model',
    headerName: 'Model',
    field: 'model',
    minWidth: 150,
    sortable: false,
    flex: 1,
  },
];

const WorkshopPriceList: React.FC = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { services } = useAppSelector(workshopServiceServicesSelector);
  const { prices, pricesTotal } = useAppSelector(workshopServicePricesSelector);
  const [tableColumns, setTableColumns] = useState<ServerSidePaginatedTableColDef[]>(pricesTableColumns);

  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [searchParams] = useState({
    where: '',
  });
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  useEffect(() => {
    if (!services.length) {
      const urlSearchParams = new URLSearchParams();
      dispatch(getWorkshopServicesAction(urlSearchParams));
    }
  }, []);

  const servicesColumns = (): ServerSidePaginatedTableColDef[] => {
    const result: ServerSidePaginatedTableColDef[] = [];
    services.forEach((service: Service) => {
      if (service.active) {
        result.push({
          headerName: service.name,
          field: service.key,
          align: 'center',
          minWidth: 150,
          sortable: false,
          renderCell: (params: any) => {
            const { services: rowServices } = params.row;
            const rowService = rowServices?.[service?.key];

            if (!rowService) {
              return '';
            }
            if (!rowService.active) {
              return <BooleanIcon check={false} />;
            }
            return rowService?.value === null ? (
              <FormattedMessage id='workshopPrices_prices_individualOffer' defaultMessage='Individual Offer' />
            ) : (
              <Price value={rowService?.value} />
            );
          },
        });
      }
    });
    return result;
  };

  const actionsColumn: ServerSidePaginatedTableColDef = {
    id: 'common_actions',
    headerName: 'Actions',
    field: 'actions',
    align: 'left',
    sortable: false,
    renderCell: (params: any) => (
      <ButtonGroup variant='text'>
        <ButtonGroupIconButton
          color='info'
          onClick={() => {
            setSelectedPrice(params.row);
            setShowUpdateModal(true);
          }}
          title={intl.formatMessage({
            id: 'common_edit',
            defaultMessage: 'Edit',
          })}>
          <EditIcon />
        </ButtonGroupIconButton>
        {params.row?.brand && (!params.row?.activeEntries || params.row?.activeEntries === 1) && (
          <ButtonGroupIconButton
            color='error'
            onClick={() => {
              setSelectedPrice(params.row);
              setShowDeleteConfirmationModal(true);
            }}
            title={intl.formatMessage({
              id: 'common_delete',
              defaultMessage: 'Delete',
            })}>
            <DeleteForever />
          </ButtonGroupIconButton>
        )}
      </ButtonGroup>
    ),
  };

  useEffect(() => {
    setTableColumns([...pricesTableColumns, ...servicesColumns(), actionsColumn]);
  }, [prices.length]);

  useEffect(() => {
    setTableColumns([...pricesTableColumns, ...servicesColumns(), actionsColumn]);
  }, [services.length]);

  return (
    <div className={styles.wrapper}>
      <ActionStatusSnackbar actionName={getWorkshopPriceListAction.typePrefix} />
      {showUpdateModal && selectedPrice && (
        <UpdateWorkshopPriceModal
          show={showUpdateModal}
          price={selectedPrice}
          handleToggleModal={() => setShowUpdateModal(!showUpdateModal)}
          onSuccess={() => setTriggerRefresh(!triggerRefresh)}
        />
      )}
      {showDeleteConfirmationModal && selectedPrice && (
        <DeleteWorkshopPriceModal
          show={showDeleteConfirmationModal}
          price={selectedPrice}
          handleToggleModal={() => setShowDeleteConfirmationModal(!showDeleteConfirmationModal)}
          onSuccess={() => setTriggerRefresh(!triggerRefresh)}
        />
      )}
      <ServerSidePaginatedTable
        rows={prices}
        columns={tableColumns}
        totalCount={pricesTotal}
        triggerRefresh={triggerRefresh}
        fetchDataEffect={getWorkshopPriceListAction}
        searchParams={searchParams}
        slots={{
          toolbar: WorkshopPriceListTableToolbar,
        }}
        slotProps={{
          toolbar: {
            onSuccess: () => setTriggerRefresh(!triggerRefresh),
          },
        }}
      />
    </div>
  );
};

export default WorkshopPriceList;
