import {
  Add,
  AttachMoney,
  CurrencyFranc,
  CurrencyPound,
  Discount,
  Euro,
  Percent,
  Remove,
  ShoppingCart,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import deLocale from 'date-fns/locale/de';
import * as React from 'react';
import { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { FormattedMessage } from 'react-intl';
import { CreateBatchJobParams } from '../../../../api/server/commercetools';
import { COUNTRY_CODES } from '../../../../constants/countryCodes';
import { REGIONS } from '../../../../constants/regions';
import { commercetoolsCategoriesSelector } from '../../../../selectors/commercetools';
import { currentUserSelector } from '../../../../selectors/user';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { createBatchJobAction, getCategoriesAction } from '../../../../state/ducks/commercetools/actions';
import { getCurrenciesFromCountries } from '../../../../utils/countryUtils';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  onSuccess: () => void;
};

const initialFormData: CreateBatchJobParams & {
  countriesText: string;
  groupsText: string;
  minCartValue: number;
  discountValue: number;
} = {
  code: '',
  currencies: ['EUR', 'GBP', 'USD', 'CHF'],
  validFrom: new Date(),
  validUntil: new Date(new Date().setDate(new Date().getDate() + 1)),
  countries: [],
  countriesText: '',
  discounts: {},
  minCartValue: 100,
  discountValue: 0,
  amount: '',
  groupsText: '',
  categories: [],
  isPercentValue: false,
};

const CreateBatchJobModal: React.FC<Props> = ({ onSuccess }) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);
  const { categories } = useAppSelector(commercetoolsCategoriesSelector);
  const [modalOpen, setModalOpen] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedCountryPreset, setSelectedCountryPreset] = useState('');
  const [selectedDatePreset, setSelectedDatePreset] = useState('');
  const [newDiscountTierFieldsValid, setNewDiscountTierFieldsValid] = useState(false);

  const clearForm = () => {
    setFormData(initialFormData);
    setSelectedCountryPreset('');
    setSelectedDatePreset('');
  };

  const handleClickOpen = () => {
    setModalOpen(true);
    if (!categories?.length) {
      const urlSearchParams = new URLSearchParams();
      dispatch(getCategoriesAction(urlSearchParams));
    }
  };

  const handleClose = (clear = false) => {
    if (clear) {
      clearForm();
    }
    setModalOpen(false);
  };

  const handleDatePresets = (event: React.MouseEvent<HTMLElement>, dateRange: string) => {
    if (dateRange) {
      const date = new Date(formData.validFrom);
      switch (dateRange) {
        case '1week':
          date.setDate(date.getDate() + 7);
          break;
        case '4weeks':
          date.setDate(date.getDate() + 28);
          break;
        case '1year':
          date.setFullYear(date.getFullYear() + 1);
          break;
        case '2years':
          date.setFullYear(date.getFullYear() + 2);
          break;
      }
      date.setHours(23, 59, 59);
      setFormData({ ...formData, validUntil: date });
      setSelectedDatePreset(dateRange);
    }
  };

  const handleCountryPresets = (event: React.MouseEvent<HTMLElement>, newCountries: string) => {
    if (newCountries) {
      const countryList = newCountries.split(',');
      const currencies = getCurrenciesFromCountries(countryList, initialFormData.currencies);
      setFormData({ ...formData, countriesText: newCountries, countries: countryList, currencies });
      setSelectedCountryPreset(newCountries);
    }
  };

  const handleDateChange = (trigger: string, newDate: Date | null | unknown) => {
    const date = newDate as Date;
    const newData = {
      [trigger]: date,
    };
    if (trigger === 'validFrom' && date && date >= formData.validUntil) {
      newData.validUntil = new Date(new Date(date).setDate(date.getDate() + 1));
    }
    // preset times for validity dates
    if (newData.validFrom) {
      newData.validFrom.setHours(0, 0, 0);
    }
    if (newData.validUntil) {
      newData.validUntil.setHours(23, 59, 59);
    }
    setFormData({ ...formData, ...newData });
    setSelectedDatePreset('');
  };

  const handleCategoriesChange = (event: SelectChangeEvent<string[]>) => {
    let newCategories: string[] = [];
    if (Array.isArray(event.target.value)) {
      newCategories = event.target.value;
    }
    setFormData({ ...formData, categories: newCategories });
  };

  const addDiscountTier = () => {
    const tiers = { ...formData.discounts };
    tiers[formData.minCartValue.toString()] = parseInt(String(formData.discountValue), 10);
    setFormData({ ...formData, discounts: tiers });
  };

  const removeDiscountTier = (key: string) => {
    const tiers = { ...formData.discounts };
    delete tiers[key];
    if (!Object.entries(tiers).length) {
      setIsFormValid(false);
    }
    setFormData({ ...formData, discounts: tiers });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case 'countriesText':
        let countryList: string[] = [];
        if (event.target.value) {
          countryList = event.target.value.split(',');
          countryList = Object.keys(
            countryList.reduce<Record<string, boolean>>((acc, item) => {
              acc[item] = true;
              return acc;
            }, {}),
          );
          countryList = countryList.filter((country) => COUNTRY_CODES.includes(country));
        }
        const currencies = getCurrenciesFromCountries(countryList, initialFormData.currencies);
        setFormData({ ...formData, [event.target.name]: event.target.value, countries: countryList, currencies });
        setSelectedCountryPreset(countryList.sort((a, b) => a.localeCompare(b)).join(','));
        break;
      case 'isPercentValue':
        const isPercentValue = event.target.checked;
        const { discounts } = formData;
        const tiers = { ...discounts };
        if (isPercentValue && Object.keys(discounts)?.length > 0) {
          Object.keys(discounts).forEach((key) => {
            if (discounts[key] > 50) {
              delete tiers[key];
              if (!Object.entries(tiers).length) {
                setIsFormValid(false);
              }
            }
          });
        }
        setFormData({ ...formData, [event.target.name]: isPercentValue, discounts: tiers });
        break;
      default:
        if (event.target.type === 'number') {
          setFormData({ ...formData, [event.target.name]: parseInt(event.target.value, 10) });
        } else {
          setFormData({ ...formData, [event.target.name]: event.target.value });
        }
    }
  };

  const submitForm = () => {
    const { countriesText, minCartValue, discountValue, groupsText, amount, ...rest } = formData;
    const createParams: CreateBatchJobParams = { ...rest, createdBy: currentUser.username };
    createParams.groups = groupsText ? groupsText.split(',') : [];
    createParams.amount = parseInt(String(amount), 10) || null;
    dispatch(createBatchJobAction(createParams)).then((response) => {
      setShowSnackbar(true);
      if (response?.payload && !response?.payload?.errors) {
        clearForm();
        setModalOpen(false);
        onSuccess();
      }
    });
  };

  useEffect(() => {
    const { code, amount, discounts, currencies, minCartValue, discountValue, isPercentValue } = formData;
    if (
      code &&
      /^[A-Z0-9\-_*]+[#]{1}$/.test(code) &&
      (amount === '' || parseInt(String(amount), 10) >= 2) &&
      Object.keys(discounts)?.length > 0 &&
      currencies?.length > 0
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
    setNewDiscountTierFieldsValid(
      minCartValue >= 100 &&
        !!discountValue &&
        parseInt(String(discountValue), 10) < parseInt(String(minCartValue), 10) &&
        (!isPercentValue || discountValue <= 50),
    );
  }, [formData]);

  return (
    <>
      {showSnackbar && (
        <ActionStatusSnackbar
          actionName={createBatchJobAction.typePrefix}
          config={{ messageValues: { code: formData.code } }}
        />
      )}
      <Button startIcon={<Add />} color='success' variant='outlined' onClick={handleClickOpen}>
        <FormattedMessage id='productDiscounts_actions_createBatchJob' defaultMessage='Create new Batch Job' />
      </Button>
      <Dialog open={modalOpen} onClose={() => handleClose()} maxWidth='sm' fullWidth>
        <DialogTitle>
          <FormattedMessage id='productDiscountBatchJobs_createModal_title' defaultMessage='New Batch Job' />
        </DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
            <DialogContentText mb={2}>
              <FormattedMessage
                id='productDiscountBatchJobs_createModal_description'
                defaultMessage='To add a new Batch Job, please fill out the fields below. After creation, it will run in the background and you can check the status by refreshing the list of Batch Jobs'
              />
            </DialogContentText>
            <Stack spacing={2}>
              <TextField
                margin='dense'
                id='code'
                name='code'
                value={formData.code}
                required
                error={!/^[A-Z0-9\-_*]+[#]{1}$/.test(formData.code)}
                onChange={handleChange}
                label={<FormattedMessage id='productDiscounts_labels_codePattern' defaultMessage='Code Pattern' />}
                helperText={
                  <FormattedMessage
                    id='productDiscountBatchJobs_createModal_codePatternHint'
                    defaultMessage='Codes can only contain uppercase letters, numbers, _, - or #.{br}# will be used to determine the placement of the generated characters.'
                    values={{ br: <br /> }}
                  />
                }
                fullWidth
                inputProps={{ pattern: '^[A-Z0-9-_#]+$' }}
              />
              <TextField
                margin='dense'
                type='number'
                id='amount'
                name='amount'
                value={formData.amount}
                onChange={handleChange}
                label={<FormattedMessage id='productDiscounts_labels_amount' defaultMessage='Amount' />}
                helperText={
                  <FormattedMessage
                    id='productDiscountBatchJobs_createModal_amountHint'
                    defaultMessage='Define how many discount codes should be created. Must be at least 2.'
                  />
                }
                fullWidth
              />
              <TextField
                margin='dense'
                id='groupsText'
                name='groupsText'
                value={formData.groupsText}
                onChange={handleChange}
                label={<FormattedMessage id='productDiscounts_labels_groups' defaultMessage='Groups' />}
                helperText={
                  <FormattedMessage
                    id='productDiscounts_createModal_groupsHint'
                    defaultMessage='Comma-separated list of groups. Example: add the ticket ID or the marketing campaign name as a group.'
                  />
                }
                fullWidth
              />
              <FormControl>
                <InputLabel id='categoriesLabel'>
                  <FormattedMessage id='productDiscounts_labels_categories' defaultMessage='Categories' />
                </InputLabel>
                <Select
                  margin='dense'
                  id='categories'
                  name='categories'
                  value={formData.categories}
                  onChange={handleCategoriesChange}
                  multiple
                  input={
                    <OutlinedInput
                      label={<FormattedMessage id='productDiscounts_labels_categories' defaultMessage='Categories' />}
                    />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={categories.find((c) => c.id === value)?.name?.en} />
                      ))}
                    </Box>
                  )}
                  labelId='categoriesLabel'
                  fullWidth>
                  {categories.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name.en}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <DialogContentText fontWeight='medium' mb={1} color='primary' borderBottom='1px solid'>
                <FormattedMessage id='productDiscounts_createModal_validity' defaultMessage='Validity period' />
              </DialogContentText>
              <Stack direction='row' spacing={2} alignItems='center'>
                <DialogContentText color='primary'>
                  <FormattedMessage id='productDiscounts_createModal_presets' defaultMessage='Presets' />
                </DialogContentText>
                <ToggleButtonGroup
                  color='primary'
                  exclusive
                  id='countryPresets'
                  value={selectedDatePreset}
                  onChange={handleDatePresets}>
                  <ToggleButton value='1week'>
                    <FormattedMessage id='common_weeks' values={{ count: 1 }} />
                  </ToggleButton>
                  <ToggleButton value='4weeks'>
                    <FormattedMessage id='common_weeks' values={{ count: 4 }} />
                  </ToggleButton>
                  <ToggleButton value='1year'>
                    <FormattedMessage id='common_years' values={{ count: 1 }} />
                  </ToggleButton>
                  <ToggleButton value='2years'>
                    <FormattedMessage id='common_years' values={{ count: 2 }} />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              <Stack direction='row' spacing={2}>
                <DatePicker
                  label={<FormattedMessage id='productDiscounts_labels_validFrom' defaultMessage='Valid from' />}
                  value={formData.validFrom}
                  onChange={(newDate) => handleDateChange('validFrom', newDate)}
                  minDate={new Date()}
                />
                <DatePicker
                  label={<FormattedMessage id='productDiscounts_labels_validUntil' defaultMessage='Valid until' />}
                  value={formData.validUntil}
                  onChange={(newDate) => handleDateChange('validUntil', newDate)}
                  minDate={new Date(new Date(formData.validFrom).setDate(formData.validFrom.getDate() + 1))}
                />
              </Stack>

              <DialogContentText fontWeight='medium' color='primary' borderBottom='1px solid'>
                <FormattedMessage id='productDiscounts_labels_countries' defaultMessage='Countries' />
              </DialogContentText>
              <Stack direction='row' spacing={2} alignItems='center'>
                <DialogContentText color='primary'>
                  <FormattedMessage id='productDiscounts_createModal_presets' defaultMessage='Presets' />
                </DialogContentText>
                <ToggleButtonGroup
                  color='primary'
                  exclusive
                  id='countryPresets'
                  value={selectedCountryPreset}
                  onChange={handleCountryPresets}>
                  <ToggleButton value={REGIONS.DACH}>Dach</ToggleButton>
                  <ToggleButton value={REGIONS.DOMAINS}>Domains</ToggleButton>
                  <ToggleButton value={REGIONS.EU}>EU</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              <TextField
                margin='dense'
                id='countriesText'
                name='countriesText'
                value={formData.countriesText}
                onChange={handleChange}
                label={<FormattedMessage id='productDiscounts_labels_countries' defaultMessage='Countries' />}
                helperText={
                  <FormattedMessage
                    id='productDiscounts_createModal_countriesHint'
                    defaultMessage='Comma-separated list of country codes. Leave empty to make the code available in all countries.'
                  />
                }
                fullWidth
                multiline
                minRows={2}
              />
              <DialogContentText color='primary'>
                <FormattedMessage
                  id='productDiscounts_createModal_detectedCountries'
                  defaultMessage='Countries detected: {countryCount}'
                  values={{ countryCount: formData.countries?.length }}
                />
              </DialogContentText>
              {formData?.countries?.length ? (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {formData?.countries?.map((country) => (
                    <Tooltip key={country} title={country} arrow>
                      <div>
                        <ReactCountryFlag countryCode={country} style={{ fontSize: '30px', lineHeight: '1em' }} />
                      </div>
                    </Tooltip>
                  ))}
                </Box>
              ) : (
                <DialogContentText color='success.main'>
                  <FormattedMessage
                    id='productDiscounts_detailsModal_allCountries'
                    defaultMessage='This discount code is valid for all countries.'
                  />
                </DialogContentText>
              )}

              <DialogContentText fontWeight='medium' color='primary' borderBottom='1px solid'>
                <FormattedMessage id='productDiscounts_labels_discountTiers' defaultMessage='Discount Tiers' />
              </DialogContentText>
              <Stack direction='row' justifyContent='space-between' spacing={2}>
                <ToggleButtonGroup disabled color='success' id='currencies' value={formData.currencies}>
                  <ToggleButton value='EUR'>
                    <Euro />
                  </ToggleButton>
                  <ToggleButton value='GBP'>
                    <CurrencyPound />
                  </ToggleButton>
                  <ToggleButton value='USD'>
                    <AttachMoney />
                  </ToggleButton>
                  <ToggleButton value='CHF'>
                    <CurrencyFranc />
                  </ToggleButton>
                </ToggleButtonGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.isPercentValue}
                      onChange={handleChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      name='isPercentValue'
                    />
                  }
                  label={
                    <FormattedMessage id='productDiscounts_labels_isRelative' defaultMessage='relative values in %' />
                  }
                />
              </Stack>
              <TableContainer component={Paper}>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id='productDiscounts_labels_minCartValue' defaultMessage='Min. cart value' />
                      </TableCell>
                      <TableCell>
                        {formData.isPercentValue ? (
                          <FormattedMessage
                            id='productDiscounts_labels_discountPercentage'
                            defaultMessage='Discount Percentage'
                          />
                        ) : (
                          <FormattedMessage
                            id='productDiscounts_labels_discountValue'
                            defaultMessage='Discount Value'
                          />
                        )}
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(formData.discounts)?.map(([key, value]) => (
                      <TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>{key}</TableCell>
                        <TableCell>
                          {value}
                          {formData.isPercentValue && '%'}
                        </TableCell>
                        <TableCell>
                          <IconButton color='error' onClick={() => removeDiscountTier(key)} size='small'>
                            <Remove fontSize='inherit' />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {Object.keys(formData.discounts).length < 10 && (
                <Stack direction='row' spacing={2}>
                  <FormControl>
                    <TextField
                      margin='dense'
                      type='number'
                      id='minCartValue'
                      name='minCartValue'
                      value={formData.minCartValue}
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <ShoppingCart />
                          </InputAdornment>
                        ),
                      }}
                      label={
                        <FormattedMessage id='productDiscounts_labels_minCartValue' defaultMessage='Min Cart Value' />
                      }
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      margin='dense'
                      type='number'
                      id='discountValue'
                      name='discountValue'
                      value={formData.discountValue}
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            {formData.isPercentValue ? <Percent /> : <Discount />}
                          </InputAdornment>
                        ),
                      }}
                      label={
                        formData.isPercentValue ? (
                          <>
                            <FormattedMessage
                              id='productDiscounts_labels_discountPercentage'
                              defaultMessage='Discount Percentage'
                            />
                            &nbsp;(max. 50%)
                          </>
                        ) : (
                          <FormattedMessage
                            id='productDiscounts_labels_discountValue'
                            defaultMessage='Discount Value'
                          />
                        )
                      }
                    />
                  </FormControl>
                  <Button color='success' onClick={addDiscountTier} disabled={!newDiscountTierFieldsValid}>
                    <FormattedMessage id='common_add' defaultMessage='Add' />
                  </Button>
                </Stack>
              )}
            </Stack>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(true)} color='error'>
            <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
          </Button>
          <Button onClick={submitForm} disabled={!isFormValid} color='success'>
            <FormattedMessage id='common_save' defaultMessage='Save' />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateBatchJobModal;
