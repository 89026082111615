import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import { green, red } from '@mui/material/colors';
import React from 'react';

type Props = {
  check?: boolean;
};

const BooleanIcon: React.FC<Props> = ({ check }) => {
  if (check === undefined) return <RemoveIcon />;

  return check ? <CheckIcon sx={{ color: green[500] }} /> : <CloseIcon sx={{ color: red[500] }} />;
};
export default BooleanIcon;
