import React from 'react';
import { useAppSelector } from '../../../state';
import { currentUserSelector } from '../../../selectors/user';
import { getPriceLocaleString } from '../../../utils/textUtils';

type Props = {
  value?: number | null | undefined;
  currency?: string;
  country?: string;
  prefix?: any;
  showZero?: boolean;
};

const Price: React.FC<Props> = ({ value, currency = 'EUR', country = 'DE', prefix = '', showZero = false }) => {
  const currentUser = useAppSelector(currentUserSelector);
  const { language } = currentUser;

  if (!value && !showZero) return <>&mdash;</>;
  return (
    <>
      {prefix ? <>{prefix}&nbsp;</> : ''}
      {getPriceLocaleString(Number(value), language, country, currency)}
    </>
  );
};

export default Price;
