import { Stack } from '@mui/material';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import DatePicker from './DatePicker';

type Props = {
  startDate?: unknown;
  endDate?: unknown;
  onFilterUpdate: (start: Moment, end: Moment) => void;
  daysRange?: number;
  maxEndDate?: Moment;
};

const DateFilter: React.FC<Props> = ({ startDate, endDate, onFilterUpdate, daysRange = 31, maxEndDate }) => {
  const intl = useIntl();
  // Get start date from the props or set to the start of the current day
  const [start, setStart] = useState<unknown>(startDate || moment.utc().startOf('day'));
  // Get end date from the props or set to the end of the current day
  const [end, setEnd] = useState<unknown>(endDate || maxEndDate || moment.utc().endOf('day'));

  // The allowed min/max range for the date picker
  const minRange = maxEndDate
    ? maxEndDate?.clone()?.subtract(daysRange, 'days').startOf('day')
    : moment.utc().subtract(daysRange, 'days').startOf('day');
  const maxRange = moment(maxEndDate).local(true)?.hour(23).minute(59).second(59) || moment.utc().endOf('day');

  // Validates date fields and if validation passed, calls
  // onFilterUpdate from the props
  useEffect(() => {
    if (
      moment.isMoment(start) &&
      moment.isMoment(end) &&
      start.isSameOrBefore(maxRange) &&
      start.isSameOrAfter(minRange) &&
      end.isSameOrAfter(minRange) &&
      end.isSameOrBefore(maxRange) &&
      start.isSameOrBefore(end)
    ) {
      onFilterUpdate(start, end);
    }
  }, [start, end]);

  return (
    <Stack spacing={2} direction='row'>
      <DatePicker
        label={intl.formatMessage({ id: 'forms_dateFilter_startDate', defaultMessage: 'Start Date' })}
        value={start}
        onChange={(d: unknown) => {
          if (moment.isMoment(d)) {
            setStart(d.hour(0).minute(0).second(0));
          }
        }}
        maxDate={end}
        minDate={minRange}
      />
      <DatePicker
        label={intl.formatMessage({ id: 'forms_dateFilter_endDate', defaultMessage: 'End Date' })}
        value={end}
        onChange={(d: unknown) => {
          if (moment.isMoment(d)) {
            setEnd(d.hour(23).minute(59).second(59));
          }
        }}
        minDate={start}
        maxDate={maxRange}
      />
    </Stack>
  );
};

export default DateFilter;
