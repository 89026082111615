import { LicenseInfo } from '@mui/x-license-pro';
import { registerLocale } from 'i18n-iso-countries';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { APP_CONFIG } from './config/app';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { store } from './state';
import App from './views/App';

if (APP_CONFIG.useMockAPI) {
  const { worker } = require('./api/mocks/setup');
  worker.start();
}

registerLocale(require('i18n-iso-countries/langs/en.json'));
registerLocale(require('i18n-iso-countries/langs/de.json'));

LicenseInfo.setLicenseKey(
  '96efcaac300ea6c7775df5d26fdec533Tz03MTc0NCxFPTE3MjIyNTYzMzIwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=',
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
