import { Edit } from '@mui/icons-material';
import { Button, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { APP_CONFIG } from '../../../../config/app';
import { WORKSHOP_REPORT_QUESTIONS } from '../../../../constants/workshopReport';
import { ClientRouteGroups } from '../../../../routing/clientRoutes';
import { isAuthorizedSelector } from '../../../../selectors/user';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { getWorkshopInboundActivityAction } from '../../../../state/ducks/workshopInbound/actions';
import { WorkshopInboundWatchActivity } from '../../../../state/ducks/workshopInbound/types';
import { formatUTCToLocal } from '../../../../utils/datetime';
import { text2title } from '../../../../utils/textUtils';
import BooleanIcon from '../../../components/icons/BooleanIcon';
import WorkshopInboundReportForm from './WorkshopInboundReportForm';

type Props = {
  activity: WorkshopInboundWatchActivity;
  onSuccess?: () => void;
};

const reportStacks = [
  WORKSHOP_REPORT_QUESTIONS.slice(0, Math.ceil(WORKSHOP_REPORT_QUESTIONS.length / 3)),
  WORKSHOP_REPORT_QUESTIONS.slice(
    Math.ceil(WORKSHOP_REPORT_QUESTIONS.length / 3),
    Math.ceil(WORKSHOP_REPORT_QUESTIONS.length / 3) * 2,
  ),
  WORKSHOP_REPORT_QUESTIONS.slice(
    Math.ceil(WORKSHOP_REPORT_QUESTIONS.length / 3) * 2,
    WORKSHOP_REPORT_QUESTIONS.length,
  ),
];

const renderReportValue = (value: any, type: string) => {
  switch (type) {
    case 'boolean':
      return <BooleanIcon check={!!value} />;
    case 'options':
      return <Typography>{text2title(value || '')}</Typography>;
    case 'date':
      return !!value ? <Typography>{formatUTCToLocal(new Date(value).toString(), 'DD.MM.YYYY')}</Typography> : '';
    default:
      return <Typography>{value}</Typography>;
  }
};

const WorkshopInboundReportCard: React.FC<Props> = ({ activity, onSuccess }) => {
  const userCanEdit =
    useAppSelector((state) =>
      isAuthorizedSelector(
        state,
        ClientRouteGroups.WORKSHOP_INBOUND_ITEM.filter((g) => g.startsWith('Workshop')),
      ),
    ) || !APP_CONFIG.isProd;
  const dispatch = useAppDispatch();
  const [showReportForm, setShowReportForm] = useState(false);

  if (!activity.activity_report) {
    return null;
  }

  return (
    <>
      {showReportForm && (
        <WorkshopInboundReportForm
          activity={activity}
          show={showReportForm}
          handleToggleModal={() => setShowReportForm(false)}
          onSuccess={() => {
            dispatch(getWorkshopInboundActivityAction(activity.id.toString()));
            onSuccess && onSuccess();
          }}
        />
      )}
      <Card>
        <CardHeader
          title={
            <Stack direction='row' justifyContent='space-between'>
              <FormattedMessage
                id={`workshop_serviceActivityTypes_${activity.activity_type}`}
                defaultMessage={text2title(activity.activity_type)}
              />
              {' - '}
              <FormattedMessage id='workshopInbound_report_title' defaultMessage='Report' />
              {!activity.report_sent_to_erp && (
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={1}>
                  <Button
                    key='edit_report'
                    startIcon={<Edit />}
                    variant='outlined'
                    color='info'
                    disabled={!userCanEdit}
                    onClick={() => setShowReportForm(true)}>
                    <FormattedMessage id='common_edit' defaultMessage='Edit' />
                  </Button>
                </Stack>
              )}
            </Stack>
          }
        />
        <CardContent>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 0, md: 4 }}>
            {reportStacks.map((stack, index) => (
              <Stack key={index} direction='column'>
                {stack.map((item) => (
                  <Stack key={item.id} direction='row' spacing={1}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage {...item.label} />:
                    </Typography>
                    {activity.activity_report && renderReportValue(activity.activity_report[item.id], item.type)}
                  </Stack>
                ))}
              </Stack>
            ))}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default WorkshopInboundReportCard;
