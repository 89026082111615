import * as React from 'react';
import styles from './CategoryTableToolbar.module.scss';
import CreateCategoryModal from './CreateCategoryModal';

type Props = {
  onSuccess: () => void;
};

const CategoryTableToolbar: React.FC<Props> = ({ onSuccess }) => {
  return (
    <div className={styles.wrapper}>
      <CreateCategoryModal onSuccess={onSuccess} />
    </div>
  );
};

export default CategoryTableToolbar;
