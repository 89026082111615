import { Button, IconButton, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { APP_CONFIG } from '../../../config/app';
import { ClientRouteGroups } from '../../../routing/clientRoutes';
import { requestSelector } from '../../../selectors/request';
import { workshopInboundMaintenanceActivitySelector } from '../../../selectors/workshop-inbound/workshop-inbound.selector';
import { useAppDispatch, useAppSelector } from '../../../state';
import { text2title } from '../../../utils/textUtils';
import StatusBadge from '../badges/StatusBadge';
import ActionStatusSnackbar from '../snackbars/ActionStatusSnackbar';
import SimpleTable, { SimpleTableColDef } from '../table/SimpleTable';
import {
  getWorkshopInboundWatchAction,
  updateWorkshopInboundActivityAction,
} from '../../../state/ducks/workshopInbound/actions';
import { WorkshopInboundWatchActivity } from '../../../state/ducks/workshopInbound/types';
import { GridCellParams } from '@mui/x-data-grid';
import { ClientRoute, createPath } from '../../../routing';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { currentUserSelector, isAuthorizedSelector } from '../../../selectors/user';
import {
  NEXT_STATUS_MAPPING,
  WORKSHOP_ACTIVITIES_WITH_REPORT,
  WORKSHOP_EMPLOYEE_USERNAMES,
  WorkshopStatus,
} from '../../../constants/workshop';
import { GROUPS } from '../../../constants/groups';
import WorkshopInboundActivityUpdateForm from './WorkshopInboundActivityUpdateForm';
import EditIcon from '@mui/icons-material/Edit';

export const WORKSHOP_INBOUND_ACTIVITIES_TABLE_COLUMNS: SimpleTableColDef[] = [
  {
    id: 'workshop_labels_activity',
    headerName: 'Activity',
    field: 'activity_type',
    minWidth: 150,
    renderCell: (params) =>
      params.value && (
        <FormattedMessage
          id={`workshop_serviceActivityTypes_${params.value}`}
          defaultMessage={text2title(params.value)}
        />
      ),
  },
  {
    id: 'workshop_labels_service',
    headerName: 'Service',
    field: 'service_type',
    minWidth: 150,
    renderCell: (params) =>
      params.row?.service?.service_type && (
        <FormattedMessage
          id={`workshop_serviceActivityTypes_${params.row?.service?.service_type}`}
          defaultMessage={text2title(params.row?.service?.service_type)}
        />
      ),
  },
  {
    id: 'common_labels_status',
    headerName: 'Status',
    field: 'status',
    minWidth: 150,
    renderCell: (params) => <StatusBadge status={params.value} />,
  },
  {
    id: 'workshop_labels_assignee',
    headerName: 'Assignee',
    field: 'assignee',
    minWidth: 150,
    sortable: false,
    filterable: false,
  },
  {
    id: 'common_labels_updatedBy',
    headerName: 'Updated By',
    field: 'updated_by',
    minWidth: 150,
    sortable: false,
    filterable: false,
  },
];

type Props = {
  activities: WorkshopInboundWatchActivity[];
  fetchItem: () => void;
};

const WorkshopInboundItemActivitiesTable: React.FC<Props> = ({ activities, fetchItem }) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);
  const isAdminUser = useAppSelector((state) => isAuthorizedSelector(state, [GROUPS.ADMIN, GROUPS.WORKSHOP_ADMIN]));
  const userCanEdit =
    useAppSelector((state) =>
      isAuthorizedSelector(
        state,
        ClientRouteGroups.WORKSHOP_INBOUND_ITEM.filter((g) => g.startsWith('Workshop')),
      ),
    ) || !APP_CONFIG.isProd;
  const maintenanceActivity = useAppSelector(workshopInboundMaintenanceActivitySelector);

  const getWorkshopInboundItemRequest = useAppSelector((state) =>
    requestSelector(state, getWorkshopInboundWatchAction.typePrefix),
  );
  const updateActivityRequest = useAppSelector((state) =>
    requestSelector(state, updateWorkshopInboundActivityAction.typePrefix),
  );
  const [tableColumns, setTableColumns] = useState<SimpleTableColDef[]>(WORKSHOP_INBOUND_ACTIVITIES_TABLE_COLUMNS);
  const [selectedActivity, setSelectedActivity] = useState<WorkshopInboundWatchActivity | null>(null);
  const [showUpdateDrawer, setShowUpdateDrawer] = useState(false);
  const [showUpdateSnackbar, setShowUpdateSnackbar] = useState(false);

  useEffect(() => {
    const additionalColumns: SimpleTableColDef[] = [];
    if (isAdminUser) {
      additionalColumns.push({
        headerName: '',
        field: 'adminAction',
        width: 50,
        renderCell: (params: any) => {
          return (
            <IconButton
              aria-label='edit'
              size='small'
              onClick={() => {
                setSelectedActivity(params.row);
                setShowUpdateDrawer(true);
              }}>
              <EditIcon fontSize='small' />
            </IconButton>
          );
        },
      });
    }
    additionalColumns.push({
      headerName: '',
      field: 'details',
      minWidth: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        const path = createPath({
          path: ClientRoute.WORKSHOP_INBOUND_ACTIVITY,
          params: { reference: params.row.id },
        });
        return (
          <NavLink to={path}>
            <Button size='small' color='info'>
              <FormattedMessage id='common_details' defaultMessage='Details' />
            </Button>
          </NavLink>
        );
      },
    });
    additionalColumns.push({
      id: 'common_actions',
      headerName: 'Actions',
      field: 'actions',
      minWidth: 220,
      renderCell: (params: any) => {
        const { status, assignee, activity_report, activity_type } = params.row;
        const next = NEXT_STATUS_MAPPING?.[status];
        if (
          (assignee && assignee !== currentUser?.username) ||
          (APP_CONFIG.isProd && !WORKSHOP_EMPLOYEE_USERNAMES.includes(currentUser?.username))
        )
          return null;
        return (
          <Stack direction='row' spacing={1} alignItems='center'>
            {next?.map((nextStatus) => (
              <StatusBadge
                key={nextStatus}
                status={nextStatus}
                onClick={() => {
                  dispatch(
                    updateWorkshopInboundActivityAction({
                      reference: params.row?.id,
                      requestBody: {
                        status: nextStatus,
                        assignee: currentUser?.username,
                        updated_by: currentUser?.username,
                      },
                    }),
                  ).then((response) => {
                    setShowUpdateSnackbar(true);
                    if (response?.payload) {
                      fetchItem();
                    }
                  });
                }}
                loading={updateActivityRequest?.status === 'pending'}
                disabled={
                  !userCanEdit ||
                  maintenanceActivity?.status === WorkshopStatus.in_progress ||
                  (nextStatus === WorkshopStatus.complete &&
                    WORKSHOP_ACTIVITIES_WITH_REPORT.includes(activity_type.activity_type) &&
                    !activity_report)
                }
              />
            ))}
          </Stack>
        );
      },
    });

    setTableColumns([...additionalColumns, ...WORKSHOP_INBOUND_ACTIVITIES_TABLE_COLUMNS]);
  }, [isAdminUser, maintenanceActivity?.status]);

  return (
    <>
      {showUpdateSnackbar && <ActionStatusSnackbar actionName={updateWorkshopInboundActivityAction.typePrefix} />}
      {selectedActivity && (
        <WorkshopInboundActivityUpdateForm
          show={showUpdateDrawer}
          onClose={() => setShowUpdateDrawer(false)}
          activity={selectedActivity}
          onSuccess={fetchItem}
        />
      )}
      <SimpleTable
        columns={tableColumns}
        rows={activities || []}
        loading={getWorkshopInboundItemRequest?.status === 'pending'}
        density='compact'
        autoHeight
        sx={{ flex: 1 }}
        hideFooter
      />
    </>
  );
};

export default WorkshopInboundItemActivitiesTable;
