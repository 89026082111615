import { Download } from '@mui/icons-material';
import { Button } from '@mui/material';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import * as React from 'react';
import { useState } from 'react';
import { TrackingReportType } from '../../../../api/server/tracking';
import { GROUPS } from '../../../../constants/groups';
import { isAuthorizedSelector } from '../../../../selectors/user';
import { useAppSelector } from '../../../../state';
import DownloadTrackingReportModal from './DownloadTrackingReportModal';
import styles from './TrackingTableToolbar.module.scss';

type Props = {
  reportType: TrackingReportType;
};

const TrackingTableToolbar: React.FC<Props> = ({ reportType }) => {
  const userCanDownloadReport = useAppSelector((state) => isAuthorizedSelector(state, [GROUPS.CONTROLLING]));
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  return (
    <>
      {userCanDownloadReport && showDownloadModal && (
        <DownloadTrackingReportModal
          reportType={reportType}
          show={showDownloadModal}
          handleToggleModal={() => setShowDownloadModal(!showDownloadModal)}
        />
      )}
      <div className={styles.wrapper}>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
        </GridToolbarContainer>
        {userCanDownloadReport && (
          <Button
            endIcon={<Download />}
            onClick={() => {
              setShowDownloadModal(true);
            }}>
            CSV
          </Button>
        )}
      </div>
    </>
  );
};

export default TrackingTableToolbar;
