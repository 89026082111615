import { GridColDef } from '@mui/x-data-grid';
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import { AsyncThunk } from '@reduxjs/toolkit';
import { debounce } from 'lodash';
import { nanoid } from 'nanoid';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { requestSelector } from '../../../selectors/request';
import { userLangSelector } from '../../../selectors/user';
import { useAppDispatch, useAppSelector } from '../../../state';
import { translateColumnLabels } from '../../../utils/translationUtils';
import { getDataGridLocaleText, getDataGridStyles } from './dataGridHelper';

export type ServerSidePaginatedTableColDef = GridColDef & {
  id?: string;
};

export type SearchParams = {
  [key: string]: string | number | null | boolean;
};

type Props = Omit<DataGridProProps, 'rowCount' | 'pageSize' | 'columns'> & {
  fetchDataEffect: AsyncThunk<any, URLSearchParams, any>;
  columns: ServerSidePaginatedTableColDef[];
  searchParams: SearchParams;
  totalCount: number;
  triggerRefresh?: boolean;
  initPageSize?: number;
};

const ServerSidePaginatedTable: React.FC<Props> = ({
  fetchDataEffect,
  searchParams,
  totalCount,
  triggerRefresh,
  initPageSize = 25,
  columns,
  disableColumnMenu = true,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const ref = useRef(null);
  // request status of the fetchDataEffect, true means loading data
  const requestState = useAppSelector((state) => {
    return requestSelector(state, fetchDataEffect.typePrefix);
  });

  const [formattedColumns, setFormattedColumns] = useState<GridColDef[]>([]);
  const intl = useIntl();
  const userLang = useAppSelector(userLangSelector);

  // Component state variables
  const [pageModel, setPageModel] = useState({
    page: 0,
    pageSize: initPageSize,
  });

  const fetchData = useCallback(
    debounce((search: URLSearchParams) => {
      if (requestState?.status === 'pending') {
        return;
      }
      dispatch(fetchDataEffect(search));
    }, 300),
    [],
  );

  // Formats the header of the columns
  useEffect(() => {
    setFormattedColumns(translateColumnLabels(columns, intl));
  }, [columns, userLang]);

  const onPageModelChange = useCallback((newPageModel: any) => {
    setPageModel(newPageModel);
    const urlParams = new URLSearchParams(window.location.search);
    if (newPageModel.page !== 0) {
      urlParams.set('page', (newPageModel.page + 1).toString());
    } else {
      urlParams.delete('page');
    }
    if (newPageModel.pageSize !== initPageSize) {
      urlParams.set('pageSize', newPageModel.pageSize.toString());
    } else {
      urlParams.delete('pageSize');
    }
    history.push({ search: urlParams.toString() });
  }, []);

  // On change of page or search parameters, fetch data
  useEffect(() => {
    const params = new URLSearchParams();
    for (const [param, val] of Object.entries(searchParams)) {
      if (val) {
        params.set(param, val.toString());
      } else {
        params.delete(param);
      }
    }
    params.set('offset', (pageModel.page * pageModel.pageSize).toString());
    params.set('limit', pageModel.pageSize.toString());
    fetchData(params);
  }, [pageModel, searchParams, triggerRefresh]);

  useLayoutEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setPageModel({
      page: parseInt(urlParams.get('page') || '1', 10) - 1,
      pageSize: parseInt(urlParams.get('pageSize') || '', 10) || initPageSize,
    });
  }, []);

  return (
    <DataGridPro
      ref={ref}
      density='compact'
      hideFooterSelectedRowCount={true}
      getRowId={() => nanoid()}
      columns={formattedColumns}
      localeText={getDataGridLocaleText(intl)}
      pagination={true}
      paginationMode='server'
      disableColumnMenu={disableColumnMenu}
      loading={requestState?.status === 'pending'}
      rowCount={totalCount}
      paginationModel={{ ...pageModel }}
      onPaginationModelChange={onPageModelChange}
      sx={getDataGridStyles(!!props.onRowClick)}
      {...props}
    />
  );
};

export default ServerSidePaginatedTable;
