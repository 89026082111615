import { slugify } from '@chronext/react-common';
import { createReducer } from '@reduxjs/toolkit';
import { getAlgoliaBrandsAction, getAlgoliaModelsForBrandAction } from './actions';
import { AlgoliaState } from './types';

const initialState = {
  brands: [],
  brandsTotal: 0,
  models: [],
  modelsTotal: 0,
  dialColors: [],
} as AlgoliaState;

const algoliaReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getAlgoliaBrandsAction.fulfilled, (state, action) => {
      const brands = Object.keys(action?.payload?.facets?.brand || {});
      state.brands = brands.map((brand) => {
        const slug = slugify(brand);
        return { name: brand, slug: slug };
      });
      state.brandsTotal = brands?.length;
      state.dialColors = Object.keys(action?.payload?.facets?.dialColor_en || {});
    })
    .addCase(getAlgoliaModelsForBrandAction.fulfilled, (state, action) => {
      const models = Object.keys(action?.payload?.facets?.model || {});
      state.models = models.map((model) => {
        const slug = slugify(model);
        return { name: model, slug: slug };
      });
      state.modelsTotal = models?.length;
    });
});

export default algoliaReducer;
