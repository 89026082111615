import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { GridFilterModel, useGridApiRef } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { WORKSHOP_INBOUND_DASHBOARD_TABLE } from '../../../../constants/localStorage';
import { WorkshopReportType } from '../../../../constants/workshop';
import useSearchStoreHistory from '../../../../hooks/useSearchStoreHistory';
import { requestSelector } from '../../../../selectors/request';
import { workshopInboundReportSelector } from '../../../../selectors/workshop-inbound/workshop-inbound.selector';
import { useAppSelector } from '../../../../state';
import { getWorkshopInboundReportActivityAction } from '../../../../state/ducks/workshopInbound/actions';
import { WorkshopInboundActions } from '../../../../state/ducks/workshopInbound/types';
import { text2title } from '../../../../utils/textUtils';
import StatusBadge from '../../../components/badges/StatusBadge';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';
import ServerSidePaginatedTable, {
  ServerSidePaginatedTableColDef,
} from '../../../components/table/ServerSidePaginatedTable';
import {
  defaultSearchParamsDetails,
  WORKSHOP_INBOUND_REPORT_DETAIL_TABLE_COLUMNS,
} from './inboundDashboardTableHelper';
import WorkshopInboundDashboardToolbar from './WorkshopInboundDashboardToolbar';

const WorkshopInboundReportDetailsTable: React.FC = () => {
  const location = useLocation();
  const reportData = useAppSelector(workshopInboundReportSelector);
  const request = useAppSelector((state) => requestSelector(state, getWorkshopInboundReportActivityAction.typePrefix));
  const queryParams = new URLSearchParams(location.search);
  const assignee = queryParams.get('assignee');
  const activity = queryParams.get('activity');
  const status = queryParams.get('status');

  const [columns, setColumns] = useState<ServerSidePaginatedTableColDef[]>(
    WORKSHOP_INBOUND_REPORT_DETAIL_TABLE_COLUMNS,
  );

  const apiRef = useGridApiRef();
  const { searchParams, updateSearchFilters, initialFiltersParsed } = useSearchStoreHistory({
    storeName: WORKSHOP_INBOUND_DASHBOARD_TABLE,
    defaultSearchParams: defaultSearchParamsDetails,
    apiRef,
  });

  const buildFilters = (start: string, end: string): GridFilterModel => {
    return {
      items: [
        {
          id: 'report_type',
          field: 'report_type',
          operator: 'is',
          value: WorkshopReportType.assignee_activity_overview,
        },
        ...(assignee
          ? [
              {
                id: 'assignee',
                field: 'assignee',
                operator: 'is',
                value: assignee,
              },
            ]
          : []),
        ...(activity
          ? [
              {
                id: 'activity',
                field: 'activity',
                operator: 'is',
                value: activity,
              },
            ]
          : []),
        ...(status
          ? [
              {
                id: 'status',
                field: 'status',
                operator: 'is',
                value: status,
              },
            ]
          : []),
        {
          id: 'start_date',
          field: 'start_date',
          operator: '>=',
          value: start,
        },
        {
          id: 'end_date',
          field: 'end_date',
          operator: '<=',
          value: end,
        },
      ],
    };
  };

  const onFilterUpdate = (start: string, end: string) => {
    updateSearchFilters(buildFilters(start, end));
  };

  useEffect(() => {
    let newColumns = [...WORKSHOP_INBOUND_REPORT_DETAIL_TABLE_COLUMNS];
    if (assignee) {
      newColumns = newColumns.filter((col) => col.field !== 'assignee');
    }
    if (activity) {
      newColumns = newColumns.filter((col) => col.field !== 'activity');
    }
    if (status) {
      newColumns = newColumns.filter((col) => col.field !== 'status');
    }
    setColumns(newColumns);

    updateSearchFilters(
      buildFilters(searchParams?.start_date?.toString() || '', searchParams?.end_date?.toString() || ''),
    );
  }, []);

  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      <ActionStatusSnackbar actionName={WorkshopInboundActions.getWorkshopInboundReportOverview} />
      <Stack spacing={2} sx={{ height: '100%' }}>
        <Stack direction='row' spacing={2}>
          <Box pt={1.5}>
            <WorkshopInboundDashboardToolbar
              onDateFilterUpdate={onFilterUpdate}
              metaData={reportData.metaData}
              params={initialFiltersParsed}
              type={WorkshopReportType.assignee_activity_overview}
            />
          </Box>
          <Stack direction='row' spacing={1} sx={{ flex: 1, justifyContent: 'flex-end' }}>
            {searchParams.assignee && (
              <Card variant='outlined'>
                <CardContent>
                  <Stack spacing={1}>
                    <Typography>
                      <FormattedMessage id='workshopInbound_labels_assignee' defaultMessage='Assignee' />
                    </Typography>
                    <Typography variant='h5' component='div'>
                      {searchParams.assignee}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            )}
            {searchParams.activity && (
              <Card variant='outlined'>
                <CardContent>
                  <Stack spacing={1}>
                    <Typography>
                      <FormattedMessage id='workshop_labels_activity' defaultMessage='Activity' />
                    </Typography>
                    <Typography variant='h5' component='div'>
                      <FormattedMessage
                        id={`workshop_serviceActivityTypes_${searchParams.activity}`}
                        defaultMessage={text2title(searchParams.activity?.toString())}
                      />
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            )}
            {searchParams.status && (
              <Card variant='outlined'>
                <CardContent>
                  <Stack spacing={1}>
                    <Typography>
                      <FormattedMessage id='common_labels_status' defaultMessage='Status' />
                    </Typography>
                    <Typography variant='h5' component='div'>
                      <StatusBadge status={searchParams.status?.toString()} />
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            )}
          </Stack>
        </Stack>
        <ServerSidePaginatedTable
          columns={columns}
          rows={reportData.items}
          totalCount={reportData.metaData.total || reportData.items.length}
          fetchDataEffect={getWorkshopInboundReportActivityAction}
          searchParams={searchParams}
          loading={request?.status === 'pending'}
        />
      </Stack>
    </Box>
  );
};

export default WorkshopInboundReportDetailsTable;
