import { TabContext, TabPanel } from '@mui/lab';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import AlgoliaBrands from '../../components/algolia/AlgoliaBrands';
import WorkshopBrands from './WorkshopBrands';
import WorkshopPriceList from './WorkshopPriceList';
import WorkshopServices from './WorkshopServices';

const tabProps = {
  sx: {
    flex: 1,
    padding: 0,
  },
};

const WorkshopPricesPage: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState(0);

  return (
    <TabContext value={tabIndex.toString()}>
      <Stack sx={{ height: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 3 }}>
          <Tabs selectionFollowsFocus value={tabIndex} onChange={(_, newValue) => setTabIndex(newValue)}>
            <Tab label={<FormattedMessage id='workshopPrices_tabs_priceList' defaultMessage='Price List' />} />
            <Tab label={<FormattedMessage id='workshopPrices_tabs_services' defaultMessage='Services' />} />
            <Tab label={<FormattedMessage id='workshopPrices_tabs_brands' defaultMessage='Brands' />} />
            <Tab label={<FormattedMessage id='workshopPrices_tabs_algoliaBrands' defaultMessage='Algolia Brands' />} />
          </Tabs>
        </Box>
        <TabPanel value='0' {...tabProps}>
          <WorkshopPriceList />
        </TabPanel>
        <TabPanel value='1' {...tabProps}>
          <WorkshopServices />
        </TabPanel>
        <TabPanel value='2' {...tabProps}>
          <WorkshopBrands />
        </TabPanel>
        <TabPanel value='3' {...tabProps}>
          <AlgoliaBrands />
        </TabPanel>
      </Stack>
    </TabContext>
  );
};

export default WorkshopPricesPage;
