import { Link } from '@mui/material';
import React from 'react';
import { CARRIERS } from '../../../constants/tracking';
import { createPath, ExternalRoute } from '../../../routing';

type Props = {
  carrier: string;
  trackingNumber: string;
  showCarrierName?: boolean;
};

const CARRIER_ROUTES: {
  [param: string]:
    | ExternalRoute.FEDEX_TRACKING
    | ExternalRoute.UPS_TRACKING
    | ExternalRoute.FERRARI_TRACKING
    | ExternalRoute.OPC_TRACKING;
} = {
  fedex: ExternalRoute.FEDEX_TRACKING,
  ups: ExternalRoute.UPS_TRACKING,
  ferrari: ExternalRoute.FERRARI_TRACKING,
  opc: ExternalRoute.OPC_TRACKING,
};

export const getCarrierName = (carrier: string) => {
  return (carrier && CARRIERS?.[carrier]) || carrier;
};

const TrackingLink: React.FC<Props> = ({ carrier, trackingNumber, showCarrierName }) => {
  let trackingPath = '';
  if (trackingNumber && carrier in CARRIER_ROUTES) {
    trackingPath = createPath({
      path: CARRIER_ROUTES?.[carrier],
      params: { trackingNumber },
    });
  }
  const renderCarrierName = carrier && <>&nbsp;({getCarrierName(carrier)})</>;
  return trackingPath ? (
    <Link
      target='_blank'
      href={trackingPath}
      underline='none'
      onClick={(ev) => {
        ev.stopPropagation();
      }}>
      {trackingNumber}
      {showCarrierName && renderCarrierName}
    </Link>
  ) : (
    <>
      {trackingNumber}
      {showCarrierName && renderCarrierName}
    </>
  );
};

export default TrackingLink;
