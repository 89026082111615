import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createWorkshopInboundActivity,
  CreateWorkshopInboundActivityParams,
  createWorkshopInboundReport,
  CreateWorkshopInboundReportParams,
  createWorkshopInboundServices,
  getMaintenanceActivityStatus,
  getWorkshopInboundActivities,
  getWorkshopInboundActivity,
  getWorkshopInboundActivityReport,
  getWorkshopInboundReportActivity,
  getWorkshopInboundReportFields,
  getWorkshopInboundReportItemOverview,
  getWorkshopInboundRequests,
  getWorkshopInboundWatch,
  getWorkshopInboundWatches,
  sendWorkshopInboundServiceQuotation,
  updateExternalWorkshop,
  updateWorkshopInboundActivity,
  UpdateWorkshopInboundActivityStatusParams,
  UpdateWorkshopInboundExternalWorkshopParams,
  updateWorkshopInboundReport,
  UpdateWorkshopInboundReportParams,
  updateWorkshopInboundService,
  updateWorkshopInboundWatch,
  UpdateWorkshopInboundWatchParams,
  updateWorkshopInboundWatchSpareParts,
  UpdateWorkshopInboundWatchSparePartsParams,
} from '../../../api/server/workshopInbound';
import { WorkshopInboundActions, WorkshopInboundWatch } from './types';

export const getWorkshopInboundRequestsAction = createAsyncThunk(
  WorkshopInboundActions.getWorkshopInboundRequests,
  async (params: URLSearchParams) => {
    const response = await getWorkshopInboundRequests(params);
    return response.data;
  },
);

export const getWorkshopInboundWatchesAction = createAsyncThunk(
  WorkshopInboundActions.getWorkshopInboundWatches,
  async (params: URLSearchParams) => {
    const response = await getWorkshopInboundWatches(params);
    return response.data;
  },
);

export const getWorkshopInboundWatchAction = createAsyncThunk(
  WorkshopInboundActions.getWorkshopInboundWatch,
  async (reference: string) => {
    const response = await getWorkshopInboundWatch(reference);
    return response.data;
  },
);

export const updateWorkshopInboundWatchAction = createAsyncThunk(
  WorkshopInboundActions.updateWorkshopInboundWatch,
  async ({ reference, requestBody }: { reference: string; requestBody: UpdateWorkshopInboundWatchParams }) => {
    const response = await updateWorkshopInboundWatch(reference, requestBody);
    return response.data;
  },
);

export const updateWorkshopInboundWatchSparePartsAction = createAsyncThunk(
  WorkshopInboundActions.updateWorkshopInboundWatchSpareParts,
  async ({
    reference,
    requestBody,
  }: {
    reference: string;
    requestBody: UpdateWorkshopInboundWatchSparePartsParams;
  }) => {
    const response = await updateWorkshopInboundWatchSpareParts(reference, requestBody);
    return response.data;
  },
);

export const getMaintenanceActivityStatusAction = createAsyncThunk(
  WorkshopInboundActions.getMaintenanceActivityStatus,
  async (params: any) => {
    const response = await getMaintenanceActivityStatus(params);
    return response.data;
  },
);

export const getWorkshopInboundActivitiesAction = createAsyncThunk(
  WorkshopInboundActions.getWorkshopInboundActivities,
  async (params: URLSearchParams) => {
    const response = await getWorkshopInboundActivities(params);
    return response.data;
  },
);

export const getWorkshopInboundActivityAction = createAsyncThunk(
  WorkshopInboundActions.getWorkshopInboundActivity,
  async (reference: string) => {
    const response = await getWorkshopInboundActivity(reference);
    return response.data;
  },
);

export const createWorkshopInboundActivityAction = createAsyncThunk(
  WorkshopInboundActions.createWorkshopInboundActivity,
  async (params: CreateWorkshopInboundActivityParams) => {
    const response = await createWorkshopInboundActivity(params);
    return response.data;
  },
);

export const updateWorkshopInboundActivityAction = createAsyncThunk(
  WorkshopInboundActions.updateWorkshopInboundActivityStatus,
  async ({ reference, requestBody }: { reference: string; requestBody: UpdateWorkshopInboundActivityStatusParams }) => {
    const response = await updateWorkshopInboundActivity(reference, requestBody);
    return response.data;
  },
);

export const getWorkshopInboundReportFieldsAction = createAsyncThunk(
  WorkshopInboundActions.getWorkshopInboundReportFields,
  async () => {
    const response = await getWorkshopInboundReportFields();
    return response.data;
  },
);

export const getWorkshopInboundActivityReportAction = createAsyncThunk(
  WorkshopInboundActions.getWorkshopInboundActivityReport,
  async (reference: string) => {
    const response = await getWorkshopInboundActivityReport(reference);
    return response.data;
  },
);

export const createWorkshopInboundReportAction = createAsyncThunk(
  WorkshopInboundActions.createWorkshopInboundReport,
  async (requestBody: CreateWorkshopInboundReportParams) => {
    const response = await createWorkshopInboundReport(requestBody);
    return response.data;
  },
);

export const updateWorkshopInboundReportAction = createAsyncThunk(
  WorkshopInboundActions.updateWorkshopInboundReport,
  async (requestBody: UpdateWorkshopInboundReportParams) => {
    const response = await updateWorkshopInboundReport(requestBody);
    return response.data;
  },
);

type Service = {
  optional: boolean;
  price: string;
  comment?: string;
};

export type WorkshopInboundServices = {
  [key: string]: Service;
};

const mapServices = (services: WorkshopInboundServices) => {
  return Object.entries(services)
    .filter(([, value]) => value.price)
    .map(([key, value]) => {
      return {
        service_type: key,
        is_optional: value.optional,
        price: parseInt(value.price, 10),
        comment: value.comment,
      };
    });
};

export type CreateWorkshopInboundServicesRequestParams = {
  watch: WorkshopInboundWatch;
  services: WorkshopInboundServices;
};

export const createWorkshopInboundServicesAction = createAsyncThunk(
  WorkshopInboundActions.createWorkshopInboundServices,
  async (params: CreateWorkshopInboundServicesRequestParams) => {
    const services = mapServices(params.services);
    const response = await createWorkshopInboundServices(params.watch.id.toString(), services);
    return response.data;
  },
);

export type UpdateWorkshopInboundServicesRequestParams = { id: number; service: Service };

export const updateWorkshopInboundServiceAction = createAsyncThunk(
  WorkshopInboundActions.updateWorkshopInboundService,
  async (params: UpdateWorkshopInboundServicesRequestParams) => {
    const service = {
      is_optional: params.service.optional,
      price: parseInt(params.service.price, 10),
      comment: params.service.comment,
    };
    const response = await updateWorkshopInboundService(params.id.toString(), service);
    return response.data;
  },
);

export const sendWorkshopInboundServiceQuotationAction = createAsyncThunk(
  WorkshopInboundActions.sendWorkshopInboundServiceQuotation,
  async (reference: string) => {
    const response = await sendWorkshopInboundServiceQuotation(reference);
    return response.data;
  },
);

export const getWorkshopInboundReportActivityAction = createAsyncThunk(
  WorkshopInboundActions.getWorkshopInboundReportOverview,
  async (params: URLSearchParams) => {
    const response = await getWorkshopInboundReportActivity(params);
    return response.data;
  },
);

export const getWorkshopInboundReportItemOverviewAction = createAsyncThunk(
  WorkshopInboundActions.getWorkshopInboundReportItemOverview,
  async (params: URLSearchParams) => {
    const response = await getWorkshopInboundReportItemOverview(params);
    return response.data;
  },
);

export const getWorkshopInboundReportItemDetailsAction = createAsyncThunk(
  WorkshopInboundActions.getWorkshopInboundReportItemDetails,
  async (params: URLSearchParams) => {
    const response = await getWorkshopInboundReportItemOverview(params);
    return response.data;
  },
);

export const updateExternalWorkshopAction = createAsyncThunk(
  WorkshopInboundActions.updateExternalWorkshop,
  async (params: { reference: string; requestBody: UpdateWorkshopInboundExternalWorkshopParams }) => {
    const response = await updateExternalWorkshop(params.reference, params.requestBody);
    return response.data;
  },
);
