import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { FormattedMessage } from 'react-intl';
import { commercetoolsCategoriesSelector } from '../../../../selectors/commercetools';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { getCategoriesAction } from '../../../../state/ducks/commercetools/actions';
import { CommercetoolsDiscountCode } from '../../../../state/ducks/commercetools/types';

type Props = {
  discountCode: CommercetoolsDiscountCode | null;
  show: boolean;
  handleToggleModal: () => void;
};

const DiscountCodeDetailsModal: React.FC<Props> = ({ discountCode, handleToggleModal, show }) => {
  const dispatch = useAppDispatch();
  const { categories } = useAppSelector(commercetoolsCategoriesSelector);

  useEffect(() => {
    if (!categories.length) {
      const urlSearchParams = new URLSearchParams();
      dispatch(getCategoriesAction(urlSearchParams));
    }
  }, []);

  if (!discountCode) {
    return null;
  }

  return (
    <Dialog open={show} onClose={handleToggleModal} maxWidth='xs' fullWidth>
      <DialogTitle>
        <FormattedMessage
          id='productDiscounts_detailsModal_title'
          defaultMessage='Details for Discount Code "{code}"?'
          values={{ code: discountCode?.code }}
        />
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Box>
            <DialogContentText fontWeight='medium' mb={1} color='primary' borderBottom='1px solid'>
              <FormattedMessage id='productDiscounts_labels_maxApplications' defaultMessage='Max. Applications' />
            </DialogContentText>
            {discountCode?.maxApplications ? (
              <DialogContentText color='text'>{discountCode?.maxApplications}</DialogContentText>
            ) : (
              <DialogContentText color='success.main'>
                <FormattedMessage
                  id='productDiscounts_detailsModal_unlimitedApplications'
                  defaultMessage='This discount code has no application limit.'
                />
              </DialogContentText>
            )}
          </Box>
          <Box>
            <DialogContentText fontWeight='medium' mb={1} color='primary' borderBottom='1px solid'>
              <FormattedMessage id='productDiscounts_labels_groups' defaultMessage='Groups' />
            </DialogContentText>
            {discountCode?.groups?.length ? (
              <DialogContentText color='text'>{discountCode?.groups?.join(', ')}</DialogContentText>
            ) : (
              <DialogContentText color='warning.main'>
                <FormattedMessage
                  id='productDiscounts_detailsModal_noGroups'
                  defaultMessage='This discount code has no groups.'
                />
              </DialogContentText>
            )}
          </Box>
          <Box>
            <DialogContentText fontWeight='medium' mb={1} color='primary' borderBottom='1px solid'>
              <FormattedMessage id='productDiscounts_labels_categories' defaultMessage='Categories' />
            </DialogContentText>
            {discountCode?.categories?.length ? (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {discountCode?.categories.map((categoryKey) => (
                  <Chip key={categoryKey} label={categories.find((c) => c.id === categoryKey)?.name?.en} />
                ))}
              </Box>
            ) : (
              <DialogContentText color='warning.main'>
                <FormattedMessage
                  id='productDiscounts_detailsModal_noCategories'
                  defaultMessage='This discount code has no categories.'
                />
              </DialogContentText>
            )}
          </Box>
          <Box>
            <DialogContentText fontWeight='medium' mb={1} color='primary' borderBottom='1px solid'>
              <FormattedMessage id='productDiscounts_labels_countries' defaultMessage='Countries' />
            </DialogContentText>
            {discountCode?.countries?.length ? (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {discountCode?.countries?.map((country) => (
                  <Tooltip key={country} title={country} arrow>
                    <div>
                      <ReactCountryFlag countryCode={country} style={{ fontSize: '30px', lineHeight: '1em' }} />
                    </div>
                  </Tooltip>
                ))}
              </Box>
            ) : (
              <DialogContentText color='success.main'>
                <FormattedMessage
                  id='productDiscounts_detailsModal_allCountries'
                  defaultMessage='This discount code is valid for all countries.'
                />
              </DialogContentText>
            )}
          </Box>
          <Box>
            <DialogContentText fontWeight='medium' mb={1} color='primary' borderBottom='1px solid'>
              <FormattedMessage id='productDiscounts_labels_discountTiers' defaultMessage='Discount Tiers' />
            </DialogContentText>
            <TableContainer component={Paper}>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id='productDiscounts_labels_minCartValue' defaultMessage='Min cart value' />
                    </TableCell>
                    <TableCell>
                      {discountCode.isPercentValue ? (
                        <FormattedMessage
                          id='productDiscounts_labels_discountPercentage'
                          defaultMessage='Discount Percentage'
                        />
                      ) : (
                        <FormattedMessage id='productDiscounts_labels_discountValue' defaultMessage='Discount Value' />
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(discountCode?.discounts)?.map(([key, value]) => (
                    <TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>{key}</TableCell>
                      <TableCell>
                        {value}
                        {discountCode.isPercentValue && '%'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleToggleModal}>
          <FormattedMessage id='common_ok' defaultMessage='Ok' />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiscountCodeDetailsModal;
