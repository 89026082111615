import { Autocomplete, Button, Stack, TextField } from '@mui/material';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { requestSelector } from '../../../../selectors/request';
import { workshopItemSelector, workshopItemsSelector, workshopLocationsSelector } from '../../../../selectors/workshop';
import { useAppDispatch, useAppSelector } from '../../../../state';
import {
  addBookingItemAction,
  getWorkshopItemAction,
  getWorkshopLocationsAction,
} from '../../../../state/ducks/workshop/actions';
import CreateWorkshopItemModal from './CreateWorkshopItemModal';

const AddBookingForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const workshopLocations = useAppSelector(workshopLocationsSelector);
  const { items: workshopItems } = useAppSelector(workshopItemsSelector);
  const workshopItem = useAppSelector(workshopItemSelector);
  const addBookingRequest = useAppSelector((state) => {
    return requestSelector(state, addBookingItemAction.typePrefix);
  });

  const [locationReference, setLocationReference] = useState<number>();
  const [workshopItemReference, setWorkshopItemReference] = useState('');
  const [createItemModalOpen, setCreateItemModalOpen] = useState(false);
  const activeLocations = useMemo(() => workshopLocations.filter((l) => l.isActive), [workshopLocations]);

  const getWorkshopItems = useCallback(
    debounce((reference: string) => {
      dispatch(getWorkshopItemAction(reference));
    }, 200),
    [],
  );

  useEffect(() => {
    dispatch(getWorkshopLocationsAction());
  }, []);

  useEffect(() => {
    getWorkshopItems(workshopItemReference);
  }, [workshopItemReference]);

  useEffect(() => {
    if (addBookingRequest.status === 'fulfilled') {
      setWorkshopItemReference('');
    }
  }, [addBookingRequest]);

  const onAddBookingClick = () => {
    dispatch(addBookingItemAction({ locationReference: locationReference || 0, workshopItemReference })).then(
      (response) => {
        if (response?.payload) {
          dispatch(getWorkshopItemAction(workshopItemReference));
        }
      },
    );
  };

  const ActionButton = () => {
    let button = (
      <Button
        variant='contained'
        color='secondary'
        size='small'
        disabled={!locationReference || !workshopItemReference}
        fullWidth
        disableElevation
        onClick={onAddBookingClick}>
        <FormattedMessage id='workshop_booking_bookItem' defaultMessage='Book Item' />
      </Button>
    );
    if (!workshopItem) {
      button = (
        <Button
          variant='contained'
          color='secondary'
          size='small'
          disabled={!workshopItemReference}
          onClick={() => setCreateItemModalOpen(true)}
          fullWidth
          disableElevation>
          <FormattedMessage id='workshop_inventory_createItem' defaultMessage='Create New Workshop Item' />
        </Button>
      );
    }
    return button;
  };

  return (
    <>
      <CreateWorkshopItemModal
        isOpen={createItemModalOpen}
        workshopItemReference={workshopItemReference}
        onClose={() => {
          setCreateItemModalOpen(false);
          getWorkshopItems(workshopItemReference);
        }}
      />
      <Stack spacing={1} sx={{ width: 400, paddingY: '5px', marginBottom: '15px' }}>
        <TextField
          margin='dense'
          size='small'
          value={workshopItemReference}
          onChange={(event) => setWorkshopItemReference(event.target.value.toUpperCase())}
          label={intl.formatMessage({
            id: 'common_labels_serialNumber',
            defaultMessage: 'Serial Number',
          })}
          inputProps={{ style: { textTransform: 'uppercase' } }}
        />
        <Autocomplete
          options={activeLocations}
          getOptionLabel={(workshopLocation) => workshopLocation.name}
          getOptionDisabled={(workshopLocation) => workshopLocation.isActive === false}
          onChange={(_, location) => setLocationReference(location?.reference)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={intl.formatMessage({
                id: 'workshop_inventory_labels_location',
                defaultMessage: 'Location',
              })}
              size='small'
              margin='dense'
            />
          )}
        />
        <ActionButton />
      </Stack>
    </>
  );
};

export default AddBookingForm;
