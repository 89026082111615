export enum SellRequestCancellationReason {
  brandNotInteresting = 'brandNotInteresting',
  fakeWatch = 'fakeWatch',
  marketSituation = 'marketSituation',
  negotiationIssue = 'negotiationIssue',
  watchCondition = 'watchCondition',
  noVendorResponse = 'noVendorResponse',
  duplicatedRequest = 'duplicatedRequest',
}

export enum SellRequestStatus {
  cxt_pending = 'cxt_pending',
  waiting_for_customer = 'waiting_for_customer',
  cancelled = 'cancelled',
  finalized = 'finalized',
}

export enum SellRequestStatusInfo {
  new_request = 'new_request',
  missing_info = 'missing_info',
  awaiting_offer_creation = 'awaiting_offer_creation',
  awaiting_offer_approval = 'awaiting_offer_approval',
  offer_sent = 'offer_sent',
  counter_offer = 'counter_offer',
  offer_expired = 'offer_expired',
  resend_offer = 'resend_offer',
  requested_vendor_info = 'requested_vendor_info',
  no_vendor_response = 'no_vendor_response',
  cancelled_by_supply_team = 'cancelled_by_supply_team',
  cancelled_by_vendor = 'cancelled_by_vendor',
  awaiting_erp_po = 'awaiting_erp_po',
  failed_erp_po_creation = 'failed_erp_po_creation',
  po_waiting_for_label = 'po_waiting_for_label',
  inbound_pending = 'inbound_pending',
  closed = 'closed',
  workshop_approval_pending = 'workshop_approval_pending',
  workshop_approval_received = 'workshop_approval_received',
  workshop_received = 'workshop_received',
  workshop_in_progress = 'workshop_in_progress',
  inbound_done = 'inbound_done',
  payment_pending = 'payment_pending',
  completed = 'completed',
}

export enum SellRequestStatusInfoShowDetails {
  workshop_approval_pending = SellRequestStatusInfo.workshop_approval_pending,
  workshop_approval_received = SellRequestStatusInfo.workshop_approval_received,
  workshop_received = SellRequestStatusInfo.workshop_received,
  workshop_in_progress = SellRequestStatusInfo.workshop_in_progress,
  inbound_done = SellRequestStatusInfo.inbound_done,
}
