import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../state';
import { ActivityReportItem, BookingHistoryItem, EmployeeReportItem, Process } from '../../state/ducks/workshop/types';
import { formatSeconds, formatUTCToLocal } from '../../utils/datetime';

export const workshopStateSelector = (state: RootState) => state.workshop;

export const workshopActivitiesSelector = (state: RootState) => state.workshop.activities;

const sortAndFormatProcesses = (processes: Process[]) => {
  const sorted = [...processes].sort((processA, processB) => {
    if (processA.reference > processB.reference) {
      return -1;
    }
    if (processA.reference < processB.reference) {
      return 1;
    }
    return 0;
  });
  return sorted.map((process) => ({
    ...process,
    createdAt: process.createdAt ? formatUTCToLocal(process.createdAt) : '-',
    latestTimestamp: process.latestTimestamp ? formatUTCToLocal(process.latestTimestamp) : '-',
    elapsedTime: process.elapsedTime ? formatSeconds(process.elapsedTime) : '-',
  }));
};

const sortAndFormatBookingHistory = (bookingHistory: BookingHistoryItem[]) => {
  const sorted = [...bookingHistory].sort((itemA, itemB) => {
    if (itemA.bookingStart > itemB.bookingStart) {
      return -1;
    }
    if (itemA.bookingStart < itemB.bookingStart) {
      return 1;
    }
    return 0;
  });
  return sorted.map((bookingItem) => ({
    ...bookingItem,
    bookingStart: bookingItem.bookingStart ? formatUTCToLocal(bookingItem.bookingStart) : '-',
    bookingEnd: bookingItem.bookingEnd ? formatUTCToLocal(bookingItem.bookingEnd) : '-',
  }));
};

export const workshopItemsSelector = (state: RootState) => {
  const { count, items } = state.workshop.workshopItems;

  return {
    count,
    items: items.map((item) => ({ ...item, processes: item.processes ? sortAndFormatProcesses(item.processes) : [] })),
  };
};

export const workshopItemSelector = (state: RootState) => {
  const { workshopItem } = state.workshop;
  return (
    workshopItem && {
      ...workshopItem,
      processes: workshopItem.processes ? sortAndFormatProcesses(workshopItem.processes) : [],
      bookingHistory: workshopItem.bookingHistory ? sortAndFormatBookingHistory(workshopItem.bookingHistory) : [],
    }
  );
};

export const workshopProcessesSelector = (state: RootState) => {
  return {
    ...state.workshop.processes,
    items: state.workshop.processes.items.map((process) => ({
      ...process,
      elapsedTime: formatSeconds(process.elapsedTime),
      createdAt: formatUTCToLocal(process.createdAt),
      latestTimestamp: formatUTCToLocal(process.latestTimestamp),
    })),
  };
};

type ReportItem = ActivityReportItem | EmployeeReportItem;

const formattedReportItem = (reportItem: ReportItem) => {
  const { startTime, endTime, elapsedTime } = reportItem;

  return {
    ...reportItem,
    startTime: formatUTCToLocal(startTime),
    endTime: formatUTCToLocal(endTime),
    elapsedTime: formatSeconds(elapsedTime),
  };
};

export const activityReportSelector = (state: RootState) =>
  state.workshop.reports.activity.map((activityReportItem) => {
    return formattedReportItem(activityReportItem);
  });

export const employeeReportSelector = (state: RootState) =>
  state.workshop.reports.employee.map((employeeReportItem) => {
    return formattedReportItem(employeeReportItem);
  });

export const analyticsSelector = (state: RootState) => {
  const { activity } = state.workshop.reports;

  let totalScans = 0;
  let totalDuration = 0;
  const employees: string[] = [];

  activity.forEach((activityReportItem) => {
    totalScans += activityReportItem.scans;
    totalDuration += activityReportItem.elapsedTime;

    if (employees.includes(activityReportItem.employee)) {
      return;
    }
    employees.push(activityReportItem.employee);
  });

  return {
    totalScans,
    activeEmployees: employees.length,
    totalDuration: formatSeconds(totalDuration),
  };
};

export const workshopLocationsSelector = (state: RootState) => state.workshop.workshopLocations;
