import { Edit } from '@mui/icons-material';
import { ButtonGroup } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { workshopLocationsSelector } from '../../../selectors/workshop';
import { useAppDispatch, useAppSelector } from '../../../state';
import { getWorkshopLocationsAction } from '../../../state/ducks/workshop/actions';
import ButtonGroupIconButton from '../../components/buttons/ButtonGroupIconButton';
import BooleanIcon from '../../components/icons/BooleanIcon';
import SimpleTable, { SimpleTableColDef } from '../../components/table/SimpleTable';
import CreateUpdateWorkshopLocationModal from './components/CreateUpdateWorkshopLocationModal';
import WorkshopLocationsTableToolbar from './components/WorkshopLocationsTableToolbar';

const workshopLocationTableColumns: SimpleTableColDef[] = [
  {
    id: 'common_labels_id',
    headerName: 'ID',
    field: 'reference',
  },
  {
    id: 'workshop_locations_labels_locationName',
    headerName: 'Name',
    field: 'name',
    minWidth: 100,
    flex: 1,
  },
  {
    id: 'workshop_locations_labels_locationCode',
    headerName: 'Code',
    field: 'code',
    minWidth: 100,
    flex: 1,
  },
  {
    id: 'workshop_locations_labels_locationArea',
    headerName: 'Area',
    field: 'area',
    minWidth: 100,
    flex: 1,
  },
  {
    id: 'workshop_locations_labels_inInventory',
    headerName: 'In Inventory',
    field: 'inInventory',
    width: 100,
    sortable: false,
    filterable: false,
    align: 'center',
    renderCell: (params: GridRenderCellParams) => {
      const { inInventory } = params.row;
      return <BooleanIcon check={inInventory} />;
    },
  },
  {
    id: 'workshop_locations_labels_isActive',
    headerName: 'Is Active',
    field: 'isActive',
    width: 100,
    sortable: false,
    filterable: false,
    align: 'center',
    renderCell: (params: GridRenderCellParams) => {
      const { isActive } = params.row;
      return <BooleanIcon check={isActive} />;
    },
  },
  {
    id: 'common_labels_createdAt',
    headerName: 'Created At',
    field: 'createdAt',
    minWidth: 150,
    flex: 1,
  },
  {
    id: 'common_labels_updatedAt',
    headerName: 'Updated At',
    field: 'updatedAt',
    minWidth: 150,
    flex: 1,
  },
];

const WorkshopLocations: React.FC = () => {
  const dispatch = useAppDispatch();
  const locations = useAppSelector(workshopLocationsSelector);
  const intl = useIntl();
  const [tableColumns, setTableColumns] = useState<SimpleTableColDef[]>(workshopLocationTableColumns);
  const [selectedReference, setSelectedReference] = useState<number>();
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    dispatch(getWorkshopLocationsAction());
  }, []);

  useEffect(() => {
    const actionsColumn: SimpleTableColDef[] = [];
    actionsColumn.push({
      id: 'common_actions',
      headerName: 'Actions',
      field: 'actions',
      align: 'center',
      sortable: false,
      renderCell: (params: any) => (
        <ButtonGroup variant='text'>
          <ButtonGroupIconButton
            color='info'
            onClick={() => {
              setSelectedReference(params.row?.reference);
              setShowEditModal(true);
            }}
            title={intl.formatMessage({
              id: 'workshop_locations_update',
              defaultMessage: 'Update Location',
            })}>
            <Edit />
          </ButtonGroupIconButton>
        </ButtonGroup>
      ),
    });
    setTableColumns([...tableColumns, ...actionsColumn]);
  }, []);

  return (
    <>
      {showEditModal && selectedReference && (
        <CreateUpdateWorkshopLocationModal
          show={showEditModal}
          locationReference={selectedReference}
          handleToggleModal={() => setShowEditModal(!showEditModal)}
          onSuccess={() => dispatch(getWorkshopLocationsAction())}
        />
      )}
      <SimpleTable
        rows={locations}
        columns={tableColumns}
        slots={{
          toolbar: WorkshopLocationsTableToolbar,
        }}
      />
    </>
  );
};

export default WorkshopLocations;
