import { TabContext, TabPanel } from '@mui/lab';
import { Box, Stack } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';
import { useIntl } from 'react-intl';
import { NavLink, useParams } from 'react-router-dom';
import { ClientRoute, createPath } from '../../../routing';
import WorkshopInboundActivitiesTable from './WorkshopInboundActivitiesTable';

const tabProps = {
  sx: {
    flex: 1,
    padding: 0,
  },
};

type Params = {
  [key: string]: string;
};

const WorkshopInboundActivities: React.FC = () => {
  const intl = useIntl();
  const { tab } = useParams<Params>();

  return (
    <TabContext value={tab}>
      <Stack sx={{ height: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 3 }}>
          <Tabs selectionFollowsFocus value={tab}>
            <Tab
              value='inventory'
              component={NavLink}
              to={createPath({
                path: ClientRoute.WORKSHOP_INBOUND_ACTIVITIES_TABS,
                params: { tab: 'inventory' },
              })}
              label={intl.formatMessage({
                id: 'menu_inventory',
                defaultMessage: 'Inventory',
              })}
            />
            <Tab
              value='maintenance'
              component={NavLink}
              to={createPath({
                path: ClientRoute.WORKSHOP_INBOUND_ACTIVITIES_TABS,
                params: { tab: 'maintenance' },
              })}
              label={intl.formatMessage({
                id: 'workshop_serviceActivityTypes_maintenance',
                defaultMessage: 'Maintenance',
              })}
            />
          </Tabs>
        </Box>
        <TabPanel value='inventory' {...tabProps}>
          {tab === 'inventory' && <WorkshopInboundActivitiesTable activity={tab} />}
        </TabPanel>
        <TabPanel value='maintenance' {...tabProps}>
          {tab === 'maintenance' && <WorkshopInboundActivitiesTable activity='maintenance' />}
        </TabPanel>
      </Stack>
    </TabContext>
  );
};

export default WorkshopInboundActivities;
