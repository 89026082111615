import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { UpdateSellRequestParams } from '../../../../api/server/sell';
import { requestSelector } from '../../../../selectors/request';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { updateSellRequestAction } from '../../../../state/ducks/sell/actions';
import { SellRequest } from '../../../../state/ducks/sell/types';
import ConditionBadge from '../../../components/badges/ConditionBadge';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

enum Condition {
  NEW = 'new',
  LIKE_NEW = 'like new',
  GOOD = 'good',
  USED = 'used',
}

const conditionMenuItems = [
  { value: Condition.NEW },
  { value: Condition.LIKE_NEW },
  { value: Condition.GOOD },
  { value: Condition.USED },
];

type Props = {
  sellRequest: SellRequest;
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
};

const initialFormData: UpdateSellRequestParams = {
  watchBrand: '',
  watchModel: '',
  watchRef: '',
  constructionYear: '',
  paperAvailable: false,
  boxAvailable: false,
  watchCondition: '',
  sku: '',
};

const UpdateItemModal: React.FC<Props> = ({ show, handleToggleModal, sellRequest, onSuccess }) => {
  const dispatch = useAppDispatch();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const request = useAppSelector((state) => requestSelector(state, updateSellRequestAction.typePrefix));

  const saveItem = () => {
    dispatch(updateSellRequestAction({ reference: sellRequest.reference.toString(), requestBody: formData })).then(
      (response) => {
        if (response.type === updateSellRequestAction.fulfilled.type) {
          setShowSnackbar(true);
          handleToggleModal();
          onSuccess();
        }
      },
    );
  };

  const clearForm = () => {
    setFormData(initialFormData);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case 'paperAvailable':
      case 'boxAvailable':
        setFormData({ ...formData, [event.target.name]: event.target.checked });
        break;
      default:
        setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };

  useEffect(() => {
    if (sellRequest) {
      const { watchBrand, watchModel, watchRef, paperAvailable, boxAvailable, watchCondition, constructionYear, sku } =
        sellRequest;
      setFormData({
        watchBrand,
        watchModel,
        watchRef,
        paperAvailable,
        boxAvailable,
        watchCondition,
        constructionYear,
        sku,
      });
    } else {
      clearForm();
    }
  }, [show]);

  return (
    <>
      {showSnackbar && (
        <ActionStatusSnackbar
          actionName={updateSellRequestAction.typePrefix}
          config={{ messageValues: { reference: sellRequest.sellRequestId || sellRequest.reference } }}
        />
      )}
      <Dialog open={show} onClose={handleToggleModal}>
        <DialogTitle>
          <FormattedMessage
            id='sellRequests_editModal_title'
            defaultMessage='Edit Sell Request {reference}'
            values={{ reference: sellRequest.sellRequestId || sellRequest.reference }}
          />
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              margin='dense'
              id='watchBrand'
              name='watchBrand'
              value={formData.watchBrand}
              onChange={handleChange}
              label={<FormattedMessage id='common_labels_brand' defaultMessage='Brand' />}
              fullWidth
            />
            <TextField
              margin='dense'
              id='watchModel'
              name='watchModel'
              value={formData.watchModel}
              onChange={handleChange}
              label={<FormattedMessage id='common_labels_model' defaultMessage='Model' />}
              fullWidth
            />
            <TextField
              margin='dense'
              id='watchRef'
              name='watchRef'
              value={formData.watchRef}
              onChange={handleChange}
              label={<FormattedMessage id='common_labels_reference' defaultMessage='Reference' />}
              fullWidth
            />
            <TextField
              margin='dense'
              id='sku'
              name='sku'
              value={formData.sku}
              onChange={handleChange}
              label={<FormattedMessage id='common_labels_sku' defaultMessage='SKU' />}
              fullWidth
            />
            <TextField
              margin='dense'
              id='constructionYear'
              name='constructionYear'
              value={formData.constructionYear}
              onChange={handleChange}
              label={<FormattedMessage id='sellRequests_labels_constructionYear' defaultMessage='Year' />}
              fullWidth
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formData.paperAvailable}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  name='paperAvailable'
                />
              }
              label={<FormattedMessage id='common_labels_paperAvailable' defaultMessage='Paper' />}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formData.boxAvailable}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  name='boxAvailable'
                />
              }
              label={<FormattedMessage id='common_labels_boxAvailable' defaultMessage='Box' />}
            />
            <TextField
              type='number'
              margin='dense'
              name='watchCondition'
              value={formData.watchCondition}
              onChange={handleChange}
              label={<FormattedMessage id='common_labels_watchCondition' defaultMessage='Condition' />}
              select
              fullWidth>
              {conditionMenuItems.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <ConditionBadge condition={item.value} />
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleModal}>
            <FormattedMessage id='common_close' defaultMessage='Close' />
          </Button>
          <Button color='info' onClick={saveItem} disabled={request.status === 'pending'}>
            <FormattedMessage id='common_save' defaultMessage='Save' />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateItemModal;
