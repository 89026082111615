import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import CreateUpdateTrackingCommentModal from './CreateUpdateTrackingCommentModal';
import styles from './TrackingCommentsTableToolbar.module.scss';

type Props = {
  trackingId: string;
  onSuccess: () => void;
};

const TrackingCommentsTableToolbar: React.FC<Props> = ({ trackingId, onSuccess }) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  return (
    <div className={styles.wrapper}>
      <Button startIcon={<AddIcon />} color='success' variant='outlined' onClick={() => setShowCreateModal(true)}>
        <FormattedMessage id='tracking_comments_actions_create' defaultMessage='Create new Comment' />
      </Button>
      <CreateUpdateTrackingCommentModal
        trackingId={trackingId}
        onSuccess={onSuccess}
        show={showCreateModal}
        handleToggleModal={() => setShowCreateModal(!showCreateModal)}
      />
    </div>
  );
};

export default TrackingCommentsTableToolbar;
