import { Button, Stack } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  id: string;
  name: string;
  accept: string;
  multiple?: boolean;
  files?: FileList;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const displayFileList = (files: FileList) => {
  const result = [];
  for (let i = 0; i < files.length; i++) {
    result.push(<span key={i}>{files[i]?.name}</span>);
  }
  return result;
};

const FileUploadArea: React.FC<Props> = (props) => {
  return (
    <Stack direction='row' alignItems='flex-start' spacing={2} sx={{ marginTop: 2 }}>
      <label htmlFor={props.id}>
        <input
          accept={props.accept}
          id={props.id}
          multiple={props.multiple}
          type='file'
          hidden
          name={props.name}
          onChange={props.handleChange}
        />
        <Button variant='contained' component='span'>
          <FormattedMessage id='forms_fileUpload_label' defaultMessage='Upload Files' />
        </Button>
      </label>
      {props.files ? (
        <Stack spacing={1}>{displayFileList(props.files)}</Stack>
      ) : (
        <span>
          <FormattedMessage id='forms_fileUpload_noFiles' defaultMessage='No files selected' />
        </span>
      )}
    </Stack>
  );
};

export default FileUploadArea;
