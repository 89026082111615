import { RootState } from '../../state';

export const sellStateSelector = (state: RootState) => state.sell;

export const sellRequestsSelector = (state: RootState) => state.sell.sellRequests;

export const sellRequestSelector = (state: RootState) => state.sell.sellRequest;

export const offersSelector = (state: RootState) => state.sell.offers;

export const sellChronextAgentsSelector = (state: RootState) => state.sell.chronextAgents;
