import { LocalAtm, Percent, WatchLaterOutlined } from '@mui/icons-material';
import { Divider, Stack, Tooltip } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  methods: string[];
};

const commonStyles = { fontSize: '1rem' };

const SellingMethods: React.FC<Props> = ({ methods }) => {
  if (!Array.isArray(methods) || methods.length === 0) {
    return null;
  }
  const getColor = (method: string) => (methods.includes(method) ? 'primary' : 'disabled');

  return (
    <Stack direction='row' alignItems='center' spacing={1} divider={<Divider orientation='vertical' flexItem />}>
      <Tooltip title={<FormattedMessage id='sellRequests_sellingMethods_direct' defaultMessage='Direct' />} arrow>
        <LocalAtm sx={{ ...commonStyles }} color={getColor('direct')} />
      </Tooltip>
      <Tooltip
        title={<FormattedMessage id='sellRequests_sellingMethods_commission' defaultMessage='Commission' />}
        arrow>
        <Percent sx={{ ...commonStyles }} color={getColor('commission')} />
      </Tooltip>
      <Tooltip title={<FormattedMessage id='sellRequests_sellingMethods_exchange' defaultMessage='Exchange' />} arrow>
        <WatchLaterOutlined sx={{ ...commonStyles }} color={getColor('exchange')} />
      </Tooltip>
    </Stack>
  );
};

export default SellingMethods;
