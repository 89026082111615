import {
  Bolt,
  Cancel,
  CheckCircle,
  Description,
  Inventory,
  LocalShipping,
  PriorityHigh,
  Schedule,
} from '@mui/icons-material';
import { Chip } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AftershipStatus } from '../../../constants/tracking';
import { WorkshopSparePartsStatus } from '../../../constants/workshop';
import styles from './Badges.module.scss';
import { LoadingButton } from '@mui/lab';

type Props = {
  status: string | undefined;
  onClick?: () => void;
  showIcon?: boolean;
  size?: 'small' | 'medium';
  loading?: boolean;
  disabled?: boolean;
};

const STATUS_ICONS: Record<AftershipStatus | WorkshopSparePartsStatus | string, React.ReactElement> = {
  InfoReceived: <Description />,
  InTransit: <LocalShipping />,
  OutForDelivery: <Inventory />,
  AttemptFail: <Bolt />,
  Delivered: <CheckCircle />,
  AvailableForPickup: <Inventory />,
  Exception: <PriorityHigh />,
  Expired: <Cancel />,
  Pending: <Schedule />,
  requested: <Schedule />,
  ordered: <LocalShipping />,
  received: <CheckCircle />,
};

const STATUS_MAPPING: {
  [param: string]: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
} = {
  pending: 'info',
  sent: 'warning',
  complete: 'success',
  cancelled: 'error',
  in_progress: 'warning',
  waiting_for_customer: 'warning',
  cxt_pending: 'info',
  finalized: 'success',
  paused: 'info',
  approved: 'success',
  declined: 'error',
  failed: 'error',
  active: 'success',
  expired: 'error',
  // afterShip status codes
  InfoReceived: 'info',
  InTransit: 'warning',
  OutForDelivery: 'warning',
  AttemptFail: 'error',
  Delivered: 'success',
  AvailableForPickup: 'primary',
  Exception: 'error',
  // workshop inbound spare parts
  requested: 'info',
  ordered: 'warning',
  received: 'success',
};

const StatusBadge: React.FC<Props> = ({ status, onClick, showIcon, size, loading, disabled }) => {
  const intl = useIntl();

  if (!status) return null;

  const messageId = `status_${status.replaceAll(' ', '_')}`;
  const label = intl.messages[messageId] ? <FormattedMessage id={messageId} defaultMessage={status} /> : status;

  return onClick ? (
    <LoadingButton
      startIcon={showIcon ? STATUS_ICONS[status] : undefined}
      size={size ?? (showIcon ? 'medium' : 'small')}
      color={STATUS_MAPPING?.[status] || 'inherit'}
      variant='outlined'
      sx={{ fontSize: '0.7rem' }}
      onClick={(ev) => {
        ev.stopPropagation();
        onClick();
      }}
      loadingPosition='center'
      loading={loading}
      disabled={disabled || loading}>
      {label}
    </LoadingButton>
  ) : (
    <Chip
      icon={showIcon ? STATUS_ICONS[status] : undefined}
      size={size ?? (showIcon ? 'medium' : 'small')}
      color={STATUS_MAPPING?.[status] || 'default'}
      variant={status === 'pending' ? 'outlined' : 'filled'}
      className={styles.chip}
      label={label}
    />
  );
};

export default StatusBadge;
