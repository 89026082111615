import { CommercetoolsActions } from '../state/ducks/commercetools/types';
import { PricingActions } from '../state/ducks/pricing/types';
import { SellActions } from '../state/ducks/sell/types';
import { TrackingActions } from '../state/ducks/tracking/types';
import { WorkshopActions } from '../state/ducks/workshop/types';
import { WorkshopInboundActions } from '../state/ducks/workshopInbound/types';
import { WorkshopPricesActions } from '../state/ducks/workshopService/types';

type Messages = {
  [key: string]: {
    id: string;
    defaultMessage: string;
  };
};

export const SUCCESS_MESSAGES: Messages = {
  [CommercetoolsActions.createCategory]: {
    id: 'successMessages_commercetools_createCategory',
    defaultMessage: 'Successfully created category {categoryName} with key {categoryKey}',
  },
  [CommercetoolsActions.clearCategory]: {
    id: 'successMessages_commercetools_clearCategory',
    defaultMessage:
      'Successfully cleared category {categoryKey}, removed {count, plural, one {# product} other {# products}}',
  },
  [CommercetoolsActions.deleteCategory]: {
    id: 'successMessages_commercetools_deleteCategory',
    defaultMessage: 'Successfully deleted category {categoryKey}',
  },
  [CommercetoolsActions.createDiscountCode]: {
    id: 'successMessages_commercetools_createDiscountCode',
    defaultMessage: 'Successfully created discount code {code}',
  },
  [CommercetoolsActions.createBatchJob]: {
    id: 'successMessages_commercetools_createBatchJob',
    defaultMessage: 'Successfully created batch job for code pattern {code}',
  },
  [CommercetoolsActions.deleteDiscountCode]: {
    id: 'successMessages_commercetools_deleteDiscountCode',
    defaultMessage: 'Successfully deleted discount code {code}',
  },
  [WorkshopActions.createProcess]: {
    id: 'successMessages_workshop_createWorkshopProcess',
    defaultMessage: 'Successfully created process {reference}',
  },
  [WorkshopActions.updateProcess]: {
    id: 'successMessages_workshop_updateWorkshopProcess',
    defaultMessage: 'Successfully updated process {reference}',
  },
  [WorkshopActions.createLocation]: {
    id: 'successMessages_workshop_createLocation',
    defaultMessage: 'Successfully created location {reference}',
  },
  [WorkshopActions.updateWorkshopItem]: {
    id: 'successMessages_workshop_updateWorkshopItem',
    defaultMessage: 'Successfully updated location {reference}',
  },
  [WorkshopPricesActions.createWorkshopService]: {
    id: 'successMessages_workshopPrices_createWorkshopService',
    defaultMessage: 'Successfully created service {name} with key {key}',
  },
  [WorkshopPricesActions.updateWorkshopService]: {
    id: 'successMessages_workshopPrices_updateWorkshopService',
    defaultMessage: 'Successfully updated service {service}',
  },
  [WorkshopPricesActions.deleteWorkshopService]: {
    id: 'successMessages_workshopPrices_deleteWorkshopService',
    defaultMessage: 'Successfully deleted service {service}',
  },
  [WorkshopPricesActions.createWorkshopPrice]: {
    id: 'successMessages_workshopPrices_createWorkshopPrice',
    defaultMessage: 'Successfully created entry {entry}',
  },
  [WorkshopPricesActions.updateWorkshopPrice]: {
    id: 'successMessages_workshopPrices_updateWorkshopPrice',
    defaultMessage: 'Successfully updated entry {entry}',
  },
  [WorkshopPricesActions.deleteWorkshopPrice]: {
    id: 'successMessages_workshopPrices_deleteWorkshopPrice',
    defaultMessage: 'Successfully deleted entry {entry}',
  },
  [TrackingActions.createTrackingComment]: {
    id: 'successMessages_tracking_createTrackingComment',
    defaultMessage: 'Successfully created comment',
  },
  [TrackingActions.updateTrackingComment]: {
    id: 'successMessages_tracking_updateTrackingComment',
    defaultMessage: 'Successfully updated comment',
  },
  [SellActions.updateSellRequest]: {
    id: 'successMessages_sell_updateSellRequest',
    defaultMessage: 'Successfully updated sell request {reference}',
  },
  [SellActions.requestMissingData]: {
    id: 'successMessages_sell_requestMissingData',
    defaultMessage: 'Successfully requested missing data for sell request {reference}',
  },
  [SellActions.cancelSellRequest]: {
    id: 'successMessages_sell_cancelSellRequest',
    defaultMessage: 'Successfully cancelled sell request {reference}',
  },
  [SellActions.updateSellRequestStatus]: {
    id: 'successMessages_sell_updateSellRequest',
    defaultMessage: 'Successfully updated sell request {reference}',
  },
  [SellActions.updateSellRequestOffer]: {
    id: 'successMessages_sell_updateSellRequestOffer',
    defaultMessage: 'Successfully updated offer for sell request {reference}',
  },
  [SellActions.updateSellRequestOfferStatus]: {
    id: 'successMessages_sell_updateSellRequestOffer',
    defaultMessage: 'Successfully updated offer for sell request {reference}',
  },
  [SellActions.updateSellRequestAgentAction]: {
    id: 'successMessages_sell_updateSellRequestAgent',
    defaultMessage: 'Successfully updated agent for sell request {reference}',
  },
  [SellActions.updateSellRequestOfferExpirationDate]: {
    id: 'successMessages_sell_updateSellRequestOffer',
    defaultMessage: 'Successfully updated offer for sell request {reference}',
  },
  [SellActions.requestVendorInformation]: {
    id: 'successMessages_sell_requestVendorInformation',
    defaultMessage: 'Successfully requested vendor information for sell request {reference}',
  },
  [SellActions.updateVendorInformation]: {
    id: 'successMessages_sell_updateVendorInformation',
    defaultMessage: 'Successfully updated vendor information for sell request {reference}',
  },
  [WorkshopInboundActions.updateWorkshopInboundActivityStatus]: {
    id: 'successMessages_workshopInbound_updateWorkshopInboundActivityStatus',
    defaultMessage: 'Successfully updated activity status',
  },
  [WorkshopInboundActions.createWorkshopInboundReport]: {
    id: 'successMessages_workshopInbound_createWorkshopInboundReport',
    defaultMessage: 'Successfully created report',
  },
  [WorkshopInboundActions.updateWorkshopInboundReport]: {
    id: 'successMessages_workshopInbound_updateWorkshopInboundReport',
    defaultMessage: 'Successfully updated report',
  },
  [WorkshopInboundActions.createWorkshopInboundServices]: {
    id: 'successMessages_workshopInbound_createWorkshopInboundServices',
    defaultMessage: 'Successfully created services',
  },
  [WorkshopInboundActions.sendWorkshopInboundServiceQuotation]: {
    id: 'successMessages_workshopInbound_sendWorkshopInboundServiceQuotation',
    defaultMessage: 'Successfully sent quotation',
  },
  [WorkshopInboundActions.createWorkshopInboundActivity]: {
    id: 'successMessages_workshopInbound_createWorkshopInboundActivity',
    defaultMessage: 'Successfully created activity {reference}',
  },
  [WorkshopInboundActions.updateWorkshopInboundWatch]: {
    id: 'successMessages_workshopInbound_updateWorkshopInboundWatchPriority',
    defaultMessage: 'Successfully updated priority',
  },
  [WorkshopInboundActions.updateWorkshopInboundWatchSpareParts]: {
    id: 'successMessages_workshopInbound_updateWorkshopInboundWatchSpareParts',
    defaultMessage: 'Successfully updated spare parts information',
  },
  [WorkshopInboundActions.updateExternalWorkshop]: {
    id: 'successMessages_workshopInbound_updateExternalWorkshop',
    defaultMessage: 'Successfully updated external workshop information',
  },
  [PricingActions.deletePricingConfigEntry]: {
    id: 'successMessages_pricing_deletePricingConfigEntry',
    defaultMessage: 'Successfully deleted pricing configuration entry {reference}',
  },
  [PricingActions.createPricingConfigEntry]: {
    id: 'successMessages_pricing_createPricingConfigEntry',
    defaultMessage: 'Successfully created pricing configuration entry {reference}',
  },
  [PricingActions.updatePricingConfigEntry]: {
    id: 'successMessages_pricing_updatePricingConfigEntry',
    defaultMessage: 'Successfully updated pricing configuration entry {reference}',
  },
  default: {
    id: 'successMessages_default',
    defaultMessage: 'Successfully executed action',
  },
};

export const COMMERCETOOLS_UPDATE_CATEGORY_MESSAGES: Messages = {
  add: {
    id: 'successMessages_commercetools_updateCategory_add',
    defaultMessage:
      'Successfully updated category {categoryKey}, added {skusAddedCount, plural, one {# product} other {# products}}',
  },
  remove: {
    id: 'successMessages_commercetools_updateCategory_remove',
    defaultMessage:
      'Successfully updated category {categoryKey}, removed {skusRemovedCount, plural, one {# product} other {# products}}',
  },
  set: {
    id: 'successMessages_commercetools_updateCategory_set',
    defaultMessage:
      'Successfully updated category {categoryKey}, added {skusAddedCount, plural, one {# product} other {# products}} and removed {skusRemovedCount, plural, one {# product} other {# products}}',
  },
  default: {
    id: 'successMessages_commercetools_updateCategory',
    defaultMessage: 'Successfully updated category {categoryKey}',
  },
};
