import { requestSelector } from '../../../selectors/request';
import { workshopItemSelector } from '../../../selectors/workshop';
import { useAppSelector } from '../../../state';
import { getWorkshopItemAction } from '../../../state/ducks/workshop/actions';
import BooleanIcon from '../icons/BooleanIcon';
import SimpleTable, { SimpleTableColDef } from '../table/SimpleTable';

const bookingHistoryTableColumns: SimpleTableColDef[] = [
  {
    id: 'common_labels_createdBy',
    headerName: 'Created By',
    field: 'createdBy',
    minWidth: 200,
    flex: 1,
  },
  {
    id: 'common_labels_startTime',
    headerName: 'Start Time',
    field: 'bookingStart',
    minWidth: 150,
    flex: 1,
    sortable: false,
  },
  {
    id: 'workshop_bookingHistory_labels_endTime',
    headerName: 'End Time',
    field: 'bookingEnd',
    minWidth: 150,
    flex: 1,
    sortable: false,
  },
  {
    id: 'common_labels_serialNumber',
    headerName: 'Inventory ID',
    field: 'serialNumber',
    minWidth: 100,
    flex: 1,
    sortable: false,
  },
  {
    id: 'workshop_bookingHistory_labels_locationName',
    headerName: 'Location Name',
    field: 'locationName',
    minWidth: 150,
    flex: 1,
    valueGetter: (params) => params.row.location.name,
  },
  {
    id: 'workshop_bookingHistory_labels_locationArea',
    headerName: 'Location Area',
    field: 'locationArea',
    minWidth: 150,
    flex: 1,
    valueGetter: (params) => params.row.location.area,
  },
  {
    id: 'workshop_bookingHistory_labels_inInventory',
    headerName: 'In Inventory',
    field: 'inInventory',
    minWidth: 100,
    align: 'center',
    renderCell: (params) => <BooleanIcon check={params.row.location.inInventory} />,
  },
];

const WorkshopItemBookingHistoryTable = () => {
  const workshopItem = useAppSelector(workshopItemSelector);
  const getWorkshopItemRequest = useAppSelector((state) => requestSelector(state, getWorkshopItemAction.typePrefix));

  return (
    <SimpleTable
      columns={bookingHistoryTableColumns}
      rows={
        (workshopItem?.bookingHistory &&
          workshopItem.bookingHistory.map((bookingHistoryItem) => ({
            ...bookingHistoryItem,
            serialNumber: workshopItem.serialNumber,
          }))) ||
        []
      }
      loading={getWorkshopItemRequest?.status === 'pending'}
    />
  );
};

export default WorkshopItemBookingHistoryTable;
