import { APP_CONFIG } from '../config/app';
import api, { RequestConfig } from '../clients/api';
import { APIRoute } from '../routing';

const getRequestConfig = (url: string, requestConfig?: RequestConfig) => {
  const defaultConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: 30000,
    withCredentials: true,
  } as RequestConfig;

  // Add baseURl for production/staging
  if (APP_CONFIG.backendAPI) {
    defaultConfig.baseURL = APP_CONFIG.backendAPI;
  }

  if (!requestConfig) {
    return defaultConfig;
  }
  return {
    ...defaultConfig,
    ...requestConfig,
  };
};

const getPath = (url: string): string => {
  const workshopInboundPathPrefix = '/ext/workshopInboundService';
  if (url.startsWith(workshopInboundPathPrefix) && !APP_CONFIG.backendAPI) {
    return 'http://localhost:8000' + url.replace(workshopInboundPathPrefix, '');
  }
  return url;
};

export const get = async (url: string, params?: URLSearchParams | null, requestConfig?: RequestConfig) => {
  return api.get(getPath(url), { ...getRequestConfig(url, requestConfig), params });
};

export const post = async (url: string, data?: any, requestConfig?: RequestConfig) => {
  let postData = {};
  if (data) {
    if (
      requestConfig &&
      requestConfig.headers &&
      requestConfig.headers['Content-Type'] &&
      requestConfig.headers['Content-Type'] !== 'application/json'
    ) {
      postData = data;
    } else {
      postData = JSON.stringify(data);
    }
  }
  return api.post(getPath(url), postData, getRequestConfig(url, requestConfig));
};

export const put = async (url: string, data: any, requestConfig?: RequestConfig) => {
  return api.put(getPath(url), JSON.stringify(data), getRequestConfig(url, requestConfig));
};

export const patch = async (url: string, data: any, requestConfig?: RequestConfig) => {
  return api.patch(getPath(url), JSON.stringify(data), getRequestConfig(url, requestConfig));
};

export const del = async (url: string, data?: any, requestConfig?: RequestConfig) => {
  let deleteData = {};
  if (data) {
    if (
      requestConfig &&
      requestConfig.headers &&
      requestConfig.headers['Content-Type'] &&
      requestConfig.headers['Content-Type'] !== 'application/json'
    ) {
      deleteData = data;
    } else {
      deleteData = JSON.stringify(data);
    }
  }
  return api.delete(getPath(url), { ...getRequestConfig(url, requestConfig), data: deleteData });
};
