import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { APP_CONFIG } from '../../../config/app';
import { GROUPS } from '../../../constants/groups';
import { ClientRouteGroups } from '../../../routing/clientRoutes';
import { requestSelector } from '../../../selectors/request';
import { currentUserSelector, isAuthorizedSelector } from '../../../selectors/user';
import { workshopItemSelector } from '../../../selectors/workshop';
import { useAppDispatch, useAppSelector } from '../../../state';
import { getWorkshopItemAction, updateWorkshopProcessAction } from '../../../state/ducks/workshop/actions';
import StatusBadge from '../badges/StatusBadge';
import ActionStatusSnackbar from '../snackbars/ActionStatusSnackbar';
import SimpleTable, { SimpleTableColDef } from '../table/SimpleTable';
import { WORKSHOP_PROCESS_TABLE_COLUMNS } from './WorkshopProcessesTable';
import WorkshopProcessUpdateForm from './WorkshopProcessUpdateForm';
import { NEXT_STATUS_MAPPING } from '../../../constants/workshop';

type Params = {
  [key: string]: string;
};

const WorkshopItemProcessesTable = () => {
  const { reference } = useParams<Params>();
  const dispatch = useAppDispatch();
  const workshopItem = useAppSelector(workshopItemSelector);
  const getWorkshopItemRequest = useAppSelector((state) => requestSelector(state, getWorkshopItemAction.typePrefix));
  const currentUser = useAppSelector(currentUserSelector);
  const isAdminUser = useAppSelector((state) => isAuthorizedSelector(state, [GROUPS.ADMIN, GROUPS.WORKSHOP_ADMIN]));
  const userCanEdit =
    useAppSelector((state) =>
      isAuthorizedSelector(
        state,
        ClientRouteGroups.WORKSHOP_INBOUND_ITEM.filter((g) => g.startsWith('Workshop')),
      ),
    ) || !APP_CONFIG.isProd;
  const [tableColumns, setTableColumns] = useState<SimpleTableColDef[]>(WORKSHOP_PROCESS_TABLE_COLUMNS);
  const [updateProcessDrawerState, setUpdateProcessDrawerState] = useState(false);
  const [showUpdateSnackbar, setShowUpdateSnackbar] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState({
    workshopItemReference: '',
    processReference: '',
    status: '',
  });

  const fetchWorkshopItem = () => {
    dispatch(getWorkshopItemAction(reference));
  };

  useEffect(() => {
    const additionalColumns: SimpleTableColDef[] = [];
    if (isAdminUser) {
      additionalColumns.push({
        headerName: '',
        field: 'adminAction',
        width: 50,
        renderCell: (params: any) => {
          return (
            <IconButton
              aria-label='edit'
              size='small'
              disabled={params?.row?.isCompleted}
              onClick={() => {
                setSelectedProcess(params.row);
                setUpdateProcessDrawerState(true);
              }}>
              <EditIcon fontSize='small' />
            </IconButton>
          );
        },
      });
    }
    if (userCanEdit) {
      additionalColumns.push({
        id: 'common_actions',
        headerName: 'Actions',
        field: 'actions',
        minWidth: 220,
        flex: 1,
        renderCell: (params: any) => {
          const { status, createdBy } = params.row;
          const next = NEXT_STATUS_MAPPING?.[status];
          if (createdBy === currentUser?.username) {
            return (
              <Stack direction='row' spacing={1} alignItems='center'>
                {next?.map((nextStatus) => (
                  <StatusBadge
                    key={nextStatus}
                    status={nextStatus}
                    onClick={() => {
                      const { reference, workshopItemReference } = params.row;
                      dispatch(
                        updateWorkshopProcessAction({
                          reference,
                          workshopItemReference,
                          requestBody: {
                            status: nextStatus,
                          },
                        }),
                      ).then((response) => {
                        setShowUpdateSnackbar(true);
                        if (response?.payload) {
                          fetchWorkshopItem();
                        }
                      });
                    }}
                  />
                ))}
              </Stack>
            );
          }
        },
      });
    }

    setTableColumns([...additionalColumns, ...WORKSHOP_PROCESS_TABLE_COLUMNS]);
  }, [isAdminUser, userCanEdit]);

  return (
    <>
      {showUpdateSnackbar && <ActionStatusSnackbar actionName={updateWorkshopProcessAction.typePrefix} />}
      <WorkshopProcessUpdateForm
        process={selectedProcess}
        isOpen={updateProcessDrawerState}
        onClose={() => setUpdateProcessDrawerState(false)}
        onSuccess={fetchWorkshopItem}
      />
      <SimpleTable
        columns={tableColumns}
        rows={workshopItem?.processes || []}
        loading={getWorkshopItemRequest?.status === 'pending'}
      />
    </>
  );
};

export default WorkshopItemProcessesTable;
