import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../state';

export const inventoryStateSelector = (state: RootState) => state.inventory;

export const inventoryProductsSelector = (state: RootState) => state.inventory.products;

export const inventoryProductSelector = (state: RootState) => state.inventory.product;

export const inventoryVariantsSelector = (state: RootState) => state.inventory.variants;

export const inventoryItemSelector = (state: RootState) => state.inventory.inventoryItem;
