import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { GridFilterModel, useGridApiRef } from '@mui/x-data-grid-pro';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { WORKSHOP_INBOUND_DASHBOARD_OVERVIEW } from '../../../../constants/localStorage';
import { WorkshopReportType } from '../../../../constants/workshop';
import useSearchStoreHistory from '../../../../hooks/useSearchStoreHistory';
import { requestSelector } from '../../../../selectors/request';
import { workshopInboundReportItemDetailsSelector } from '../../../../selectors/workshop-inbound/workshop-inbound.selector';
import { useAppSelector } from '../../../../state';
import { getWorkshopInboundReportItemDetailsAction } from '../../../../state/ducks/workshopInbound/actions';
import { WorkshopInboundActions } from '../../../../state/ducks/workshopInbound/types';
import { text2title } from '../../../../utils/textUtils';
import StatusBadge from '../../../components/badges/StatusBadge';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';
import ServerSidePaginatedTable from '../../../components/table/ServerSidePaginatedTable';
import { WORKSHOP_INBOUND_OVERVIEW_TABLE_ITEM_DETAILS } from './inboundDashboardTableHelper';
import WorkshopInboundDashboardToolbar from './WorkshopInboundDashboardToolbar';

const WorkshopInboundReportItemDetailsTable: React.FC = () => {
  const location = useLocation();
  const reportData = useAppSelector(workshopInboundReportItemDetailsSelector);
  const request = useAppSelector((state) =>
    requestSelector(state, getWorkshopInboundReportItemDetailsAction.typePrefix),
  );
  const queryParams = new URLSearchParams(location.search);
  const completed = queryParams.get('completed');

  const apiRef = useGridApiRef();
  const { searchParams, updateSearchFilters, initialFiltersParsed } = useSearchStoreHistory({
    storeName: WORKSHOP_INBOUND_DASHBOARD_OVERVIEW,
    defaultSearchParams: {
      report_type: WorkshopReportType.watch_overview_details,
      completed: completed || '',
    },
    apiRef,
  });

  const buildFilters = (start: string, end: string): GridFilterModel => {
    return {
      items: [
        {
          id: 'report_type',
          field: 'report_type',
          operator: 'is',
          value: WorkshopReportType.watch_overview_details,
        },
        ...(completed
          ? [
              {
                id: 'completed',
                field: 'completed',
                operator: 'is',
                value: completed,
              },
            ]
          : []),
        {
          id: 'start_date',
          field: 'start_date',
          operator: '>=',
          value: start,
        },
        {
          id: 'end_date',
          field: 'end_date',
          operator: '<=',
          value: end,
        },
      ],
    };
  };

  const onFilterUpdate = (start: string, end: string) => {
    updateSearchFilters(buildFilters(start, end));
  };

  useEffect(() => {
    updateSearchFilters(
      buildFilters(searchParams?.start_date?.toString() || '', searchParams?.end_date?.toString() || ''),
    );
  }, []);

  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      <ActionStatusSnackbar actionName={WorkshopInboundActions.getWorkshopInboundReportOverview} />
      <Stack spacing={2} sx={{ height: '100%' }}>
        <Stack direction='row' spacing={2}>
          <Box pt={1.5}>
            <WorkshopInboundDashboardToolbar
              onDateFilterUpdate={onFilterUpdate}
              metaData={reportData.metaData}
              params={initialFiltersParsed}
              type={WorkshopReportType.assignee_activity_overview}
            />
          </Box>
          <Stack direction='row' spacing={1} sx={{ flex: 1, justifyContent: 'flex-end' }}>
            {searchParams.assignee && (
              <Card variant='outlined'>
                <CardContent>
                  <Stack spacing={1}>
                    <Typography>
                      <FormattedMessage id='workshopInbound_labels_assignee' defaultMessage='Assignee' />
                    </Typography>
                    <Typography variant='h5' component='div'>
                      {searchParams.assignee}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            )}
            {searchParams.activity && (
              <Card variant='outlined'>
                <CardContent>
                  <Stack spacing={1}>
                    <Typography>
                      <FormattedMessage id='workshop_labels_activity' defaultMessage='Activity' />
                    </Typography>
                    <Typography variant='h5' component='div'>
                      <FormattedMessage
                        id={`workshop_serviceActivityTypes_${searchParams.activity}`}
                        defaultMessage={text2title(searchParams.activity?.toString())}
                      />
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            )}
            {searchParams.status && (
              <Card variant='outlined'>
                <CardContent>
                  <Stack spacing={1}>
                    <Typography>
                      <FormattedMessage id='common_labels_status' defaultMessage='Status' />
                    </Typography>
                    <Typography variant='h5' component='div'>
                      <StatusBadge status={searchParams.status?.toString()} />
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            )}
          </Stack>
        </Stack>
        <ServerSidePaginatedTable
          columns={WORKSHOP_INBOUND_OVERVIEW_TABLE_ITEM_DETAILS}
          rows={reportData.items}
          totalCount={reportData.metaData.total || reportData.items.length}
          fetchDataEffect={getWorkshopInboundReportItemDetailsAction}
          searchParams={searchParams}
          loading={request?.status === 'pending'}
        />
      </Stack>
    </Box>
  );
};

export default WorkshopInboundReportItemDetailsTable;
