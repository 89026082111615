import { COLORS } from '@chronext/react-common';

export const APPLICATION = {
  APP_NAME: 'Operations',
};

export const MUI_THEME_DARK = {
  background: {
    default: '#303030',
    paper: COLORS.grayDarkest,
  },
  text: {
    primary: COLORS.white,
  },
  primary: {
    main: COLORS.orange,
  },
  secondary: {
    main: COLORS.grayDarkest,
  },
};

export const MUI_THEME_LIGHT = {
  background: {
    default: COLORS.canvasLight,
    paper: COLORS.coreQuarternary,
  },
  text: {
    primary: COLORS.corePrimary,
    secondary: COLORS.coreSecondary,
  },
  primary: {
    main: COLORS.corePrimary,
  },
  secondary: {
    main: COLORS.coreSecondary,
  },
};
