import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { UpdateWorkshopInboundWatchSparePartsParams } from '../../../../api/server/workshopInbound';
import { WorkshopSparePartsStatus } from '../../../../constants/workshop';
import { requestSelector } from '../../../../selectors/request';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { updateWorkshopInboundWatchSparePartsAction } from '../../../../state/ducks/workshopInbound/actions';
import { WorkshopInboundWatch } from '../../../../state/ducks/workshopInbound/types';
import { text2title } from '../../../../utils/textUtils';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  item: WorkshopInboundWatch;
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
};

const initialFormData: UpdateWorkshopInboundWatchSparePartsParams = {
  spare_parts_status: '',
  spare_parts: '',
};

const UpdateSparePartsInfoModal: React.FC<Props> = ({ item, onSuccess, handleToggleModal, show }) => {
  const dispatch = useAppDispatch();
  const request = useAppSelector((state) =>
    requestSelector(state, updateWorkshopInboundWatchSparePartsAction.typePrefix),
  );

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [isFormValid, setIsFormValid] = useState(false);

  const clearForm = () => {
    setFormData(initialFormData);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const submitForm = () => {
    dispatch(
      updateWorkshopInboundWatchSparePartsAction({
        reference: item.id.toString(),
        requestBody: formData,
      }),
    ).then((response) => {
      setShowSnackbar(true);
      if (response?.payload && !response.payload?.errors) {
        clearForm();
        handleToggleModal();
        onSuccess();
      }
    });
  };

  useEffect(() => {
    const { spare_parts_status, spare_parts } = item;
    setFormData({ spare_parts_status, spare_parts });
  }, [show]);

  useEffect(() => {
    if (item.spare_parts_status !== formData.spare_parts_status || item.spare_parts !== formData.spare_parts) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formData]);

  return (
    <>
      {showSnackbar && <ActionStatusSnackbar actionName={updateWorkshopInboundWatchSparePartsAction.typePrefix} />}
      <Dialog open={show} onClose={handleToggleModal}>
        <DialogTitle>
          <FormattedMessage
            id='workshopInbound_actions_updateSpareParts'
            defaultMessage='Update Spare Parts Information'
          />
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} mt={2}>
            <TextField
              type='text'
              margin='dense'
              name='spare_parts_status'
              value={formData.spare_parts_status}
              onChange={handleChange}
              label={
                <FormattedMessage id='workshopInbound_labels_sparePartsStatus' defaultMessage='Spare Parts Status' />
              }
              select
              fullWidth>
              {Object.keys(WorkshopSparePartsStatus).map((item) => (
                <MenuItem key={item} value={item}>
                  <FormattedMessage id={`status_${item}`} defaultMessage={text2title(item)} />
                </MenuItem>
              ))}
            </TextField>
            <TextField
              margin='dense'
              id='spare_parts'
              name='spare_parts'
              value={formData.spare_parts}
              onChange={handleChange}
              label={<FormattedMessage id='workshopInbound_labels_spareParts' defaultMessage='Spare Parts' />}
              fullWidth
              multiline
              rows={4}
              inputProps={{ maxLength: 500 }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleModal} disabled={request?.status === 'pending'} color='error'>
            <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
          </Button>
          <Button onClick={submitForm} disabled={!isFormValid || request?.status === 'pending'} color='success'>
            <FormattedMessage id='common_save' defaultMessage='Save' />
          </Button>
        </DialogActions>
        {request?.status === 'pending' && <LinearProgress color='primary' />}
      </Dialog>
    </>
  );
};

export default UpdateSparePartsInfoModal;
