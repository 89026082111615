import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getWorkshopItems,
  createWorkshopItem,
  getWorkshopItemByReference,
  updateWorkshopItem,
  getWorkshopActivities,
  createWorkshopProcess,
  updateWorkshopProcess,
  getWorkshopProcesses,
  getWorkshopReport,
  getWorkshopLocations,
  createWorkshopLocation,
  updateWorkshopLocation,
  addBookingItem,
  UpdateWorkshopProcessParams,
  getWorkshopLocationsOverview,
} from '../../../api/server/workshop';
import { WorkshopActions } from './types';

type ReportParams = {
  startTime: string;
  endTime: string;
  searchParams?: URLSearchParams;
};

export const getWorkshopProcessesAction = createAsyncThunk(
  WorkshopActions.getWorkshopProcesses,
  async (params?: URLSearchParams) => {
    const response = await getWorkshopProcesses(params);
    return response.data;
  },
);

export const getActivityReportAction = createAsyncThunk(
  WorkshopActions.getActivityReport,
  async (params: ReportParams) => {
    const searchParams = new URLSearchParams({
      report_type: 'activity',
      start_time: params.startTime,
      end_time: params.endTime,
    });
    const response = await getWorkshopReport(searchParams);
    return response.data;
  },
);

export const getEmployeeReportAction = createAsyncThunk(
  WorkshopActions.getEmployeeReport,
  async (params: ReportParams) => {
    const searchParams = new URLSearchParams({
      report_type: 'employee',
      start_time: params.startTime,
      end_time: params.endTime,
    });
    const response = await getWorkshopReport(searchParams);
    return response.data;
  },
);

export const getWorkshopActivitiesAction = createAsyncThunk(WorkshopActions.getActivities, async () => {
  const response = await getWorkshopActivities();
  return response.data;
});

export const getWorkshopItemsAction = createAsyncThunk(
  WorkshopActions.getWorkshopItems,
  async (params: URLSearchParams) => {
    const response = await getWorkshopItems(params);
    return response.data;
  },
);

export const getWorkshopItemAction = createAsyncThunk(WorkshopActions.getWorkshopItem, async (reference: string) => {
  const response = await getWorkshopItemByReference(reference);
  return response.data;
});

type CreateWorkshopItemParams = {
  serialNumber: string;
};

type UpdateWorkshopItemParams = CreateWorkshopItemParams & {
  productReference: string;
  variantCode: string;
};

export const createWorkshopItemAction = createAsyncThunk(
  WorkshopActions.createWorkshopItem,
  async (body: CreateWorkshopItemParams) => {
    const response = await createWorkshopItem(body);
    return response.data;
  },
);

export const updateWorkshopItemAction = createAsyncThunk(
  WorkshopActions.updateWorkshopItem,
  async ({ serialNumber, productReference, variantCode }: UpdateWorkshopItemParams) => {
    const response = await updateWorkshopItem(serialNumber, {
      productReference,
      variantCode,
      variantReference: productReference && variantCode && `${productReference}_${variantCode}`,
    });
    return response.data;
  },
);

type CreateProcessParams = {
  workshopItemReference: string;
  activityReference: string;
};

export const createWorkshopProcessAction = createAsyncThunk(
  WorkshopActions.createProcess,
  async (params: CreateProcessParams) => {
    const response = await createWorkshopProcess(params.workshopItemReference, params.activityReference);
    return response.data;
  },
);

type UpdateProcessParams = {
  workshopItemReference: string;
  reference: string;
  requestBody: UpdateWorkshopProcessParams;
};

export const updateWorkshopProcessAction = createAsyncThunk(
  WorkshopActions.updateProcess,
  async (params: UpdateProcessParams) => {
    const response = await updateWorkshopProcess(params.workshopItemReference, params.reference, params.requestBody);
    return response.data;
  },
);

export const getWorkshopLocationsAction = createAsyncThunk(WorkshopActions.getLocations, async () => {
  const response = await getWorkshopLocations();
  return response.data;
});

type CreateWorkshopLocation = {
  name: string;
  code: string;
  area: string;
  inInventory: boolean;
  isActive: boolean;
};

type UpdateWorkshopLocation = CreateWorkshopLocation & {
  locationReference: number;
};

export const createWorkshopLocationAction = createAsyncThunk(
  WorkshopActions.createLocation,
  async (body: CreateWorkshopLocation) => {
    const response = await createWorkshopLocation(body);
    return response.data;
  },
);

export const updateWorkshopLocationAction = createAsyncThunk(
  WorkshopActions.updateWorkshopItem,
  async ({ locationReference, ...data }: UpdateWorkshopLocation) => {
    const response = await updateWorkshopLocation(locationReference, data);
    return response.data;
  },
);

type AddBookingItem = {
  locationReference: number;
  workshopItemReference: string;
};

export const addBookingItemAction = createAsyncThunk(WorkshopActions.addBooking, async (params: AddBookingItem) => {
  const response = await addBookingItem(params.locationReference, params.workshopItemReference);
  return response.data;
});

export const getWorkshopLocationsOverviewAction = createAsyncThunk(
  WorkshopActions.getLocationsOverview,
  async (params?: URLSearchParams) => {
    const response = await getWorkshopLocationsOverview(params);
    return response.data;
  },
);
