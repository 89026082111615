import { Cancel, Share } from '@mui/icons-material';
import { Button } from '@mui/material';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { WorkshopActivities } from '../../../../constants/workshop';
import { sort, sortFunctionParams } from '../../../../hooks/useSearchStoreHistory';

type ToolbarProps = {
  showFilterButtons: boolean;
  handleResetClick: () => void;
  handleShareClick: () => void;
};

export const WorkshopInboundTableToolbar: React.FC<ToolbarProps> = ({
  showFilterButtons = false,
  handleResetClick,
  handleShareClick,
}) => (
  <GridToolbarContainer>
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
    {showFilterButtons && (
      <>
        <Button
          sx={{ ml: 1 }}
          color='warning'
          variant='outlined'
          size='small'
          onClick={handleResetClick}
          endIcon={<Cancel />}>
          <FormattedMessage id='common_resetFilters' defaultMessage='Reset Filters & Sorting' />
        </Button>
        <Button
          sx={{ ml: 1 }}
          color='info'
          variant='outlined'
          size='small'
          onClick={handleShareClick}
          endIcon={<Share />}>
          <FormattedMessage id='common_shareFilters' defaultMessage='Share Filters & Sorting' />
        </Button>
      </>
    )}
  </GridToolbarContainer>
);

export const sortingParams: Record<string, any> = {
  order_by: 'created_at',
  order: 'desc',
};

export const defaultSearchParams: Record<string, any> = {
  show_empty_sn: 'false',
  show_maintenance_activity: 'false',
  ...sortingParams,
};

export const defaultMaintenanceSearchParams: Record<string, any> = {
  show_empty_sn: 'false',
  show_maintenance_activity: 'true',
  activity_type: [WorkshopActivities.maintenance],
  ...sortingParams,
};

export const defaultSearchParamsWIItems: Record<string, any> = {
  show_empty_sn: 'false',
  service_approved: '',
  item_number: '',
  variant_code: '',
  brand: '',
  model: '',
  reference: '',
  serial_number: '',
  priority: '',
  ...sortingParams,
};

export const sortFunction = ({ sortModel }: sortFunctionParams): sort => ({
  order_by: sortModel[0]?.field || 'created_at',
  order: sortModel[0]?.sort || 'desc',
});
