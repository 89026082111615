import React, { useState } from 'react';
import { algoliaBrandsSelector } from '../../../selectors/algolia';
import { useAppSelector } from '../../../state';
import { getAlgoliaBrandsAction } from '../../../state/ducks/algolia/actions';
import ActionStatusSnackbar from '../snackbars/ActionStatusSnackbar';
import ServerSidePaginatedTable, { ServerSidePaginatedTableColDef } from '../table/ServerSidePaginatedTable';
import styles from '../../pages/workshop-prices/WorkshopPrices.module.scss';

const brandsTableColumns: ServerSidePaginatedTableColDef[] = [
  {
    id: 'common_labels_brand',
    headerName: 'Brand',
    field: 'name',
    width: 60,
    sortable: false,
    flex: 1,
  },
  {
    id: 'workshopPrices_labels_brandSlug',
    headerName: 'Brand Slug',
    field: 'slug',
    sortable: false,
    flex: 1,
  },
];

const AlgoliaBrands: React.FC = () => {
  const { brands, brandsTotal } = useAppSelector(algoliaBrandsSelector);
  const [searchParams] = useState({
    where: '',
  });

  return (
    <div className={styles.wrapper}>
      <ActionStatusSnackbar actionName={getAlgoliaBrandsAction.typePrefix} />
      <ServerSidePaginatedTable
        rows={brands}
        columns={brandsTableColumns}
        totalCount={brandsTotal}
        fetchDataEffect={getAlgoliaBrandsAction}
        searchParams={searchParams}
      />
    </div>
  );
};

export default AlgoliaBrands;
