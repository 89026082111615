export type WorkshopEmployee = {
  username: string;
  role: string;
};

export const WORKSHOP_EMPLOYEES: WorkshopEmployee[] = [
  {
    username: 'celine.rothe',
    role: 'watchmaker',
  },
  {
    username: 'cem.yelegen',
    role: 'polisher',
  },
  {
    username: 'doreen.grantolli',
    role: 'watchmaker',
  },
  {
    username: 'hagen.klein',
    role: 'watchmaker',
  },
  {
    username: 'marcel.schmitz',
    role: 'watchmaker',
  },
  {
    username: 'martin.kloeckner',
    role: 'watchmaker',
  },
  {
    username: 'nico.drolshagen',
    role: 'watchmaker',
  },
];

export const WORKSHOP_EMPLOYEE_USERNAMES: string[] = WORKSHOP_EMPLOYEES.map((employee) => employee.username);

export enum WorkshopStatus {
  pending = 'pending',
  in_progress = 'in_progress',
  paused = 'paused',
  complete = 'complete',
}

export enum WorkshopActivities {
  assembling = 'assembling',
  dismantling = 'dismantling',
  fullService = 'fullService',
  polishing = 'polishing',
  endControl = 'endControl',
  cleaning = 'cleaning',
  authenticate = 'authenticate',
  rework = 'rework',
  maintenance = 'maintenance',
  externalWorkshop = 'externalWorkshop',
}

export enum WorkshopServiceTypes {
  authenticate = 'authenticate',
  polishing = 'polishing',
  partialService = 'partialService',
  fullService = 'fullService',
  externalWorkshop = 'externalWorkshop',
}

export enum WorkshopReworkReasons {
  amplitude_out_of_range = 'amplitude_out_of_range', // Amplitude out of range
  assembled_wrong = 'assembled_wrong', // Assembled wrong
  date_setting_wrong = 'date_setting_wrong', // Date setting wrong
  dirty = 'dirty', // Dirty
  hand_setting_wrong = 'hand_setting_wrong', // Hand setting wrong
  not_running = 'not_running', // Not running
  not_waterproof = 'not_waterproof', // Not waterproof
  outer_parts_defective = 'outer_parts_defective', // Outer parts defective
  scratched_after_assembling = 'scratched_after_assembling', // Scratched after assembling
}

type StatusMapping = {
  [key: string]: string[];
};

export const NEXT_STATUS_MAPPING: StatusMapping = {
  pending: [WorkshopStatus.in_progress],
  in_progress: [WorkshopStatus.paused, WorkshopStatus.complete],
  paused: [WorkshopStatus.in_progress],
  complete: [],
};

export const NEXT_MAINTENANCE_STATUS_MAPPING: StatusMapping = {
  pending: [WorkshopStatus.in_progress],
  in_progress: [WorkshopStatus.complete],
  complete: [],
};

export enum WorkshopServiceApprovalStatus {
  pending = 'pending',
  approved = 'approved',
  declined = 'declined',
}

export const WORKSHOP_ACTIVITIES_WITH_REPORT: string[] = [
  WorkshopActivities.authenticate,
  WorkshopActivities.endControl,
];

export enum WorkshopReportType {
  assignee_overview = 'assignee_overview',
  activity_overview = 'activity_overview',
  watch_overview = 'watch_overview',
  assignee_activity_overview = 'assignee_activity_overview',
  watch_overview_details = 'watch_overview_details',
}

export enum WorkshopSparePartsStatus {
  requested = 'requested',
  ordered = 'ordered',
  received = 'received',
}
