const isProd: boolean = process.env.REACT_APP_NODE_ENV === 'production';

const useMockAPI: boolean = process.env.NODE_ENV === 'development' && process.env.REACT_APP_USE_MOCK_SERVER === 'true';

const backendAPI: string = process.env.REACT_APP_BACKEND_URL || '';

const mockResDelay: number = parseInt(process.env.REACT_APP_MOCK_RESPONSE_DELAY || '0') || 0;

export const APP_CONFIG = {
  isProd,
  useMockAPI,
  backendAPI,
  mockResDelay,
};
