import { IntlShape } from 'react-intl/src/types';

export const getDataGridStyles = (onRowClick: boolean) => {
  const styles: Record<string, any> = {
    '.MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-virtualScrollerContent': {
      height: `100% !important`,
    },
  };
  if (onRowClick) {
    styles['& .MuiDataGrid-row'] = {
      cursor: 'pointer',
    };
  }
  return styles;
};

export const getDataGridLocaleText = (intl: IntlShape) => ({
  noRowsLabel: intl.formatMessage({ id: 'dataGrid_noRowsLabel', defaultMessage: 'No Rows' }),
  noResultsOverlayLabel: intl.formatMessage({
    id: 'dataGrid_noResultsOverlayLabel',
    defaultMessage: 'No results found.',
  }),
});
