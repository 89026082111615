import { Home, Receipt } from '@mui/icons-material';
import { Card, CardContent, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { FormattedMessage } from 'react-intl';
import { currentUserSelector } from '../../../../selectors/user';
import { useAppSelector } from '../../../../state';
import { VendorAddress } from '../../../../state/ducks/sell/types';
import { getCountryNameFromAlpha2 } from '../../../../utils/countryUtils';

type Props = {
  address: VendorAddress;
};
const Address: React.FC<Props> = ({ address }) => {
  const { language } = useAppSelector(currentUserSelector);
  const countryCode = address.country.slice(0, 2);
  const countryName = getCountryNameFromAlpha2(countryCode, language);

  return (
    <Card variant='outlined' sx={{ minWidth: '300px' }}>
      <CardContent>
        <Stack direction='column'>
          <Stack direction='row' spacing={0.5} alignItems='center' sx={{ mb: 1.5 }}>
            {address.pickup ? <Home /> : <Receipt />}
            <Typography fontWeight='medium' variant='h6' component='div'>
              {address.pickup ? (
                <FormattedMessage id='address_display_pickup' defaultMessage='Pickup Address' />
              ) : (
                <FormattedMessage id='address_display_billing' defaultMessage='Billing Address' />
              )}
            </Typography>
          </Stack>
          <Typography fontWeight='bold'>
            {address.salutation} {address.first_name} {address.last_name}
          </Typography>
          {address.company && <Typography>{address.company}</Typography>}
          <Typography>
            {address.street} {address.street_number}
          </Typography>
          {address.street_info && <Typography>{address.street_info}</Typography>}
          <Typography>
            {address.postal_code} {address.city}
          </Typography>
          <Typography>{address.state}</Typography>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Tooltip key={countryCode} title={countryName} arrow>
              <div>
                <ReactCountryFlag countryCode={countryCode} style={{ fontSize: '25px', lineHeight: '1em' }} />
              </div>
            </Tooltip>
            <Typography>{countryName}</Typography>
          </Stack>
        </Stack>
        <Typography>{address.phone}</Typography>
      </CardContent>
    </Card>
  );
};

export default Address;
