import { GROUPS } from '../constants/groups';

export enum ClientRoute {
  HOME = '/',
  LOGIN = '/login',
  DASHBOARD = '/dashboard',
  TRACKING = '/tracking',
  TRACKING_TABS = '/tracking/:tab',
  TRACKING_SALES_DETAILS = '/tracking/sales/:reference',
  TRACKING_PURCHASES_DETAILS = '/tracking/purchases/:reference',
  TRACKING_TRANSFERS_DETAILS = '/tracking/transfers/:reference',
  WORKSHOP = '/workshop',
  WORKSHOP_PROCESSES = '/workshop/processes',
  WORKSHOP_BOOKING_ITEM = '/workshop/booking',
  WORKSHOP_REPORTS = '/workshop/reports',
  WORKSHOP_PRODUCTS = '/workshop/products',
  WORKSHOP_INVENTORY = '/workshop/inventory',
  WORKSHOP_INVENTORY_ITEM = '/workshop/inventory/:reference',
  WORKSHOP_LOCATIONS = '/workshop/locations',
  PRODUCTS = '/products',
  PRODUCT_CATEGORIES = '/product-categories',
  PRODUCT_DISCOUNTS = '/product-discounts',
  WORKSHOP_PRICES = '/workshop-prices',
  SELL = '/sell',
  SELL_TABS = '/sell/:tab',
  SELL_DETAILS = '/sell/details/:reference',
  WORKSHOP_INBOUND = '/workshop/inbound',
  WORKSHOP_INBOUND_DASHBOARD = '/workshop/inbound/dashboard',
  WORKSHOP_INBOUND_DASHBOARD_TABS = '/workshop/inbound/dashboard/:tab',
  WORKSHOP_INBOUND_DASHBOARD_DETAILS = '/workshop/inbound/dashboard-details',
  WORKSHOP_INBOUND_DASHBOARD_ITEMS = '/workshop/inbound/dashboard-items',
  WORKSHOP_INBOUND_REQUESTS = '/workshop/inbound/requests',
  WORKSHOP_INBOUND_ITEMS = '/workshop/inbound/items/:tab',
  WORKSHOP_INBOUND_ACTIVITIES = '/workshop/inbound/activities',
  WORKSHOP_INBOUND_ACTIVITIES_TABS = '/workshop/inbound/activities/:tab',
  WORKSHOP_INBOUND_ITEM = '/workshop/inbound/items/details/:reference',
  WORKSHOP_INBOUND_ACTIVITY = '/workshop/inbound/activities/details/:reference',
  PRICE_CALCULATOR = '/calculator/prices',
  PRICE_CALCULATOR_CONFIG = '/calculator/config',
}

export type PathArgs =
  | { path: ClientRoute.HOME }
  | { path: ClientRoute.LOGIN }
  | { path: ClientRoute.DASHBOARD }
  | { path: ClientRoute.TRACKING }
  | { path: ClientRoute.TRACKING_TABS; params: { tab: string } }
  | { path: ClientRoute.TRACKING_SALES_DETAILS; params: { reference: string } }
  | { path: ClientRoute.TRACKING_PURCHASES_DETAILS; params: { reference: string } }
  | { path: ClientRoute.TRACKING_TRANSFERS_DETAILS; params: { reference: string } }
  | {
      path:
        | ClientRoute.WORKSHOP
        | ClientRoute.WORKSHOP_PROCESSES
        | ClientRoute.WORKSHOP_BOOKING_ITEM
        | ClientRoute.WORKSHOP_REPORTS
        | ClientRoute.WORKSHOP_PRODUCTS
        | ClientRoute.WORKSHOP_INVENTORY
        | ClientRoute.WORKSHOP_LOCATIONS;
    }
  | {
      path: ClientRoute.WORKSHOP_INVENTORY_ITEM;
      params: { reference: string };
    }
  | { path: ClientRoute.PRODUCTS | ClientRoute.PRODUCT_CATEGORIES | ClientRoute.PRODUCT_DISCOUNTS }
  | { path: ClientRoute.WORKSHOP_PRICES }
  | { path: ClientRoute.SELL }
  | {
      path: ClientRoute.SELL_TABS | ClientRoute.WORKSHOP_INBOUND_DASHBOARD_TABS | ClientRoute.WORKSHOP_INBOUND_ITEMS;
      params: { tab: string };
    }
  | { path: ClientRoute.SELL_DETAILS; params: { reference: string } }
  | { path: ClientRoute.WORKSHOP_INBOUND_ACTIVITIES }
  | { path: ClientRoute.WORKSHOP_INBOUND_ACTIVITIES_TABS; params: { tab: string } }
  | {
      path:
        | ClientRoute.WORKSHOP_INBOUND
        | ClientRoute.WORKSHOP_INBOUND_DASHBOARD
        | ClientRoute.WORKSHOP_INBOUND_DASHBOARD_DETAILS
        | ClientRoute.WORKSHOP_INBOUND_DASHBOARD_ITEMS
        | ClientRoute.WORKSHOP_INBOUND_REQUESTS;
    }
  | { path: ClientRoute.WORKSHOP_INBOUND_ITEM | ClientRoute.WORKSHOP_INBOUND_ACTIVITY; params: { reference: string } }
  | { path: ClientRoute.PRICE_CALCULATOR }
  | { path: ClientRoute.PRICE_CALCULATOR_CONFIG };

export const ClientRouteGroups = {
  HOME: [],
  LOGIN: [],
  DASHBOARD: [],
  TRACKING: [],
  TRACKING_SALES_DETAILS: [],
  TRACKING_PURCHASES_DETAILS: [],
  TRACKING_TRANSFERS_DETAILS: [],
  WORKSHOP: [GROUPS.WORKSHOP_ADMIN, GROUPS.WORKSHOP_EMPLOYEE, GROUPS.FPA],
  WORKSHOP_PROCESSES: [GROUPS.WORKSHOP_ADMIN, GROUPS.WORKSHOP_EMPLOYEE, GROUPS.FPA],
  WORKSHOP_BOOKING_ITEM: [GROUPS.WORKSHOP_ADMIN, GROUPS.WORKSHOP_EMPLOYEE],
  WORKSHOP_REPORTS: [GROUPS.WORKSHOP_ADMIN, GROUPS.FPA],
  WORKSHOP_PRODUCTS: [GROUPS.WORKSHOP_ADMIN, GROUPS.WORKSHOP_EMPLOYEE],
  WORKSHOP_INVENTORY: [GROUPS.WORKSHOP_ADMIN, GROUPS.WORKSHOP_EMPLOYEE, GROUPS.FPA],
  WORKSHOP_INVENTORY_ITEM: [GROUPS.WORKSHOP_ADMIN, GROUPS.WORKSHOP_EMPLOYEE, GROUPS.FPA],
  WORKSHOP_LOCATIONS: [GROUPS.WORKSHOP_ADMIN],
  PRODUCTS: [GROUPS.MARKETING, GROUPS.PRICING, GROUPS.SALES_MANAGER, GROUPS.OPERATIONS, GROUPS.SALES_AGENT],
  PRODUCT_CATEGORIES: [GROUPS.MARKETING, GROUPS.PRICING],
  PRODUCT_DISCOUNTS: [GROUPS.MARKETING, GROUPS.SALES_MANAGER, GROUPS.OPERATIONS, GROUPS.SALES_AGENT],
  WORKSHOP_PRICES: [GROUPS.WORKSHOP_ADMIN],
  SELL: [
    GROUPS.SUPPLY_MANAGER,
    GROUPS.SUPPLY_AGENT,
    GROUPS.SALES_MANAGER,
    GROUPS.SALES_AGENT,
    GROUPS.OPERATIONS,
    GROUPS.PRICING,
    GROUPS.WORKSHOP_ADMIN,
    GROUPS.WORKSHOP_EMPLOYEE,
    GROUPS.FPA,
  ],
  SELL_DETAILS: [
    GROUPS.SUPPLY_MANAGER,
    GROUPS.SUPPLY_AGENT,
    GROUPS.SALES_MANAGER,
    GROUPS.SALES_AGENT,
    GROUPS.OPERATIONS,
    GROUPS.PRICING,
    GROUPS.WORKSHOP_ADMIN,
    GROUPS.WORKSHOP_EMPLOYEE,
    GROUPS.FPA,
  ],
  WORKSHOP_INBOUND: [
    GROUPS.WORKSHOP_ADMIN,
    GROUPS.WORKSHOP_EMPLOYEE,
    GROUPS.SUPPLY_MANAGER,
    GROUPS.SUPPLY_AGENT,
    GROUPS.SALES_MANAGER,
    GROUPS.SALES_AGENT,
    GROUPS.FPA,
  ],
  WORKSHOP_INBOUND_DASHBOARD: [GROUPS.WORKSHOP_ADMIN],
  WORKSHOP_INBOUND_REQUESTS: [GROUPS.WORKSHOP_ADMIN, GROUPS.FPA],
  WORKSHOP_INBOUND_ITEMS: [
    GROUPS.WORKSHOP_ADMIN,
    GROUPS.WORKSHOP_EMPLOYEE,
    GROUPS.SUPPLY_MANAGER,
    GROUPS.SUPPLY_AGENT,
    GROUPS.SALES_MANAGER,
    GROUPS.SALES_AGENT,
    GROUPS.FPA,
  ],
  WORKSHOP_INBOUND_ITEM: [
    GROUPS.WORKSHOP_ADMIN,
    GROUPS.WORKSHOP_EMPLOYEE,
    GROUPS.SUPPLY_MANAGER,
    GROUPS.SUPPLY_AGENT,
    GROUPS.SALES_MANAGER,
    GROUPS.SALES_AGENT,
    GROUPS.FPA,
  ],
  WORKSHOP_INBOUND_ACTIVITIES: [GROUPS.WORKSHOP_ADMIN, GROUPS.WORKSHOP_EMPLOYEE, GROUPS.FPA],
  WORKSHOP_INBOUND_ACTIVITY: [GROUPS.WORKSHOP_ADMIN, GROUPS.WORKSHOP_EMPLOYEE, GROUPS.FPA],
  PRICE_CALCULATOR: [],
  PRICE_CALCULATOR_CONFIG: [GROUPS.PRICING],
};
