import { THEME_COLORS } from '@chronext/react-common';
import { Percent, Send } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Backdrop,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { LineChart } from '@mui/x-charts/LineChart';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { BraceletType, Condition } from '../../../constants/pricing';
import { algoliaStateSelector } from '../../../selectors/algolia';
import { calculatorResultSelector } from '../../../selectors/pricing/pricing.selector';
import { requestSelector } from '../../../selectors/request';
import { currentUserSelector, userColorModeSelector } from '../../../selectors/user/user.selector';
import { useAppDispatch, useAppSelector } from '../../../state';
import { getAlgoliaBrandsAction, getAlgoliaModelsForBrandAction } from '../../../state/ducks/algolia/actions';
import { getPricingAction } from '../../../state/ducks/pricing/actions';
import { formatUTCToLocal } from '../../../utils/datetime';
import { calculatePriceFromMargin, getPriceLocaleString, text2title } from '../../../utils/textUtils';
import ConditionBadge from '../../components/badges/ConditionBadge';
import BooleanIcon from '../../components/icons/BooleanIcon';
import Price from '../../components/price/Price';
import ActionStatusSnackbar from '../../components/snackbars/ActionStatusSnackbar';

type MarginFieldProps = {
  name: string;
  value: number;
  onChange: (v: number) => void;
  disabled: boolean;
  min?: number;
};
const renderMarginField = ({ name, value, disabled, onChange, min = 0 }: MarginFieldProps) => (
  <TextField
    sx={{ width: '150px' }}
    size='small'
    type='number'
    margin='none'
    name={name}
    value={value}
    disabled={disabled}
    error={Number(value) < min || Number(value) > 100}
    inputProps={{ min: min, step: 1, max: 100 }}
    InputProps={{
      startAdornment: (
        <InputAdornment position='start'>
          <Percent />
        </InputAdornment>
      ),
    }}
    onChange={(event) => onChange(Number(event.target.value))}
    label={<FormattedMessage id='sellRequests_labels_desiredMargin' defaultMessage='Desired Margin' />}
  />
);

type PriceCalculatorFormData = {
  brand: string;
  model: string;
  reference: string;
  dial_color: string;
  condition: string;
  box: boolean;
  papers: boolean;
  bracelet_type: string;
};

const initialFormData: PriceCalculatorFormData = {
  brand: '',
  model: '',
  reference: '',
  dial_color: '',
  condition: '',
  box: false,
  papers: false,
  bracelet_type: '',
};

const PriceCalculatorPage = () => {
  const dispatch = useAppDispatch();
  const colorMode = useAppSelector(userColorModeSelector);
  const currentUser = useAppSelector(currentUserSelector);
  const { prices, item, weekEndingDate, sampleCount, margins, modelledData } = useAppSelector(calculatorResultSelector);
  const { brands: algoliaBrands, models: algoliaModels, dialColors } = useAppSelector(algoliaStateSelector);
  const [formData, setFormData] = useState<PriceCalculatorFormData>(initialFormData);
  const [isFormValid, setIsFormValid] = useState(false);
  const request = useAppSelector((state) => requestSelector(state, getPricingAction.typePrefix));
  const [directMargin, setDirectMargin] = useState(Math.trunc((margins?.direct || 0) * 100) || 0);
  const [commissionMargin, setCommissionMargin] = useState(Math.trunc((margins?.commission || 0) * 100) || 0);
  const [tradeInMargin, setTradeInMargin] = useState(Math.trunc((margins?.tradeIn || 0) * 100) || 0);
  const { language } = currentUser;

  const clearForm = () => {
    setFormData(initialFormData);
  };
  const fetchPricing = () => {
    const formattedData = {
      ...formData,
      box: formData.box ? '1' : '0',
      papers: formData.papers ? '1' : '0',
    };
    const params = new URLSearchParams(formattedData);
    dispatch(getPricingAction(params));
  };

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.checked });
  };

  const handleBrandChange = (event: SelectChangeEvent) => {
    dispatch(getAlgoliaModelsForBrandAction(event.target.value));
    setFormData({ ...formData, brand: event.target.value, model: '' });
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams();
    dispatch(getAlgoliaBrandsAction(urlSearchParams));
  }, []);

  useEffect(() => {
    setCommissionMargin(Math.trunc((margins?.commission || 0) * 100) || 0);
    setDirectMargin(Math.trunc((margins?.direct || 0) * 100) || 0);
    setTradeInMargin(Math.trunc((margins?.tradeIn || 0) * 100) || 0);
  }, [margins]);

  useEffect(() => {
    if (formData.brand && formData.dial_color && (formData.model || formData.reference) && formData.condition) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formData]);

  return (
    <>
      <ActionStatusSnackbar actionName={getPricingAction.typePrefix} />
      <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={8} md={6}>
          <Card>
            <CardHeader title={<FormattedMessage id='menu_priceCalculator' defaultMessage='Price Calculator' />} />
            <CardContent>
              <Stack spacing={2}>
                <Typography>
                  <FormattedMessage
                    id='priceCalculator_formDescription'
                    defaultMessage='To calculate the price for an item, the following data has to be provided: brand, dial color and at least one of model or reference'
                  />
                </Typography>
                <Alert severity='info' sx={{ marginBottom: '1rem !important' }}>
                  <Typography>
                    <FormattedMessage
                      id='priceCalculator_formDescription_modelledData'
                      defaultMessage='In case there are no results, please enter at least all three of brand, model and reference. For non-Rolex brands, we may calculate the potential price based on our model of the data.'
                    />
                  </Typography>
                </Alert>
                <FormControl>
                  <InputLabel id='brandLabel'>
                    <FormattedMessage id='common_labels_brand' defaultMessage='Brand' />
                  </InputLabel>
                  <Select
                    margin='dense'
                    id='brand'
                    name='brand'
                    value={formData.brand}
                    onChange={handleBrandChange}
                    input={
                      <OutlinedInput label={<FormattedMessage id='common_labels_brand' defaultMessage='Brand' />} />
                    }
                    labelId='brandLabel'
                    fullWidth>
                    {algoliaBrands.map((option) => (
                      <MenuItem key={option.slug} value={option.slug}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    <FormattedMessage id='common_required' defaultMessage='required' />
                  </FormHelperText>
                </FormControl>
                {algoliaModels.length ? (
                  <FormControl>
                    <InputLabel id='modelLabel'>
                      <FormattedMessage id='common_labels_model' defaultMessage='Model' />
                    </InputLabel>
                    <Select
                      disabled={!formData.brand}
                      margin='dense'
                      id='model'
                      name='model'
                      value={formData.model}
                      onChange={handleSelectChange}
                      input={
                        <OutlinedInput label={<FormattedMessage id='common_labels_model' defaultMessage='model' />} />
                      }
                      labelId='modelLabel'
                      fullWidth>
                      {algoliaModels.map((option) => (
                        <MenuItem key={option.slug} value={option.slug}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    margin='dense'
                    id='model'
                    name='model'
                    value={formData.model}
                    onChange={handleTextFieldChange}
                    label={<FormattedMessage id='common_labels_model' defaultMessage='Model' />}
                    fullWidth
                  />
                )}
                <TextField
                  margin='dense'
                  id='reference'
                  name='reference'
                  value={formData.reference}
                  onChange={handleTextFieldChange}
                  label={<FormattedMessage id='common_labels_reference' defaultMessage='Reference' />}
                  fullWidth
                />
                <FormControl>
                  <InputLabel id='dialColorLabel'>
                    <FormattedMessage id='workshop_report_labels_questions_dialColor' defaultMessage='Dial Color' />
                  </InputLabel>
                  <Select
                    margin='dense'
                    id='dial_color'
                    name='dial_color'
                    value={formData.dial_color}
                    onChange={handleSelectChange}
                    input={
                      <OutlinedInput
                        label={
                          <FormattedMessage
                            id='workshop_report_labels_questions_dialColor'
                            defaultMessage='Dial Color'
                          />
                        }
                      />
                    }
                    labelId='dialColorLabel'
                    fullWidth>
                    {dialColors
                      .filter((color) => color.toLowerCase() !== 'multicolor')
                      .map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>
                    <FormattedMessage id='common_required' defaultMessage='required' />
                  </FormHelperText>
                </FormControl>
                <FormControl>
                  <InputLabel id='conditionLabel'>
                    <FormattedMessage id='common_labels_watchCondition' defaultMessage='Condition' />
                  </InputLabel>
                  <Select
                    margin='dense'
                    id='condition'
                    name='condition'
                    value={formData.condition}
                    onChange={handleSelectChange}
                    input={
                      <OutlinedInput
                        label={<FormattedMessage id='common_labels_watchCondition' defaultMessage='Condition' />}
                      />
                    }
                    labelId='conditionLabel'
                    fullWidth>
                    {Object.keys(Condition).map((option) => (
                      <MenuItem key={option} value={option}>
                        <FormattedMessage id={`condition_${option}`} defaultMessage={option} />
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    <FormattedMessage id='common_required' defaultMessage='required' />
                  </FormHelperText>
                </FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.papers}
                      onChange={handleSwitchChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      name='papers'
                    />
                  }
                  label={<FormattedMessage id='common_labels_paperAvailable' defaultMessage='Paper' />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.box}
                      onChange={handleSwitchChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      name='box'
                    />
                  }
                  label={<FormattedMessage id='common_labels_boxAvailable' defaultMessage='Box' />}
                />
                <FormControl>
                  <InputLabel id='braceletTypeLabel'>
                    <FormattedMessage id='common_labels_braceletType' defaultMessage='Bracelet Type' />
                  </InputLabel>
                  <Select
                    margin='dense'
                    id='bracelet_type'
                    name='bracelet_type'
                    value={formData.bracelet_type}
                    onChange={handleSelectChange}
                    input={
                      <OutlinedInput
                        label={<FormattedMessage id='common_labels_braceletType' defaultMessage='Bracelet Type' />}
                      />
                    }
                    labelId='braceletTypeLabel'
                    fullWidth>
                    {Object.keys(BraceletType).map((option) => (
                      <MenuItem key={option} value={option}>
                        {text2title(option)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </CardContent>
            <CardActions>
              <LoadingButton
                endIcon={<Send />}
                loading={request.status === 'pending'}
                variant='contained'
                color='primary'
                onClick={fetchPricing}
                disabled={!isFormValid}>
                <FormattedMessage id='common_submit' defaultMessage='Submit' />
              </LoadingButton>
              <Button variant='text' color='error' onClick={clearForm}>
                <FormattedMessage id='common_clear' defaultMessage='Clear' />
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={4} sm={8} md={6}>
          <Card sx={{ ...THEME_COLORS[`green${colorMode === 'light' ? 'Light' : ''}`], position: 'relative' }}>
            <Backdrop open={request.status === 'pending'} sx={{ position: 'absolute' }}>
              <CircularProgress color='inherit' />
            </Backdrop>
            <CardHeader title={<FormattedMessage id='common_results' defaultMessage='Results' />} />
            <CardContent>
              <Stack spacing={2}>
                {item?.brand && (
                  <TableContainer component={Paper}>
                    <Table size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <FormattedMessage id='common_labels_brand' defaultMessage='Brand' />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage id='common_labels_model' defaultMessage='Model' />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage id='common_labels_reference' defaultMessage='Reference' />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage
                              id='workshop_report_labels_questions_dialColor'
                              defaultMessage='Dial Color'
                            />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage id='common_labels_watchCondition' defaultMessage='Condition' />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage id='common_labels_boxAvailable' defaultMessage='Box' />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage id='common_labels_paperAvailable' defaultMessage='Paper' />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage id='common_labels_braceletType' defaultMessage='Bracelet Type' />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>{item.brand}</TableCell>
                          <TableCell>{item.model}</TableCell>
                          <TableCell>{item.reference}</TableCell>
                          <TableCell>{item.dialColor}</TableCell>
                          <TableCell>
                            <ConditionBadge condition={item.condition} />
                          </TableCell>
                          <TableCell>
                            <BooleanIcon check={item.box} />
                          </TableCell>
                          <TableCell>
                            <BooleanIcon check={item.papers} />
                          </TableCell>
                          <TableCell>{text2title(item.braceletType)}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {modelledData?.isModelled && (
                  <Alert severity='info'>
                    <Typography>
                      <FormattedMessage
                        id='priceCalculator_disclaimer_modelledData'
                        defaultMessage='The price is based on a dataset with an average error of {mae}, meaning predictions are typically off by this amount, and {absoluteErrorPercentage}% of the predictions fall within a range of ±{absoluteErrorRange} from the actual values.'
                        values={{
                          mae: modelledData?.mae,
                          absoluteErrorPercentage: modelledData?.absoluteErrorPercentage,
                          absoluteErrorRange: modelledData?.absoluteErrorRange,
                        }}
                      />
                    </Typography>
                  </Alert>
                )}
                <Stack direction='row' spacing={2}>
                  <Typography fontWeight='bold'>
                    <FormattedMessage id='priceCalculator_labels_marketPrice' defaultMessage='Market Price' />
                  </Typography>
                  <Typography fontWeight='bold' color='primary'>
                    <Price value={prices?.marketPrice} />
                    {prices?.minMarketPrice && prices?.maxMarketPrice && (
                      <>
                        &nbsp;(
                        <Price value={prices?.minMarketPrice} /> - <Price value={prices?.maxMarketPrice} />)
                      </>
                    )}
                  </Typography>
                </Stack>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell variant='head'>
                          <Typography fontWeight='bold'>
                            <FormattedMessage id='sellRequests_sellingMethods_direct' defaultMessage='Direct' />
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Price
                            value={prices?.direct}
                            prefix={<FormattedMessage id='priceCalculator_priceUpTo' defaultMessage='up to' />}
                          />
                        </TableCell>
                        <TableCell>
                          <FormattedMessage id='common_labels_margin' defaultMessage='Margin' />
                          <br />
                          {!!margins?.direct && `${Math.trunc(margins.direct * 100)}%`}
                        </TableCell>
                        <TableCell>
                          {renderMarginField({
                            name: 'directMargin',
                            value: directMargin,
                            onChange: setDirectMargin,
                            disabled: !prices?.marketPrice || prices?.marketPrice <= 0,
                          })}
                        </TableCell>
                        <TableCell>
                          <Price value={prices && calculatePriceFromMargin(prices?.marketPrice, directMargin)} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant='head'>
                          <Typography fontWeight='bold'>
                            <FormattedMessage id='sellRequests_sellingMethods_commission' defaultMessage='Commission' />
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Price
                            value={prices?.commission}
                            prefix={<FormattedMessage id='priceCalculator_priceUpTo' defaultMessage='up to' />}
                          />
                        </TableCell>
                        <TableCell>
                          <FormattedMessage id='common_labels_margin' defaultMessage='Margin' />
                          <br />
                          {!!margins?.commission && `${Math.trunc(margins.commission * 100)}%`}
                        </TableCell>
                        <TableCell>
                          {renderMarginField({
                            name: 'commissionMargin',
                            value: commissionMargin,
                            onChange: setCommissionMargin,
                            disabled: !prices?.marketPrice || prices?.marketPrice <= 0,
                          })}
                        </TableCell>
                        <TableCell>
                          <Price value={prices && calculatePriceFromMargin(prices?.marketPrice, commissionMargin)} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant='head'>
                          <Typography fontWeight='bold'>
                            <FormattedMessage id='sellRequests_sellingMethods_tradeIn' defaultMessage='Trade In' />
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Price
                            value={prices?.tradeIn}
                            prefix={<FormattedMessage id='priceCalculator_priceUpTo' defaultMessage='up to' />}
                          />
                        </TableCell>
                        <TableCell>
                          <FormattedMessage id='common_labels_margin' defaultMessage='Margin' />
                          <br />
                          {!!margins?.tradeIn && `${Math.trunc(margins.tradeIn * 100)}%`}
                        </TableCell>
                        <TableCell>
                          {renderMarginField({
                            name: 'tradeInMargin',
                            value: tradeInMargin,
                            onChange: setTradeInMargin,
                            disabled: !prices?.marketPrice || prices?.marketPrice <= 0,
                          })}
                        </TableCell>
                        <TableCell>
                          <Price value={prices && calculatePriceFromMargin(prices?.marketPrice, tradeInMargin)} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                {!!sampleCount && !!weekEndingDate && (
                  <Typography>
                    <FormattedMessage
                      id='priceCalculator_disclaimer'
                      defaultMessage='These prices are calculated based on {count, plural, one {# sample} other {# samples}} collected from {startDate} until {endDate}'
                      values={{
                        count: sampleCount,
                        startDate: formatUTCToLocal(
                          moment(weekEndingDate).subtract(6, 'days').toString(),
                          'DD.MM.YYYY',
                        ),
                        endDate: formatUTCToLocal(weekEndingDate, 'DD.MM.YYYY'),
                      }}
                    />
                  </Typography>
                )}
                {prices?.yearsAndPrices && (
                  <>
                    <Typography variant='h5'>
                      <FormattedMessage
                        id='priceCalculator_priceGraph'
                        defaultMessage='Prices based on manufacturing year'
                      />
                    </Typography>
                    <LineChart
                      xAxis={[
                        {
                          id: 'years',
                          data: Object.keys(prices.yearsAndPrices),
                          valueFormatter: (v) => v.toString(),
                        },
                      ]}
                      series={[
                        {
                          id: 'prices',
                          data: Object.values(prices.yearsAndPrices),
                          valueFormatter: (v) => getPriceLocaleString(v, language, 'DE', 'EUR').toString(),
                        },
                      ]}
                      height={300}
                      colors={[THEME_COLORS[`green${colorMode === 'light' ? 'Light' : ''}`].color]}
                    />
                  </>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default PriceCalculatorPage;
