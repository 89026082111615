import { Add, PriorityHigh } from '@mui/icons-material';
import { Button, Card, CardContent, CardHeader, Divider, Grid, Link, Skeleton, Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { APP_CONFIG } from '../../../config/app';
import {
  NEXT_MAINTENANCE_STATUS_MAPPING,
  NEXT_STATUS_MAPPING,
  WORKSHOP_ACTIVITIES_WITH_REPORT,
  WorkshopActivities,
  WorkshopStatus,
} from '../../../constants/workshop';
import { ClientRoute, createPath } from '../../../routing';
import { ClientRouteGroups } from '../../../routing/clientRoutes';
import { requestSelector } from '../../../selectors/request';
import { currentUserSelector, isAuthorizedSelector } from '../../../selectors/user';
import {
  workshopInboundActivitySelector,
  workshopInboundMaintenanceActivitySelector,
} from '../../../selectors/workshop-inbound/workshop-inbound.selector';
import { useAppDispatch, useAppSelector } from '../../../state';
import {
  getMaintenanceActivityStatusAction,
  getWorkshopInboundActivityAction,
  updateWorkshopInboundActivityAction,
} from '../../../state/ducks/workshopInbound/actions';
import { formatSeconds, formatUTCString, formatUTCToLocal } from '../../../utils/datetime';
import { text2title } from '../../../utils/textUtils';
import StatusBadge from '../../components/badges/StatusBadge';
import ActionStatusSnackbar from '../../components/snackbars/ActionStatusSnackbar';
import SimpleTable, { SimpleTableColDef } from '../../components/table/SimpleTable';
import CreateReworkActivityModal from './components/CreateReworkActivityModal';
import WorkshopInboundItemCard from './components/WorkshopInboundItemCard';
import WorkshopInboundReportCard from './components/WorkshopInboundReportCard';
import WorkshopInboundReportForm from './components/WorkshopInboundReportForm';

const historyColumns: SimpleTableColDef[] = [
  {
    id: 'common_labels_number',
    headerName: 'No.',
    field: 'revision_number',
    width: 50,
    sortable: false,
    filterable: false,
  },
  {
    id: 'common_labels_status',
    headerName: 'Status',
    field: 'status',
    minWidth: 150,
    sortable: false,
    filterable: false,
    renderCell: (params: GridRenderCellParams) => <StatusBadge status={params.value} />,
  },
  {
    id: 'workshop_labels_assignee',
    headerName: 'Assignee',
    field: 'assignee',
    minWidth: 150,
    sortable: false,
    filterable: false,
  },
  {
    id: 'workshop_labels_lastTimestamp',
    headerName: 'Last Timestamp',
    field: 'changed_at',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => formatUTCToLocal(params.value),
  },
  {
    id: 'common_labels_updatedBy',
    headerName: 'Updated By',
    field: 'updated_by',
    minWidth: 150,
    sortable: false,
    filterable: false,
  },
];

const WorkshopInboundActivityDetails: React.FC = () => {
  const { reference } = useParams<{ reference: string }>();
  const workshopActivity = useAppSelector(workshopInboundActivitySelector);
  const updateActivityRequest = useAppSelector((state) =>
    requestSelector(state, updateWorkshopInboundActivityAction.typePrefix),
  );
  const dispatch = useAppDispatch();
  const maintenanceActivity = useAppSelector(workshopInboundMaintenanceActivitySelector);
  const currentUser = useAppSelector(currentUserSelector);
  const intl = useIntl();
  const [showReportForm, setShowReportForm] = useState(false);
  const [showReworkModal, setShowReworkModal] = useState(false);
  const [showUpdateActivitySnackbar, setShowUpdateActivitySnackbar] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const userCanEdit =
    useAppSelector((state) =>
      isAuthorizedSelector(
        state,
        ClientRouteGroups.WORKSHOP_INBOUND_ITEM.filter((g) => g.startsWith('Workshop')),
      ),
    ) || !APP_CONFIG.isProd;

  const fetchItem = () => {
    dispatch(getWorkshopInboundActivityAction(reference));
  };

  useEffect(() => {
    fetchItem();
  }, []);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (workshopActivity && workshopActivity.status === 'in_progress') {
        const activityLastChangedAt =
          workshopActivity.activity_history?.[workshopActivity?.activity_history?.length - 1]?.changed_at;
        setElapsedTime(Math.floor((Date.now() - new Date(formatUTCString(activityLastChangedAt)).getTime()) / 1000));
      } else if (workshopActivity && workshopActivity.status !== 'in_progress') {
        setElapsedTime(0);
      }
    }, 1000);
    return () => clearInterval(countdownInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workshopActivity?.status]);

  if (!workshopActivity) {
    return (
      <Stack spacing={2}>
        <Skeleton variant='rounded' height={250} />
        <Skeleton variant='rounded' height={215} />
        <Skeleton />
        <Skeleton variant='rounded' height={100} />
      </Stack>
    );
  }

  const NEXT_STATUS_LIST =
    workshopActivity.activity_type === WorkshopActivities.maintenance
      ? NEXT_MAINTENANCE_STATUS_MAPPING
      : NEXT_STATUS_MAPPING;

  return (
    <>
      {showUpdateActivitySnackbar && (
        <ActionStatusSnackbar actionName={updateWorkshopInboundActivityAction.typePrefix} />
      )}
      {showReportForm && (
        <WorkshopInboundReportForm
          activity={workshopActivity}
          show={showReportForm}
          handleToggleModal={() => setShowReportForm(false)}
          onSuccess={fetchItem}
        />
      )}
      {showReworkModal && (
        <CreateReworkActivityModal
          show={showReworkModal}
          handleToggleModal={() => setShowReworkModal(false)}
          service={workshopActivity.service}
          onSuccess={fetchItem}
        />
      )}
      <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={12} md={12}>
          <Card>
            <CardHeader
              title={
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} justifyContent='space-between'>
                  <Typography variant='h5'>
                    <FormattedMessage
                      id='workshopInbound_details_cardTitles_activity'
                      defaultMessage='Details for activity {reference}'
                      values={{
                        reference: `${reference} - ${intl.formatMessage({
                          id: `workshop_serviceActivityTypes_${workshopActivity.activity_type}`,
                          defaultMessage: text2title(workshopActivity.activity_type),
                        })}`,
                      }}
                    />
                  </Typography>
                  <Stack direction='column' spacing={1}>
                    {!workshopActivity?.activity_report &&
                      WORKSHOP_ACTIVITIES_WITH_REPORT.includes(workshopActivity.activity_type) && (
                        <Button
                          key='create_report'
                          startIcon={<Add />}
                          variant='outlined'
                          color='success'
                          disabled={!userCanEdit}
                          onClick={() => setShowReportForm(true)}>
                          <FormattedMessage id='workshopInbound_actions_createReport' defaultMessage='Create Report' />
                        </Button>
                      )}
                    {workshopActivity.activity_type === WorkshopActivities.endControl &&
                      !workshopActivity.rework_activity_id && (
                        <Button
                          key='create_rework'
                          startIcon={<PriorityHigh />}
                          variant='outlined'
                          color='error'
                          disabled={!userCanEdit}
                          onClick={() => setShowReworkModal(true)}>
                          <FormattedMessage id='workshopInbound_actions_createRework' defaultMessage='Rework needed' />
                        </Button>
                      )}
                    {workshopActivity.activity_type === WorkshopActivities.endControl &&
                      workshopActivity.rework_activity_id && (
                        <Button
                          key='rework_activity'
                          component={Link}
                          href={createPath({
                            path: ClientRoute.WORKSHOP_INBOUND_ACTIVITY,
                            params: { reference: workshopActivity.rework_activity_id.toString() },
                          })}>
                          <FormattedMessage id='workshop_serviceActivityTypes_rework' defaultMessage='Rework' />
                        </Button>
                      )}
                  </Stack>
                </Stack>
              }
            />
            <CardContent>
              <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-between' spacing={1}>
                <Stack direction='column' spacing={1}>
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='common_labels_status' defaultMessage='Status' />:
                    </Typography>
                    <StatusBadge status={workshopActivity.status} />
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='workshop_labels_assignee' defaultMessage='Assignee' />:
                    </Typography>
                    <Typography>{workshopActivity.assignee}</Typography>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='workshop_labels_activity' defaultMessage='Activity' />:
                    </Typography>
                    <Typography>
                      <FormattedMessage
                        id={`workshop_serviceActivityTypes_${workshopActivity.activity_type}`}
                        defaultMessage={text2title(workshopActivity.activity_type)}
                      />
                    </Typography>
                  </Stack>
                  {workshopActivity.activity_type === WorkshopActivities.rework &&
                    !!workshopActivity.rework_reasons?.length && (
                      <Stack direction='row' spacing={2}>
                        <Typography fontWeight='bold'>
                          <FormattedMessage id='workshopInbound_labels_reworkReasons' defaultMessage='Rework Reasons' />
                          :
                        </Typography>
                        <Typography>
                          {workshopActivity.rework_reasons?.map((reason, index) => (
                            <>
                              {index > 0 && ', '}
                              <FormattedMessage
                                key={reason}
                                id='workshop_reworkReasons_{reason}'
                                defaultMessage={text2title(reason)}
                              />
                            </>
                          ))}
                        </Typography>
                      </Stack>
                    )}
                  <Stack direction='row' spacing={2}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='workshop_labels_service' defaultMessage='Service' />:
                    </Typography>
                    <Typography>
                      {workshopActivity.service?.service_type && (
                        <FormattedMessage
                          id={`workshop_serviceActivityTypes_${workshopActivity.service?.service_type}`}
                          defaultMessage={text2title(workshopActivity.service?.service_type)}
                        />
                      )}
                    </Typography>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='common_labels_elapsedTime' defaultMessage='Elapsed Time' />:
                    </Typography>
                    <Typography>
                      {workshopActivity.status === 'in_progress'
                        ? formatSeconds(workshopActivity.elapsed_time + elapsedTime, 'dd:hh:mm:ss')
                        : formatSeconds(workshopActivity.elapsed_time, 'dd:hh:mm:ss')}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction='column' spacing={1}>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    {(!workshopActivity.assignee || workshopActivity.assignee === currentUser.username) &&
                      NEXT_STATUS_LIST?.[workshopActivity.status]?.map((nextStatus) => (
                        <StatusBadge
                          key={nextStatus}
                          status={nextStatus}
                          onClick={() => {
                            dispatch(
                              updateWorkshopInboundActivityAction({
                                reference: workshopActivity.id.toString(),
                                requestBody: {
                                  status: nextStatus,
                                  assignee: currentUser?.username,
                                  updated_by: currentUser?.username,
                                },
                              }),
                            ).then((response) => {
                              setShowUpdateActivitySnackbar(true);
                              if (response?.payload) {
                                fetchItem();
                                if (workshopActivity.id === maintenanceActivity?.id) {
                                  dispatch(getMaintenanceActivityStatusAction({ assignee: currentUser.username }));
                                }
                              }
                            });
                          }}
                          loading={updateActivityRequest?.status === 'pending'}
                          disabled={
                            !userCanEdit ||
                            maintenanceActivity?.status === WorkshopStatus.in_progress ||
                            (nextStatus === WorkshopStatus.complete &&
                              WORKSHOP_ACTIVITIES_WITH_REPORT.includes(workshopActivity.activity_type) &&
                              !workshopActivity?.activity_report)
                          }
                        />
                      ))}
                  </Stack>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        {workshopActivity.activity_type !== WorkshopActivities.maintenance && (
          <Grid item xs={4} sm={12} md={12}>
            <WorkshopInboundItemCard
              item={workshopActivity.watch}
              userCanEdit={userCanEdit}
              actionButtons={[
                <Button
                  color='info'
                  variant='text'
                  key='item_details'
                  href={createPath({
                    path: ClientRoute.WORKSHOP_INBOUND_ITEM,
                    params: { reference: workshopActivity.watch.id.toString() },
                  })}>
                  <FormattedMessage id='common_details' defaultMessage='Details' />
                </Button>,
              ]}
            />
          </Grid>
        )}
        {workshopActivity.activity_report && (
          <Grid item xs={4} sm={12} md={12}>
            <WorkshopInboundReportCard activity={workshopActivity} onSuccess={fetchItem} />
          </Grid>
        )}
        <Grid item={true} xs={4} sm={12} md={12}>
          <Divider variant='middle' sx={{ padding: '15px 0' }}>
            <Typography variant='h6'>
              <FormattedMessage id='common_labels_history' defaultMessage='History' />
            </Typography>
          </Divider>
          <SimpleTable
            rows={workshopActivity.activity_history}
            columns={historyColumns}
            density='compact'
            autoHeight
            sx={{ flex: 1 }}
            hideFooter
          />
        </Grid>
      </Grid>
    </>
  );
};

export default WorkshopInboundActivityDetails;
