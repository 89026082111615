export enum InventoryActions {
  getProducts = 'inventory/getProducts',
  getProduct = 'inventory/getProduct',
  getVariants = 'inventory/getVariants',
  getInventoryItem = 'inventory/getInventoryItem',
}

export type Location = {
  reference: string;
  name: string;
};

export type Product = {
  reference: string;
  description: string;
};

export type Variant = {
  reference: string; // sku
  code: string;
  product: Product;
};

export type InventoryItem = {
  reference: string;
  serialNumber: string;
  variant: Variant;
  location: Location;
};

export type ProductsState = {
  count: number;
  items: Product[];
};

export type VariantsState = {
  count: number;
  items: Variant[];
};

export type InventoryItemsState = {
  count: number;
  items: InventoryItem[];
};

export type InventoryState = {
  products: ProductsState;
  product: Product | null;
  variants: VariantsState;
  variant: Variant | null;
  inventoryItems: InventoryItemsState;
  inventoryItem: InventoryItem | null;
};
