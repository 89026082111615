import { PHONE_CODES } from '@chronext/react-common';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { isWeekend } from 'date-fns';
import deLocale from 'date-fns/locale/de';
import { electronicFormatIBAN, friendlyFormatIBAN, isValidIBAN } from 'ibantools';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { FormattedMessage } from 'react-intl';
import { UpdateSellRequestVendorInformationParams } from '../../../../api/server/sell';
import { COUNTRY_CODES } from '../../../../constants/countryCodes';
import { requestSelector } from '../../../../selectors/request';
import { currentUserSelector } from '../../../../selectors/user';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { updateSellRequestVendorInformationAction } from '../../../../state/ducks/sell/actions';
import { SellRequest } from '../../../../state/ducks/sell/types';
import { getCountryNameFromAlpha2 } from '../../../../utils/countryUtils';
import { text2title } from '../../../../utils/textUtils';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  sellRequest: SellRequest;
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
};

const LABEL_MAP: Record<string, string> = {
  bankHolderName: 'accountHolder',
  phone: 'phoneNumber',
};

const initialFormData: Record<string, any> = {
  country: '',
  bankHolderName: '',
  bankName: '',
  iban: '',
  pickup_salutation: '',
  pickup_firstName: '',
  pickup_lastName: '',
  pickup_country: '',
  pickup_city: '',
  pickup_streetName: '',
  pickup_streetNumber: '',
  pickup_additionalStreetInfo: '',
  pickup_postalCode: '',
  pickup_phone: '',
  pickup_state: '',
  pickup_company: '',
  pickup_pickupDate: null,
  billing_salutation: '',
  billing_firstName: '',
  billing_lastName: '',
  billing_country: '',
  billing_city: '',
  billing_streetName: '',
  billing_streetNumber: '',
  billing_additionalStreetInfo: '',
  billing_postalCode: '',
  billing_phone: '',
  billing_state: '',
  billing_company: '',
  differentBillingAddress: false,
};

const UpdateVendorInformationModal: React.FC<Props> = ({ show, handleToggleModal, sellRequest, onSuccess }) => {
  const dispatch = useAppDispatch();
  const { language } = useAppSelector(currentUserSelector);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const request = useAppSelector((state) =>
    requestSelector(state, updateSellRequestVendorInformationAction.typePrefix),
  );
  const [formData, setFormData] = useState(initialFormData);
  const [ibanError, setIbanError] = useState(false);

  const handleCountryChange = (event: SelectChangeEvent) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleDateChange = (newDate: Date | null | unknown) => {
    const date = newDate as Date;
    date.setHours(0, 0, 0, 0);
    setFormData({ ...formData, pickup_pickupDate: date });
  };

  const handleIbanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newIban = electronicFormatIBAN(event.target?.value || '') as string;
    setFormData({ ...formData, iban: newIban });
    newIban === '' ? setIbanError(false) : setIbanError(!isValidIBAN(newIban));
  };

  const saveItem = () => {
    const pickupAddress = {
      salutation: formData.pickup_salutation,
      firstName: formData.pickup_firstName,
      lastName: formData.pickup_lastName,
      country: formData.pickup_country,
      city: formData.pickup_city,
      streetName: formData.pickup_streetName,
      streetNumber: formData.pickup_streetNumber,
      additionalStreetInfo: formData.pickup_additionalStreetInfo,
      postalCode: formData.pickup_postalCode,
      phone: formData.pickup_phone,
      state: formData.pickup_state,
      company: formData.pickup_company,
    };

    let requestBody: UpdateSellRequestVendorInformationParams = {
      country: formData.country,
    };
    if (sellRequest.offerReply?.accept && !sellRequest.sentToERP && !sellRequest.seller?.addresses?.length) {
      requestBody = {
        ...requestBody,
        paymentDetails: {
          srId: sellRequest.sellRequestId,
          bankHolderName: formData.bankHolderName,
          bankName: formData.bankName || '',
          iban: formData.iban,
          pickupAddress: {
            ...pickupAddress,
            pickupDate: formData?.pickup_pickupDate ? new Date(formData?.pickup_pickupDate || '')?.toISOString() : '',
          },
          billingAddress: formData.differentBillingAddress
            ? {
                salutation: formData.billing_salutation,
                firstName: formData.billing_firstName,
                lastName: formData.billing_lastName,
                country: formData.billing_country,
                city: formData.billing_city,
                streetName: formData.billing_streetName,
                streetNumber: formData.billing_streetNumber,
                additionalStreetInfo: formData.billing_additionalStreetInfo,
                postalCode: formData.billing_postalCode,
                phone: formData.billing_phone,
                state: formData.billing_state,
                company: formData.billing_company,
              }
            : pickupAddress,
        },
      };
    }

    const isFullUpdate =
      sellRequest.offerReply?.accept && !sellRequest.sentToERP && !sellRequest.seller?.addresses?.length;

    dispatch(
      updateSellRequestVendorInformationAction({
        reference: sellRequest.reference.toString(),
        requestBody,
      }),
    ).then((response) => {
      if (response.type === updateSellRequestVendorInformationAction.fulfilled.type) {
        setShowSnackbar(true);
        handleToggleModal();
        onSuccess();
      }
    });
  };

  const clearForm = () => {
    setFormData(initialFormData);
  };

  useEffect(() => {
    if (show) {
      if (sellRequest.offerReply?.accept && !sellRequest.sentToERP && !sellRequest.seller?.addresses?.length) {
        const prevPickupAddress = sellRequest.seller.addresses.find((address) => address.pickup);
        const prevBillingAddress = sellRequest.seller.addresses.find((address) => !address.pickup);
        setFormData({
          ...formData,
          differentBillingAddress: !!prevBillingAddress,
          country: sellRequest.seller.country,
          iban: sellRequest.seller?.iban,
          bankName: sellRequest.seller?.bankName,
          bankHolderName: sellRequest.seller?.bankHolderName,
          pickup_salutation: prevPickupAddress?.salutation || '',
          pickup_firstName: prevPickupAddress?.first_name || '',
          pickup_lastName: prevPickupAddress?.last_name || '',
          pickup_country: prevPickupAddress?.country || sellRequest.seller.country || '',
          pickup_city: prevPickupAddress?.city || '',
          pickup_streetName: prevPickupAddress?.street || '',
          pickup_streetNumber: prevPickupAddress?.street_number.toString() || '',
          pickup_additionalStreetInfo: prevPickupAddress?.street_info || '',
          pickup_postalCode: prevPickupAddress?.postal_code || '',
          pickup_phone: prevPickupAddress?.phone || '',
          pickup_state: prevPickupAddress?.state || '',
          pickup_company: prevPickupAddress?.company || '',
          pickup_pickupDate: sellRequest?.pickupDate
            ? new Date(sellRequest?.pickupDate)
            : new Date().setHours(0, 0, 0, 0),
          billing_salutation: prevBillingAddress?.salutation || '',
          billing_firstName: prevBillingAddress?.first_name || '',
          billing_lastName: prevBillingAddress?.last_name || '',
          billing_country: prevBillingAddress?.country || sellRequest.seller.country || '',
          billing_city: prevBillingAddress?.city || '',
          billing_streetName: prevBillingAddress?.street || '',
          billing_streetNumber: prevBillingAddress?.street_number.toString() || '',
          billing_additionalStreetInfo: prevBillingAddress?.street_info || '',
          billing_postalCode: prevBillingAddress?.postal_code || '',
          billing_phone: prevBillingAddress?.phone || '',
          billing_state: prevBillingAddress?.state || '',
          billing_company: prevBillingAddress?.company || '',
        });
      } else {
        setFormData({ country: sellRequest.seller.country });
      }
    } else {
      clearForm();
    }
  }, [show]);

  const renderFormField = (fieldKey: string) => {
    const labelKey = fieldKey.replace(/^(pickup|billing)_/, '');
    const fieldLabel = (
      <FormattedMessage id={`common_labels_${LABEL_MAP[labelKey] || labelKey}`} defaultMessage={text2title(labelKey)} />
    );

    let maxLength = 50;
    if (['pickup_streetNumber', 'billing_streetNumber'].includes(fieldKey)) {
      maxLength = 5;
    } else if (['pickup_streetName', 'billing_streetName'].includes(fieldKey)) {
      maxLength = 44;
    }

    switch (fieldKey) {
      case 'country':
      case 'pickup_country':
      case 'billing_country':
        return (
          <FormControl sx={{ marginTop: 2 }}>
            <InputLabel id={`${fieldKey}_label`}>{fieldLabel}</InputLabel>
            <Select
              margin='dense'
              id={fieldKey}
              name={fieldKey}
              value={formData?.[fieldKey]}
              onChange={handleCountryChange}
              input={<OutlinedInput label={fieldLabel} />}
              renderValue={(selected) => (
                <Stack direction='row' spacing={1} alignItems='center'>
                  <ReactCountryFlag countryCode={selected} style={{ fontSize: '25px', lineHeight: '1em' }} />
                  <Typography>{getCountryNameFromAlpha2(selected, language)}</Typography>
                </Stack>
              )}
              labelId={`${fieldKey}_label`}
              fullWidth>
              {COUNTRY_CODES.map((countryCode) => (
                <MenuItem key={countryCode} value={countryCode}>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <ReactCountryFlag countryCode={countryCode} style={{ fontSize: '25px', lineHeight: '1em' }} />
                    <Typography>{getCountryNameFromAlpha2(countryCode, language)}</Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case 'pickup_salutation':
      case 'billing_salutation':
        return (
          <Stack direction='row' spacing={3} alignItems={'center'}>
            <Typography fontWeight='medium'>{fieldLabel}</Typography>
            <RadioGroup row={true} defaultValue='' name={fieldKey} onChange={handleChange}>
              <FormControlLabel
                value='m'
                control={<Radio />}
                label={<FormattedMessage id='common_salutation_m' defaultMessage='Mr' />}
              />
              <FormControlLabel
                value='f'
                control={<Radio />}
                label={<FormattedMessage id='common_salutation_f' defaultMessage='Ms' />}
              />
            </RadioGroup>
          </Stack>
        );
      case 'pickup_pickupDate':
        return (
          <DatePicker
            sx={{
              marginTop: '8px',
              marginBottom: '4px',
            }}
            label={fieldLabel}
            value={formData?.[fieldKey]}
            timezone='UTC'
            onChange={(newDate) => handleDateChange(newDate)}
            shouldDisableDate={isWeekend}
            minDate={new Date().setDate(new Date().getDate() + 2)}
            maxDate={new Date().setDate(new Date().getDate() + 14)}
          />
        );
      case 'iban':
        return (
          <TextField
            type='text'
            margin='dense'
            name={fieldKey}
            value={friendlyFormatIBAN(formData?.[fieldKey])}
            onChange={handleIbanChange}
            label={fieldLabel}
            error={ibanError}
            fullWidth
          />
        );
      case 'pickup_phone':
      case 'billing_phone':
        return (
          <MuiPhoneNumber
            defaultCountry={formData?.seller?.country?.toLowerCase() || 'de'}
            margin='dense'
            name={fieldKey}
            value={formData?.[fieldKey]}
            onChange={(value) => setFormData({ ...formData, [fieldKey]: value })}
            label={fieldLabel}
            fullWidth
            countryCodeEditable={false}
            onlyCountries={Object.keys(PHONE_CODES).map((c) => c.toLowerCase())}
            disableAreaCodes
            variant='outlined'
          />
        );
      default:
        return (
          <TextField
            type='text'
            margin='dense'
            name={fieldKey}
            value={formData?.[fieldKey]}
            onChange={handleChange}
            label={fieldLabel}
            fullWidth
            inputProps={{ maxLength }}
          />
        );
    }
  };

  return (
    <>
      {showSnackbar && (
        <ActionStatusSnackbar
          actionName={updateSellRequestVendorInformationAction.typePrefix}
          config={{ messageValues: { reference: sellRequest.sellRequestId || sellRequest.reference } }}
        />
      )}
      <Dialog open={show} onClose={handleToggleModal}>
        <DialogTitle>
          <FormattedMessage
            id='sellRequests_updateVendorInformationModal_title'
            defaultMessage='Update Vendor Information for Sell Request {reference}'
            values={{ reference: sellRequest.sellRequestId || sellRequest.reference }}
          />
        </DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
            <Stack spacing={1} mt={2}>
              <Paper sx={{ padding: 2 }}>
                <Stack spacing={1}>
                  <Typography fontWeight='medium'>
                    <FormattedMessage id='sellRequests_labels_vendor' />
                  </Typography>
                  {Object.keys(formData)
                    .filter((field) => !field.match(/^((pickup|billing)_|differentBillingAddress)/))
                    .map((key) => (
                      <div key={key}>{renderFormField(key)}</div>
                    ))}
                </Stack>
              </Paper>
              {sellRequest.offerReply?.accept && !sellRequest.sentToERP && !sellRequest.seller?.addresses?.length && (
                <>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='pickup-content' id='pickup-header'>
                      <Typography fontWeight='medium'>
                        <FormattedMessage id='address_display_pickup' />
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {Object.keys(formData)
                        .filter((field) => field.startsWith('pickup_'))
                        .map((key) => (
                          <div key={key}>{renderFormField(key)}</div>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.differentBillingAddress}
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            differentBillingAddress: event.target.checked,
                          })
                        }
                        name='differentBillingAddress'
                      />
                    }
                    label={
                      <FormattedMessage
                        id='common_labels_differentBillingAddress'
                        defaultMessage='Different Billing Address'
                      />
                    }
                  />
                  {formData.differentBillingAddress && (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='billing-content'
                        id='billing-header'>
                        <Typography fontWeight='medium'>
                          <FormattedMessage id='address_display_billing' />
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {Object.keys(formData)
                          .filter((field) => field.startsWith('billing_'))
                          .map((key) => (
                            <div key={key}>{renderFormField(key)}</div>
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  )}
                </>
              )}
            </Stack>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleModal}>
            <FormattedMessage id='common_close' defaultMessage='Close' />
          </Button>
          <Button color='info' onClick={saveItem} disabled={request.status === 'pending'}>
            <FormattedMessage id='common_save' defaultMessage='Save' />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default UpdateVendorInformationModal;
