import { GridFilterModel, GridLogicOperator, GridRowParams } from '@mui/x-data-grid';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TrackingReportType } from '../../../api/server/tracking';
import { ClientRoute, createPath } from '../../../routing';
import { useAppSelector } from '../../../state';
import { getTrackingItemsPurchasesAction } from '../../../state/ducks/tracking/actions';
import ActionStatusSnackbar from '../../components/snackbars/ActionStatusSnackbar';
import ServerSidePaginatedTable from '../../components/table/ServerSidePaginatedTable';
import { SimpleTableColDef } from '../../components/table/SimpleTable';
import { TRACKING_FILTER_QUERY_MAP, TRACKING_TABLE_COLUMNS } from './components/trackingTableHelper';
import TrackingTableToolbar from './components/TrackingTableToolbar';
import styles from './Tracking.module.scss';

const tableColumns: SimpleTableColDef[] = [
  {
    id: 'tracking_labels_purchaseOrderId',
    headerName: 'Purchase Order ID',
    field: 'purchaseOrderId',
    minWidth: 150,
    sortable: false,
  },
  {
    id: 'common_labels_serialNumber',
    headerName: 'Inventory ID',
    field: 'inventoryId',
    minWidth: 150,
    sortable: false,
  },
  ...TRACKING_TABLE_COLUMNS,
];

const TrackingPurchases: React.FC = () => {
  const trackingItems = useAppSelector((store) => store.tracking.trackingItemsPurchases);
  const totalCount = useAppSelector((store) => store.tracking.countPurchases);
  const history = useHistory();
  const [searchParams, setSearchParams] = useState({});

  const handleFilterModelChange = useCallback((filterModel: GridFilterModel) => {
    const filters: Record<string, any> = { tracking_id: '', purchase_order_id: '', inventory_id: '', status: '' }; // Reset all filters
    filterModel?.items?.forEach((item) => {
      const field = item.field;
      const fieldKey = TRACKING_FILTER_QUERY_MAP[field] || '';
      const value = item.value;
      if (fieldKey && value) {
        filters[fieldKey] = value;
      }
    });
    setSearchParams({ ...searchParams, ...filters });
  }, []);

  return (
    <div className={styles.wrapper}>
      <ActionStatusSnackbar actionName={getTrackingItemsPurchasesAction.typePrefix} />
      <ServerSidePaginatedTable
        rows={trackingItems}
        columns={tableColumns}
        totalCount={totalCount}
        fetchDataEffect={getTrackingItemsPurchasesAction}
        searchParams={searchParams}
        onRowClick={(params: GridRowParams) => {
          const path = createPath({
            path: ClientRoute.TRACKING_PURCHASES_DETAILS,
            params: { reference: params.row.purchaseOrderId },
          });
          history.push(path);
        }}
        slots={{
          toolbar: TrackingTableToolbar,
        }}
        slotProps={{
          toolbar: { reportType: TrackingReportType.PURCHASES },
          filterPanel: {
            logicOperators: [GridLogicOperator.And],
          },
        }}
        filterMode='server'
        onFilterModelChange={handleFilterModelChange}
      />
    </div>
  );
};

export default TrackingPurchases;
