import { Box, Button, Card, CardActions, CardContent, CardMedia, Divider, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { createPath, ExternalRoute } from '../../../routing';
import { inventoryProductSelector } from '../../../selectors/inventory';
import { workshopItemSelector } from '../../../selectors/workshop';
import { useAppDispatch, useAppSelector } from '../../../state';
import { getProductAction } from '../../../state/ducks/inventory/actions';
import { Product, Variant } from '../../../state/ducks/inventory/types';
import { getWorkshopItemAction, updateWorkshopItemAction } from '../../../state/ducks/workshop/actions';
import WorkshopItemBookingHistoryTable from '../../components/workshop-tables/WorkshopItemBookingHistoryTable';
import WorkshopItemProcessesTable from '../../components/workshop-tables/WorkshopItemProcessesTable';
import ProductFilter from '../../pages/inventory/components/ProductFilter';
import VariantFilter from '../../pages/inventory/components/VariantFilter';

type Params = {
  [key: string]: string;
};

const imageHeight = 300;
const imageWidth = 400;
const tableHeight = 400;

const WorkshopInventoryItem: React.FC = () => {
  const { reference } = useParams<Params>();
  const dispatch = useAppDispatch();
  const workshopItem = useAppSelector(workshopItemSelector);
  const product = useAppSelector(inventoryProductSelector);

  // product and variant from the select fields
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [selectedVariant, setSelectedVariant] = useState<Variant>();
  const [imageURL, setImageURL] = useState('');

  useEffect(() => {
    dispatch(getWorkshopItemAction(reference)).then(() => {
      if (!workshopItem) {
        return;
      }

      // Get the product description
      workshopItem?.productReference && dispatch(getProductAction(workshopItem.productReference));

      // Create an image URL from the variant code
      const { variantReference, variantCode } = workshopItem;
      if (variantCode || variantReference) {
        let code = variantCode || '';
        if (!code && variantReference) {
          const i = workshopItem.variantReference.search(/_/);
          code = i >= 0 ? variantReference.substring(i + 1) : code;
        }
        const path = createPath({
          path: ExternalRoute.WATCH_IMAGE_URL,
          params: {
            variantCodeFirstLetter: code[0],
            variantCodeSecondLetter: code[1],
            variantCode: code,
          },
        });
        setImageURL(path);
      }
    });
  }, [reference]);

  const onUpdateWorkshopItemClick = () => {
    if (!workshopItem) {
      return;
    }
    dispatch(
      updateWorkshopItemAction({
        serialNumber: workshopItem.serialNumber,
        productReference: selectedProduct?.reference || workshopItem.productReference,
        variantCode: selectedVariant?.code || workshopItem.variantCode,
      }),
    );
  };

  return (
    <Stack spacing={1} sx={{ position: 'relative', height: '100%' }}>
      <Stack direction='row' spacing={2}>
        <Card>
          <CardMedia
            sx={{
              width: imageWidth,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            component='img'
            height={imageHeight}
            image={
              imageURL +
              `?h=${imageHeight}&w=${imageWidth}&auto=format&fm=png&q=55&usm=50&usmrad=1.5&dpr=2&fit=clamp&auto=compress`
            }
            alt={workshopItem?.reference}
          />
          <CardContent>
            <Stack direction='row' spacing={3} sx={{ marginTop: '15px' }}>
              <Box sx={{ paddingY: '15px' }}>
                <Typography gutterBottom variant='h5' component='div'>
                  {workshopItem?.reference}
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  {workshopItem?.variantReference}
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  {product?.description}
                </Typography>
              </Box>
              <Stack spacing={1} sx={{ justifyContent: 'flex-end' }}>
                <ProductFilter onSelectChange={setSelectedProduct} />
                <VariantFilter onSelectChange={setSelectedVariant} productReference={selectedProduct?.reference} />
              </Stack>
            </Stack>
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button size='small' color='primary' variant='outlined' onClick={onUpdateWorkshopItemClick}>
              <FormattedMessage id='workshop_inventory_updateItem' defaultMessage='Update Item' />
            </Button>
          </CardActions>
        </Card>
      </Stack>
      <Box sx={{ paddingTop: '30px', paddingBottom: '15px' }}>
        <Divider variant='middle'>
          <Typography variant='h6'>
            <FormattedMessage id='workshop_processes_title' defaultMessage='Workshop Processes' />
          </Typography>
        </Divider>
      </Box>
      <Box sx={{ minHeight: tableHeight }}>
        <WorkshopItemProcessesTable />
      </Box>
      <Box sx={{ paddingTop: '30px', paddingBottom: '15px' }}>
        <Divider variant='middle'>
          <Typography variant='h6'>
            <FormattedMessage id='workshop_bookingHistory_title' defaultMessage='Booking History' />
          </Typography>
        </Divider>
      </Box>
      <Box sx={{ minHeight: tableHeight }}>
        <WorkshopItemBookingHistoryTable />
      </Box>
    </Stack>
  );
};

export default WorkshopInventoryItem;
