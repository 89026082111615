export enum AftershipStatus {
  InfoReceived = 'InfoReceived',
  InTransit = 'InTransit',
  OutForDelivery = 'OutForDelivery',
  AttemptFail = 'AttemptFail',
  Delivered = 'Delivered',
  AvailableForPickup = 'AvailableForPickup',
  Exception = 'Exception',
  Expired = 'Expired',
  Pending = 'Pending',
}

export const CARRIERS: Record<string, string> = {
  ups: 'UPS',
  fedex: 'FedEx',
  ferrari: 'Ferrari Group',
  opc: 'OPC',
  pu_dropoff: 'PU Dropoff',
};
