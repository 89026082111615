import { createReducer } from '@reduxjs/toolkit';
import {
  getMaintenanceActivityStatusAction,
  getWorkshopInboundActivitiesAction,
  getWorkshopInboundActivityAction,
  getWorkshopInboundReportActivityAction,
  getWorkshopInboundReportFieldsAction,
  getWorkshopInboundReportItemDetailsAction,
  getWorkshopInboundReportItemOverviewAction,
  getWorkshopInboundRequestsAction,
  getWorkshopInboundWatchAction,
  getWorkshopInboundWatchesAction,
} from './actions';
import { WorkshopInboundState } from './types';

const initialState: WorkshopInboundState = {
  requests: {
    count: 0,
    items: [],
  },
  watches: {
    count: 0,
    total_value: 0,
    items: [],
  },
  watch: undefined,
  activities: {
    count: 0,
    items: [],
  },
  maintenanceActivity: undefined,
  refetchActivities: false,
  activity: undefined,
  reportFields: {},
  report: {
    metaData: {
      startDate: '',
      endDate: '',
    },
    items: [],
  },
  reportItemOverview: {
    metaData: {
      startDate: '',
      endDate: '',
    },
    items: {},
  },
  reportItemDetails: {
    metaData: {
      startDate: '',
      endDate: '',
      offset: 0,
      limit: 0,
      total: 0,
    },
    items: [],
  },
};

const workshopInboundReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getWorkshopInboundRequestsAction.fulfilled, (state, action) => {
      state.requests.items = action.payload.items;
      state.requests.count = action.payload.total || action.payload.items.length;
    })
    .addCase(getWorkshopInboundWatchesAction.fulfilled, (state, action) => {
      state.watches.items = action.payload.items;
      state.watches.count = action.payload.total || action.payload.items.length;
      state.watches.total_value = action.payload.total_value;
    })
    .addCase(getWorkshopInboundWatchAction.fulfilled, (state, action) => {
      state.watch = action.payload;
    })
    .addCase(getWorkshopInboundWatchAction.pending, (state) => {
      state.watch = undefined;
    })
    .addCase(getWorkshopInboundActivitiesAction.fulfilled, (state, action) => {
      state.activities.items = action.payload.items;
      state.activities.count = action.payload.total || action.payload.items.length;
      state.refetchActivities = false;
    })
    .addCase(getMaintenanceActivityStatusAction.fulfilled, (state, action) => {
      state.maintenanceActivity = action.payload;
      state.refetchActivities = true;
    })
    .addCase(getWorkshopInboundActivityAction.fulfilled, (state, action) => {
      state.activity = action.payload;
    })
    .addCase(getWorkshopInboundActivityAction.pending, (state) => {
      state.activity = undefined;
    })
    .addCase(getWorkshopInboundReportFieldsAction.fulfilled, (state, action) => {
      state.reportFields = action.payload;
    })
    .addCase(getWorkshopInboundReportActivityAction.fulfilled, (state, action) => {
      state.report = action.payload;
    })
    .addCase(getWorkshopInboundReportActivityAction.rejected, (state) => {
      state.report = initialState.report;
    })
    .addCase(getWorkshopInboundReportItemOverviewAction.fulfilled, (state, action) => {
      state.reportItemOverview = action.payload;
    })
    .addCase(getWorkshopInboundReportItemOverviewAction.rejected, (state) => {
      state.reportItemOverview = initialState.reportItemOverview;
    })
    .addCase(getWorkshopInboundReportItemDetailsAction.fulfilled, (state, action) => {
      state.reportItemDetails = action.payload;
    })
    .addCase(getWorkshopInboundReportItemDetailsAction.rejected, (state) => {
      state.reportItemDetails = initialState.reportItemDetails;
    });
});

export default workshopInboundReducer;
