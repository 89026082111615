import { TabContext, TabPanel } from '@mui/lab';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink, useParams } from 'react-router-dom';
import { WorkshopReportType } from '../../../constants/workshop';
import { ClientRoute, createPath } from '../../../routing';
import WorkshopInboundReportItemOverview from './components/WorkshopInboundReportItemOverview';
import WorkshopInboundReportOverviewTable from './components/WorkshopInboundReportOverviewTable';

type Params = {
  [key: string]: string;
};

const tabProps = {
  sx: {
    flex: 1,
    padding: 0,
  },
};

const WorkshopInboundDashboard: React.FC = () => {
  const { tab } = useParams<Params>();

  return (
    <TabContext value={tab}>
      <Stack sx={{ height: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 3 }}>
          <Tabs selectionFollowsFocus value={tab}>
            <Tab
              value='assignee'
              component={NavLink}
              to={createPath({ path: ClientRoute.WORKSHOP_INBOUND_DASHBOARD_TABS, params: { tab: 'assignee' } })}
              label={<FormattedMessage id='workshopInbound_report_tabs_assignee' defaultMessage='By Assignee' />}
            />
            <Tab
              value='activity'
              component={NavLink}
              to={createPath({ path: ClientRoute.WORKSHOP_INBOUND_DASHBOARD_TABS, params: { tab: 'activity' } })}
              label={<FormattedMessage id='workshopInbound_report_tabs_activity' defaultMessage='By Activity' />}
            />
            <Tab
              value='item'
              component={NavLink}
              to={createPath({ path: ClientRoute.WORKSHOP_INBOUND_DASHBOARD_TABS, params: { tab: 'item' } })}
              label={<FormattedMessage id='workshopInbound_report_tabs_item' defaultMessage='By Item' />}
            />
          </Tabs>
        </Box>
        <TabPanel value='assignee' {...tabProps}>
          {tab === 'assignee' && (
            <WorkshopInboundReportOverviewTable reportType={WorkshopReportType.assignee_overview} />
          )}
        </TabPanel>
        <TabPanel value='activity' {...tabProps}>
          {tab === 'activity' && (
            <WorkshopInboundReportOverviewTable reportType={WorkshopReportType.activity_overview} />
          )}
        </TabPanel>
        <TabPanel value='item' {...tabProps}>
          {tab === 'item' && <WorkshopInboundReportItemOverview />}
        </TabPanel>
      </Stack>
    </TabContext>
  );
};

export default WorkshopInboundDashboard;
