export enum CommercetoolsActions {
  getCategories = 'commercetools/getCategories',
  clearCategory = 'commercetools/clearCategory',
  createCategory = 'commercetools/createCategory',
  deleteCategory = 'commercetools/deleteCategory',
  updateCategory = 'commercetools/updateCategory',
  getCategoryProductCount = 'commercetools/getCategoryProductCount',
  getDiscountCodes = 'commercetools/getDiscountCodes',
  createDiscountCode = 'commercetools/createDiscountCode',
  deleteDiscountCode = 'commercetools/deleteDiscountCode',
  updateDiscountCode = 'commercetools/updateDiscountCode',
  getDiscountBatchJobs = 'commercetools/getDiscountBatchJobs',
  getDiscountBatchJob = 'commercetools/getDiscountBatchJob',
  downloadBatchJobDiscountCodes = 'commercetools/downloadBatchJobDiscountCodes',
  createBatchJob = 'commercetools/createBatchJob',
}

export type CommercetoolsCategoryName = {
  en: string;
  de?: string;
};

export type CommercetoolsCategory = {
  id: string;
  key: string;
  name: CommercetoolsCategoryName;
  createdAt: string;
  modifiedAt: string;
  count?: number;
};

export type CommercetoolsCategories = CommercetoolsCategory[];

export type CommercetoolsDiscountCode = {
  id: string;
  code: string;
  isActive: boolean;
  discounts: Record<string, string>;
  currencies: string[];
  countries: string[];
  groups: string[];
  categories: string[];
  validFrom: string;
  validUntil: string;
  stackingMode: string;
  maxApplications?: number;
  createdBy: string;
  isPercentValue?: boolean;
};

export type CommercetoolsDiscountCodes = CommercetoolsDiscountCode[];

type CommercetoolsDiscountBatchJobPrototype = Omit<CommercetoolsDiscountCode, 'id' | 'maxApplications' | 'isActive'> & {
  replacementCharacter: string;
  amount: number;
};

export type CommercetoolsDiscountBatchJob = {
  id: string;
  prototype: CommercetoolsDiscountBatchJobPrototype;
  createdAt: string;
  postCount?: number;
};

export type CommercetoolsDiscountBatchJobs = CommercetoolsDiscountBatchJob[];

export type CommercetoolsState = {
  categoriesTotal: number;
  categories: CommercetoolsCategories;
  categoryProductCount: number;
  discountCodesTotal: number;
  discountCodes: CommercetoolsDiscountCodes;
  discountBatchJobsTotal: number;
  discountBatchJobs: CommercetoolsDiscountBatchJobs;
  discountBatchJob: CommercetoolsDiscountBatchJob;
};
