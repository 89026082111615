import { APIRoute, createPath } from '../../routing';
import { get, post, patch, put } from '../../utils/httpUtils';

export const getWorkshopActivities = () => {
  return get(APIRoute.WORKSHOP_ACTIVITIES);
};

export const getWorkshopItems = (params: URLSearchParams | null = null) => {
  return get(APIRoute.WORKSHOP_ITEMS, params);
};

export const getWorkshopItemByReference = (reference: string) => {
  const url = createPath({
    path: APIRoute.WORKSHOP_ITEM,
    params: { reference },
  });
  return get(url);
};

export const createWorkshopItem = (body: any) => {
  return post(APIRoute.WORKSHOP_ITEMS, body);
};

export const updateWorkshopItem = (reference: string, body: any) => {
  const url = createPath({
    path: APIRoute.WORKSHOP_ITEM,
    params: { reference },
  });
  return patch(url, body);
};

export const createWorkshopProcess = (workshopItemReference: string, activityReference: string) => {
  const url = createPath({
    path: APIRoute.WORKSHOP_CREATE_PROCESS,
    params: { workshopItemReference },
  });
  return post(url, { activityReference });
};

export type UpdateWorkshopProcessParams = {
  status: string;
  elapsedTime?: number;
};
export const updateWorkshopProcess = (
  workshopItemReference: string,
  processReference: string,
  requestBody: UpdateWorkshopProcessParams,
) => {
  const url = createPath({
    path: APIRoute.WORKSHOP_UPDATE_PROCESS,
    params: { workshopItemReference, processReference },
  });
  return patch(url, requestBody);
};

export const getWorkshopReport = (params?: URLSearchParams) => {
  return get(APIRoute.WORKSHOP_REPORTS, params);
};

export const getWorkshopProcesses = (params?: URLSearchParams) => {
  return get(APIRoute.WORKSHOP_PROCESSES, params);
};

export const getWorkshopLocations = () => {
  return get(APIRoute.WORKSHOP_LOCATIONS);
};

export const createWorkshopLocation = (data: any) => {
  return post(APIRoute.WORKSHOP_LOCATIONS, data);
};

export const updateWorkshopLocation = (locationReference: number, data: any) => {
  const url = createPath({
    path: APIRoute.WORKSHOP_LOCATION_UPDATE,
    params: { locationReference },
  });
  return put(url, data);
};

export const addBookingItem = (locationReference: number, workshopItemReference: string) => {
  const url = createPath({
    path: APIRoute.WORKSHOP_BOOKING_ITEM,
    params: { locationReference, workshopItemReference },
  });
  return post(url, null);
};

export const getWorkshopLocationsOverview = (params?: URLSearchParams) => {
  return get(APIRoute.WORKSHOP_LOCATIONS_OVERVIEW, params);
};
