export enum MarginType {
  'direct' = 'direct',
  'tradeIn' = 'tradeIn',
  'commission' = 'commission',
}

export enum Condition {
  'new' = 'new',
  'aaa' = 'aaa',
  'aa' = 'aa',
  'a' = 'a',
}

export enum BraceletType {
  'oyster' = 'oyster',
  'jubilee' = 'jubilee',
  'president' = 'president',
  'other' = 'other',
}
