import { FormGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { APP_CONFIG } from '../../../../config/app';
import { GROUPS } from '../../../../constants/groups';
import { NEXT_MAINTENANCE_STATUS_MAPPING, WorkshopStatus } from '../../../../constants/workshop';
import { currentUserSelector, isAuthorizedSelector } from '../../../../selectors/user';
import {
  workshopInboundActivitySelector,
  workshopInboundMaintenanceActivitySelector,
} from '../../../../selectors/workshop-inbound/workshop-inbound.selector';
import { useAppDispatch, useAppSelector } from '../../../../state';
import {
  getMaintenanceActivityStatusAction,
  getWorkshopInboundActivityAction,
  updateWorkshopInboundActivityAction,
} from '../../../../state/ducks/workshopInbound/actions';

const MaintenanceActivityButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);

  const maintenanceActivity = useAppSelector(workshopInboundMaintenanceActivitySelector);
  const workshopActivity = useAppSelector(workshopInboundActivitySelector);

  const currentUser = useAppSelector(currentUserSelector);
  const isAuthorizedForMaintenance = useAppSelector((state) =>
    isAuthorizedSelector(state, [GROUPS.WORKSHOP_ADMIN, GROUPS.WORKSHOP_EMPLOYEE], APP_CONFIG.isProd),
  );

  const nextActivityStatus = useMemo(() => {
    return (
      (!!maintenanceActivity?.status && NEXT_MAINTENANCE_STATUS_MAPPING?.[maintenanceActivity?.status]?.[0]) ||
      WorkshopStatus.in_progress
    );
  }, [maintenanceActivity?.status]);

  const getActivityStatus = useCallback(async () => {
    if (!currentUser.username || !isAuthorizedForMaintenance) return;
    setLoading(true);
    dispatch(getMaintenanceActivityStatusAction({ assignee: currentUser.username }))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [currentUser.username, nextActivityStatus]);

  const fetchWorkshopActivity = () => {
    if (!workshopActivity?.id) return;
    dispatch(getWorkshopInboundActivityAction(workshopActivity?.id.toString()));
  };

  const updateActivityStatus = async () => {
    if (!maintenanceActivity) {
      return;
    }
    setLoading(true);
    dispatch(
      updateWorkshopInboundActivityAction({
        reference: maintenanceActivity.id.toString(),
        requestBody: {
          status: nextActivityStatus,
          updated_by: currentUser.username,
          assignee: currentUser.username,
        },
      }),
    )
      .then(() => {
        setLoading(false);
        getActivityStatus();
        fetchWorkshopActivity();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getActivityStatus();
  }, [getActivityStatus]);

  const onSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateActivityStatus();
  };

  return (
    <FormGroup
      sx={{
        paddingRight: '36px',
      }}>
      <FormControlLabel
        labelPlacement='start'
        control={
          <Switch
            disabled={loading}
            checked={maintenanceActivity?.status === WorkshopStatus.in_progress}
            onChange={onSwitchChange}
            color='success'
          />
        }
        label={<FormattedMessage id='workshopInbound_maintenanceMode' defaultMessage='Maintenance Mode' />}
      />
    </FormGroup>
  );
};

export default MaintenanceActivityButton;
