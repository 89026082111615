import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Stack,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { RequestMissingDataParams } from '../../../../api/server/sell';
import { useAppDispatch } from '../../../../state';
import { requestMissingDataAction } from '../../../../state/ducks/sell/actions';
import { SellRequest } from '../../../../state/ducks/sell/types';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

const fieldsMenuItems = [
  { value: 'watchBrand', label: <FormattedMessage id='common_labels_brand' defaultMessage='Brand' /> },
  { value: 'watchModel', label: <FormattedMessage id='common_labels_model' defaultMessage='Model' /> },
  { value: 'watchRef', label: <FormattedMessage id='common_labels_reference' defaultMessage='Reference' /> },
  { value: 'images', label: <FormattedMessage id='sellRequests_labels_images' defaultMessage='Images' /> },
  {
    value: 'paperAvailable',
    label: <FormattedMessage id='common_labels_paperAvailable' defaultMessage='Papers' />,
  },
  { value: 'boxAvailable', label: <FormattedMessage id='common_labels_boxAvailable' defaultMessage='Box' /> },
  {
    value: 'constructionYear',
    label: <FormattedMessage id='sellRequests_labels_constructionYear' defaultMessage='Year' />,
  },
  {
    value: 'watchCondition',
    label: <FormattedMessage id='common_labels_watchCondition' defaultMessage='Condition' />,
  },
];

type Props = {
  sellRequest: SellRequest;
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
};

const initialFormData: RequestMissingDataParams = {
  missingData: [],
};

const MissingDataModal: React.FC<Props> = ({ show, handleToggleModal, sellRequest, onSuccess }) => {
  const dispatch = useAppDispatch();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const handleFieldsChange = (event: SelectChangeEvent<string[]>) => {
    let newFields: string[] = [];
    if (Array.isArray(event.target.value)) {
      newFields = event.target.value;
    }
    newFields.sort(
      (a, b) =>
        fieldsMenuItems.findIndex((item) => item.value === a) - fieldsMenuItems.findIndex((item) => item.value === b),
    );
    setFormData({ ...formData, missingData: newFields });
  };

  const saveItem = () => {
    dispatch(requestMissingDataAction({ reference: sellRequest.reference.toString(), requestBody: formData })).then(
      (response) => {
        if (response.type === requestMissingDataAction.fulfilled.type) {
          setShowSnackbar(true);
          handleToggleModal();
          onSuccess();
        }
      },
    );
  };

  const clearForm = () => {
    setFormData(initialFormData);
  };

  useEffect(() => {
    clearForm();
  }, [show]);

  return (
    <>
      {showSnackbar && (
        <ActionStatusSnackbar
          actionName={requestMissingDataAction.typePrefix}
          config={{ messageValues: { reference: sellRequest.sellRequestId || sellRequest.reference } }}
        />
      )}
      <Dialog open={show} onClose={handleToggleModal}>
        <DialogTitle>
          <FormattedMessage
            id='sellRequests_missingDataModal_title'
            defaultMessage='Request Missing Data for Sell Request {reference}'
            values={{ reference: sellRequest.sellRequestId || sellRequest.reference }}
          />
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} mt={2}>
            <FormControl>
              <InputLabel id='missingDataLabel'>
                <FormattedMessage id='sellRequests_labels_missingDataFields' defaultMessage='Missing Fields' />
              </InputLabel>
              <Select
                margin='dense'
                id='missingData'
                name='missingData'
                value={formData.missingData}
                onChange={handleFieldsChange}
                multiple
                input={
                  <OutlinedInput
                    label={
                      <FormattedMessage id='sellRequests_labels_missingDataFields' defaultMessage='Missing Fields' />
                    }
                  />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((field) => (
                      <Chip key={field} label={fieldsMenuItems.find((item) => item.value === field)?.label} />
                    ))}
                  </Box>
                )}
                labelId='missingDataLabel'
                fullWidth>
                {fieldsMenuItems.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleModal}>
            <FormattedMessage id='common_close' defaultMessage='Close' />
          </Button>
          <Button color='info' onClick={saveItem} disabled={formData.missingData.length === 0}>
            <FormattedMessage id='sellRequests_missingDataModal_requestButton' defaultMessage='Request Information' />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MissingDataModal;
