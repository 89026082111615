import { get } from '../../utils/httpUtils';
import { APIRoute, createPath } from '../../routing';

export const getProducts = (params: URLSearchParams | null) => {
  return get(APIRoute.INVENTORY_PRODUCTS, params);
};

export const getProductByReference = (reference: string) => {
  const url = createPath({ path: APIRoute.INVENTORY_PRODUCT, params: { reference: reference } });
  return get(url);
};

export const getVariants = (params: URLSearchParams | null) => {
  return get(APIRoute.INVENTORY_VARIANTS, params);
};

export const getInventoryItemByReference = (reference: string) => {
  const url = createPath({ path: APIRoute.INVENTORY_ITEM, params: { reference } });
  return get(url);
};
