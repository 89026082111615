import { getGridStringOperators, GridFilterModel, GridLogicOperator, GridRowParams } from '@mui/x-data-grid';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TrackingReportType } from '../../../api/server/tracking';
import { ClientRoute, createPath } from '../../../routing';
import { useAppSelector } from '../../../state';
import { getTrackingItemsTransfersAction } from '../../../state/ducks/tracking/actions';
import ActionStatusSnackbar from '../../components/snackbars/ActionStatusSnackbar';
import ServerSidePaginatedTable from '../../components/table/ServerSidePaginatedTable';
import { SimpleTableColDef } from '../../components/table/SimpleTable';
import { TRACKING_FILTER_QUERY_MAP, TRACKING_TABLE_COLUMNS } from './components/trackingTableHelper';
import TrackingTableToolbar from './components/TrackingTableToolbar';
import styles from './Tracking.module.scss';

const tableColumns: SimpleTableColDef[] = [
  {
    id: 'tracking_labels_transferOrderId',
    headerName: 'Transfer Order ID',
    field: 'transferOrderId',
    minWidth: 150,
    sortable: false,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
  },
  ...TRACKING_TABLE_COLUMNS.filter((col) => !['shipmentId', 'invoiceId', 'paymentMethod'].includes(col.field)),
];

const TrackingTransfers: React.FC = () => {
  const trackingItems = useAppSelector((store) => store.tracking.trackingItemsTransfers);
  const totalCount = useAppSelector((store) => store.tracking.countTransfers);
  const history = useHistory();
  const [searchParams, setSearchParams] = useState({});

  const handleFilterModelChange = useCallback((filterModel: GridFilterModel) => {
    const filters: Record<string, any> = { tracking_id: '', transfer_order_id: '', status: '' }; // Reset all filters
    filterModel?.items?.forEach((item) => {
      const field = item.field;
      const fieldKey = TRACKING_FILTER_QUERY_MAP[field] || '';
      const value = item.value;
      if (fieldKey && value) {
        filters[fieldKey] = value;
      }
    });
    setSearchParams({ ...searchParams, ...filters });
  }, []);

  return (
    <div className={styles.wrapper}>
      <ActionStatusSnackbar actionName={getTrackingItemsTransfersAction.typePrefix} />
      <ServerSidePaginatedTable
        rows={trackingItems}
        columns={tableColumns}
        totalCount={totalCount}
        fetchDataEffect={getTrackingItemsTransfersAction}
        searchParams={searchParams}
        onRowClick={(params: GridRowParams) => {
          const path = createPath({
            path: ClientRoute.TRACKING_TRANSFERS_DETAILS,
            params: { reference: params.row.transferOrderId },
          });
          history.push(path);
        }}
        slots={{
          toolbar: TrackingTableToolbar,
        }}
        slotProps={{
          toolbar: { reportType: TrackingReportType.TRANSFERS },
          filterPanel: {
            logicOperators: [GridLogicOperator.And],
          },
        }}
        filterMode='server'
        onFilterModelChange={handleFilterModelChange}
      />
    </div>
  );
};

export default TrackingTransfers;
