import { Edit } from '@mui/icons-material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch } from '../../../state';
import { getPricingConfigAction } from '../../../state/ducks/pricing/actions';
import { MarginConfigItem } from '../../../state/ducks/pricing/types';
import CreateUpdatePricingCalculatorConfigModal from '../../pages/price-calculator/components/CreateUpdatePricingCalculatorConfigModal';
import ButtonGroupIconButton from './ButtonGroupIconButton';

type Props = {
  item: MarginConfigItem;
};

const EditMarginConfigItemButton: React.FC<Props> = ({ item }) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && (
        <CreateUpdatePricingCalculatorConfigModal
          marginItem={item}
          onSuccess={() => dispatch(getPricingConfigAction(new URLSearchParams()))}
          show={showModal}
          handleToggleModal={() => setShowModal(!showModal)}
        />
      )}
      <ButtonGroupIconButton
        variant='contained'
        color='info'
        onClick={() => setShowModal(true)}
        title={intl.formatMessage({
          id: 'common_edit',
          defaultMessage: 'Edit',
        })}>
        <Edit />
      </ButtonGroupIconButton>
    </>
  );
};

export default EditMarginConfigItemButton;
