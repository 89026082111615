import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  cancelSellRequest,
  finalizeSellRequestOffer,
  getChronextAgentsRequest,
  getExistingOffers,
  getSellRequest,
  getSellRequests,
  refreshSWSSellRequests,
  requestMissingData,
  RequestMissingDataParams,
  requestVendorInformation,
  updateSellRequest,
  updateSellRequestAgent,
  updateSellRequestOffer,
  updateSellRequestOfferExpirationDate,
  UpdateSellRequestParams,
  updateSellRequestStatus,
  updateSellRequestVendorInformation,
  UpdateSellRequestVendorInformationParams,
} from '../../../api/server/sell';
import { SellRequestCancellationReason } from '../../../constants/sell';
import { Offer, SellActions } from './types';

export const refreshSWSSellRequestsAction = createAsyncThunk(SellActions.refreshSellRequests, async () => {
  const response = await refreshSWSSellRequests();
  return response.data;
});

export const getSellRequestsAction = createAsyncThunk(SellActions.getSellRequests, async (params: URLSearchParams) => {
  const response = await getSellRequests(params);
  return response.data;
});

export const getSellRequestAction = createAsyncThunk(SellActions.getSellRequest, async (reference: string) => {
  const response = await getSellRequest(reference);
  return response.data;
});

type UpdateSellRequestActionParams = {
  reference: string;
  requestBody: UpdateSellRequestParams;
};

export const updateSellRequestAction = createAsyncThunk(
  SellActions.updateSellRequest,
  async (params: UpdateSellRequestActionParams) => {
    const response = await updateSellRequest(params.reference, params.requestBody);
    return response.data;
  },
);

type RequestMissingDataActionParams = {
  reference: string;
  requestBody: RequestMissingDataParams;
};

export const requestMissingDataAction = createAsyncThunk(
  SellActions.requestMissingData,
  async (params: RequestMissingDataActionParams) => {
    const response = await requestMissingData(params.reference, params.requestBody);
    return response.data;
  },
);

export type CancelSellRequestActionParams = {
  reference: string;
  reason: SellRequestCancellationReason;
};
export const cancelSellRequestAction = createAsyncThunk(
  SellActions.cancelSellRequest,
  async (params: CancelSellRequestActionParams) => {
    const requestBody = {
      [params.reason]: true,
    };
    const response = await cancelSellRequest(params.reference, requestBody);
    return response.data;
  },
);

export const updateSellRequestStatusAction = createAsyncThunk(
  SellActions.updateSellRequestStatus,
  async (reference: string) => {
    const response = await updateSellRequestStatus(reference);
    return response.data;
  },
);

export type UpdateSellRequestOfferParams = Pick<
  Offer,
  'cxtSellingPrice' | 'commissionRate' | 'directPrice' | 'exchangePrice' | 'taxation' | 'expirationDaysLimit'
> & {
  reference: string;
};

export const updateSellRequestOfferAction = createAsyncThunk(
  SellActions.updateSellRequestOffer,
  async (params: UpdateSellRequestOfferParams) => {
    const { reference, ...body } = params;
    const response = await updateSellRequestOffer(reference, body);
    return response.data;
  },
);

export type FinalizeSellRequestOfferParams = Pick<Offer, 'status'> & {
  reference: string;
};

export const finalizeSellRequestOfferAction = createAsyncThunk(
  SellActions.updateSellRequestOfferStatus,
  async (params: FinalizeSellRequestOfferParams) => {
    const { reference, status } = params;
    const response = await finalizeSellRequestOffer(reference, status);
    return response.data;
  },
);

export type UpdateSellRequestOfferExpirationDateParams = {
  reference: string;
  daysLimit: number;
};
export const updateSellRequestOfferExpirationDateAction = createAsyncThunk(
  SellActions.updateSellRequestOfferExpirationDate,
  async (params: UpdateSellRequestOfferExpirationDateParams) => {
    const { reference, daysLimit } = params;
    const response = await updateSellRequestOfferExpirationDate(reference, daysLimit);
    return response.data;
  },
);

export const requestVendorInformationAction = createAsyncThunk(
  SellActions.requestVendorInformation,
  async (reference: string) => {
    const response = await requestVendorInformation(reference);
    return response.data;
  },
);

export type UpdateSellRequestVendorInformationActionParams = {
  reference: string;
  requestBody: UpdateSellRequestVendorInformationParams;
};

export const updateSellRequestVendorInformationAction = createAsyncThunk(
  SellActions.updateVendorInformation,
  async (params: UpdateSellRequestVendorInformationActionParams) => {
    const response = await updateSellRequestVendorInformation(params.reference, params.requestBody);
    return response.data;
  },
);

export const getExistingOffersAction = createAsyncThunk(
  SellActions.getExistingOffers,
  async (reference: string, { rejectWithValue }) => {
    if (!reference) {
      return rejectWithValue('No reference provided');
    }
    const response = await getExistingOffers(reference);
    return response.data;
  },
);

export const getChronextAgents = createAsyncThunk(SellActions.getChronextAgents, async (params: URLSearchParams) => {
  const response = await getChronextAgentsRequest(params);
  return response.data;
});

export type UpdateSellRequestAgentParams = {
  reference: string;
  agentReference: number;
};
export const updateSellRequestAgentAction = createAsyncThunk(
  SellActions.updateSellRequestAgentAction,
  async (params: UpdateSellRequestAgentParams) => {
    const { reference, agentReference } = params;
    const response = await updateSellRequestAgent(reference, agentReference);
    return response.data;
  },
);
