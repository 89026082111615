import { Box, Stack } from '@mui/material';
import { GridCellParams, GridFilterModel, useGridApiRef } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { WORKSHOP_INBOUND_DASHBOARD_TABLE } from '../../../../constants/localStorage';
import { WorkshopReportType } from '../../../../constants/workshop';
import useSearchStoreHistory from '../../../../hooks/useSearchStoreHistory';
import { ClientRoute } from '../../../../routing';
import { requestSelector } from '../../../../selectors/request';
import { workshopInboundReportSelector } from '../../../../selectors/workshop-inbound/workshop-inbound.selector';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { getWorkshopInboundReportActivityAction } from '../../../../state/ducks/workshopInbound/actions';
import { WorkshopInboundActions } from '../../../../state/ducks/workshopInbound/types';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';
import { ServerSidePaginatedTableColDef } from '../../../components/table/ServerSidePaginatedTable';
import SimpleTable from '../../../components/table/SimpleTable';
import {
  defaultSearchParamsAll,
  WORKSHOP_INBOUND_OVERVIEW_TABLE_ACTIVITY_COLUMNS,
  WORKSHOP_INBOUND_OVERVIEW_TABLE_ASSIGNEE_COLUMNS,
  WORKSHOP_INBOUND_REPORT_TABLE_STATUS_COLUMNS,
  WORKSHOP_INBOUND_STATUS_QUERY_MAP,
} from './inboundDashboardTableHelper';
import WorkshopInboundDashboardToolbar from './WorkshopInboundDashboardToolbar';

type Props = {
  reportType: WorkshopReportType;
};

const WorkshopInboundReportOverviewTable: React.FC<Props> = ({ reportType }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const reportData = useAppSelector(workshopInboundReportSelector);
  const request = useAppSelector((state) => requestSelector(state, getWorkshopInboundReportActivityAction.typePrefix));

  const apiRef = useGridApiRef();
  const { searchParams, updateSearchFilters, initialFilters, initialFiltersParsed } = useSearchStoreHistory({
    storeName: WORKSHOP_INBOUND_DASHBOARD_TABLE,
    defaultSearchParams: { ...defaultSearchParamsAll, report_type: reportType },
    apiRef,
  });

  const [columns, setColumns] = useState<ServerSidePaginatedTableColDef[]>(
    WORKSHOP_INBOUND_REPORT_TABLE_STATUS_COLUMNS,
  );

  const onFilterUpdate = (start: string, end: string) => {
    const filterModel: GridFilterModel = {
      items: [
        {
          id: 'start_date',
          field: 'start_date',
          operator: '>=',
          value: start,
        },
        {
          id: 'end_date',
          field: 'end_date',
          operator: '<=',
          value: end,
        },
        {
          id: 'report_type',
          field: 'report_type',
          operator: 'is',
          value: reportType,
        },
      ],
    };
    updateSearchFilters(filterModel);
  };

  useEffect(() => {
    switch (reportType) {
      case WorkshopReportType.assignee_overview:
        setColumns([
          ...WORKSHOP_INBOUND_OVERVIEW_TABLE_ASSIGNEE_COLUMNS,
          ...WORKSHOP_INBOUND_REPORT_TABLE_STATUS_COLUMNS,
        ]);
        break;
      case WorkshopReportType.activity_overview:
        setColumns([
          ...WORKSHOP_INBOUND_OVERVIEW_TABLE_ACTIVITY_COLUMNS,
          ...WORKSHOP_INBOUND_REPORT_TABLE_STATUS_COLUMNS,
        ]);
        break;
      default:
        setColumns(WORKSHOP_INBOUND_REPORT_TABLE_STATUS_COLUMNS);
    }
  }, [reportType]);

  useEffect(() => {
    const params = new URLSearchParams();
    for (const [param, val] of Object.entries(searchParams)) {
      if (val) {
        params.set(param, val.toString());
      } else {
        params.delete(param);
      }
    }
    dispatch(getWorkshopInboundReportActivityAction(params));
  }, [searchParams]);

  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      <ActionStatusSnackbar actionName={WorkshopInboundActions.getWorkshopInboundReportOverview} />
      <Stack spacing={2} sx={{ height: '100%' }}>
        <SimpleTable
          columns={columns}
          rows={reportData.items || []}
          loading={request?.status === 'pending'}
          density='compact'
          autoHeight
          sx={{ flex: 1 }}
          hideFooter
          onCellClick={(params: GridCellParams) => {
            if (['assignee', 'activity'].includes(params?.field)) {
              return;
            }
            const urlParams: Record<string, string> = {};
            if (params.field !== 'total') {
              urlParams['status'] = WORKSHOP_INBOUND_STATUS_QUERY_MAP[params.field];
            }
            switch (reportType) {
              case WorkshopReportType.assignee_overview:
                urlParams['assignee'] = params.row.assignee;
                break;
              case WorkshopReportType.activity_overview:
                urlParams['activity'] = params.row.activity;
                break;
            }
            history.push({
              pathname: ClientRoute.WORKSHOP_INBOUND_DASHBOARD_DETAILS,
              search: new URLSearchParams(urlParams).toString(),
            });
          }}
          slots={{
            toolbar: WorkshopInboundDashboardToolbar,
          }}
          slotProps={{
            toolbar: {
              onDateFilterUpdate: onFilterUpdate,
              metaData: reportData.metaData,
              params: initialFiltersParsed,
              type: reportType,
            },
          }}
          initialState={{
            filter: {
              filterModel: {
                items: initialFilters,
              },
            },
          }}
        />
      </Stack>
    </Box>
  );
};

export default WorkshopInboundReportOverviewTable;
