import { Close } from '@mui/icons-material';
import { IconButton, PaletteMode } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import dot from 'dot-object';
import moment from 'moment';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { createAccessToken } from '../api/server/user';
import { APP_CONFIG } from '../config/app';
import { APPLICATION, MUI_THEME_DARK, MUI_THEME_LIGHT } from '../constants/application';
import deMessages from '../lang/de';
import enMessages from '../lang/en';
import { APIRoute, ClientRoute } from '../routing';
import { ClientRouteGroups } from '../routing/clientRoutes';
import { currentUserSelector, userAuthStateSelector } from '../selectors/user';
import { useAppDispatch, useAppSelector } from '../state';
import { getChronextAgents } from '../state/ducks/sell/actions';
import { getCurrentUserAction } from '../state/ducks/user/actions';
import styles from './App.module.scss';
import PrivateRoute from './components/auth/PrivateRoute';
import Spinner from './components/progress/Spinner';
import Sidebar from './components/sidebar/Sidebar';
import TopBar from './components/topbar/TopBar';
import Homepage from './pages/home/HomePage';
import PriceCalculatorMarginConfigPage from './pages/price-calculator/PriceCalculatorMarginConfigPage';
import PriceCalculatorPage from './pages/price-calculator/PriceCalculatorPage';
import ProductCategoriesPage from './pages/product-categories/ProductCategoriesPage';
import ProductDiscountsPage from './pages/product-discounts/ProductDiscountsPage';
import SellRequestDetails from './pages/sell/SellRequestDetails';
import SellRequests from './pages/sell/SellRequests';
import TrackingDetailsPurchases from './pages/tracking/TrackingDetailsPurchases';
import TrackingDetailsSales from './pages/tracking/TrackingDetailsSales';
import TrackingDetailsTransfers from './pages/tracking/TrackingDetailsTransfers';
import TrackingPage from './pages/tracking/TrackingPage';
import WorkshopInboundReportItemDetailsTable from './pages/workshop-inbound/components/WorkshopInboundReportItemDetailsTable';
import WorkshopInboundActivities from './pages/workshop-inbound/WorkshopInboundActivities';
import WorkshopInboundActivityDetails from './pages/workshop-inbound/WorkshopInboundActivityDetails';
import WorkshopInboundDashboard from './pages/workshop-inbound/WorkshopInboundDashboard';
import WorkshopInboundDashboardDetails from './pages/workshop-inbound/WorkshopInboundDashboardDetails';
import WorkshopInboundItemDetails from './pages/workshop-inbound/WorkshopInboundItemDetails';
import WorkshopInboundItemsWrapper from './pages/workshop-inbound/WorkshopInboundItemsWrapper';
import WorkshopInboundRequestsTable from './pages/workshop-inbound/WorkshopInboundRequestsTable';
import WorkshopInventory from './pages/workshop-inventory/WorkshopInventory';
import WorkshopInventoryItem from './pages/workshop-inventory/WorkshopInventoryItem';
import WorkshopInventoryItemBooking from './pages/workshop-inventory/WorkshopInventoryItemBooking';
import WorkshopLocations from './pages/workshop-locations/WorkshopLocations';
import WorkshopPricesPage from './pages/workshop-prices/WorkshopPricesPage';
import WorkshopProcesses from './pages/workshop-processes/WorkshopProcesses';
import WorkshopReports from './pages/workshop-reports/WorkshopReports';

const messages: { [param: string]: any } = {
  en: enMessages,
  de: deMessages,
};

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    components: {
      MuiDatePicker: {
        styleOverrides: {
          root: {
            backgroundColor: 'red',
          },
        },
      },
    },
    ...(mode === 'light' ? MUI_THEME_LIGHT : MUI_THEME_DARK),
  },
});

const App = () => {
  const dispatch = useAppDispatch();

  const userAuthState = useAppSelector(userAuthStateSelector);
  const currentUser = useAppSelector(currentUserSelector);

  const [isTokenValid, setIsTokenValid] = useState(false);
  const [requestLogin, setRequestLogin] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const theme = useMemo(() => createTheme(getDesignTokens(currentUser.colorMode)), [currentUser.colorMode]);

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const tokenIssuedAt = moment(localStorage.getItem('token-issued-at'), DATE_TIME_FORMAT, true);

      if (!tokenIssuedAt.isValid()) {
        return setIsTokenValid(false);
      }

      if (tokenIssuedAt.valueOf() <= moment().subtract(14, 'minutes').valueOf()) {
        return setIsTokenValid(false);
      }

      setIsTokenValid(true);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isTokenValid) {
      const params = new URLSearchParams();
      dispatch(getChronextAgents(params));
      return;
    }

    createAccessToken()
      .then(() => {
        localStorage.setItem('token-issued-at', moment().format(DATE_TIME_FORMAT));
        dispatch(getCurrentUserAction());
        setIsTokenValid(true);
      })
      .catch(() => {
        setRequestLogin(true);
      });
  }, [isTokenValid]);

  useEffect(() => {
    if (!requestLogin) {
      return;
    }

    const url = APP_CONFIG.backendAPI || 'http://localhost:8080/api';
    const authURL = new URL(`${url}${APIRoute.AUTH_SAML_SSO}`);
    window.location.replace(authURL);
  }, [requestLogin]);

  const locale = currentUser.language;
  const notistackRef = useRef<SnackbarProvider>(null);
  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef?.current?.closeSnackbar(key);
  };

  return (
    <>
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline>
            <IntlProvider locale={locale} defaultLocale='en' messages={dot.dot(messages[locale] || {})}>
              <SnackbarProvider
                ref={notistackRef}
                action={(key) => (
                  <IconButton color='inherit' onClick={onClickDismiss(key)}>
                    <Close />
                  </IconButton>
                )}
                maxSnack={3}
                preventDuplicate>
                <div className={styles.body}>
                  {/* Sidebar is not visible for unauthenticated users */}
                  {userAuthState.isAuthenticated ? (
                    <Sidebar open={sidebarOpen} drawerWidth={200} onCloseCallback={handleSidebarToggle} />
                  ) : null}
                  <div className={styles.wrapper} style={{ width: sidebarOpen ? '100%' : 'calc(100%- 200px)' }}>
                    <TopBar handleToggle={handleSidebarToggle} />
                    <main className={styles.main}>
                      {userAuthState.isAuthenticated ? (
                        <Switch>
                          <PrivateRoute
                            exact
                            path={ClientRoute.HOME}
                            component={Homepage}
                            groups={ClientRouteGroups.HOME}
                          />
                          <PrivateRoute
                            exact
                            path={ClientRoute.TRACKING}
                            component={TrackingPage}
                            groups={ClientRouteGroups.TRACKING}
                          />
                          <PrivateRoute
                            exact
                            path={ClientRoute.TRACKING_TABS}
                            component={TrackingPage}
                            groups={ClientRouteGroups.TRACKING}
                          />
                          <PrivateRoute
                            exact
                            path={ClientRoute.TRACKING_SALES_DETAILS}
                            component={TrackingDetailsSales}
                            groups={ClientRouteGroups.TRACKING_SALES_DETAILS}
                          />
                          <PrivateRoute
                            exact
                            path={ClientRoute.TRACKING_PURCHASES_DETAILS}
                            component={TrackingDetailsPurchases}
                            groups={ClientRouteGroups.TRACKING_PURCHASES_DETAILS}
                          />
                          <PrivateRoute
                            exact
                            path={ClientRoute.TRACKING_TRANSFERS_DETAILS}
                            component={TrackingDetailsTransfers}
                            groups={ClientRouteGroups.TRACKING_TRANSFERS_DETAILS}
                          />
                          <PrivateRoute path={ClientRoute.WORKSHOP} groups={ClientRouteGroups.WORKSHOP}>
                            <PrivateRoute
                              exact
                              path={ClientRoute.WORKSHOP_INVENTORY}
                              component={WorkshopInventory}
                              groups={ClientRouteGroups.WORKSHOP_INVENTORY}
                            />
                            <PrivateRoute
                              exact
                              path={ClientRoute.WORKSHOP_INVENTORY_ITEM}
                              component={WorkshopInventoryItem}
                              groups={ClientRouteGroups.WORKSHOP_INVENTORY_ITEM}
                            />
                            <PrivateRoute
                              exact
                              path={ClientRoute.WORKSHOP_BOOKING_ITEM}
                              component={WorkshopInventoryItemBooking}
                              groups={ClientRouteGroups.WORKSHOP_BOOKING_ITEM}
                            />
                            <PrivateRoute
                              exact
                              path={ClientRoute.WORKSHOP_LOCATIONS}
                              component={WorkshopLocations}
                              groups={ClientRouteGroups.WORKSHOP_LOCATIONS}
                            />
                            <PrivateRoute
                              exact
                              path={ClientRoute.WORKSHOP_REPORTS}
                              component={WorkshopReports}
                              groups={ClientRouteGroups.WORKSHOP_REPORTS}
                            />
                            <PrivateRoute
                              exact
                              path={ClientRoute.WORKSHOP_PROCESSES}
                              component={WorkshopProcesses}
                              groups={ClientRouteGroups.WORKSHOP_PROCESSES}
                            />
                            <PrivateRoute
                              exact
                              path={ClientRoute.WORKSHOP_INBOUND_DASHBOARD}
                              component={WorkshopInboundDashboard}
                              groups={ClientRouteGroups.WORKSHOP_INBOUND_DASHBOARD}
                            />
                            <PrivateRoute
                              exact
                              path={ClientRoute.WORKSHOP_INBOUND_DASHBOARD_DETAILS}
                              component={WorkshopInboundDashboardDetails}
                              groups={ClientRouteGroups.WORKSHOP_INBOUND_DASHBOARD}
                            />
                            <PrivateRoute
                              exact
                              path={ClientRoute.WORKSHOP_INBOUND_DASHBOARD_TABS}
                              component={WorkshopInboundDashboard}
                              groups={ClientRouteGroups.WORKSHOP_INBOUND_DASHBOARD}
                            />
                            <PrivateRoute
                              exact
                              path={ClientRoute.WORKSHOP_INBOUND_DASHBOARD_ITEMS}
                              component={WorkshopInboundReportItemDetailsTable}
                              groups={ClientRouteGroups.WORKSHOP_INBOUND_DASHBOARD}
                            />
                            <PrivateRoute
                              exact
                              path={ClientRoute.WORKSHOP_INBOUND_REQUESTS}
                              component={WorkshopInboundRequestsTable}
                              groups={ClientRouteGroups.WORKSHOP_INBOUND_REQUESTS}
                            />
                            <PrivateRoute
                              exact
                              path={ClientRoute.WORKSHOP_INBOUND_ITEMS}
                              component={WorkshopInboundItemsWrapper}
                              groups={ClientRouteGroups.WORKSHOP_INBOUND_ITEMS}
                            />
                            <PrivateRoute
                              exact
                              path={ClientRoute.WORKSHOP_INBOUND_ACTIVITIES}
                              component={WorkshopInboundActivities}
                              groups={ClientRouteGroups.WORKSHOP_INBOUND_ACTIVITIES}
                            />
                            <PrivateRoute
                              exact
                              path={ClientRoute.WORKSHOP_INBOUND_ACTIVITIES_TABS}
                              component={WorkshopInboundActivities}
                              groups={ClientRouteGroups.WORKSHOP_INBOUND_ACTIVITIES}
                            />
                            <PrivateRoute
                              exact
                              path={ClientRoute.WORKSHOP_INBOUND_ITEM}
                              component={WorkshopInboundItemDetails}
                              groups={ClientRouteGroups.WORKSHOP_INBOUND_ITEM}
                            />
                            <PrivateRoute
                              exact
                              path={ClientRoute.WORKSHOP_INBOUND_ACTIVITY}
                              component={WorkshopInboundActivityDetails}
                              groups={ClientRouteGroups.WORKSHOP_INBOUND_ACTIVITY}
                            />
                          </PrivateRoute>
                          <PrivateRoute
                            exact
                            path={ClientRoute.WORKSHOP_PRICES}
                            component={WorkshopPricesPage}
                            groups={ClientRouteGroups.WORKSHOP_PRICES}
                          />
                          <PrivateRoute
                            exact
                            path={ClientRoute.PRODUCT_CATEGORIES}
                            component={ProductCategoriesPage}
                            groups={ClientRouteGroups.PRODUCT_CATEGORIES}
                          />
                          <PrivateRoute
                            exact
                            path={ClientRoute.PRODUCT_DISCOUNTS}
                            component={ProductDiscountsPage}
                            groups={ClientRouteGroups.PRODUCT_DISCOUNTS}
                          />
                          <PrivateRoute
                            exact
                            path={ClientRoute.SELL}
                            component={SellRequests}
                            groups={ClientRouteGroups.SELL}
                          />
                          <PrivateRoute
                            exact
                            path={ClientRoute.SELL_TABS}
                            component={SellRequests}
                            groups={ClientRouteGroups.SELL}
                          />
                          <PrivateRoute
                            exact
                            path={ClientRoute.SELL_DETAILS}
                            component={SellRequestDetails}
                            groups={ClientRouteGroups.SELL_DETAILS}
                          />
                          <PrivateRoute
                            exact
                            path={ClientRoute.PRICE_CALCULATOR}
                            component={PriceCalculatorPage}
                            groups={ClientRouteGroups.PRICE_CALCULATOR}
                          />
                          <PrivateRoute
                            exact
                            path={ClientRoute.PRICE_CALCULATOR_CONFIG}
                            component={PriceCalculatorMarginConfigPage}
                            groups={ClientRouteGroups.PRICE_CALCULATOR_CONFIG}
                          />
                        </Switch>
                      ) : (
                        <Spinner size={70} />
                      )}
                    </main>
                  </div>
                </div>
              </SnackbarProvider>
            </IntlProvider>
          </CssBaseline>
        </ThemeProvider>
      </Router>
      <Helmet>
        <title>{process.env.REACT_APP_SITE_NAME || APPLICATION.APP_NAME}</title>
        <link rel='icon' href={`/assets/${process.env.REACT_APP_FAVICON_FILENAME}.ico?v2`} />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href={`/assets/${process.env.REACT_APP_FAVICON_FILENAME}-32x32.png`}
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href={`/assets/${process.env.REACT_APP_FAVICON_FILENAME}-16x16.png`}
        />
      </Helmet>
    </>
  );
};

export default App;
