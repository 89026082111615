import { getDefaultFromSlug, unslugify } from '@chronext/react-common';
import { Remove } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Stack,
  TextField,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CreatePricingConfigParams, UpdatePricingConfigParams } from '../../../../api/server/pricing';
import { MarginType } from '../../../../constants/pricing';
import { algoliaStateSelector } from '../../../../selectors/algolia';
import { pricingConfigSelector } from '../../../../selectors/pricing/pricing.selector';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { getAlgoliaBrandsAction, getAlgoliaModelsForBrandAction } from '../../../../state/ducks/algolia/actions';
import {
  createPricingConfigEntryAction,
  getPricingConfigBrandsAction,
  getPricingConfigModelsAction,
  getPricingConfigReferencesAction,
  updatePricingConfigEntryAction,
} from '../../../../state/ducks/pricing/actions';
import { MarginConfigItem } from '../../../../state/ducks/pricing/types';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';
import AddMarginTierForm, { AddMarginTierFormData } from './AddMarginTierForm';
import MarginRangeTable from './MarginRangeTable';

type Props = {
  onSuccess: () => void;
  show: boolean;
  handleToggleModal: () => void;
  marginItem?: MarginConfigItem;
};

const initialFormData: Omit<MarginConfigItem, 'isDeletable'> = {
  id: '',
  brandOriginal: '',
  modelOriginal: '',
  referenceOriginal: '',
  marginRanges: {
    direct: {},
    commission: {},
    tradeIn: {},
  },
};

const MARGIN_TYPE_OPTIONS: MarginType[] = [MarginType.direct, MarginType.tradeIn, MarginType.commission];

const validateReference = (reference: string) => {
  return !!reference.match(/^[a-zA-Z0-9-./]*$/);
};

const CreateUpdatePricingCalculatorConfigModal: React.FC<Props> = ({
  marginItem,
  onSuccess,
  show,
  handleToggleModal,
}) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { brands: algoliaBrands, models: algoliaModels } = useAppSelector(algoliaStateSelector);
  const { brands, models, references } = useAppSelector(pricingConfigSelector);

  const [showCreateSnackbar, setShowCreateSnackbar] = useState(false);
  const [showUpdateSnackbar, setShowUpdateSnackbar] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [isFormValid, setIsFormValid] = useState(false);

  const [brandExists, setBrandExists] = useState(false);
  const [modelExists, setModelExists] = useState(false);
  const [referenceExists, setReferenceExists] = useState(false);
  const [referenceHelperText, setReferenceHelperText] = useState('');

  const clearForm = () => {
    setFormData(initialFormData);
  };

  const handleClose = (clear = false) => {
    if (clear) {
      clearForm();
    }
    handleToggleModal();
  };

  const addMarginTier = (type: MarginType, data: AddMarginTierFormData) => {
    const tiers = { ...formData.marginRanges[type] };
    tiers[data.minValue.toString()] = Number(data.marginValue);
    setFormData({ ...formData, marginRanges: { ...formData.marginRanges, [type]: tiers } });
  };

  const removeMarginTier = (type: MarginType, key: string) => {
    const tiers = { ...formData.marginRanges[type] };
    delete tiers[key];
    if (!Object.entries(tiers).length) {
      setIsFormValid(false);
    }
    setFormData({ ...formData, marginRanges: { ...formData.marginRanges, [type]: tiers } });
  };

  const handleBrandChange = (event: SelectChangeEvent) => {
    if (brands.includes(event.target.value)) {
      setBrandExists(true);
      dispatch(getPricingConfigModelsAction(event.target.value)).then(() => {
        dispatch(getAlgoliaModelsForBrandAction(event.target.value));
      });
    } else {
      setBrandExists(false);
    }
    setModelExists(false);
    setReferenceExists(false);
    setFormData({ ...formData, brandOriginal: event.target.value, modelOriginal: '', referenceOriginal: '' });
  };

  const handleModelChange = (event: SelectChangeEvent) => {
    if (models.includes(event.target.value)) {
      setModelExists(true);
      dispatch(getPricingConfigReferencesAction({ brand: formData.brandOriginal, model: event.target.value }));
    } else {
      setModelExists(false);
    }
    setReferenceExists(false);
    setFormData({ ...formData, modelOriginal: event.target.value, referenceOriginal: '' });
  };

  const handleReferenceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const referenceExists = references.includes(event.target.value.toLowerCase());
    setReferenceExists(referenceExists);
    setIsFormValid(!referenceExists && validateReference(event.target.value));
    setFormData({ ...formData, referenceOriginal: event.target.value });
  };

  const handleReferenceHelperText = (reference: string) => {
    if (referenceExists) {
      setReferenceHelperText(
        intl.formatMessage({
          id: 'priceCalculatorConfig_createModal_referenceExists',
          defaultMessage: 'Reference already exists. Please choose another one.',
        }),
      );
    } else if (!validateReference(reference)) {
      setReferenceHelperText(
        intl.formatMessage({
          id: 'priceCalculatorConfig_createModal_referenceInvalid',
          defaultMessage: 'Reference can only contain letters, numbers, and the following characters: -./',
        }),
      );
    } else {
      setReferenceHelperText('');
    }
  };

  const submitForm = () => {
    if (marginItem) {
      const updateParams: UpdatePricingConfigParams = {
        id: formData.id,
        data: formData.marginRanges,
      };
      dispatch(updatePricingConfigEntryAction(updateParams)).then((response) => {
        setShowUpdateSnackbar(true);
        if (response?.payload && !response.payload?.errors) {
          clearForm();
          handleToggleModal();
          onSuccess();
        }
      });
    } else {
      const { brandOriginal, modelOriginal, referenceOriginal, ...rest } = formData;
      const createParams: CreatePricingConfigParams = {
        brand: brandOriginal,
        model: modelOriginal,
        reference: referenceOriginal,
        ...rest,
      };
      dispatch(createPricingConfigEntryAction(createParams)).then((response) => {
        setShowCreateSnackbar(true);
        if (response?.payload && !response.payload?.errors) {
          clearForm();
          handleToggleModal();
          onSuccess();
        }
      });
    }
  };

  useEffect(() => {
    if (marginItem) {
      setFormData({ ...marginItem });
    } else {
      clearForm();
    }
    if (show) {
      const urlSearchParams = new URLSearchParams();
      dispatch(getAlgoliaBrandsAction(urlSearchParams));
      dispatch(getPricingConfigBrandsAction(urlSearchParams));
    }
  }, [show]);

  useEffect(() => {
    const directValid = Object.entries(formData.marginRanges[MarginType.direct]).length > 0;
    const tradeInValid = Object.entries(formData.marginRanges[MarginType.tradeIn]).length > 0;
    const commissionValid = Object.entries(formData.marginRanges[MarginType.commission]).length > 0;
    if (!directValid || !tradeInValid || !commissionValid) {
      setIsFormValid(false);
      handleReferenceHelperText(formData.referenceOriginal);
      return;
    }
    if (
      formData.brandOriginal &&
      (!brandExists || formData.modelOriginal) &&
      (!modelExists || formData.referenceOriginal) &&
      !referenceExists &&
      validateReference(formData.referenceOriginal)
    ) {
      setIsFormValid(true);
      handleReferenceHelperText(formData.referenceOriginal);
    } else {
      setIsFormValid(false);
      handleReferenceHelperText(formData.referenceOriginal);
    }
  }, [formData]);

  const renderMarginTableStack = (type: MarginType) => (
    <Stack spacing={2}>
      <MarginRangeTable
        marginTiers={formData.marginRanges[type]}
        type={type}
        rowActions={(key) => (
          <IconButton color='error' onClick={() => removeMarginTier(type, key)} disabled={key === '0'} size='small'>
            <Remove fontSize='inherit' />
          </IconButton>
        )}
      />
      <AddMarginTierForm type={type} addMarginTier={addMarginTier} previousMargins={formData.marginRanges[type]} />
    </Stack>
  );

  return (
    <>
      {showUpdateSnackbar && (
        <ActionStatusSnackbar
          actionName={updatePricingConfigEntryAction.typePrefix}
          config={{ messageValues: { reference: marginItem?.id } }}
        />
      )}
      {showCreateSnackbar && <ActionStatusSnackbar actionName={createPricingConfigEntryAction.typePrefix} />}
      <Dialog open={show} onClose={() => handleClose()} maxWidth='sm' fullWidth>
        <DialogTitle>
          {marginItem ? (
            <FormattedMessage
              id='priceCalculatorConfig_updateModal_title'
              defaultMessage='{fallback, select, true {Update Default entry} other {Update entry "{entry}"}}'
              values={{
                fallback: !marginItem.brandOriginal && !marginItem.modelOriginal,
                entry: [
                  String(getDefaultFromSlug(marginItem.brandOriginal, '').brand || '') ||
                    unslugify(marginItem.brandOriginal),
                  String(getDefaultFromSlug(marginItem.brandOriginal, marginItem.modelOriginal)?.model || '') ||
                    unslugify(marginItem.modelOriginal),
                  marginItem.referenceOriginal,
                ].join(' '),
              }}
            />
          ) : (
            <FormattedMessage id='priceCalculatorConfig_createModal_title' defaultMessage='New Entry' />
          )}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            {!marginItem && (
              <>
                <DialogContentText>
                  <FormattedMessage
                    id='priceCalculatorConfig_createModal_description'
                    defaultMessage='To add a new entry, please fill out the fields below. Please note that you can only select a model after you have selected a brand from the dropdown. If there is no entry for the selected brand yet, you have to create that first. The same rule applies for references.'
                  />
                </DialogContentText>
                <FormControl>
                  <InputLabel id='brandLabel'>
                    <FormattedMessage id='common_labels_brand' defaultMessage='Brand' />
                  </InputLabel>
                  <Select
                    margin='dense'
                    id='brandOriginal'
                    name='brandOriginal'
                    value={formData.brandOriginal}
                    onChange={handleBrandChange}
                    input={
                      <OutlinedInput label={<FormattedMessage id='common_labels_brand' defaultMessage='Brand' />} />
                    }
                    labelId='brandLabel'
                    fullWidth>
                    {algoliaBrands.map((option) => (
                      <MenuItem key={option.slug} value={option.slug}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {formData.brandOriginal && brandExists && (
                  <FormControl>
                    <InputLabel id='modelLabel'>
                      <FormattedMessage id='common_labels_model' defaultMessage='Model' />
                    </InputLabel>
                    <Select
                      margin='dense'
                      id='model'
                      name='model'
                      value={formData.modelOriginal}
                      onChange={handleModelChange}
                      input={
                        <OutlinedInput label={<FormattedMessage id='common_labels_model' defaultMessage='model' />} />
                      }
                      labelId='modelLabel'
                      fullWidth>
                      {algoliaModels.map((option) => (
                        <MenuItem key={option.slug} value={option.slug}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {formData.modelOriginal && modelExists && (
                  <TextField
                    margin='dense'
                    id='referenceOriginal'
                    name='referenceOriginal'
                    value={formData.referenceOriginal}
                    error={referenceExists || !validateReference(formData.referenceOriginal)}
                    onChange={handleReferenceChange}
                    label={<FormattedMessage id='common_labels_reference' defaultMessage='Reference' />}
                    fullWidth
                    helperText={!isFormValid && referenceHelperText}
                  />
                )}
              </>
            )}
            <Stack spacing={3}>{MARGIN_TYPE_OPTIONS.map((type) => renderMarginTableStack(type))}</Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(true)} color='error'>
            <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
          </Button>
          <Button onClick={submitForm} disabled={!isFormValid} color='success'>
            <FormattedMessage id='common_save' defaultMessage='Save' />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateUpdatePricingCalculatorConfigModal;
