import * as React from 'react';
import CreateBatchJobModal from './CreateBatchJobModal';
import styles from './DiscountsTableToolbar.module.scss';

type Props = {
  onSuccess: () => void;
};

const BatchJobsTableToolbar: React.FC<Props> = ({ onSuccess }) => {
  return (
    <div className={styles.wrapper}>
      <CreateBatchJobModal onSuccess={onSuccess} />
    </div>
  );
};

export default BatchJobsTableToolbar;
