import * as clipboard from 'clipboard-polyfill';

export const capitalizeFirstLetter = (word: string): string => {
  return word.replace(/^\w/, (c: string) => c.toLocaleUpperCase());
};

export const replaceNumbersAndLettersInString = (word: string): string => {
  return word.replaceAll('#', getRandomNumber).replaceAll('*', getRandomLetter);
};

const replacementLetters = 'ABCDEFGHJKLMNPQRSTUVWXYZ';
const replacementNumbers = '23456789';

const getRandomNumber = () => {
  return replacementNumbers[Math.floor(Math.random() * replacementNumbers.length)];
};

const getRandomLetter = () => {
  return replacementLetters[Math.floor(Math.random() * replacementLetters.length)];
};

export const getPriceLocaleString = (value: number, language = 'en', country: string, currency: string) => {
  const locale = `${language}-${country}`;

  if (!/[a-z]{2}-[a-z]{2}$/i.test(locale)) {
    return value;
  }

  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  };

  if (currency === 'USD') {
    options.currencyDisplay = 'code';
  }

  return value.toLocaleString(locale, options).replace(/\s/g, '\u00a0');
};

export const calculatePriceFromMargin = (price: number, margin: number): number => price - (price * margin) / 100;

export const calculateMarginPercentage = (price: number, priceWithMargin: number): number => {
  return ((price - priceWithMargin) / price) * 100;
};

/* converts any string in camelCase, PascalCase, snake_case, kebab-case or any combination of them to Title Case */
export const text2title = (snakeCase: string) =>
  snakeCase
    ?.replace(/([A-Z])/g, (match) => ` ${match}`)
    ?.replace(/^./, (match) => match.toUpperCase())
    ?.replace(/^[-_]*(.)/, (_, c: string) => c.toUpperCase())
    ?.replace(/[-_]+(.)/g, (_, c: string) => ' ' + c.toUpperCase())
    ?.trim() ?? '';

export const copyToClipboard = (text: string, onSuccess?: (msg: Record<string, string>) => void) => {
  clipboard.writeText(text).then(
    () => {
      onSuccess?.({
        id: 'successMessages_copiedToClipboard',
        defaultMessage: 'Copied to clipboard',
      });
    },
    () => {
      console.error('error while copying to clipboard');
    },
  );
};
