import { Cancel, Refresh, Share } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, Tooltip } from '@mui/material';
import {
  GridAlignment,
  GridRenderCellParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridValueGetterParams,
  getGridSingleSelectOperators,
  getGridStringOperators,
} from '@mui/x-data-grid';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { FormattedMessage } from 'react-intl';
import { COUNTRY_CODES } from '../../../../constants/countryCodes';
import { SellRequestStatus, SellRequestStatusInfo } from '../../../../constants/sell';
import { sort, sortFunctionParams } from '../../../../hooks/useSearchStoreHistory';
import { formatUTCToLocal } from '../../../../utils/datetime';
import StatusBadge from '../../../components/badges/StatusBadge';
import SellingMethods from './SellingMethods';

const centerAlignment: GridAlignment = 'center';

export const SELL_REQUESTS_FILTER_PREFIXES: Record<string, string> = {
  status: 'status_',
  statusInfo: 'sellRequests_statusInfo_',
};

export const SELL_REQUESTS_TABLE_STATUS_COLUMN = {
  id: 'common_labels_status',
  headerName: 'Status',
  field: 'status',
  sortable: false,
  filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'is'),
  type: 'singleSelect',
  valueOptions: Object.keys(SellRequestStatus),
  minWidth: 200,
  renderCell: (params: GridRenderCellParams) => <StatusBadge status={params.row?.status} />,
};

export const SELL_REQUESTS_TABLE_COLUMNS = [
  {
    id: 'sellRequests_labels_statusInfo',
    headerName: 'Status Information',
    field: 'statusInfo',
    minWidth: 300,
    sortable: false,
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'is'),
    type: 'singleSelect',
    valueOptions: Object.keys(SellRequestStatusInfo),
    renderCell: (params: GridRenderCellParams) =>
      params.value && <FormattedMessage id={`sellRequests_statusInfo_${params.value}`} defaultMessage={params.value} />,
  },
  {
    id: 'common_labels_id',
    headerName: 'ID',
    field: 'reference',
    width: 110,
    valueGetter: (params: GridValueGetterParams) => params.row?.sellRequestId || params.row.reference,
  },
  {
    id: 'sellRequests_labels_vendor',
    headerName: 'Vendor',
    field: 'seller',
    sortable: false,
    width: 150,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
    renderCell: (params: GridRenderCellParams) => {
      const { seller } = params.row;
      if (!seller) {
        return '';
      }
      const { firstName, lastName } = seller;
      return `${firstName} ${lastName}`;
    },
  },
  {
    id: 'sellRequests_labels_vendorEmail',
    headerName: 'Vendor Email',
    field: 'sellerEmail',
    sortable: false,
    width: 250,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
    valueGetter: (params: GridValueGetterParams) => params.row.seller?.email || '',
  },
  {
    id: 'sellRequests_labels_Agent',
    headerName: 'Agent',
    field: 'agent',
    sortable: false,
    width: 150,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
    valueGetter: (params: GridValueGetterParams) => params.row?.agent?.preferredName || '',
  },
  {
    id: 'sellRequests_labels_sellingMethods',
    headerName: 'Selling Methods',
    field: 'sellingMethods',
    minWidth: 150,
    sortable: false,
    filterable: false,
    align: centerAlignment,
    renderCell: (params: GridRenderCellParams) => <SellingMethods methods={params.row?.sellingMethods || []} />,
  },
  {
    id: 'sellRequests_labels_offerSellingMethods',
    headerName: 'Offered Selling Methods (+ chosen)',
    field: 'offerSellingMethods',
    minWidth: 250,
    sortable: false,
    filterable: false,
    renderCell: (params: GridRenderCellParams) => {
      const { offer, offerReply } = params.row;
      const offeredMethods = [];
      if (offer?.directPrice > 0) {
        offeredMethods.push('direct');
      }
      if (offer?.commissionRate > 0) {
        offeredMethods.push('commission');
      }
      // exchange
      if (offer?.exchangePrice > 0) {
        offeredMethods.push('exchange');
      }
      return (
        <Stack direction='row' spacing={2} justifyContent='start'>
          <SellingMethods methods={offeredMethods} />
          {offerReply?.selling_method && (
            <span>
              <FormattedMessage
                id={`sellRequests_sellingMethods_${offerReply?.selling_method}`}
                defaultMessage={offerReply?.selling_method}
              />
            </span>
          )}
        </Stack>
      );
    },
  },
  {
    id: 'common_labels_brand',
    headerName: 'Brand',
    field: 'watchBrand',
    minWidth: 150,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
  },
  {
    id: 'common_labels_model',
    headerName: 'Model',
    field: 'watchModel',
    minWidth: 150,
  },
  {
    id: 'common_labels_reference',
    headerName: 'Reference',
    field: 'watchRef',
    minWidth: 150,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
  },
  {
    id: 'common_labels_country',
    headerName: 'Country',
    field: 'country',
    sortable: false,
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'is'),
    type: 'singleSelect',
    valueOptions: COUNTRY_CODES,
    width: 80,
    align: centerAlignment,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params?.row?.seller?.country} arrow>
        <div>
          <ReactCountryFlag
            countryCode={params?.row?.seller?.country}
            style={{ fontSize: '20px', lineHeight: '1em' }}
          />
        </div>
      </Tooltip>
    ),
  },
  {
    id: 'common_labels_updatedAt',
    headerName: 'Updated At',
    field: 'updatedAt',
    minWidth: 150,
    filterable: false,
    valueGetter: ({ value }: GridValueGetterParams) => value && formatUTCToLocal(value),
  },
  {
    id: 'common_labels_createdAt',
    headerName: 'Created At',
    field: 'createdAt',
    minWidth: 150,
    filterable: false,
    valueGetter: ({ value }: GridValueGetterParams) => value && formatUTCToLocal(value),
  },
];

type ToolbarProps = {
  loading: boolean;
  showFilterButtons: boolean;
  handleRefreshClick: () => void;
  handleResetClick: () => void;
  handleShareClick: () => void;
};

export const SellRequestsTableToolbar: React.FC<ToolbarProps> = ({
  loading,
  showFilterButtons = false,
  handleRefreshClick,
  handleResetClick,
  handleShareClick,
}) => (
  <GridToolbarContainer>
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
    <LoadingButton
      sx={{ ml: 1 }}
      color='info'
      variant='contained'
      size='small'
      loading={loading}
      disabled={loading}
      onClick={handleRefreshClick}
      loadingPosition='end'
      endIcon={<Refresh />}>
      <FormattedMessage id='common_refresh' defaultMessage='Refresh' />
    </LoadingButton>
    {showFilterButtons && (
      <>
        <Button
          sx={{ ml: 1 }}
          color='warning'
          variant='outlined'
          size='small'
          onClick={handleResetClick}
          endIcon={<Cancel />}>
          <FormattedMessage id='common_resetFilters' defaultMessage='Reset Filters & Sorting' />
        </Button>
        <Button
          sx={{ ml: 1 }}
          color='info'
          variant='outlined'
          size='small'
          onClick={handleShareClick}
          endIcon={<Share />}>
          <FormattedMessage id='common_shareFilters' defaultMessage='Share Filters & Sorting' />
        </Button>
      </>
    )}
  </GridToolbarContainer>
);

export const SELL_REQUESTS_QUERY_MAP: Record<string, any> = {
  reference: 'sell_request_id',
  watchBrand: 'watch_brand',
  watchModel: 'watch_model',
  watchRef: 'watch_ref',
  watchCondition: 'watch_condition',
  boxAvailable: 'box_available',
  paperAvailable: 'paper_available',
  seller: 'seller_name',
  sellerEmail: 'seller_email',
  createdAt: 'created_at',
  updatedAt: 'updated_at',
  status: 'sell_request_status',
  statusInfo: 'sell_request_status_info',
  country: 'seller_country',
};

export const sortingParams: Record<string, any> = {
  sorting_field: 'created_at',
  order_asc: false,
};

export const defaultSearchParams: Record<string, any> = {
  sell_request_id: '',
  watch_brand: '',
  watch_model: '',
  watch_ref: '',
  watch_condition: '',
  boxAvailable: null,
  paperAvailable: null,
  seller_country: '',
  sell_request_status: '',
  sell_request_status_info: '',
  seller_name: '',
  ...sortingParams,
};

export const sortFunction = ({ sortModel, QUERY_MAP }: sortFunctionParams): sort => ({
  sorting_field: QUERY_MAP?.[sortModel[0]?.field] || sortModel[0]?.field || 'created_at',
  order_asc: sortModel[0]?.sort === 'asc',
});
