import { createReducer } from '@reduxjs/toolkit';
import {
  getActivityReportAction,
  getEmployeeReportAction,
  getWorkshopItemsAction,
  getWorkshopItemAction,
  getWorkshopActivitiesAction,
  getWorkshopProcessesAction,
  updateWorkshopItemAction,
  getWorkshopLocationsAction,
  getWorkshopLocationsOverviewAction,
} from './actions';
import { WorkshopState } from './types';

const initialState = {
  reports: {
    activity: [],
    employee: [],
  },
  activities: [],
  processes: {
    count: 0,
    items: [],
  },
  inventoryItems: {
    count: 0,
    items: [],
  },
  inventoryItem: null,
  workshopItems: {
    count: 0,
    items: [],
  },
  workshopItem: null,
  workshopLocations: [],
  workshopAreaOverview: [],
} as WorkshopState;

const workshopReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getWorkshopProcessesAction.fulfilled, (state, action) => {
      state.processes.items = action.payload.items;
      state.processes.count = action.payload?.count || action.payload.items.length;
    })
    .addCase(getActivityReportAction.fulfilled, (state, action) => {
      state.reports.activity = action.payload.items;
    })
    .addCase(getEmployeeReportAction.fulfilled, (state, action) => {
      state.reports.employee = action.payload.items;
    })
    .addCase(getWorkshopActivitiesAction.fulfilled, (state, action) => {
      state.activities = action.payload.items;
    })
    .addCase(getWorkshopItemsAction.fulfilled, (state, action) => {
      state.workshopItems.items = action.payload.items;
      state.workshopItems.count = action.payload?.count || action.payload.items.length;
    })
    .addCase(getWorkshopItemAction.fulfilled, (state, action) => {
      state.workshopItem = action.payload?.reference ? action.payload : initialState.workshopItem;
    })
    .addCase(getWorkshopItemAction.rejected, (state) => {
      state.workshopItem = initialState.workshopItem;
    })
    .addCase(updateWorkshopItemAction.fulfilled, (state, action) => {
      state.workshopItem = action.payload;
    })
    .addCase(getWorkshopLocationsAction.fulfilled, (state, action) => {
      state.workshopLocations = action.payload.items;
    })
    .addCase(getWorkshopLocationsOverviewAction.fulfilled, (state, action) => {
      state.workshopAreaOverview = action.payload.items;
    });
});

export default workshopReducer;
