import {
  AttachMoney,
  CurrencyFranc,
  CurrencyPound,
  Download,
  Euro,
  InfoOutlined,
  Money,
  Percent,
} from '@mui/icons-material';
import { ButtonGroup, Divider, IconButton, Stack, Tooltip } from '@mui/material';
import { GridCellParams, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { commercetoolsDiscountBatchJobsSelector } from '../../../selectors/commercetools';
import { useAppDispatch, useAppSelector } from '../../../state';
import {
  downloadBatchJobDiscountCodesAction,
  getDiscountBatchJobsAction,
} from '../../../state/ducks/commercetools/actions';
import { formatUTCToLocal } from '../../../utils/datetime';
import ButtonGroupIconButton from '../../components/buttons/ButtonGroupIconButton';
import ActionStatusSnackbar from '../../components/snackbars/ActionStatusSnackbar';
import ServerSidePaginatedTable, {
  ServerSidePaginatedTableColDef,
} from '../../components/table/ServerSidePaginatedTable';
import BatchJobDetailsModal from './components/BatchJobDetailsModal';
import BatchJobsTableToolbar from './components/BatchJobsTableToolbar';
import styles from './ProductDiscounts.module.scss';

// Column definitions for Product Categories table
const categoriesTableColumns: ServerSidePaginatedTableColDef[] = [
  {
    id: 'common_details',
    headerName: 'Details',
    field: 'details', // This field doesn't exist in the row data
    sortable: false,
    renderCell: () => {
      return (
        <IconButton aria-label='edit' size='small'>
          <InfoOutlined />
        </IconButton>
      );
    },
    width: 80,
    align: 'center',
  },
  {
    id: 'productDiscounts_labels_code',
    headerName: 'Code',
    field: 'code',
    minWidth: 150,
    sortable: false,
    valueGetter: ({ row }) => row?.prototype?.code,
    flex: 1,
  },
  {
    id: 'productDiscounts_labels_currencies',
    headerName: 'Currencies',
    field: 'currencies',
    minWidth: 120,
    sortable: false,
    flex: 1,
    align: 'center',
    renderCell: (params: GridRenderCellParams) => {
      const { currencies } = params.row?.prototype;
      if (!Array.isArray(currencies)) {
        return '';
      }
      const commonStyles = { fontSize: '1rem' };
      const getColor = (currency: string) => (currencies.includes(currency) ? 'primary' : 'disabled');

      return (
        <Stack direction='row' spacing={1} divider={<Divider orientation='vertical' flexItem />}>
          <Tooltip title='EUR' arrow>
            <Euro sx={{ ...commonStyles }} color={getColor('EUR')} />
          </Tooltip>
          <Tooltip title='GBP' arrow>
            <CurrencyPound sx={{ ...commonStyles }} color={getColor('GBP')} />
          </Tooltip>
          <Tooltip title='USD' arrow>
            <AttachMoney sx={{ ...commonStyles }} color={getColor('USD')} />
          </Tooltip>
          <Tooltip title='CHF' arrow>
            <CurrencyFranc sx={{ ...commonStyles }} color={getColor('CHF')} />
          </Tooltip>
        </Stack>
      );
    },
  },
  {
    id: 'productDiscounts_labels_discountType',
    headerName: 'Type',
    field: 'isPercentValue',
    sortable: false,
    align: 'center',
    renderCell: (params: GridRenderCellParams) => {
      const { isPercentValue } = params.row?.prototype;
      return isPercentValue ? (
        <Tooltip title={<FormattedMessage id='productDiscounts_types_relative' defaultMessage='Relative' />}>
          <Percent />
        </Tooltip>
      ) : (
        <Tooltip title={<FormattedMessage id='productDiscounts_types_absolute' defaultMessage='Absolute' />}>
          <Money />
        </Tooltip>
      );
    },
  },
  {
    id: 'productDiscounts_labels_amount',
    headerName: 'Amount',
    field: 'amount',
    sortable: false,
    align: 'center',
    valueGetter: ({ row }) => row?.prototype?.amount,
    flex: 1,
  },
  {
    id: 'productDiscounts_labels_validFrom',
    headerName: 'Valid From',
    field: 'validFrom',
    sortable: false,
    valueGetter: ({ row }) => row?.prototype?.validFrom && formatUTCToLocal(row.prototype.validFrom),
    flex: 1,
  },
  {
    id: 'productDiscounts_labels_validUntil',
    headerName: 'Valid Until',
    field: 'validUntil',
    sortable: false,
    valueGetter: ({ row }) => row?.prototype?.validUntil && formatUTCToLocal(row.prototype.validUntil),
    flex: 1,
  },
  {
    id: 'common_labels_createdBy',
    headerName: 'Created By',
    field: 'createdBy',
    sortable: false,
    valueGetter: ({ row }) => row?.prototype?.createdBy,
    flex: 1,
  },
  {
    id: 'common_labels_createdAt',
    headerName: 'Created At',
    field: 'createdAt',
    sortable: false,
    flex: 1,
    valueGetter: ({ row }) => row?.createdAt && formatUTCToLocal(row.createdAt),
  },
];

const BatchJobs: React.FC = () => {
  const dispatch = useAppDispatch();
  const { batchJobs, batchJobsTotal } = useAppSelector(commercetoolsDiscountBatchJobsSelector);
  const [tableColumns, setTableColumns] = useState<ServerSidePaginatedTableColDef[]>(categoriesTableColumns);

  const intl = useIntl();
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [searchParams] = useState({
    where: '',
  });
  const [selectedJobId, setSelectedJobId] = useState('');
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  useEffect(() => {
    const actionsColumn: ServerSidePaginatedTableColDef[] = [];
    actionsColumn.push({
      id: 'common_actions',
      headerName: 'Actions',
      field: 'actions',
      align: 'center',
      sortable: false,
      renderCell: (params: any) => {
        return (
          <ButtonGroup variant='text'>
            <ButtonGroupIconButton
              color='info'
              onClick={() => {
                dispatch(downloadBatchJobDiscountCodesAction(params.row.id));
              }}
              title={intl.formatMessage(
                {
                  id: 'productDiscounts_actions_download_codes',
                  defaultMessage: 'Download Discount Codes',
                },
                { category: params.row?.key },
              )}>
              <Download />
            </ButtonGroupIconButton>
          </ButtonGroup>
        );
      },
    });
    setTableColumns([...tableColumns, ...actionsColumn]);
  }, []);

  return (
    <div className={styles.wrapper}>
      <ActionStatusSnackbar actionName={getDiscountBatchJobsAction.typePrefix} />
      {showDetailsModal && selectedJobId && (
        <BatchJobDetailsModal
          show={showDetailsModal}
          jobId={selectedJobId}
          handleToggleModal={() => setShowDetailsModal(!showDetailsModal)}
        />
      )}
      <ServerSidePaginatedTable
        rows={batchJobs}
        columns={tableColumns}
        totalCount={batchJobsTotal}
        triggerRefresh={triggerRefresh}
        fetchDataEffect={getDiscountBatchJobsAction}
        searchParams={searchParams}
        onCellClick={(params: GridCellParams) => {
          if (params.field !== 'details') {
            return;
          }
          setSelectedJobId(params.row?.id);
          setShowDetailsModal(true);
        }}
        slots={{
          toolbar: BatchJobsTableToolbar,
        }}
        slotProps={{
          toolbar: {
            onSuccess: () => setTriggerRefresh(!triggerRefresh),
          },
        }}
      />
    </div>
  );
};

export default BatchJobs;
