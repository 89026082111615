import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import CreateUpdatePricingCalculatorConfigModal from './CreateUpdatePricingCalculatorConfigModal';
import styles from './PricingCalculatorConfigListTableToolbar.module.scss';

type Props = {
  onSuccess: () => void;
};

const PricingCalculatorConfigListTableToolbar: React.FC<Props> = ({ onSuccess }) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  return (
    <div className={styles.wrapper}>
      <Button startIcon={<AddIcon />} color='success' variant='outlined' onClick={() => setShowCreateModal(true)}>
        <FormattedMessage id='workshopPrices_prices_actions_create' defaultMessage='Create new Entry' />
      </Button>
      <CreateUpdatePricingCalculatorConfigModal
        onSuccess={onSuccess}
        show={showCreateModal}
        handleToggleModal={() => setShowCreateModal(!showCreateModal)}
      />
    </div>
  );
};

export default PricingCalculatorConfigListTableToolbar;
