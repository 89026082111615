import { APIRoute, createPath } from '../../routing';
import { del, get, post, put } from '../../utils/httpUtils';

export const getWorkshopServices = (params: URLSearchParams) => {
  return get(APIRoute.WORKSHOP_SERVICE_SERVICES, params);
};

export type CreateUpdateWorkshopServiceParams = {
  active?: boolean;
  key?: string;
  name?: string;
};

export const createWorkshopService = (requestBody: CreateUpdateWorkshopServiceParams) => {
  return post(APIRoute.WORKSHOP_SERVICE_SERVICES, requestBody);
};

export const updateWorkshopService = (id: string, requestBody: CreateUpdateWorkshopServiceParams) => {
  const url = createPath({
    path: APIRoute.WORKSHOP_SERVICE_SERVICE,
    params: { id },
  });
  return put(url, requestBody);
};

export const deleteWorkshopService = (id: string) => {
  const url = createPath({
    path: APIRoute.WORKSHOP_SERVICE_SERVICE,
    params: { id },
  });
  return del(url);
};

export const getWorkshopPriceList = (params: URLSearchParams) => {
  return get(APIRoute.WORKSHOP_SERVICE_PRICES, params);
};

export const getWorkshopPrice = (brand: string, model?: string) => {
  let url = '';
  if (model) {
    url = createPath({
      path: APIRoute.WORKSHOP_SERVICE_PRICE_BRAND_MODEL,
      params: { brand, model },
    });
  } else {
    url = createPath({
      path: APIRoute.WORKSHOP_SERVICE_PRICE_BRAND,
      params: { brand },
    });
  }
  return get(url);
};

export type CreateWorkshopServicePriceParams = {
  key: string;
  value?: number | null;
  active?: boolean;
};

export type UpdateWorkshopServicePriceParams = CreateWorkshopServicePriceParams & {
  delete?: boolean;
};

export type UpdateWorkshopPriceParams = UpdateWorkshopServicePriceParams[];

export type CreateWorkshopPriceParams = {
  brand: string;
  brandSlug: string;
  model: string;
  modelSlug: string;
  services?: CreateWorkshopServicePriceParams[];
};

export const createWorkshopPrice = (requestBody: CreateWorkshopPriceParams) => {
  return post(APIRoute.WORKSHOP_SERVICE_PRICES, requestBody);
};

export const updateWorkshopPrice = (id: string, requestBody: UpdateWorkshopServicePriceParams[]) => {
  const url = createPath({
    path: APIRoute.WORKSHOP_SERVICE_PRICE,
    params: { id },
  });
  return put(url, requestBody);
};

export const deleteWorkshopPrice = (id: string) => {
  const url = createPath({
    path: APIRoute.WORKSHOP_SERVICE_PRICE,
    params: { id },
  });
  return del(url);
};

export const getWorkshopBrands = (params: URLSearchParams) => {
  return get(APIRoute.WORKSHOP_SERVICE_BRANDS, params);
};

export const getWorkshopBrand = (brand: string) => {
  const url = createPath({
    path: APIRoute.WORKSHOP_SERVICE_BRAND,
    params: { brand },
  });
  return get(url);
};

export const getWorkshopModels = (brand: string) => {
  const url = createPath({
    path: APIRoute.WORKSHOP_SERVICE_MODELS,
    params: { brand },
  });
  return get(url);
};
