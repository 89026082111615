import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../../state';
import { deleteDiscountCodesAction } from '../../../../state/ducks/commercetools/actions';
import { CommercetoolsDiscountCode } from '../../../../state/ducks/commercetools/types';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  code: string;
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
  discountCode: CommercetoolsDiscountCode | null;
};

const DeleteDiscountCodeModal: React.FC<Props> = ({ code, handleToggleModal, show, onSuccess, discountCode }) => {
  const dispatch = useAppDispatch();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const discountHasStarted =
    !discountCode?.validFrom || new Date().getTime() > new Date(discountCode?.validFrom).getTime();

  return (
    <>
      {showSnackbar && (
        <ActionStatusSnackbar actionName={deleteDiscountCodesAction.typePrefix} config={{ messageValues: { code } }} />
      )}
      <Dialog open={show} onClose={handleToggleModal}>
        <DialogTitle>
          <FormattedMessage
            id='productDiscounts_deleteModal_title'
            defaultMessage='Delete Discount Code "{code}"?'
            values={{ code }}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontWeight: 'medium' }}>
            <FormattedMessage id='common_warning_noUndo' defaultMessage='This action cannot be undone.' />
          </DialogContentText>
          {discountHasStarted && (
            <DialogContentText color='warning.main' sx={{ fontWeight: 'medium' }}>
              <FormattedMessage
                id='productDiscounts_deleteModal_discountHasStarted'
                defaultMessage='Please make sure that the code has not yet been communicated to customers.'
              />
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleModal}>
            <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
          </Button>
          <Button
            onClick={() => {
              dispatch(deleteDiscountCodesAction(code)).then((response) => {
                setShowSnackbar(true);
                if (response?.payload && !response.payload?.errors) {
                  handleToggleModal();
                  onSuccess();
                }
              });
            }}
            color='error'>
            <FormattedMessage id='common_delete' defaultMessage='Delete' />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteDiscountCodeModal;
