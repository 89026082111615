import moment from 'moment';
import 'moment-duration-format';

// Converts number of seconds to custom human-readable format
export const formatSeconds = (seconds: number, format = 'hh:mm:ss'): string => {
  return moment.duration(seconds, 'seconds').format(format);
};

// Converts human-readable format to number of seconds
export const formatDurationToSeconds = (duration: string): number => {
  const times = duration.split(':').map((str) => parseInt(str, 10));
  return times.reduce((acc, time) => {
    return 60 * acc + +time;
  });
};

// Converts a UTC datetime string to local datetime and returns a formatted string
export const formatUTCToLocal = (datetime?: string, format = 'DD.MM.YYYY HH:mm:ss'): string => {
  if (!datetime) return '';
  return moment.utc(datetime).local().format(format);
};

export const formatUTCString = (datetime?: string) =>
  datetime ? (!datetime.match(/\+\d{4}$/) ? `${datetime}+0000` : datetime) : '';
