import { Tooltip, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import * as React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { FormattedMessage } from 'react-intl';
import { TrackingHistory } from '../../../../state/ducks/tracking/types';
import { getAlpha2FromAlpha3, getCountryNameFromAlpha2 } from '../../../../utils/countryUtils';
import { formatUTCToLocal } from '../../../../utils/datetime';
import StatusBadge from '../../../components/badges/StatusBadge';
import SimpleTable, { SimpleTableColDef } from '../../../components/table/SimpleTable';

type Props = {
  history: TrackingHistory[];
};

// Column definitions for Tracking History table
const historyTableColumns: SimpleTableColDef[] = [
  {
    id: 'common_labels_number',
    headerName: 'No.',
    field: 'reference',
    width: 50,
    sortable: false,
    filterable: false,
    renderCell: (params: GridRenderCellParams) => `${params.api.getRowIndexRelativeToVisibleRows(params.id) + 1 || ''}`,
  },
  {
    id: 'common_labels_country',
    headerName: 'Country',
    field: 'countryCode',
    sortable: false,
    filterable: false,
    renderCell: (params: GridRenderCellParams) => {
      const countryCode = getAlpha2FromAlpha3(params.value);
      return (
        <Tooltip title={getCountryNameFromAlpha2(countryCode) || ''} arrow>
          <div>
            <ReactCountryFlag countryCode={countryCode} style={{ fontSize: '20px', lineHeight: '1em' }} />
          </div>
        </Tooltip>
      );
    },
  },
  {
    id: 'common_labels_city',
    headerName: 'City',
    field: 'city',
    minWidth: 250,
    sortable: false,
    filterable: false,
  },
  {
    id: 'common_labels_postalCode',
    headerName: 'Postal Code',
    field: 'postalCode',
    minWidth: 100,
    sortable: false,
    filterable: false,
  },
  {
    id: 'common_labels_status',
    headerName: 'Status',
    field: 'tag',
    minWidth: 150,
    sortable: false,
    filterable: false,
    renderCell: (params: GridRenderCellParams) => <StatusBadge status={params.row.tag} showIcon size='small' />,
  },
  {
    id: 'tracking_labels_statusSubtag',
    headerName: 'Status Subtag',
    field: 'subtag',
    minWidth: 150,
    sortable: false,
    filterable: false,
  },
  {
    id: 'common_labels_statusMessage',
    headerName: 'Status Message',
    field: 'message',
    minWidth: 400,
    sortable: false,
    filterable: false,
  },
  {
    id: 'tracking_labels_checkpointTime',
    headerName: 'Checkpoint Time',
    field: 'checkpointTime',
    valueGetter: ({ value }) => value && formatUTCToLocal(value),
    minWidth: 150,
    sortable: false,
    filterable: false,
  },
];

const TrackingHistoryTable: React.FC<Props> = ({ history }) => (
  <SimpleTable columns={historyTableColumns} rows={history || []} />
);

export default TrackingHistoryTable;
