export enum WorkshopPricesActions {
  getWorkshopServices = 'workshopPrices/getWorkshopServices',
  updateWorkshopService = 'workshopPrices/updateWorkshopService',
  createWorkshopService = 'workshopPrices/createWorkshopService',
  deleteWorkshopService = 'workshopPrices/deleteWorkshopService',
  getWorkshopPrices = 'workshopPrices/getWorkshopPrices',
  getWorkshopPrice = 'workshopPrices/getWorkshopPrice',
  updateWorkshopPrice = 'workshopPrices/updateWorkshopPrice',
  createWorkshopPrice = 'workshopPrices/createWorkshopPrice',
  deleteWorkshopPrice = 'workshopPrices/deleteWorkshopPrice',
  getWorkshopBrands = 'workshopPrices/getWorkshopBrands',
  getWorkshopBrand = 'workshopPrices/getWorkshopBrand',
  getWorkshopModels = 'workshopPrices/getWorkshopModels',
}

export type Service = {
  id: string;
  key: string;
  name: string;
  active: boolean;
  dateCreated: string;
  dateUpdated: string;
};

export type PriceServices = {
  [key: string]: {
    value: number | null;
    active?: boolean;
  };
};

export type Price = {
  id: string;
  brand: string | null;
  brandSlug: string | null;
  model: string | null;
  modelSlug: string | null;
  services: PriceServices;
  createdAt: string;
};

export type Brand = {
  id: string;
  name: string;
  slug: string;
};

export type Model = {
  id: string;
  name: string;
  slug: string;
  brand: Brand;
};

export type WorkshopPricesState = {
  services: Service[];
  servicesTotal: number;
  prices: Price[];
  pricesTotal: number;
  brands: Brand[];
  brandsTotal: number;
  price: Price | null;
};
