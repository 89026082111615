import { Stack } from '@mui/material';
import React from 'react';
import WorkshopItemBookingHistoryTable from '../../components/workshop-tables/WorkshopItemBookingHistoryTable';
import AddBookingForm from './components/AddBookingForm';

const WorkshopItemBooking: React.FC = () => {
  return (
    <Stack sx={{ position: 'relative', height: '100%' }}>
      <AddBookingForm />
      <WorkshopItemBookingHistoryTable />
    </Stack>
  );
};

export default WorkshopItemBooking;
