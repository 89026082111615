import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { FormattedMessage } from 'react-intl';
import { commercetoolsStateSelector } from '../../../../selectors/commercetools';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { getCategoriesAction, getDiscountBatchJobAction } from '../../../../state/ducks/commercetools/actions';
import StatusBadge from '../../../components/badges/StatusBadge';

type Props = {
  jobId: string;
  show: boolean;
  handleToggleModal: () => void;
};

enum Status {
  COMPLETE = 'complete',
  PENDING = 'pending',
  FAILED = 'failed',
}

const BatchJobDetailsModal: React.FC<Props> = ({ jobId, handleToggleModal, show }) => {
  const dispatch = useAppDispatch();
  const { categories, discountBatchJob: batchJob } = useAppSelector(commercetoolsStateSelector);

  useEffect(() => {
    dispatch(getDiscountBatchJobAction(jobId));
  }, []);

  useEffect(() => {
    if (!categories.length) {
      const urlSearchParams = new URLSearchParams();
      dispatch(getCategoriesAction(urlSearchParams));
    }
  }, []);

  const getStatus = () => {
    let status = '';
    const today = new Date().setHours(0, 0, 0, 0);
    const createdAt = new Date(batchJob.createdAt).setHours(0, 0, 0, 0);

    if (batchJob?.postCount === batchJob?.prototype?.amount) {
      status = Status.COMPLETE;
    } else if (createdAt < today) {
      status = Status.FAILED;
    } else {
      status = Status.PENDING;
    }
    return status;
  };

  if (!jobId || !batchJob) {
    return null;
  }

  return (
    <Dialog open={show} onClose={handleToggleModal} maxWidth='xs' fullWidth>
      <DialogTitle>
        <Stack direction='row' justifyContent='space-between'>
          <FormattedMessage
            id='productDiscountBatchJobs_detailsModal_title'
            defaultMessage='Details for Batch Job {id}'
            values={{ id: batchJob?.id }}
          />
          <StatusBadge status={getStatus()} />
        </Stack>
      </DialogTitle>
      {batchJob.prototype && (
        <DialogContent>
          <Stack spacing={2}>
            <Box>
              <DialogContentText fontWeight='medium' mb={1} color='primary' borderBottom='1px solid'>
                <FormattedMessage id='productDiscounts_labels_code' defaultMessage='Code' />
              </DialogContentText>
              <DialogContentText color='text'>{batchJob?.prototype?.code}</DialogContentText>
            </Box>
            <Box>
              <DialogContentText fontWeight='medium' mb={1} color='primary' borderBottom='1px solid'>
                <FormattedMessage id='productDiscounts_labels_amount' defaultMessage='Amount' />
              </DialogContentText>
              <DialogContentText color='text'>
                {batchJob?.postCount} / {batchJob?.prototype?.amount}
              </DialogContentText>
            </Box>
            <Box>
              <DialogContentText fontWeight='medium' mb={1} color='primary' borderBottom='1px solid'>
                <FormattedMessage id='productDiscounts_labels_groups' defaultMessage='Groups' />
              </DialogContentText>
              {batchJob?.prototype?.groups?.length ? (
                <DialogContentText color='text'>{batchJob?.prototype?.groups?.join(', ')}</DialogContentText>
              ) : (
                <DialogContentText color='warning.main'>
                  <FormattedMessage
                    id='productDiscounts_detailsModal_noGroups'
                    defaultMessage='This discount code has no groups.'
                  />
                </DialogContentText>
              )}
            </Box>
            <Box>
              <DialogContentText fontWeight='medium' mb={1} color='primary' borderBottom='1px solid'>
                <FormattedMessage id='productDiscounts_labels_categories' defaultMessage='Categories' />
              </DialogContentText>
              {batchJob?.prototype?.categories?.length ? (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {batchJob?.prototype?.categories.map((categoryKey) => (
                    <Chip key={categoryKey} label={categories.find((c) => c.id === categoryKey)?.name?.en} />
                  ))}
                </Box>
              ) : (
                <DialogContentText color='warning.main'>
                  <FormattedMessage
                    id='productDiscounts_detailsModal_noCategories'
                    defaultMessage='This discount code has no categories.'
                  />
                </DialogContentText>
              )}
            </Box>
            <Box>
              <DialogContentText fontWeight='medium' mb={1} color='primary' borderBottom='1px solid'>
                <FormattedMessage id='productDiscounts_labels_countries' defaultMessage='Countries' />
              </DialogContentText>
              {batchJob?.prototype?.countries?.length ? (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {batchJob?.prototype?.countries?.map((country) => (
                    <Tooltip key={country} title={country} arrow>
                      <div>
                        <ReactCountryFlag countryCode={country} style={{ fontSize: '30px', lineHeight: '1em' }} />
                      </div>
                    </Tooltip>
                  ))}
                </Box>
              ) : (
                <DialogContentText color='success.main'>
                  <FormattedMessage
                    id='productDiscounts_detailsModal_allCountries'
                    defaultMessage='This discount code is valid for all countries.'
                  />
                </DialogContentText>
              )}
            </Box>
            <Box>
              <DialogContentText fontWeight='medium' mb={1} color='primary' borderBottom='1px solid'>
                <FormattedMessage id='productDiscounts_labels_discountTiers' defaultMessage='Discount Tiers' />
              </DialogContentText>
              <TableContainer component={Paper}>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id='productDiscounts_labels_minCartValue' defaultMessage='Min cart value' />
                      </TableCell>
                      <TableCell>
                        {batchJob?.prototype?.isPercentValue ? (
                          <FormattedMessage
                            id='productDiscounts_labels_discountPercentage'
                            defaultMessage='Discount Percentage'
                          />
                        ) : (
                          <FormattedMessage
                            id='productDiscounts_labels_discountValue'
                            defaultMessage='Discount Value'
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(batchJob?.prototype?.discounts)?.map(([key, value]) => (
                      <TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>{key}</TableCell>
                        <TableCell>
                          {value}
                          {batchJob?.prototype?.isPercentValue && '%'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Stack>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleToggleModal}>
          <FormattedMessage id='common_ok' defaultMessage='Ok' />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BatchJobDetailsModal;
