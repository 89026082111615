import { createSelector, ParametricSelector } from '@reduxjs/toolkit';
import { RootState } from '../../state';
import { GROUPS } from '../../constants/groups';
import { User } from '../../state/ducks/user/types';

export const userStateSelector = (state: RootState) => state.user;

export const currentUserSelector = (state: RootState) => state.user.currentUser;

export const userAuthStateSelector = (state: RootState) => {
  const isAuthenticated = !!state.user.currentUser.username;
  return {
    isAuthenticated: isAuthenticated,
    groups: isAuthenticated ? state.user.currentUser.groups : [],
  };
};

export const userLangSelector = (state: RootState) => state.user.currentUser.language;

export const userColorModeSelector = (state: RootState) => state.user.currentUser.colorMode;

export const allUsersSelector = (state: RootState) => state.user.users;

export const checkGroupPermission = (
  authorizedGroups: string[] | undefined,
  user: User,
  ignoreAdmin?: boolean,
): boolean => {
  if (typeof authorizedGroups === 'undefined' || !authorizedGroups.length) {
    return true;
  }

  if (!ignoreAdmin && user.groups.includes(GROUPS.ADMIN)) {
    if (user.viewAsGroup) {
      return authorizedGroups.includes(user.viewAsGroup);
    }
    return true;
  }

  return user.groups.some((group) => authorizedGroups.includes(group));
};

export const isAuthorizedSelector: ParametricSelector<RootState, string[] | undefined, boolean> = createSelector(
  userStateSelector,
  (state: RootState, authorizedGroups: string[] | undefined, ignoreAdmin?: boolean) => ({
    authorizedGroups,
    ignoreAdmin,
  }),
  (userState, props) => checkGroupPermission(props.authorizedGroups, userState.currentUser, props.ignoreAdmin),
);
