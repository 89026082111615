import { Box, Button, Drawer, MenuItem, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../state';
import { updateWorkshopProcessAction } from '../../../state/ducks/workshop/actions';
import { formatDurationToSeconds } from '../../../utils/datetime';
import ActionStatusSnackbar from '../snackbars/ActionStatusSnackbar';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  process: Record<string, any>;
  onSuccess: () => void;
};

const statusOptions = ['pending', 'in_progress', 'paused', 'complete'];

const initialFormData = {
  status: '',
  elapsedTime: '',
};

const WorkshopProcessUpdateForm: React.FC<Props> = ({ isOpen, onClose, process, onSuccess }) => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState(initialFormData);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const onUpdateWorkshopProcessClick = () => {
    const { workshopItemReference, reference } = process;
    const requestBody = {
      ...formData,
      elapsedTime: formatDurationToSeconds(formData.elapsedTime),
    };
    dispatch(
      updateWorkshopProcessAction({
        workshopItemReference,
        reference,
        requestBody,
      }),
    ).then((response) => {
      setShowSnackbar(true);
      if (response?.payload) {
        onSuccess();
        onClose();
      }
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    setFormData({
      status: process.status,
      elapsedTime: process.elapsedTime,
    });
  }, [process]);

  return (
    <>
      {showSnackbar && <ActionStatusSnackbar actionName={updateWorkshopProcessAction.typePrefix} />}
      <Drawer anchor='right' open={isOpen} onClose={onClose}>
        <Box sx={{ padding: '15px' }}>
          <Typography variant='h5'>
            <FormattedMessage id='workshop_processes_updateProcess' defaultMessage='Update Process' />
          </Typography>
          <Stack sx={{ minWidth: '300px', marginTop: '30px' }}>
            <TextField
              id='status'
              name='status'
              label={<FormattedMessage id='common_labels_status' defaultMessage='Status' />}
              value={formData.status}
              onChange={handleChange}
              size='small'
              margin='dense'
              select
              fullWidth>
              {statusOptions.map((option, index) => (
                <MenuItem key={index} value={option} disabled={option === process.status}>
                  <FormattedMessage id={`status_${option}`} defaultMessage={option} />
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id='elapsedTime'
              name='elapsedTime'
              label={<FormattedMessage id='workshop_processes_labels_elapsedTime' defaultMessage='ElapsedTime' />}
              helperText={
                <FormattedMessage
                  id='workshop_processes_labels_elapsedTimeHint'
                  defaultMessage='Format: ss, mm:ss or hh:mm:ss'
                />
              }
              value={formData.elapsedTime}
              onChange={handleChange}
              size='small'
              margin='dense'
              fullWidth
            />
            <Button
              variant='contained'
              color='secondary'
              size='small'
              fullWidth={true}
              disableElevation={true}
              sx={{ marginTop: '50px' }}
              disabled={formData.status === process.status && parseInt(formData.elapsedTime, 10) <= 0}
              onClick={onUpdateWorkshopProcessClick}>
              <FormattedMessage id='workshop_processes_updateProcess' defaultMessage='Update Process' />
            </Button>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default WorkshopProcessUpdateForm;
