export enum APIRoute {
  AUTH_USER = '/auth/me/',
  AUTH_SAML_SSO = '/auth/-/saml/sso/',
  AUTH_ACCESS_TOKEN = '/auth/token/',
  AUTH_LOGOUT = '/auth/logout/',
  TRACKING_SALES = '/tracking/sales/',
  TRACKING_SALES_DETAILS = '/tracking/sales/:reference/',
  TRACKING_PURCHASES = '/tracking/purchases/',
  TRACKING_PURCHASES_DETAILS = '/tracking/purchases/:reference/',
  TRACKING_TRANSFERS = '/tracking/transferOrders/',
  TRACKING_TRANSFERS_DETAILS = '/tracking/transferOrders/:reference/',
  TRACKING_REPORTS_EXPORT = '/tracking/reports/exportCSV/',
  TRACKING_COMMENTS = '/trackings/:reference/comments/',
  TRACKING_COMMENTS_UPDATE = '/trackings/:reference/comments/:commentId/',
  WORKSHOP_ITEMS = '/workshop/items/',
  WORKSHOP_ITEM = '/workshop/items/:reference/',
  WORKSHOP_ACTIVITIES = '/workshop/activities/',
  WORKSHOP_CREATE_PROCESS = '/workshop/items/:workshopItemReference/processes/',
  WORKSHOP_UPDATE_PROCESS = '/workshop/items/:workshopItemReference/processes/:processReference/',
  WORKSHOP_PROCESSES = '/workshop/items/-/processes/',
  WORKSHOP_REPORTS = '/workshop/reports/',
  WORKSHOP_LOCATIONS = '/workshop/locations/',
  WORKSHOP_LOCATION_UPDATE = '/workshop/locations/:locationReference/',
  WORKSHOP_LOCATIONS_OVERVIEW = '/workshop/locations/-/overview/',
  WORKSHOP_BOOKING_ITEM = '/workshop/locations/:locationReference/items/:workshopItemReference/',
  SELL_REFRESH_SWS = '/sellRequests/name:downloadSellRequests/',
  SELL_REQUESTS = '/sellRequests/',
  SELL_REQUEST = '/sellRequests/:reference/',
  SELL_REQUEST_RESUBMISSION = '/sellRequests/:reference/reSubmission/',
  SELL_REQUEST_CANCEL = '/sellRequests/:reference/cancel/',
  SELL_REQUEST_STATUS = '/sellRequests/:reference/status/',
  SELL_OFFER = '/sellRequests/:reference/offer/',
  SELL_REQUEST_EXPIRATION_DATE = '/sellRequests/:reference/expiration_date/',
  SELL_REQUEST_VENDOR_INFORMATION = '/sellRequests/:reference/payment_details_mailing/',
  SELL_REQUEST_VENDOR_INFORMATION_UPDATE = '/sellRequests/:reference/customer-details/',
  SELL_EXISTING_OFFERS = '/sellRequests/existing-offers/',
  SELL_REQUEST_REFRESH = '/sellRequests/name:downloadSellRequests/',
  SELL_REQUEST_LIST = '/sellRequests/',
  SELL_REQUEST_DETAILS = '/sellRequests/:reference/',
  SELL_REQUEST_OFFER = '/sellRequests/:reference/offer/',
  SELL_REQUEST_AGENT_UPDATE = '/sellRequests/:reference/add-or-update-agent/',
  INVENTORY_PRODUCTS = '/inventory/products/',
  INVENTORY_PRODUCT = '/inventory/products/:reference/',
  INVENTORY_VARIANTS = '/inventory/variants/',
  INVENTORY_VARIANT = '/inventory/variants/:reference/',
  INVENTORY_ITEMS = '/inventory/items/',
  INVENTORY_ITEM = '/inventory/items/:reference/',
  COMMERCETOOLS_CATEGORIES = '/ext/commercetoolsService/api/category/',
  COMMERCETOOLS_CATEGORIES_CREATE = '/ext/commercetoolsService/api/category/create/',
  COMMERCETOOLS_CATEGORIES_DELETE = '/ext/commercetoolsService/api/category/delete/:key/',
  COMMERCETOOLS_CATEGORIES_CLEAR = '/ext/commercetoolsService/api/category/clear/:key/',
  COMMERCETOOLS_CATEGORIES_COUNT = '/ext/commercetoolsService/api/category/count/:key/',
  COMMERCETOOLS_CATEGORIES_ADD_SKUS = '/ext/commercetoolsService/api/category/add/:key/',
  COMMERCETOOLS_CATEGORIES_SET_REMOVE_SKUS = '/ext/commercetoolsService/api/category/skus/:key/',
  COMMERCETOOLS_DISCOUNT_CODES = '/ext/commercetoolsService/api/discount/',
  COMMERCETOOLS_DISCOUNT_CODES_CREATE = '/ext/commercetoolsService/api/discount/create/',
  COMMERCETOOLS_DISCOUNT_CODE_UPDATE = '/ext/commercetoolsService/api/discount/edit/:code/',
  COMMERCETOOLS_DISCOUNT_CODE_DELETE = '/ext/commercetoolsService/api/discount/delete/:code/',
  COMMERCETOOLS_DISCOUNT_CODE_BATCH_JOBS = '/ext/commercetoolsService/api/discount/job/',
  COMMERCETOOLS_DISCOUNT_CODE_BATCH_JOB = '/ext/commercetoolsService/api/discount/job/:id/',
  COMMERCETOOLS_DISCOUNT_CODE_BATCH_JOB_EXPORT = '/ext/commercetoolsService/api/discount/job/:id/export/',
  COMMERCETOOLS_DISCOUNT_CODE_BATCH_JOB_CREATE = '/ext/commercetoolsService/api/discount/create/batch/',
  WORKSHOP_SERVICE_SERVICES = '/ext/workshopService/services/',
  WORKSHOP_SERVICE_SERVICE = '/ext/workshopService/services/:id/',
  WORKSHOP_SERVICE_PRICES = '/ext/workshopService/prices/',
  WORKSHOP_SERVICE_PRICE = '/ext/workshopService/prices/:id/',
  WORKSHOP_SERVICE_PRICE_BRAND = '/ext/workshopService/prices/:brand/',
  WORKSHOP_SERVICE_PRICE_BRAND_MODEL = '/ext/workshopService/prices/:brand/:model/',
  WORKSHOP_SERVICE_BRANDS = '/ext/workshopService/brands/',
  WORKSHOP_SERVICE_BRAND = '/ext/workshopService/brands/:brand/',
  WORKSHOP_SERVICE_MODELS = '/ext/workshopService/models/:brand/',
  WORKSHOP_INBOUND_REQUESTS = '/ext/workshopInboundService/workshop-requests/',
  WORKSHOP_INBOUND_WATCHES = '/ext/workshopInboundService/watches/',
  WORKSHOP_INBOUND_WATCH = '/ext/workshopInboundService/watches/:reference/',
  WORKSHOP_INBOUND_WATCH_SERVICES = '/ext/workshopInboundService/watches/:reference/set-services/',
  WORKSHOP_INBOUND_ACTIVITIES = '/ext/workshopInboundService/activities/',
  WORKSHOP_INBOUND_MAINTENANCE_ACTIVITY_STATUS = '/ext/workshopInboundService/activities/maintenance/status/',
  WORKSHOP_INBOUND_ACTIVITY = '/ext/workshopInboundService/activities/:reference/',
  WORKSHOP_INBOUND_ACTIVITY_REPORT = '/ext/workshopInboundService/activities/:reference/activity-report/',
  WORKSHOP_INBOUND_ACTIVITY_REPORTS = '/ext/workshopInboundService/activity-reports/',
  WORKSHOP_INBOUND_ACTIVITY_REPORTS_DETAILS = '/ext/workshopInboundService/activity-reports/:reference/',
  WORKSHOP_INBOUND_ACTIVITY_REPORT_FIELDS = '/ext/workshopInboundService/activity-reports/fields/',
  WORKSHOP_INBOUND_SERVICE = '/ext/workshopInboundService/services/:reference/',
  WORKSHOP_INBOUND_SERVICE_QUOTATION = '/ext/workshopInboundService/services/send-quotation/',
  WORKSHOP_INBOUND_REPORTS_ACTIVITY = '/ext/workshopInboundService/reports/activity-reports',
  WORKSHOP_INBOUND_REPORTS_ITEM_OVERVIEW = '/ext/workshopInboundService/reports/watch-reports',
  WORKSHOP_INBOUND_SPARE_PARTS = '/ext/workshopInboundService/watches/:reference/spare-parts/',
  WORKSHOP_INBOUND_EXTERNAL_WORKSHOP_DETAILS = '/ext/workshopInboundService/external-workshop/:reference/',
  PRICING = '/pricing/calculator/',
  PRICING_CONFIG = '/pricing/calculator-setup/',
  PRICING_CONFIG_BRANDS = '/pricing/calculator-setup/brands',
  PRICING_CONFIG_MODELS = '/pricing/calculator-setup/models',
  PRICING_CONFIG_REFERENCES = '/pricing/calculator-setup/references',
  USERS = '/users/',
}

export type PathArgs =
  | { path: APIRoute.AUTH_USER | APIRoute.AUTH_SAML_SSO | APIRoute.AUTH_ACCESS_TOKEN }
  | { path: APIRoute.TRACKING_SALES | APIRoute.TRACKING_PURCHASES | APIRoute.TRACKING_TRANSFERS }
  | {
      path: APIRoute.TRACKING_SALES_DETAILS | APIRoute.TRACKING_PURCHASES_DETAILS | APIRoute.TRACKING_TRANSFERS_DETAILS;
      params: { reference: string };
    }
  | { path: APIRoute.TRACKING_REPORTS_EXPORT }
  | { path: APIRoute.TRACKING_COMMENTS; params: { reference: string } }
  | { path: APIRoute.TRACKING_COMMENTS_UPDATE; params: { reference: string; commentId: string } }
  | {
      path: APIRoute.WORKSHOP_REPORTS;
      params: { reportType: string; startTime: string; endTime: string };
    }
  | {
      path: APIRoute.WORKSHOP_PROCESSES;
      params: { startTime: string; endTime: string };
    }
  | { path: APIRoute.WORKSHOP_ACTIVITIES }
  | { path: APIRoute.WORKSHOP_ITEMS }
  | { path: APIRoute.WORKSHOP_ITEM; params: { reference: string } }
  | {
      path: APIRoute.WORKSHOP_CREATE_PROCESS;
      params: { workshopItemReference: string };
    }
  | {
      path: APIRoute.WORKSHOP_UPDATE_PROCESS;
      params: { workshopItemReference: string; processReference: string };
    }
  | { path: APIRoute.WORKSHOP_LOCATIONS | APIRoute.WORKSHOP_LOCATIONS_OVERVIEW }
  | { path: APIRoute.WORKSHOP_LOCATION_UPDATE; params: { locationReference: number } }
  | { path: APIRoute.WORKSHOP_BOOKING_ITEM; params: { locationReference: number; workshopItemReference: string } }
  | { path: APIRoute.SELL_REQUEST_REFRESH }
  | { path: APIRoute.SELL_REQUEST_LIST }
  | { path: APIRoute.SELL_REQUEST_DETAILS; params: { reference: string } }
  | { path: APIRoute.SELL_REQUEST_OFFER; params: { reference: string } }
  | { path: APIRoute.INVENTORY_PRODUCTS | APIRoute.INVENTORY_VARIANTS | APIRoute.INVENTORY_ITEMS }
  | {
      path: APIRoute.INVENTORY_PRODUCT | APIRoute.INVENTORY_VARIANT | APIRoute.INVENTORY_ITEM;
      params: { reference: string };
    }
  | { path: APIRoute.COMMERCETOOLS_CATEGORIES | APIRoute.COMMERCETOOLS_CATEGORIES_CREATE }
  | {
      path:
        | APIRoute.COMMERCETOOLS_CATEGORIES_CLEAR
        | APIRoute.COMMERCETOOLS_CATEGORIES_DELETE
        | APIRoute.COMMERCETOOLS_CATEGORIES_COUNT
        | APIRoute.COMMERCETOOLS_CATEGORIES_ADD_SKUS
        | APIRoute.COMMERCETOOLS_CATEGORIES_SET_REMOVE_SKUS;
      params: { key: string };
    }
  | {
      path:
        | APIRoute.COMMERCETOOLS_DISCOUNT_CODES
        | APIRoute.COMMERCETOOLS_DISCOUNT_CODES_CREATE
        | APIRoute.COMMERCETOOLS_DISCOUNT_CODE_BATCH_JOBS;
    }
  | {
      path: APIRoute.COMMERCETOOLS_DISCOUNT_CODE_UPDATE | APIRoute.COMMERCETOOLS_DISCOUNT_CODE_DELETE;
      params: { code: string };
    }
  | {
      path: APIRoute.COMMERCETOOLS_DISCOUNT_CODE_BATCH_JOB | APIRoute.COMMERCETOOLS_DISCOUNT_CODE_BATCH_JOB_EXPORT;
      params: { id: string };
    }
  | { path: APIRoute.WORKSHOP_SERVICE_SERVICES | APIRoute.WORKSHOP_SERVICE_PRICES | APIRoute.WORKSHOP_SERVICE_BRANDS }
  | { path: APIRoute.WORKSHOP_SERVICE_SERVICE | APIRoute.WORKSHOP_SERVICE_PRICE; params: { id: string } }
  | {
      path:
        | APIRoute.WORKSHOP_SERVICE_PRICE_BRAND
        | APIRoute.WORKSHOP_SERVICE_BRAND
        | APIRoute.WORKSHOP_SERVICE_MODELS
        | APIRoute.PRICING_CONFIG_MODELS;
      params: { brand: string };
    }
  | {
      path: APIRoute.WORKSHOP_SERVICE_PRICE_BRAND_MODEL | APIRoute.PRICING_CONFIG_REFERENCES;
      params: { brand: string; model?: string };
    }
  | { path: APIRoute.SELL_REFRESH_SWS | APIRoute.SELL_REQUESTS }
  | {
      path:
        | APIRoute.SELL_REQUEST
        | APIRoute.SELL_REQUEST_RESUBMISSION
        | APIRoute.SELL_REQUEST_CANCEL
        | APIRoute.SELL_REQUEST_STATUS
        | APIRoute.SELL_OFFER
        | APIRoute.SELL_REQUEST_EXPIRATION_DATE
        | APIRoute.SELL_REQUEST_VENDOR_INFORMATION
        | APIRoute.SELL_REQUEST_VENDOR_INFORMATION_UPDATE
        | APIRoute.SELL_REQUEST_AGENT_UPDATE;
      params: { reference: string };
    }
  | {
      path:
        | APIRoute.WORKSHOP_INBOUND_REQUESTS
        | APIRoute.WORKSHOP_INBOUND_WATCHES
        | APIRoute.WORKSHOP_INBOUND_ACTIVITIES
        | APIRoute.WORKSHOP_INBOUND_ACTIVITY_REPORTS
        | APIRoute.WORKSHOP_INBOUND_ACTIVITY_REPORT_FIELDS
        | APIRoute.WORKSHOP_INBOUND_SERVICE_QUOTATION
        | APIRoute.WORKSHOP_INBOUND_REPORTS_ACTIVITY
        | APIRoute.WORKSHOP_INBOUND_REPORTS_ITEM_OVERVIEW;
    }
  | {
      path:
        | APIRoute.WORKSHOP_INBOUND_WATCH
        | APIRoute.WORKSHOP_INBOUND_WATCH_SERVICES
        | APIRoute.WORKSHOP_INBOUND_ACTIVITY
        | APIRoute.WORKSHOP_INBOUND_ACTIVITY_REPORT
        | APIRoute.WORKSHOP_INBOUND_ACTIVITY_REPORTS_DETAILS
        | APIRoute.WORKSHOP_INBOUND_SERVICE
        | APIRoute.WORKSHOP_INBOUND_SPARE_PARTS
        | APIRoute.WORKSHOP_INBOUND_EXTERNAL_WORKSHOP_DETAILS;
      params: { reference: string };
    }
  | { path: APIRoute.PRICING | APIRoute.PRICING_CONFIG | APIRoute.PRICING_CONFIG_BRANDS }
  | { path: APIRoute.USERS };
