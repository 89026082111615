import { getDefaultFromSlug, slugify, unslugify } from '@chronext/react-common';
import { createReducer } from '@reduxjs/toolkit';
import {
  getPricingAction,
  getPricingConfigAction,
  getPricingConfigBrandsAction,
  getPricingConfigModelsAction,
  getPricingConfigReferencesAction,
} from './actions';
import { PricingState } from './types';

const initialState: PricingState = {
  calculatorResult: {
    prices: null,
    sampleCount: 0,
    item: {
      brand: '',
      model: '',
      reference: '',
      dialColor: '',
      condition: '',
      papers: false,
      box: false,
      braceletType: '',
    },
    weekEndingDate: '',
    margins: null,
    modelledData: null,
  },
  marginConfig: {
    totalCount: 0,
    marginItems: [],
    brands: [],
    models: [],
    references: [],
  },
};

const pricingReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getPricingAction.fulfilled, (state, action) => {
      const {
        marketPrice,
        tradeIn,
        commission,
        direct,
        basedOn,
        weekEndingDate,
        sampleCount,
        yearsAndPrices,
        minMarketPrice,
        maxMarketPrice,
        isModelled,
        MAE,
        absoluteErrorRange,
        absoluteErrorPercentage,
      } = action.payload;

      state.calculatorResult = {
        prices: {
          marketPrice,
          minMarketPrice,
          maxMarketPrice,
          tradeIn,
          commission,
          direct,
          yearsAndPrices,
        },
        sampleCount,
        item: {
          brand: String(getDefaultFromSlug(basedOn?.brand, '').brand || '') || unslugify(basedOn?.brand),
          model: String(getDefaultFromSlug(basedOn?.brand, basedOn?.model).model || '') || unslugify(basedOn?.model),
          reference: basedOn?.reference,
          dialColor: basedOn?.dialColor,
          condition: basedOn?.condition,
          papers: !!basedOn?.papers,
          box: !!basedOn?.box,
          braceletType: basedOn?.braceletType,
        },
        weekEndingDate,
        margins: basedOn?.margins,
        modelledData: {
          isModelled: !!isModelled,
          mae: MAE,
          absoluteErrorRange,
          absoluteErrorPercentage,
        },
      };
    })
    .addCase(getPricingAction.rejected, (state, action) => {
      state.calculatorResult = initialState.calculatorResult;
    })
    .addCase(getPricingConfigAction.fulfilled, (state, action) => {
      state.marginConfig.marginItems = action.payload?.items || [];
      state.marginConfig.totalCount = action.payload?.total || action.payload?.items?.length || 0;
    })
    .addCase(getPricingConfigBrandsAction.fulfilled, (state, action) => {
      state.marginConfig.brands = action.payload?.brands?.map((brand: string) => slugify(brand)) || [];
    })
    .addCase(getPricingConfigModelsAction.fulfilled, (state, action) => {
      state.marginConfig.models = action.payload?.models?.map((model: string) => slugify(model)) || [];
    })
    .addCase(getPricingConfigReferencesAction.fulfilled, (state, action) => {
      state.marginConfig.references =
        action.payload?.references?.map((reference: string) => reference.toLowerCase()) || [];
    });
});

export default pricingReducer;
