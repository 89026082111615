import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { commercetoolsCategoryProductCountSelector } from '../../../../selectors/commercetools';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { getCategoryProductCountAction } from '../../../../state/ducks/commercetools/actions';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  categoryKey: string;
  show: boolean;
  handleToggleModal: () => void;
};

const CategoryDetailsModal: React.FC<Props> = ({ categoryKey, handleToggleModal, show }) => {
  const dispatch = useAppDispatch();
  const categoryProductCount = useAppSelector(commercetoolsCategoryProductCountSelector);

  useEffect(() => {
    dispatch(getCategoryProductCountAction(categoryKey));
  }, []);

  return (
    <>
      <ActionStatusSnackbar actionName={getCategoryProductCountAction.typePrefix} />
      <Dialog open={show} onClose={handleToggleModal}>
        <DialogContent>
          <DialogContentText sx={{ fontWeight: 'medium' }}>
            <FormattedMessage
              id='productCategories_detailsModal_productCount'
              defaultMessage='Product Count: {count}'
              values={{ count: categoryProductCount }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleModal}>
            <FormattedMessage id='common_ok' defaultMessage='Ok' />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CategoryDetailsModal;
