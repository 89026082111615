import { createAsyncThunk } from '@reduxjs/toolkit';
import algoliasearch from 'algoliasearch';
import { AlgoliaActions } from './types';

const SEARCH_INDEX_NAME = process.env.REACT_APP_ALGOLIA_SELLWATCH_INDEX || '';
const ALGOLIA_APP_ID = 'JSHHENYLXM';
const ALGOLIA_API_KEY = '9ec77ba9eab04001bbc64a90a05e2caa';

export const getAlgoliaBrandsAction = createAsyncThunk(AlgoliaActions.getBrands, async (params: URLSearchParams) => {
  const algolia = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);
  const index = algolia.initIndex(SEARCH_INDEX_NAME);

  return await index.search('', { facets: ['*'] });
});

export const getAlgoliaModelsForBrandAction = createAsyncThunk(AlgoliaActions.getModels, async (brand: string) => {
  const algolia = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);
  const index = algolia.initIndex(SEARCH_INDEX_NAME);

  return await index.search('', { filters: `brand:${brand}`, facets: ['brand', 'model'] });
});
