import { TabContext, TabPanel } from '@mui/lab';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import SellRequestsOverviewTable from './SellRequestsOverviewTable';
import SellRequestsStatusTable from './SellRequestsStatusTable';
import { NavLink, useParams } from 'react-router-dom';
import { ClientRoute, createPath } from '../../../routing';

type Params = {
  [key: string]: string;
};

const tabProps = {
  sx: {
    flex: 1,
    padding: 0,
  },
};

const SellRequests: React.FC = () => {
  const { tab } = useParams<Params>();

  return (
    <TabContext value={tab}>
      <Stack sx={{ height: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 3 }}>
          <Tabs selectionFollowsFocus value={tab}>
            <Tab
              value='all'
              component={NavLink}
              to={createPath({ path: ClientRoute.SELL_TABS, params: { tab: 'all' } })}
              label={<FormattedMessage id='sellRequests_tabs_overview' defaultMessage='Overview' />}
            />
            <Tab
              value='pending'
              component={NavLink}
              to={createPath({ path: ClientRoute.SELL_TABS, params: { tab: 'pending' } })}
              label={<FormattedMessage id='status_cxt_pending' defaultMessage='CXT - Action Required' />}
            />
            <Tab
              value='waiting'
              component={NavLink}
              to={createPath({ path: ClientRoute.SELL_TABS, params: { tab: 'waiting' } })}
              label={<FormattedMessage id='status_waiting_for_customer' defaultMessage='Waiting for Customer' />}
            />
            <Tab
              value='cancelled'
              component={NavLink}
              to={createPath({ path: ClientRoute.SELL_TABS, params: { tab: 'cancelled' } })}
              label={<FormattedMessage id='status_cancelled' defaultMessage='cancelled' />}
            />
            <Tab
              value='finalized'
              component={NavLink}
              to={createPath({ path: ClientRoute.SELL_TABS, params: { tab: 'finalized' } })}
              label={<FormattedMessage id='status_finalized' defaultMessage='finalized' />}
            />
          </Tabs>
        </Box>
        <TabPanel value='all' {...tabProps}>
          {tab === 'all' && <SellRequestsOverviewTable key='all' />}
        </TabPanel>
        <TabPanel value='pending' {...tabProps}>
          {tab === 'pending' && <SellRequestsStatusTable key='cxt_pending' status='cxt_pending' />}
        </TabPanel>
        <TabPanel value='waiting' {...tabProps}>
          {tab === 'waiting' && <SellRequestsStatusTable key='waiting_for_customer' status='waiting_for_customer' />}
        </TabPanel>
        <TabPanel value='cancelled' {...tabProps}>
          {tab === 'cancelled' && <SellRequestsStatusTable key='cancelled' status='cancelled' />}
        </TabPanel>
        <TabPanel value='finalized' {...tabProps}>
          {tab === 'finalized' && <SellRequestsStatusTable key='finalized' status='finalized' />}
        </TabPanel>
      </Stack>
    </TabContext>
  );
};

export default SellRequests;
