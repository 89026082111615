export const GROUPS = {
  ADMIN: 'Admin_OPS',
  SUPPLY_AGENT: 'Supply_Agent',
  SUPPLY_MANAGER: 'Supply_Manager',
  WORKSHOP_EMPLOYEE: 'Workshop_Employee',
  WORKSHOP_ADMIN: 'Workshop_Admin',
  MARKETING: 'Marketing',
  SALES_MANAGER: 'Sales_Manager',
  SALES_AGENT: 'Sales_Agent',
  OPERATIONS: 'Operations',
  CONTROLLING: 'Controlling',
  PRICING: 'Pricing',
  FPA: 'Financial_Planning_Analysis',
};
