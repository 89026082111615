export type WorkshopActivityReportFormData = {
  is_authentic: boolean;
  caliber?: string;
  manufacturer_serial_no?: string;
  warranty_date?: string;
  watch_type?: string;
  service_needed?: boolean;
  repairable?: boolean;
  internal_service?: boolean;
  spare_parts_needed?: boolean;
  spare_parts?: string;
  has_bracelet?: boolean;
  bracelet_type?: string;
  bracelet_material?: string;
  bracelet_supply_rolex_only?: string;
  case_material?: string;
  case_size?: number;
  case_shape?: string;
  crystal_material?: string;
  dial_color?: string;
  index_type?: string;
  movement_type?: string;
  dial_up_amplitude?: number;
  crown_down_amplitude?: number;
  bracelet_length?: number;
  current_condition?: string;
  polished_condition?: string;
  service_mandatory?: string;
  actual_reference?: string;
  comment?: string;
};

export type WorkshopReportQuestion = {
  id: keyof WorkshopActivityReportFormData;
  label: {
    id: string;
    defaultMessage: string;
  };
  type: 'boolean' | 'options' | 'text' | 'number' | 'date';
  maxLength?: number;
  required: boolean;
};

export const WORKSHOP_REPORT_QUESTIONS: WorkshopReportQuestion[] = [
  {
    id: 'is_authentic',
    label: {
      id: 'workshop_report_labels_questions_authenticity',
      defaultMessage: 'Is the watch authentic?',
    },
    type: 'boolean',
    required: true,
  },
  {
    id: 'caliber',
    label: {
      id: 'workshop_report_labels_questions_caliber',
      defaultMessage: 'Caliber',
    },
    type: 'text',
    maxLength: 50,
    required: false,
  },
  {
    id: 'manufacturer_serial_no',
    label: {
      id: 'workshop_report_labels_questions_manufacturerSerialNumber',
      defaultMessage: 'Manufacturer serial number',
    },
    type: 'text',
    required: true,
  },
  {
    id: 'warranty_date',
    label: {
      id: 'workshop_report_labels_questions_warrantyDate',
      defaultMessage: 'Warranty Date',
    },
    type: 'date',
    required: false,
  },
  {
    id: 'watch_type',
    label: {
      id: 'workshop_report_labels_questions_watchType',
      defaultMessage: 'Watch type',
    },
    type: 'options',
    required: true,
  },
  {
    id: 'service_needed',
    label: {
      id: 'workshop_report_labels_questions_serviceNeeded',
      defaultMessage: 'Service needed',
    },
    type: 'boolean',
    required: true,
  },
  {
    id: 'repairable',
    label: {
      id: 'workshop_report_labels_questions_repairable',
      defaultMessage: 'Repairable',
    },
    type: 'boolean',
    required: false,
  },
  {
    id: 'internal_service',
    label: {
      id: 'workshop_report_labels_questions_internalService',
      defaultMessage: 'Internal service',
    },
    type: 'boolean',
    required: false,
  },
  {
    id: 'spare_parts_needed',
    label: {
      id: 'workshop_report_labels_questions_sparePartsNeeded',
      defaultMessage: 'Spare Parts needed',
    },
    type: 'boolean',
    required: false,
  },
  {
    id: 'spare_parts',
    label: {
      id: 'workshopInbound_labels_spareParts',
      defaultMessage: 'Spare Parts',
    },
    type: 'text',
    required: false,
  },
  {
    id: 'has_bracelet',
    label: {
      id: 'workshop_report_labels_questions_bracelet',
      defaultMessage: 'Bracelet',
    },
    type: 'boolean',
    required: true,
  },
  {
    id: 'bracelet_type',
    label: {
      id: 'common_labels_braceletType',
      defaultMessage: 'Bracelet Type',
    },
    type: 'options',
    required: false,
  },
  {
    id: 'bracelet_material',
    label: {
      id: 'workshop_report_labels_questions_braceletMaterial',
      defaultMessage: 'Bracelet Material',
    },
    type: 'options',
    required: false,
  },
  {
    id: 'bracelet_supply_rolex_only',
    label: {
      id: 'workshop_report_labels_questions_braceletSupply',
      defaultMessage: 'Bracelet Supply Rolex Only',
    },
    type: 'options',
    required: false,
  },
  {
    id: 'case_material',
    label: {
      id: 'workshop_report_labels_questions_caseMaterial',
      defaultMessage: 'Case Material',
    },
    type: 'options',
    required: true,
  },
  {
    id: 'case_size',
    label: {
      id: 'workshop_report_labels_questions_caseSize',
      defaultMessage: 'Case Size (mm)',
    },
    type: 'number',
    required: false,
  },
  {
    id: 'case_shape',
    label: {
      id: 'workshop_report_labels_questions_caseShape',
      defaultMessage: 'Case Shape',
    },
    type: 'options',
    required: false,
  },
  {
    id: 'crystal_material',
    label: {
      id: 'workshop_report_labels_questions_crystalMaterial',
      defaultMessage: 'Crystal Material',
    },
    type: 'options',
    required: true,
  },
  {
    id: 'dial_color',
    label: {
      id: 'workshop_report_labels_questions_dialColor',
      defaultMessage: 'Dial Color',
    },
    type: 'options',
    required: false,
  },
  {
    id: 'index_type',
    label: {
      id: 'workshop_report_labels_questions_indexType',
      defaultMessage: 'Index Type',
    },
    type: 'options',
    required: false,
  },
  {
    id: 'movement_type',
    label: {
      id: 'workshop_report_labels_questions_movementType',
      defaultMessage: 'Movement Type',
    },
    type: 'options',
    required: true,
  },
  {
    id: 'dial_up_amplitude',
    label: {
      id: 'workshop_report_labels_questions_amplitudeDialUp',
      defaultMessage: 'Amplitude Dial Up(°)',
    },
    type: 'number',
    required: false,
  },
  {
    id: 'crown_down_amplitude',
    label: {
      id: 'workshop_report_labels_questions_amplitudeCrownDown',
      defaultMessage: 'Amplitude Crown Down(°)',
    },
    type: 'number',
    required: false,
  },
  {
    id: 'bracelet_length',
    label: {
      id: 'workshop_report_labels_questions_braceletLength',
      defaultMessage: 'Bracelet Length(mm)',
    },
    type: 'number',
    required: false,
  },
  {
    id: 'current_condition',
    label: {
      id: 'workshop_report_labels_questions_currentCondition',
      defaultMessage: 'Current Condition',
    },
    type: 'options',
    required: false,
  },
  {
    id: 'polished_condition',
    label: {
      id: 'workshop_report_labels_questions_polishedCondition',
      defaultMessage: 'Polished Condition',
    },
    type: 'options',
    required: false,
  },
  {
    id: 'service_mandatory',
    label: {
      id: 'workshop_report_labels_questions_serviceMandatory',
      defaultMessage: 'Which service is required?',
    },
    type: 'options',
    required: false,
  },
  {
    id: 'actual_reference',
    label: {
      id: 'workshop_report_labels_questions_actualReference',
      defaultMessage: 'Actual Reference',
    },
    type: 'text',
    required: false,
  },
  {
    id: 'comment',
    label: {
      id: 'workshop_report_labels_questions_comment',
      defaultMessage: 'Comment',
    },
    type: 'text',
    maxLength: 500,
    required: false,
  },
];
