import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  LinearProgress,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { requestSelector } from '../../../../selectors/request';
import { useAppDispatch, useAppSelector } from '../../../../state';
import {
  createWorkshopServiceAction,
  updateWorkshopServiceAction,
} from '../../../../state/ducks/workshopService/actions';
import { Service } from '../../../../state/ducks/workshopService/types';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  service?: Service;
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
};

const initialFormData = {
  name: '',
  key: '',
  active: false,
};

const CreateUpdateWorkshopServiceModal: React.FC<Props> = ({ service, onSuccess, handleToggleModal, show }) => {
  const dispatch = useAppDispatch();
  const updateRequest = useAppSelector((state) => requestSelector(state, updateWorkshopServiceAction.typePrefix));
  const createRequest = useAppSelector((state) => requestSelector(state, createWorkshopServiceAction.typePrefix));

  const [showUpdateSnackbar, setShowUpdateSnackbar] = useState(false);
  const [showCreateSnackbar, setShowCreateSnackbar] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [isFormValid, setIsFormValid] = useState(false);

  const clearForm = () => {
    setFormData(initialFormData);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case 'active':
        setFormData({ ...formData, [event.target.name]: event.target.checked });
        break;
      default:
        setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };

  const submitForm = () => {
    if (service) {
      dispatch(
        updateWorkshopServiceAction({
          id: service.id,
          requestBody: formData,
        }),
      ).then((response) => {
        setShowUpdateSnackbar(true);
        if (response?.payload && !response.payload?.errors) {
          clearForm();
          handleToggleModal();
          onSuccess();
        }
      });
    } else {
      dispatch(createWorkshopServiceAction(formData)).then((response) => {
        setShowCreateSnackbar(true);
        if (response?.payload && !response?.payload?.errors) {
          clearForm();
          handleToggleModal();
          onSuccess();
        }
      });
    }
  };

  useEffect(() => {
    if (service) {
      const { key, name, active } = service;
      setFormData({ key, name, active });
    } else {
      clearForm();
    }
  }, [show]);

  useEffect(() => {
    if (
      (!service && formData.key && formData.name) ||
      (service &&
        (formData.key !== service.key || formData.name !== service.name || formData.active !== service.active))
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formData]);

  return (
    <>
      {showUpdateSnackbar && <ActionStatusSnackbar actionName={updateWorkshopServiceAction.typePrefix} />}
      {showCreateSnackbar && <ActionStatusSnackbar actionName={createWorkshopServiceAction.typePrefix} />}
      <Dialog open={show} onClose={handleToggleModal}>
        <DialogTitle>
          {service ? (
            <FormattedMessage
              id='workshopPrices_services_updateModal_title'
              defaultMessage='Update Service "{service}"'
              values={{ service: service.name }}
            />
          ) : (
            <FormattedMessage id='workshopPrices_services_createModal_title' defaultMessage='New Service' />
          )}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText>
              {service ? (
                <FormattedMessage
                  id='workshopPrices_services_updateModal_description'
                  defaultMessage='To update this service, please change values as needed below. Please be aware that the key is used in the webshop to identify the service.'
                />
              ) : (
                <FormattedMessage
                  id='workshopPrices_services_createModal_description'
                  defaultMessage='To add a new Service, please fill out the fields below.'
                />
              )}
            </DialogContentText>
            <TextField
              margin='dense'
              id='name'
              name='name'
              value={formData.name}
              onChange={handleChange}
              label={<FormattedMessage id='workshopPrices_labels_name' defaultMessage='Name' />}
              fullWidth
            />
            <TextField
              margin='dense'
              id='key'
              name='key'
              value={formData.key}
              onChange={handleChange}
              label={<FormattedMessage id='workshopPrices_labels_key' defaultMessage='Key' />}
              fullWidth
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formData.active}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  name='active'
                />
              }
              label={<FormattedMessage id='workshopPrices_labels_isActive' defaultMessage='Active' />}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleToggleModal}
            disabled={[createRequest?.status, updateRequest?.status].includes('pending')}
            color='error'>
            <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
          </Button>
          <Button
            onClick={submitForm}
            disabled={!isFormValid || [createRequest?.status, updateRequest?.status].includes('pending')}
            color='success'>
            <FormattedMessage id='common_save' defaultMessage='Save' />
          </Button>
        </DialogActions>
        {[createRequest?.status, updateRequest?.status].includes('pending') && <LinearProgress color='primary' />}
      </Dialog>
    </>
  );
};

export default CreateUpdateWorkshopServiceModal;
