export enum ExternalRoute {
  UPS_TRACKING = 'https://www.ups.com/track?loc=en_DE&tracknum=:trackingNumber',
  FEDEX_TRACKING = 'https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=:trackingNumber',
  FERRARI_TRACKING = 'https://webapps.ferrarigroup.net/tracking/:trackingNumber',
  OPC_TRACKING = 'https://tracking.opc-courier.de/mbsTracking.php?Paketnummer=:trackingNumber',
  WATCH_IMAGE_URL = 'https://chronexttime.imgix.net/:variantCodeFirstLetter/:variantCodeSecondLetter/:variantCode/:variantCode_1_det.png',
  ZENDESK_TICKET_URL = 'https://:subdomain.zendesk.com/agent/tickets/:ticketNumber',
  SELL_WATCH_IMAGE_URL = 'https://ctx-nsp-sell-watches-prod:subdomain.s3.eu-central-1.amazonaws.com/:imageKey',
}

export type PathArgs =
  | {
      path:
        | ExternalRoute.UPS_TRACKING
        | ExternalRoute.FEDEX_TRACKING
        | ExternalRoute.FERRARI_TRACKING
        | ExternalRoute.OPC_TRACKING;
      params: { trackingNumber: string };
    }
  | {
      path: ExternalRoute.WATCH_IMAGE_URL;
      params: { variantCodeFirstLetter: string; variantCodeSecondLetter: string; variantCode: string };
    }
  | {
      path: ExternalRoute.ZENDESK_TICKET_URL;
      params: { subdomain: string; ticketNumber: string };
    }
  | {
      path: ExternalRoute.SELL_WATCH_IMAGE_URL;
      params: { subdomain: string; imageKey: string };
    };
