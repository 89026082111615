import { Box, FormControlLabel, Grid, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../state';
import {
  getWorkshopLocationsAction,
  getWorkshopLocationsOverviewAction,
} from '../../../../state/ducks/workshop/actions';
import AreaOverviewCard from './AreaOverviewCard';

const AreaOverview: React.FC = () => {
  const dispatch = useAppDispatch();
  const areaOverview = useAppSelector((store) => store.workshop.workshopAreaOverview);
  const intl = useIntl();
  const [showAllItems, setShowAllItems] = useState(false);

  useEffect(() => {
    dispatch(getWorkshopLocationsAction());
    dispatch(getWorkshopLocationsOverviewAction(new URLSearchParams({ in_inventory: 'true' })));
  }, []);

  useEffect(() => {
    if (showAllItems) {
      dispatch(getWorkshopLocationsOverviewAction());
    } else {
      dispatch(getWorkshopLocationsOverviewAction(new URLSearchParams({ in_inventory: 'true' })));
    }
  }, [showAllItems]);

  return (
    <>
      <Box padding={1} display='flex' justifyContent='flex-end'>
        <FormControlLabel
          control={
            <Switch
              checked={showAllItems}
              onChange={() => setShowAllItems(!showAllItems)}
              inputProps={{ 'aria-label': 'controlled' }}
              name={intl.formatMessage({
                id: 'workshop_reports_labels_showAllItems',
                defaultMessage: 'Also show items not in inventory',
              })}
            />
          }
          label={
            <FormattedMessage
              id='workshop_reports_labels_showAllItems'
              defaultMessage='Also show items not in inventory'
            />
          }
        />
      </Box>
      <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
        {areaOverview.map((area) => (
          <AreaOverviewCard key={area.area} name={area.area} total={area.totalCountOfItems} overview={area.overview} />
        ))}
      </Grid>
    </>
  );
};

export default AreaOverview;
