import { Button } from '@mui/material';
import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { WorkshopReportType } from '../../../../constants/workshop';
import { formatSeconds, formatUTCToLocal } from '../../../../utils/datetime';
import { text2title } from '../../../../utils/textUtils';
import StatusBadge from '../../../components/badges/StatusBadge';
import Price from '../../../components/price/Price';

const renderCellAsButton = ({ value }: GridRenderCellParams) => (
  <Button variant='text' fullWidth>
    {value}
  </Button>
);

export const WORKSHOP_INBOUND_REPORT_TABLE_STATUS_COLUMNS = [
  {
    id: 'status_pending',
    headerName: 'pending',
    field: 'pending',
    width: 150,
    renderCell: renderCellAsButton,
  },
  {
    id: 'status_in_progress',
    headerName: 'in progress',
    field: 'inProgress',
    width: 150,
    renderCell: renderCellAsButton,
  },
  {
    id: 'status_paused',
    headerName: 'paused',
    field: 'paused',
    width: 150,
    renderCell: renderCellAsButton,
  },
  {
    id: 'status_complete',
    headerName: 'complete',
    field: 'complete',
    width: 150,
    renderCell: renderCellAsButton,
  },
  {
    id: 'common_total',
    headerName: 'Total',
    field: 'total',
    width: 150,
    renderCell: renderCellAsButton,
  },
];

export const WORKSHOP_INBOUND_OVERVIEW_TABLE_ASSIGNEE_COLUMNS = [
  {
    id: 'workshop_labels_assignee',
    headerName: 'Assignee',
    field: 'assignee',
    width: 150,
  },
];

export const WORKSHOP_INBOUND_OVERVIEW_TABLE_ACTIVITY_COLUMNS = [
  {
    id: 'workshop_labels_activity',
    headerName: 'Activity',
    field: 'activity',
    width: 150,
    renderCell: ({ value }: GridRenderCellParams) =>
      !!value && <FormattedMessage id={`workshop_serviceActivityTypes_${value}`} defaultMessage={text2title(value)} />,
  },
];

export const WORKSHOP_INBOUND_REPORT_DETAIL_TABLE_COLUMNS = [
  ...WORKSHOP_INBOUND_OVERVIEW_TABLE_ASSIGNEE_COLUMNS,
  ...WORKSHOP_INBOUND_OVERVIEW_TABLE_ACTIVITY_COLUMNS,
  {
    id: 'common_labels_status',
    headerName: 'Status',
    field: 'status',
    width: 150,
    renderCell: (params: GridRenderCellParams) => <StatusBadge status={params.value} />,
  },
  {
    id: 'common_labels_serialNumber',
    headerName: 'Inventory ID',
    field: 'serialNumber',
    width: 150,
  },
  {
    id: 'common_labels_updatedAt',
    headerName: 'Updated At',
    field: 'lastDateModified',
    width: 150,
    valueGetter: ({ value }: GridValueGetterParams) => value && formatUTCToLocal(value, 'DD.MM.YYYY'),
  },
  {
    id: 'workshop_processes_labels_elapsedTime',
    headerName: 'Elapsed Time',
    field: 'duration',
    width: 150,
    renderCell: ({ value }: GridRenderCellParams) => formatSeconds(value, 'dd:hh:mm:ss'),
  },
];

export const WORKSHOP_INBOUND_OVERVIEW_TABLE_ITEM_DETAILS = [
  {
    id: 'common_labels_id',
    headerName: 'ID',
    field: 'watchId',
  },
  {
    id: 'common_labels_serialNumber',
    headerName: 'Inventory ID',
    field: 'serialNumber',
    minWidth: 100,
  },
  {
    id: 'common_labels_price',
    headerName: 'Price',
    field: 'price',
    minWidth: 100,
    renderCell: ({ value }: GridRenderCellParams) => <Price value={value} />,
  },
];

export const defaultSearchParamsAll: Record<string, any> = {
  start_date: moment.utc().subtract(1, 'day').startOf('month').format('YYYY-MM-DD'),
  end_date: moment.utc().subtract(1, 'day').format('YYYY-MM-DD'),
};

export const defaultSearchParamsDetails: Record<string, any> = {
  ...defaultSearchParamsAll,
  report_type: WorkshopReportType.assignee_activity_overview,
  assignee: '',
  activity: '',
  status: '',
};

export const WORKSHOP_INBOUND_STATUS_QUERY_MAP: Record<string, string> = {
  pending: 'pending',
  inProgress: 'in_progress',
  paused: 'paused',
  complete: 'complete',
};
