import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { requestSelector } from '../../../../selectors/request';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { updateWorkshopServiceAction } from '../../../../state/ducks/workshopService/actions';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  service: Record<string, any>;
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
};

const ToggleWorkshopServiceModal: React.FC<Props> = ({ service, handleToggleModal, show, onSuccess }) => {
  const dispatch = useAppDispatch();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const updateRequest = useAppSelector((state) => requestSelector(state, updateWorkshopServiceAction.typePrefix));

  return (
    <>
      {showSnackbar && <ActionStatusSnackbar actionName={updateWorkshopServiceAction.typePrefix} />}
      <Dialog open={show} onClose={handleToggleModal}>
        <DialogTitle>
          <FormattedMessage
            id='workshopPrices_services_toggleModal_title'
            defaultMessage='{active, select, true {Disable} other {Enable}} Service "{service}"?'
            values={{ active: service.active, service: service.name }}
          />
        </DialogTitle>
        {service.active && (
          <DialogContent>
            <DialogContentText sx={{ fontWeight: 'medium' }}>
              <FormattedMessage
                id='workshopPrices_services_toggleModal_disableWarningText'
                defaultMessage='This service will no longer be shown in the web shop.'
              />
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleToggleModal} disabled={updateRequest?.status === 'pending'}>
            <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
          </Button>
          <Button
            disabled={updateRequest?.status === 'pending'}
            onClick={() => {
              dispatch(
                updateWorkshopServiceAction({
                  id: service.id,
                  requestBody: { active: !service.active },
                }),
              ).then((response) => {
                setShowSnackbar(true);
                if (response?.payload && !response.payload?.errors) {
                  handleToggleModal();
                  onSuccess();
                }
              });
            }}
            color={service.active ? 'error' : 'success'}>
            {service.active ? (
              <FormattedMessage id='common_disable' defaultMessage='Disable' />
            ) : (
              <FormattedMessage id='common_enable' defaultMessage='Enable' />
            )}
          </Button>
        </DialogActions>
        {updateRequest?.status === 'pending' && <LinearProgress color='primary' />}
      </Dialog>
    </>
  );
};

export default ToggleWorkshopServiceModal;
