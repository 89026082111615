import { Card, CardContent, Stack, Typography } from '@mui/material';
import { Moment } from 'moment';
import React from 'react';
import { requestSelector } from '../../../../selectors/request';
import { activityReportSelector, analyticsSelector } from '../../../../selectors/workshop';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { getActivityReportAction } from '../../../../state/ducks/workshop/actions';
import DateFilter from '../../../components/datetime/DateFilter';
import SimpleTable, { SimpleTableColDef } from '../../../components/table/SimpleTable';

const columns: SimpleTableColDef[] = [
  {
    id: 'workshop_labels_activity',
    headerName: 'Activity',
    field: 'name',
    minWidth: 150,
    flex: 1,
  },
  {
    id: 'common_labels_createdBy',
    headerName: 'Created By',
    field: 'employee',
    minWidth: 200,
    flex: 1,
  },
  {
    id: 'workshop_reports_labels_uncompletedCount',
    headerName: 'Uncompleted Count',
    field: 'uncompletedCount',
    minWidth: 200,
    flex: 1,
  },
  {
    id: 'workshop_reports_labels_scans',
    headerName: 'Scans',
    field: 'scans',
    minWidth: 100,
  },
  {
    id: 'workshop_reports_labels_scannedItems',
    headerName: 'Distinct Scans',
    field: 'scannedItems',
    minWidth: 120,
  },
  {
    id: 'common_labels_elapsedTime',
    headerName: 'Elapsed Time',
    field: 'elapsedTime',
    minWidth: 150,
    sortable: false,
  },
  {
    id: 'common_labels_startTime',
    headerName: 'Start Time',
    field: 'startTime',
    minWidth: 150,
    flex: 1,
    sortable: false,
  },
  {
    id: 'common_labels_endTime',
    headerName: 'End Time',
    field: 'endTime',
    minWidth: 150,
    flex: 1,
    sortable: false,
  },
];

const DailyActivityReport: React.FC = () => {
  const dispatch = useAppDispatch();
  const activityReportData = useAppSelector(activityReportSelector);
  const analytics = useAppSelector(analyticsSelector);
  const requestState = useAppSelector((state) => {
    return requestSelector(state, getActivityReportAction.typePrefix);
  });

  // dispatch action when the date filter is updated
  const onFilterUpdate = (start: Moment, end: Moment) => {
    dispatch(
      getActivityReportAction({
        startTime: start.toISOString(),
        endTime: end.toISOString(),
      }),
    );
  };

  return (
    <Stack spacing={2} sx={{ position: 'relative', height: '100%' }}>
      <Stack direction='row' spacing={2}>
        <DateFilter onFilterUpdate={onFilterUpdate} />
        <Stack direction='row' spacing={1} sx={{ flex: 1, justifyContent: 'center' }}>
          <Card variant='outlined'>
            <CardContent>
              <Typography variant='h5' component='div'>
                {analytics.totalDuration}
              </Typography>
              <Typography color='text.secondary'>Duration</Typography>
              <Typography variant='body2'>Total Duration recorded in time frame</Typography>
            </CardContent>
          </Card>
          <Card variant='outlined'>
            <CardContent>
              <Typography variant='h5' component='div'>
                {analytics.totalScans}
              </Typography>
              <Typography color='text.secondary'>Scans</Typography>
              <Typography variant='body2'>Total Number of Scans recorded in time frame</Typography>
            </CardContent>
          </Card>
          <Card variant='outlined'>
            <CardContent>
              <Typography variant='h5' component='div'>
                {analytics.activeEmployees}
              </Typography>
              <Typography color='text.secondary'>Employees Active</Typography>
              <Typography variant='body2'>Total Number of active Employees in time frame</Typography>
            </CardContent>
          </Card>
        </Stack>
      </Stack>
      <SimpleTable columns={columns} rows={activityReportData} loading={requestState?.status === 'pending'} />
    </Stack>
  );
};

export default DailyActivityReport;
