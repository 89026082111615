import { loadLocalStorageState, saveLocalStorageState } from '@chronext/react-common';
import { createReducer } from '@reduxjs/toolkit';
import { getCurrentUserAction, setColorModeAction, setLanguageAction, setViewAsGroupAction } from './actions';
import { UserState } from './types';

export const initialState: UserState = {
  users: [],
  currentUser: {
    username: '',
    groups: [],
    language: 'en',
    colorMode: 'dark',
    viewAsGroup: undefined,
  },
};

const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getCurrentUserAction.fulfilled, (state, action) => {
      const { username, groups } = action.payload.user;
      state.currentUser.username = username;
      state.currentUser.groups = groups;
      const colorMode = loadLocalStorageState('user_colorMode');
      const language = loadLocalStorageState('user_language');
      const viewAsGroup = loadLocalStorageState('user_viewAsGroup');
      state.currentUser.language = language || initialState.currentUser.language;
      state.currentUser.colorMode = colorMode || initialState.currentUser.colorMode;
      state.currentUser.viewAsGroup = viewAsGroup || initialState.currentUser.viewAsGroup;
    })
    .addCase(setLanguageAction, (state, action) => {
      state.currentUser.language = action.payload;
      saveLocalStorageState('user_language', action.payload);
    })
    .addCase(setColorModeAction, (state, action) => {
      state.currentUser.colorMode = action.payload;
      saveLocalStorageState('user_colorMode', action.payload);
    })
    .addCase(setViewAsGroupAction, (state, action) => {
      if (action.payload === 'Reset') {
        state.currentUser.viewAsGroup = undefined;
        localStorage.removeItem('user_viewAsGroup');
      } else {
        state.currentUser.viewAsGroup = action.payload;
        saveLocalStorageState('user_viewAsGroup', action.payload);
      }
    });
});

export default userReducer;
