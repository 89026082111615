import AddIcon from '@mui/icons-material/Add';
import { Button, ButtonGroup, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { GridValueGetterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { APP_CONFIG } from '../../../config/app';
import { WorkshopServiceApprovalStatus } from '../../../constants/workshop';
import { ClientRouteGroups } from '../../../routing/clientRoutes';
import { requestSelector } from '../../../selectors/request';
import { isAuthorizedSelector } from '../../../selectors/user';
import { workshopInboundWatchSelector } from '../../../selectors/workshop-inbound/workshop-inbound.selector';
import { useAppDispatch, useAppSelector } from '../../../state';
import { getWorkshopInboundWatchAction } from '../../../state/ducks/workshopInbound/actions';
import { formatUTCToLocal } from '../../../utils/datetime';
import { text2title } from '../../../utils/textUtils';
import StatusBadge from '../../components/badges/StatusBadge';
import WorkshopInboundSendQuotationButton from '../../components/buttons/WorkshopInboundSendQuotationButton';
import WorkshopInboundUpdateServiceButton from '../../components/buttons/WorkshopInboundUpdateServiceButton';
import BooleanIcon from '../../components/icons/BooleanIcon';
import Price from '../../components/price/Price';
import SimpleTable, { SimpleTableColDef } from '../../components/table/SimpleTable';
import WorkshopInboundItemActivitiesTable from '../../components/workshop-tables/WorkshopInboundItemActivitiesTable';
import CreateUpdateInboundServiceModal, { SERVICES } from './components/CreateUpdateInboundServiceModal';
import WorkshopInboundItemCard from './components/WorkshopInboundItemCard';
import WorkshopInboundReportCard from './components/WorkshopInboundReportCard';

const serviceTableColumns: SimpleTableColDef[] = [
  {
    id: 'common_labels_id',
    headerName: 'ID',
    field: 'id',
    minWidth: 100,
  },
  {
    id: 'workshop_labels_service',
    headerName: 'Service',
    field: 'service_type',
    minWidth: 200,
    renderCell: (params) => (
      <FormattedMessage
        id={`workshop_serviceActivityTypes_${params.value}`}
        defaultMessage={text2title(params.value)}
      />
    ),
  },
  {
    id: 'common_labels_status',
    headerName: 'Status',
    field: 'approval_status',
    minWidth: 100,
    renderCell: (params) => <StatusBadge status={params.value} />,
  },
  {
    id: 'common_optional',
    headerName: 'optional',
    field: 'is_optional',
    minWidth: 100,
    renderCell: (params) => <BooleanIcon check={params.value} />,
  },
  {
    id: 'common_labels_price',
    headerName: 'Price',
    field: 'price',
    minWidth: 100,
    renderCell: (params) => (params.value ? <Price value={params.value} /> : null),
  },
  {
    id: 'workshop_labels_quotationSent',
    headerName: 'Quotation sent',
    field: 'quotation_sent',
    minWidth: 150,
    renderCell: (params) => <BooleanIcon check={params.value} />,
  },
  {
    id: 'common_labels_comment',
    headerName: 'Comment',
    field: 'comment',
    minWidth: 200,
  },
  {
    id: 'common_actions',
    headerName: 'Actions',
    field: 'actions',
    minWidth: 100,
    renderCell: (params) =>
      !params.row.quotation_sent && (
        <ButtonGroup variant='text'>
          <WorkshopInboundUpdateServiceButton service={params.row} />
          <WorkshopInboundSendQuotationButton serviceId={params.row.id} />
        </ButtonGroup>
      ),
  },
  {
    id: 'common_labels_createdAt',
    headerName: 'Created At',
    field: 'created_at',
    sortable: false,
    minWidth: 150,
    valueGetter: ({ value }: GridValueGetterParams) => value && formatUTCToLocal(value),
  },
  {
    id: 'common_labels_updatedAt',
    headerName: 'Updated At',
    field: 'updated_at',
    sortable: false,
    minWidth: 150,
    valueGetter: ({ value }: GridValueGetterParams) => value && formatUTCToLocal(value),
  },
];

const WorkshopInboundItemDetails: React.FC = () => {
  const userCanEdit =
    useAppSelector((state) =>
      isAuthorizedSelector(
        state,
        ClientRouteGroups.WORKSHOP_INBOUND_ITEM.filter((g) => g.startsWith('Workshop')),
      ),
    ) || !APP_CONFIG.isProd;
  const { reference } = useParams<{ reference: string }>();
  const workshopWatch = useAppSelector(workshopInboundWatchSelector);
  const dispatch = useAppDispatch();
  const getWorkshopInboundItemRequest = useAppSelector((state) =>
    requestSelector(state, getWorkshopInboundWatchAction.typePrefix),
  );
  const [showServiceForm, setShowServiceForm] = useState(false);

  useEffect(() => {
    dispatch(getWorkshopInboundWatchAction(reference));
  }, [reference]);

  if (!workshopWatch) {
    return (
      <Stack spacing={2}>
        <Skeleton variant='rounded' height={215} />
        <Skeleton />
        <Skeleton variant='rounded' height={100} />
        <Skeleton variant='rounded' height={215} />
        <Skeleton />
        <Skeleton variant='rounded' height={100} />
      </Stack>
    );
  }

  return (
    <>
      {showServiceForm && (
        <CreateUpdateInboundServiceModal
          watch={workshopWatch}
          show={showServiceForm}
          handleToggleModal={() => setShowServiceForm(!showServiceForm)}
          onSuccess={() => dispatch(getWorkshopInboundWatchAction(reference))}
        />
      )}
      <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={12} md={12}>
          <WorkshopInboundItemCard
            item={workshopWatch}
            userCanEdit={userCanEdit}
            actionButtons={
              SERVICES.filter(
                (key) =>
                  !workshopWatch.services.some(
                    (s) => s.service_type === key && s.approval_status !== WorkshopServiceApprovalStatus.declined,
                  ),
              ).length
                ? [
                    <Button
                      key='create_service'
                      startIcon={<AddIcon />}
                      variant='outlined'
                      color='success'
                      disabled={!userCanEdit}
                      onClick={() => setShowServiceForm(true)}>
                      <FormattedMessage id='workshopInbound_actions_createServices' defaultMessage='Create services' />
                    </Button>,
                  ]
                : []
            }
          />
        </Grid>
        <Grid item xs={4} sm={12} md={12}>
          <Divider variant='middle' sx={{ padding: '15px 0' }}>
            <Typography variant='h6'>
              <FormattedMessage id='workshopInbound_labels_services' defaultMessage='Services' />
            </Typography>
          </Divider>
          <SimpleTable
            columns={serviceTableColumns}
            rows={workshopWatch?.services || []}
            loading={getWorkshopInboundItemRequest?.status === 'pending'}
            density='compact'
            autoHeight
            sx={{ flex: 1 }}
            hideFooter
          />
        </Grid>
        {workshopWatch?.authenticate_activity?.activity_report && (
          <Grid item xs={4} sm={12} md={12}>
            <WorkshopInboundReportCard
              activity={workshopWatch?.authenticate_activity}
              onSuccess={() => dispatch(getWorkshopInboundWatchAction(reference))}
            />
          </Grid>
        )}
        <Grid item xs={4} sm={12} md={12}>
          <Divider variant='middle' sx={{ padding: '15px 0' }}>
            <Typography variant='h6'>
              <FormattedMessage id='workshopInbound_labels_activities' defaultMessage='Activities' />
            </Typography>
          </Divider>
          <WorkshopInboundItemActivitiesTable
            activities={workshopWatch.activities}
            fetchItem={() => dispatch(getWorkshopInboundWatchAction(reference))}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default WorkshopInboundItemDetails;
