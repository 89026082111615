import {
  Add,
  ArrowOutward,
  Check,
  Close,
  Edit,
  NoPhotography,
  PhotoAlbum,
  PriorityHigh,
  Schedule,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, useParams } from 'react-router-dom';
import { GROUPS } from '../../../constants/groups';
import { SellRequestStatus, SellRequestStatusInfoShowDetails } from '../../../constants/sell';
import { ClientRoute, createPath, ExternalRoute } from '../../../routing';
import { ClientRouteGroups } from '../../../routing/clientRoutes';
import { sellRequestSelector } from '../../../selectors/sell';
import { sellChronextAgentsSelector } from '../../../selectors/sell/sell.selector';
import { currentUserSelector, isAuthorizedSelector } from '../../../selectors/user';
import { useAppDispatch, useAppSelector } from '../../../state';
import {
  getSellRequestAction,
  updateSellRequestAgentAction,
  updateSellRequestStatusAction,
} from '../../../state/ducks/sell/actions';
import { Image, OfferStatus } from '../../../state/ducks/sell/types';
import { getCountryNameFromAlpha2 } from '../../../utils/countryUtils';
import { formatUTCToLocal } from '../../../utils/datetime';
import { calculatePriceFromMargin, getPriceLocaleString, text2title } from '../../../utils/textUtils';
import ConditionBadge from '../../components/badges/ConditionBadge';
import StatusBadge from '../../components/badges/StatusBadge';
import CopyToClipboardButton from '../../components/buttons/CopyToClipboardButton';
import BooleanIcon from '../../components/icons/BooleanIcon';
import Gallery, { GalleryImage } from '../../components/images/Gallery';
import ActionStatusSnackbar from '../../components/snackbars/ActionStatusSnackbar';
import Address from './components/Address';
import CancelModal from './components/CancelModal';
import FinalizeModal from './components/FinalizeModal';
import MissingDataModal from './components/MissingDataModal';
import OfferModal from './components/OfferModal';
import RequestVendorInformationModal from './components/RequestVendorInformationModal';
import SellingMethods from './components/SellingMethods';
import UpdateExpirationModal from './components/UpdateExpirationModal';
import UpdateItemModal from './components/UpdateItemModal';
import UpdateVendorInformationModal from './components/UpdateVendorInformationModal';
import styles from './SellRequestDetails.module.scss';

type AllowedActions = {
  cancel?: boolean;
  editItem?: boolean;
  requestMissingInfo?: boolean;
  editOffer?: boolean;
  updateOfferExpiration?: boolean;
  setToPending?: boolean;
  finalizeOffer?: boolean;
  requestVendorInfo?: boolean;
  updateVendorInfo?: boolean;
};

type Params = {
  [key: string]: string;
};
const getGalleryImages = (images: Image[]): GalleryImage[] =>
  images.map((image) => {
    const imageURL = /^https?:\/\//.test(image?.key)
      ? image.key
      : createPath({
          path: ExternalRoute.SELL_WATCH_IMAGE_URL,
          params: {
            subdomain: process.env.REACT_APP_SELL_WATCH_IMAGE_SUBDOMAIN || '',
            imageKey: image.key,
          },
        });
    return {
      key: image.key,
      name: image.name,
      src: imageURL,
    };
  });

const SellRequestDetails = () => {
  const intl = useIntl();
  const { language } = useAppSelector(currentUserSelector);
  const { reference } = useParams<Params>();
  const sellRequest = useAppSelector(sellRequestSelector);
  const chronextAgents = useAppSelector(sellChronextAgentsSelector);
  const isSupplyManager = useAppSelector((state) => isAuthorizedSelector(state, [GROUPS.SUPPLY_MANAGER]));
  const [galleryModalOpen, setGalleryModalOpen] = useState(false);
  const [offerModalOpen, setOfferModalOpen] = useState(false);
  const [editItemModalOpen, setEditItemModalOpen] = useState(false);
  const [missingDataModalOpen, setMissingDataModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [showStatusUpdateActionSnackbar, setShowStatusUpdateActionSnackbar] = useState(false);
  const [showAgentUpdateActionSnackbar, setShowAgentUpdateActionSnackbar] = useState(false);
  const [finalizeModalOpen, setFinalizeModalOpen] = useState(false);
  const [finalizeStatus, setFinalizeStatus] = useState('');
  const [updateExpirationModalOpen, setUpdateExpirationModalOpen] = useState(false);
  const [requestVendorInfoModalOpen, setRequestVendorInfoModalOpen] = useState(false);
  const [updateVendorInfoModalOpen, setUpdateVendorInfoModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const userCanEdit = useAppSelector((state) =>
    isAuthorizedSelector(
      state,
      ClientRouteGroups.SELL_DETAILS.filter((g) => g.startsWith('Supply')),
    ),
  );

  const userCanPartiallyEdit = useAppSelector((state) =>
    isAuthorizedSelector(
      state,
      ClientRouteGroups.SELL_DETAILS.filter((g) => g.startsWith('Supply') || g.startsWith('Sales')),
    ),
  );

  useEffect(() => {
    dispatch(getSellRequestAction(reference));
  }, []);

  if (!sellRequest) {
    return null;
  }

  const updateAgent = (agentReference: number) => {
    const params = {
      reference: sellRequest.reference.toString(),
      agentReference: Number(agentReference),
    };
    dispatch(updateSellRequestAgentAction(params)).then((response) => {
      if (response.payload) {
        setShowAgentUpdateActionSnackbar(true);
        dispatch(getSellRequestAction(reference));
      }
    });
  };
  let allowedActions: AllowedActions = {};
  if (userCanEdit || userCanPartiallyEdit) {
    allowedActions = {
      cancel: ![SellRequestStatus.cancelled].includes(sellRequest.status),
      requestMissingInfo: sellRequest.status === SellRequestStatus.cxt_pending,
      setToPending: ![SellRequestStatus.cxt_pending, SellRequestStatus.finalized].includes(sellRequest.status),
      requestVendorInfo:
        sellRequest.status === SellRequestStatus.cxt_pending && sellRequest.offerReply?.accept === 'approved',
    };
  }
  if (userCanEdit) {
    allowedActions = {
      ...allowedActions,
      editItem: ![SellRequestStatus.cancelled, SellRequestStatus.finalized].includes(sellRequest.status),
      editOffer: sellRequest.status === SellRequestStatus.cxt_pending,
      updateOfferExpiration: sellRequest.status !== SellRequestStatus.finalized,
      finalizeOffer:
        sellRequest.status === SellRequestStatus.cxt_pending &&
        sellRequest.offer?.status === 'pending' &&
        isSupplyManager,
      updateVendorInfo: sellRequest.status === SellRequestStatus.cxt_pending,
    };
  }

  const galleryImages = getGalleryImages(sellRequest.images);
  const avatarClass = galleryImages.length > 0 ? styles.avatar : styles.avatarNoImage;

  const trackingNumberURL =
    sellRequest.trackingNumber &&
    sellRequest.purchaseOrder &&
    createPath({
      path: ClientRoute.TRACKING_PURCHASES_DETAILS,
      params: { reference: sellRequest.purchaseOrder },
    });

  const expirationDate = moment
    .utc(sellRequest?.offer?.approvedAt)
    .local()
    .add(sellRequest?.offer?.expirationDaysLimit, 'days');

  const offerExpired = moment().isAfter(expirationDate);

  const offerRejectionReason = sellRequest?.offerReply?.rejection_reason.replaceAll('-', '_');
  const offerRejectionReasonMessageId = `sellRequests_offerRejectionReasons_${offerRejectionReason}`;
  const offerRejectionReasonLabel = intl.messages[offerRejectionReasonMessageId] ? (
    <FormattedMessage
      id={offerRejectionReasonMessageId}
      defaultMessage={text2title(sellRequest?.offerReply?.rejection_reason || '')}
    />
  ) : (
    sellRequest?.offerReply?.rejection_reason
  );

  const getOfferSellingMethods = () => {
    const result = [];
    if (sellRequest?.offer?.commissionRate) {
      result.push('commission');
    }
    if (sellRequest?.offer?.directPrice) {
      result.push('direct');
    }
    if (sellRequest?.offer?.exchangePrice) {
      result.push('exchange');
    }
    return result;
  };

  const getPriceRangeString = (priceFrom: number, priceTo: number): string => {
    return `${getPriceLocaleString(
      priceFrom,
      language,
      sellRequest?.seller?.country,
      sellRequest?.currencyCode,
    )} \u2013 ${getPriceLocaleString(priceTo, language, sellRequest?.seller?.country, sellRequest?.currencyCode)}`;
  };

  const setSellRequestToPending = () => {
    dispatch(updateSellRequestStatusAction(sellRequest.reference?.toString())).then((response) => {
      setShowStatusUpdateActionSnackbar(true);
      if (response.payload) {
        dispatch(getSellRequestAction(sellRequest.reference?.toString()));
      }
    });
  };

  return (
    <>
      {showStatusUpdateActionSnackbar && (
        <ActionStatusSnackbar
          actionName={updateSellRequestStatusAction.typePrefix}
          config={{ messageValues: { reference } }}
        />
      )}
      {showAgentUpdateActionSnackbar && (
        <ActionStatusSnackbar
          actionName={updateSellRequestAgentAction.typePrefix}
          config={{ messageValues: { reference } }}
        />
      )}
      {galleryModalOpen && (
        <Gallery
          images={galleryImages}
          show={galleryModalOpen}
          handleToggleModal={() => {
            setGalleryModalOpen(false);
          }}
        />
      )}
      {offerModalOpen && (
        <OfferModal
          sellRequestReference={sellRequest.reference?.toString()}
          show={offerModalOpen}
          handleToggleModal={() => {
            setOfferModalOpen(false);
          }}
          onSuccess={() => dispatch(getSellRequestAction(sellRequest.reference?.toString()))}
        />
      )}
      {cancelModalOpen && (
        <CancelModal
          sellRequest={sellRequest}
          show={cancelModalOpen}
          handleToggleModal={() => {
            setCancelModalOpen(false);
          }}
          onSuccess={() => dispatch(getSellRequestAction(sellRequest.reference?.toString()))}
        />
      )}
      {editItemModalOpen && (
        <UpdateItemModal
          sellRequest={sellRequest}
          show={editItemModalOpen}
          handleToggleModal={() => {
            setEditItemModalOpen(false);
          }}
          onSuccess={() => dispatch(getSellRequestAction(sellRequest.reference?.toString()))}
        />
      )}
      {missingDataModalOpen && (
        <MissingDataModal
          sellRequest={sellRequest}
          show={missingDataModalOpen}
          handleToggleModal={() => {
            setMissingDataModalOpen(false);
          }}
          onSuccess={() => dispatch(getSellRequestAction(sellRequest.reference?.toString()))}
        />
      )}
      {finalizeModalOpen && (
        <FinalizeModal
          show={finalizeModalOpen}
          handleToggleModal={() => {
            setFinalizeModalOpen(false);
          }}
          onSuccess={() => dispatch(getSellRequestAction(sellRequest.reference?.toString()))}
          status={finalizeStatus}
        />
      )}
      {updateExpirationModalOpen && (
        <UpdateExpirationModal
          show={updateExpirationModalOpen}
          sellRequest={sellRequest}
          handleToggleModal={() => {
            setUpdateExpirationModalOpen(false);
          }}
          onSuccess={() => dispatch(getSellRequestAction(sellRequest.reference?.toString()))}
        />
      )}
      {requestVendorInfoModalOpen && (
        <RequestVendorInformationModal
          sellRequest={sellRequest}
          show={requestVendorInfoModalOpen}
          handleToggleModal={() => {
            setRequestVendorInfoModalOpen(false);
          }}
          onSuccess={() => dispatch(getSellRequestAction(sellRequest.reference?.toString()))}
        />
      )}
      {updateVendorInfoModalOpen && (
        <UpdateVendorInformationModal
          sellRequest={sellRequest}
          show={updateVendorInfoModalOpen}
          handleToggleModal={() => {
            setUpdateVendorInfoModalOpen(false);
          }}
          onSuccess={() => dispatch(getSellRequestAction(sellRequest.reference?.toString()))}
        />
      )}

      <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={12} md={12}>
          <Card>
            <CardHeader
              title={
                <Stack direction='row' justifyContent='space-between'>
                  <FormattedMessage
                    id='sellRequests_details_title'
                    defaultMessage='Details for Sell Request {reference}'
                    values={{ reference: sellRequest.sellRequestId || reference }}
                  />
                  <StatusBadge status={sellRequest.status} />
                </Stack>
              }
            />
            <CardContent>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent='space-between'>
                <Stack spacing={1}>
                  <Stack direction='row' spacing={1} mb={2}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='sellRequests_labels_statusInfo' defaultMessage='Status Information' />:
                    </Typography>
                    <Typography>
                      <FormattedMessage
                        id={`sellRequests_statusInfo_${sellRequest.statusInfo}`}
                        defaultMessage={sellRequest.statusInfo}
                      />
                    </Typography>
                  </Stack>
                  <Stack direction='row' spacing={1}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='common_labels_createdAt' defaultMessage='Created At' />:
                    </Typography>
                    <Typography>{formatUTCToLocal(sellRequest?.createdAt)}</Typography>
                  </Stack>
                  <Stack direction='row' spacing={1}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='common_labels_updatedAt' defaultMessage='Updated At' />:
                    </Typography>
                    <Typography>{formatUTCToLocal(sellRequest?.updatedAt)}</Typography>
                  </Stack>
                  {sellRequest.status === 'cancelled' && (
                    <Stack direction='row' spacing={1} mb={2}>
                      <Typography fontWeight='bold'>
                        <FormattedMessage
                          id='sellRequests_labels_cancellationReason'
                          defaultMessage='Cancellation Reason'
                        />
                        :
                      </Typography>
                      <Typography>
                        <FormattedMessage
                          id={`sellRequests_cancellationReasons_${sellRequest.cancellationReason}`}
                          defaultMessage={sellRequest.cancellationReason}
                        />
                      </Typography>
                    </Stack>
                  )}
                  {sellRequest.purchaseOrder && (
                    <>
                      <Stack direction='row' spacing={1} mb={2}>
                        <Typography fontWeight='bold'>
                          <FormattedMessage id='tracking_labels_purchaseOrderId' defaultMessage='Purchase Order ID' />:
                        </Typography>
                        <Typography>
                          <Stack justifyContent='center' direction='row' spacing={1}>
                            <div>{sellRequest.purchaseOrder}</div>
                            {Object.keys(SellRequestStatusInfoShowDetails).includes(sellRequest.statusInfo) && (
                              <Link
                                component={NavLink}
                                to={createPath({
                                  path: ClientRoute.WORKSHOP_INBOUND_ITEM,
                                  params: { reference: sellRequest.purchaseOrder },
                                })}>
                                <Stack justifyContent='center' direction='row' spacing={1}>
                                  <FormattedMessage id='common_details' defaultMessage='Details' />
                                  <ArrowOutward />
                                </Stack>
                              </Link>
                            )}
                          </Stack>
                        </Typography>
                      </Stack>
                      {sellRequest.trackingNumber && (
                        <Stack direction='row' spacing={1} mb={2}>
                          <Typography fontWeight='bold'>
                            <FormattedMessage id='tracking_labels_trackingNumber' defaultMessage='Tracking Number' />:
                          </Typography>
                          <Link
                            target='_blank'
                            onClick={(ev) => {
                              ev.stopPropagation();
                            }}
                            href={trackingNumberURL}
                            underline='none'>
                            {sellRequest.trackingNumber}
                          </Link>
                        </Stack>
                      )}
                    </>
                  )}
                  {sellRequest.pickupDate && (
                    <Stack direction='row' spacing={1}>
                      <Typography fontWeight='bold'>
                        <FormattedMessage id='common_labels_pickupDate' defaultMessage='Handover Date' />:
                      </Typography>
                      <Typography>
                        {formatUTCToLocal(
                          sellRequest?.pickupDate,
                          sellRequest.dropOff ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY',
                        )}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
                <Stack spacing={1}>
                  <Stack direction='row' spacing={1}>
                    <FormControl fullWidth>
                      <InputLabel id='agentLabel'>
                        <FormattedMessage id='common_labels_Agent' defaultMessage='Supply / CA Agent' />
                      </InputLabel>
                      <Select
                        id='agent'
                        name='agent'
                        value={sellRequest?.agent?.reference || ''}
                        onChange={(ev) => updateAgent(Number(ev.target.value))}
                        input={
                          <OutlinedInput
                            label={<FormattedMessage id='common_labels_Agent' defaultMessage='Supply / CA Agent' />}
                          />
                        }
                        required={false}
                        labelId='agentLabel'
                        fullWidth>
                        {chronextAgents.map((option) => (
                          <MenuItem key={option.reference} value={option.reference}>
                            {option.preferredName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='common_labels_FreshSales_link' defaultMessage='Freshsales' />:
                    </Typography>
                    <Button
                      component={Link}
                      color='info'
                      sx={{ textTransform: 'none' }}
                      target='_blank'
                      href={`https://chronextag.myfreshworks.com/crm/sales/contacts/view/custom?q[]={"name":"contact_email.email","operator":17,"value":["${sellRequest?.seller?.email}"]}`}>
                      <Typography fontWeight='bold'>
                        <FormattedMessage id='common_labels_FreshSales_link' defaultMessage='Contact Link' />
                      </Typography>
                    </Button>
                  </Stack>
                </Stack>
                <Stack spacing={1}>
                  {allowedActions.cancel && (
                    <Button
                      variant='outlined'
                      color='error'
                      onClick={() => setCancelModalOpen(true)}
                      startIcon={<Close />}>
                      <FormattedMessage id='sellRequests_actions_cancel' defaultMessage='Cancel Sell Request' />
                    </Button>
                  )}
                  {allowedActions.setToPending && (
                    <Button variant='outlined' color='info' onClick={setSellRequestToPending} startIcon={<Schedule />}>
                      <FormattedMessage id='sellRequests_actions_setToPending' defaultMessage='Set to Pending' />
                    </Button>
                  )}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4} sm={12} md={12}>
          <Card>
            <CardHeader
              title={
                <Stack direction='row' justifyContent='space-between'>
                  <FormattedMessage id='sellRequests_details_cardTitles_item' defaultMessage='Item' />
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={1}>
                    {allowedActions.editItem && (
                      <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => setEditItemModalOpen(true)}
                        startIcon={<Edit />}>
                        <FormattedMessage id='common_edit' defaultMessage='Edit' />
                      </Button>
                    )}
                    {allowedActions.requestMissingInfo && (
                      <Button
                        variant='outlined'
                        color='error'
                        onClick={() => setMissingDataModalOpen(true)}
                        startIcon={<PriorityHigh />}>
                        <FormattedMessage id='sellRequests_missingData' defaultMessage='Missing Data' />
                      </Button>
                    )}
                  </Stack>
                </Stack>
              }
            />
            <CardContent>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
                <Box>
                  <Avatar
                    className={avatarClass}
                    data-overlaycontent={galleryImages.length > 0 ? `+ ${galleryImages.length - 1}` : ''}
                    onClick={() => {
                      if (galleryImages.length > 0) {
                        setGalleryModalOpen(true);
                      }
                    }}
                    variant='rounded'
                    style={galleryImages.length > 1 ? { cursor: 'pointer' } : {}}
                    src={galleryImages[0]?.src}
                    alt={galleryImages[0]?.name}>
                    {galleryImages.length > 0 ? (
                      <PhotoAlbum sx={{ width: '50px', height: '50px' }} />
                    ) : (
                      <NoPhotography sx={{ width: '50px', height: '50px' }} />
                    )}
                  </Avatar>
                </Box>
                <Stack direction='column' spacing={1}>
                  <Stack direction='row' spacing={2}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='common_labels_brand' defaultMessage='Brand' />:
                    </Typography>
                    <Typography>{sellRequest.watchBrand}</Typography>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='common_labels_model' defaultMessage='Model' />:
                    </Typography>
                    <Typography>{sellRequest.watchModel}</Typography>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='common_labels_reference' defaultMessage='Reference' />:
                    </Typography>
                    <Typography>{sellRequest.watchRef}</Typography>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='common_labels_sku' defaultMessage='SKU' />:
                    </Typography>
                    <Typography>{sellRequest.sku}</Typography>
                  </Stack>
                </Stack>
                <Stack direction='column' spacing={1}>
                  <Stack direction='row' spacing={2}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='common_labels_paperAvailable' defaultMessage='Paper' />:
                    </Typography>
                    <BooleanIcon check={sellRequest.paperAvailable} />
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='common_labels_boxAvailable' defaultMessage='Box' />:
                    </Typography>
                    <BooleanIcon check={sellRequest.boxAvailable} />
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='sellRequests_labels_constructionYear' defaultMessage='Year' />:
                    </Typography>
                    <Typography>{sellRequest.constructionYear}</Typography>
                  </Stack>
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='common_labels_watchCondition' defaultMessage='Condition' />:
                    </Typography>
                    <ConditionBadge condition={sellRequest.watchCondition} />
                  </Stack>
                </Stack>
                <Stack direction='column' spacing={1}>
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='sellRequests_labels_sellingMethods' defaultMessage='Selling Methods' />:
                    </Typography>
                    <SellingMethods methods={sellRequest.sellingMethods || []} />
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='sellRequests_labels_comments' defaultMessage='Comment' />:
                    </Typography>
                    <Typography>{sellRequest.comments}</Typography>
                  </Stack>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4} sm={12} md={12}>
          <Card>
            <CardHeader
              title={
                <Stack direction='row' justifyContent='space-between'>
                  <FormattedMessage id='sellRequests_details_cardTitles_offer' defaultMessage='Offer' />
                  {allowedActions.editOffer && (
                    <Button
                      variant='outlined'
                      color={sellRequest.offer ? 'primary' : 'success'}
                      onClick={() => setOfferModalOpen(true)}
                      startIcon={sellRequest.offer ? <Edit /> : <Add />}>
                      {sellRequest.offer ? (
                        <FormattedMessage id='common_edit' defaultMessage='Edit' />
                      ) : (
                        <FormattedMessage id='sellRequests_actions_createOffer' defaultMessage='Create new Offer' />
                      )}
                    </Button>
                  )}
                </Stack>
              }
            />
            <CardContent>
              {sellRequest?.offer ? (
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} justifyContent='space-between'>
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
                    <Stack direction='column' spacing={1}>
                      <Stack direction='row' spacing={1} alignItems='center'>
                        <Typography fontWeight='bold'>
                          <FormattedMessage id='sellRequests_labels_status' defaultMessage='Offer Status' />:
                        </Typography>
                        <StatusBadge status={sellRequest?.offer?.status} />
                        {offerExpired && (
                          <>
                            <Divider orientation='vertical' />
                            <StatusBadge status='expired' />
                          </>
                        )}
                      </Stack>
                      <Stack direction='row' spacing={1}>
                        <Typography fontWeight='bold'>
                          <FormattedMessage id='common_labels_createdAt' defaultMessage='Created At' />:
                        </Typography>
                        <Typography>{formatUTCToLocal(sellRequest?.offer?.createdAt)}</Typography>
                      </Stack>
                      <Stack direction='row' spacing={1}>
                        <Typography fontWeight='bold'>
                          <FormattedMessage id='common_labels_createdBy' defaultMessage='Created By' />:
                        </Typography>
                        <Typography>{sellRequest?.offer?.createdBy}</Typography>
                      </Stack>
                      <Stack direction='row' spacing={1}>
                        <Typography fontWeight='bold'>
                          <FormattedMessage id='common_labels_updatedAt' defaultMessage='Updated At' />:
                        </Typography>
                        <Typography>{formatUTCToLocal(sellRequest?.offer?.updatedAt)}</Typography>
                      </Stack>
                      {sellRequest?.offer?.status !== OfferStatus.PENDING && (
                        <Stack direction='row' spacing={1}>
                          <Typography fontWeight='bold'>
                            {sellRequest?.offer?.status === OfferStatus.APPROVED && (
                              <FormattedMessage id='sellRequests_labels_approvedBy' defaultMessage='Approved By' />
                            )}
                            {sellRequest?.offer?.status === OfferStatus.DECLINED && (
                              <FormattedMessage id='sellRequests_labels_declinedBy' defaultMessage='Declined By' />
                            )}
                            :
                          </Typography>
                          <Typography>{sellRequest?.offer?.approver}</Typography>
                        </Stack>
                      )}
                      {sellRequest?.offer?.status === OfferStatus.APPROVED && (
                        <Stack direction='row' spacing={1}>
                          <Typography fontWeight='bold'>
                            <FormattedMessage id='sellRequests_labels_approvedAt' defaultMessage='Approved At' />:
                          </Typography>
                          <Typography>{formatUTCToLocal(sellRequest?.offer?.approvedAt)}</Typography>
                        </Stack>
                      )}
                      <Stack direction='row' spacing={1}>
                        <Typography fontWeight='bold'>
                          <FormattedMessage
                            id='sellRequests_labels_expirationDaysLimit'
                            defaultMessage='Expiration in Days'
                          />
                          :
                        </Typography>
                        <Typography>{sellRequest?.offer?.expirationDaysLimit}</Typography>
                      </Stack>
                      {sellRequest?.offer?.status === OfferStatus.APPROVED && sellRequest?.offer?.approvedAt && (
                        <Stack direction='row' spacing={1}>
                          <Typography fontWeight='bold'>
                            <FormattedMessage
                              id='sellRequests_labels_expirationDate'
                              defaultMessage='Expiration Date'
                            />
                            :
                          </Typography>
                          <Typography>{expirationDate.format('DD.MM.YYYY HH:mm:ss')}</Typography>
                        </Stack>
                      )}
                    </Stack>
                    <Stack direction='column' spacing={1}>
                      <Stack direction='row' spacing={1}>
                        <Typography fontWeight='bold'>
                          <FormattedMessage id='sellRequests_labels_taxation' defaultMessage='Taxation' />:
                        </Typography>
                        <Typography>{sellRequest?.offer?.taxation}</Typography>
                      </Stack>
                      <Stack direction='row' spacing={1}>
                        <Typography fontWeight='bold'>
                          <FormattedMessage
                            id='sellRequests_labels_cxtSellingPrice'
                            defaultMessage='CHRONEXT sell price including VAT'
                          />
                          :
                        </Typography>
                        <Typography>
                          {getPriceLocaleString(
                            sellRequest?.offer?.cxtSellingPrice / 100,
                            language,
                            sellRequest?.seller?.country,
                            sellRequest?.currencyCode,
                          )}
                        </Typography>
                      </Stack>
                      <Stack direction='row' spacing={2} alignItems='center'>
                        <Typography fontWeight='bold'>
                          <FormattedMessage id='sellRequests_labels_sellingMethods' defaultMessage='Selling Methods' />:
                        </Typography>
                        <SellingMethods methods={getOfferSellingMethods()} />
                      </Stack>
                      {sellRequest?.offer?.directPrice > 0 && (
                        <Stack direction='row' spacing={1}>
                          <Typography fontWeight='bold'>
                            <FormattedMessage id='sellRequests_labels_directPrice' defaultMessage='Net direct offer' />:
                          </Typography>
                          <Typography>
                            {getPriceLocaleString(
                              sellRequest?.offer?.directPrice / 100,
                              language,
                              sellRequest?.seller?.country,
                              sellRequest?.currencyCode,
                            )}
                          </Typography>
                        </Stack>
                      )}
                      {sellRequest?.offer?.exchangePrice > 0 && (
                        <Stack direction='row' spacing={1}>
                          <Typography fontWeight='bold'>
                            <FormattedMessage
                              id='sellRequests_labels_exchangePrice'
                              defaultMessage='Net partex offer'
                            />
                            :
                          </Typography>
                          <Typography>
                            {getPriceLocaleString(
                              sellRequest?.offer?.exchangePrice / 100,
                              language,
                              sellRequest?.seller?.country,
                              sellRequest?.currencyCode,
                            )}
                          </Typography>
                        </Stack>
                      )}

                      {sellRequest?.offer?.commissionRate > 0 && (
                        <>
                          <Stack direction='row' spacing={1}>
                            <Typography fontWeight='bold'>
                              <FormattedMessage
                                id='sellRequests_labels_commissionRate'
                                defaultMessage='Commission rate'
                              />
                              :
                            </Typography>
                            <Typography>{sellRequest?.offer?.commissionRate}%</Typography>
                          </Stack>
                          <Stack direction='row' spacing={1}>
                            <Typography fontWeight='bold'>
                              <FormattedMessage
                                id='sellRequests_labels_commissionPrice'
                                defaultMessage='Commission price'
                              />
                              :
                            </Typography>
                            <Typography>
                              {getPriceLocaleString(
                                calculatePriceFromMargin(
                                  sellRequest?.offer?.cxtSellingPrice / 100,
                                  sellRequest?.offer?.commissionRate,
                                ),
                                language,
                                sellRequest?.seller?.country,
                                sellRequest?.currencyCode,
                              )}
                            </Typography>
                          </Stack>
                        </>
                      )}
                    </Stack>
                  </Stack>
                  <Stack direction='column' spacing={1}>
                    {sellRequest.offerReply && (
                      <>
                        {sellRequest.offerReply?.accept === 'approved' && (
                          <Stack spacing={2}>
                            <Typography fontWeight='medium' color='success.main'>
                              <FormattedMessage
                                id='sellRequests_offerReply_accepted'
                                defaultMessage='The customer has accepted this offer.'
                              />
                            </Typography>
                            <Stack direction='row' spacing={1}>
                              <Typography fontWeight='bold'>
                                <FormattedMessage id='sellRequests_labels_acceptedAt' defaultMessage='Accepted At' />:
                              </Typography>
                              <Typography>{formatUTCToLocal(sellRequest?.offerReply?.created_at)}</Typography>
                            </Stack>
                            <SellingMethods methods={[sellRequest?.offerReply?.selling_method?.toLowerCase()] || []} />
                          </Stack>
                        )}
                        {sellRequest.offerReply?.accept === 'declined' && (
                          <Stack spacing={2}>
                            <Typography fontWeight='medium' color='error.main'>
                              <FormattedMessage
                                id='sellRequests_offerReply_declined'
                                defaultMessage='The customer has declined this offer.'
                              />
                            </Typography>
                            <Stack direction='row' spacing={1}>
                              <Typography fontWeight='bold'>
                                <FormattedMessage id='sellRequests_labels_declinedAt' defaultMessage='Declined At' />:
                              </Typography>
                              <Typography>{formatUTCToLocal(sellRequest?.offerReply?.created_at)}</Typography>
                            </Stack>
                            {sellRequest.offerReply?.rejection_reason && (
                              <Typography>&quot;{offerRejectionReasonLabel}&quot;</Typography>
                            )}
                          </Stack>
                        )}
                      </>
                    )}
                    {sellRequest?.counterOffer && sellRequest.offerReply?.accept !== 'approved' && (
                      <Card variant='outlined'>
                        <CardContent>
                          <Stack spacing={1}>
                            <Typography variant='h6' fontWeight='medium'>
                              {sellRequest.counterOffer && (
                                <FormattedMessage
                                  id='sellRequests_offerReply_counterOffer'
                                  defaultMessage='Counter Offer'
                                />
                              )}
                            </Typography>
                            <Stack direction='row' spacing={1}>
                              <Typography fontWeight='bold'>
                                <FormattedMessage id='common_labels_createdAt' defaultMessage='Created At' />:
                              </Typography>
                              <Typography>{formatUTCToLocal(sellRequest?.counterOffer?.created_at)}</Typography>
                            </Stack>
                            <SellingMethods
                              methods={[sellRequest?.counterOffer?.selling_method?.toLowerCase()] || []}
                            />
                            <Typography>
                              {getPriceLocaleString(
                                sellRequest?.counterOffer?.price,
                                language,
                                sellRequest?.seller?.country,
                                sellRequest?.counterOffer?.currency,
                              )}
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    )}
                  </Stack>
                  <Stack direction='column' spacing={1}>
                    {allowedActions.finalizeOffer && (
                      <>
                        <Button
                          variant='outlined'
                          color='success'
                          startIcon={<Check />}
                          onClick={() => {
                            setFinalizeModalOpen(true);
                            setFinalizeStatus(OfferStatus.APPROVED);
                          }}>
                          <FormattedMessage id='common_approve' defaultMessage='Approve' />
                        </Button>
                        <Button
                          variant='outlined'
                          color='error'
                          startIcon={<Close />}
                          onClick={() => {
                            setFinalizeModalOpen(true);
                            setFinalizeStatus(OfferStatus.DECLINED);
                          }}>
                          <FormattedMessage id='common_decline' defaultMessage='Decline' />
                        </Button>
                      </>
                    )}
                    {allowedActions.updateOfferExpiration && (
                      <Button
                        variant='outlined'
                        color='info'
                        startIcon={<Edit />}
                        onClick={() => {
                          setUpdateExpirationModalOpen(true);
                        }}>
                        <FormattedMessage
                          id='sellRequests_actions_updateExpiration'
                          defaultMessage='Update Expiration'
                        />
                      </Button>
                    )}
                  </Stack>
                </Stack>
              ) : (
                <Typography>
                  <FormattedMessage id='sellRequests_details_noOffer' defaultMessage='Offer has not been created yet' />
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4} sm={12} md={12}>
          <Card className={styles.cardWrapper}>
            <CardHeader
              title={
                <Stack direction='row' justifyContent='space-between'>
                  <FormattedMessage id='sellRequests_details_cardTitles_vendor' defaultMessage='Vendor' />
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={1}>
                    {allowedActions.updateVendorInfo && (
                      <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => setUpdateVendorInfoModalOpen(true)}
                        startIcon={<Edit />}>
                        <FormattedMessage id='common_edit' defaultMessage='Edit' />
                      </Button>
                    )}
                    {allowedActions.requestVendorInfo && (
                      <Button
                        variant='outlined'
                        color='error'
                        onClick={() => setRequestVendorInfoModalOpen(true)}
                        startIcon={<PriorityHigh />}>
                        <FormattedMessage
                          id='sellRequests_actions_requestVendorInfo'
                          defaultMessage='Request Information'
                        />
                      </Button>
                    )}
                  </Stack>
                </Stack>
              }
            />
            <CardContent>
              <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-between' spacing={2}>
                <Stack direction='column' spacing={1}>
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='common_labels_country' defaultMessage='Country' />:
                    </Typography>
                    <Tooltip title={sellRequest.seller?.country} arrow>
                      <div>
                        <ReactCountryFlag
                          countryCode={sellRequest.seller?.country}
                          style={{ fontSize: '20px', lineHeight: '1em' }}
                        />
                      </div>
                    </Tooltip>
                    <Typography>{getCountryNameFromAlpha2(sellRequest?.seller?.country || '', language)}</Typography>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='sellRequests_labels_language' defaultMessage='Language' />:
                    </Typography>
                    <Typography>{sellRequest?.seller?.language}</Typography>
                  </Stack>
                  <Stack direction='row' alignItems='center' spacing={2}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='sellRequests_labels_email' defaultMessage='E-mail Address' />:
                    </Typography>
                    <Stack direction='row' alignItems='center' spacing={1}>
                      <Button
                        component={Link}
                        color='info'
                        sx={{ textTransform: 'none' }}
                        target='_blank'
                        href={`/sell/all?share=%7B%22filters%22:%5B%7B%22field%22:%22sellerEmail%22,%22operator%22:%22contains%22,%22id%22:41974,%22value%22:%22${sellRequest?.seller?.email}%22%7D%5D%7D`}>
                        {sellRequest?.seller?.email}
                      </Button>
                      <CopyToClipboardButton text={sellRequest?.seller?.email} />
                    </Stack>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='common_labels_gender' defaultMessage='Gender' />:
                    </Typography>
                    <Typography>{sellRequest?.seller?.gender}</Typography>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='sellRequests_labels_fullName' defaultMessage='Full Name' />:
                    </Typography>
                    <Typography>
                      {sellRequest?.seller?.firstName} {sellRequest?.seller?.lastName}
                    </Typography>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='common_labels_phoneNumber' defaultMessage='Phone Number' />:
                    </Typography>
                    <Typography>{sellRequest?.seller?.phoneNumber}</Typography>
                  </Stack>
                </Stack>
                <Stack direction='column' spacing={1}>
                  <Stack direction='row' spacing={2}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='common_labels_iban' defaultMessage='IBAN' />:
                    </Typography>
                    <Typography>{sellRequest?.seller?.iban}</Typography>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='common_labels_accountHolder' defaultMessage='Account Holder' />:
                    </Typography>
                    <Typography>{sellRequest?.seller?.bankHolderName}</Typography>
                  </Stack>{' '}
                  <Stack direction='row' spacing={2}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='common_labels_bankName' defaultMessage='Bank Name' />:
                    </Typography>
                    <Typography>{sellRequest?.seller?.bankName}</Typography>
                  </Stack>
                </Stack>

                <Stack direction='column' spacing={1}>
                  {sellRequest?.seller?.addresses?.map((address, index) => (
                    <Address key={index} address={address} />
                  ))}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4} sm={12} md={12}>
          <Card className={styles.cardWrapper}>
            <CardHeader
              title={
                <FormattedMessage
                  id='sellRequests_details_cardTitles_priceEstimates'
                  defaultMessage='Price Estimates'
                />
              }
            />
            <CardContent>
              <Stack direction='column' spacing={1}>
                <Stack direction='row' spacing={2}>
                  <Typography fontWeight='bold'>
                    <FormattedMessage id='sellRequests_labels_priceEstimates' defaultMessage='Price Estimates' />:
                  </Typography>
                  <Typography>{getPriceRangeString(sellRequest?.priceFrom, sellRequest?.priceTo)}</Typography>
                </Stack>
                <Stack direction='row' spacing={2}>
                  <Typography fontWeight='bold'>
                    <FormattedMessage id='sellRequests_sellingMethods_direct' defaultMessage='Direct' />:
                  </Typography>
                  <Typography>
                    {getPriceRangeString(sellRequest?.directPriceFrom, sellRequest?.directPriceTo)}
                  </Typography>
                </Stack>
                <Stack direction='row' spacing={2}>
                  <Typography fontWeight='bold'>
                    <FormattedMessage id='sellRequests_sellingMethods_commission' defaultMessage='Commission' />:
                  </Typography>
                  <Typography>
                    {getPriceRangeString(sellRequest?.commissionPriceFrom, sellRequest?.commissionPriceTo)}
                  </Typography>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default SellRequestDetails;
