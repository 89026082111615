import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { WorkshopActivities, WorkshopReworkReasons, WorkshopStatus } from '../../../../constants/workshop';
import { useAppDispatch } from '../../../../state';
import { createWorkshopInboundActivityAction } from '../../../../state/ducks/workshopInbound/actions';
import { WorkshopInboundWatchService } from '../../../../state/ducks/workshopInbound/types';
import { text2title } from '../../../../utils/textUtils';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  service: WorkshopInboundWatchService;
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
};

const initialFormData: { reasons: WorkshopReworkReasons[] | [] } = {
  reasons: [],
};

const CreateReworkActivityModal: React.FC<Props> = ({ show, handleToggleModal, service, onSuccess }) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const handleReasonsChange = (event: SelectChangeEvent<WorkshopReworkReasons[]>) => {
    let newReasons: WorkshopReworkReasons[] = [];
    if (Array.isArray(event.target.value)) {
      newReasons = event.target.value;
    }
    setFormData({ ...formData, reasons: newReasons });
  };

  const onSubmit = () => {
    if (!formData.reasons.length) return;
    const params = {
      service_id: service.id.toString(),
      rework_reasons: formData.reasons,
      status: WorkshopStatus.pending,
      activity_type: WorkshopActivities.rework,
    };
    dispatch(createWorkshopInboundActivityAction(params)).then((response) => {
      if (response) {
        setShowSnackbar(true);
      }
      if (response.type === createWorkshopInboundActivityAction.fulfilled.type) {
        handleToggleModal();
        onSuccess();
      }
    });
  };

  const clearForm = () => {
    setFormData(initialFormData);
  };

  useEffect(() => {
    clearForm();
  }, [show]);

  return (
    <>
      {showSnackbar && (
        <ActionStatusSnackbar
          actionName={createWorkshopInboundActivityAction.typePrefix}
          config={{
            messageValues: {
              reference: intl.formatMessage({
                id: 'workshop_serviceActivityTypes_rework',
                defaultMessage: 'Rework',
              }),
            },
          }}
        />
      )}
      <Dialog open={show} onClose={handleToggleModal} maxWidth='sm' fullWidth>
        <DialogTitle>
          <FormattedMessage id='workshopInbound_reworkModal_title' defaultMessage='Create rework activity' />
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} mt={2}>
            <FormControl>
              <InputLabel id='reasonsLabel'>
                <FormattedMessage id='workshopInbound_labels_reworkReasons' defaultMessage='Rework Reasons' />
              </InputLabel>
              <Select
                margin='dense'
                id='reasons'
                name='reasons'
                value={formData.reasons}
                onChange={handleReasonsChange}
                multiple
                input={
                  <OutlinedInput
                    label={
                      <FormattedMessage id='workshopInbound_labels_reworkReasons' defaultMessage='Rework Reasons' />
                    }
                  />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={
                          <FormattedMessage id={`workshop_reworkReasons_${value}`} defaultMessage={text2title(value)} />
                        }
                      />
                    ))}
                  </Box>
                )}
                labelId='reasonsLabel'
                fullWidth>
                {Object.keys(WorkshopReworkReasons).map((key) => (
                  <MenuItem key={key} value={key}>
                    <FormattedMessage id={`workshop_reworkReasons_${key}`} defaultMessage={text2title(key)} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleModal}>
            <FormattedMessage id='common_close' defaultMessage='Close' />
          </Button>
          <Button color='info' onClick={onSubmit} disabled={!formData.reasons.length}>
            <FormattedMessage id='common_submit' defaultMessage='Submit' />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateReworkActivityModal;
