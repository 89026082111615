import { Button, Stack, TextField, Typography } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, useHistory } from 'react-router-dom';
import { ClientRoute, createPath } from '../../../routing';
import { workshopItemsSelector } from '../../../selectors/workshop';
import { useAppSelector } from '../../../state';
import { Product, Variant } from '../../../state/ducks/inventory/types';
import { getWorkshopItemsAction } from '../../../state/ducks/workshop/actions';
import { Process } from '../../../state/ducks/workshop/types';
import ServerSidePaginatedTable from '../../components/table/ServerSidePaginatedTable';
import { SimpleTableColDef } from '../../components/table/SimpleTable';
import ProductFilter from '../inventory/components/ProductFilter';
import VariantFilter from '../inventory/components/VariantFilter';

const columns: SimpleTableColDef[] = [
  {
    id: 'common_labels_reference',
    headerName: 'Reference',
    field: 'reference',
    sortable: false,
    minWidth: 200,
  },
  {
    id: 'workshop_inventory_labels_productReference',
    headerName: 'Product Reference',
    field: 'productReference',
    sortable: false,
    minWidth: 150,
  },
  {
    id: 'common_labels_variantCode',
    headerName: 'Variant Code',
    field: 'variantReference',
    sortable: false,
    minWidth: 150,
  },
  {
    id: 'workshop_inventory_labels_ongoingProcesses',
    headerName: 'Ongoing Processes',
    field: 'ongoingProcesses',
    sortable: false,
    minWidth: 150,
    align: 'center',
    valueGetter: (params) => {
      const processes: Process[] = params.row?.processes;
      return processes.filter((p) => !p.isCompleted).length;
    },
  },
  {
    id: 'workshop_inventory_labels_lastActivity',
    headerName: 'Last Activity',
    field: 'lastActivity',
    sortable: false,
    minWidth: 150,
    valueGetter: (params) => {
      return params.row?.processes[0]?.activity?.name;
    },
  },
  {
    id: 'workshop_labels_lastTimestamp',
    headerName: 'Last Timestamp',
    field: 'lastTimestamp',
    sortable: false,
    minWidth: 150,
    valueGetter: (params) => {
      return params.row?.processes[0]?.latestTimestamp;
    },
  },
];

const Inventory: React.FC = () => {
  const intl = useIntl();
  const { items: workshopItems, count } = useAppSelector(workshopItemsSelector);
  const history = useHistory();
  const [product, setProduct] = useState<Product | undefined>();
  const [variant, setVariant] = useState<Variant | undefined>();
  const [searchKey, setSearchKey] = useState<string>('');

  // As these values are directly placed in URL query parameters by
  // the table component, they have been written in snake case form
  const [searchParams, setSearchParams] = useState({
    product_reference: '',
    variant_reference: '',
    search_key: '',
  });

  useEffect(() => {
    setSearchParams({
      product_reference: product?.reference || '',
      variant_reference: variant?.reference || '',
      search_key: searchKey,
    });
  }, [product, variant, searchKey]);

  return (
    <Stack spacing={1} sx={{ position: 'relative', height: '100%' }}>
      <Stack spacing={1} direction='row' sx={{ alignItems: 'center' }}>
        <TextField
          sx={{ width: 250 }}
          margin='dense'
          size='small'
          name='workshopItemReference'
          value={searchKey}
          onChange={(event) => setSearchKey(event.target.value)}
          label={`${intl.formatMessage({ id: 'common_search', defaultMessage: 'Search' })}...`}
          inputProps={{ style: { textTransform: 'uppercase' } }}
        />
        <ProductFilter width={250} onSelectChange={(product) => setProduct(product)} />
        <VariantFilter
          width={250}
          onSelectChange={(variant) => setVariant(variant)}
          productReference={product?.reference}
        />
        {product || variant ? (
          <Typography color='text.secondary'>
            <FormattedMessage id='workshop_inventory_model' defaultMessage='Model' /> :
            {product?.description || variant?.product.description}
          </Typography>
        ) : null}
      </Stack>
      <ServerSidePaginatedTable
        columns={columns}
        rows={workshopItems}
        fetchDataEffect={getWorkshopItemsAction}
        initPageSize={50}
        totalCount={count}
        searchParams={searchParams}
        onRowClick={(params: GridRowParams) => {
          const inventoryItemPath = createPath({
            path: ClientRoute.WORKSHOP_INVENTORY_ITEM,
            params: { reference: params.row.reference },
          });
          history.push(inventoryItemPath);
        }}
      />
    </Stack>
  );
};

export default Inventory;
