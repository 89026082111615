import { Euro, Percent } from '@mui/icons-material';
import { Button, FormControl, InputAdornment, Stack, TextField } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { MarginType } from '../../../../constants/pricing';
import { MarginTiers } from '../../../../state/ducks/pricing/types';

const MIN_MARGIN_VALUE = 5;

export type AddMarginTierFormData = {
  minValue: number;
  marginValue: number;
};
type Props = {
  addMarginTier: (type: MarginType, data: AddMarginTierFormData) => void;
  type: MarginType;
  previousMargins: MarginTiers;
};

const AddMarginTierForm: React.FC<Props> = ({ type, addMarginTier, previousMargins }) => {
  const [minValue, setMinValue] = useState('');
  const [marginValue, setMarginValue] = useState('');

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (Object.keys(previousMargins).length <= 0) {
      setIsValid(!!minValue && Number(minValue) === 0 && Number(marginValue) >= MIN_MARGIN_VALUE);
    } else {
      setIsValid(!!marginValue && Number(marginValue) >= MIN_MARGIN_VALUE);
    }
  }, [minValue, marginValue]);

  const submit = () => {
    addMarginTier(type, { minValue: Number(minValue), marginValue: Number(marginValue) });
    setMinValue('');
    setMarginValue('');
  };

  return (
    <Stack direction='row' spacing={2}>
      <FormControl>
        <TextField
          margin='dense'
          type='number'
          id='minValue'
          name='minValue'
          value={minValue}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setMinValue(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Euro />
              </InputAdornment>
            ),
          }}
          label={<FormattedMessage id='priceCalculatorConfig_minProductValue' defaultMessage='Min. Product Value' />}
          helperText={
            !isValid &&
            Object.keys(previousMargins).length === 0 &&
            (minValue === '' || Number(minValue) !== 0) && (
              <FormattedMessage
                id='priceCalculatorConfig_createModal_marginTierMinValueError'
                defaultMessage='First entry has to be 0.'
              />
            )
          }
        />
      </FormControl>
      <FormControl>
        <TextField
          margin='dense'
          type='number'
          id='marginValue'
          name='marginValue'
          value={marginValue}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setMarginValue(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Percent />
              </InputAdornment>
            ),
          }}
          label={<FormattedMessage id='common_labels_margin' defaultMessage='Margin' />}
          helperText={
            !isValid &&
            Number(marginValue) < MIN_MARGIN_VALUE && (
              <FormattedMessage
                id='priceCalculatorConfig_createModal_marginTierMarginValueError'
                defaultMessage='Minimum value {minMarginValue}.'
                values={{ minMarginValue: `${MIN_MARGIN_VALUE}%` }}
              />
            )
          }
        />
      </FormControl>
      <Button color='success' onClick={submit} disabled={!isValid}>
        <FormattedMessage id='common_add' defaultMessage='Add' />
      </Button>
    </Stack>
  );
};

export default AddMarginTierForm;
