import { RootState } from '../../state';

export const workshopServiceStateSelector = (state: RootState) => state.workshopService;

export const workshopServiceServicesSelector = (state: RootState) => ({
  services: state.workshopService.services,
  servicesTotal: state.workshopService.servicesTotal,
});

export const workshopServiceBrandsSelector = (state: RootState) => ({
  brands: state.workshopService.brands,
  brandsTotal: state.workshopService.brandsTotal,
});

export const workshopServicePricesSelector = (state: RootState) => ({
  prices: state.workshopService.prices,
  pricesTotal: state.workshopService.pricesTotal,
  price: state.workshopService.price,
});
