import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../../state';
import { deleteWorkshopPriceAction } from '../../../../state/ducks/workshopService/actions';
import { Price } from '../../../../state/ducks/workshopService/types';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  price: Price;
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
};

const DeleteWorkshopPriceModal: React.FC<Props> = ({ price, handleToggleModal, show, onSuccess }) => {
  const dispatch = useAppDispatch();
  const [showSnackbar, setShowSnackbar] = useState(false);

  return (
    <>
      {showSnackbar && (
        <ActionStatusSnackbar
          actionName={deleteWorkshopPriceAction.typePrefix}
          config={{ messageValues: { entry: `${price.brand}${price.model ? ` ${price.model}` : ''}` } }}
        />
      )}
      <Dialog open={show} onClose={handleToggleModal}>
        <DialogTitle>
          <FormattedMessage
            id='common_deleteModal_title'
            defaultMessage='Delete entry "{entry}"?'
            values={{ entry: `${price.brand}${price.model ? ` ${price.model}` : ''}` }}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontWeight: 'medium' }}>
            <FormattedMessage id='common_warning_noUndo' defaultMessage='This action cannot be undone.' />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleModal}>
            <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
          </Button>
          <Button
            onClick={() => {
              dispatch(deleteWorkshopPriceAction(price.id)).then((response) => {
                setShowSnackbar(true);
                if (response?.payload && !response.payload?.errors) {
                  handleToggleModal();
                  onSuccess();
                }
              });
            }}
            color='error'>
            <FormattedMessage id='common_delete' defaultMessage='Delete' />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteWorkshopPriceModal;
