export enum AlgoliaActions {
  getBrands = 'algolia/getBrands',
  getModels = 'algolia/getModels',
}

export type Brand = {
  name: string;
  slug: string;
};

export type Model = {
  name: string;
  slug: string;
};

export type AlgoliaState = {
  brands: Brand[];
  brandsTotal: number;
  models: Model[];
  modelsTotal: number;
  dialColors: string[];
};
