import { DeleteForever } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { Button, ButtonGroup } from '@mui/material';
import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { workshopServiceServicesSelector } from '../../../selectors/workshopService';
import { useAppSelector } from '../../../state';
import { getWorkshopServicesAction } from '../../../state/ducks/workshopService/actions';
import ButtonGroupIconButton from '../../components/buttons/ButtonGroupIconButton';
import BooleanIcon from '../../components/icons/BooleanIcon';
import ActionStatusSnackbar from '../../components/snackbars/ActionStatusSnackbar';
import ServerSidePaginatedTable, {
  ServerSidePaginatedTableColDef,
} from '../../components/table/ServerSidePaginatedTable';
import CreateUpdateWorkshopServiceModal from './components/CreateUpdateWorkshopServiceModal';
import DeleteWorkshopServiceModal from './components/DeleteWorkshopServiceModal';
import ToggleWorkshopServiceModal from './components/ToggleWorkshopServiceModal';
import WorkshopServicesTableToolbar from './components/WorkshopServicesTableToolbar';
import styles from './WorkshopPrices.module.scss';

const servicesTableColumns: ServerSidePaginatedTableColDef[] = [
  {
    id: 'common_labels_id',
    headerName: 'ID',
    field: 'id',
    maxWidth: 60,
    sortable: false,
    flex: 1,
  },
  {
    id: 'workshopPrices_labels_key',
    headerName: 'Key',
    field: 'key',
    sortable: false,
    flex: 1,
  },
  {
    id: 'workshopPrices_labels_name',
    headerName: 'Name',
    field: 'name',
    sortable: false,
    flex: 1,
  },
  {
    id: 'workshopPrices_labels_active',
    headerName: 'Active',
    field: 'active',
    sortable: false,
    align: 'center',
    renderCell: (params: GridRenderCellParams) => {
      const { active } = params.row;
      return <BooleanIcon check={active} />;
    },
  },
  {
    id: 'common_labels_updatedAt',
    headerName: 'Updated At',
    field: 'dateUpdated',
    sortable: false,
    valueGetter: ({ value }: GridValueGetterParams) => value && new Date(value),
    flex: 1,
  },
  {
    id: 'common_labels_createdAt',
    headerName: 'Created At',
    field: 'dateCreated',
    sortable: false,
    valueGetter: ({ value }: GridValueGetterParams) => value && new Date(value),
    flex: 1,
  },
];

const WorkshopServices: React.FC = () => {
  const intl = useIntl();
  const { services, servicesTotal } = useAppSelector(workshopServiceServicesSelector);
  const [tableColumns, setTableColumns] = useState<ServerSidePaginatedTableColDef[]>(servicesTableColumns);

  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [searchParams] = useState({
    where: '',
  });
  const [selectedService, setSelectedService] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showToggleModal, setShowToggleModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  useEffect(() => {
    const actionsColumn: ServerSidePaginatedTableColDef[] = [];
    actionsColumn.push({
      id: 'common_actions',
      headerName: 'Actions',
      field: 'actions',
      width: 200,
      align: 'right',
      sortable: false,
      renderCell: (params: any) => {
        const { active } = params.row;
        return (
          <ButtonGroup variant='text'>
            <Button
              color={active ? 'error' : 'success'}
              onClick={() => {
                setSelectedService(params.row);
                setShowToggleModal(true);
              }}>
              {active ? (
                <FormattedMessage id='common_disable' defaultMessage='Disable' />
              ) : (
                <FormattedMessage id='common_enable' defaultMessage='Enable' />
              )}
            </Button>
            <ButtonGroupIconButton
              color='info'
              onClick={() => {
                setSelectedService(params.row);
                setShowUpdateModal(true);
              }}
              title={intl.formatMessage({
                id: 'common_edit',
                defaultMessage: 'Edit',
              })}>
              <EditIcon />
            </ButtonGroupIconButton>
            <ButtonGroupIconButton
              color='error'
              onClick={() => {
                setSelectedService(params.row);
                setShowDeleteConfirmationModal(true);
              }}
              title={intl.formatMessage({
                id: 'common_delete',
                defaultMessage: 'Delete',
              })}>
              <DeleteForever />
            </ButtonGroupIconButton>
          </ButtonGroup>
        );
      },
    });
    setTableColumns([...servicesTableColumns, ...actionsColumn]);
  }, []);

  return (
    <div className={styles.wrapper}>
      <ActionStatusSnackbar actionName={getWorkshopServicesAction.typePrefix} />
      {showUpdateModal && selectedService && (
        <CreateUpdateWorkshopServiceModal
          show={showUpdateModal}
          service={selectedService}
          handleToggleModal={() => setShowUpdateModal(!showUpdateModal)}
          onSuccess={() => setTriggerRefresh(!triggerRefresh)}
        />
      )}
      {showToggleModal && selectedService && (
        <ToggleWorkshopServiceModal
          show={showToggleModal}
          service={selectedService}
          handleToggleModal={() => setShowToggleModal(!showToggleModal)}
          onSuccess={() => setTriggerRefresh(!triggerRefresh)}
        />
      )}
      {showDeleteConfirmationModal && selectedService && (
        <DeleteWorkshopServiceModal
          show={showDeleteConfirmationModal}
          service={selectedService}
          handleToggleModal={() => setShowDeleteConfirmationModal(!showDeleteConfirmationModal)}
          onSuccess={() => setTriggerRefresh(!triggerRefresh)}
        />
      )}
      <ServerSidePaginatedTable
        rows={services}
        columns={tableColumns}
        totalCount={servicesTotal}
        triggerRefresh={triggerRefresh}
        fetchDataEffect={getWorkshopServicesAction}
        searchParams={searchParams}
        slots={{
          toolbar: WorkshopServicesTableToolbar,
        }}
        slotProps={{
          toolbar: {
            onSuccess: () => setTriggerRefresh(!triggerRefresh),
          },
        }}
      />
    </div>
  );
};

export default WorkshopServices;
