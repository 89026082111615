import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getProducts,
  getProductByReference,
  getVariants,
  getInventoryItemByReference,
} from '../../../api/server/inventory';
import { InventoryActions } from './types';

export const getProductsAction = createAsyncThunk(
  InventoryActions.getProducts,
  async (params: URLSearchParams | null) => {
    const response = await getProducts(params);
    return response.data;
  },
);

export const getProductAction = createAsyncThunk(InventoryActions.getProduct, async (reference: string) => {
  const response = await getProductByReference(reference);
  return response.data;
});

export const getVariantsAction = createAsyncThunk(
  InventoryActions.getVariants,
  async (params: URLSearchParams | null) => {
    const response = await getVariants(params);
    return response.data;
  },
);

export const getInventoryItemAction = createAsyncThunk(InventoryActions.getInventoryItem, async (reference: string) => {
  const response = await getInventoryItemByReference(reference);
  return response.data;
});
