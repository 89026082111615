import { RootState } from '../../state';

export const workshopInboundSelector = (state: RootState) => state.workshopInbound;

export const workshopInboundRequestsSelector = (state: RootState) => state.workshopInbound.requests;

export const workshopInboundWatchesSelector = (state: RootState) => state.workshopInbound.watches;

export const workshopInboundWatchSelector = (state: RootState) => state.workshopInbound.watch;

export const workshopInboundActivitiesSelector = (state: RootState) => state.workshopInbound.activities;

export const workshopInboundMaintenanceActivitySelector = (state: RootState) =>
  state.workshopInbound.maintenanceActivity;

export const refetchActivitiesSelector = (state: RootState) => state.workshopInbound.refetchActivities;

export const workshopInboundActivitySelector = (state: RootState) => state.workshopInbound.activity;

export const workshopInboundReportFieldsSelector = (state: RootState) => state.workshopInbound.reportFields;

export const workshopInboundReportSelector = (state: RootState) => state.workshopInbound.report;

export const workshopInboundReportItemOverviewSelector = (state: RootState) => state.workshopInbound.reportItemOverview;

export const workshopInboundReportItemDetailsSelector = (state: RootState) => state.workshopInbound.reportItemDetails;
