import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import moment, { Moment } from 'moment';
import React from 'react';
import { WorkshopReportType, WorkshopStatus } from '../../../../constants/workshop';
import StatusBadge from '../../../components/badges/StatusBadge';
import DateFilter from '../../../components/datetime/DateFilter';

type Props = {
  onDateFilterUpdate: (start: string, end: string) => void;
  metaData: any;
  showTotals?: boolean;
  params?: Record<string, string | null> | { [x: string]: string | number | boolean | null };
  type: WorkshopReportType;
};

const ToolbarTotals: React.FC<{ totals: any; type: WorkshopReportType }> = ({ totals, type }) => {
  switch (type) {
    case WorkshopReportType.activity_overview:
    case WorkshopReportType.assignee_overview:
      return (
        <Stack direction='row' spacing={1} sx={{ flex: 1, justifyContent: 'flex-end' }}>
          {Object.keys(WorkshopStatus).map((status) => {
            return (
              <Card variant='outlined' key={status}>
                <CardContent>
                  <Stack direction='row' spacing={1} justifyContent={'center'}>
                    <Typography variant='h5' component='div'>
                      {status === WorkshopStatus.in_progress ? totals?.inProgress : totals?.[status]}
                    </Typography>
                    <StatusBadge status={status} />
                  </Stack>
                </CardContent>
              </Card>
            );
          })}
        </Stack>
      );
    default:
      return null;
  }
};

const WorkshopInboundDashboardToolbar: React.FC<Props> = ({ onDateFilterUpdate, metaData, params, type }) => {
  const onFilterUpdate = (start: Moment, end: Moment) => {
    const startDate = start.format('YYYY-MM-DD');
    const endDate = end.format('YYYY-MM-DD');
    return onDateFilterUpdate(startDate, endDate);
  };

  return (
    <Stack direction='row' spacing={2} p={1}>
      <Box pt={1.5}>
        <DateFilter
          onFilterUpdate={onFilterUpdate}
          daysRange={360}
          startDate={
            params?.start_date
              ? moment(params?.start_date?.toString())
              : moment.utc().subtract(1, 'day').startOf('month')
          }
          endDate={params?.end_date ? moment(params?.end_date?.toString()) : moment.utc().subtract(1, 'day')}
          maxEndDate={moment.utc().subtract(1, 'day')}
        />
      </Box>
      <ToolbarTotals totals={metaData?.totals} type={type} />
    </Stack>
  );
};

export default WorkshopInboundDashboardToolbar;
