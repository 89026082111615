import { TabContext, TabPanel } from '@mui/lab';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import AllActivityReport from './components/AllActivities';
import AreaOverview from './components/AreaOverview';
import DailyActivityReport from './components/DailyActivity';
import EmployeeActivityReport from './components/Employee';

const tabProps = {
  sx: {
    flex: 1,
    padding: 0,
  },
};

const WorkshopReports: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState(0);

  return (
    <TabContext value={tabIndex.toString()}>
      <Stack sx={{ height: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 3 }}>
          <Tabs selectionFollowsFocus value={tabIndex} onChange={(_, newValue) => setTabIndex(newValue)}>
            <Tab label={<FormattedMessage id='workshop_reports_allActivity' defaultMessage='All Activity Records' />} />
            <Tab
              label={<FormattedMessage id='workshop_reports_dailyActivity' defaultMessage='Daily Activity Overview' />}
            />
            <Tab
              label={
                <FormattedMessage id='workshop_reports_employeeActivity' defaultMessage='Employee Activity Overview' />
              }
            />
            <Tab label={<FormattedMessage id='workshop_reports_areaOverview' defaultMessage='Area Overview' />} />
          </Tabs>
        </Box>
        <TabPanel value='0' {...tabProps}>
          <AllActivityReport />
        </TabPanel>
        <TabPanel value='1' {...tabProps}>
          <DailyActivityReport />
        </TabPanel>
        <TabPanel value='2' {...tabProps}>
          <EmployeeActivityReport />
        </TabPanel>
        <TabPanel value='3' {...tabProps}>
          <AreaOverview />
        </TabPanel>
      </Stack>
    </TabContext>
  );
};

export default WorkshopReports;
