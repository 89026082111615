import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createPricingConfigEntry,
  CreatePricingConfigParams,
  deletePricingConfigEntry,
  getPricing,
  getPricingConfig,
  getPricingConfigBrands,
  getPricingConfigModels,
  getPricingConfigReferences,
  updatePricingConfigEntry,
  UpdatePricingConfigParams,
} from '../../../api/server/pricing';
import { PricingActions } from './types';

export const getPricingAction = createAsyncThunk(PricingActions.getPricing, async (params: URLSearchParams) => {
  const response = await getPricing(params);
  return response.data;
});

export const getPricingConfigAction = createAsyncThunk(
  PricingActions.getPricingConfig,
  async (params: URLSearchParams) => {
    const response = await getPricingConfig(params);
    return response.data;
  },
);

export const createPricingConfigEntryAction = createAsyncThunk(
  PricingActions.createPricingConfigEntry,
  async (params: CreatePricingConfigParams) => {
    const response = await createPricingConfigEntry(params);
    return response.data;
  },
);

export const updatePricingConfigEntryAction = createAsyncThunk(
  PricingActions.updatePricingConfigEntry,
  async (params: UpdatePricingConfigParams) => {
    const response = await updatePricingConfigEntry(params);
    return response.data;
  },
);

export const deletePricingConfigEntryAction = createAsyncThunk(
  PricingActions.deletePricingConfigEntry,
  async (id: string) => {
    const response = await deletePricingConfigEntry(id);
    return response.data;
  },
);

export const getPricingConfigBrandsAction = createAsyncThunk(
  PricingActions.getPricingConfigBrands,
  async (params: URLSearchParams) => {
    const response = await getPricingConfigBrands(params);
    return response.data;
  },
);

export const getPricingConfigModelsAction = createAsyncThunk(
  PricingActions.getPricingConfigModels,
  async (brand: string) => {
    const response = await getPricingConfigModels(brand);
    return response.data;
  },
);

type GetPricingConfigReferencesParams = {
  brand: string;
  model: string;
};

export const getPricingConfigReferencesAction = createAsyncThunk(
  PricingActions.getPricingConfigReferences,
  async ({ brand, model }: GetPricingConfigReferencesParams) => {
    const response = await getPricingConfigReferences(brand, model);
    return response.data;
  },
);
