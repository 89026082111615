import { loadLocalStorageState, saveLocalStorageState } from '@chronext/react-common';
import { GridFilterItem, GridFilterModel, GridSortModel } from '@mui/x-data-grid';
import * as clipboard from 'clipboard-polyfill';
import { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { SearchParams } from '../views/components/table/ServerSidePaginatedTable';

export type sort = { [key: string]: string | boolean };
export type sortFunctionParams = { sortModel: GridSortModel; QUERY_MAP?: Record<string, any> | null };

const getKeyByValue = (obj: any, value: any) => obj.find((key: any) => key === value);

const useSearchStoreHistory = ({
  storeName,
  defaultSearchParams,
  QUERY_MAP = null,
  sortFunction = null,
  apiRef,
}: {
  storeName: string;
  defaultSearchParams: SearchParams;
  QUERY_MAP?: Record<string, any> | null;
  sortFunction?: (({ sortModel, QUERY_MAP }: sortFunctionParams) => sort | null) | null;
  apiRef: any;
}) => {
  const parseFilterModel = (items: GridFilterItem[]) => {
    const defaultSearchParamsKeys = Object.keys(defaultSearchParams ?? {});
    return items?.reduce(
      (acc, item) => {
        const fieldKey = QUERY_MAP?.[item.field] || getKeyByValue(defaultSearchParamsKeys, item.field) || item.field;
        const value = item.value;
        return fieldKey && value ? { ...acc, [fieldKey]: value } : acc;
      },
      { ...defaultSearchParams },
    );
  };

  const parseSortModel = (sortModel: GridSortModel): sort | null => {
    return sortFunction ? sortFunction({ sortModel, QUERY_MAP }) : {};
  };

  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shareParam = queryParams.get('share');

  useEffect(() => {
    if (shareParam) {
      history.replace(location.pathname);
    }
  }, [shareParam, storeName, history]);

  if (shareParam) {
    saveLocalStorageState(storeName, JSON.parse(shareParam));
  }

  const savedSearch = loadLocalStorageState(storeName);
  const hasSavedSearch =
    savedSearch !== null &&
    savedSearch !== undefined &&
    (savedSearch?.filters?.length > 0 || savedSearch?.sort?.length > 0);

  const initialFilters = savedSearch?.filters || [];
  const initialSorting = savedSearch?.sort || [];

  const [searchParams, setSearchParams] = useState<SearchParams>(() => {
    const filters = parseFilterModel(initialFilters);
    const sort = parseSortModel(initialSorting);
    return { ...defaultSearchParams, ...filters, ...sort };
  });

  const updateSearchFilters = (filterModel: GridFilterModel) => {
    const filters = parseFilterModel(filterModel?.items);
    const localStorageState = {
      ...loadLocalStorageState(storeName),
      filters: filterModel?.items,
    };
    saveLocalStorageState(storeName, localStorageState);
    setSearchParams(filters);
  };

  const updateSort = (sortModel: GridSortModel) => {
    const localStorageState = {
      ...loadLocalStorageState(storeName),
      sort: sortModel,
    };
    saveLocalStorageState(storeName, localStorageState);
    const sort = parseSortModel(sortModel);
    setSearchParams((prev) => ({ ...prev, ...sort }));
  };

  const resetSearch = () => {
    apiRef.current.setFilterModel({ items: [] });
    apiRef.current.setSortModel([]);
    setSearchParams({ ...defaultSearchParams });
    setTimeout(() => {
      localStorage.removeItem(storeName);
    }, 150);
  };

  const shareSearch = async () => {
    await clipboard.writeText(`${window.location.href}?share=${encodeURI(JSON.stringify(savedSearch))}`);
  };

  return {
    searchParams,
    updateSearchFilters,
    updateSort,
    resetSearch,
    initialFilters,
    initialFiltersParsed: parseFilterModel(initialFilters),
    initialSorting,
    hasSavedSearch,
    shareSearch,
  };
};

export default useSearchStoreHistory;
