import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  LinearProgress,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import deLocale from 'date-fns/locale/de';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { WorkshopActivities } from '../../../../constants/workshop';
import {
  WORKSHOP_REPORT_QUESTIONS,
  WorkshopActivityReportFormData,
  WorkshopReportQuestion,
} from '../../../../constants/workshopReport';
import { requestSelector } from '../../../../selectors/request';
import { currentUserSelector } from '../../../../selectors/user';
import { workshopInboundReportFieldsSelector } from '../../../../selectors/workshop-inbound/workshop-inbound.selector';
import { useAppDispatch, useAppSelector } from '../../../../state';
import {
  createWorkshopInboundReportAction,
  getWorkshopInboundReportFieldsAction,
  updateWorkshopInboundActivityAction,
  updateWorkshopInboundReportAction,
} from '../../../../state/ducks/workshopInbound/actions';
import { WorkshopInboundWatchActivity } from '../../../../state/ducks/workshopInbound/types';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  activity: WorkshopInboundWatchActivity;
  show: boolean;
  handleToggleModal: () => void;
  onSuccess?: () => void;
};

const initialFormData: WorkshopActivityReportFormData = {
  is_authentic: false,
  caliber: '',
  manufacturer_serial_no: '',
  warranty_date: '',
  watch_type: '',
  service_needed: undefined,
  repairable: undefined,
  internal_service: undefined,
  spare_parts_needed: undefined,
  spare_parts: '',
  has_bracelet: undefined,
  bracelet_type: '',
  bracelet_material: '',
  bracelet_supply_rolex_only: '',
  case_material: '',
  case_size: 0,
  case_shape: '',
  crystal_material: '',
  dial_color: '',
  index_type: '',
  movement_type: '',
  dial_up_amplitude: 0,
  crown_down_amplitude: 0,
  bracelet_length: 0,
  current_condition: '',
  polished_condition: '',
  service_mandatory: '',
  actual_reference: '',
  comment: '',
};

const WorkshopInboundReportForm: React.FC<Props> = ({ activity, show, handleToggleModal, onSuccess }) => {
  const reportFields = useAppSelector(workshopInboundReportFieldsSelector);
  const createRequest = useAppSelector((state) => requestSelector(state, createWorkshopInboundReportAction.typePrefix));
  const currentUser = useAppSelector(currentUserSelector);
  const [showCreateSnackbar, setShowCreateSnackbar] = useState(false);
  const [showUpdateSnackbar, setShowUpdateSnackbar] = useState(false);
  const [showUpdateActivitySnackbar, setShowUpdateActivitySnackbar] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const dispatch = useAppDispatch();

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.checked });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (name: string, value?: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (name: string, newDate: Date | null | unknown) => {
    const date = newDate as Date;
    date.setUTCHours(0, 0, 0, 0);
    setFormData({ ...formData, [name]: date });
  };

  const stopActivity = () => {
    if (activity.status === 'in_progress') {
      dispatch(
        updateWorkshopInboundActivityAction({
          reference: activity.id.toString(),
          requestBody: { status: 'complete', updated_by: currentUser?.username },
        }),
      ).then(() => {
        setShowUpdateActivitySnackbar(true);
      });
    }
  };

  const handleFormSubmit = (shouldStopActivity?: boolean) => {
    if (formData.warranty_date) {
      formData.warranty_date = new Date(formData.warranty_date.toString())?.toISOString()?.split('T')[0];
    }
    const filteredFormData = Object.fromEntries(
      Object.entries(formData).filter(([, value]) => value !== undefined && value !== ''),
    );
    if (activity.activity_report) {
      dispatch(
        updateWorkshopInboundReportAction({
          reportId: activity.activity_report.id,
          ...formData,
        }),
      ).then((response) => {
        setShowUpdateSnackbar(true);
        if (response.payload) {
          if (shouldStopActivity) {
            stopActivity();
          }
          handleToggleModal();
          onSuccess?.();
        }
      });
    } else {
      dispatch(
        createWorkshopInboundReportAction({
          ...filteredFormData,
          is_authentic: formData.is_authentic,
          activity_id: activity.id,
        }),
      ).then((response) => {
        setShowCreateSnackbar(true);
        if (response.payload) {
          if (shouldStopActivity) {
            stopActivity();
          }
          handleToggleModal();
          onSuccess?.();
        }
      });
    }
  };

  const renderQuestion = (question: WorkshopReportQuestion) => {
    switch (question.type) {
      case 'boolean':
        return (
          <FormControlLabel
            control={
              <Switch
                checked={!!formData?.[question.id]}
                onChange={handleSwitchChange}
                inputProps={{ 'aria-label': 'controlled' }}
                name={question.id}
              />
            }
            label={<FormattedMessage {...question.label} />}
          />
        );
      case 'options':
        return reportFields[question.id] ? (
          <Autocomplete
            options={['', ...reportFields[question.id]]}
            value={formData?.[question.id]}
            onChange={(_, value) => {
              handleSelectChange(question.id, value?.toString());
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name={question.id}
                label={<FormattedMessage {...question.label} />}
                margin='dense'
                fullWidth
              />
            )}
          />
        ) : null;
      case 'number':
        return (
          <TextField
            type={question.type}
            inputProps={{ min: 0, max: 360 }}
            margin='dense'
            name={question.id}
            value={formData?.[question.id]}
            onChange={handleChange}
            label={<FormattedMessage {...question.label} />}
            fullWidth
          />
        );
      case 'date':
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
            <DatePicker
              sx={{
                marginTop: '8px',
                marginBottom: '4px',
              }}
              label={<FormattedMessage {...question.label} />}
              value={new Date(formData?.[question?.id]?.toString() || '')}
              timezone='UTC'
              onChange={(newDate) => handleDateChange(question.id, newDate)}
            />
          </LocalizationProvider>
        );
      default:
        return (
          <TextField
            type={question.type}
            inputProps={{ maxLength: question.maxLength || 500 }}
            margin='dense'
            name={question.id}
            value={formData?.[question.id]}
            onChange={handleChange}
            label={<FormattedMessage {...question.label} />}
            fullWidth
          />
        );
    }
  };

  useEffect(() => {
    if (show) {
      if (activity.activity_report) {
        setFormData(activity.activity_report);
      } else if (activity.activity_type === WorkshopActivities.endControl && activity.authenticate_activity_report) {
        setFormData(activity.authenticate_activity_report);
      } else {
        setFormData(initialFormData);
      }
    }
  }, [show, activity]);

  useEffect(() => {
    dispatch(getWorkshopInboundReportFieldsAction());
  }, []);

  return (
    <>
      {showCreateSnackbar && <ActionStatusSnackbar actionName={createWorkshopInboundReportAction.typePrefix} />}
      {showUpdateSnackbar && <ActionStatusSnackbar actionName={updateWorkshopInboundReportAction.typePrefix} />}
      {showUpdateActivitySnackbar && (
        <ActionStatusSnackbar actionName={updateWorkshopInboundActivityAction.typePrefix} />
      )}
      <Dialog open={show} onClose={handleToggleModal} maxWidth='xs' fullWidth>
        <DialogTitle>
          <FormattedMessage id='workshopInbound_report_title' defaultMessage='Workshop Inbound Report' />
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} mt={2}>
            {WORKSHOP_REPORT_QUESTIONS.map((question) => (
              <div key={question.id}>{renderQuestion(question)}</div>
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color='error' onClick={handleToggleModal}>
            <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
          </Button>
          <Button color='info' onClick={() => handleFormSubmit(false)}>
            <FormattedMessage id='common_save' defaultMessage='Save' />
          </Button>
          {[WorkshopActivities.authenticate].includes(activity.activity_type) && activity.status === 'in_progress' && (
            <Button color='warning' onClick={() => handleFormSubmit(true)}>
              <FormattedMessage
                id='workshopInbound_actions_saveReportStopActivity'
                defaultMessage='Save & Stop Activity'
              />
            </Button>
          )}
        </DialogActions>
        {createRequest.status === 'pending' && <LinearProgress color='primary' />}
      </Dialog>
    </>
  );
};

export default WorkshopInboundReportForm;
