const enMessages = {
  address_display_billing: 'Billing Address',
  address_display_pickup: 'Pickup Address',
  auth_unauthorized_message:
    'You are not authorized to view this page. If you think this is a mistake, please contact your administrator.',
  auth_unauthorized_title: 'Error',
  common_actions: 'Actions',
  common_add: 'Add',
  common_all: 'All',
  common_approve: 'Approve',
  common_average: 'Average',
  common_back: 'Back',
  common_cancel: 'Cancel',
  common_clear: 'Clear',
  common_close: 'Close',
  common_confirm: 'Confirm',
  common_copyToClipboard: 'Copy to clipboard',
  common_days: '{count, plural, one {# day} other {# days}}',
  common_decline: 'Decline',
  common_delete: 'Delete',
  common_deleteModal_title: 'Delete entry "{entry}"?',
  common_details: 'Details',
  common_disable: 'Disable',
  common_download: 'Download',
  common_edit: 'Edit',
  common_enable: 'Enable',
  common_filters: 'Filters',
  common_labels_accountHolder: 'Account Holder',
  common_labels_additionalStreetInfo: 'Address Line 2',
  common_labels_bankName: 'Bank Name',
  common_labels_bic: 'BIC',
  common_labels_boxAvailable: 'Box',
  common_labels_braceletType: 'Bracelet Type',
  common_labels_brand: 'Brand',
  common_labels_city: 'City',
  common_labels_comment: 'Comment',
  common_labels_company: 'Company',
  common_labels_count: 'Count',
  common_labels_country: 'Country',
  common_labels_createdAt: 'Created At',
  common_labels_createdBy: 'Created By',
  common_labels_differentBillingAddress: 'Different Billing Address',
  common_labels_elapsedTime: 'Elapsed Time',
  common_labels_endTime: 'End Time',
  common_labels_finalVariant: 'Final Variant',
  common_labels_firstName: 'First Name',
  common_labels_gender: 'Gender',
  common_labels_history: 'History',
  common_labels_iban: 'IBAN',
  common_labels_id: 'ID',
  common_labels_lastName: 'Last Name',
  common_labels_margin: 'Margin',
  common_labels_model: 'Model',
  common_labels_number: 'No.',
  common_labels_orderId: 'Order ID',
  common_labels_paperAvailable: 'Paper',
  common_labels_phoneNumber: 'Phone Number',
  common_labels_pickupDate: 'Handover Date',
  common_labels_postalCode: 'Postal Code',
  common_labels_price: 'Price',
  common_labels_reference: 'Reference',
  common_labels_salutation: 'Salutation',
  common_labels_serialNumber: 'Inventory ID',
  common_labels_sku: 'SKU',
  common_labels_startTime: 'Start Time',
  common_labels_state: 'State',
  common_labels_status: 'Status',
  common_labels_statusMessage: 'Status Message',
  common_labels_streetName: 'Street Name',
  common_labels_streetNumber: 'Street Number',
  common_labels_updatedAt: 'Updated At',
  common_labels_updatedBy: 'Updated By',
  common_labels_value: 'Value',
  common_labels_variantCode: 'Variant Code',
  common_labels_watchCondition: 'Condition',
  common_months: '{count, plural, one {# month} other {# months}}',
  common_next: 'Next',
  common_no: 'No',
  common_noImages: 'No images available',
  common_ok: 'Ok',
  common_optional: 'optional',
  common_refresh: 'Refresh',
  common_remove: 'Remove',
  common_required: 'required',
  common_resetFilters: 'Reset Filters & Sorting',
  common_results: 'Results',
  common_salutation_f: 'Ms',
  common_salutation_m: 'Mr',
  common_save: 'Save',
  common_search: 'Search',
  common_set: 'Set',
  common_shareFilters: 'Share Filters & Sorting',
  common_show: 'Show',
  common_submit: 'Submit',
  common_total: 'Total',
  common_warning_noUndo: 'This action cannot be undone.',
  common_weeks: '{count, plural, one {# week} other {# weeks}}',
  common_years: '{count, plural, one {# year} other {# years}}',
  common_yes: 'Yes',
  condition_a: 'Good',
  condition_aa: 'Very Good',
  condition_aaa: 'Like New',
  condition_good: 'Good',
  condition_like_new: 'Like New',
  condition_new: 'New',
  condition_used: 'Used',
  condition_vint: 'Vintage',
  dataGrid_noResultsOverlayLabel: 'No results found.',
  dataGrid_noRowsLabel: 'No Rows',
  forms_dateFilter_endDate: 'End Date',
  forms_dateFilter_startDate: 'Start Date',
  forms_fileUpload_label: 'Upload Files',
  forms_fileUpload_noFiles: 'No files selected',
  menu_inventory: 'Inventory',
  menu_priceCalculator: 'Price Calculator',
  menu_priceCalculatorConfig: 'Price Calculator Config',
  menu_processes: 'Processes',
  menu_productCategories: 'Categories',
  menu_productDiscounts: 'Discount Codes',
  menu_products: 'Products',
  menu_reports: 'Reports',
  menu_sell: 'Sell Requests',
  menu_tracking: 'Tracking',
  menu_workshop: 'Workshop',
  menu_workshopBooking: 'Book Item',
  menu_workshopInbound: 'Workshop Inbound',
  menu_workshopInboundActivities: 'Activities',
  menu_workshopInboundDashboard: 'Dashboard',
  menu_workshopInboundItems: 'Items',
  menu_workshopInboundRequests: 'Workshop Requests',
  menu_workshopLocations: 'Locations',
  menu_workshopPrices: 'Workshop Prices',
  menuCategories_operations: 'Operations',
  menuCategories_pickupLocations: 'Pickup Locations',
  menuCategories_sell: 'Sell Requests',
  menuDescriptions_priceCalculator: 'Price Calculator for Sales and Supply',
  menuDescriptions_processes: 'Log Workshop Processes from Watch Inbound to Final Control',
  menuDescriptions_sell: 'View, make offers and approve/decline sell requests',
  menuDescriptions_tracking: 'Shipment status for sales orders and purchase orders',
  menuDescriptions_workshopBooking: 'Book a workshop item',
  menuDescriptions_workshopLocations: 'List and edit workshop locations',
  menuDescriptions_workshopPrices: 'Manage workshop services and corresponding prices offered in the web shop',
  priceCalculator_disclaimer:
    'These prices are calculated based on {count, plural, one {# sample} other {# samples}} collected from {startDate} until {endDate}',
  priceCalculator_disclaimer_modelledData:
    'The price is based on a dataset with an average error of {mae}, meaning predictions are typically off by this amount, and {absoluteErrorPercentage}% of the predictions fall within a range of ±{absoluteErrorRange} from the actual values.',
  priceCalculator_formDescription:
    'To calculate the price for an item, the following data has to be provided: brand, dial color and at least one of model or reference',
  priceCalculator_formDescription_modelledData:
    'In case there are no results, please enter at least all three of brand, model and reference. We may calculate the potential price based on our model of the data.',
  priceCalculator_labels_marketPrice: 'Market Price',
  priceCalculator_priceGraph: 'Prices based on manufacturing year',
  priceCalculator_priceUpTo: 'up to',
  priceCalculatorConfig_createModal_description:
    'To add a new entry, please fill out the fields below. Please note that you can only select a model after you have selected a brand from the dropdown. If there is no entry for the selected brand yet, you have to create that first. The same rule applies for references.',
  priceCalculatorConfig_createModal_marginTierMarginValueError: 'Minimum value {minMarginValue}.',
  priceCalculatorConfig_createModal_marginTierMinValueError: 'First entry has to be 0.',
  priceCalculatorConfig_createModal_referenceExists: 'Reference already exists. Please choose another one.',
  priceCalculatorConfig_createModal_referenceInvalid:
    'Reference can only contain letters, numbers, and the following characters: -./',
  priceCalculatorConfig_createModal_title: 'New Entry',
  priceCalculatorConfig_minProductValue: 'Min. Product Value',
  priceCalculatorConfig_tabs_brands: 'Brands',
  priceCalculatorConfig_tabs_margins: 'Margins',
  priceCalculatorConfig_updateModal_title:
    '{fallback, select, true {Update Default entry} other {Update entry "{entry}"}}',
  pricingCalculator_actions_delete_warning:
    'Please note that an entry can only be deleted if it is not a fallback entry for any brand or model and that brand or model has other entries.',
  productCategories_actions_clear: 'Clear all SKUs from category "{category}"',
  productCategories_actions_create: 'Create new Category',
  productCategories_actions_delete: 'Permanently delete category "{category}"',
  productCategories_actions_modify: 'Modify SKUs in category "{category}"',
  productCategories_clearModal_deleteItemsText:
    '{count, plural, one {# product} other {# products}} will be cleared from this category.',
  productCategories_clearModal_emptyText: 'Action not available, this category is empty',
  productCategories_clearModal_title: 'Clear Category "{category}"?',
  productCategories_createModal_description: 'To add a new Category, please fill out the fields below.',
  productCategories_createModal_title: 'New Category',
  productCategories_deleteModal_notEmptyText: 'Action not available, only empty categories can be deleted.',
  productCategories_deleteModal_title: 'Delete Category "{category}"?',
  productCategories_detailsModal_productCount: 'Product Count: {count}',
  productCategories_labels_key: 'Key',
  productCategories_labels_name: 'Name',
  productCategories_modifyModal_detectedSkus: 'SKUs detected: {skuCount}',
  productCategories_modifyModal_explanation:
    'To add, remove or set SKUs in a category, either upload one or mutliple csv files containing the SKUs or enter them in the text area below, separated with a comma, IMPORTANT: if you change one input field, it overrides the previous input',
  productCategories_modifyModal_formFields_skus_hint: 'example: A10500_T0815,X10500_T0815',
  productCategories_modifyModal_formFields_skus_label: 'SKUs',
  productCategories_modifyModal_productCount: 'Current Product Count: {count}',
  productCategories_modifyModal_title: 'Modify Category Content for "{category}"',
  productDiscountBatchJobs_createModal_amountHint:
    'Define how many discount codes should be created. Must be at least 2.',
  productDiscountBatchJobs_createModal_codePatternHint:
    'Codes can only contain uppercase letters, numbers, _, - or #.{br}# will be used to determine the placement of the generated characters.',
  productDiscountBatchJobs_createModal_description:
    'To add a new Batch Job, please fill out the fields below. After creation, it will run in the background and you can check the status by refreshing the list of Batch Jobs.',
  productDiscountBatchJobs_createModal_title: 'New Batch Job',
  productDiscountBatchJobs_detailsModal_title: 'Details for Batch Job {id}',
  productDiscounts_actions_create: 'Create new Discount Code',
  productDiscounts_actions_createBatchJob: 'Create new Batch Job',
  productDiscounts_actions_delete: 'Permanently delete Discount Code',
  productDiscounts_actions_download_codes: 'Download Discount Codes',
  productDiscounts_actions_edit: 'Edit Discount Code',
  productDiscounts_createModal_codeHint:
    'Codes can only contain uppercase letters, numbers, _, -, # or *.{br}# will be replaced by numbers, * will be replaced by letters',
  productDiscounts_createModal_codePreview: 'Generated Code: {code}',
  productDiscounts_createModal_countriesHint:
    'Comma-separated list of country codes. Leave empty to make the code available in all countries.',
  productDiscounts_createModal_description: 'To add a new Discount Code, please fill out the fields below.',
  productDiscounts_createModal_detectedCountries: 'Countries detected: {countryCount}',
  productDiscounts_createModal_groupsHint:
    'Comma-separated list of groups. Example: add the ticket ID or the marketing campaign name as a group.',
  productDiscounts_createModal_maxApplicationsHint:
    'Limit how many times a discount code can be used (at least 1). Leave empty for no limit.',
  productDiscounts_createModal_presets: 'Presets',
  productDiscounts_createModal_title: 'New Discount Code',
  productDiscounts_createModal_validity: 'Validity period',
  productDiscounts_deleteModal_discountHasStarted:
    'Please make sure that the code has not yet been communicated to customers.',
  productDiscounts_deleteModal_title: 'Delete Discount Code "{code}"?',
  productDiscounts_detailsModal_allCountries: 'This discount code is valid for all countries.',
  productDiscounts_detailsModal_noCategories: 'This discount code has no categories.',
  productDiscounts_detailsModal_noGroups: 'This discount code has no groups.',
  productDiscounts_detailsModal_title: 'Details for Discount Code "{code}"',
  productDiscounts_detailsModal_unlimitedApplications: 'This discount code has no application limit.',
  productDiscounts_editModal_activeWarning:
    'The validity period for this discount code has already started. The values you can change are limited because of that.',
  productDiscounts_editModal_changedDataHint: 'Fields marked with this icon will be changed when you save the form',
  productDiscounts_editModal_maxApplicationsHint:
    'Limit how many times a discount code can be used (at least 1). If the discount code is already active, you can only increase this value.',
  productDiscounts_editModal_title: 'Update Discount Code "{discountCode}"',
  productDiscounts_filter_hideBatchDiscounts: 'Hide Batch Discounts',
  productDiscounts_labels_amount: 'Amount',
  productDiscounts_labels_categories: 'Categories',
  productDiscounts_labels_code: 'Code',
  productDiscounts_labels_codePattern: 'Code Pattern',
  productDiscounts_labels_countries: 'Countries',
  productDiscounts_labels_currencies: 'Currencies',
  productDiscounts_labels_discountPercentage: 'Discount Percentage',
  productDiscounts_labels_discountTiers: 'Discount Tiers',
  productDiscounts_labels_discountType: 'Type',
  productDiscounts_labels_discountValue: 'Discount Value',
  productDiscounts_labels_groups: 'Groups',
  productDiscounts_labels_isActive: 'Active',
  productDiscounts_labels_isRelative: 'relative values in %',
  productDiscounts_labels_maxApplications: 'Max. Applications',
  productDiscounts_labels_minCartValue: 'Min. cart value',
  productDiscounts_labels_validFrom: 'Valid From',
  productDiscounts_labels_validUntil: 'Valid Until',
  productDiscounts_searchPlaceholder: 'Search for code / groups',
  productDiscounts_tabs_batchJobs: 'Batch Jobs',
  productDiscounts_tabs_discounts: 'Discount Codes',
  productDiscounts_types_absolute: 'Absolute',
  productDiscounts_types_myDiscounts: 'My Discounts',
  productDiscounts_types_relative: 'Relative',
  sellRequests_actions_cancel: 'Cancel Sell Request',
  sellRequests_actions_createOffer: 'Create new Offer',
  sellRequests_actions_requestVendorInfo: 'Request Information',
  sellRequests_actions_setToPending: 'Set to Pending',
  sellRequests_actions_updateExpiration: 'Update Expiration',
  sellRequests_cancellationReasons_brandNotInteresting: 'Brand not interesting',
  sellRequests_cancellationReasons_duplicatedRequest: 'Duplicated Request',
  sellRequests_cancellationReasons_fakeWatch: 'Fake Watch',
  sellRequests_cancellationReasons_marketSituation: 'Market Situation',
  sellRequests_cancellationReasons_negotiationIssue: 'Negotiation Issue',
  sellRequests_cancellationReasons_noVendorResponse: 'No Response from Vendor',
  sellRequests_cancellationReasons_offerDeclined: 'Offer declined by Vendor',
  sellRequests_cancellationReasons_watchCondition: 'Watch Condition',
  sellRequests_cancelModal_title: 'Cancel Sell Request {reference}',
  sellRequests_details_cardTitles_item: 'Item',
  sellRequests_details_cardTitles_offer: 'Offer',
  sellRequests_details_cardTitles_priceEstimates: 'Price Estimates',
  sellRequests_details_cardTitles_vendor: 'Vendor',
  sellRequests_details_noOffer: 'Offer has not been created yet',
  sellRequests_details_title: 'Details for Sell Request {reference}',
  sellRequests_detailsModal_country: 'Country',
  sellRequests_detailsModal_email: 'E-mail Address',
  sellRequests_detailsModal_fullName: 'Full Name',
  sellRequests_detailsModal_language: 'Language',
  sellRequests_detailsModal_noImages: 'No images available for this sell request',
  sellRequests_detailsModal_phoneNumber: 'Phone Number',
  sellRequests_detailsModal_sellerInfo: 'Seller Information',
  sellRequests_editModal_title: 'Edit Sell Request {reference}',
  sellRequests_filter_approvedOffer: 'Approved Offer',
  sellRequests_filter_declinedOffer: 'Declined Offer',
  sellRequests_filter_none: '<em>None</em>',
  sellRequests_filter_noOffer: 'No Offer',
  sellRequests_filter_pendingOffer: 'Pending Offer',
  sellRequests_finalizeModal_confirmation: 'Are you sure? The process cannot be rolled back.',
  sellRequests_labels_acceptedAt: 'Accepted At',
  sellRequests_labels_approvedAt: 'Approved At',
  sellRequests_labels_approvedBy: 'Approved By',
  sellRequests_labels_cancellationReason: 'Cancellation Reason',
  sellRequests_labels_comments: 'Comment',
  sellRequests_labels_commissionPrice: 'Commission price',
  sellRequests_labels_commissionRate: 'Commission rate',
  sellRequests_labels_constructionYear: 'Year',
  sellRequests_labels_cxtSellingPrice: 'CHRONEXT sell price including VAT',
  sellRequests_labels_declinedAt: 'Declined At',
  sellRequests_labels_declinedBy: 'Declined By',
  sellRequests_labels_desiredMargin: 'Desired Margin',
  sellRequests_labels_directPrice: 'Net direct offer',
  sellRequests_labels_email: 'E-mail Address',
  sellRequests_labels_exchangePrice: 'Net partex offer',
  sellRequests_labels_expirationDate: 'Expiration Date',
  sellRequests_labels_expirationDaysLimit: 'Expiration in days',
  sellRequests_labels_fullName: 'Full Name',
  sellRequests_labels_images: 'Images',
  sellRequests_labels_language: 'Language',
  sellRequests_labels_missingDataFields: 'Missing Fields',
  sellRequests_labels_offeredBy: 'Offered By',
  sellRequests_labels_offerSellingMethods: 'Offered Selling Methods (+ chosen)',
  sellRequests_labels_priceEstimates: 'Price Estimates',
  sellRequests_labels_sellingMethods: 'Selling Methods',
  sellRequests_labels_status: 'Offer Status',
  sellRequests_labels_statusInfo: 'Status Information',
  sellRequests_labels_taxation: 'Taxation',
  sellRequests_labels_vendor: 'Vendor',
  sellRequests_labels_vendorEmail: 'Vendor Email',
  sellRequests_missingData: 'Missing Data',
  sellRequests_missingDataModal_requestButton: 'Request Information',
  sellRequests_missingDataModal_title: 'Request Missing Data for Sell Request {reference}',
  sellRequests_offerModal_description:
    'If details are changed, the offer status is reset. If you only need to change the expiration, please use the functionality on the detail page.',
  sellRequests_offerModal_lastOffers: 'Last {count, plural, one {offer} other {# offers}} for this SKU',
  sellRequests_offerModal_title: 'Offer Details for Sell Request {reference}',
  sellRequests_offerModal_usePrice: 'use this price',
  sellRequests_offerRejectionReasons_cannot_trust_chronext: 'I do not trust Chronext to handle the sale of my watch.',
  sellRequests_offerRejectionReasons_low_price: 'The price offer did not meet my expectation.',
  sellRequests_offerRejectionReasons_not_interested: 'I am not interested in selling, I only wanted to know the value.',
  sellRequests_offerRejectionReasons_not_the_right_option: 'I was not offered the right purchase option that I prefer.',
  sellRequests_offerRejectionReasons_other: 'Other',
  sellRequests_offerRejectionReasons_slow_response: 'The offer was made too late, I have already sold my watch.',
  sellRequests_offerReply_accepted: 'The customer has accepted this offer.',
  sellRequests_offerReply_counterOffer: 'Counter Offer',
  sellRequests_offerReply_declined: 'The customer has declined this offer.',
  sellRequests_requestVendorInformationModal_confirmation:
    'Please confirm that you want to request vendor information for this sell request. This will trigger an email to be sent to the vendor.',
  sellRequests_sellingMethods_commission: 'Commission',
  sellRequests_sellingMethods_direct: 'Direct',
  sellRequests_sellingMethods_exchange: 'Exchange',
  sellRequests_sellingMethods_tradeIn: 'Trade In',
  sellRequests_statusInfo_awaiting_erp_po: 'ERP - Awaiting PO creation',
  sellRequests_statusInfo_awaiting_offer_approval: 'Waiting for Offer Approval',
  sellRequests_statusInfo_awaiting_offer_creation: 'Waiting for Offer Creation',
  sellRequests_statusInfo_cancelled_by_supply_team: 'Cancelled by Supply team',
  sellRequests_statusInfo_cancelled_by_vendor: 'Cancelled by Vendor',
  sellRequests_statusInfo_closed: 'Closed',
  sellRequests_statusInfo_completed: 'Completed',
  sellRequests_statusInfo_counter_offer: 'Review Counter Offer',
  sellRequests_statusInfo_failed_erp_po_creation: 'ERP - PO creation failed',
  sellRequests_statusInfo_inbound_done: 'Inbound done',
  sellRequests_statusInfo_inbound_pending: 'Inbound pending',
  sellRequests_statusInfo_missing_info: 'Requested missing Watch Information',
  sellRequests_statusInfo_new_request: 'New Request',
  sellRequests_statusInfo_no_vendor_response: 'No Response from Vendor',
  sellRequests_statusInfo_offer_expired: 'Offer expired',
  sellRequests_statusInfo_offer_sent: 'Offer sent',
  sellRequests_statusInfo_payment_pending: 'Payment pending',
  sellRequests_statusInfo_po_waiting_for_label: 'ERP - PO waiting for shipping label',
  sellRequests_statusInfo_requested_vendor_info: 'Requested Vendor Information',
  sellRequests_statusInfo_resend_offer: 'Vendor re-opened expired offer',
  sellRequests_statusInfo_workshop_approval_pending: 'Workshop Approval pending',
  sellRequests_statusInfo_workshop_approval_received: 'Workshop Approval Received',
  sellRequests_statusInfo_workshop_in_progress: 'Workshop in progress',
  sellRequests_statusInfo_workshop_received: 'Received in Workshop',
  sellRequests_tabs_overview: 'Overview',
  sellRequests_taxationTypes_diff: 'diff',
  sellRequests_taxationTypes_full: 'full',
  sellRequests_updateExpirationModal_title: 'Update Expiration of the Offer for Sell Request {reference}',
  sellRequests_updateVendorInformationModal_title: 'Update Vendor Information for Sell Request {reference}',
  status_active: 'active',
  status_approved: 'approved',
  status_cancelled: 'cancelled',
  status_complete: 'complete',
  status_cxt_pending: 'CXT - Action Required',
  status_declined: 'declined',
  status_expired: 'expired',
  status_failed: 'failed',
  status_finalized: 'finalized',
  status_in_progress: 'in progress',
  status_ordered: 'ordered',
  status_paused: 'paused',
  status_pending: 'pending',
  status_received: 'received',
  status_requested: 'requested',
  status_sent: 'sent',
  status_waiting_for_customer: 'Waiting for Customer',
  successMessages_commercetools_clearCategory:
    'Successfully cleared category {categoryKey}, removed {count, plural, one {# product} other {# products}}',
  successMessages_commercetools_createBatchJob: 'Successfully created batch job for code pattern {code}',
  successMessages_commercetools_createCategory: 'Successfully created category {categoryName} with key {categoryKey}',
  successMessages_commercetools_createDiscountCode: 'Successfully created discount code {code}',
  successMessages_commercetools_deleteCategory: 'Successfully deleted category {categoryKey}',
  successMessages_commercetools_deleteDiscountCode: 'Successfully deleted discount code {code}',
  successMessages_commercetools_updateCategory: 'Successfully updated category {categoryKey}',
  successMessages_commercetools_updateCategory_add:
    'Successfully updated category {categoryKey}, added {skusAddedCount, plural, one {# product} other {# products}}',
  successMessages_commercetools_updateCategory_remove:
    'Successfully updated category {categoryKey}, removed {skusRemovedCount, plural, one {# product} other {# products}}',
  successMessages_commercetools_updateCategory_set:
    'Successfully updated category {categoryKey}, added {skusAddedCount, plural, one {# product} other {# products}} and removed {skusRemovedCount, plural, one {# product} other {# products}}',
  successMessages_copiedToClipboard: 'Successfully copied to clipboard',
  successMessages_default: 'Successfully executed action',
  successMessages_finalizeOffer: 'Successfully finalized offer {reference}',
  successMessages_pricing_createPricingConfigEntry: 'Successfully created pricing configuration entry {reference}',
  successMessages_pricing_deletePricingConfigEntry: 'Successfully deleted pricing configuration entry {reference}',
  successMessages_pricing_updatePricingConfigEntry: 'Successfully updated pricing configuration entry {reference}',
  successMessages_saveOfferDetails: 'Successfully saved offer {reference}',
  successMessages_sell_cancelSellRequest: 'Successfully cancelled sell request {reference}',
  successMessages_sell_requestMissingData: 'Successfully requested missing data for sell request {reference}',
  successMessages_sell_requestVendorInformation:
    'Successfully requested vendor information for sell request {reference}',
  successMessages_sell_updateSellRequest: 'Successfully updated sell request {reference}',
  successMessages_sell_updateSellRequestOffer: 'Successfully updated offer for sell request {reference}',
  successMessages_sell_updateVendorInformation: 'Successfully updated vendor information for sell request {reference}',
  successMessages_tracking_createTrackingComment: 'Successfully created comment',
  successMessages_tracking_updateTrackingComment: 'Successfully updated comment',
  successMessages_workshop_createLocation: 'Successfully created location {reference}',
  successMessages_workshop_createWorkshopProcess: 'Successfully created process {reference}',
  successMessages_workshop_updateWorkshopItem: 'Successfully updated location {reference}',
  successMessages_workshop_updateWorkshopProcess: 'Successfully updated process {reference}',
  successMessages_workshopInbound_createWorkshopInboundReport: 'Successfully created report',
  successMessages_workshopInbound_createWorkshopInboundServices: 'Successfully created services',
  successMessages_workshopInbound_sendWorkshopInboundServiceQuotation: 'Successfully sent quotation',
  successMessages_workshopInbound_updateExternalWorkshop: 'Successfully updated external workshop information',
  successMessages_workshopInbound_updateWorkshopInboundActivityStatus: 'Successfully updated activity status',
  successMessages_workshopInbound_updateWorkshopInboundReport: 'Successfully updated report',
  successMessages_workshopInbound_updateWorkshopInboundWatch: 'Successfully updated spare parts information',
  successMessages_workshopPrices_createWorkshopPrice: 'Successfully created entry {entry}',
  successMessages_workshopPrices_createWorkshopService: 'Successfully created service {name} with key {key}',
  successMessages_workshopPrices_deleteWorkshopPrice: 'Successfully deleted entry {entry}',
  successMessages_workshopPrices_deleteWorkshopService: 'Successfully deleted service {service}',
  successMessages_workshopPrices_updateWorkshopPrice: 'Successfully updated entry {entry}',
  successMessages_workshopPrices_updateWorkshopService: 'Successfully updated service {service}',
  tracking_comments_actions_create: 'Create new Comment',
  tracking_comments_createModal_title: 'New Comment',
  tracking_comments_updateModal_title: 'Update Comment',
  tracking_details_purchases_title: 'Details for Purchase Order {reference}',
  tracking_details_sales_title: 'Details for Sales Order {reference}',
  tracking_details_transfers_title: 'Details for Transfer Order {reference}',
  tracking_downloadReportsModal_title:
    'Download {type, select, sales {Sales Orders} purchases {Purchase Orders} transfer_orders {Internal Transfers} other {}} Tracking Report',
  tracking_labels_carrier: 'Carrier',
  tracking_labels_checkpointTime: 'Checkpoint Time',
  tracking_labels_comments: 'Comments ({count})',
  tracking_labels_destination: 'Destination',
  tracking_labels_destinationPostalCode: 'Dest. Postal Code',
  tracking_labels_expectedDeliveryDate: 'Expected Delivery Date',
  tracking_labels_invoiceId: 'Invoice ID',
  tracking_labels_origin: 'Origin',
  tracking_labels_paymentMethod: 'Payment Method',
  tracking_labels_purchaseOrderId: 'Purchase Order ID',
  tracking_labels_shipmentId: 'Shipment ID',
  tracking_labels_startDate: 'Start Date',
  tracking_labels_statusSubtag: 'Status Subtag',
  tracking_labels_trackingNumber: 'Tracking Number',
  tracking_labels_transferOrderId: 'Transfer Order ID',
  tracking_tabs_purchases: 'Purchase Orders',
  tracking_tabs_sales: 'Sales Orders',
  tracking_tabs_transfers: 'Internal Transfers',
  workshop_booking_bookItem: 'Book Item',
  workshop_bookingHistory_labels_endTime: 'End Time',
  workshop_bookingHistory_labels_inInventory: 'In Inventory',
  workshop_bookingHistory_labels_locationArea: 'Location Area',
  workshop_bookingHistory_labels_locationName: 'Location Name',
  workshop_bookingHistory_title: 'Booking History',
  workshop_inventory_actions_startActivity: 'Start activity "{activity}"',
  workshop_inventory_confirm_item_creation: 'Please confirm to create a workshop item for serial number {serialNumber}',
  workshop_inventory_createItem: 'Create New Workshop Item',
  workshop_inventory_labels_activities: 'Activities',
  workshop_inventory_labels_lastActivity: 'Last Activity',
  workshop_inventory_labels_location: 'Location',
  workshop_inventory_labels_ongoingProcesses: 'Ongoing Processes',
  workshop_inventory_labels_productReference: 'Product Reference',
  workshop_inventory_model: 'Model',
  workshop_inventory_updateItem: 'Update Item',
  workshop_labels_activity: 'Activity',
  workshop_labels_assignee: 'Assignee',
  workshop_labels_lastTimestamp: 'Last Timestamp',
  workshop_labels_quotationSent: 'Quotation sent',
  workshop_labels_service: 'Service',
  workshop_locations_add: 'Add new Location',
  workshop_locations_labels_inInventory: 'In Inventory',
  workshop_locations_labels_isActive: 'Is Active',
  workshop_locations_labels_locationArea: 'Area',
  workshop_locations_labels_locationCode: 'Location Code',
  workshop_locations_labels_locationName: 'Location Name',
  workshop_locations_update: 'Update Location',
  workshop_processes_createProcess: 'Create New Process',
  workshop_processes_labels_elapsedTime: 'Elapsed Time',
  workshop_processes_labels_elapsedTimeHint: 'Format: ss, mm:ss or hh:mm:ss',
  workshop_processes_title: 'Workshop Processes',
  workshop_processes_updateProcess: 'Update Process',
  workshop_report_labels_questions_actualReference: 'Actual Reference',
  workshop_report_labels_questions_amplitudeCrownDown: 'Amplitude Crown Down(°)',
  workshop_report_labels_questions_amplitudeDialUp: 'Amplitude Dial Up(°)',
  workshop_report_labels_questions_authenticity: 'Is the watch authentic?',
  workshop_report_labels_questions_bracelet: 'Bracelet',
  workshop_report_labels_questions_braceletLength: 'Bracelet Length(mm)',
  workshop_report_labels_questions_braceletMaterial: 'Bracelet Material',
  workshop_report_labels_questions_braceletSupply: 'Bracelet Supply (Rolex only)',
  workshop_report_labels_questions_caliber: 'Caliber',
  workshop_report_labels_questions_caseMaterial: 'Case Material',
  workshop_report_labels_questions_caseShape: 'Case Shape',
  workshop_report_labels_questions_caseSize: 'Case Size (mm)',
  workshop_report_labels_questions_comment: 'Comment',
  workshop_report_labels_questions_crystalMaterial: 'Crystal Material',
  workshop_report_labels_questions_currentCondition: 'Current Condition',
  workshop_report_labels_questions_dialColor: 'Dial Color',
  workshop_report_labels_questions_indexType: 'Index Type',
  workshop_report_labels_questions_internalService: 'Internal service',
  workshop_report_labels_questions_manufacturerSerialNumber: 'Manufacturer serial number',
  workshop_report_labels_questions_movementType: 'Movement Type',
  workshop_report_labels_questions_polishedCondition: 'Polished Condition',
  workshop_report_labels_questions_repairable: 'Repairable',
  workshop_report_labels_questions_serviceMandatory: 'Which service is required?',
  workshop_report_labels_questions_serviceNeeded: 'Service needed',
  workshop_report_labels_questions_sparePartsNeeded: 'Spare Parts needed',
  workshop_report_labels_questions_warrantyDate: 'Warranty Date',
  workshop_report_labels_questions_watchType: 'Watch type',
  workshop_reports_allActivity: 'All Activity Records',
  workshop_reports_areaOverview: 'Area Overview',
  workshop_reports_dailyActivity: 'Daily Activity Overview',
  workshop_reports_employeeActivity: 'Employee Activity Overview',
  workshop_reports_labels_employee: 'Employee',
  workshop_reports_labels_scannedItems: 'Distinct Scans',
  workshop_reports_labels_scans: 'Scans',
  workshop_reports_labels_showAllItems: 'Also show items not in inventory',
  workshop_reports_labels_uncompletedCount: 'Uncompleted Count',
  workshop_reworkReasons_amplitude_out_of_range: 'Amplitude out of range',
  workshop_reworkReasons_assembled_wrong: 'Assembled wrong',
  workshop_reworkReasons_date_setting_wrong: 'Date setting wrong',
  workshop_reworkReasons_dirty: 'Dirty',
  workshop_reworkReasons_hand_setting_wrong: 'Hand setting wrong',
  workshop_reworkReasons_not_running: 'Not running',
  workshop_reworkReasons_not_waterproof: 'Not waterproof',
  workshop_reworkReasons_outer_parts_defective: 'Outer parts defective',
  workshop_reworkReasons_scratched_after_assembling: 'Scratched after assembling',
  workshop_serviceActivityTypes_assembling: 'Assembling',
  workshop_serviceActivityTypes_authenticate: 'Authenticate',
  workshop_serviceActivityTypes_cleaning: 'Cleaning',
  workshop_serviceActivityTypes_dismantling: 'Dismantling',
  workshop_serviceActivityTypes_endControl: 'End Control',
  workshop_serviceActivityTypes_externalWorkshop: 'External Workshop',
  workshop_serviceActivityTypes_fullService: 'Full Service',
  workshop_serviceActivityTypes_maintenance: 'Maintenance',
  workshop_serviceActivityTypes_partialService: 'Partial Service',
  workshop_serviceActivityTypes_polishing: 'Polishing',
  workshop_serviceActivityTypes_rework: 'Rework',
  workshopInbound_actions_createReport: 'Create Report',
  workshopInbound_actions_createRework: 'Rework needed',
  workshopInbound_actions_createServices: 'Create Services',
  workshopInbound_actions_saveReportStopActivity: 'Save & Stop Activity',
  workshopInbound_actions_sendQuotation: 'Send Quotation',
  workshopInbound_actions_sendQuotation_confirmation:
    'Please confirm that you want to send the quotation to the ERP system',
  workshopInbound_actions_updateExternalWorkshop: 'Update External Workshop Information',
  workshopInbound_actions_updateSpareParts: 'Update Spare Parts Information',
  workshopInbound_details_cardTitles_activity: 'Details for activity {reference}',
  workshopInbound_details_cardTitles_item: 'Item',
  workshopInbound_labels_activities: 'Activities',
  workshopInbound_labels_assignee: 'Assignee',
  workshopInbound_labels_backlog: 'Backlog',
  workshopInbound_labels_id: 'Item Number',
  workshopInbound_labels_itemOverview_watches_completed: 'Completed Watches',
  workshopInbound_labels_itemOverview_watches_received: 'Received Watches',
  workshopInbound_labels_orderType: 'Order Type',
  workshopInbound_labels_receivedAt: 'Quotation received at',
  workshopInbound_labels_reworkReasons: 'Rework Reasons',
  workshopInbound_labels_services: 'Services',
  workshopInbound_labels_serviceStatus: 'Service Status',
  workshopInbound_labels_spareParts: 'Spare Parts',
  workshopInbound_labels_sparePartsStatus: 'Spare Parts Status',
  workshopInbound_labels_usedGoods: 'Used Goods',
  workshopInbound_labels_vendorId: 'Vendor ID',
  workshopInbound_labels_vendorName: 'Vendor Name',
  workshopInbound_maintenanceMode: 'Maintenance Mode',
  workshopInbound_report_tabs_activity: 'By Activity',
  workshopInbound_report_tabs_assignee: 'By Assignee',
  workshopInbound_report_tabs_item: 'Item Overview',
  workshopInbound_report_title: 'Report',
  workshopInbound_reworkModal_title: 'Create activity "Rework"',
  workshopInbound_services_createModal_title: 'Create Services for {item}',
  workshopInbound_services_updateModal_title: 'Update Service {reference}',
  workshopPrices_labels_active: 'Active',
  workshopPrices_labels_brandSlug: 'Brand Slug',
  workshopPrices_labels_inactiveServices: 'Inactive Services',
  workshopPrices_labels_key: 'Key',
  workshopPrices_labels_modelSlug: 'Model Slug',
  workshopPrices_labels_name: 'Name',
  workshopPrices_prices_actions_create: 'Create new Entry',
  workshopPrices_prices_createModal_description:
    'To add a new entry, please fill out the fields below. Please note that you can only select a model after you have selected a brand from the dropdown. If there is no entry for the selected brand yet, you have to create that first.',
  workshopPrices_prices_createModal_title: 'New Entry',
  workshopPrices_prices_individualOffer: 'Individual Offer',
  workshopPrices_prices_updateModal_description:
    'To update the prices for this entry, please adjust the fields below as needed.',
  workshopPrices_prices_updateModal_title:
    '{fallback, select, true {Update Default entry} other {Update entry "{entry}"}}',
  workshopPrices_services_actions_create: 'Create new Service',
  workshopPrices_services_createModal_description: 'To add a new service, please fill out the fields below.',
  workshopPrices_services_createModal_title: 'New Service',
  workshopPrices_services_deleteModal_title: 'Delete Service "{service}"',
  workshopPrices_services_toggleModal_disableWarningText: 'This service will no longer be shown in the web shop.',
  workshopPrices_services_toggleModal_title: '{active, select, true {Disable} other {Enable}} Service "{service}"?',
  workshopPrices_services_updateModal_description:
    'To update this service, please change values as needed below. Please be aware that the key is used in the webshop to identify the service.',
  workshopPrices_services_updateModal_title: 'Update Service "{service}"',
  workshopPrices_tabs_algoliaBrands: 'Algolia Brands',
  workshopPrices_tabs_brands: 'Brands',
  workshopPrices_tabs_priceList: 'Price List',
  workshopPrices_tabs_services: 'Services',
};

export default enMessages;
