import { ArrowOutward } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, Card, CardContent, CardHeader, IconButton, Link, Stack, Switch, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { GROUPS } from '../../../../constants/groups';
import { ClientRoute, createPath } from '../../../../routing';
import { isAuthorizedSelector } from '../../../../selectors/user';
import { useAppDispatch, useAppSelector } from '../../../../state';
import {
  getWorkshopInboundWatchAction,
  updateWorkshopInboundWatchAction,
} from '../../../../state/ducks/workshopInbound/actions';
import { WorkshopInboundWatch } from '../../../../state/ducks/workshopInbound/types';
import { formatUTCToLocal } from '../../../../utils/datetime';
import ConditionBadge from '../../../components/badges/ConditionBadge';
import StatusBadge from '../../../components/badges/StatusBadge';
import BooleanIcon from '../../../components/icons/BooleanIcon';
import Price from '../../../components/price/Price';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';
import UpdateExternalWorkshopModal from './UpdateExternalWorkshopModal';
import UpdateSparePartsInfoModal from './UpdateSparePartsInfoModal';

type Props = {
  item: WorkshopInboundWatch;
  actionButtons?: React.ReactNode[];
  userCanEdit?: boolean;
};
const WorkshopInboundItemCard: React.FC<Props> = ({ item, actionButtons, userCanEdit }) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [showSparePartsModal, setShowSparePartsModal] = useState(false);
  const [showExternalWorkshopModal, setShowExternalWorkshopModal] = useState(false);
  const isWorkshopAdmin = useAppSelector((state) => isAuthorizedSelector(state, [GROUPS.WORKSHOP_ADMIN]));
  const [showSnackbar, setShowSnackbar] = useState(false);

  const updatePriority = (priorityValue: boolean) => {
    dispatch(
      updateWorkshopInboundWatchAction({
        reference: item.id.toString(),
        requestBody: {
          priority: priorityValue,
        },
      }),
    ).then((response) => {
      setShowSnackbar(true);
      if (response?.payload && !response.payload?.errors) {
        dispatch(getWorkshopInboundWatchAction(item.id.toString()));
      }
    });
  };

  return (
    <>
      {showSnackbar && <ActionStatusSnackbar actionName={updateWorkshopInboundWatchAction.typePrefix} />}
      {showSparePartsModal && (
        <UpdateSparePartsInfoModal
          item={item}
          show={showSparePartsModal}
          handleToggleModal={() => setShowSparePartsModal(!showSparePartsModal)}
          onSuccess={() => dispatch(getWorkshopInboundWatchAction(item.id.toString()))}
        />
      )}
      {showExternalWorkshopModal && item.external_workshop && (
        <UpdateExternalWorkshopModal
          item={item.external_workshop}
          show={showExternalWorkshopModal}
          handleToggleModal={() => setShowExternalWorkshopModal(!showExternalWorkshopModal)}
          onSuccess={() => dispatch(getWorkshopInboundWatchAction(item.id.toString()))}
        />
      )}
      <Card>
        <CardHeader
          title={
            <Stack direction='row' justifyContent='space-between'>
              <FormattedMessage id='workshopInbound_details_cardTitles_item' defaultMessage='Item' />
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={1}>
                {actionButtons}
              </Stack>
            </Stack>
          }
        />
        <CardContent>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
            <Stack direction='column' spacing={1}>
              <Stack direction='row' spacing={2}>
                <Typography fontWeight='bold'>
                  <FormattedMessage id='common_labels_brand' defaultMessage='Brand' />:
                </Typography>
                <Typography>{item.brand}</Typography>
              </Stack>
              <Stack direction='row' spacing={2}>
                <Typography fontWeight='bold'>
                  <FormattedMessage id='common_labels_model' defaultMessage='Model' />:
                </Typography>
                <Typography>{item.model}</Typography>
              </Stack>
              <Stack direction='row' spacing={2}>
                <Typography fontWeight='bold'>
                  <FormattedMessage id='common_labels_reference' defaultMessage='Reference' />:
                </Typography>
                <Typography>{item.reference}</Typography>
              </Stack>
              <Stack direction='row' spacing={2}>
                <Typography fontWeight='bold'>
                  <FormattedMessage id='common_labels_variantCode' defaultMessage='Variant Code' />:
                </Typography>
                <Typography>{item.variant_code}</Typography>
              </Stack>
              <Stack direction='row' spacing={2}>
                <Typography fontWeight='bold'>
                  <FormattedMessage id='common_labels_serialNumber' defaultMessage='Inventory ID' />:
                </Typography>
                <Typography>{item.serial_number}</Typography>
              </Stack>
            </Stack>
            <Stack direction='column' spacing={1}>
              <Stack direction='row' spacing={2}>
                <Typography fontWeight='bold'>
                  <FormattedMessage id='common_labels_paperAvailable' defaultMessage='Paper' />:
                </Typography>
                <BooleanIcon check={item.certificate} />
              </Stack>
              <Stack direction='row' spacing={2}>
                <Typography fontWeight='bold'>
                  <FormattedMessage id='common_labels_boxAvailable' defaultMessage='Box' />:
                </Typography>
                <BooleanIcon check={item.box} />
              </Stack>
              <Stack direction='row' spacing={2}>
                <Typography fontWeight='bold'>
                  <FormattedMessage id='sellRequests_labels_constructionYear' defaultMessage='Year' />:
                </Typography>
                <Typography>{item.year}</Typography>
              </Stack>
              <Stack direction='row' spacing={2} alignItems='center'>
                <Typography fontWeight='bold'>
                  <FormattedMessage id='common_labels_watchCondition' defaultMessage='Condition' />:
                </Typography>
                <ConditionBadge condition={item.condition} />
              </Stack>
              <Stack direction='row' spacing={2}>
                <Typography fontWeight='bold'>
                  <FormattedMessage id='common_labels_finalVariant' defaultMessage='Final Variant' />:
                </Typography>
                <Typography>{item.final_variant}</Typography>
              </Stack>
            </Stack>
            <Stack direction='column' spacing={1}>
              {item?.workshop_request && (
                <>
                  <Stack direction='row' spacing={2}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='workshopInbound_labels_orderType' defaultMessage='Order Type' />:
                    </Typography>
                    <Typography>{item.workshop_request?.order_type}</Typography>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='common_labels_orderId' defaultMessage='Order ID' />:
                    </Typography>
                    <Stack justifyContent='center' direction='row' spacing={1}>
                      <div>{item.workshop_request?.order_number}</div>
                      {item.variant_code?.endsWith('-SW') && (
                        <Link
                          component={NavLink}
                          to={createPath({
                            path: ClientRoute.SELL_DETAILS,
                            params: { reference: item.workshop_request?.order_number },
                          })}>
                          <Stack justifyContent='center' direction='row' spacing={1}>
                            <FormattedMessage id='common_details' defaultMessage='Details' />
                            <ArrowOutward />
                          </Stack>
                        </Link>
                      )}
                    </Stack>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Typography fontWeight='bold'>
                      <FormattedMessage id='workshopInbound_labels_usedGoods' defaultMessage='Used Goods' />:
                    </Typography>
                    <BooleanIcon check={item.used_goods} />
                  </Stack>
                </>
              )}
              <Stack direction='row' spacing={2} alignItems='center'>
                <Typography fontWeight='bold'>
                  <FormattedMessage id='workshopInbound_labels_sparePartsStatus' defaultMessage='Spare Parts Status' />:
                </Typography>
                <StatusBadge status={item.spare_parts_status} showIcon size='small' />
                <IconButton
                  color='info'
                  disabled={!userCanEdit}
                  onClick={() => setShowSparePartsModal(true)}
                  title={intl.formatMessage({
                    id: 'workshopInbound_actions_updateSpareParts',
                    defaultMessage: 'Update Spare Parts Information',
                  })}>
                  <EditIcon />
                </IconButton>
              </Stack>
              <Stack direction='row' spacing={2}>
                <Typography fontWeight='bold'>
                  <FormattedMessage id='workshopInbound_labels_spareParts' defaultMessage='Spare Parts' />:
                </Typography>
                <Typography sx={{ whiteSpace: 'pre-line' }}>{item.spare_parts}</Typography>
              </Stack>
            </Stack>
            <Stack direction='column' spacing={1}>
              <Stack direction='row' spacing={2}>
                <Typography fontWeight='bold'>
                  <FormattedMessage id='common_labels_status' defaultMessage='Status' />:
                </Typography>
                <Typography>
                  {item?.status ? (
                    <FormattedMessage
                      id={`sellRequests_statusInfo_${item?.status}`}
                      defaultMessage={item?.status?.toString()}
                    />
                  ) : (
                    <RemoveIcon />
                  )}
                </Typography>
              </Stack>
              <Stack direction='row' spacing={2} alignItems='center'>
                <Typography fontWeight='bold'>
                  <FormattedMessage id='workshopInbound_labels_priority' defaultMessage='Priority' />:
                </Typography>
                {isWorkshopAdmin ? (
                  <Switch
                    checked={item.priority}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => updatePriority(event.target.checked)}
                    color='success'
                  />
                ) : (
                  <BooleanIcon check={item.priority} />
                )}
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
        {item.external_workshop && (
          <>
            <CardHeader
              title={
                <FormattedMessage
                  id='workshop_serviceActivityTypes_externalWorkshop'
                  defaultMessage='External Workshop'
                />
              }
              action={
                <Button color='info' startIcon={<EditIcon />} onClick={() => setShowExternalWorkshopModal(true)}>
                  <FormattedMessage id='common_edit' defaultMessage='Edit' />
                </Button>
              }
            />
            <CardContent>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
                <Stack direction='row' spacing={2} alignItems='center'>
                  <Typography fontWeight='bold'>
                    <FormattedMessage id='common_labels_price' defaultMessage='Price' />:
                  </Typography>
                  <Typography>
                    <Price value={item.external_workshop.amount} currency={item.external_workshop.currency} showZero />
                  </Typography>
                </Stack>
                <Stack direction='row' spacing={1} alignItems='center'>
                  <Typography fontWeight='bold'>
                    <FormattedMessage id='workshopInbound_labels_receivedAt' defaultMessage='Quotation received at' />:
                  </Typography>
                  <Typography>{formatUTCToLocal(item.external_workshop.received_date, 'DD.MM.YYYY')}</Typography>
                </Stack>
                <Stack direction='row' spacing={1} alignItems='center'>
                  <Typography fontWeight='bold'>
                    <FormattedMessage id='common_labels_updatedBy' defaultMessage='Updated by' />:
                  </Typography>
                  <Typography>{item.external_workshop.updated_by}</Typography>
                </Stack>
              </Stack>
            </CardContent>
          </>
        )}
      </Card>
    </>
  );
};

export default WorkshopInboundItemCard;
