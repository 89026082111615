import { Autocomplete, Box, Button, Drawer, MenuItem, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../state';
import ActionStatusSnackbar from '../snackbars/ActionStatusSnackbar';
import { WorkshopInboundWatchActivity } from '../../../state/ducks/workshopInbound/types';
import { currentUserSelector } from '../../../selectors/user';
import { updateWorkshopInboundActivityAction } from '../../../state/ducks/workshopInbound/actions';
import { NEXT_STATUS_MAPPING, WORKSHOP_EMPLOYEES } from '../../../constants/workshop';

type Props = {
  show: boolean;
  onClose: () => void;
  activity: WorkshopInboundWatchActivity;
  onSuccess: () => void;
};

const statusMapping: Record<string, string[]> = {
  ...NEXT_STATUS_MAPPING,
  complete: ['paused', 'pending'],
};

const initialFormData = {
  status: '',
  assignee: '',
};

const WorkshopInboundActivityUpdateForm: React.FC<Props> = ({ show, onClose, activity, onSuccess }) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const currentUser = useAppSelector(currentUserSelector);

  const [formData, setFormData] = useState(initialFormData);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const submitForm = () => {
    const { id } = activity;
    const requestBody: Record<string, string> = {
      assignee: formData.assignee,
    };
    if (activity.status !== formData.status) {
      requestBody['status'] = formData.status;
    }
    dispatch(
      updateWorkshopInboundActivityAction({
        reference: id.toString(),
        requestBody: {
          ...requestBody,
          updated_by: currentUser?.username,
        },
      }),
    ).then((response) => {
      setShowSnackbar(true);
      if (response?.payload) {
        onSuccess();
        onClose();
      }
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (name: string, value?: string) => {
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    setFormData({
      status: activity.status,
      assignee: activity.assignee,
    });
  }, [activity, show]);

  return (
    <>
      {showSnackbar && <ActionStatusSnackbar actionName={updateWorkshopInboundActivityAction.typePrefix} />}
      <Drawer anchor='right' open={show} onClose={onClose}>
        <Box sx={{ padding: '15px' }}>
          <Stack sx={{ minWidth: '300px', marginTop: '30px' }}>
            <TextField
              id='status'
              name='status'
              label={intl.formatMessage({ id: 'common_labels_status', defaultMessage: 'Status' })}
              value={formData.status}
              onChange={handleChange}
              size='small'
              margin='dense'
              select
              fullWidth>
              <MenuItem key={activity.status} value={activity.status}>
                <FormattedMessage id={`status_${activity.status}`} defaultMessage={activity.status} />
              </MenuItem>
              {statusMapping[activity.status].map((option, index) => (
                <MenuItem key={option} value={option}>
                  <FormattedMessage id={`status_${option}`} defaultMessage={option} />
                </MenuItem>
              ))}
            </TextField>
            <Autocomplete
              options={WORKSHOP_EMPLOYEES.map((option) => option.username)}
              value={formData.assignee}
              onChange={(_, value) => {
                handleSelectChange('assignee', value || '');
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='assignee'
                  label={intl.formatMessage({
                    id: 'workshop_labels_assignee',
                    defaultMessage: 'Assignee',
                  })}
                  margin='dense'
                  fullWidth
                />
              )}
            />
            <Button
              variant='contained'
              color='secondary'
              size='small'
              fullWidth={true}
              disableElevation={true}
              sx={{ marginTop: '50px' }}
              disabled={activity.assignee === formData.assignee && activity.status === formData.status}
              onClick={submitForm}>
              <FormattedMessage id='common_save' defaultMessage='Save' />
            </Button>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default WorkshopInboundActivityUpdateForm;
