import { Stack } from '@mui/material';
import moment, { Moment } from 'moment';
import React from 'react';
import { useAppDispatch } from '../../../../state';
import { getWorkshopProcessesAction } from '../../../../state/ducks/workshop/actions';
import DateFilter from '../../../components/datetime/DateFilter';
import WorkshopProcessesTable from '../../../components/workshop-tables/WorkshopProcessesTable';

const AllActivityReport: React.FC = () => {
  const dispatch = useAppDispatch();

  // dispatch action when the date filter is updated
  const onFilterUpdate = (start: Moment, end: Moment) => {
    const searchParams = new URLSearchParams({
      offset: '0',
      limit: '3000',
      start_time: start.toISOString(),
      end_time: end.toISOString(),
    });
    dispatch(getWorkshopProcessesAction(searchParams));
  };

  return (
    <Stack spacing={2} sx={{ position: 'relative', height: '100%' }}>
      <DateFilter
        startDate={moment().startOf('isoWeek')} // The first day of the current week
        onFilterUpdate={onFilterUpdate}
        daysRange={100}
      />
      <WorkshopProcessesTable editable />
    </Stack>
  );
};

export default AllActivityReport;
