import { Alert, AlertTitle, Stack } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { ClientRoute } from '../../../routing';
import { isAuthorizedSelector, userAuthStateSelector } from '../../../selectors/user';
import { useAppSelector } from '../../../state';

type Props = RouteProps & {
  groups?: string[];
};

const PrivateRoute: React.FC<Props> = (props) => {
  const userAuthState = useAppSelector(userAuthStateSelector);
  const isAuthorized = useAppSelector((state) => isAuthorizedSelector(state, props.groups));

  // Check if the user is authenticated
  if (!userAuthState.isAuthenticated) {
    return <Redirect to={ClientRoute.HOME} />;
  }
  // Checks the authorization
  if (!isAuthorized) {
    return (
      <Route
        {...props}
        component={() => (
          <Stack height='100%' justifyContent='center' alignItems='center'>
            <Alert severity='error' variant='outlined'>
              <AlertTitle>
                <FormattedMessage id='auth_unauthorized_title' defaultMessage='Error' />
              </AlertTitle>
              <FormattedMessage
                id='auth_unauthorized_message'
                defaultMessage='You are not authorized to view this page. If you think this is a mistake, please contact your administrator.'
              />
            </Alert>
          </Stack>
        )}
      />
    );
  }

  return <Route {...props} />;
};

export default PrivateRoute;
