import { RootState } from '../../state';

export const commercetoolsStateSelector = (state: RootState) => state.commercetools;

export const commercetoolsCategoriesSelector = (state: RootState) => ({
  categories: state.commercetools.categories,
  categoriesTotal: state.commercetools.categoriesTotal,
});

export const commercetoolsCategoryProductCountSelector = (state: RootState) => state.commercetools.categoryProductCount;

export const commercetoolsDiscountCodesSelector = (state: RootState) => ({
  discountCodes: state.commercetools.discountCodes,
  discountCodesTotal: state.commercetools.discountCodesTotal,
});

export const commercetoolsDiscountBatchJobsSelector = (state: RootState) => ({
  batchJobs: state.commercetools.discountBatchJobs,
  batchJobsTotal: state.commercetools.discountBatchJobsTotal,
});

export const commercetoolsDiscountBatchJobSelector = (state: RootState) => state.commercetools.discountBatchJob;
