import { Brightness4, Brightness7 } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';
import { currentUserSelector } from '../../../../selectors/user';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { setColorModeAction } from '../../../../state/ducks/user/actions';

const ColorModeSwitcher: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);

  return (
    <IconButton
      size='small'
      className='color-mode-switcher'
      color='inherit'
      onClick={() => dispatch(setColorModeAction(currentUser.colorMode === 'dark' ? 'light' : 'dark'))}>
      {currentUser.colorMode === 'dark' ? <Brightness4 /> : <Brightness7 />}
    </IconButton>
  );
};

export default ColorModeSwitcher;
