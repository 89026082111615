import {
  getGridBooleanOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridFilterModel,
  GridLogicOperator,
  GridRenderCellParams,
  GridRowParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { WORKSHOP_INBOUND_TABLE } from '../../../constants/localStorage';
import { SellRequestStatusInfoShowDetails } from '../../../constants/sell';
import { WorkshopServiceApprovalStatus, WorkshopSparePartsStatus } from '../../../constants/workshop';
import useSearchStoreHistory from '../../../hooks/useSearchStoreHistory';
import { ClientRoute, createPath } from '../../../routing';
import { workshopInboundWatchesSelector } from '../../../selectors/workshop-inbound/workshop-inbound.selector';
import { useAppSelector } from '../../../state';
import { getWorkshopInboundWatchesAction } from '../../../state/ducks/workshopInbound/actions';
import { formatUTCToLocal } from '../../../utils/datetime';
import StatusBadge from '../../components/badges/StatusBadge';
import BooleanIcon from '../../components/icons/BooleanIcon';
import ActionStatusSnackbar from '../../components/snackbars/ActionStatusSnackbar';
import ServerSidePaginatedTable, {
  ServerSidePaginatedTableColDef,
} from '../../components/table/ServerSidePaginatedTable';
import {
  defaultSearchParamsWIItems,
  sortFunction,
  WorkshopInboundTableToolbar,
} from './components/WorkshopInboundTableToolbar';
import styles from './WorkshopInbound.module.scss';

const tableColumns: ServerSidePaginatedTableColDef[] = [
  {
    id: 'workshopInbound_labels_id',
    headerName: 'Item Number',
    field: 'item_number',
    minWidth: 150,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
  },
  {
    id: 'common_labels_variantCode',
    headerName: 'Variant Code',
    field: 'variant_code',
    minWidth: 150,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
  },
  {
    id: 'common_labels_finalVariant',
    headerName: 'Final Variant',
    field: 'final_variant',
    minWidth: 150,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
  },
  {
    id: 'common_labels_brand',
    headerName: 'Brand',
    field: 'brand',
    minWidth: 150,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
  },
  {
    id: 'common_labels_model',
    headerName: 'Model',
    field: 'model',
    minWidth: 150,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
  },
  {
    id: 'common_labels_reference',
    headerName: 'Reference',
    field: 'reference',
    minWidth: 150,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
  },
  {
    id: 'common_labels_serialNumber',
    headerName: 'Inventory ID',
    field: 'serial_number',
    minWidth: 150,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
  },
  {
    id: 'workshopInbound_labels_priority',
    headerName: 'Priority',
    field: 'priority',
    minWidth: 150,
    filterOperators: getGridBooleanOperators(),
    type: 'boolean',
    renderCell: ({ value }: GridRenderCellParams) => <BooleanIcon check={value} />,
  },
  {
    id: 'common_labels_status',
    headerName: 'Status',
    field: 'status',
    minWidth: 200,
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'is'),
    type: 'singleSelect',
    valueOptions: Object.keys(SellRequestStatusInfoShowDetails),
    renderCell: (params: GridRenderCellParams) =>
      params.value && <FormattedMessage id={`sellRequests_statusInfo_${params.value}`} defaultMessage={params.value} />,
  },
  {
    id: 'workshopInbound_labels_serviceStatus',
    headerName: 'Service Status',
    field: 'service_approved',
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'is'),
    type: 'singleSelect',
    valueOptions: Object.keys(WorkshopServiceApprovalStatus),
    minWidth: 150,
    renderCell: ({ value }: GridRenderCellParams) => <StatusBadge status={value} />,
  },
  {
    id: 'workshopInbound_labels_sparePartsStatus',
    headerName: 'Spare Parts Status',
    field: 'spare_parts_status',
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'is'),
    type: 'singleSelect',
    valueOptions: Object.keys(WorkshopSparePartsStatus),
    minWidth: 150,
    renderCell: ({ value }: GridRenderCellParams) => <StatusBadge status={value} showIcon size='small' />,
  },
  {
    id: 'workshopInbound_labels_usedGoods',
    headerName: 'Used Goods',
    field: 'used_goods',
    minWidth: 150,
    filterOperators: getGridBooleanOperators(),
    type: 'boolean',
    renderCell: ({ value }: GridRenderCellParams) => <BooleanIcon check={value} />,
  },
  {
    id: 'common_labels_createdAt',
    headerName: 'Created At',
    field: 'created_at',
    minWidth: 150,
    filterable: false,
    valueGetter: ({ value }: GridValueGetterParams) => value && formatUTCToLocal(value),
  },
  {
    id: 'workshopInbound_labels_backlog',
    headerName: 'Backlog',
    field: 'backlog',
    minWidth: 150,
    filterOperators: getGridBooleanOperators(),
    type: 'boolean',
    renderCell: ({ value }: GridRenderCellParams) => <BooleanIcon check={value} />,
  },
];

type Props = {
  tabFilter?: Record<string, any>;
};
const WorkshopInboundItems: React.FC<Props> = ({ tabFilter }) => {
  const workshopWatches = useAppSelector(workshopInboundWatchesSelector);
  const intl = useIntl();
  const history = useHistory();
  const apiRef = useGridApiRef();

  const defaultSearchParamsWIItemsStatus = {
    ...defaultSearchParamsWIItems,
    ...tabFilter,
  };

  const {
    searchParams,
    updateSearchFilters,
    updateSort,
    resetSearch,
    initialFilters,
    initialSorting,
    hasSavedSearch,
    shareSearch,
  } = useSearchStoreHistory({
    storeName: `${WORKSHOP_INBOUND_TABLE}_items`,
    defaultSearchParams: defaultSearchParamsWIItemsStatus,
    sortFunction,
    apiRef,
  });

  const [columns, setColumns] = useState<ServerSidePaginatedTableColDef[]>([...tableColumns]);

  const handleFilterModelChange = debounce((filterModel: GridFilterModel) => {
    updateSearchFilters(filterModel);
  }, 150);

  useEffect(() => {
    const newCols = tableColumns
      .map((col) => {
        if (['service_approved', 'spare_parts_status', 'status'].includes(col.field)) {
          return {
            ...col,
            getOptionLabel: (option: string) =>
              option &&
              intl.formatMessage({
                id: col.field === 'status' ? `sellRequests_statusInfo_${option}` : `status_${option}`,
                defaultMessage: option,
              }),
          };
        }
        return col;
      })
      .filter((col) => {
        if (tabFilter?.service_approved === 'approved' || tabFilter?.service_approved === 'declined') {
          return col.field !== 'service_approved';
        }
        return col;
      });
    setColumns(newCols);
  }, []);

  return (
    <div className={styles.wrapper}>
      <ActionStatusSnackbar actionName={getWorkshopInboundWatchesAction.typePrefix} />
      <ServerSidePaginatedTable
        apiRef={apiRef}
        rows={workshopWatches.items}
        columns={columns}
        totalCount={workshopWatches.count}
        fetchDataEffect={getWorkshopInboundWatchesAction}
        searchParams={searchParams}
        onRowClick={(params: GridRowParams) => {
          const path = createPath({
            path: ClientRoute.WORKSHOP_INBOUND_ITEM,
            params: { reference: params.row.id },
          });
          history.push(path);
        }}
        slots={{
          toolbar: WorkshopInboundTableToolbar,
        }}
        slotProps={{
          toolbar: {
            showFilterButtons: hasSavedSearch,
            handleResetClick: resetSearch,
            handleShareClick: shareSearch,
          },
          filterPanel: {
            logicOperators: [GridLogicOperator.And],
          },
        }}
        sortingMode='server'
        onSortModelChange={updateSort}
        filterMode='server'
        onFilterModelChange={handleFilterModelChange}
        initialState={{
          filter: {
            filterModel: {
              items: initialFilters,
            },
          },
          sorting: {
            sortModel: initialSorting,
          },
        }}
      />
    </div>
  );
};

export default WorkshopInboundItems;
