import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TextField,
} from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { commercetoolsCategoryProductCountSelector } from '../../../../selectors/commercetools';
import { requestSelector } from '../../../../selectors/request';
import { useAppDispatch, useAppSelector } from '../../../../state';
import {
  getCategoryProductCountAction,
  updateCategorySkuListAction,
} from '../../../../state/ducks/commercetools/actions';
import FileUploadArea from '../../../components/forms/FileUploadArea';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  categoryKey: string;
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
};

const initialFormData = {
  skus: '',
  skuFiles: undefined,
};

const SkuListModal: React.FC<Props> = ({ categoryKey, handleToggleModal, show, onSuccess }) => {
  const dispatch = useAppDispatch();
  const categoryProductCount = useAppSelector(commercetoolsCategoryProductCountSelector);
  const updateRequest = useAppSelector((state) => requestSelector(state, updateCategorySkuListAction.typePrefix));
  const [formData, setFormData] = useState(initialFormData);
  const [skus, setSkus] = useState<string[]>([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [actionType, setActionType] = useState('');

  useEffect(() => {
    dispatch(getCategoryProductCountAction(categoryKey));
  }, []);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (['skuFiles'].includes(event.target.name)) {
      setFormData({ ...initialFormData, [event.target.name]: event.target.files });
      const skuList: string[] = [];
      const files = event.target.files || [];
      if (event?.target?.files) {
        for (let i = 0; i < files.length; i++) {
          const result = await new Promise<string[]>((resolve) => {
            const csvType = 'text/csv';
            if (files[i].type.match(csvType)) {
              const reader = new FileReader();
              reader.onload = (res) => {
                if (typeof res?.target?.result === 'string') {
                  let skusInFile = res?.target?.result?.match(/([A-Z0-9]+_[A-Z0-9_]+)/g) || [];
                  skusInFile = skusInFile?.map((sku) => sku.toString());
                  resolve(skusInFile);
                }
              };
              reader.onerror = (err) => {
                console.error(err);
                resolve([]);
              };
              reader.readAsText(files[i]);
            }
          });
          skuList.push(...result);
        }
      }
      setSkus(skuList);
      setIsFormValid(skuList.length > 0);
    } else {
      setFormData({ ...initialFormData, [event.target.name]: event.target.value });
      let skuList: string[] = [];
      if (event.target.value) {
        skuList = event.target.value.split(',');
        skuList = skuList.filter((sku) => /^([A-Z0-9]+_[A-Z0-9_]+)$/.test(sku));
      }
      setSkus(skuList);
      setIsFormValid(skuList.length > 0);
    }
  };

  const handleSubmit = (actionType: 'add' | 'remove' | 'set') => {
    setActionType(actionType);
    dispatch(updateCategorySkuListAction({ key: categoryKey, actionType, skus })).then((response) => {
      setShowSnackbar(true);
      if (response?.payload) {
        handleToggleModal();
        onSuccess();
      }
    });
  };

  return (
    <>
      {showSnackbar && (
        <ActionStatusSnackbar
          actionName={updateCategorySkuListAction.typePrefix}
          config={{ messageValues: { categoryKey }, actionType }}
        />
      )}
      <Dialog open={show} onClose={() => updateRequest?.status !== 'pending' && handleToggleModal()}>
        <DialogTitle>
          <FormattedMessage
            id='productCategories_modifyModal_title'
            defaultMessage='Modify Category Content for "{category}"'
            values={{ category: categoryKey }}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText color='primary' sx={{ fontWeight: 'medium', marginBottom: '10px' }}>
            <FormattedMessage
              id='productCategories_modifyModal_productCount'
              defaultMessage='Current Product Count: {count}'
              values={{ count: categoryProductCount }}
            />
          </DialogContentText>
          <DialogContentText sx={{ marginBottom: '20px' }}>
            <FormattedMessage
              id='productCategories_modifyModal_explanation'
              defaultMessage='To add, remove or set SKUs in a category, either upload a csv file containing the SKUs or enter them in the text area below, separated with a comma, IMPORTANT: if you change one input field, it overrides the previous input'
            />
          </DialogContentText>
          <FileUploadArea
            id='skuFiles'
            name='skuFiles'
            accept='text/csv'
            files={formData.skuFiles}
            handleChange={handleChange}
            multiple
          />
          <TextField
            margin='dense'
            id='skus'
            name='skus'
            value={formData.skus}
            onChange={handleChange}
            label={<FormattedMessage id='productCategories_modifyModal_formFields_skus_label' defaultMessage='SKUs' />}
            helperText={
              <FormattedMessage
                id='productCategories_modifyModal_formFields_skus_hint'
                defaultMessage='example: A10500_T0815,X10500_T0815'
              />
            }
            fullWidth
            multiline
            minRows={3}
          />
          <DialogContentText color='primary'>
            <FormattedMessage
              id='productCategories_modifyModal_detectedSkus'
              defaultMessage='SKUs detected: {skuCount}'
              values={{ skuCount: skus.length }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleModal} disabled={updateRequest?.status === 'pending'}>
            <FormattedMessage id='common_cancel' defaultMessage='Cancel' />
          </Button>
          <Button
            onClick={() => {
              handleSubmit('remove');
            }}
            disabled={!isFormValid || categoryProductCount === 0 || updateRequest?.status === 'pending'}
            color='error'>
            <FormattedMessage id='common_remove' defaultMessage='Remove' />
          </Button>
          <Button
            onClick={() => {
              handleSubmit('set');
            }}
            disabled={!isFormValid || updateRequest?.status === 'pending'}
            color='info'>
            <FormattedMessage id='common_set' defaultMessage='Set' />
          </Button>
          <Button
            onClick={() => {
              handleSubmit('add');
            }}
            disabled={!isFormValid || updateRequest?.status === 'pending'}
            color='success'>
            <FormattedMessage id='common_add' defaultMessage='Add' />
          </Button>
        </DialogActions>
        {updateRequest?.status === 'pending' && <LinearProgress color='primary' />}
      </Dialog>
    </>
  );
};

export default SkuListModal;
