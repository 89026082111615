import { Clear, LocalAtm, NorthWest, Percent, WatchLaterOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fade,
  IconButton,
  InputAdornment,
  LinearProgress,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { FormattedMessage, useIntl } from 'react-intl';
import { requestSelector } from '../../../../selectors/request';
import { sellRequestSelector } from '../../../../selectors/sell';
import { offersSelector } from '../../../../selectors/sell/sell.selector';
import { currentUserSelector } from '../../../../selectors/user';
import { useAppDispatch, useAppSelector } from '../../../../state';
import {
  getExistingOffersAction,
  getSellRequestAction,
  updateSellRequestOfferAction,
} from '../../../../state/ducks/sell/actions';
import { Offer } from '../../../../state/ducks/sell/types';
import { getCountryNameFromAlpha2 } from '../../../../utils/countryUtils';
import { formatUTCToLocal } from '../../../../utils/datetime';
import { calculateMarginPercentage, calculatePriceFromMargin, getPriceLocaleString } from '../../../../utils/textUtils';
import ConditionBadge from '../../../components/badges/ConditionBadge';
import StatusBadge from '../../../components/badges/StatusBadge';
import BooleanIcon from '../../../components/icons/BooleanIcon';
import CurrencyIcon from '../../../components/icons/CurrencyIcon';
import Price from '../../../components/price/Price';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';
import SellingMethods from './SellingMethods';

type Props = {
  sellRequestReference: string;
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
};

type FormData = Pick<
  Offer,
  'cxtSellingPrice' | 'commissionRate' | 'directPrice' | 'exchangePrice' | 'taxation' | 'expirationDaysLimit'
> & {
  sellingMethods: string[];
  desiredMargin: number;
};

const initialFormData: FormData = {
  cxtSellingPrice: 0,
  commissionRate: 0,
  directPrice: 0,
  exchangePrice: 0,
  taxation: 'diff',
  expirationDaysLimit: 14,
  sellingMethods: [],
  desiredMargin: 0,
};

const commonPriceInputProps = {
  min: 0,
  step: 10,
};

const commonNumberInputProps = {
  min: 0,
  step: 1,
};

const OfferModal: React.FC<Props> = ({ show, handleToggleModal, sellRequestReference, onSuccess }) => {
  const dispatch = useAppDispatch();
  const { language } = useAppSelector(currentUserSelector);
  const intl = useIntl();

  const sellRequest = useAppSelector(sellRequestSelector);
  const saveOfferDetailsRequest = useAppSelector((state) =>
    requestSelector(state, updateSellRequestOfferAction.typePrefix),
  );
  const getSellRequestByRefRequest = useAppSelector((state) => requestSelector(state, getSellRequestAction.typePrefix));

  const offers = useAppSelector(offersSelector);
  const getOffersRequest = useAppSelector((state) => requestSelector(state, getExistingOffersAction.typePrefix));

  const [formData, setFormData] = useState(initialFormData);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showSaveSnackbar, setShowSaveSnackbar] = useState(false);

  const countryName = getCountryNameFromAlpha2(sellRequest?.seller?.country || '', language);
  const commonInputProps = {
    startAdornment: (
      <InputAdornment position='start'>
        <CurrencyIcon currency={sellRequest?.currencyCode || ''} color='inherit' />
      </InputAdornment>
    ),
  };

  const clearForm = () => {
    setFormData(initialFormData);
  };

  useEffect(() => {
    dispatch(getSellRequestAction(sellRequestReference)).then((response) => {
      if (response.payload) {
        const { sku } = response.payload;
        dispatch(getExistingOffersAction(sku));
      }
    });
  }, []);

  useEffect(() => {
    if (sellRequest?.offer) {
      const { offer } = sellRequest;
      const sellingMethods = [];
      if (offer.directPrice > 0) sellingMethods.push('direct');
      if (offer.commissionRate > 0) sellingMethods.push('commission');
      if (offer.exchangePrice > 0) sellingMethods.push('exchange');

      setFormData({
        cxtSellingPrice: offer.cxtSellingPrice / 100,
        commissionRate: offer.commissionRate,
        directPrice: offer.directPrice / 100,
        exchangePrice: offer.exchangePrice / 100,
        taxation: offer.taxation,
        expirationDaysLimit: offer.expirationDaysLimit,
        sellingMethods: sellingMethods,
        desiredMargin: initialFormData.desiredMargin,
      });
    } else {
      clearForm();
    }
  }, [sellRequest]);

  useEffect(() => {
    const { sellingMethods, cxtSellingPrice, commissionRate, directPrice, exchangePrice, expirationDaysLimit } =
      formData;

    const initialSellingMethods = [];
    if (sellRequest?.offer) {
      if (sellRequest.offer?.directPrice > 0) initialSellingMethods.push('direct');
      if (sellRequest.offer?.commissionRate > 0) initialSellingMethods.push('commission');
      if (sellRequest.offer?.exchangePrice > 0) initialSellingMethods.push('exchange');
    }
    const allFieldsUnchanged =
      sellRequest?.offer?.cxtSellingPrice === cxtSellingPrice * 100 &&
      sellRequest?.offer?.exchangePrice === exchangePrice * 100 &&
      sellRequest?.offer?.directPrice === directPrice * 100 &&
      sellRequest?.offer?.commissionRate === commissionRate &&
      sellRequest?.offer?.expirationDaysLimit === expirationDaysLimit &&
      sellRequest?.offer?.taxation === formData.taxation &&
      initialSellingMethods.sort().join(',') === sellingMethods.sort().join(',');

    if (
      (sellRequest?.offer && allFieldsUnchanged) ||
      cxtSellingPrice <= 0 ||
      sellingMethods.length === 0 ||
      expirationDaysLimit <= 0 ||
      (sellingMethods.includes('direct') && directPrice <= 0) ||
      (sellingMethods.includes('commission') && commissionRate <= 0) ||
      (sellingMethods.includes('exchange') && exchangePrice <= 0)
    ) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  }, [formData]);

  if (!sellRequest) {
    return null;
  }

  const handleSellingMethodsChange = (event: React.MouseEvent<HTMLElement>, newMethods: string[]) => {
    setFormData({ ...formData, sellingMethods: newMethods });
  };

  const handleDesiredMarginChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const margin = Number(event.target.value);
    setFormData({
      ...formData,
      desiredMargin: margin,
      directPrice: calculatePriceFromMargin(formData.cxtSellingPrice, margin),
      exchangePrice: calculatePriceFromMargin(formData.cxtSellingPrice, margin),
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const saveOffer = () => {
    if (!sellRequest) {
      return;
    }

    const { sellingMethods } = formData;
    const params = {
      reference: sellRequest.reference.toString(),
      cxtSellingPrice: Number(formData.cxtSellingPrice) * 100,
      commissionRate: sellingMethods.includes('commission') ? Number(formData.commissionRate) : 0,
      directPrice: sellingMethods.includes('direct') ? Number(formData.directPrice) * 100 : 0,
      exchangePrice: sellingMethods.includes('exchange') ? Number(formData.exchangePrice) * 100 : 0,
      expirationDaysLimit: Number(formData.expirationDaysLimit),
      taxation: formData.taxation,
    };
    // send a request to save the details
    dispatch(updateSellRequestOfferAction(params)).then((response) => {
      setShowSaveSnackbar(true);
      if (response.payload) {
        clearForm();
        handleToggleModal();
        onSuccess();
      }
    });
  };

  const getPriceRangeString = (priceFrom: number, priceTo: number): string => {
    return `${getPriceLocaleString(
      priceFrom,
      language,
      sellRequest?.seller?.country,
      sellRequest?.currencyCode,
    )} \u2013 ${getPriceLocaleString(priceTo, language, sellRequest?.seller.country, sellRequest?.currencyCode)}`;
  };

  return (
    <>
      {showSaveSnackbar && <ActionStatusSnackbar actionName={updateSellRequestOfferAction.typePrefix} />}
      <Dialog open={show} onClose={handleToggleModal} maxWidth='md' fullWidth>
        <Box sx={{ height: '5px' }}>
          <Fade
            in={[saveOfferDetailsRequest?.status, getSellRequestByRefRequest?.status, getOffersRequest.status].includes(
              'pending',
            )}
            timeout={{ appear: 0, exit: 1500 }}>
            {<LinearProgress color='success' />}
          </Fade>
        </Box>
        <DialogTitle>
          <Stack direction='row' justifyContent='space-between'>
            <FormattedMessage
              id='sellRequests_offerModal_title'
              defaultMessage='Offer Details for Sell Request {reference}'
              values={{ reference: sellRequest.sellRequestId || sellRequestReference }}
            />
            <StatusBadge status={sellRequest?.offer?.status} />
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText mb={2}>
            <FormattedMessage
              id='sellRequests_offerModal_description'
              defaultMessage='If details are changed, the offer status is reset. If you only need to change the expiration, please use the functionality on the detail page.'
            />
          </DialogContentText>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} justifyContent='space-between'>
            <Stack spacing={2} width={{ xs: 1, md: 0.3 }}>
              <TextField
                type='number'
                margin='dense'
                name='cxtSellingPrice'
                value={formData.cxtSellingPrice}
                inputProps={commonPriceInputProps}
                InputProps={commonInputProps}
                onChange={handleChange}
                label={
                  <FormattedMessage
                    id='sellRequests_labels_cxtSellingPrice'
                    defaultMessage='CHRONEXT sell price including VAT'
                  />
                }
                fullWidth
              />
              <Stack
                direction={{ xs: 'column', md: 'row' }}
                spacing={2}
                alignItems={{ xs: 'flex-start', md: 'center' }}>
                <Stack>
                  <Typography variant='body2'>
                    <FormattedMessage id='sellRequests_labels_sellingMethods' defaultMessage='Selling Methods' />:
                  </Typography>
                  <ToggleButtonGroup
                    color='primary'
                    id='sellingMethods'
                    value={formData.sellingMethods}
                    disabled={formData.cxtSellingPrice <= 0}
                    onChange={handleSellingMethodsChange}>
                    <ToggleButton value='direct'>
                      <Tooltip
                        title={<FormattedMessage id='sellRequests_sellingMethods_direct' defaultMessage='Direct' />}
                        arrow>
                        <LocalAtm />
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton value='commission'>
                      <Tooltip
                        title={
                          <FormattedMessage id='sellRequests_sellingMethods_commission' defaultMessage='Commission' />
                        }
                        arrow>
                        <Percent />
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton value='exchange'>
                      <Tooltip
                        title={<FormattedMessage id='sellRequests_sellingMethods_exchange' defaultMessage='Exchange' />}
                        arrow>
                        <WatchLaterOutlined />
                      </Tooltip>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
                <TextField
                  type='number'
                  margin='dense'
                  name='desiredMargin'
                  value={formData.desiredMargin}
                  disabled={formData.cxtSellingPrice <= 0}
                  inputProps={commonNumberInputProps}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Percent />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleDesiredMarginChange}
                  label={<FormattedMessage id='sellRequests_labels_desiredMargin' defaultMessage='Desired Margin' />}
                  fullWidth
                />
              </Stack>
              {formData.sellingMethods.includes('direct') && (
                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  spacing={2}
                  alignItems={{ xs: 'flex-start', md: 'center' }}>
                  <TextField
                    type='number'
                    margin='dense'
                    name='directPrice'
                    value={formData.directPrice}
                    disabled={formData.cxtSellingPrice <= 0}
                    inputProps={commonPriceInputProps}
                    InputProps={commonInputProps}
                    onChange={handleChange}
                    label={<FormattedMessage id='sellRequests_labels_directPrice' defaultMessage='Net direct offer' />}
                    fullWidth
                  />
                  {formData.cxtSellingPrice > 0 && (
                    <Stack width={0.2}>
                      <Typography variant='body2'>
                        <FormattedMessage id='common_labels_margin' defaultMessage='Margin' />:
                      </Typography>
                      <Typography>
                        {calculateMarginPercentage(formData?.cxtSellingPrice, formData.directPrice).toLocaleString(
                          `${language}-${sellRequest?.seller?.country}`,
                          {
                            maximumFractionDigits: 2,
                          },
                        )}
                        &nbsp;%
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              )}
              {formData.sellingMethods.includes('exchange') && (
                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  spacing={2}
                  alignItems={{ xs: 'flex-start', md: 'center' }}>
                  <TextField
                    type='number'
                    margin='dense'
                    name='exchangePrice'
                    value={formData.exchangePrice}
                    disabled={formData.cxtSellingPrice <= 0}
                    inputProps={commonPriceInputProps}
                    InputProps={commonInputProps}
                    onChange={handleChange}
                    label={
                      <FormattedMessage id='sellRequests_labels_exchangePrice' defaultMessage='Net partex offer' />
                    }
                    fullWidth
                  />
                  {formData.cxtSellingPrice > 0 && (
                    <Stack width={0.2}>
                      <Typography variant='body2'>
                        <FormattedMessage id='common_labels_margin' defaultMessage='Margin' />:
                      </Typography>
                      <Typography>
                        {calculateMarginPercentage(formData?.cxtSellingPrice, formData.exchangePrice).toLocaleString(
                          `${language}-${sellRequest?.seller?.country}`,
                          {
                            maximumFractionDigits: 2,
                          },
                        )}
                        &nbsp;%
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              )}
              {formData.sellingMethods.includes('commission') && (
                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  spacing={2}
                  alignItems={{ xs: 'flex-start', md: 'center' }}>
                  <TextField
                    type='number'
                    margin='dense'
                    name='commissionRate'
                    value={formData.commissionRate}
                    disabled={formData.cxtSellingPrice <= 0}
                    inputProps={commonNumberInputProps}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Percent />
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleChange}
                    label={
                      <FormattedMessage id='sellRequests_labels_commissionRate' defaultMessage='Commission rate' />
                    }
                    fullWidth
                  />
                  {formData.commissionRate > 0 && (
                    <Stack width={0.4}>
                      <Typography variant='body2'>
                        <FormattedMessage id='sellRequests_labels_commissionPrice' defaultMessage='Commission price' />:
                      </Typography>
                      <Typography>
                        <Price
                          value={calculatePriceFromMargin(formData.cxtSellingPrice, formData.commissionRate)}
                          country={sellRequest?.seller?.country}
                          currency={sellRequest?.currencyCode}
                        />
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              )}
              <Divider sx={{ my: 2 }} />
              <Stack direction='row' spacing={2}>
                <Box sx={{ flex: 1 }}>
                  <TextField
                    margin='dense'
                    name='taxation'
                    value={formData.taxation}
                    onChange={handleChange}
                    label={<FormattedMessage id='sellRequests_labels_taxation' defaultMessage='Taxation' />}
                    select
                    fullWidth>
                    <MenuItem key='diff' value='diff'>
                      <FormattedMessage id='sellRequests_taxationTypes_diff' defaultMessage='diff' />
                    </MenuItem>
                    <MenuItem key='full' value='full'>
                      <FormattedMessage id='sellRequests_taxationTypes_full' defaultMessage='full' />
                    </MenuItem>
                  </TextField>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <TextField
                    type='number'
                    margin='dense'
                    name='expirationDaysLimit'
                    value={formData.expirationDaysLimit}
                    inputProps={commonNumberInputProps}
                    onChange={handleChange}
                    label={
                      <FormattedMessage
                        id='sellRequests_labels_expirationDaysLimit'
                        defaultMessage='Expiration in days'
                      />
                    }
                    fullWidth
                  />
                </Box>
              </Stack>
            </Stack>
            <Stack spacing={1} width={{ xs: 1, md: 0.7 }}>
              {offers.length > 0 ? (
                <>
                  <Typography fontWeight='bold'>
                    <FormattedMessage
                      id='sellRequests_offerModal_lastOffers'
                      defaultMessage='Last {count, plural, one {offer} other {# offers}} for this SKU'
                      values={{ count: offers.length }}
                    />
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <FormattedMessage id='common_labels_price' defaultMessage='Price' />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage id='common_labels_paperAvailable' defaultMessage='Paper' />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage id='common_labels_boxAvailable' defaultMessage='Box' />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage id='sellRequests_labels_constructionYear' defaultMessage='Year' />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage id='common_labels_watchCondition' defaultMessage='Condition' />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage id='common_labels_createdAt' defaultMessage='Created At' />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {offers?.map((offer, index) => (
                          <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell>
                              <IconButton
                                color='info'
                                onClick={() => {
                                  setFormData({
                                    ...formData,
                                    cxtSellingPrice: offer?.cxtSellingPrice / 100,
                                  });
                                }}
                                title={intl.formatMessage({
                                  id: 'sellRequests_offerModal_usePrice',
                                  defaultMessage: 'use this price',
                                })}>
                                <NorthWest />
                              </IconButton>
                              <Price
                                value={offer?.cxtSellingPrice / 100}
                                country={sellRequest?.seller.country}
                                currency={sellRequest?.currencyCode}
                              />
                            </TableCell>
                            <TableCell>
                              <BooleanIcon check={offer?.watch?.paperAvailable} />
                            </TableCell>
                            <TableCell>
                              <BooleanIcon check={offer?.watch?.boxAvailable} />
                            </TableCell>
                            <TableCell>{offer?.watch?.constructionYear}</TableCell>
                            <TableCell>
                              <ConditionBadge condition={offer?.watch?.watchCondition} />
                            </TableCell>
                            <TableCell>{formatUTCToLocal(offer?.createdAt)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <Card variant='outlined'>
                  <CardContent>
                    <Stack direction='column' spacing={1}>
                      <Typography fontWeight='bold'>
                        <FormattedMessage id='sellRequests_labels_priceEstimates' defaultMessage='Price Estimates' />:
                      </Typography>
                      <Typography>{getPriceRangeString(sellRequest?.priceFrom, sellRequest?.priceTo)}</Typography>
                      <Typography fontWeight='bold'>
                        <FormattedMessage id='sellRequests_sellingMethods_direct' defaultMessage='Direct' />:
                      </Typography>
                      <Typography>
                        {getPriceRangeString(sellRequest?.directPriceFrom, sellRequest?.directPriceTo)}
                      </Typography>
                      <Typography fontWeight='bold'>
                        <FormattedMessage id='sellRequests_sellingMethods_commission' defaultMessage='Commission' />:
                      </Typography>
                      <Typography>
                        {getPriceRangeString(sellRequest?.commissionPriceFrom, sellRequest?.commissionPriceTo)}
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>
              )}
              <Card variant='outlined'>
                <CardContent>
                  <Stack direction='column' spacing={1}>
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Tooltip key={sellRequest?.seller?.country} title={countryName} arrow>
                        <div>
                          <ReactCountryFlag
                            countryCode={sellRequest?.seller?.country || ''}
                            style={{ fontSize: '25px', lineHeight: '1em' }}
                          />
                        </div>
                      </Tooltip>
                      <Typography>{countryName}</Typography>
                    </Stack>
                    <Stack>
                      <Typography>
                        {sellRequest?.watchBrand} {sellRequest?.watchModel}
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography>{sellRequest?.watchRef}</Typography>
                    </Stack>
                    <Stack>
                      <Typography>{sellRequest?.sku}</Typography>
                    </Stack>
                    <Stack>
                      <Typography>{sellRequest?.constructionYear}</Typography>
                    </Stack>
                    <Stack direction='row'>
                      <ConditionBadge condition={sellRequest?.watchCondition} />
                    </Stack>
                    <Stack>
                      <SellingMethods methods={sellRequest?.sellingMethods || []} />
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
              {sellRequest.offerReply && (
                <>
                  {sellRequest.offerReply?.accept === 'approved' && (
                    <Typography fontWeight='medium' color='success.main'>
                      <FormattedMessage
                        id='sellRequests_offerReply_accepted'
                        defaultMessage='The customer has accepted this offer.'
                      />
                    </Typography>
                  )}
                  {sellRequest.offerReply?.accept === 'declined' && (
                    <Typography fontWeight='medium' color='error.main'>
                      <FormattedMessage
                        id='sellRequests_offerReply_declined'
                        defaultMessage='The customer has declined this offer.'
                      />
                    </Typography>
                  )}
                </>
              )}
              {sellRequest?.counterOffer && (
                <Card variant='outlined'>
                  <CardContent>
                    <Stack spacing={1}>
                      <Typography fontWeight='medium'>
                        {sellRequest.counterOffer && (
                          <FormattedMessage id='sellRequests_offerReply_counterOffer' defaultMessage='Counter Offer' />
                        )}
                      </Typography>
                      <SellingMethods methods={[sellRequest?.counterOffer?.selling_method?.toLowerCase()] || []} />
                      <Typography>
                        <Price
                          value={sellRequest?.counterOffer?.price}
                          country={sellRequest?.seller?.country}
                          currency={sellRequest?.counterOffer?.currency}
                        />
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>
              )}
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleModal}>
            <FormattedMessage id='common_close' defaultMessage='Close' />
          </Button>
          <Button color='info' onClick={saveOffer} disabled={!isFormValid}>
            <FormattedMessage id='common_save' defaultMessage='Save' />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OfferModal;
