import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { SellRequestCancellationReason } from '../../../../constants/sell';
import { requestSelector } from '../../../../selectors/request';
import { useAppDispatch, useAppSelector } from '../../../../state';
import { cancelSellRequestAction } from '../../../../state/ducks/sell/actions';
import { SellRequest } from '../../../../state/ducks/sell/types';
import { sendWorkshopInboundServiceQuotationAction } from '../../../../state/ducks/workshopInbound/actions';
import { text2title } from '../../../../utils/textUtils';
import ActionStatusSnackbar from '../../../components/snackbars/ActionStatusSnackbar';

type Props = {
  sellRequest: SellRequest;
  show: boolean;
  handleToggleModal: () => void;
  onSuccess: () => void;
};

const initialFormData: { reason: SellRequestCancellationReason | '' } = {
  reason: '',
};

const CancelModal: React.FC<Props> = ({ show, handleToggleModal, sellRequest, onSuccess }) => {
  const dispatch = useAppDispatch();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const request = useAppSelector((state) => requestSelector(state, cancelSellRequestAction.typePrefix));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const onCancel = () => {
    if (!formData.reason) return;
    dispatch(cancelSellRequestAction({ reference: sellRequest.reference.toString(), reason: formData.reason })).then(
      (response) => {
        if (response.type === cancelSellRequestAction.fulfilled.type) {
          setShowSnackbar(true);
          handleToggleModal();
          onSuccess();
        }
      },
    );
  };

  const clearForm = () => {
    setFormData(initialFormData);
  };

  useEffect(() => {
    clearForm();
  }, [show]);

  return (
    <>
      {showSnackbar && (
        <ActionStatusSnackbar
          actionName={cancelSellRequestAction.typePrefix}
          config={{ messageValues: { reference: sellRequest.sellRequestId || sellRequest.reference } }}
        />
      )}
      <Dialog open={show} onClose={() => request?.status !== 'pending' && handleToggleModal()}>
        <DialogTitle>
          <FormattedMessage
            id='sellRequests_cancelModal_title'
            defaultMessage='Cancel Sell Request {reference}'
            values={{ reference: sellRequest.sellRequestId || sellRequest.reference }}
          />
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} mt={2}>
            <TextField
              type='text'
              margin='dense'
              name='reason'
              value={formData.reason}
              onChange={handleChange}
              label={
                <FormattedMessage id='sellRequests_labels_cancellationReason' defaultMessage='Cancellation Reason' />
              }
              select
              fullWidth
              disabled={request.status === 'pending'}>
              {Object.keys(SellRequestCancellationReason).map((item) => (
                <MenuItem key={item} value={item}>
                  <FormattedMessage id={`sellRequests_cancellationReasons_${item}`} defaultMessage={text2title(item)} />
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleModal} disabled={request.status === 'pending'}>
            <FormattedMessage id='common_close' defaultMessage='Close' />
          </Button>
          <Button color='info' onClick={onCancel} disabled={!formData.reason || request.status === 'pending'}>
            <FormattedMessage id='sellRequests_actions_cancel' defaultMessage='Cancel Sell Request' />
          </Button>
        </DialogActions>
        {request?.status === 'pending' && <LinearProgress color='primary' />}
      </Dialog>
    </>
  );
};

export default CancelModal;
