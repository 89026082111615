import React from 'react';
import { DatePicker as MuiDatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

type Props = Omit<DatePickerProps<any>, 'renderInput'>;

const DatePicker: React.FC<Props> = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiDatePicker {...props} slotProps={{ textField: { size: 'small' } }} />
    </LocalizationProvider>
  );
};

export default DatePicker;
