import AddIcon from '@mui/icons-material/Add';
import { Button, Stack } from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../../state';
import { getWorkshopLocationsAction } from '../../../../state/ducks/workshop/actions';
import CreateUpdateWorkshopLocationModal from './CreateUpdateWorkshopLocationModal';

const WorkshopLocationsTableToolbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const [showCreateModal, setShowCreateModal] = useState(false);

  return (
    <Stack direction='row' sx={{ padding: '10px' }}>
      <Button startIcon={<AddIcon />} color='success' variant='outlined' onClick={() => setShowCreateModal(true)}>
        <FormattedMessage id='workshop_locations_add' defaultMessage='Add new Location' />
      </Button>
      <CreateUpdateWorkshopLocationModal
        onSuccess={() => dispatch(getWorkshopLocationsAction())}
        show={showCreateModal}
        handleToggleModal={() => setShowCreateModal(!showCreateModal)}
      />
    </Stack>
  );
};

export default WorkshopLocationsTableToolbar;
