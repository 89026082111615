import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Stack } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { APP_CONFIG } from '../../../config/app';
import { GROUPS } from '../../../constants/groups';
import { ClientRouteGroups } from '../../../routing/clientRoutes';
import { requestSelector } from '../../../selectors/request';
import { isAuthorizedSelector } from '../../../selectors/user';
import { workshopProcessesSelector } from '../../../selectors/workshop';
import { useAppDispatch, useAppSelector } from '../../../state';
import { getWorkshopProcessesAction, updateWorkshopProcessAction } from '../../../state/ducks/workshop/actions';
import StatusBadge from '../badges/StatusBadge';
import ActionStatusSnackbar from '../snackbars/ActionStatusSnackbar';
import SimpleTable, { SimpleTableColDef } from '../table/SimpleTable';
import WorkshopProcessUpdateForm from './WorkshopProcessUpdateForm';
import { NEXT_STATUS_MAPPING } from '../../../constants/workshop';

export const WORKSHOP_PROCESS_TABLE_COLUMNS: SimpleTableColDef[] = [
  {
    id: 'common_labels_status',
    headerName: 'Status',
    field: 'status',
    minWidth: 150,
    renderCell: (params) => <StatusBadge status={params.row.status} />,
  },
  {
    id: 'workshop_labels_activity',
    headerName: 'Activity',
    field: 'activity',
    minWidth: 200,
    valueGetter: (params) => params.row?.activity?.name,
  },
  {
    id: 'common_labels_reference',
    headerName: 'Reference',
    field: 'workshopItemReference',
    minWidth: 100,
    flex: 1,
  },
  {
    id: 'common_labels_createdBy',
    headerName: 'Created By',
    field: 'createdBy',
    minWidth: 200,
    flex: 1,
  },
  {
    id: 'workshop_processes_labels_elapsedTime',
    headerName: 'Elapsed Time',
    field: 'elapsedTime',
    minWidth: 150,
    flex: 1,
    sortable: false,
  },
  {
    id: 'common_labels_createdAt',
    headerName: 'Created At',
    field: 'createdAt',
    minWidth: 150,
    flex: 1,
    sortable: false,
  },
  {
    id: 'workshop_labels_lastTimestamp',
    headerName: 'Last Timestamp',
    field: 'latestTimestamp',
    minWidth: 150,
    flex: 1,
    sortable: false,
  },
];

type Props = {
  editable?: boolean;
  username?: string;
};

const WorkshopProcessesTable: React.FC<Props> = ({ editable, username }) => {
  const dispatch = useAppDispatch();
  const isAdminUser = useAppSelector((state) => isAuthorizedSelector(state, [GROUPS.ADMIN, GROUPS.WORKSHOP_ADMIN]));
  const userCanEdit =
    useAppSelector((state) =>
      isAuthorizedSelector(
        state,
        ClientRouteGroups.WORKSHOP_INBOUND_ITEM.filter((g) => g.startsWith('Workshop')),
      ),
    ) || !APP_CONFIG.isProd;
  const getWorkshopProcessesRequest = useAppSelector((state) =>
    requestSelector(state, getWorkshopProcessesAction.typePrefix),
  );
  const processes = useAppSelector(workshopProcessesSelector);
  const [tableColumns, setTableColumns] = useState<SimpleTableColDef[]>(WORKSHOP_PROCESS_TABLE_COLUMNS);
  const [updateProcessDrawerState, setUpdateProcessDrawerState] = useState(false);
  const [showUpdateSnackbar, setShowUpdateSnackbar] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState({
    workshopItemReference: '',
    processReference: '',
    status: '',
  });

  const fetchLatestProcesses = (username?: string) => {
    const startTime = moment().subtract(4, 'month').startOf('month').toISOString();
    const endTime = moment().endOf('day').toISOString();
    const params: Record<string, string> = {
      offset: '0',
      limit: '3000',
      start_time: startTime,
      end_time: endTime,
    };
    if (username) {
      params.created_by = username;
      params.except_completed = 'true';
    }
    const searchParams = new URLSearchParams(params);

    dispatch(getWorkshopProcessesAction(searchParams));
  };

  useEffect(() => {
    if (username) {
      fetchLatestProcesses(username);
    }
  }, []);

  useEffect(() => {
    const additionalColumns: SimpleTableColDef[] = [];
    if (editable && isAdminUser) {
      additionalColumns.push({
        headerName: '',
        field: 'adminAction',
        width: 50,
        renderCell: (params: any) => {
          return (
            <IconButton
              aria-label='edit'
              size='small'
              disabled={params?.row?.isCompleted}
              onClick={() => {
                setSelectedProcess(params.row);

                // Open the drawer
                setUpdateProcessDrawerState(true);
              }}>
              <EditIcon fontSize='small' />
            </IconButton>
          );
        },
      });
    }
    if (username && userCanEdit) {
      additionalColumns.push({
        id: 'common_actions',
        headerName: 'Actions',
        field: 'actions',
        minWidth: 220,
        flex: 1,
        renderCell: (params: any) => {
          const status = params.row.status;
          const next = NEXT_STATUS_MAPPING?.[status];
          return (
            <Stack direction='row' spacing={1} alignItems='center'>
              {next?.map((nextStatus) => (
                <StatusBadge
                  key={nextStatus}
                  status={nextStatus}
                  onClick={() => {
                    const { reference, workshopItemReference } = params.row;
                    dispatch(
                      updateWorkshopProcessAction({
                        reference,
                        workshopItemReference,
                        requestBody: {
                          status: nextStatus,
                        },
                      }),
                    ).then((response) => {
                      setShowUpdateSnackbar(true);
                      if (response?.payload) {
                        fetchLatestProcesses(username);
                      }
                    });
                  }}
                />
              ))}
            </Stack>
          );
        },
      });
    }

    setTableColumns([...additionalColumns, ...WORKSHOP_PROCESS_TABLE_COLUMNS]);
  }, [isAdminUser, editable, userCanEdit]);

  return (
    <>
      {showUpdateSnackbar && <ActionStatusSnackbar actionName={updateWorkshopProcessAction.typePrefix} />}
      <WorkshopProcessUpdateForm
        process={selectedProcess}
        isOpen={updateProcessDrawerState}
        onClose={() => setUpdateProcessDrawerState(false)}
        onSuccess={() => fetchLatestProcesses()}
      />
      <SimpleTable
        columns={tableColumns}
        rows={processes?.items || []}
        loading={getWorkshopProcessesRequest?.status === 'pending'}
      />
    </>
  );
};

export default WorkshopProcessesTable;
