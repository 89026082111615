import { SellRequestStatusInfoShowDetails } from '../../../constants/sell';
import {
  WorkshopActivities,
  WorkshopReworkReasons,
  WorkshopSparePartsStatus,
  WorkshopStatus,
} from '../../../constants/workshop';
import { WorkshopActivityReportFormData } from '../../../constants/workshopReport';

export enum WorkshopInboundActions {
  getWorkshopInboundRequests = 'workshop/getWorkshopInboundRequests',
  getWorkshopInboundWatches = 'workshop/getWorkshopInboundWatches',
  getWorkshopInboundWatch = 'workshop/getWorkshopInboundWatch',
  updateWorkshopInboundWatch = 'workshop/updateWorkshopInboundWatch',
  updateWorkshopInboundWatchSpareParts = 'workshop/updateWorkshopInboundWatchSpareParts ',
  getWorkshopInboundActivities = 'workshop/getWorkshopInboundActivities',
  getWorkshopInboundActivity = 'workshop/getWorkshopInboundActivity',
  createWorkshopInboundActivity = 'workshop/createWorkshopInboundActivity',
  updateWorkshopInboundActivityStatus = 'workshop/updateWorkshopInboundActivityStatus',
  getWorkshopInboundReportFields = 'workshop/getWorkshopInboundReportFields',
  getWorkshopInboundActivityReport = 'workshop/getWorkshopInboundActivityReport',
  createWorkshopInboundReport = 'workshop/createWorkshopInboundReport',
  updateWorkshopInboundReport = 'workshop/updateWorkshopInboundReport',
  createWorkshopInboundServices = 'workshop/createWorkshopInboundServices',
  updateWorkshopInboundService = 'workshop/updateWorkshopInboundService',
  sendWorkshopInboundServiceQuotation = 'workshop/sendWorkshopInboundServiceQuotation',
  getMaintenanceActivityStatus = 'workshop/getMaintenanceActivityStatus',
  getWorkshopInboundReportOverview = 'workshop/getWorkshopInboundReportOverview',
  getWorkshopInboundReportItemOverview = 'workshop/getWorkshopInboundReportItemOverview',
  getWorkshopInboundReportItemDetails = 'workshop/getWorkshopInboundReportItemDetails',
  updateExternalWorkshop = 'workshop/updateExternalWorkshop',
}

export type WorkshopInboundWatchActivityHistoryItem = {
  activity_id: number;
  status: string;
  revision_number: number;
  changed_at: string;
};

export type WorkshopInboundWatchActivity = {
  id: number;
  activity_type: WorkshopActivities;
  elapsed_time: number;
  status: WorkshopStatus;
  watch: WorkshopInboundWatch;
  activity_history: WorkshopInboundWatchActivityHistoryItem[];
  assignee: string;
  service: WorkshopInboundWatchService;
  activity_report?: WorkshopActivityReport;
  report_sent_to_erp?: boolean;
  rework_reasons?: WorkshopReworkReasons[];
  rework_activity_id?: number;
  authenticate_activity_report?: WorkshopActivityReport;
};

export type WorkshopInboundWatchService = {
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  id: number;
  service_type: string;
  approval_status: string;
  is_optional: boolean;
  price: number;
  quotation_sent: boolean;
  comment?: string;
};

export enum WorkshopInboundExternalWorkshopCurrency {
  EUR = 'EUR',
  CHF = 'CHF',
}

export type WorkshopInboundExternalWorkshop = {
  id: number;
  service_id: number;
  currency: WorkshopInboundExternalWorkshopCurrency;
  amount: number;
  received_date?: string;
  created_at: string;
  updated_at: string;
  created_by?: string;
  updated_by?: string;
};

export type WorkshopInboundWatch = {
  id: number;
  item_number: string;
  variant_code: string;
  description: string;
  brand: string;
  model: string;
  submodel: string;
  final_variant: string;
  reference: string;
  dial_color: string;
  bracelet_material: string;
  box: boolean;
  certificate: boolean;
  condition: string;
  year: string;
  quantity: number;
  unit_price: number;
  activity: string;
  location_id: string;
  serial_number: string;
  activities: WorkshopInboundWatchActivity[];
  services: WorkshopInboundWatchService[];
  authenticate_activity: WorkshopInboundWatchActivity;
  workshop_request: Omit<WorkshopInboundRequest, 'watches'>;
  spare_parts_status: WorkshopSparePartsStatus;
  spare_parts: string;
  used_goods: boolean;
  status: SellRequestStatusInfoShowDetails;
  external_workshop?: WorkshopInboundExternalWorkshop;
  priority?: boolean;
};

export type WorkshopInboundRequest = {
  id: number;
  order_type: string;
  order_number: string;
  vendor_number: string;
  vendor_name: string;
  watches: WorkshopInboundWatch[];
  created_at?: string;
  updated_at?: string;
  created_by?: string;
  updated_by?: string;
};

export type WorkshopInboundRequestsState = {
  count: number;
  items: WorkshopInboundRequest[];
};

export type WorkshopInboundItemsState = {
  count: number;
  total_value?: number;
  items: WorkshopInboundWatch[];
};

export type WorkshopInboundWatchActivityState = {
  count: number;
  items: WorkshopInboundWatchActivity[];
};

export type WorkshopActivityReport = WorkshopActivityReportFormData & {
  id: number;
  activity_id: number;
};

type WorkshopInboundDashboardReportItem = {
  [key: string]: string | number;
};

export type WorkshopInboundDashboardReport = {
  metaData: {
    startDate: string;
    endDate: string;
    totals?: {
      [key: string]: number;
    };
    offset?: number;
    limit?: number;
    total?: number;
  };
  items: WorkshopInboundDashboardReportItem[];
};

export type WorkshopInboundDashboardReportItemOverview = {
  metaData: {
    startDate: string;
    endDate: string;
  };
  items?: Record<string, WorkshopInboundDashboardReportItem>;
};

export type WorkshopInboundDashboardReportItemList = {
  metaData: {
    startDate: string;
    endDate: string;
    offset: number;
    limit: number;
    total: number;
  };
  items: {
    watchId: string;
    serialNumber: string;
    price: number;
    completed: boolean;
  }[];
};

export type WorkshopInboundState = {
  requests: WorkshopInboundRequestsState;
  watches: WorkshopInboundItemsState;
  watch?: WorkshopInboundWatch;
  activities: WorkshopInboundWatchActivityState;
  activity?: WorkshopInboundWatchActivity;
  reportFields: Record<string, string[]>;
  maintenanceActivity?: WorkshopInboundWatchActivity;
  refetchActivities: boolean;
  report: WorkshopInboundDashboardReport;
  reportItemOverview: WorkshopInboundDashboardReportItemOverview;
  reportItemDetails: WorkshopInboundDashboardReportItemList;
};
