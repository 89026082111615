import { APIRoute, createPath } from '../../routing';
import { MarginConfigItem, MarginRanges } from '../../state/ducks/pricing/types';
import { del, get, patch, post } from '../../utils/httpUtils';

export const getPricing = (params: URLSearchParams) => {
  return get(APIRoute.PRICING, params);
};

export const getPricingConfig = (params: URLSearchParams) => {
  return get(APIRoute.PRICING_CONFIG, params);
};

export type CreatePricingConfigParams = Omit<
  MarginConfigItem,
  'brandOriginal' | 'modelOriginal' | 'referenceOriginal' | 'id' | 'isDeletable'
> & {
  brand: string;
  model: string;
  reference: string;
};

export const createPricingConfigEntry = (params: CreatePricingConfigParams) => {
  return post(APIRoute.PRICING_CONFIG, params);
};

export type UpdatePricingConfigParams = {
  id: string;
  data: MarginRanges;
};

export const updatePricingConfigEntry = (params: UpdatePricingConfigParams) => {
  return patch(APIRoute.PRICING_CONFIG, params.data, {
    params: { id: params.id },
  });
};

export const deletePricingConfigEntry = (id: string) => {
  return del(APIRoute.PRICING_CONFIG, null, { params: { id } });
};

export const getPricingConfigBrands = (params: URLSearchParams) => {
  return get(APIRoute.PRICING_CONFIG_BRANDS, params);
};

export const getPricingConfigModels = (brand: string) => {
  const params = new URLSearchParams({ brand });
  return get(APIRoute.PRICING_CONFIG_MODELS, params);
};

export const getPricingConfigReferences = (brand: string, model: string) => {
  const params = new URLSearchParams({ brand, model });
  return get(APIRoute.PRICING_CONFIG_REFERENCES, params);
};
