import { AftershipStatus } from '../../constants/tracking';
import { get, post, put } from '../../utils/httpUtils';
import { APIRoute, createPath } from '../../routing';

export enum TrackingType {
  SALES,
  PURCHASES,
  TRANSFERS,
}

export enum TrackingReportType {
  SALES = 'sales',
  PURCHASES = 'purchases',
  TRANSFERS = 'transfer_orders',
}

export const getTrackingItems = (type: TrackingType, params: URLSearchParams | null = null) => {
  switch (type) {
    case TrackingType.SALES:
      return get(APIRoute.TRACKING_SALES, params);
    case TrackingType.PURCHASES:
      return get(APIRoute.TRACKING_PURCHASES, params);
    case TrackingType.TRANSFERS:
      return get(APIRoute.TRACKING_TRANSFERS, params);
  }
};

export const getTrackingItem = (type: TrackingType, reference: string) => {
  let url = '';
  switch (type) {
    case TrackingType.SALES:
      url = createPath({
        path: APIRoute.TRACKING_SALES_DETAILS,
        params: { reference },
      });
      break;
    case TrackingType.PURCHASES:
      url = createPath({
        path: APIRoute.TRACKING_PURCHASES_DETAILS,
        params: { reference },
      });
      break;
    case TrackingType.TRANSFERS:
      url = createPath({
        path: APIRoute.TRACKING_TRANSFERS_DETAILS,
        params: { reference },
      });
      break;
  }
  return get(url);
};

export type GetTrackingReportParams = {
  reportType: TrackingReportType;
  startDate: string;
  state?: AftershipStatus;
};

export const downloadTrackingReport = (requestBody: GetTrackingReportParams) => {
  return post(APIRoute.TRACKING_REPORTS_EXPORT, requestBody);
};

export const createTrackingComment = (params: { reference: string; comment: string }) => {
  const url = createPath({
    path: APIRoute.TRACKING_COMMENTS,
    params: { reference: params.reference },
  });
  return post(url, { comment: params.comment });
};

export const updateTrackingComment = (params: { reference: string; commentId: string; comment: string }) => {
  const url = createPath({
    path: APIRoute.TRACKING_COMMENTS_UPDATE,
    params: { reference: params.reference, commentId: params.commentId },
  });
  return put(url, { comment: params.comment });
};
