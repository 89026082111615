import styles from './Spinner.module.scss';

import { CircularProgress, CircularProgressProps } from '@mui/material';
import * as React from 'react';

const Spinner: React.FC<CircularProgressProps> = (props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.spinner}>
        <CircularProgress thickness={1} size={50} {...props} />
      </div>
    </div>
  );
};

export default Spinner;
