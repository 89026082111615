export enum TrackingActions {
  getTrackingItemsSales = 'tracking/getTrackingItemsSales',
  getTrackingItemSales = 'tracking/getTrackingItemSales',
  getTrackingItemsPurchases = 'tracking/getTrackingItemsPurchases',
  getTrackingItemPurchases = 'tracking/getTrackingItemPurchases',
  getTrackingItemsTransfers = 'tracking/getTrackingItemsTransfers',
  getTrackingItemTransfers = 'tracking/getTrackingItemTransfers',
  downloadTrackingReportSales = 'tracking/downloadTrackingReportSales',
  downloadTrackingReportPurchases = 'tracking/downloadTrackingReportPurchases',
  downloadTrackingReportTransfers = 'tracking/downloadTrackingReportTransfers',
  createTrackingComment = 'tracking/createTrackingComment',
  updateTrackingComment = 'tracking/updateTrackingComment',
}
export type TrackingComment = {
  id: string;
  username: string;
  comment: string;
  createdAt: string;
  updatedAt?: string;
};
export type TrackingHistory = {
  id: string;
  countryCode: string;
  city: string;
  postalCode: string;
  tag: string;
  subtag: string;
  message: string;
  checkpointTime: string;
};

export type Tracking = {
  id: string;
  carrier: string;
  createdAt: string;
  originCountryCode: string;
  destCountryCode: string;
  destPostalCode: string;
  expectedDelivery: string;
  state: string;
  comments: TrackingComment[];
  history: TrackingHistory[];
};

export type TrackingItem = {
  shipmentId: string;
  invoiceId: string;
  paymentMethod: string;
  inventoryId?: string;
  tracking: Tracking;
};

type TrackingItemSales = TrackingItem & {
  orderId: string;
};

type TrackingItemPurchases = TrackingItem & {
  purchaseOrderId: string;
};

type TrackingItemTransfer = TrackingItem & {
  transferOrderId: string;
};

export type TrackingState = {
  countSales: number;
  trackingItemsSales: TrackingItemSales[];
  trackingItemSales?: TrackingItemSales;
  countPurchases: number;
  trackingItemsPurchases: TrackingItemPurchases[];
  trackingItemPurchases?: TrackingItemPurchases;
  countTransfers: number;
  trackingItemsTransfers: TrackingItemTransfer[];
  trackingItemTransfers?: TrackingItemTransfer;
};
