import {
  GridFilterModel,
  GridLogicOperator,
  GridValueGetterParams,
  getGridSingleSelectOperators,
  getGridStringOperators,
} from '@mui/x-data-grid';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import React, { useCallback } from 'react';
import { WORKSHOP_INBOUND_TABLE } from '../../../constants/localStorage';
import useSearchStoreHistory from '../../../hooks/useSearchStoreHistory';
import { workshopInboundRequestsSelector } from '../../../selectors/workshop-inbound';
import { useAppSelector } from '../../../state';
import { getWorkshopInboundRequestsAction } from '../../../state/ducks/workshopInbound/actions';
import { formatUTCToLocal } from '../../../utils/datetime';
import ActionStatusSnackbar from '../../components/snackbars/ActionStatusSnackbar';
import ServerSidePaginatedTable, {
  ServerSidePaginatedTableColDef,
} from '../../components/table/ServerSidePaginatedTable';
import styles from './WorkshopInbound.module.scss';
import WorkshopInboundRequestsDetailPanel from './components/WorkshopInboundRequestsDetailPanel';

import { debounce } from 'lodash';
import {
  WorkshopInboundTableToolbar,
  defaultSearchParams,
  sortFunction,
} from './components/WorkshopInboundTableToolbar';

const tableColumns: ServerSidePaginatedTableColDef[] = [
  {
    id: 'common_labels_id',
    headerName: 'ID',
    field: 'id',
    minWidth: 50,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
  },
  {
    id: 'workshopInbound_labels_orderType',
    headerName: 'Order Type',
    field: 'order_type',
    minWidth: 150,
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'is'),
    type: 'singleSelect',
    valueOptions: ['SalesReturnOrder', 'PurchaseOrder'],
  },
  {
    id: 'common_labels_orderId',
    headerName: 'Order ID',
    field: 'order_number',
    minWidth: 150,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
  },
  {
    id: 'workshopInbound_labels_vendorId',
    headerName: 'Vendor Number',
    field: 'vendor_number',
    minWidth: 150,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
  },
  {
    id: 'workshopInbound_labels_vendorName',
    headerName: 'Vendor Name',
    field: 'vendor_name',
    minWidth: 150,
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
  },
  {
    id: 'common_labels_createdAt',
    headerName: 'Created At',
    field: 'created_at',
    minWidth: 150,
    valueGetter: ({ value }: GridValueGetterParams) => value && formatUTCToLocal(value),
  },
];

const WorkshopInboundRequestsTable: React.FC = () => {
  const workshopRequests = useAppSelector(workshopInboundRequestsSelector);
  const apiRef = useGridApiRef();

  const {
    searchParams,
    updateSearchFilters,
    updateSort,
    resetSearch,
    initialFilters,
    initialSorting,
    hasSavedSearch,
    shareSearch,
  } = useSearchStoreHistory({
    storeName: `${WORKSHOP_INBOUND_TABLE}_requests`,
    defaultSearchParams,
    sortFunction,
    apiRef,
  });

  const handleFilterModelChange = debounce((filterModel: GridFilterModel) => {
    updateSearchFilters(filterModel);
  }, 150);

  const getDetailPanelContent = useCallback(({ row }) => <WorkshopInboundRequestsDetailPanel row={row} />, []);

  return (
    <div className={styles.wrapper}>
      <ActionStatusSnackbar actionName={getWorkshopInboundRequestsAction.typePrefix} />
      <ServerSidePaginatedTable
        apiRef={apiRef}
        rows={workshopRequests.items}
        columns={tableColumns}
        totalCount={workshopRequests.count}
        fetchDataEffect={getWorkshopInboundRequestsAction}
        searchParams={searchParams}
        slots={{
          toolbar: WorkshopInboundTableToolbar,
        }}
        slotProps={{
          toolbar: {
            showFilterButtons: hasSavedSearch,
            handleResetClick: resetSearch,
            handleShareClick: shareSearch,
          },
          filterPanel: {
            logicOperators: [GridLogicOperator.And],
          },
        }}
        sortingMode='server'
        onSortModelChange={updateSort}
        filterMode='server'
        onFilterModelChange={handleFilterModelChange}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => 'auto'}
        initialState={{
          filter: {
            filterModel: {
              items: initialFilters,
            },
          },
          sorting: {
            sortModel: initialSorting,
          },
        }}
      />
    </div>
  );
};

export default WorkshopInboundRequestsTable;
