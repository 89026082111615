import { createReducer } from '@reduxjs/toolkit';
import { InventoryState } from './types';
import { getProductsAction, getProductAction, getVariantsAction, getInventoryItemAction } from './actions';

const initialState = {
  products: {
    count: 0,
    items: [],
  },
  product: null,
  variants: {
    count: 0,
    items: [],
  },
  variant: null,
  inventoryItems: {
    count: 0,
    items: [],
  },
  inventoryItem: null,
} as InventoryState;

const inventoryReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getProductsAction.fulfilled, (state, action) => {
      const { items, count } = action.payload;
      state.products.items = items;
      state.products.count = count || items.length;
    })
    .addCase(getProductAction.fulfilled, (state, action) => {
      state.product = action.payload;
    })
    .addCase(getVariantsAction.fulfilled, (state, action) => {
      const { items, count } = action.payload;
      state.variants.items = items;
      state.variants.count = count || items.length;
    })
    .addCase(getInventoryItemAction.fulfilled, (state, action) => {
      state.inventoryItem = action.payload;
    });
});

export default inventoryReducer;
