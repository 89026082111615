import { TabContext, TabPanel } from '@mui/lab';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import TrackingPurchases from './TrackingPurchases';
import TrackingSales from './TrackingSales';
import TrackingTransfers from './TrackingTransfers';
import { NavLink, useParams } from 'react-router-dom';
import { ClientRoute, createPath } from '../../../routing';

type Params = {
  [key: string]: string;
};

const tabProps = {
  sx: {
    flex: 1,
    padding: 0,
  },
};

const TrackingPage: React.FC = () => {
  const { tab } = useParams<Params>();

  return (
    <TabContext value={tab}>
      <Stack sx={{ height: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 3 }}>
          <Tabs selectionFollowsFocus value={tab}>
            <Tab
              value='sales'
              component={NavLink}
              to={createPath({ path: ClientRoute.TRACKING_TABS, params: { tab: 'sales' } })}
              label={<FormattedMessage id='tracking_tabs_sales' defaultMessage='Sales Tracking' />}
            />
            <Tab
              value='purchases'
              component={NavLink}
              to={createPath({ path: ClientRoute.TRACKING_TABS, params: { tab: 'purchases' } })}
              label={<FormattedMessage id='tracking_tabs_purchases' defaultMessage='Purchases Tracking' />}
            />
            <Tab
              value='transfers'
              component={NavLink}
              to={createPath({ path: ClientRoute.TRACKING_TABS, params: { tab: 'transfers' } })}
              label={<FormattedMessage id='tracking_tabs_transfers' defaultMessage='Internal Transfers' />}
            />
          </Tabs>
        </Box>
        <TabPanel value='sales' {...tabProps}>
          {tab === 'sales' && <TrackingSales />}
        </TabPanel>
        <TabPanel value='purchases' {...tabProps}>
          {tab === 'purchases' && <TrackingPurchases />}
        </TabPanel>
        <TabPanel value='transfers' {...tabProps}>
          {tab === 'transfers' && <TrackingTransfers />}
        </TabPanel>
      </Stack>
    </TabContext>
  );
};

export default TrackingPage;
