import { Paper, Stack } from '@mui/material';
import { GridValueGetterParams } from '@mui/x-data-grid';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { MarginConfigItem } from '../../../../state/ducks/pricing/types';
import { formatUTCToLocal } from '../../../../utils/datetime';
import { SimpleTableColDef } from '../../../components/table/SimpleTable';
import MarginRangeTable from './MarginRangeTable';

const columns: SimpleTableColDef[] = [
  {
    id: 'workshopInbound_labels_id',
    headerName: 'Item Number',
    field: 'item_number',
    minWidth: 50,
    sortable: false,
    filterable: false,
  },
  {
    id: 'common_labels_variantCode',
    headerName: 'Variant Code',
    field: 'variant_code',
    minWidth: 150,
    sortable: false,
    filterable: false,
  },
  {
    id: 'common_labels_brand',
    headerName: 'Brand',
    field: 'brand',
    minWidth: 150,
    sortable: false,
    filterable: false,
  },
  {
    id: 'common_labels_model',
    headerName: 'Model',
    field: 'model',
    minWidth: 150,
    sortable: false,
    filterable: false,
  },
  {
    id: 'common_labels_serialNumber',
    headerName: 'Serial Number',
    field: 'serial_number',
    minWidth: 150,
    sortable: false,
    filterable: false,
  },
  {
    id: 'common_labels_createdAt',
    headerName: 'Created At',
    field: 'created_at',
    valueGetter: ({ value }: GridValueGetterParams) => value && formatUTCToLocal(value),
    flex: 1,
  },
];

type Props = {
  row: MarginConfigItem;
};

const PriceCalculatorMarginConfigDetailPanel: React.FC<Props> = ({ row }) => (
  <Paper sx={{ flex: 1, my: 1, mx: 'auto', width: '95%', p: 1 }}>
    <Stack direction='row' spacing={3}>
      {Object.entries(row?.marginRanges).map(([key, value]) => (
        <Stack key={key} spacing={1} sx={{ height: `${Object.keys(value)?.length * 36 + 50}px` }}>
          <MarginRangeTable marginTiers={value} type={key} />
        </Stack>
      ))}
    </Stack>
  </Paper>
);

export default PriceCalculatorMarginConfigDetailPanel;
