import { AttachMoney, CurrencyFranc, CurrencyPound, Euro, Money } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React from 'react';

type Props = {
  currency: string;
  styles?: Record<string, any>;
  color?:
    | 'inherit'
    | 'disabled'
    | 'action'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | undefined;
};

const CurrencyIcon: React.FC<Props> = ({ currency, styles = { fontSize: '1rem' }, color = 'primary' }) => {
  const iconProps = { sx: { ...styles }, color };
  let icon;
  switch (currency) {
    case 'EUR':
      icon = <Euro {...iconProps} />;
      break;
    case 'GBP':
      icon = <CurrencyPound {...iconProps} />;
      break;
    case 'USD':
      icon = <AttachMoney {...iconProps} />;
      break;
    case 'CHF':
      icon = <CurrencyFranc {...iconProps} />;
      break;
    default:
      icon = <Money {...iconProps} />;
  }
  return (
    <Tooltip title={currency} arrow>
      {icon}
    </Tooltip>
  );
};

export default CurrencyIcon;
