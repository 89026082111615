import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { isAuthorizedSelector } from '../../../../selectors/user';
import { useAppSelector } from '../../../../state';
import styles from './SidebarItem.module.scss';

type Item = {
  icon?: React.ReactNode;
  name: string | React.ReactNode;
  href?: string;
  groups?: string[];
};

type Props = {
  item: Item;
  children?: Item[];
};

const ChildrenListItem: React.FC<Item> = ({ href, icon, name, groups }) => {
  const isAuthorized = useAppSelector((state) => isAuthorizedSelector(state, groups));
  if (!isAuthorized) return null;

  return (
    <ListItemButton
      disableRipple
      disabled={!isAuthorized}
      component={NavLink}
      to={href || '#'}
      className={styles.listItem}
      activeClassName='Mui-selected'>
      <ListItemIcon className={styles.listIcon}>{icon}</ListItemIcon>
      <ListItemText primary={name} />
    </ListItemButton>
  );
};

const SidebarItem: React.FC<Props> = ({ item, children }) => {
  const location = useLocation();
  const isAuthorized = useAppSelector((state) => isAuthorizedSelector(state, item.groups));
  const initialOpen = children?.some((child) => child.href === location.pathname);
  const [open, setOpen] = useState(initialOpen);

  const handleClick = () => {
    setOpen(!open);
  };

  if (!isAuthorized) return null;

  const listItemProps = children
    ? { component: 'div' }
    : {
        component: NavLink,
        to: item.href || '#',
        activeClassName: 'Mui-selected',
      };

  const mainItem = (
    <ListItemButton disableGutters disableRipple className={styles.mainItem} onClick={handleClick} {...listItemProps}>
      <ListItemIcon className={styles.listIcon}>{item.icon}</ListItemIcon>
      <ListItemText primary={item.name} />
      {children ? open ? <ExpandMoreIcon /> : <ChevronRightIcon /> : null}
    </ListItemButton>
  );

  const childrenList = children ? (
    <Collapse in={open} timeout='auto' unmountOnExit>
      <List dense={true} disablePadding>
        {children.map((child) => (
          <ChildrenListItem key={child.href} {...child} />
        ))}
      </List>
    </Collapse>
  ) : null;

  return (
    <List dense={true} className={styles.itemGroup}>
      {mainItem}
      {childrenList}
    </List>
  );
};

export default SidebarItem;
