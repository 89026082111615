import React from 'react';
import SimpleTable, { SimpleTableColDef } from '../../../components/table/SimpleTable';
import { WorkshopInboundRequest } from '../../../../state/ducks/workshopInbound/types';
import { Paper, Stack } from '@mui/material';
import { GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import { ClientRoute, createPath } from '../../../../routing';
import { useHistory } from 'react-router-dom';
import { formatUTCToLocal } from '../../../../utils/datetime';

const columns: SimpleTableColDef[] = [
  {
    id: 'workshopInbound_labels_id',
    headerName: 'Item Number',
    field: 'item_number',
    minWidth: 50,
    sortable: false,
    filterable: false,
  },
  {
    id: 'common_labels_variantCode',
    headerName: 'Variant Code',
    field: 'variant_code',
    minWidth: 150,
    sortable: false,
    filterable: false,
  },
  {
    id: 'common_labels_brand',
    headerName: 'Brand',
    field: 'brand',
    minWidth: 150,
    sortable: false,
    filterable: false,
  },
  {
    id: 'common_labels_model',
    headerName: 'Model',
    field: 'model',
    minWidth: 150,
    sortable: false,
    filterable: false,
  },
  {
    id: 'common_labels_serialNumber',
    headerName: 'Serial Number',
    field: 'serial_number',
    minWidth: 150,
    sortable: false,
    filterable: false,
  },
  {
    id: 'common_labels_createdAt',
    headerName: 'Created At',
    field: 'created_at',
    valueGetter: ({ value }: GridValueGetterParams) => value && formatUTCToLocal(value),
    flex: 1,
  },
];

type Props = {
  row: WorkshopInboundRequest;
};

const WorkshopInboundRequestsDetailPanel: React.FC<Props> = ({ row }) => {
  const history = useHistory();

  return (
    <Paper sx={{ flex: 1, my: 1, mx: 'auto', width: '95%', p: 1 }}>
      <Stack direction='column' spacing={1} sx={{ height: `${row.watches?.length * 36 + 39}px` }}>
        <SimpleTable
          rows={row.watches}
          columns={columns}
          density='compact'
          autoHeight
          sx={{ flex: 1 }}
          hideFooter
          onRowClick={(params: GridRowParams) => {
            const path = createPath({
              path: ClientRoute.WORKSHOP_INBOUND_ITEM,
              params: { reference: params.row.id },
            });
            history.push(path);
          }}
        />
      </Stack>
    </Paper>
  );
};

export default WorkshopInboundRequestsDetailPanel;
